import { createModel } from '@rematch/core';
import type {
    Stories,
    StoriesQuery,
    StoriesWithPagination,
} from '@mega/api';
import * as CLIENT from '@mega/api';
import { debounce, isUndefined } from 'lodash-es';

import type { RootModel } from '../rootModel';
import { DefaultStore } from '../type';
import { parseQueryString } from '../transformers';
import { calculatePagesCount } from '@mega/utils';

const defaultStore: DefaultStore<Stories, StoriesQuery> = {
    currentPage: 1,
    query: {
        per_page: 30,
    },
    meta: {
        count: null,
    },
    hasMore: false,
    pages: {},
};

const stories = createModel<RootModel>()({
    name: 'stories',
    state: defaultStore,
    reducers: {
        changePerPage: (
            state,
            payload: { perPage: number },
        ) => {
            return { ...state, perPage: payload.perPage };
        },
        putCurrentPage: (
            state,
            payload: { triggerPage: number },
        ) => {
            return {
                ...state,
                currentPage: payload.triggerPage,
            };
        },
        putPage: (
            state,
            payload: StoriesWithPagination,
        ) => {
            const next = parseQueryString<number>(
                payload?.next ?? '',
                'page',
                parseInt,
            );
            const prev = parseQueryString<number>(
                payload?.previous ?? '',
                'page',
                parseInt,
            );
            const isFirstPageAndLast =
                !payload?.next && !payload?.previous;
            const isFirstPage = next === 2;

            const isLastPage = next === null;

            if (isFirstPageAndLast) {
                return {
                    ...state,
                    hasMore: Boolean(next),
                    meta: {
                        count: isUndefined(payload.count)
                            ? null
                            : payload.count,
                    },
                    pages: {
                        ...state.pages,
                        [`${1}`]: payload.results,
                    },
                };
            }

            return {
                ...state,

                hasMore: Boolean(next),
                meta: {
                    count: isUndefined(payload.count)
                        ? null
                        : payload.count,
                },
                pages: isFirstPage
                    ? {
                          ...state.pages,
                          [`${1}`]: payload.results,
                      }
                    : isLastPage
                    ? {
                          ...state.pages,
                          [`${prev ? prev + 1 : 2}`]:
                              payload.results,
                      }
                    : {
                          ...state.pages,
                          [`${next - 1}`]: payload.results,
                      },
            };
        },
        putQuery: (state, payload: StoriesQuery) => {
            return {
                ...defaultStore,
                query: { ...state.query, ...payload },
            };
        },
        clear: () => {
            return defaultStore;
        },
    },
    effects: (dispatch) => ({
        /* #region  Search */
        search: async (
            query: Pick<StoriesQuery, 'search'>,
        ) => {
            await dispatch.stories.putQuery(query);
            await dispatch.stories.afterSearch(null);
        },
        afterSearch: debounce((_, state) => {
            dispatch.stories.get(state.stories.query);
        }, 1000),
        /* #endregion */
        /* #region  Stories list for Read and pagination */
        get: async (query: StoriesQuery, store) => {
            // @ts-ignore
            const { query: storeQuery } = store.stories;

            try {
                // @ts-ignore
                const response = await CLIENT.stories.get?.(
                    {
                        ...storeQuery,
                        ...query,
                        is_count: true,
                        //@ts-ignore
                        site: store.site.site?.id || '',
                    },
                    {
                        headers: {
                            'X-Site':
                                store.site.site?.id || '',
                        },
                    },
                );
                if (response?.ok) {
                    // @ts-ignore
                    const { data } = response;
                    dispatch.stories?.putPage(data);
                    return data;
                }
            } catch (e) {
                if (
                    CLIENT.stories.get &&
                    e instanceof CLIENT.stories.get.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },
        nextPage: async (_: any, store) => {
            const { query, currentPage } = store.stories;

            await dispatch.stories.putCurrentPage({
                triggerPage: currentPage + 1,
            });

            await dispatch.stories.get({
                ...query,
                page: currentPage + 1,
            });
        },

        setCurrentPage: async (
            payload: {
                triggerPage: number;
                action: 'next' | 'prev' | 'body';
            },
            store,
        ) => {
            const { query, currentPage } = store.stories;
            const { triggerPage, action } = payload;

            switch (action) {
                case 'next': {
                    const count =
                        store?.stories?.meta?.count || 0;
                    const perPage =
                        store?.stories?.query?.per_page ||
                        1;
                    const isMaxPage =
                        calculatePagesCount(
                            perPage,
                            count,
                        ) <
                        triggerPage + 1;

                    if (isMaxPage) {
                        break;
                    }
                    dispatch.stories.get({
                        ...query,
                        page: triggerPage + 1,
                    });
                    break;
                }

                case 'body': {
                    if (triggerPage !== currentPage) {
                        dispatch.stories.putCurrentPage({
                            triggerPage,
                        });
                        dispatch.stories.get({
                            ...query,
                            page: triggerPage,
                        });
                    }
                    break;
                }

                case 'prev': {
                    if (triggerPage !== 1) {
                        dispatch.stories.get({
                            ...query,
                            page: triggerPage - 1,
                        });
                    }
                    break;
                }

                default:
                    break;
            }
        },
        jumpTo: async (page: number) => {
            dispatch.stories.get({
                page: page,
            });
        },
        chungeCountItemsOnPage: async (
            perPage: number,
            state,
        ) => {
            if (
                Math.floor(
                    state?.stories?.meta.count ??
                        0 / perPage,
                ) > 0
            ) {
                await dispatch.stories.changePerPage({
                    perPage,
                });
                await dispatch.stories.get({});
            }
        },
        /* #endregion */
    }),
});

export { stories };
export default stories;
