import * as CLIENT from '@mega/api';
import {
    Repost,
    RepostCreate,
    RepostUpdate,
} from '@mega/api';
import { createModel } from '@rematch/core';
import { DispatchEffectWithDependency } from '../type';

import type { RootModel } from '../rootModel';

export interface RepostCreateEffect
    extends DispatchEffectWithDependency<RepostCreate> {}

export interface RepostUpdateEffect
    extends DispatchEffectWithDependency<RepostUpdate> {
    query: {
        id: number;
    };
}

const repost = createModel<RootModel>()({
    name: 'repost',
    state: {} as Repost,
    reducers: {
        put: (state, payload: Repost) => {
            return { ...payload };
        },
    },
    effects: (dispatch) => ({
        get: async (params: { id: number }, store) => {
            try {
                const response =
                    await CLIENT.reposts.getWebsiteRepostsById?.(
                        {
                            ...params,
                        },
                    );
                if (response?.ok) {
                    dispatch.repost.put(response.data);
                }
            } catch (e) {
                if (
                    CLIENT.reposts.getWebsiteRepostsById &&
                    e instanceof
                        CLIENT.reposts.getWebsiteRepostsById
                            .Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },
        create: async (
            { payload, options }: RepostCreateEffect,
            store,
        ) => {
            try {
                const response =
                    await CLIENT.reposts?.createWebsiteReposts?.(
                        {
                            ...payload,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    if (options?.router) {
                        const { navigate, location } =
                            options.router;
                        navigate(
                            `/${
                                store.site.site?.id || ''
                            }/repost/change/${
                                response.data.id
                            }`,
                            {
                                state: location,
                            },
                        );
                    }
                    if (options?.notifier) {
                        const { enqueueSnackbar } =
                            options.notifier;
                        enqueueSnackbar('Репост добавлен', {
                            variant: 'success',
                        });
                    }
                }
            } catch (e) {
                if (
                    CLIENT.reposts.createWebsiteReposts &&
                    e instanceof
                        CLIENT.reposts.createWebsiteReposts
                            .Error
                ) {
                    const error = e.getActualType();
                    if (
                        options?.notifier?.enqueueSnackbar
                    ) {
                        const { enqueueSnackbar } =
                            options.notifier;

                        const errormsgs = Object.values(
                            error.data,
                        );

                        errormsgs?.map((msg) => {
                            enqueueSnackbar(msg as string, {
                                variant: 'error',
                            });
                        });
                    }
                    return error.data;
                }
            }
        },
        update: async (
            { query, payload, options }: RepostUpdateEffect,
            store,
        ) => {
            try {
                const response =
                    await CLIENT.reposts?.updatePartialWebsiteRepostsById?.(
                        {
                            ...payload,
                            id: query.id,
                        },
                    );

                if (response?.ok) {
                    dispatch.repost.put(response.data);
                    if (options?.notifier) {
                        const { enqueueSnackbar } =
                            options.notifier;
                        enqueueSnackbar('Репост обновлен');
                    }
                }
            } catch (e) {
                if (
                    CLIENT.reposts
                        .updatePartialWebsiteRepostsById &&
                    e instanceof
                        CLIENT.reposts
                            .updatePartialWebsiteRepostsById
                            .Error
                ) {
                    const error = e.getActualType();
                    if (
                        options?.notifier?.enqueueSnackbar
                    ) {
                        const { enqueueSnackbar } =
                            options.notifier;

                        const errormsgs = Object.values(
                            error.data,
                        );

                        errormsgs?.map((msg) => {
                            enqueueSnackbar(msg as string, {
                                variant: 'error',
                            });
                        });
                    }
                    return error.data;
                }
            }
        },
        remove: async (id: number, store) => {
            const query = store.reposts.query;

            try {
                const response =
                    await CLIENT?.reposts?.deleteWebsiteRepostsById?.(
                        {
                            id,
                        },
                    );
                if (response?.ok) {
                    dispatch.reposts.get(query);
                }
            } catch {}
        },
    }),
});

export { repost };
export default repost;
