import * as Yup from 'yup';

const AdsObj = {
    code: Yup.string(),
    height: Yup.number().required(
        'Введите высоту блока рекламы',
    ),
};

export const validateAds = Yup.object().shape(AdsObj);
