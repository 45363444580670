import React, { ReactElement, useCallback } from 'react';
import {
    msPicker,
    styledCoverWrapper,
} from './MediaLoaderWithMS.css';
import { MediaPicker } from '../MediaPicker';
import {
    FilesObject,
    MediaFormat,
} from '../MediaPickerModal/types';
import { RootState, useStoreSelector } from '@mega/store';

export interface MediaLoaderWithMSProps {
    children: ReactElement;
    type: MediaFormat;
    isHidden?: boolean;
    isMulti?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
}

export interface ExternalPickerProps {
    type: MediaFormat;
    handleSubmit: (files: FilesObject) => void;
    isMulti?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
}

export const ExternalPicker: React.FC<
    ExternalPickerProps
> = (props) => {
    return (
        <div
            className={msPicker({})}
            onClick={(e) => e.stopPropagation()}
        >
            <MediaPicker {...props} />
        </div>
    );
};

export const MediaLoaderWithMS: React.FC<
    MediaLoaderWithMSProps
> = ({
    children,
    isHidden,
    type,
    isMulti,
    onOpen,
    onClose = () => {},
}) => {
    const { config } = useStoreSelector(
        (store: RootState) => store.settings,
    );

    const hasMS = config.article.change['mediastock'];

    const isAvailable = !isHidden && hasMS;

    return (
        <div className={styledCoverWrapper}>
            {React.cloneElement(children, {
                externalBlock: isAvailable ? (
                    <ExternalPicker
                        type={type}
                        isMulti={isMulti}
                        handleSubmit={() => {}}
                        onOpen={onOpen}
                        onClose={onClose}
                    />
                ) : null,
            })}
        </div>
    );
};
