import type {
    GetByIdADSBlock,
    GetByIdADSBlockError,
    GetByIdADSBlockQuery,
    CreateADSBlock,
    CreateADSBlockError,
    CreateADSBlockQuery,
    PartialUpdateADSBlock,
    PartialUpdateADSBlockError,
    PartialUpdateADSBlockQuery,
    DeleteADSBlock,
    DeleteADSBlockError,
    DeleteADSBlockQuery,
} from '@mega/api';
import { isOk } from '@mega/api';
import { createModel } from '@rematch/core';

import type { RootModel } from '../../rootModel';
import {
    getADSBlockById,
    GetADSBlockPromise,
    createADSBlock,
    CreateADSBlockPromise,
    partialUpdateADSBlockById,
    PartialUpdateADSBlockPromise,
    deleteADSBlockById,
    DeleteADSBlockPromise,
} from './adsBlock.api';

const adsBlock = createModel<RootModel>()({
    name: 'adsBlock',
    state: {} as GetByIdADSBlock,
    reducers: {
        put: (state, payload: GetByIdADSBlock) => {
            return { ...payload };
        },
    },
    effects: (dispatch) => ({
        get: async (
            props: { paylaod: GetByIdADSBlockQuery },
            store,
        ): GetADSBlockPromise => {
            const response = await getADSBlockById(
                props.paylaod,
                store.site.site?.id || '',
            );
            if (
                isOk<
                    GetByIdADSBlock,
                    GetByIdADSBlockError | null
                >(response)
            ) {
                dispatch.adsBlock.put(response.value);
                return response;
            }
            return response;
        },
        create: async (
            props: { payload: CreateADSBlockQuery },
            store,
        ): CreateADSBlockPromise => {
            const response = await createADSBlock(
                props.payload,
                store.site.site?.id || '',
            );
            if (
                isOk<
                    CreateADSBlock,
                    CreateADSBlockError | null
                >(response)
            ) {
                dispatch.adsBlock.put(response.value);
                return response;
            }
            return response;
        },
        update: async (
            props: { payload: PartialUpdateADSBlockQuery },
            store,
        ): PartialUpdateADSBlockPromise => {
            const response =
                await partialUpdateADSBlockById(
                    props.payload,
                    store.site.site?.id || '',
                );
            if (
                isOk<
                    PartialUpdateADSBlock,
                    PartialUpdateADSBlockError | null
                >(response)
            ) {
                dispatch.adsBlock.get({
                    paylaod: {
                        id: props.payload.id,
                    },
                });
                return response;
            }
            return response;
        },
        remove: async (
            props: { payload: DeleteADSBlockQuery },
            store,
        ): DeleteADSBlockPromise => {
            const responses = await deleteADSBlockById(
                props.payload,
                store.site.site?.id || '',
            );
            if (
                isOk<
                    DeleteADSBlock,
                    DeleteADSBlockError | null
                >(responses)
            ) {
                return responses;
            }
            return responses;
        },
    }),
});

export { adsBlock };
export default adsBlock;
