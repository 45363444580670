import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { BlockScriptCreate } from '@mega/forms';
import { CreateADSBlockQuery } from '@mega/api';
import {
    useAdsBlockDispatch,
    useSiteStore,
} from '@mega/store';

export const BlockNew: FC<{}> = () => {
    const { create } = useAdsBlockDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const location = useLocation();
    const navigate = useNavigate();

    const site = useSiteStore();

    const handleCreate = async (
        value: CreateADSBlockQuery,
    ) => {
        if (!Boolean(site && site.id)) {
            return enqueueSnackbar(
                'Простите что-то пошло не так, обратитесь пожалуйста в тех поддержку',
                {
                    variant: 'error',
                },
            );
        }

        const response = await create({
            payload: {
                ...value,
            },
        });

        if (response.isOk) {
            navigate(
                `/${site?.id || ''}/block/change/${
                    response.value.id
                }`,
                {
                    state: location,
                },
            );

            enqueueSnackbar('Скрипт <block> создан', {
                variant: 'success',
            });
        } else {
            if (response.value) {
                const errormsgs = Object.values(
                    response.value,
                );

                errormsgs?.map((msg) => {
                    enqueueSnackbar(msg as string, {
                        variant: 'error',
                    });
                });
            }
        }

        return response;
    };

    return (
        <BlockScriptCreate handleCreate={handleCreate} />
    );
};
