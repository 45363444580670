import { definitions } from '@mega/api';

export interface IInitValuesForm {
    id: string;
    title?: string;
    url?: string;
}

export const initValuesForm: IInitValuesForm = {
    id: '',
    title: undefined,
    url: undefined,
};
