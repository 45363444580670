import React, { memo, useCallback, useState } from 'react';
import { Refresh, Copy } from '@mega/icons';
import { articles } from '@mega/api';
import { Loader, Typography } from '@mega/ui';
import { ArticleInfoUnit } from '../ArticleInfoUnit';
import { useField, useFormikContext } from 'formik';
import useSWR from 'swr';
import {
    Accordion,
    Button,
    CheckboxSwitchField,
    IconButton,
    Indi,
    Input,
    InputField,
    InputSelectMultiField,
    Paper,
    Row,
    SelectSingleField,
    Stack,
    WithLabel,
} from '@mega/ui';
import { Settings } from '@mega/icons';
import {
    getOptionsAuthors,
    getOptionsCategories,
    getOptionsRss,
    getOptionsTags,
} from '@mega/core';
import {
    RootState,
    useApostrophDispatch,
    useApostrophStore,
    useArticleData,
    useSiteStore,
    useStoreSelector,
    useUserInfo,
} from '@mega/store';
import { definitions } from '@mega/api';
import { ArticleSuggestedTags } from '../ArticleSuggestedTags';
import { classes } from './ArticleFormSettings.css';
import { useSnackbar } from 'notistack';
import { AnimatePresence, motion } from 'framer-motion';
import { format } from 'date-fns';
const settingsList = [
    {
        name: 'is_autogrid',
        label: 'Автосетка',
    },
    {
        name: 'is_important',
        label: 'Важно',
    },
    {
        name: 'is_exclusive',
        label: 'Эксклюзивна',
    },
    {
        name: 'is_muted',
        label: 'Без рекламы',
    },
    {
        name: 'is_feed_hidden',
        label: 'Не отображать в лентах',
    },
    {
        name: 'is_noindex',
        label: 'Скрыть от поисковиков',
    },
    {
        name: 'show_cover_in_body',
        label: 'Не отображать обложку',
    },
];

const Rewrite = memo(() => {
    const articleData = useArticleData();
    const currentSite = useSiteStore();
    const [rewrite, setRewrite] = useState(
        articleData?.rewrite,
    );
    const { enqueueSnackbar } = useSnackbar();
    const [input, setInput] = useState(rewrite?.url || '');

    const isLoading =
        rewrite?.state === 'waiting' ||
        rewrite?.state === 'processing';

    const isCompleted = rewrite?.state === 'completed';

    useSWR(
        isLoading ? `rewrite/${rewrite?.id}` : null,
        async () => {
            if (!rewrite?.id) {
                return;
            }
            const responce = await articles.getRewriteURL?.(
                {
                    id: rewrite?.id,
                },
                {
                    headers: {
                        'X-Site': currentSite?.id || '',
                    },
                },
            );

            if (responce?.ok) {
                if (
                    JSON.stringify(rewrite) !==
                    JSON.stringify(responce.data)
                ) {
                    setRewrite(responce.data);
                    if (
                        responce.data.state === 'completed'
                    ) {
                        window.location.reload();
                    }
                }
            }
        },
        { refreshInterval: 5000 },
    );

    const createRewrite = async () => {
        try {
            if (!articleData?.id) {
                return;
            }
            const responce =
                await articles.createRewriteURL?.(
                    {
                        id: articleData?.id,
                        url: input,
                    },
                    {
                        headers: {
                            'X-Site': currentSite?.id || '',
                        },
                    },
                );
            if (responce?.ok) {
                setRewrite(responce.data);
                if (responce.data.state === 'completed') {
                    window.location.reload();
                }
            }
        } catch (e) {
            enqueueSnackbar(
                'При генерации рерайта произошла ошибка. Попробуйте отправить запрос снова',
                {
                    variant: 'error',
                },
            );
        }
    };

    const handleCopy = useCallback(() => {
        if (!rewrite?.url) {
            return;
        }
        navigator.clipboard
            .writeText(rewrite?.url)
            .then(() => {
                enqueueSnackbar('URL успешно скопирован', {
                    variant: 'success',
                });
            })
            .catch(() => {
                enqueueSnackbar(
                    'Не удалось скопировать URL',
                    {
                        variant: 'error',
                    },
                );
            });
    }, [rewrite?.url, enqueueSnackbar]);

    return (
        <Paper
            contentHeight
            color={'white'}
            variant="outline"
            classes={{
                padding: {
                    size: '24x24',
                },
            }}
        >
            <Stack gap="8">
                <WithLabel
                    id={'rewrite'}
                    title={
                        isCompleted ? (
                            <span
                                style={{
                                    display: 'flex',
                                    justifyContent:
                                        'space-between',
                                    alignItems: 'center',
                                    gap: '45px',
                                }}
                            >
                                {/* 23.12.2024 16:16 */}
                                <span>Рерайт из: </span>
                                {rewrite.completed_at && (
                                    <span
                                        style={{
                                            fontWeight: 400,
                                            lineHeight:
                                                '140%',
                                        }}
                                    >
                                        {`(выполнен: ${format(
                                            new Date(
                                                rewrite.completed_at,
                                            ),
                                            'dd.MM.yyyy HH:mm',
                                        )})`}
                                    </span>
                                )}
                            </span>
                        ) : (
                            'Рерайт из:'
                        )
                    }
                >
                    <Row gap="8">
                        <Input
                            value={input}
                            onChange={(e) => {
                                setInput(e.target.value);
                            }}
                            dimension="small"
                            type="text"
                            name="rewrite"
                            placeholder="https://..."
                            placeholderSimple
                        />
                        {articleData?.is_active ? (
                            <IconButton
                                size="extra"
                                style={{
                                    backgroundColor:
                                        '#F4F4F4',
                                }}
                                onClick={handleCopy}
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <Loader size="micro" />
                                ) : (
                                    <Copy />
                                )}
                            </IconButton>
                        ) : (
                            <IconButton
                                size="extra"
                                style={{
                                    backgroundColor:
                                        '#3CCFAC',
                                }}
                                onClick={createRewrite}
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <Loader size="micro" />
                                ) : (
                                    <Refresh />
                                )}
                            </IconButton>
                        )}
                    </Row>
                </WithLabel>
                <AnimatePresence>
                    {isLoading ? (
                        <motion.div
                            initial={{
                                opacity: 0,
                                scale: 0,
                            }}
                            animate={{
                                opacity: 1,
                                scale: 1,
                            }}
                            exit={{
                                opacity: 0,
                                scale: 0,
                            }}
                        >
                            <Stack gap="4">
                                <Typography
                                    as={'span'}
                                    size="18"
                                    weight="bold"
                                    textAlign="center"
                                >
                                    Статья создается
                                    {'...'
                                        .split('')
                                        .map(
                                            (
                                                letter,
                                                index,
                                            ) => (
                                                <motion.span
                                                    key={
                                                        index
                                                    }
                                                    initial={{
                                                        opacity: 0,
                                                    }}
                                                    animate={{
                                                        opacity: 1,
                                                    }}
                                                    transition={{
                                                        duration: 1,
                                                        delay:
                                                            index *
                                                            1.2,
                                                        repeat: Infinity,
                                                        // repeatDelay: 0.5,
                                                    }}
                                                >
                                                    {letter}
                                                </motion.span>
                                            ),
                                        )}
                                </Typography>
                                <Typography
                                    as={'span'}
                                    size="12"
                                    weight="regular"
                                    textAlign="center"
                                    style={{
                                        color: '#878787',
                                    }}
                                >
                                    Это займет некоторое
                                    время
                                </Typography>
                            </Stack>
                        </motion.div>
                    ) : null}
                </AnimatePresence>
                <AnimatePresence>
                    {rewrite?.state === 'error' && (
                        <motion.div
                            initial={{
                                opacity: 0,
                                scale: 0,
                            }}
                            animate={{
                                opacity: 1,
                                scale: 1,
                            }}
                            exit={{
                                opacity: 0,
                                scale: 0,
                            }}
                            style={{
                                display: 'flex',
                                padding: '10px',
                                alignItems: 'flex-start',
                                gap: '6px',
                                alignSelf: 'stretch',
                                borderRadius: '4px',
                                border: '1px solid var(--border-alert,#DF4B4B)',
                                background:
                                    'var(--bg-warning-subdued,#FDF8F8)',
                            }}
                        >
                            <Stack gap="4">
                                <Typography
                                    size="14"
                                    weight="bold"
                                >
                                    Ответ от Яндекс GPT:
                                </Typography>
                                <Typography
                                    size="14"
                                    weight="regular"
                                    style={{
                                        color: 'var(--text-alert-secondary, #A36E6E)',
                                    }}
                                >
                                    {rewrite.error}
                                </Typography>
                            </Stack>
                        </motion.div>
                    )}
                </AnimatePresence>
            </Stack>
        </Paper>
    );
});

export const ArticleFormSettings = () => {
    const user = useUserInfo();
    const currentSite = useSiteStore();
    const [{ value }] = useField<boolean>({
        name: 'is_active',
    });
    const { values } = useFormikContext();
    const { config } = useStoreSelector(
        (store: RootState) => store.settings,
    );
    const articleData = useArticleData();
    const isInfoUnit = articleData?.info_unit;

    const permissionRewrite = config.rewrite;
    const hasTelegramPost =
        config.article.change.telegram_post;

    const isCategorySpecialProjects =
        values?.category && values?.category.id === 11;

    const permissionGovContract =
        user.me?.permissions.includes(
            'article.change_is_gov_contract',
        );

    if (
        permissionGovContract &&
        !settingsList.some(
            (el) => el.name === 'is_gov_contract',
        )
    ) {
        settingsList.push({
            name: 'is_gov_contract',
            label: 'Входит в Госконтракт',
        });
    }

    const { enqueueSnackbar } = useSnackbar();

    const copyToClipboard = (text: string) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                enqueueSnackbar('Ссылка скопирована.', {
                    variant: 'default',
                });
            })
            .catch(() => {
                enqueueSnackbar(
                    'Не удалось скопировать ссылку, попробуйте позже ',
                    { variant: 'error' },
                );
            });
    };
    return (
        <Stack gap="16">
            <ArticleInfoUnit />
            {permissionRewrite && !isInfoUnit && (
                <Rewrite />
            )}

            <Paper
                contentHeight
                color={'white'}
                variant="outline"
                classes={{
                    padding: {
                        size: '24x24',
                    },
                }}
            >
                <Stack gap="18">
                    <Row gap="8">
                        <Indi
                            colors={
                                value
                                    ? 'success'
                                    : 'in_progress'
                            }
                            label={
                                value
                                    ? 'Опубликована'
                                    : 'Не опубликована'
                            }
                        />
                        <Button
                            size={'medium'}
                            label="Копировать ссылку"
                            classes={{
                                paper: {
                                    color: 'dark',
                                    variant: 'outline',
                                },
                            }}
                            onClick={() => {
                                copyToClipboard(
                                    values.url || '',
                                );
                            }}
                        />
                    </Row>
                    <Accordion
                        startIcon={<Settings />}
                        label={'Настройки'}
                        value="settings"
                        isOpened
                    >
                        <Stack gap="8">
                            {settingsList.map(
                                (item, id) => {
                                    const { name, label } =
                                        item;

                                    const currentRule =
                                        config.article
                                            .change[
                                            name as keyof definitions['AdminDashboardConfigArticleChange']
                                        ];

                                    const hasRule =
                                        typeof currentRule !==
                                        'undefined';

                                    const isAvailable =
                                        !hasRule ||
                                        (hasRule &&
                                            currentRule);
                                    if (!isAvailable)
                                        return null;
                                    return (
                                        <CheckboxSwitchField
                                            key={id}
                                            name={name}
                                            label={label}
                                        />
                                    );
                                },
                            )}
                        </Stack>
                    </Accordion>
                </Stack>
            </Paper>

            <Paper
                contentHeight
                color={'white'}
                variant="outline"
                classes={{
                    padding: {
                        size: '24x24',
                    },
                }}
            >
                <WithLabel
                    id={'authors'}
                    title="Тип материала"
                >
                    <SelectSingleField
                        name={'article_type'}
                        placeholder={'Тип материала'}
                        handleLoader={async () => [
                            {
                                value: 'article',
                                label: 'Новость',
                            },
                            {
                                value: 'text',
                                label: 'Текст',
                            },
                        ]}
                        classes={{
                            paper: {
                                color: 'dark',
                            },
                            select: {
                                variant: 'secondary',
                            },
                        }}
                    />
                </WithLabel>
            </Paper>
            <Paper
                contentHeight
                color={'white'}
                variant="outline"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                }}
                classes={{
                    padding: {
                        size: '24x24',
                    },
                }}
            >
                <>
                    <WithLabel
                        id={'category'}
                        isRequired
                        title="Рубрика"
                    >
                        <SelectSingleField
                            clearIcon={<></>}
                            isCannotRemovalbe={true}
                            name={'category'}
                            handleLoader={getOptionsCategories(
                                {
                                    site:
                                        currentSite?.id ||
                                        '',
                                },
                            )}
                            classes={{
                                paper: {
                                    color: 'dark',
                                },
                                select: {
                                    variant: 'secondary',
                                },
                            }}
                        />
                    </WithLabel>

                    {isCategorySpecialProjects && (
                        <WithLabel
                            id={'redirect_url'}
                            title="Редирект"
                        >
                            <InputField
                                placeholderBase={() => (
                                    <span
                                        className={
                                            classes.placeholder
                                        }
                                    >
                                        Ссылка на источник
                                    </span>
                                )}
                                type="text"
                                name={'redirect_url'}
                                dimension="small"
                            />
                        </WithLabel>
                    )}
                </>
            </Paper>
            <Paper
                contentHeight
                color={'white'}
                variant="outline"
                classes={{
                    padding: {
                        size: '24x24',
                    },
                }}
            >
                <WithLabel id={'rss'} title="RSS каналы">
                    <InputSelectMultiField
                        classes={{
                            paper: {
                                color: 'dark',
                            },
                            select: {
                                variant: 'secondary',
                            },
                        }}
                        name="rssfeeds"
                        handleLoader={getOptionsRss({
                            site: currentSite?.id || '',
                        })}
                    />
                </WithLabel>
            </Paper>
            <Paper
                contentHeight
                color={'white'}
                variant="outline"
                classes={{
                    padding: {
                        size: '24x24',
                    },
                }}
            >
                <WithLabel id={'tags'} title="Теги">
                    <>
                        <InputSelectMultiField
                            classes={{
                                paper: {
                                    color: 'dark',
                                },
                                select: {
                                    variant: 'secondary',
                                },
                            }}
                            name="tags"
                            handleLoader={getOptionsTags({
                                site: currentSite?.id || '',
                            })}
                        />
                        <ArticleSuggestedTags />
                    </>
                </WithLabel>
            </Paper>
            <Paper
                contentHeight
                color={'white'}
                variant="outline"
                classes={{
                    padding: {
                        size: '24x24',
                    },
                }}
            >
                <WithLabel id={'authors'} title="Авторы">
                    <InputSelectMultiField
                        classes={{
                            paper: {
                                color: 'dark',
                            },
                            select: {
                                variant: 'secondary',
                            },
                        }}
                        name="authors"
                        handleLoader={getOptionsAuthors({
                            site: currentSite?.id || '',
                        })}
                    />
                </WithLabel>
            </Paper>
            <Paper
                contentHeight
                color={'white'}
                variant="outline"
                classes={{
                    padding: {
                        size: '24x24',
                    },
                }}
            >
                <WithLabel
                    id={'telegram_post'}
                    title="Комментарии"
                >
                    <InputField
                        placeholderBase={() => (
                            <span
                                className={
                                    classes.placeholder
                                }
                            >
                                Ссылка на пост в Telegram
                            </span>
                        )}
                        type="text"
                        name={'telegram_post'}
                        dimension="small"
                        disabled={!hasTelegramPost}
                    />
                </WithLabel>
            </Paper>
        </Stack>
    );
};
