/* eslint-disable @typescript-eslint/no-unused-vars */
import { AutoArticle } from './AutoArticle';
import {
    AutoArticleControl,
    AutoArticleEnum,
    AutoArticlePluginDefault,
} from './AutoArticle.types';
import { AutoArticlePlugin } from './AutoArticlePlugin';
import { coverTypeList } from '@pages/smartGrid/constants/data';

const PLUGIN_AUTO_ARTICLE_NAME = AutoArticleEnum.type;

const PLUGIN_META_360_DEFAULT = {
    categories: [],
    tags: [],
    cover: coverTypeList.nocover,
    withInfo: false,
    withLead: false,
};

const PLUGIN_META_RIAMO_DEFAULT = {
    categories: [],
    tags: [],
    cover: coverTypeList.photocover,
    withInfo: true,
    withLead: true,
};

const DEFAULT_360_AUTO_ARTICLE: AutoArticlePluginDefault = {
    type: PLUGIN_AUTO_ARTICLE_NAME,
    meta: PLUGIN_META_360_DEFAULT,
    childrenElement: [],
};

const DEFAULT_RIAMO_AUTO_ARTICLE: AutoArticlePluginDefault =
    {
        type: PLUGIN_AUTO_ARTICLE_NAME,
        meta: PLUGIN_META_RIAMO_DEFAULT,
        childrenElement: [],
    };

const PLUGIN_360_AUTO_ARTICLE = {
    [PLUGIN_AUTO_ARTICLE_NAME]: {
        emptyObject: DEFAULT_360_AUTO_ARTICLE,
        render: AutoArticle,
    },
};

const PLUGIN_RIAMO_AUTO_ARTICLE = {
    [PLUGIN_AUTO_ARTICLE_NAME]: {
        emptyObject: DEFAULT_RIAMO_AUTO_ARTICLE,
        render: AutoArticle,
    },
};

const PLUGIN_360_AUTO_ARTICLE_CONTROL: AutoArticleControl =
    {
        name: PLUGIN_AUTO_ARTICLE_NAME,
        label: 'AutoArticle',
        tooltip: 'Создать автостатью',
        meta: PLUGIN_META_360_DEFAULT,
        render: AutoArticlePlugin,
        Icon: () => <></>,
    };

const PLUGIN_RIAMO_AUTO_ARTICLE_CONTROL: AutoArticleControl =
    {
        name: PLUGIN_AUTO_ARTICLE_NAME,
        label: 'AutoArticle',
        tooltip: 'Создать автостатью',
        meta: PLUGIN_META_RIAMO_DEFAULT,
        render: AutoArticlePlugin,
        Icon: () => <></>,
    };

export {
    DEFAULT_360_AUTO_ARTICLE,
    DEFAULT_RIAMO_AUTO_ARTICLE,
    PLUGIN_360_AUTO_ARTICLE,
    PLUGIN_RIAMO_AUTO_ARTICLE,
    PLUGIN_360_AUTO_ARTICLE_CONTROL,
    PLUGIN_RIAMO_AUTO_ARTICLE_CONTROL,
    PLUGIN_AUTO_ARTICLE_NAME,
};
