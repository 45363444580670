import { useDependencyEditorContext } from '@pages/smartGrid/store/context';

export interface OverlayPreviewProps {
    type: string;
    meta: {
        label: string;
        dataId: string;
    };
}

export const OverlayPreview: React.FC<
    OverlayPreviewProps
> = ({ type, meta }) => {
    const { pluginElements } = useDependencyEditorContext();

    const currentElement =
        type in pluginElements
            ? pluginElements[type]
            : null;

    if (currentElement?.render) {
        const ComponentRender = currentElement.render;

        return (
            <ComponentRender
                id={'preview'}
                type={type}
                // @ts-ignore
                meta={meta}
            />
        );
    }
    return null;
};
