import React, { FC } from 'react';
import { HeadlineProps } from './Headline.types';
import { styles as s } from './Headline.css';
import { useEditor } from '@pages/smartGrid/store/hooks';
import { PluginViewWrapper } from 'src/pages/smartGrid/components/shared/PluginViewWrapper';
import {
    IInitValuesForm,
    initValuesForm,
} from './HeadlineSettings/HeadlineForm/config/config';
import { GridSettings } from '@pages/smartGrid/components/shared/GridSettings';
import { HeadlineSettings } from './HeadlineSettings/HeadlineSettings';
import EastIcon from '@mui/icons-material/East';
import { Tooltip } from '@mui/material';

const Headline: FC<HeadlineProps> = (props) => {
    const { id, parentId, type } = props;

    const { handleUpdateElement, getCurrentDataElement } =
        useEditor();
    const data = getCurrentDataElement(id);

    const values = { ...initValuesForm, ...data?.meta, id };

    const onSubmit = (values: IInitValuesForm) => {
        handleUpdateElement({
            id,
            type,
            childrenElement: data.childrenElement,
            meta: values as Record<string, any>,
        });
    };

    if (!data && id !== 'preview') return null;
    return (
        <PluginViewWrapper
            id={id}
            parentId={parentId}
            title={'Заголовок'}
            render={(isEdit: any, setIsEdit: any) => {
                return (
                    <GridSettings
                        isOpen={isEdit}
                        setIsOpen={setIsEdit}
                        title={'Заголовок - настройка'}
                        render={({ handleClose }) => {
                            return (
                                <HeadlineSettings
                                    initialValues={values}
                                    onSave={(
                                        values: IInitValuesForm,
                                    ) => {
                                        onSubmit(values);
                                        handleClose();
                                    }}
                                />
                            );
                        }}
                    />
                );
            }}
        >
            {values.title && (
                <div className={s.wrapper({})}>
                    <div
                        className={`${s.textField({})} ${
                            s.content
                        }`}
                    >
                        <div className={s.title}>
                            {values.title}
                            {values.url && (
                                <a
                                    className={s.titleLink}
                                    target="_blank"
                                    href={values.url}
                                    rel="noreferrer"
                                >
                                    <Tooltip
                                        title={values.url}
                                    >
                                        <EastIcon />
                                    </Tooltip>
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </PluginViewWrapper>
    );
};

export { Headline };
