import React, { FC, useState } from 'react';
import { Form, Formik, useFormikContext } from 'formik';

import * as Yup from 'yup';
import { RepostCreate as RepostCreateType } from '@mega/api';
import {
    Button,
    FormLayout,
    InputField,
    Paper,
    Stack,
} from '@mega/ui';

export interface RepostCreateProps {
    initialValue?: RepostCreateType;
    handleCreate?: (values: RepostCreateType) => any;
}

const RepostCreate: FC<RepostCreateProps> = ({
    initialValue = {
        link: '',
    },
    handleCreate = () => null,
}) => {
    const [isCreated, setIsCreated] = useState(false);

    const handleSubmit = async (
        values: RepostCreateType,
    ) => {
        if (!isCreated) {
            const responce = await handleCreate(values);

            if (responce.isOk) {
                setIsCreated(true);
            }

            return;
        }
        return;
    };

    return (
        <Formik
            validationSchema={Yup.object({
                link: Yup.string()
                    .min(
                        2,
                        'Это поле явно должно быть больше одного символа',
                    )
                    .required('Это поле обязательное'),
            })}
            initialValues={initialValue}
            onSubmit={handleSubmit}
        >
            <Form>
                <FormLayout
                    actions={<RepostCreateActions />}
                >
                    <Paper
                        variant="outline"
                        color="white"
                        classes={{
                            padding: { size: '24x24' },
                        }}
                    >
                        <Stack gap="18">
                            <InputField
                                type="text"
                                name={'link'}
                                placeholder="Ссылка"
                                dimension="medium"
                                isRequired
                            />
                        </Stack>
                    </Paper>
                </FormLayout>
            </Form>
        </Formik>
    );
};

const RepostCreateActions = () => {
    const { submitForm, isSubmitting } =
        useFormikContext<RepostCreateType>();

    return (
        <Paper
            style={{
                borderTop: '1px solid #EAEAEA',
            }}
            fullWidth
            color={'white'}
            variant="filled"
            classes={{
                padding: { size: '24x24' },
            }}
        >
            <Button
                classes={{
                    paper: {
                        variant: 'filled',
                        color: 'dark',
                    },
                }}
                color="secondary"
                size={'large'}
                type={'submit'}
                label={'Создать'}
                onClick={submitForm}
                disabled={isSubmitting}
            />
        </Paper>
    );
};

export { RepostCreate };
