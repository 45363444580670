import React, { useState } from 'react';
import {
    styledVideo,
    videoView,
    videoWrapper,
} from './VideoPreview.css';
import { VideoPoster } from './VideoPoster';

export interface VideoPreviewProps {
    videoSrc: string;
    imgSrc?: string;
    title?: string;
}

export const VideoPreview: React.FC<VideoPreviewProps> = (
    props,
) => {
    const { videoSrc, imgSrc = '', title } = props;
    const [isStarted, setIsStarted] = useState(false);
    return (
        <div className={videoWrapper}>
            {!isStarted ? (
                <VideoPoster
                    img={imgSrc}
                    title={title}
                    onClick={() => setIsStarted(true)}
                />
            ) : (
                <div className={videoView}>
                    <video
                        className={styledVideo}
                        src={videoSrc}
                        controls
                        autoPlay
                        poster={imgSrc}
                    />
                </div>
            )}
        </div>
    );
};
