/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Control } from '../../../../types/core';
import { Themes } from './Themes';
import {
    ThemesEnum,
    ThemesPluginDefault,
} from './Themes.types';

const PLUGIN_THEMES_NAME = ThemesEnum.type;

const PLUGIN_META_DEFAULT = {
    dataId: '',
    label: '',
    categories: [],
    tags: [],
};
const DEFAULT_THEMES: ThemesPluginDefault = {
    type: PLUGIN_THEMES_NAME,
    meta: PLUGIN_META_DEFAULT,
    childrenElement: [],
};

const PLUGIN_THEMES = {
    [PLUGIN_THEMES_NAME]: {
        emptyObject: DEFAULT_THEMES,
        render: Themes,
    },
};

const PLUGIN_THEMES_CONTROL: Control = {
    name: PLUGIN_THEMES_NAME,
    label: 'Themes',
    tooltip: 'Создать тему',
    meta: PLUGIN_META_DEFAULT,
    Icon: () => <></>,
};

export {
    DEFAULT_THEMES,
    PLUGIN_THEMES,
    PLUGIN_THEMES_CONTROL,
    PLUGIN_THEMES_NAME,
};
