import React, { FC, PropsWithChildren } from 'react';
import { components, OptionProps } from 'react-select';

// import { base, active } from '@mega/styles';
import { selectSingleClasses } from '../../SelectSingle.css';

interface OptionClass {
    className?: string;
}

type OptionOverideProps = OptionProps<
    Record<string, unknown>,
    true
>;

const Option =
    ({ className = '' }: OptionClass) =>
    ({
        children,
        ...props
    }: PropsWithChildren<OptionOverideProps>) => {
        const { isSelected, isFocused } = props;

        return (
            <components.Option
                className={[
                    className,
                    selectSingleClasses.option({
                        active: isSelected,
                    }),
                    selectSingleClasses.focus({
                        isFocus: isFocused,
                    }),
                ].join(' ')}
                {...props}
            >
                {children}
            </components.Option>
        );
    };

export { Option };
export default Option;
