import React, { Component, PropsWithChildren } from 'react';
import Select, {
    components,
    ValueContainerProps,
} from 'react-select';

interface ValueContainerClass {
    className?: string;
}

type ValueContainerTestProps = ValueContainerProps<
    Record<string, unknown>,
    true
>;

const ValueContainer =
    ({ className = '' }: ValueContainerClass) =>
    ({
        children,
        ...props
    }: PropsWithChildren<ValueContainerTestProps>) =>
        (
            <components.ValueContainer
                className={className}
                {...props}
            >
                {children}
            </components.ValueContainer>
        );

export { ValueContainer };
export default ValueContainer;
