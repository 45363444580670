import { FC } from 'react';
import { StoriesProps } from './Stories.types';
import { pluginStyles as s } from './Stories.css';
import { Dropdown } from 'src/pages/smartGrid/components/shared/Dropdown';
import {
    useMainGridStore,
    useStoriesDispatch,
} from '@mega/store';
import { DEFAULT_STORIES } from './Stories.default';

const StoriesPlugin: FC<StoriesProps> = ({}) => {
    const { get } = useStoriesDispatch();

    const store = useMainGridStore();

    const handleLoader = async (
        inputValue?: string,
        pageNumber?: number,
    ) => {
        const responce = await get({
            search: inputValue,
            page: pageNumber,
            per_page: 20,
        });

        if (responce?.results.length) {
            return (
                responce.results?.map((item: any) => ({
                    label: item?.title || '',
                    value: item?.id || '',
                })) ?? []
            );
        } else {
            return [];
        }
    };

    const usedIdList = store.itemsList.CONTENT_STORIES;

    return (
        <div className={s.wrapper({})}>
            <Dropdown
                meta={DEFAULT_STORIES.meta}
                type={DEFAULT_STORIES.type}
                title={'Истории'}
                usedIdList={usedIdList}
                getItemsLoader={handleLoader}
            />
        </div>
    );
};

export { StoriesPlugin };
