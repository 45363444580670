import React, { FC, PropsWithChildren } from 'react';
import { components, OptionProps } from 'react-select';
import { inputSelectMultiClasses } from '../../InputSelectMulti.css';

interface OptionClass {
    className?: string;
}

type OptionTestProps = OptionProps<
    Record<string, unknown>,
    true
>;

const Option =
    ({ className = '' }: OptionClass) =>
    ({
        children,
        ...props
    }: PropsWithChildren<OptionTestProps>) => {
        const { isSelected, isFocused } = props;
        return (
            <components.Option
                {...props}
                className={[
                    className,
                    inputSelectMultiClasses.optionMulti({
                        active: isSelected,
                    }),
                    inputSelectMultiClasses.optionFocus({
                        isFocus: isFocused,
                    }),
                ].join(' ')}
            >
                {children}
            </components.Option>
        );
    };

export { Option };
export default Option;
