import { createModel } from '@rematch/core';
import * as CLIENT from '@mega/api';
import type {
    InfounittaskArray,
    InfounittaskQuery,
    InfounittaskWithPagination,
} from '@mega/api';

import { debounce, isUndefined } from 'lodash-es';

import type { RootModel } from '../rootModel';
import { stringify } from 'query-string';
import { DefaultStore } from '../type';
import { calculatePagesCount } from '@mega/utils';

const urlParams = new URLSearchParams(location.search);

const defaultStore: DefaultStore<
    InfounittaskArray,
    InfounittaskQuery
> = {
    currentPage: 1,
    query: {
        per_page: 30,
    },
    meta: {
        count: null,
    },
    pages: {},
    hasMore: false,
};

const infounittasks = createModel<RootModel>()({
    name: 'infounittasks',
    state: defaultStore,
    reducers: {
        changePerPage: (
            state,
            payload: { perPage: number },
        ) => {
            return { ...state, perPage: payload.perPage };
        },
        putCurrentPage: (
            state,
            payload: { triggerPage: number },
        ) => {
            return {
                ...state,
                currentPage: payload.triggerPage,
            };
        },
        putPage: (
            state,
            payload: InfounittaskWithPagination,
        ) => {
            return {
                ...state,
                meta: {
                    count: isUndefined(payload.count)
                        ? null
                        : payload.count,
                },
                pages: {
                    ...state.pages,
                    [`${state.currentPage}`]:
                        payload.results,
                },
            };
        },
        removeElementByInfoUnitIdFromPage(
            state,
            payload: { id: string | number },
        ) {
            const pages = Object.values(state.pages);
            const filtredPages = pages
                .map((page) =>
                    page.filter((element) => {
                        return (
                            element.info_unit.id !==
                            payload.id
                        );
                    }),
                )
                .reduce((acc, page, index) => {
                    return {
                        ...acc,
                        [index + 1]: page,
                    };
                }, {});

            return { ...state, pages: { ...filtredPages } };
        },
        putQuery: (state, payload: InfounittaskQuery) => {
            return {
                ...state,
                query: { ...state.query, ...payload },
            };
        },
        clear: () => {
            return defaultStore;
        },
    },

    effects: (dispatch) => ({
        updateUrl: async (_, state) => {
            const query = state.infounittasks.query;

            const queryString = stringify(query);
            window.history.replaceState(
                null,
                '',
                `?${queryString}`,
            );
        },
        search: async (query: InfounittaskQuery) => {
            await dispatch.infounittasks.putQuery({
                ...query,
                page: 1,
            });
            await dispatch.infounittasks.putCurrentPage({
                triggerPage: 1,
            });
            await dispatch.infounittasks.afterSearch(null);
        },
        afterSearch: debounce((_, state) => {
            dispatch.infounittasks.get(
                state.infounittasks.query,
            );
            dispatch.infounittasks.updateUrl({});
        }, 1000),
        createArticle: async (id: string, store) => {
            try {
                const response =
                    await CLIENT.infounittask.createFromInfoUnitTask?.(
                        { info_unit_task_id: id },
                    );
                if (response?.ok) {
                    dispatch.infounittasks.get(
                        store.infounittasks.query,
                    );
                    return {
                        isOk: true,
                        data: response.data,
                    };
                }
            } catch (e) {
                if (
                    CLIENT.articles.create &&
                    e instanceof
                        CLIENT.articles.create.Error
                ) {
                    const error = e.getActualType();
                    return {
                        isOk: false,
                        error: error,
                    };
                }
            }
        },
        rejectInfoUnit: async (data, store) => {
            const { id, smi_id, rejection_comment, page } =
                data;
            try {
                const response =
                    await CLIENT.infounittask.rejectInfoUnitTask?.(
                        {
                            id,
                            site: smi_id,
                            rejection_comment:
                                rejection_comment ||
                                undefined,

                            //TODO: спросить что  с этим полем не так
                            //@ts-ignore
                            is_rejected: true,
                        },
                    );
                if (response?.ok) {
                    dispatch.infounittasks.removeElementByInfoUnitIdFromPage(
                        {
                            id: id,
                        },
                    );
                    dispatch.infounittasks.get({
                        ...store.infounittasks.query,
                        page: page,
                    });
                    return {
                        isOk: true,
                        data: response.data,
                    };
                }
            } catch (e) {
                if (
                    CLIENT.articles.create &&
                    e instanceof
                        CLIENT.articles.create.Error
                ) {
                    const error = e.getActualType();
                    return {
                        isOk: false,
                        error: error,
                    };
                }
            }
        },
        get: async (query: InfounittaskQuery, store) => {
            const { query: storeQuery } =
                store.infounittasks;

            try {
                const response =
                    await CLIENT.infounittask.getTasks?.(
                        {
                            ...storeQuery,
                            ...query,
                            is_count: true,
                            page: store.infounittasks
                                .currentPage,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    const { data } = response;

                    const wait =
                        await dispatch.infounittasks?.putPage(
                            data,
                        );
                }
            } catch (e) {}
        },
        nextPage: async (_: any, store) => {
            const { query, currentPage } =
                store.infounittasks;

            await dispatch.infounittasks.putCurrentPage({
                triggerPage: currentPage + 1,
            });
            await dispatch.infounittasks.get({
                ...query,
                page: currentPage + 1,
            });
        },
        setCurrentPage: async (
            payload: {
                triggerPage: number;
                action: 'next' | 'prev' | 'body';
            },
            store,
        ) => {
            const { query, currentPage } =
                store.infounittasks;
            const { triggerPage, action } = payload;

            switch (action) {
                case 'next': {
                    const count =
                        store?.infounittasks?.meta?.count ||
                        0;
                    const perPage =
                        store?.infounittasks?.query
                            ?.per_page || 1;
                    const isMaxPage =
                        calculatePagesCount(
                            perPage,
                            count,
                        ) <
                        triggerPage + 1;

                    if (isMaxPage) {
                        break;
                    }

                    dispatch.infounittasks.get({
                        ...query,
                        page: triggerPage + 1,
                    });
                    break;
                }

                case 'body': {
                    if (triggerPage !== currentPage) {
                        dispatch.infounittasks.putCurrentPage(
                            {
                                triggerPage,
                            },
                        );
                    }

                    break;
                }

                case 'prev': {
                    if (triggerPage !== 1) {
                        dispatch.infounittasks.get({
                            ...query,
                            page: triggerPage - 1,
                        });
                    }
                    break;
                }

                default:
                    break;
            }
        },

        jumpTo: async (page: number) => {
            await dispatch.infounittasks.putQuery({
                page: page,
            });
            await dispatch.infounittasks.putCurrentPage({
                triggerPage: page,
            });
            dispatch.infounittasks.get({
                page: page,
            });
        },
        chungeCountItemsOnPage: async (
            perPage: number,
            state,
        ) => {
            if (
                Math.floor(
                    state?.infounittasks?.meta.count ??
                        0 / perPage,
                ) > 0
            ) {
                await dispatch.infounittasks.changePerPage({
                    perPage,
                });
                await dispatch.infounittasks.get({});
            }
        },

        /* #endregion */
        /* #region  Articles list for Read and pagination */
        /* #endregion */
    }),
});

export { infounittasks };
export default infounittasks;
