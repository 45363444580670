/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Control } from '../../../../types/core';
import { Second } from './Second';
import {
    SecondEnum,
    SecondPluginDefault,
} from '@pages/smartGrid/components/Plugins/Templates/Second/Second.types';

const PLUGIN_SECOND_TEMPLATE_NAME = SecondEnum.type;

const DEFAULT_SECOND_TEMPLATE: SecondPluginDefault = {
    type: PLUGIN_SECOND_TEMPLATE_NAME,
    meta: {},
    isContainer: true,
    childrenElement: [],
};

const PLUGIN_SECOND_TEMPLATE = {
    [PLUGIN_SECOND_TEMPLATE_NAME]: {
        emptyObject: DEFAULT_SECOND_TEMPLATE,
        render: Second,
    },
};

const PLUGIN_SECOND_TEMPLATE_CONTROL: Control = {
    name: PLUGIN_SECOND_TEMPLATE_NAME,
    label: 'SECOND_TEMPLATE Template',
    tooltip: 'Создать Flow Stack',
    Icon: () => <></>,
};

export {
    DEFAULT_SECOND_TEMPLATE,
    PLUGIN_SECOND_TEMPLATE,
    PLUGIN_SECOND_TEMPLATE_CONTROL,
    PLUGIN_SECOND_TEMPLATE_NAME,
};
