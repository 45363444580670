import React, { Fragment, useState } from 'react';
import { toolsStyles as s } from './GridTools.css';
import { DraggableContainer } from '../shared/DraggableContainer';
import { useEditor } from '../../store/hooks';
import { Typography } from '@mega/ui';
import { useDndContext } from '@dnd-kit/core';

export interface GridToolsProps {}

const RenderTools: React.FC<{ type: string }> = (props) => {
    const { type } = props;
    const { getControlsByType } = useEditor();
    const controls = getControlsByType(type);

    const dndContext = useDndContext();
    const activeElementId = dndContext.active?.id;

    return (
        <div className={s.list}>
            {controls.map((item, idx) => {
                const Render = item.render || null;
                return (
                    <Fragment key={`${item.name}_${idx}`}>
                        {Render ? (
                            <Render />
                        ) : (
                            <DraggableContainer
                                meta={item.meta}
                                type={item.name}
                            >
                                <div
                                    className={s.listItem({
                                        isDragging:
                                            activeElementId ===
                                            item.name,
                                    })}
                                >
                                    <Typography
                                        size={'16'}
                                        weight={'medium'}
                                    >
                                        {item.label ||
                                            item.name}
                                    </Typography>
                                </div>
                            </DraggableContainer>
                        )}
                    </Fragment>
                );
            })}
        </div>
    );
};
export const GridTools: React.FC<GridToolsProps> = (
    props,
) => {
    const {} = props;

    const { getControlsList } = useEditor();
    const controlsList = getControlsList();

    const [activeType, setActiveType] = useState(
        controlsList[0].name || '',
    );

    return (
        <div className={s.wrapper}>
            <ul className={s.navWrapper}>
                {controlsList.map((item) => {
                    return (
                        <li
                            className={s.navItem({
                                isActive:
                                    item.name ===
                                    activeType,
                            })}
                            key={item.name}
                            onClick={() =>
                                setActiveType(item.name)
                            }
                        >
                            <Typography size={'14'}>
                                {item.label}
                            </Typography>
                        </li>
                    );
                })}
            </ul>
            <RenderTools type={activeType} />
        </div>
    );
};
