import { createSelector } from 'reselect';
import { RootState, useStoreSelector } from '../../models';

const selectApiInitializing = (state: RootState) =>
    state.loading.effects.auth.stageInit.loading;

const selectMeInitializing = (state: RootState) =>
    state.loading.effects.user.getMe.loading;

const selectAppInitializing = createSelector(
    selectApiInitializing,
    selectMeInitializing,
    (isApiInitializing, isMeInitializing) => {
        return {
            isApiInitializing,
            isMeInitializing,
            isAppInitializing:
                isApiInitializing || isMeInitializing,
        };
    },
);

// please use this hook for only suitable progress processing
const useAppIntializingInfo = () => {
    const initializingProgressInfo = useStoreSelector(
        selectAppInitializing,
    );
    return initializingProgressInfo;
};

export { useAppIntializingInfo };
