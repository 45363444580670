import React, { useEffect, useRef, useState } from 'react';
import { fileViewerClasses } from '../FileViewer.css';
import { motion } from 'framer-motion';
import { PlayCircle } from '@mega/icons';
import * as CLIENT from '@mega/api';
import { Loader } from '@mega/ui';

interface ViewProps {
    isVideo?: boolean;
    fileSrc?: string;
    description?: string;
    videoPoster?: string;
}

const { getMediafile } =
    CLIENT.mediashareApiClient.Mediashare();
export const View: React.FC<ViewProps> = (props) => {
    const { isVideo, fileSrc, videoPoster, description } =
        props;

    const [isPlay, setIsPlay] = useState(false);

    const vidRef = useRef<HTMLVideoElement>(null);
    const handlePlayVideo = () => {
        setIsPlay(true);
    };

    useEffect(() => {
        if (isPlay) {
            vidRef.current && vidRef.current.play();
        }
    }, [isPlay]);

    const isMediashare = fileSrc?.includes('mediashare');
    const isJWT = isMediashare && fileSrc;

    const [jwtSrc, setJwtSrc] = useState('');

    const getFileBlob = async () => {
        if (fileSrc) {
            const res = await getMediafile({
                src: fileSrc,
            });
            setJwtSrc(res);
        }
    };

    useEffect(() => {
        setIsPlay(false);
    }, [fileSrc]);

    // TODO добавить сигнал аборта для оптимизации
    useEffect(() => {
        if (!jwtSrc && isJWT) getFileBlob();
    }, []);

    const currentSrc = isJWT ? jwtSrc : fileSrc;

    if (!jwtSrc && isJWT)
        return (
            <div className={fileViewerClasses.mediaWrapper}>
                <Loader size="small" />
            </div>
        );

    if (isVideo) {
        return (
            <div className={fileViewerClasses.videoWrapper}>
                <video
                    ref={vidRef}
                    src={currentSrc}
                    className={fileViewerClasses.image}
                    muted
                    controls
                    poster={videoPoster || ''}
                    autoPlay={false}
                />
                {!isPlay && videoPoster && (
                    <>
                        <img
                            className={
                                fileViewerClasses.videoPreview
                            }
                            src={videoPoster}
                            alt={description || 'preview'}
                        />
                        <div
                            className={
                                fileViewerClasses.playButton
                            }
                        >
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                transition={{
                                    duration: 0.2,
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    videoPoster &&
                                        handlePlayVideo();
                                }}
                            >
                                <PlayCircle />
                            </motion.div>
                        </div>
                    </>
                )}
            </div>
        );
    }

    return (
        <>
            <img
                className={fileViewerClasses.image}
                src={currentSrc}
                alt={description || 'cover'}
            />
        </>
    );
};
