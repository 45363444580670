import React, { FC, ReactElement } from 'react';

import { Paper } from '@mega/ui';
import { Typography, Stack } from '../../utility';

export interface HeadProps {
    label?: string;
    breadcrumbs?: () => ReactElement;
}

const HeadTemplate: FC<HeadProps> = ({
    label,
    breadcrumbs,
}) => (
    <Paper variant="filled">
        <Stack gap="8">
            {breadcrumbs?.()}
            <Typography size="24" weight="medium">
                {label}
            </Typography>
        </Stack>
    </Paper>
);

export { HeadTemplate };
