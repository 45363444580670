/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { FC, useMemo } from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { reports, SourcesReport } from '@mega/api';
import {
    Accordion,
    Button,
    CheckboxSwitchField,
    FormLayout,
    InputField,
    Paper,
    Stack,
} from '@mega/ui';
import { CreatebleSelectField } from '@mega/core';
import { throttle } from 'lodash-es';

interface SourcesReportProps {
    initialValue?: SourcesReport;
    handleUpdate?: (value: SourcesReport) => void;
}

const TagEditActions = () => {
    const { submitForm, isSubmitting } = useFormikContext();
    const throttledSubmit = useMemo(
        () => throttle(submitForm, 1000),
        [],
    );

    return (
        <>
            <Paper
                style={{
                    borderTop: '1px solid #EAEAEA',
                }}
                fullWidth
                color={'white'}
                variant="filled"
                classes={{
                    padding: { size: '24x24' },
                }}
            >
                <Button
                    size={'large'}
                    type={'submit'}
                    label={'Обновить'}
                    onClick={throttledSubmit}
                    classes={{
                        paper: {
                            color: 'dark',
                            variant: 'filled',
                        },
                    }}
                />
            </Paper>
        </>
    );
};

const SourcesReportEdit: FC<SourcesReportProps> = ({
    handleUpdate = () => null,
    initialValue = { title: '', codes: [''] },
}) => {
    return (
        <Formik
            validationSchema={Yup.object({
                title: Yup.string()
                    .min(
                        2,
                        'Это поле явно должно быть больше одного символа',
                    )
                    .required('Это поле обязательное'),
                codes: Yup.array()
                    .min(1, 'Это поле обязательноe')
                    .required('Это поле обязательноe'),
            })}
            initialValues={{
                ...initialValue,
            }}
            onSubmit={handleUpdate}
        >
            <Form
                style={{
                    minHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <FormLayout
                    actions={<TagEditActions />}
                    settings={
                        <Paper
                            contentHeight
                            color={'white'}
                            variant="outline"
                            classes={{
                                padding: {
                                    size: '24x24',
                                },
                            }}
                        >
                            <Stack gap="18">
                                <Accordion
                                    label={'Настройки'}
                                    value="settings"
                                    isOpened
                                >
                                    <Stack gap="16">
                                        <Stack gap="8">
                                            <CheckboxSwitchField
                                                name="is_active"
                                                label="Активно"
                                            />
                                        </Stack>
                                    </Stack>
                                </Accordion>
                            </Stack>
                        </Paper>
                    }
                >
                    <Paper
                        variant="outline"
                        color="white"
                        classes={{
                            padding: {
                                size: '24x24',
                            },
                        }}
                    >
                        <Stack gap="18">
                            <InputField
                                type="text"
                                name={'title'}
                                placeholder="Название"
                                dimension="medium"
                                isRequired
                            />
                            <CreatebleSelectField
                                name={'codes'}
                                placeholder="Домен"
                            />
                        </Stack>
                    </Paper>
                </FormLayout>
            </Form>
        </Formik>
    );
};

export { SourcesReportEdit };
