import React from 'react';
import { imgWrapper, styledImg } from './ImagePreview.css';

export interface VideoPreviewProps {
    src: string;
    title?: string;
}

export const ImagePreview: React.FC<VideoPreviewProps> = (
    props,
) => {
    const { src, title } = props;
    return (
        <div className={imgWrapper}>
            <img
                className={styledImg}
                src={src}
                alt={title || ''}
            />
        </div>
    );
};
