import React, { FC, useMemo, useEffect } from 'react';

import { CellValue, Column } from 'react-table';
import type { InfounittaskArray as InfoUnitType } from '@mega/api';
import {
    useInfounittasksDispatch,
    useInfounittasksStore,
} from '@mega/store';

import { ListingTable } from '@mega/ui';
import { typographyClasses } from '@mega/styles';
import { headItem, listingRecipe } from '../Listing.css';

import { ReplacePerformer } from './ReplacePerformer';
import { ActionsCell } from './ActionsCell';
import { TitleCell } from './TitleCell';
import { Urgent } from './UrgentCell';
import { isString, compact } from 'lodash-es';
export interface ListingInfoUnitProps {}

const ListingInfoUnit: FC<ListingInfoUnitProps> = () => {
    const store = useInfounittasksStore();
    const { clear } = useInfounittasksDispatch();

    useEffect(() => {
        return () => {
            clear();
        };
    }, [clear]);

    const data = useMemo(() => {
        if (store.pages[store.currentPage]) {
            const values =
                store.pages[store.currentPage].map(
                    (item) => {
                        return {
                            ...item,
                            page: store.currentPage,
                        };
                    },
                ) || [];
            return values;
        } else {
            return [];
        }
    }, [store.pages]);

    const columns: Column<InfoUnitType>[] =
        React.useMemo(() => {
            const { is_rejected: isRejected = false } =
                store.query;
            const isRejectedParse = isString(isRejected)
                ? isRejected === 'true'
                    ? true
                    : false
                : isRejected;
            return compact([
                {
                    Header: 'Заголовок',
                    accessor: (row) => {
                        return row;
                    },
                    minWidth: 200,
                    width: '100%',
                    Cell: ({ value }: CellValue) => {
                        return <TitleCell {...value} />;
                    },
                },
                {
                    Header: 'Срочность',
                    accessor: (row) => row.info_unit,
                    minWidth: 100,
                    width: '100%',
                    maxWidth: 220,
                    Cell: ({ value }: CellValue) => {
                        return <Urgent center {...value} />;
                    },
                },
                {
                    Header: 'Источник',
                    accessor: (row) => row.info_unit.source,
                    minWidth: 100,
                    width: '100%',
                    maxWidth: 220,
                    Cell: ({ value }: CellValue) => {
                        return (
                            <div
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                <span>{value}</span>
                            </div>
                        );
                    },
                },
                isRejectedParse && {
                    Header: 'Причина отказа',
                    accessor: (row) => {
                        return row.rejection_comment;
                    },
                    minWidth: 100,
                    width: '100%',
                    maxWidth: 200,
                    Cell: ({ value }: CellValue) => {
                        return (
                            <div
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                {value}
                            </div>
                        );
                    },
                },
                !isRejectedParse && {
                    Header: 'Действия',
                    accessor: (row) => {
                        return {
                            article: row.article,
                            id: row.id,
                            site: row.site,
                            info_unit: row.info_unit,
                            user: row.user,
                            page: row.page,
                        };
                    },
                    minWidth: 80,
                    width: '100%',
                    maxWidth: 100,
                    Cell: ({ value }: CellValue) => {
                        return <ActionsCell {...value} />;
                    },
                },
                !isRejectedParse && {
                    Header: 'Исполнитель',
                    accessor: (row) => {
                        return {
                            user: row.user,
                            info_unit: row.info_unit,
                            id: row.id,
                        };
                    },
                    Cell: ({ value }: CellValue) => {
                        return (
                            <ReplacePerformer {...value} />
                        );
                    },
                },
            ]);
        }, [store.query.is_rejected]);

    return (
        <>
            <div>
                <ListingTable
                    getHeaderGroupProps={(cellInfo) => {
                        return {
                            className: listingRecipe({
                                header: true,
                            }),
                        };
                    }}
                    getHeaderProps={(cellInfo) => {
                        return {
                            className: headItem({
                                title:
                                    cellInfo?.id ===
                                    'Заголовок',
                            }),
                        };
                    }}
                    getRowGroupProps={(row) => {
                        return {
                            className: [
                                typographyClasses.recipe({
                                    size: '14',
                                }),
                                listingRecipe({
                                    header: false,
                                }),
                            ].join(' '),
                        };
                    }}
                    columns={columns}
                    data={data}
                />
            </div>
        </>
    );
};

export { ListingInfoUnit };
