import { FC, Fragment, useState } from 'react';
import {
    CardNavigation,
    DashboardLayout,
    IconButton,
} from '@mega/ui';
import { useLocation, useNavigate } from 'react-router';
import { FiPlus } from 'react-icons/fi';
import { useSettings, useUserInfo } from '@mega/store';
import { FormatListBulleted as NewsIcon } from '@mega/icons';

const CardNavigationFeature: FC<{
    label: string;
    entity: string;
}> = ({ label = '', entity = '' }) => {
    const [buttonIsHover, setButtonHover] = useState(false);
    let navigate = useNavigate();
    let location = useLocation();

    return (
        <CardNavigation
            label={label}
            icon={<NewsIcon style={{ fontSize: '48px' }} />}
            onClick={() => {
                if (!buttonIsHover) {
                    navigate(entity, {
                        state: location,
                    });
                }
            }}
            button={
                <IconButton
                    // color="secondary"
                    // variant="filled"
                    classes={{
                        paper: {
                            variant: 'filled',
                            color: 'dark',
                        },
                    }}
                    onHoverStart={() =>
                        setButtonHover(true)
                    }
                    onHoverEnd={() => setButtonHover(false)}
                    onClick={() => {
                        navigate(`${entity}/add`, {
                            state: location,
                        });
                    }}
                >
                    <FiPlus></FiPlus>
                </IconButton>
            }
        />
    );
};

const Dashboard = () => {
    const { rootPermissions } = useUserInfo();

    const { permissionConfig } = useSettings();

    return (
        <DashboardLayout>
            {rootPermissions.map((permission) => {
                if (!permissionConfig?.[permission]) {
                    return <Fragment key={permission} />;
                }
                return (
                    <CardNavigationFeature
                        key={permission}
                        label={
                            permissionConfig?.[
                                permission
                            ]?.['verbose_name_plural'] ||
                            ' '
                        }
                        entity={permission}
                    />
                );
            })}
        </DashboardLayout>
    );
};

export { Dashboard };
