import * as CLIENT from '@mega/api';
import type {
    GetByIdInfiniteMix,
    GetByIdInfiniteMixError,
    GetByIdInfiniteMixQuery,
    CreateInfiniteMix,
    CreateInfiniteMixError,
    CreateInfiniteMixQuery,
    PartialUpdateInfiniteMix,
    PartialUpdateInfiniteMixError,
    PartialUpdateInfiniteMixQuery,
    DeleteInfiniteMix,
    DeleteInfiniteMixError,
    DeleteInfiniteMixQuery,
    IisOk,
    IisNotOk,
} from '@mega/api';

export type GetInfiniteMixPromise = Promise<
    | IisOk<GetByIdInfiniteMix>
    | IisNotOk<GetByIdInfiniteMixError | null>
>;

export const getInfiniteMixById = async (
    query: GetByIdInfiniteMixQuery,
    site: string,
): GetInfiniteMixPromise => {
    try {
        const response =
            await CLIENT?.infiniteMix?.getById?.(
                {
                    ...query,
                },
                {
                    headers: {
                        'X-Site': site,
                    },
                },
            );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.streams.get &&
            e instanceof CLIENT.streams.get.Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};

export type CreateInfiniteMixPromise = Promise<
    | IisOk<CreateInfiniteMix>
    | IisNotOk<CreateInfiniteMixError | null>
>;

export const createInfiniteMix = async (
    query: CreateInfiniteMixQuery,
    site: string,
): GetInfiniteMixPromise => {
    try {
        const response =
            await CLIENT?.infiniteMix?.create?.(
                {
                    ...query,
                },
                {
                    headers: {
                        'X-Site': site,
                    },
                },
            );

        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.infiniteMix.create &&
            e instanceof CLIENT.infiniteMix.create.Error
        ) {
            const error = e.getActualType();

            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};

export type PartialUpdateInfiniteMixPromise = Promise<
    | IisOk<PartialUpdateInfiniteMix>
    | IisNotOk<PartialUpdateInfiniteMixError | null>
>;

export const partialUpdateInfiniteMixById = async (
    query: PartialUpdateInfiniteMixQuery,
    site: string,
): PartialUpdateInfiniteMixPromise => {
    try {
        const response =
            await CLIENT?.infiniteMix?.updatePartialById?.(
                {
                    ...query,
                },
                {
                    headers: {
                        'X-Site': site,
                    },
                },
            );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.infiniteMix.updatePartialById &&
            e instanceof
                CLIENT.infiniteMix.updatePartialById.Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};

export type DeleteInfiniteMixPromise = Promise<
    | IisOk<DeleteInfiniteMix>
    | IisNotOk<DeleteInfiniteMixError | null>
>;

export const deleteInfiniteMixById = async (
    query: DeleteInfiniteMixQuery,
    site: string,
): DeleteInfiniteMixPromise => {
    try {
        const response =
            await CLIENT?.infiniteMix?.deleteById?.(
                {
                    ...query,
                },
                {
                    headers: {
                        'X-Site': site,
                    },
                },
            );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.infiniteMix.deleteById &&
            e instanceof CLIENT.infiniteMix.deleteById.Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 204: {
                    return {
                        isOk: false,
                        value: null,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: null,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};
