import React, { FC, useEffect, useMemo } from 'react';
import { Column, CellValue } from 'react-table';
import type { Tags as TagsType } from '@mega/api';
import { ListingTable } from '@mega/ui';
import { typographyClasses } from '@mega/styles';
import { useTagsDispatch, useTagsStore } from '@mega/store';
import { headItem, listingRecipe } from '../Listing.css';
import {
    TitleColumn,
    PublicationColumn,
    TagIsThemeColumn,
} from '../Columns';

export interface ListingTagsProps {}

const ListingTags: FC<ListingTagsProps> = () => {
    const store = useTagsStore();
    const { get, clear } = useTagsDispatch();

    useEffect(() => {
        get({});
        return () => {
            clear();
        };
    }, [get, clear]);

    const columns: Column<TagsType>[] = React.useMemo(
        () => [
            {
                Header: 'Заголовок',
                accessor: (row) => {
                    return {
                        title: row.title,
                        id: row.id,
                    };
                },
                Cell: ({ value }: CellValue) => (
                    <TitleColumn {...value} />
                ),
                minWidth: 200,
                width: '100%',
            },
            {
                Header: 'Тема',
                accessor: (row) => {
                    return {
                        is_theme: row.is_theme,
                    };
                },

                Cell: ({ value }: CellValue) => {
                    return <TagIsThemeColumn {...value} />;
                },
            },
            {
                Header: 'Публикация',
                accessor: (row) => {
                    return {
                        data: row.updated_at,
                        pubOn: row.is_active,
                    };
                },

                Cell: ({ value }: CellValue) => {
                    return <PublicationColumn {...value} />;
                },
            },
        ],
        [],
    );
    const data = useMemo(() => {
        const values =
            store.pages?.[store.currentPage] || [];
        return Object.values(values).flat();
    }, [store.pages]);

    return (
        <>
            <ListingTable
                getHeaderGroupProps={(cellInfo) => {
                    return {
                        className: listingRecipe({
                            header: true,
                        }),
                    };
                }}
                getHeaderProps={(cellInfo) => {
                    return {
                        className: headItem({
                            title:
                                cellInfo?.id ===
                                'Заголовок',
                        }),
                    };
                }}
                getRowGroupProps={() => {
                    return {
                        className: [
                            typographyClasses.recipe({
                                size: '14',
                            }),
                            listingRecipe({
                                header: false,
                            }),
                        ].join(' '),
                    };
                }}
                columns={columns}
                data={data}
            />
        </>
    );
};

export { ListingTags };
