import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgHtmlcode = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <g fill="currentColor">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.323 1.25c1.075 0 1.849-.001 2.559.34.692.331 1.238.947 2.035 1.844l3.366 3.787c.956 1.075 1.57 1.766 1.897 2.681.215.604.285 1.252.309 2.098h-1.502c-.015-.544-.049-.927-.121-1.25h-2.293c-.86 0-1.606 0-2.204-.11-.664-.123-1.23-.395-1.655-.98-.402-.55-.563-1.22-.64-1.994C11 6.903 11 5.93 11 4.708V2.854c-.332-.093-.799-.104-1.826-.104-1.543 0-2.606.003-3.406.136-.758.127-1.177.357-1.495.754-.338.423-.55 1.02-.66 2.051-.112 1.04-.113 2.406-.113 4.31v4c0 1.902.001 3.268.113 4.308.11 1.032.322 1.629.66 2.051.318.398.737.628 1.49.754.795.134 1.852.136 3.387.136h3.2c1.535 0 2.592-.002 3.387-.136.753-.127 1.171-.356 1.49-.754.254-.318.437-.734.559-1.36h1.523c-.144.907-.407 1.667-.91 2.297-.62.773-1.424 1.13-2.413 1.296-.934.157-2.118.157-3.57.157H9.084c-1.451 0-2.635 0-3.569-.157-.99-.166-1.794-.523-2.412-1.296-.6-.75-.857-1.681-.98-2.828C2 17.34 2 15.893 2 14.046V9.954c0-1.847 0-3.294.121-4.423.124-1.146.381-2.079.98-2.828.62-.774 1.427-1.13 2.42-1.296.937-.157 2.127-.157 3.586-.157h.216Zm6.748 6.865c.422.475.74.835.984 1.135h-1.416c-.945 0-1.552-.003-1.998-.085-.394-.073-.572-.192-.716-.388-.167-.23-.291-.58-.357-1.257-.067-.681-.068-1.58-.068-2.853V4.1l.404.452 3.167 3.563Z"
            />
            <path d="M23.622 17.077V18h-3.289v-4.977h1.2v4.054h2.088ZM15.907 13.023h-1.572V18h1.2v-1.162l-.118-2.38L16.621 18h.793l1.204-3.542-.117 2.38V18h1.203v-4.977H18.13l-1.111 3.463-1.111-3.463ZM12.468 13.95V18h-1.2v-4.05H9.807v-.927h4.16v.927h-1.498ZM8.343 14.999v-1.976h1.196V18H8.343v-2.078H6.579V18h-1.2v-4.977h1.2V15h1.764Z" />
        </g>
    </svg>
);
const ForwardRef = forwardRef(SvgHtmlcode);
export default ForwardRef;
