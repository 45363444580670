import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';

const useMediaStore = () => {
    const store = useStoreSelector(
        (state: RootState) => state.media,
    );
    const loading = useStoreSelector(
        (state: RootState) => state.loading.effects.media,
    );

    return {
        store,
        loading,
    };
};

const useMediaDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        setIsLoading: dispatch.media.setIsLoading,
    };
};

export { useMediaStore, useMediaDispatch };
