import type { PluginTemplate } from '@apostroph/types';

export enum inProgressEnum {
    type = 'IN_PROGRESS',
}

export interface inProgressProps {
    id: string;
    type: string;
    parentId: string | null;
}

export type inProgressMeta = {
    text: string;
};

export type inProgressPlugin = PluginTemplate<
    inProgressEnum.type,
    inProgressMeta
>;
export type inProgressPluginDefault = Omit<
    inProgressPlugin,
    'id'
>;
