import React, { FC, useEffect, useMemo } from 'react';

import { Column, CellValue } from 'react-table';
import {
    useEmployeesDispatch,
    useEmployeesStore,
    useUserInfo,
} from '@mega/store';

import {
    IdColumn,
    NameColumn,
    EmailColumn,
    LoginColumn,
    SitesColumn,
} from '../Columns';

import type { Employees as EmployeesType } from '@mega/api';

import { listingRecipe, headItem } from '../Listing.css';
import { typographyClasses } from '@mega/styles';

import { ListingTable, Row } from '@mega/ui';
import { PubOff, PubOn } from '@mega/icons';

export interface ListingEmployeesProps {}

const ListingEmployees: FC<
    ListingEmployeesProps
> = (): JSX.Element => {
    const { me } = useUserInfo();
    const { get } = useEmployeesDispatch();
    const store = useEmployeesStore();

    useEffect(() => {
        get({
            sites_in: me?.sites
                .map((el) => el.id)
                .join(','),
        });
    }, []);

    const columns: Column<EmployeesType>[] = React.useMemo(
        () => [
            {
                Header: 'Id',
                accessor: (row) => {
                    return {
                        id: row.id,
                    };
                },
                Cell: ({ value }: CellValue) => {
                    return <IdColumn {...value} />;
                },
                minWidth: 50,
                width: '5%',
            },
            {
                Header: () => {
                    return (
                        <div style={{ textAlign: 'start' }}>
                            ФИО
                        </div>
                    );
                },
                id: 'lastname_name_partronimyc',
                accessor: (row) => {
                    return {
                        firstName: row.first_name,
                        lastName: row.last_name,
                        patronymic: row.patronymic,
                        id: row.id,
                    };
                },
                Cell: ({ value }: CellValue) => {
                    return <NameColumn {...value} />;
                },
                minWidth: 250,
                width: '24%',
            },
            {
                Header: 'Email',
                accessor: (row) => {
                    return {
                        id: row.id,
                        email: row.email,
                    };
                },
                Cell: ({ value }: CellValue) => {
                    return (
                        <EmailColumn
                            {...value}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        />
                    );
                },
                minWidth: 200,
                width: '18%',
            },
            {
                Header: 'Логин',
                accessor: (row) => {
                    return {
                        username: row.username,
                        id: row.id,
                    };
                },
                Cell: ({ value }: CellValue) => {
                    return (
                        <LoginColumn
                            {...value}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        />
                    );
                },
                minWidth: 200,
                width: '18%',
            },
            {
                Header: 'Сайты',
                accessor: (row) => {
                    return {
                        sites: row.sites,
                        id: row.id,
                    };
                },
                Cell: ({ value }: CellValue) => {
                    if (
                        !value.sites ||
                        value.sites.length == 0
                    )
                        return <></>;
                    return <SitesColumn {...value} />;
                },
                minWidth: 275,
                width: '25%',
            },
            {
                Header: 'Активность',
                accessor: (row) => {
                    return {
                        is_active: row.is_active,
                    };
                },
                Cell: ({ value }: CellValue) => {
                    if (value.is_active)
                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    padding: '4px',
                                    justifyContent:
                                        'center',
                                    alignItems: 'center',
                                    gap: '4px',
                                }}
                            >
                                <PubOn
                                    style={{
                                        width: '18px',
                                        height: '18px',
                                    }}
                                />
                            </div>
                        );
                    else
                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    padding: '4px',
                                    justifyContent:
                                        'center',
                                    alignItems: 'center',
                                    gap: '4px',
                                }}
                            >
                                <PubOff
                                    style={{
                                        width: '18px',
                                        height: '18px',
                                    }}
                                />
                            </div>
                        );
                },
                minWidth: 100,
                width: '10%',
            },
        ],
        [],
    );

    const data = useMemo(() => {
        const values =
            store.pages?.[store.currentPage] || [];
        return Object.values(values).flat();
    }, [store.pages]);

    return (
        <ListingTable
            getHeaderGroupProps={(cellInfo) => {
                return {
                    className: listingRecipe({
                        header: true,
                    }),
                };
            }}
            getHeaderProps={(cellInfo) => {
                return {
                    className: headItem({
                        title: cellInfo?.id === 'Заголовок',
                    }),
                };
            }}
            getRowGroupProps={(cell) => {
                return {
                    className: [
                        typographyClasses.recipe({
                            size: '14',
                        }),
                        listingRecipe({
                            header: false,
                        }),
                    ].join(' '),
                };
            }}
            columns={columns}
            data={data}
        />
    );
};

export { ListingEmployees };
