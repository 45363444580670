import { Option } from '@mega/ui/src/components/Inputs/types';
import { PluginTemplate } from '@pages/smartGrid/types/blocks';

export enum CategoriesEnum {
    type = 'CONTENT_CATEGORIES',
}

export interface CategoriesProps {
    id: string;
    meta: CategoriesMeta;
    type: string;
    parentId: string | null;
}

export type CategoriesMeta = {
    label: string;
    dataId: string;
    categories: Option[];
    tags: Option[];
};

export type CategoriesPlugin = PluginTemplate<
    CategoriesEnum.type,
    CategoriesMeta
>;
export type CategoriesPluginDefault = Omit<
    CategoriesPlugin,
    'id'
>;
