import React, { useEffect, useState } from 'react';
import {
    DynHeight,
    styles as s,
} from './PluginViewWrapper.css';
import EditIcon from '@mui/icons-material/Edit';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEditor } from '@pages/smartGrid/store/hooks';
import { AcceptModal } from '@mega/core';
import { useDroppable } from '@dnd-kit/core';

export interface PluginViewWrapperProps {
    title: string;
    id: string;
    parentId: string | null;
    children?: React.ReactNode;
    isEditable?: boolean;
    onChange?: (value: boolean) => void;
    render?: any;
    dynHeight?: '100%' | 'auto' | 'max-content';
}

export const PluginViewWrapper: React.FC<
    PluginViewWrapperProps
> = ({
    title,
    id,
    parentId,
    children,
    render,
    onChange,
    dynHeight = '100%',
}) => {
    const {
        handleRemoveElement,
        getParent,
        getCurrentDataElement,
    } = useEditor();

    const [isEdit, setIsEdit] = useState(false);

    const { isOver, setNodeRef, active } = useDroppable({
        id,
        data: {
            id,
        },
    });

    useEffect(() => {
        onChange && onChange(isEdit);
    }, [isEdit]);

    const handleDelete = () => {
        handleRemoveElement({
            what: id,
            parent: parentId,
        });
    };

    const isOverActive = active && isOver;

    const childrenElement =
        getParent(parentId)?.childrenElement[0];
    const childrenElementIsContainer = Boolean(
        getParent(childrenElement)?.isContainer,
    );
    const childrenElementHasParent = Boolean(
        getParent(childrenElement)?.parentId,
    );

    let isCustomPositionAddedGorizontal;
    let isCustomPositionAddedVertical;

    if (isOverActive && childrenElement && parentId) {
        const activeId = active!.data!.current!.id;
        const activeInNotOverSelf =
            !getParent(parentId)?.childrenElement.includes(
                activeId,
            );
        const isTheSameEntityEachElement = getParent(
            parentId,
        )?.childrenElement.every(
            (child) =>
                getCurrentDataElement(child).type ===
                active.data?.current?.type,
        );

        const isParentMainStack =
            !getCurrentDataElement(parentId).meta.isMain &&
            getCurrentDataElement(parentId).type ===
                'FLOW_STACK';

        const isCustomPositionAdded =
            isOverActive &&
            childrenElementHasParent &&
            !childrenElementIsContainer &&
            activeInNotOverSelf;

        isCustomPositionAddedGorizontal =
            isCustomPositionAdded && !isParentMainStack;
        isCustomPositionAddedVertical =
            isCustomPositionAdded && isParentMainStack;
    }

    return (
        <div
            ref={setNodeRef}
            className={
                isCustomPositionAddedGorizontal
                    ? s.wrapper({
                          isCustomPositionAddedGorizontal,
                      })
                    : s.wrapper({
                          isCustomPositionAddedVertical,
                      })
            }
            style={assignInlineVars({
                [DynHeight]: dynHeight,
            })}
        >
            <div className={s.header}>
                <p className={s.title}>{title}</p>
                {/* Редактирование нужно не везде, будет заменять блок тулзов справа*/}
                <div className={s.iconList}>
                    {render && (
                        <div
                            className={s.edit}
                            onClick={() => setIsEdit(true)}
                        >
                            <EditIcon />
                        </div>
                    )}

                    {parentId && id && (
                        <AcceptModal
                            msg="Вы уверенны что хотите удалить данный блок?"
                            Trigger={
                                <button
                                    className={s.deleteIcon}
                                    type={'button'}
                                    onClick={() => {
                                        handleDelete();
                                    }}
                                >
                                    <DeleteIcon />
                                </button>
                            }
                        />
                    )}
                </div>
            </div>
            <div className={s.content}>
                {children ? children : null}
                {render && render(isEdit, setIsEdit)}
            </div>
        </div>
    );
};
