import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgPlayCircle = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M7.181 12.188 12.187 9 7.181 5.812v6.375ZM9 16.5a7.258 7.258 0 0 1-2.906-.59 7.61 7.61 0 0 1-2.39-1.613 7.607 7.607 0 0 1-1.613-2.39A7.257 7.257 0 0 1 1.5 9c0-1.037.197-2.013.59-2.925a7.496 7.496 0 0 1 1.613-2.381 7.688 7.688 0 0 1 2.39-1.603A7.257 7.257 0 0 1 9 1.5c1.037 0 2.012.197 2.925.59a7.574 7.574 0 0 1 2.381 1.604 7.575 7.575 0 0 1 1.603 2.381c.394.912.591 1.888.591 2.925a7.258 7.258 0 0 1-.59 2.906 7.689 7.689 0 0 1-1.604 2.39 7.497 7.497 0 0 1-2.381 1.613A7.304 7.304 0 0 1 9 16.5Zm0-1.125c1.775 0 3.281-.622 4.519-1.866 1.237-1.243 1.856-2.747 1.856-4.509 0-1.775-.619-3.281-1.856-4.519C12.28 3.244 10.775 2.625 9 2.625c-1.763 0-3.266.619-4.51 1.856C3.248 5.72 2.626 7.225 2.626 9c0 1.762.622 3.266 1.866 4.51C5.734 14.752 7.238 15.374 9 15.374Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgPlayCircle);
export default ForwardRef;
