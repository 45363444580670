import { Input, InputProps, Typography } from '@mega/ui';
import React, { FC, InputHTMLAttributes } from 'react';
import { InputClasses } from '@mega/ui/src/components/Inputs/Input/Input.css';
import {
    inputWrapper,
    total,
    totalCount,
} from './CustomInput.css';

import { useFormikContext } from 'formik';
import { FormValues } from '../../types';

export const CustomInput: FC<
    InputHTMLAttributes<HTMLInputElement> &
        InputProps &
        InputClasses['recipe']
> = (props) => {
    const { values, setFieldValue } =
        useFormikContext<FormValues>();
    return (
        <div className={inputWrapper}>
            <Input
                {...props}
                value={values.search}
                onChange={(e) => {
                    const value = e.target.value;
                    return setFieldValue('search', value);
                }}
            />
            <Typography className={total}>
                Найдено{' '}
                <span className={totalCount}>
                    {values.total}
                </span>{' '}
                файл(ов)
            </Typography>
        </div>
    );
};
