import { ReactNode } from 'react';
import {
    PluginElements,
    PluginElementsPreview,
    RootElements,
} from './blocks';

export interface Core {
    view: 'layout' | 'modal';
    viewElements: Array<'editor' | 'preview' | string>;
}
export interface PreviewCore {
    rootElements: {
        [key: string]: PluginElementsPreview;
    };
    pluginElements: {
        [key: string]: PluginElementsPreview;
    };
}

export enum PositionsVariants {
    overlay = 'OVERLAY',
    actionHeadFeed = 'ACTION_HEAD_FEED',
    actionFeed = 'ACTION_FEED',
    actionBottomFeed = 'ACTION_BOTTOM_FEED',
    actionZone = 'ACTION_ZONE',
    customPosition = 'CUSTOM_POSITION',
    none = 'NONE',
}

export type Positions =
    | PositionsVariants.overlay
    | PositionsVariants.actionHeadFeed
    | PositionsVariants.actionFeed
    | PositionsVariants.actionBottomFeed
    | PositionsVariants.actionZone
    | PositionsVariants.customPosition
    | PositionsVariants.none;

export type Group = {
    name: string;
    label: string;
};

export type Control = {
    name: string;
    label: string;
    tooltip: string;
    groupName?: string;
    position: Positions | Array<Positions>;
    Icon?: () => ReactNode;
};

export type PositionedControl = {
    name: string;
    label: string;
    tooltip: string;
    groupName?: string;
    position: Positions;
    Icon?: () => ReactNode;
};

export type GroupedByPositionControl = {
    [key in Positions]: Array<PositionedControl>;
};

export interface EditorCore {
    groups: {
        [key: string]: Array<Group>;
    };
    controls: {
        [key: string]: Array<Control>;
    };
    rootElements: {
        [key: string]: RootElements;
    };
    pluginElements: {
        [key: string]: PluginElements;
    };
}
