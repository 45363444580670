import type { PluginTemplate } from '@apostroph/types';

export enum LexEnum {
    type = 'MILK', // LEX - key
}

export interface LexProps {
    id: string;
    type: string;
    parentId: string | null;
}

export type InAgent = {
    id?: number | undefined;
    title: string;
    synonyms?: string[] | undefined;
    org_type: 'extremist' | 'terrorist' | 'foreign_agent';
    org_type_display?: string | undefined;
    description?: string | undefined;
};

export type InAgents = Array<InAgent>;
export type SetInAgent = (
    agents: InAgents,
    content: string,
) => void;

export type LexMeta = {
    content: string;
    inAgents?: InAgents;
    isAnnotation?: boolean;
    annotation?: string;
};

export type LexPlugin = PluginTemplate<
    LexEnum.type,
    LexMeta
>;
export type LexPluginDefault = Omit<LexPlugin, 'id'>;
