/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Control } from '../../../../types/core';
import { Main } from './Main';
import {
    MainTemplateEnum,
    MainTemplatePluginDefault,
} from '@pages/smartGrid/components/Plugins/Templates/Main/Main.types';

const PLUGIN_MAIN_TEMPLATE_NAME = MainTemplateEnum.type;

const DEFAULT_MAIN_TEMPLATE: MainTemplatePluginDefault = {
    type: PLUGIN_MAIN_TEMPLATE_NAME,
    meta: {},
    isContainer: true,
    childrenElement: [],
};

const PLUGIN_MAIN_TEMPLATE = {
    [PLUGIN_MAIN_TEMPLATE_NAME]: {
        emptyObject: DEFAULT_MAIN_TEMPLATE,
        render: Main,
    },
};

const PLUGIN_MAIN_TEMPLATE_CONTROL: Control = {
    name: PLUGIN_MAIN_TEMPLATE_NAME,
    label: 'MAIN_TEMPLATE Template',
    tooltip: 'Создать Flow Stack',
    Icon: () => <></>,
};

export {
    DEFAULT_MAIN_TEMPLATE,
    PLUGIN_MAIN_TEMPLATE,
    PLUGIN_MAIN_TEMPLATE_CONTROL,
    PLUGIN_MAIN_TEMPLATE_NAME,
};
