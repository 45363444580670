export const fetchFile = (url: string) => {
    return fetch(url).then((res) => res.blob());
};
export const exportFile = (file: any) => {
    const href = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
};
