/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Control } from '../../../../types/core';
import { FlowStack } from './FlowStack';
import {
    FlowStackEnum,
    FlowStackPluginDefault,
} from './FlowStack.types';

const PLUGIN_FLOW_STACK_NAME = FlowStackEnum.type;

const DEFAULT_FLOW_STACK: FlowStackPluginDefault = {
    type: PLUGIN_FLOW_STACK_NAME,
    meta: {},
    isContainer: true,
    childrenElement: [],
};

const PLUGIN_FLOW_STACK = {
    [PLUGIN_FLOW_STACK_NAME]: {
        emptyObject: DEFAULT_FLOW_STACK,
        render: FlowStack,
    },
};

const PLUGIN_FLOW_STACK_CONTROL: Control = {
    name: PLUGIN_FLOW_STACK_NAME,
    label: 'Flow Stack',
    tooltip: 'Создать Flow Stack',
    Icon: () => <></>,
};

export {
    DEFAULT_FLOW_STACK,
    PLUGIN_FLOW_STACK,
    PLUGIN_FLOW_STACK_CONTROL,
    PLUGIN_FLOW_STACK_NAME,
};
