import React, { useEffect } from 'react';
import { youTubeEmbed } from '@mega/utils';

export const VideoEmbed = ({
    source,
    className = '',
}: {
    source: string;
    className?: string;
}) => {
    if (!source.includes('iframe')) {
        useEffect(() => {
            youTubeEmbed();
        }, []);

        return (
            <div
                data-youtube-source={source}
                className={`article-youtube-sharing ${className}`}
            />
        );
    }

    return (
        <div
            className={className}
            dangerouslySetInnerHTML={{ __html: source }}
        />
    );
};
