import React, { ReactNode } from 'react';
import { styledTag, TagRecipeVariant } from './Tag.css';

export interface TagProps {
    children: ReactNode;
}

export const Tag: React.FC<TagProps & TagRecipeVariant> = (
    props,
) => {
    const { children, type = 'default' } = props;
    return (
        <div className={styledTag({ type })}>
            {children}
        </div>
    );
};
