import * as CLIENT from '@mega/api';
import {
    authorCreate,
    authorPartialUpdateById,
    authorPartialUpdateImageById,
    authorGetById,
    authorDeleteById,
    AuthorGetByIdPromise,
    AuthorCreatePromise,
    AuthorPartialUpdatePromise,
    AuthorDeletePromise,
} from './author.api';
import { createModel } from '@rematch/core';
import {
    Author,
    AuthorGetByIdQuery,
    AuthorFormDataCreate,
    AuthorFormDataUpdate,
    AuthorPartialUpdateReturn,
    AuthorPartialUpdateError,
    AuthorCreateError,
    AuthorCreateReturn,
    AuthorGetByIdReturn,
    AuthorGetByIdError,
    AuthorDeleteError,
    AuthorDeleteQuery,
    AuthorDeleteReturn,
} from '@mega/types';
import { AuthorCreate, isOk } from '@mega/api';
import type { RootModel } from '../rootModel';

const author = createModel<RootModel>()({
    name: 'author',
    state: {} as Author,
    reducers: {
        put: (state, payload: Author) => {
            return { ...payload };
        },
    },
    effects: (dispatch) => ({
        get: async (
            props: {
                payload: AuthorGetByIdQuery;
            },
            store,
        ): AuthorGetByIdPromise => {
            const response = await authorGetById(
                props.payload,
                store.site.site?.id || '',
            );
            if (
                isOk<
                    AuthorGetByIdReturn,
                    AuthorGetByIdError | null
                >(response)
            ) {
                dispatch.author.put(response.value);
                return response;
            }
            return response;
        },
        create: async (
            props: {
                payload: AuthorFormDataCreate;
            },
            store,
        ): AuthorCreatePromise => {
            const response = await authorCreate(
                {
                    name: props.payload.name,
                    sites: props.payload.sites.map(
                        (item) => item.id,
                    ),
                },
                store.site.site?.id || '',
            );

            if (
                isOk<
                    AuthorCreateReturn,
                    AuthorCreateError | null
                >(response)
            ) {
                dispatch.author.put(response.value);
                return response;
            }
            return response;
        },
        updateImage: authorPartialUpdateImageById,
        update: async (
            payload: Omit<AuthorFormDataUpdate, 'photo'> & {
                id: number;
            },
            store,
        ): AuthorPartialUpdatePromise => {
            const response = await authorPartialUpdateById(
                {
                    id: payload.id,
                    name: payload.name,
                    sites:
                        payload.sites.map(
                            (item) => item.id,
                        ) || [],
                    slug: payload.slug,
                    is_active: payload.isActive,
                    email: payload.email,
                    position: payload.position,
                    description: payload.description,
                },
                store.site.site?.id || '',
            );

            if (
                response &&
                isOk<
                    AuthorPartialUpdateReturn,
                    AuthorPartialUpdateError | null
                >(response)
            ) {
                dispatch.author.put(response.value);
                return response;
            }
            return response;
        },
        remove: async (
            props: { payload: AuthorDeleteQuery },
            store,
        ): AuthorDeletePromise => {
            const response = await authorDeleteById(
                props.payload,
                store.site.site?.id || '',
            );
            if (
                isOk<
                    AuthorDeleteReturn,
                    AuthorDeleteError | null
                >(response)
            ) {
                return response;
            }
            return response;
        },
    }),
});

export { author };
export default author;
