import type {
    GetByIdADSHeadScript,
    GetByIdADSHeadScriptError,
    GetByIdADSHeadScriptQuery,
    CreateADSHeadScript,
    CreateADSHeadScriptError,
    CreateADSHeadScriptQuery,
    PartialUpdateADSHeadScript,
    PartialUpdateADSHeadScriptError,
    PartialUpdateADSHeadScriptQuery,
    DeleteADSHeadScript,
    DeleteADSHeadScriptError,
    DeleteADSHeadScriptQuery,
} from '@mega/api';
import { isOk } from '@mega/api';
import { createModel } from '@rematch/core';

import type { RootModel } from '../../rootModel';
import {
    getADSHeadScriptById,
    GetADSHeadScriptPromise,
    createADSHeadScript,
    CreateADSHeadScriptPromise,
    partialUpdateADSHeadScriptById,
    PartialUpdateADSHeadScriptPromise,
    deleteADSHeadScriptById,
    DeleteADSHeadScriptPromise,
} from './adsHeadScript.api';

const adsHeadScript = createModel<RootModel>()({
    name: 'adsHeadScript',
    state: {} as GetByIdADSHeadScript,
    reducers: {
        put: (state, payload: GetByIdADSHeadScript) => {
            return { ...payload };
        },
    },
    effects: (dispatch) => ({
        get: async (
            props: { paylaod: GetByIdADSHeadScriptQuery },
            store,
        ): GetADSHeadScriptPromise => {
            const response = await getADSHeadScriptById(
                props.paylaod,
                store.site.site?.id || '',
            );
            if (
                isOk<
                    GetByIdADSHeadScript,
                    GetByIdADSHeadScriptError | null
                >(response)
            ) {
                dispatch.adsHeadScript.put(response.value);
                return response;
            }
            return response;
        },
        create: async (
            props: { payload: CreateADSHeadScriptQuery },
            store,
        ): CreateADSHeadScriptPromise => {
            const response = await createADSHeadScript(
                props.payload,
                store.site.site?.id || '',
            );
            if (
                isOk<
                    CreateADSHeadScript,
                    CreateADSHeadScriptError | null
                >(response)
            ) {
                dispatch.adsHeadScript.put(response.value);
                return response;
            }
            return response;
        },
        update: async (
            props: {
                payload: PartialUpdateADSHeadScriptQuery;
            },
            store,
        ): PartialUpdateADSHeadScriptPromise => {
            const response =
                await partialUpdateADSHeadScriptById(
                    props.payload,
                    store.site.site?.id || '',
                );
            if (
                isOk<
                    PartialUpdateADSHeadScript,
                    PartialUpdateADSHeadScriptError | null
                >(response)
            ) {
                dispatch.adsHeadScript.get({
                    paylaod: {
                        id: props.payload.id,
                    },
                });
                return response;
            }
            return response;
        },
        remove: async (
            props: { payload: DeleteADSHeadScriptQuery },
            store,
        ): DeleteADSHeadScriptPromise => {
            const responses = await deleteADSHeadScriptById(
                props.payload,
                store.site.site?.id || '',
            );
            if (
                isOk<
                    DeleteADSHeadScript,
                    DeleteADSHeadScriptError | null
                >(responses)
            ) {
                return responses;
            }
            return responses;
        },
    }),
});

export { adsHeadScript };
export default adsHeadScript;
