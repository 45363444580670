import type {
    GetByIdInfiniteMix,
    GetByIdInfiniteMixError,
    GetByIdInfiniteMixQuery,
    CreateInfiniteMix,
    CreateInfiniteMixError,
    CreateInfiniteMixQuery,
    PartialUpdateInfiniteMix,
    PartialUpdateInfiniteMixError,
    PartialUpdateInfiniteMixQuery,
    DeleteInfiniteMix,
    DeleteInfiniteMixError,
    DeleteInfiniteMixQuery,
} from '@mega/api';
import { isOk } from '@mega/api';
import { createModel } from '@rematch/core';

import type { RootModel } from '../rootModel';
import {
    getInfiniteMixById,
    GetInfiniteMixPromise,
    createInfiniteMix,
    CreateInfiniteMixPromise,
    partialUpdateInfiniteMixById,
    PartialUpdateInfiniteMixPromise,
    deleteInfiniteMixById,
    DeleteInfiniteMixPromise,
} from './InfinityMix.api';

const infiniteMix = createModel<RootModel>()({
    name: 'infiniteMix',
    state: {} as GetByIdInfiniteMix,
    reducers: {
        put: (state, payload: GetByIdInfiniteMix) => {
            return { ...payload };
        },
    },
    effects: (dispatch) => ({
        get: async (
            props: { paylaod: GetByIdInfiniteMixQuery },
            store,
        ): GetInfiniteMixPromise => {
            const response = await getInfiniteMixById(
                props.paylaod,
                store.site.site?.id || '',
            );
            if (
                isOk<
                    GetByIdInfiniteMix,
                    GetByIdInfiniteMixError | null
                >(response)
            ) {
                dispatch.infiniteMix.put(response.value);
                return response;
            }
            return response;
        },
        create: async (
            props: { payload: CreateInfiniteMixQuery },
            store,
        ): CreateInfiniteMixPromise => {
            const response = await createInfiniteMix(
                props.payload,
                store.site.site?.id || '',
            );
            if (
                isOk<
                    CreateInfiniteMix,
                    CreateInfiniteMixError | null
                >(response)
            ) {
                dispatch.infiniteMix.put(response.value);
                return response;
            }
            return response;
        },
        update: async (
            props: {
                payload: PartialUpdateInfiniteMixQuery;
            },
            store,
        ): PartialUpdateInfiniteMixPromise => {
            const response =
                await partialUpdateInfiniteMixById(
                    props.payload,
                    store.site.site?.id || '',
                );
            if (
                isOk<
                    PartialUpdateInfiniteMix,
                    PartialUpdateInfiniteMixError | null
                >(response)
            ) {
                dispatch.infiniteMix.get({
                    paylaod: {
                        id: props.payload.id,
                    },
                });
                return response;
            }
            return response;
        },
        remove: async (
            props: { payload: DeleteInfiniteMixQuery },
            store,
        ): DeleteInfiniteMixPromise => {
            const responses = await deleteInfiniteMixById(
                props.payload,
                store.site.site?.id || '',
            );

            const query = store.infiniteMixList.query;

            if (
                isOk<
                    DeleteInfiniteMix,
                    DeleteInfiniteMixError | null
                >(responses)
            ) {
                dispatch.infiniteMixList.get(query);
                return responses;
            }
            return responses;
        },
    }),
});

export { infiniteMix };
export default infiniteMix;
