import React, {
    FC,
    useEffect,
    useMemo,
    CSSProperties,
} from 'react';
import { Column, CellValue } from 'react-table';
import type {
    InfiniteMixEntity,
    InfiniteMixList,
} from '@mega/api';
import { ListingTable, IconButton, Icon } from '@mega/ui';
import { Close } from '@mega/icons';
import { typographyClasses } from '@mega/styles';
import {
    useInfiniteMixListDispatch,
    useInfiniteMixListStore,
    useInfiniteMixDispatch,
} from '@mega/store';
import { headItem, listingRecipe } from '../Listing.css';
import { TitleColumn } from '../Columns';
import { format, isBefore } from 'date-fns';
import { AcceptModal } from '@mega/core';
import { useSnackbar } from 'notistack';

export interface ListingInfiniteMixProps {}

const centred = {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
} as CSSProperties;

const ListingInfiniteMix: FC<
    ListingInfiniteMixProps
> = () => {
    const store = useInfiniteMixListStore();
    const { get, clear } = useInfiniteMixListDispatch();

    const { remove } = useInfiniteMixDispatch();

    useEffect(() => {
        get({});
        return () => {
            clear();
        };
    }, [get, clear]);

    const columns: Column<InfiniteMixEntity>[] =
        React.useMemo(
            () => [
                {
                    Header: () => (
                        <p style={{ textAlign: 'start' }}>
                            Заголовок
                        </p>
                    ),
                    id: 'Title',
                    accessor: (row) => {
                        return {
                            title: row.article.label,
                            id: row.id,
                        };
                    },
                    Cell: ({ value }: CellValue) => (
                        <TitleColumn {...value} />
                    ),
                    minWidth: 200,
                    width: '100%',
                },
                {
                    Header: 'Активно с',
                    accessor: (row) => row.active_from,
                    Cell: ({ value }: CellValue) => {
                        return (
                            <div style={centred}>
                                {format(
                                    new Date(value),
                                    'dd-MM-yyyy HH:mm',
                                )}
                            </div>
                        );
                    },
                },
                {
                    Header: 'Активно до',
                    accessor: (row) => row.active_to,
                    Cell: ({ value }: CellValue) => {
                        return (
                            <div style={centred}>
                                {format(
                                    new Date(value),
                                    'dd-MM-yyyy HH:mm',
                                )}
                            </div>
                        );
                    },
                },
                {
                    Header: 'Цель просмотров',
                    accessor: (row) => row.target_shows,
                    Cell: ({ value }: CellValue) => {
                        return (
                            <div style={centred}>
                                {value}
                            </div>
                        );
                    },
                },
                {
                    Header: 'Просмотры',
                    accessor: (row) => row.shows,
                    width: '100%',
                    maxWidth: 100,
                    Cell: ({ value }: CellValue) => {
                        return (
                            <div style={centred}>
                                {value}
                            </div>
                        );
                    },
                },
                {
                    Header: 'Порядок',
                    accessor: (row) => row.order,
                    width: '100%',
                    maxWidth: 100,
                    Cell: ({ value }: CellValue) => {
                        return (
                            <div style={centred}>
                                {value}
                            </div>
                        );
                    },
                },
                {
                    Header: 'Действия',
                    accessor: (row) => {
                        return {
                            id: row.id,
                            active_to: row.active_to,
                        };
                    },
                    maxWidth: 70,
                    width: '100%',
                    Cell: ({ value }: CellValue) => {
                        const { enqueueSnackbar } =
                            useSnackbar();

                        const isActive = isBefore(
                            new Date(),
                            new Date(value.active_to),
                        );

                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent:
                                        'center',
                                    alignItems: 'center',
                                }}
                            >
                                <AcceptModal
                                    msg="Вы уверенны что хотите удалить инфинит-скролл?"
                                    Trigger={
                                        <IconButton
                                            animate="none"
                                            onClick={() => {
                                                if (
                                                    value.id &&
                                                    !isActive
                                                ) {
                                                    remove({
                                                        payload:
                                                            {
                                                                id: value.id,
                                                            },
                                                    });
                                                } else {
                                                    return enqueueSnackbar(
                                                        'Активный инфинит-скролл удалить нельзя',
                                                        {
                                                            variant:
                                                                'error',
                                                        },
                                                    );
                                                }
                                            }}
                                            classes={{
                                                paper: {
                                                    color: 'white',
                                                },
                                            }}
                                        >
                                            <Icon size="25">
                                                <Close
                                                    style={{
                                                        color: '#EB5757CC',
                                                    }}
                                                />
                                            </Icon>
                                        </IconButton>
                                    }
                                />
                            </div>
                        );
                    },
                },
            ],
            [],
        );

    const data = useMemo(() => {
        const values =
            store.pages?.[store.currentPage] || [];
        return Object.values(values).flat();
    }, [store.pages]);

    return (
        <>
            <ListingTable
                getHeaderGroupProps={(cellInfo) => {
                    return {
                        className: listingRecipe({
                            header: true,
                        }),
                    };
                }}
                getHeaderProps={(cellInfo) => {
                    return {
                        className: headItem({
                            title:
                                cellInfo?.id ===
                                'Заголовок',
                        }),
                    };
                }}
                getRowGroupProps={() => {
                    return {
                        className: [
                            typographyClasses.recipe({
                                size: '14',
                            }),
                            listingRecipe({
                                header: false,
                            }),
                        ].join(' '),
                    };
                }}
                columns={columns}
                data={data}
            />
        </>
    );
};

export { ListingInfiniteMix };
