import * as CLIENT from '@mega/api';
import {
    AuthorGetByIdError,
    AuthorGetByIdQuery,
    AuthorGetByIdReturn,
    AuthorCreateReturn,
    AuthorCreateQuery,
    AuthorCreateError,
    AuthorPartialUpdateReturn,
    AuthorPartialUpdateQuery,
    AuthorPartialUpdateError,
    AuthorDeleteReturn,
    AuthorDeleteQuery,
    AuthorDeleteError,
} from '@mega/types';

import type { IisOk, IisNotOk } from '@mega/api';

export type AuthorGetByIdPromise = Promise<
    | IisOk<AuthorGetByIdReturn>
    | IisNotOk<AuthorGetByIdError | null>
>;

export const authorGetById = async (
    query: AuthorGetByIdQuery,
    site: string,
): AuthorGetByIdPromise => {
    try {
        const response = await CLIENT?.authors?.getById?.(
            {
                ...query,
            },
            {
                headers: {
                    'X-Site': site,
                },
            },
        );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.authors.get &&
            e instanceof CLIENT.authors.get.Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};

export type AuthorCreatePromise = Promise<
    | IisOk<AuthorCreateReturn>
    | IisNotOk<AuthorCreateError | null>
>;

export const authorCreate = async (
    query: AuthorCreateQuery,
    site: string,
): AuthorCreatePromise => {
    try {
        const response = await CLIENT?.authors?.create?.(
            {
                ...query,
            },
            {
                headers: {
                    'X-Site': site,
                },
            },
        );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.authors.create &&
            e instanceof CLIENT.authors.create.Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};

export type AuthorPartialUpdatePromise = Promise<
    | IisOk<AuthorPartialUpdateReturn>
    | IisNotOk<AuthorPartialUpdateError | null>
>;

export const authorPartialUpdateById = async (
    query: AuthorPartialUpdateQuery,
    site: string,
): AuthorPartialUpdatePromise => {
    try {
        const response =
            await CLIENT?.authors?.updatePartialById?.(
                {
                    ...query,
                },
                {
                    headers: {
                        'X-Site': site,
                    },
                },
            );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.authors.updatePartialById &&
            e instanceof
                CLIENT.authors.updatePartialById.Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};

export const authorPartialUpdateImageById = async (query: {
    id: string;
    data: FormData;
}): AuthorPartialUpdatePromise => {
    try {
        const response =
            await CLIENT?.authors?.updateImageById?.(
                query.id,
                query.data,
            );
        if (response.data) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        return {
            isOk: false,
            value: null,
        };
    }
};

export type AuthorDeletePromise = Promise<
    | IisOk<AuthorDeleteReturn>
    | IisNotOk<AuthorDeleteError | null>
>;

export const authorDeleteById = async (
    query: AuthorDeleteQuery,
    site: string,
): AuthorDeletePromise => {
    try {
        const response =
            await CLIENT?.authors?.deleteById?.(
                {
                    ...query,
                },
                {
                    headers: {
                        'X-Site': site,
                    },
                },
            );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.authors.deleteById &&
            e instanceof CLIENT.authors.deleteById.Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 204: {
                    return {
                        isOk: false,
                        value: null,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: null,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};
