import React, { ReactNode, FC, useState } from 'react';

import { Button, Icon, SelectSingleField } from '@mega/ui';
import { ArrowDown } from '@mega/icons';

type OptionType = {
    label: string;
    value: string | number;
};

export const SelectWithTrigger: FC<{
    actionOnSelectedOption?: (e: OptionType | null) => void;
    handleLoader: (
        inputValue: string,
        page: number,
    ) => Promise<OptionType[]>;
    options?: OptionType[];
    getUploadOptions?: (options: OptionType[]) => void;
    name: string;
    label: string;
}> = ({
    actionOnSelectedOption,
    handleLoader,
    options,
    getUploadOptions,
    name,
    label,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Dropdown
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            target={
                <Button
                    classes={{
                        paper: {
                            color: 'gray',
                        },
                    }}
                    size="large"
                    onClick={() =>
                        setIsOpen((prev) => !prev)
                    }
                    label={label}
                    endIcon={
                        <Icon size="18">
                            <ArrowDown />
                        </Icon>
                    }
                />
            }
        >
            <SelectSingleField
                actionOnSelectedOption={
                    actionOnSelectedOption
                }
                menuIsOpen
                options={options}
                autoFocus
                classes={{
                    paper: {
                        color: 'gray',
                    },
                    select: {
                        variant: 'third',
                    },
                }}
                hideSelectedOptions={true}
                name={name}
                handleLoader={handleLoader}
                getUploadOptions={getUploadOptions}
            />
        </Dropdown>
    );
};

const Menu = (props: JSX.IntrinsicElements['div']) => {
    return (
        <div
            style={{
                position: 'absolute',
                width: '300px',
                zIndex: 4001,
                paddingTop: '16px',
            }}
            {...props}
        />
    );
};
const Blanket = (props: JSX.IntrinsicElements['div']) => (
    <div
        style={{
            bottom: 0,
            left: 0,
            top: 0,
            right: 0,
            position: 'fixed',
            zIndex: 4000,
        }}
        {...props}
    />
);
const Dropdown = ({
    children,
    isOpen,
    target,
    onClose,
}: {
    children?: ReactNode;
    readonly isOpen: boolean;
    readonly target: ReactNode;
    readonly onClose: () => void;
}) => (
    <div style={{ position: 'relative' }}>
        {target}
        {isOpen ? <Menu>{children}</Menu> : null}
        {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
);
