import { SelectSingleField, WithLabel } from '@mega/ui';
import React, { useEffect, useState } from 'react';
import { reportTypeList } from '../../constants';
import {
    ReportFormValues,
    ReportTypes,
} from '../../Reports.types';
import { getOptionsReports } from '@mega/core';
import { useSiteStore } from '@mega/store';
import { useFormikContext } from 'formik';

export const SelectReportType = () => {
    const currentSite = useSiteStore();

    const [availableTypes, setAvailableTypes] = useState<
        ReportTypes[]
    >([]);

    const reportsCallback = getOptionsReports({
        site: currentSite?.id || '',
    });

    useEffect(() => {
        (async () => {
            const res = await reportsCallback();
            setAvailableTypes(res);
        })();
    }, []);

    const curReportTypeList = reportTypeList.filter(
        (item) => availableTypes.includes(item.value),
    );

    const { values, setFieldValue } =
        useFormikContext<ReportFormValues>();

    const formatList = curReportTypeList.find(
        (item) => item.value === values.report_type?.id,
    )?.formats;

    useEffect(() => {
        const hasFormat = formatList?.find(
            (item) => item.value === values.file_format?.id,
        );
        if (!hasFormat) {
            setFieldValue('file_format', undefined);
        }
    }, [values.report_type, formatList]);

    return (
        <>
            <WithLabel
                id={'report_type'}
                title="Тип отчета"
            >
                <SelectSingleField
                    name={'report_type'}
                    placeholder={'Тип отчета'}
                    handleLoader={async () =>
                        curReportTypeList
                    }
                    classes={{
                        paper: {
                            color: 'dark',
                        },
                        select: {
                            variant: 'secondary',
                        },
                    }}
                />
            </WithLabel>
            {formatList && (
                <WithLabel
                    id={'file_format'}
                    title="Формат отчета"
                >
                    <SelectSingleField
                        key={values.report_type?.id}
                        name={'file_format'}
                        placeholder={'Формат отчета'}
                        handleLoader={async () =>
                            formatList
                        }
                        classes={{
                            paper: {
                                color: 'dark',
                            },
                            select: {
                                variant: 'secondary',
                            },
                        }}
                    />
                </WithLabel>
            )}
        </>
    );
};
