import React, { ReactNode, useState } from 'react';

import { Button, Icon, InputSelectMulti } from '@mega/ui';
import { ArrowDown } from '@mega/icons';
import { Options } from '@mega/ui/src/components/Inputs/types';

interface SelectMultiWithTriggerProps {
    actionOnSelectedOption?: (e: Options | null) => void;
    currentTag: Options | undefined;
    handleLoader: (
        inputValue: string,
        page: number,
    ) => Promise<Options>;
    getUploadOptions?: (options: Options) => void;
    name: string;
    label: string;
}

export const SelectMultiWithTrigger = ({
    actionOnSelectedOption,
    handleLoader,
    label,
    currentTag,
    ...props
}: SelectMultiWithTriggerProps) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Dropdown
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            target={
                <Button
                    classes={{
                        paper: {
                            color: 'gray',
                        },
                    }}
                    size="large"
                    onClick={() =>
                        setIsOpen((prev) => !prev)
                    }
                    label={label}
                    endIcon={
                        <Icon size="18">
                            <ArrowDown />
                        </Icon>
                    }
                />
            }
        >
            <InputSelectMulti
                {...props}
                key="tags"
                defaultValue={currentTag}
                classes={{
                    paper: {
                        color: 'dark',
                        variant: 'dashedFilled',
                        fullHeight: true,
                    },
                    select: {
                        variant: 'fourth',
                    },
                }}
                actionOnSelectedOption={
                    actionOnSelectedOption
                }
                handleLoader={handleLoader}
                menuIsOpen={true}
            />
        </Dropdown>
    );
};

const Menu = (props: JSX.IntrinsicElements['div']) => {
    return (
        <>
            <div
                style={{
                    position: 'absolute',
                    width: '300px',
                    zIndex: 4001,
                    paddingTop: '16px',
                }}
                {...props}
            />
        </>
    );
};
const Blanket = (props: JSX.IntrinsicElements['div']) => (
    <div
        style={{
            bottom: 0,
            left: 0,
            top: 0,
            right: 0,
            position: 'fixed',
            zIndex: 4000,
        }}
        {...props}
    />
);
const Dropdown = ({
    children,
    isOpen,
    target,
    onClose,
}: {
    children?: ReactNode;
    readonly isOpen: boolean;
    readonly target: ReactNode;
    readonly onClose: () => void;
}) => (
    <div style={{ position: 'relative' }}>
        {target}
        {isOpen ? <Menu>{children}</Menu> : null}
        {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
);
