import { FC, useState } from 'react';
import { PluginViewWrapper } from 'src/pages/smartGrid/components/shared/PluginViewWrapper';
import { GridSettings } from '@pages/smartGrid/components/shared/GridSettings';
import { useEditor } from '@pages/smartGrid/store/hooks';
import { CategoriesProps } from './Categories.types';
import { IInitValuesForm } from './CategoriesSettings/CategoriesForm/config';
import { CategoriesSettings } from './CategoriesSettings';
import { styles as s } from './Categories.css';
import {
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    horizontalListSortingStrategy,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
} from '@dnd-kit/sortable';
import { Option } from '@mega/core';

const SortableItem: React.FC<{
    cat: Option;
}> = ({ cat }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: cat.value });

    const style = transform
        ? {
              transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
              transition,
          }
        : undefined;

    return (
        <div
            style={style}
            className={s.category}
            ref={setNodeRef}
            {...attributes}
            {...listeners}
        >
            {cat.label}
        </div>
    );
};
const Categories: FC<CategoriesProps> = ({
    id,
    type,
    meta,
    parentId,
}) => {
    const { handleUpdateElement } = useEditor();

    const [categoriesList, setCategoriesList] = useState<
        number[]
    >(
        meta.categories?.map(
            (item) => item.value as number,
        ) || [],
    );

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );
    const onSubmit = (values: IInitValuesForm) => {
        setCategoriesList(
            values.categories.map(
                (item) => item.value as number,
            ),
        );
        handleUpdateElement({
            id,
            type,
            meta: values as Record<string, any>,
        });
    };

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (active.id !== over?.id) {
            setCategoriesList((items) => {
                const oldIndex = items.indexOf(
                    active?.id as number,
                );
                const newIndex = items.indexOf(
                    over?.id as number,
                );

                const newArray = arrayMove(
                    items,
                    oldIndex,
                    newIndex,
                );

                const updatedTags = newArray.map((id) => {
                    const curCat = meta.categories.find(
                        (tag) => tag.value === id,
                    );
                    return curCat || id;
                });

                handleUpdateElement({
                    id,
                    type,
                    meta: {
                        ...meta,
                        categories: updatedTags,
                    } as Record<string, any>,
                });
                return newArray;
            });
        }
    };

    return (
        <>
            <PluginViewWrapper
                title={'Категории'}
                id={id}
                parentId={parentId}
                render={(isEdit: any, setIsEdit: any) => {
                    return (
                        <GridSettings
                            isOpen={isEdit}
                            setIsOpen={setIsEdit}
                            title={'Категории - настройка'}
                            render={({ handleClose }) => {
                                return (
                                    <CategoriesSettings
                                        initialValues={meta}
                                        onSave={(
                                            values: IInitValuesForm,
                                        ) => {
                                            onSubmit(
                                                values,
                                            );
                                            handleClose();
                                        }}
                                    />
                                );
                            }}
                        />
                    );
                }}
            >
                <div className={s.wrapper({})}>
                    <div className={s.content}>
                        <DndContext
                            sensors={sensors}
                            onDragEnd={handleDragEnd}
                        >
                            <SortableContext
                                items={categoriesList}
                                id="categories"
                            >
                                <div
                                    className={s.categories}
                                >
                                    {categoriesList.map(
                                        (id) => {
                                            const curCat =
                                                meta.categories.find(
                                                    (
                                                        item,
                                                    ) =>
                                                        item.value ===
                                                        id,
                                                );
                                            if (!curCat)
                                                return null;
                                            return (
                                                <SortableItem
                                                    cat={
                                                        curCat
                                                    }
                                                    key={id}
                                                />
                                            );
                                        },
                                    )}
                                </div>
                            </SortableContext>
                        </DndContext>
                    </div>
                </div>
            </PluginViewWrapper>
        </>
    );
};

export { Categories };
