import React, { useState } from 'react';
import 'react-calendar/dist/Calendar.css';

import Calendar from 'react-calendar';
import {
    button,
    buttonText,
    buttonWrapper,
    calendarWrapper,
} from './DatePicker.css';
import { Typography } from '@mega/ui';

interface DateRangePickerProps {
    onChange: any;
    initValue?: any;
    maxDate?: any;
}

export const DateRangePicker: React.FC<
    DateRangePickerProps
> = (props) => {
    const { onChange, initValue, maxDate } = props;
    const [value, setValue] = useState(initValue);
    const handlerOnChange = (date: any) => {
        setValue(date);
        if (onChange && typeof onChange === 'function')
            onChange(date);
    };
    const setRange = (range: any) => {
        const weekAgo = new Date();
        weekAgo.setDate(weekAgo.getDate() - range);
        const date = [weekAgo, new Date()];
        setValue(date);
        onChange(date);
    };
    return (
        <div className={calendarWrapper}>
            <Calendar
                onChange={handlerOnChange}
                value={value}
                prev2Label={null}
                next2Label={null}
                selectRange
                maxDate={maxDate}
            />
            <div className={buttonWrapper}>
                <div
                    className={button}
                    onClick={() => setRange(7)}
                    aria-hidden="true"
                >
                    <Typography className={buttonText}>
                        За неделю
                    </Typography>
                </div>
                <div
                    className={button}
                    onClick={() => setRange(30)}
                    aria-hidden="true"
                >
                    <Typography className={buttonText}>
                        За месяц
                    </Typography>
                </div>
                <div
                    className={button}
                    onClick={() => setRange(365)}
                    aria-hidden="true"
                >
                    <Typography className={buttonText}>
                        За год
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default DateRangePicker;
