import React from 'react';
import { ArticleCreate as ArticleCreateType } from '@mega/api';
import { ArticleCreate } from '@mega/forms';
import { useArticleDispatch } from '@mega/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

export const ArticlesNew: React.FC = () => {
    const { create } = useArticleDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const location = useLocation();
    const navigate = useNavigate();

    const handleCreate = async (
        value: ArticleCreateType,
    ) => {
        return create({
            payload: value,
            options: {
                router: {
                    navigate,
                    location,
                },
                notifier: {
                    enqueueSnackbar,
                },
            },
        });
    };

    return <ArticleCreate handleCreate={handleCreate} />;
};
