import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgFile = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M5.833 11.041a.625.625 0 1 0 0 1.25h5.834a.625.625 0 1 0 0-1.25H5.833ZM5.208 15c0-.345.28-.625.625-.625H10a.625.625 0 0 1 0 1.25H5.833A.625.625 0 0 1 5.208 15Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.943 1.324c-.591-.283-1.237-.283-2.132-.282h-.18c-1.216 0-2.208 0-2.989.13-.827.139-1.5.435-2.016 1.08-.5.625-.714 1.402-.816 2.357-.102.941-.102 2.147-.102 3.686v3.41c0 1.539 0 2.745.102 3.686.102.955.317 1.732.816 2.356.516.645 1.186.942 2.011 1.08.778.131 1.764.131 2.974.131h2.778c1.21 0 2.196 0 2.974-.13.825-.139 1.495-.436 2.01-1.08.5-.625.715-1.402.817-2.357.102-.941.102-2.147.102-3.686v-.528c0-1.342 0-2.175-.267-2.925-.272-.763-.784-1.339-1.581-2.235L12.639 2.86c-.664-.747-1.12-1.26-1.696-1.537Zm-.735 1.054c-.276-.077-.665-.086-1.521-.086-1.286 0-2.172.002-2.838.113-.632.106-.982.297-1.247.628-.281.352-.457.85-.55 1.71-.093.866-.094 2.005-.094 3.59v3.334c0 1.585.001 2.724.094 3.59.093.86.269 1.358.55 1.71.266.331.614.522 1.242.628.663.111 1.544.113 2.823.113h2.666c1.28 0 2.16-.002 2.823-.113.627-.106.976-.297 1.242-.628.281-.352.457-.85.55-1.71.093-.866.094-2.005.094-3.59v-.365c0-1.243-.006-1.87-.112-2.344h-1.911c-.717 0-1.338 0-1.836-.092-.554-.102-1.025-.328-1.38-.815-.335-.46-.47-1.017-.532-1.663-.063-.636-.063-1.446-.063-2.465V2.378Zm5.046 5.33c-.203-.25-.468-.55-.82-.946l-2.64-2.969c-.123-.14-.235-.264-.336-.376v.472c0 1.06.001 1.81.057 2.377.055.565.158.857.298 1.048.12.164.268.263.596.323.372.069.877.071 1.665.071h1.18Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgFile);
export default ForwardRef;
