import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';
import { useRef } from 'react';
import { debounce } from 'lodash-es';

const useBannedOrganizationsStore = () => {
    const state = useStoreSelector(
        (state: RootState) => state.bannedOrganizations,
    );
    const loading = useStoreSelector(
        (state: RootState) =>
            state.loading.effects.bannedOrganizations,
    );

    return Object.assign(state, {
        loading: {
            get: loading.get,
            chungeCountItemsOnPage:
                loading.chungeCountItemsOnPage,
            jumpTo: loading.jumpTo,
        },
    });
};
const useBannedOrganizationsDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    const debouncedHandleSetCurrentPage = useRef(
        debounce(
            dispatch.bannedOrganizations.setCurrentPage,
            1000,
        ),
    );

    return {
        get: dispatch.bannedOrganizations.get,
        nextPage: dispatch.bannedOrganizations.nextPage,
        jumpTo: dispatch.bannedOrganizations.jumpTo,
        setCurrentPage:
            debouncedHandleSetCurrentPage.current,
        search: dispatch.bannedOrganizations.search,
        clear: dispatch.bannedOrganizations.clear,
    };
};

export {
    useBannedOrganizationsStore,
    useBannedOrganizationsDispatch,
};
