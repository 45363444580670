import React, {
    FC,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    useArticleDispatch,
    useArticleRoom,
} from '@mega/store';
import { useParams } from 'react-router-dom';
import { useField, useFormikContext } from 'formik';
import { Article } from '@mega/api';
import dayjs from 'dayjs';
import { throttle } from 'lodash-es';
import { Button, InputDate, Paper, Row } from '@mega/ui';
import { articleEditFormClasses } from '../ArticleEdit.css';
import { useApostrophDispatch } from '@mega/store';
import { ArticleEditMultipost } from '../ArticleEditMultipost';
import { useSettings } from '@mega/store';
interface ArticleEditActionsProps {
    handleGoToSite?: () => void;
}

function usePrevious(value: boolean | undefined) {
    const ref = useRef<boolean | undefined>();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

const UnPublishButton: FC<{
    isReadonly?: boolean;
    isSubmitting?: boolean;
}> = ({ isReadonly, isSubmitting }) => {
    const { submitForm } = useFormikContext<Article>();

    const [, , { setValue }] = useField<boolean>({
        name: 'isUnPublish',
    });

    const handleChangeStatus = () => {
        setValue(true);
        return submitForm();
    };

    return !isReadonly ? (
        <Button
            disabled={isSubmitting}
            size={'large'}
            label={'Снять с публикации'}
            onClick={handleChangeStatus}
            status={'error'}
            classes={{
                paper: {
                    color: 'dark',
                    variant: 'filled',
                },
            }}
        />
    ) : null;
};

const PublishButton: FC<{
    isReadonly?: boolean;
    isSubmitting?: boolean;
}> = ({ isReadonly, isSubmitting }) => {
    const { submitForm } = useFormikContext<Article>();

    const [, , { setValue }] = useField<boolean>({
        name: 'isPublish',
    });

    const handleChangeStatus = () => {
        setValue(true);
        return submitForm();
    };

    return !isReadonly ? (
        <Button
            disabled={isSubmitting}
            size={'large'}
            label={
                'Опубликовать'
                // value
                //     ? 'Снять с публикации'
                //     : ''
            }
            onClick={handleChangeStatus}
            status={undefined}
            classes={{
                paper: {
                    color: 'dark',
                    variant: 'filled',
                },
            }}
        />
    ) : null;
};

const SaveButton: FC<{
    isReadonly?: boolean;
    isSubmitting?: boolean;
}> = ({ isReadonly, isSubmitting }) => {
    const { submitForm } = useFormikContext<Article>();

    const [, , { setValue }] = useField<boolean>({
        name: 'isSave',
    });

    const handleChangeStatus = () => {
        setValue(true);
        return submitForm();
    };

    const throttledSubmit = useMemo(
        () => throttle(handleChangeStatus, 1000),
        [],
    );

    return !isReadonly ? (
        <Button
            disabled={isSubmitting}
            size={'large'}
            type={'submit'}
            label={'Сохранить изменения'}
            onClick={throttledSubmit}
            classes={{
                paper: {
                    color: 'dark',
                    variant: 'filled',
                },
            }}
        />
    ) : null;
};

export const ArticleEditActions: FC<
    ArticleEditActionsProps
> = ({ handleGoToSite }) => {
    const { setIsFormatted, setIsSearchedInAgent } =
        useApostrophDispatch();
    const { id } = useParams();
    const { isReadonly } = useArticleRoom();
    const { get } = useArticleDispatch();
    const { values, setFieldValue, isSubmitting } =
        useFormikContext<Article>();
    const [isOpen, setIsOpen] = useState(false);

    const { config } = useSettings();
    const isMultipostingRequired =
        config.article.change.multiposting;

    const prevIsReadonly = usePrevious(isReadonly);
    useEffect(() => {
        if (isReadonly === false && prevIsReadonly) {
            if (id) {
                window.location.reload();
            }
        }
    }, [prevIsReadonly, isReadonly, get, id]);
    const timeTemplate = 'YYYY-MM-DDTHH:mm';

    const [currentDate, setCurrentDate] = useState(
        dayjs().format(timeTemplate),
    );
    const [timeIsChanged, setTimeIsChanged] =
        useState(false);

    useEffect(() => {
        setInterval(
            () =>
                setCurrentDate(
                    dayjs().format(timeTemplate),
                ),
            30000,
        );
    }, []);

    useEffect(() => {
        if (!timeIsChanged && !values.is_active) {
            setFieldValue('pub_date', currentDate);
        }
    }, [currentDate]);

    return (
        <Paper
            style={{
                borderTop: '1px solid #EAEAEA',
            }}
            fullWidth
            color={'white'}
            variant="filled"
            classes={{
                padding: { size: '24x24' },
            }}
        >
            <Row gap="16">
                <SaveButton
                    isReadonly={isReadonly}
                    isSubmitting={isSubmitting}
                />
                <Button
                    disabled={isSubmitting}
                    size={'large'}
                    type={'submit'}
                    label={
                        values.is_active
                            ? 'Посмотреть на сайте'
                            : 'Предварительный просмотр'
                    }
                    onClick={handleGoToSite}
                    classes={{
                        paper: {
                            color: 'dark',
                            variant: 'outline',
                        },
                    }}
                    animate={'secondary'}
                />
                {values.is_active ? (
                    <UnPublishButton
                        isReadonly={isReadonly}
                        isSubmitting={isSubmitting}
                    />
                ) : (
                    <PublishButton
                        isReadonly={isReadonly}
                        isSubmitting={isSubmitting}
                    />
                )}
                <div
                    className={
                        articleEditFormClasses.dateWrapper
                    }
                >
                    <InputDate
                        name="pub_date"
                        onChange={() =>
                            setTimeIsChanged(true)
                        }
                    />
                </div>
                <div>
                    <Button
                        size={'large'}
                        label="Типографировать текст"
                        classes={{
                            paper: {
                                variant: 'filled',
                                color: 'dark',
                            },
                        }}
                        onClick={() => {
                            setIsFormatted({
                                isFormatted: true,
                            });
                        }}
                    />
                </div>
                <div>
                    <Button
                        size={'large'}
                        label="Поиск иноагентов"
                        classes={{
                            paper: {
                                variant: 'filled',
                                color: 'dark',
                            },
                        }}
                        onClick={() => {
                            setIsSearchedInAgent({
                                isSearchedInAgent: true,
                            });
                        }}
                    />
                </div>
                {isMultipostingRequired ? (
                    <ArticleEditMultipost />
                ) : null}
            </Row>
        </Paper>
    );
};
