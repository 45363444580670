import { PluginTemplate } from '@pages/smartGrid/types/blocks';

export enum MostReadEnum {
    type = 'MOST_READ',
}

export interface MostReadProps {
    id: string;
    type: string;
    parentId: string | null;
    text: string;
}

export type MostReadMeta = {};

export type MostReadPlugin = PluginTemplate<
    MostReadEnum.type,
    MostReadMeta
>;
export type MostReadPluginDefault = Omit<
    MostReadPlugin,
    'id'
>;
