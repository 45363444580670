import { MainGrid } from '@mega/api';
import { Input, Typography } from '@mega/ui';
import cn from 'classnames';
import {
    Dispatch,
    MouseEvent,
    SetStateAction,
    useEffect,
    useState,
} from 'react';
import { ContextMenu } from '../shared/ContextMenu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { styles as s } from './Tabs.css';

export interface TabProps {
    item: MainGrid;
    isActive: boolean;
    disabled: boolean;
    handleChangeTab: (
        e: React.MouseEvent<HTMLDivElement>,
        item: MainGrid,
    ) => null | undefined;

    tabTitle: string;
    setTabTitle: Dispatch<SetStateAction<string>>;
    handleCopy: (item?: MainGrid) => void;
    handleUpdate: (id: number, payload: MainGrid) => void;
}

export const Tab: React.FC<TabProps> = ({
    item,
    isActive,
    disabled,
    handleChangeTab,
    tabTitle,
    setTabTitle,
    handleUpdate,
    handleCopy,
}) => {
    const [isEditMode, setIsEditMode] =
        useState<boolean>(false);

    useEffect(() => {
        if (!isActive) setIsEditMode(false);
    }, [isActive]);

    const handleStartTitleEdit = () => {
        setTabTitle('');
        setIsEditMode((prev) => !prev);
    };

    const handleConfirmTitleEdit = (
        e: MouseEvent<HTMLButtonElement>,
        item: MainGrid,
    ) => {
        if (!item.id) {
            return;
        } else {
            const newItem = { ...item, title: tabTitle };
            handleUpdate(item.id, newItem);
            setIsEditMode((prev) => !prev);
            setTabTitle('');
        }
    };

    const restTabsConfig = [
        {
            label: 'Скопировать',
            icon: <CopyAllIcon fontSize="small" />,
            cb: handleCopy,
        },
        {
            label: 'Переименовать',
            icon: <EditIcon fontSize="small" />,
            cb: handleStartTitleEdit,
        },
    ];

    return (
        <>
            <div
                onClick={(e) => handleChangeTab(e, item)}
                className={cn(
                    s.navItem({
                        isCurActive: isActive,
                    }),
                    s.disabled({
                        isDisabled: disabled,
                    }),
                )}
                key={item.id}
            >
                <Typography size={'14'}>
                    {item?.title ? item.title : item.id}
                </Typography>

                <div
                    className={s.editTitleWrapper({
                        isEditMode,
                    })}
                >
                    <Input
                        style={{
                            minWidth: '100px',
                            maxWidth: 'fit-content',
                        }}
                        name="tabTitle"
                        dimension="small"
                        value={tabTitle}
                        className={s.editInputField}
                        onChange={(e) =>
                            setTabTitle(e.target.value)
                        }
                    />
                    <button
                        className={s.editTitleButton({})}
                        onClick={(e) =>
                            handleConfirmTitleEdit(e, item)
                        }
                        type="button"
                    >
                        <CheckIcon />
                    </button>
                </div>
                <div
                    className={s.copyAll({
                        isActive: isActive && !isEditMode,
                    })}
                >
                    {isActive && (
                        <ContextMenu<MainGrid>
                            item={item}
                            config={restTabsConfig}
                            icon={<MoreHorizIcon />}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
