import * as CLIENT from '@mega/api';
import type {
    GetStream,
    GetStreamQuery,
    GetStreamError,
    CreateStream,
    CreateStreamError,
    CreateStreamQuery,
    UpdateStream,
    UpdateStreamError,
    UpdateStreamQuery,
    PartialUpdateStream,
    PartialUpdateStreamError,
    PartialUpdateStreamQuery,
    DeleteStream,
    DeleteStreamError,
    DeleteStreamQuery,
    IisOk,
    IisNotOk,
} from '@mega/api';

export type GetStreamPromise = Promise<
    IisOk<GetStream> | IisNotOk<GetStreamError | null>
>;

export const getStreamById = async (
    query: GetStreamQuery,
    site: string,
): GetStreamPromise => {
    try {
        const response = await CLIENT?.streams?.getById?.(
            {
                ...query,
            },
            {
                headers: {
                    'X-Site': site,
                },
            },
        );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.streams.get &&
            e instanceof CLIENT.streams.get.Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};

export type CreateStreamPromise = Promise<
    IisOk<CreateStream> | IisNotOk<CreateStreamError | null>
>;

export const createStream = async (
    query: CreateStreamQuery,
    site: string,
): GetStreamPromise => {
    try {
        const response = await CLIENT?.streams?.create?.(
            {
                ...query,
            },
            {
                headers: {
                    'X-Site': site,
                },
            },
        );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.streams.create &&
            e instanceof CLIENT.streams.create.Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};

export type UpdateStreamPromise = Promise<
    IisOk<UpdateStream> | IisNotOk<UpdateStreamError | null>
>;

export const updateStreamById = async (
    query: UpdateStreamQuery,
    site: string,
): UpdateStreamPromise => {
    try {
        const response =
            await CLIENT?.streams?.updateById?.(
                {
                    ...query,
                },
                {
                    headers: {
                        'X-Site': site,
                    },
                },
            );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.streams.updatePartialById &&
            e instanceof
                CLIENT.streams.updatePartialById.Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};

export type PartialUpdateStreamPromise = Promise<
    | IisOk<PartialUpdateStream>
    | IisNotOk<PartialUpdateStreamError | null>
>;

export const partialUpdateStreamById = async (
    query: PartialUpdateStreamQuery,
    site: string,
): PartialUpdateStreamPromise => {
    try {
        const response =
            await CLIENT?.streams?.updatePartialById?.(
                {
                    ...query,
                },
                {
                    headers: {
                        'X-Site': site,
                    },
                },
            );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.streams.get &&
            e instanceof CLIENT.streams.get.Error
        ) {
            const error = e.getActualType();

            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};

export type DeleteStreamPromise = Promise<
    IisOk<DeleteStream> | IisNotOk<DeleteStreamError | null>
>;

export const deleteStreamById = async (
    query: DeleteStreamQuery,
    site: string,
): DeleteStreamPromise => {
    try {
        const response =
            await CLIENT?.streams?.deleteById?.(
                {
                    ...query,
                },
                {
                    headers: {
                        'X-Site': site,
                    },
                },
            );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.streams.get &&
            e instanceof CLIENT.streams.get.Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};
