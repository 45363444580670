import React from 'react';

import { PaperClasses, paperClasses } from '@mega/styles';

import {
    dividerClasses,
    RootVariants,
} from './Divider.css';

export interface DividerProps {}

export const Divider: React.FC<
    DividerProps & RootVariants
> = ({ mt, mb, color }) => (
    <hr
        className={[
            dividerClasses.root({
                mt,
                mb,
                color,
            }),
        ].join(' ')}
    />
);
