import React, { FC } from 'react';
import {
    blockView,
    gapBlockViewVar,
    paddingBlockViewVar,
    alignItemsBlockViewVar,
    justifyContentBlockViewVar,
} from '@apostroph/styles';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { isString } from 'lodash';

const BlockView: FC<Record<string, unknown>> = ({
    children,
    ...props
}) => {
    const align =
        (props?.align || 'row') === 'column'
            ? 'column'
            : 'row';
    // const backgroundColor = props?.backgroundColor || '';
    const gap = isString(props?.gap) ? props?.gap : '';
    const padding = isString(props?.padding)
        ? props?.padding
        : '';
    const horizontalAlign = props?.horizontalAlign || '';
    const verticalAlign = props?.verticalAlign || '';
    const wrap =
        (props?.wrap || 'nowrap') === 'nowrap'
            ? 'nowrap'
            : 'wrap';
    const stretch =
        (props?.stretch || 'nostretch') === 'nostretch'
            ? 'nostretch'
            : 'stretch';

    const resolveAlignItems =
        stretch === 'stretch'
            ? 'stretch'
            : horizontalAlign === 'left'
            ? 'flex-start'
            : horizontalAlign === 'center'
            ? 'center'
            : horizontalAlign === 'right'
            ? 'flex-end'
            : 'flex-start';

    const resolveJustifyContent =
        verticalAlign === 'top'
            ? 'flex-start'
            : verticalAlign === 'center'
            ? 'center'
            : verticalAlign === 'buttom'
            ? 'flex-end'
            : 'flex-start';

    return (
        <div
            className={blockView({
                wrap,
                orientation: align,
            })}
            style={assignInlineVars({
                [gapBlockViewVar]: gap,
                [paddingBlockViewVar]: padding,
                [alignItemsBlockViewVar]: resolveAlignItems,
                [justifyContentBlockViewVar]:
                    resolveJustifyContent,
            })}
        >
            {children}
        </div>
    );
};

export { BlockView };
export default { BlockView };
