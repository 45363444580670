import React, { FC } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { useEmployee } from '@mega/store';
import { EmployeeFormDataCreate } from '@mega/api';
import { extraClass } from './EmployeeCreate.css';

import {
    Button,
    FormLayout,
    Row,
    Paper,
    Typography,
} from '@mega/ui';

import { Info } from '@mega/icons';
import { EmployeeFormBody } from '../EmployeeFormBody';

type FormValues = {
    is_active: boolean;
    first_name: string;
    last_name: string;
    patronymic: string;
    email: string;
    username: string;
    author: null | {};
    telegram: string;
    phone: string;
    sites: {}[];
    groups: [];
    filter: string[];
};

const initialValues: FormValues = {
    is_active: false,
    first_name: '',
    last_name: '',
    patronymic: '',
    email: '',
    username: '',
    author: null,
    telegram: '',
    phone: '',
    sites: [],
    groups: [],
    filter: [],
};

const validationSchema = Yup.object({
    is_active: Yup.boolean(),
    first_name: Yup.string().required(
        'Это поле обязательное',
    ),
    last_name: Yup.string().required(
        'Это поле обязательное',
    ),
    patronymic: Yup.string(),
    email: Yup.string()
        .email('Невалидный адрес электронной почты')
        .required('Это поле обязательное'),
    username: Yup.string()
        .min(5, 'Логин должен быть не менее 5 символов')
        .matches(
            /^[a-zA-Z0-9@.+/_'-]{1,150}$/,
            'Логин невалидный',
        )
        .required('Это поле обязательное'),
    telegram: Yup.string().matches(
        /^@[a-zA-Z0-9](?:[a-zA-Z0-9_]{0,28}[a-zA-Z0-9])?$/,
        'Телеграм невалидный',
    ),
    phone: Yup.string().matches(
        /^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/,
        'Номер телефона невалидный',
    ),
    author: Yup.object({
        id: Yup.number(),
        label: Yup.string(),
    })

        .nullable()
        .typeError('Это поле обязательное')
        .required('Это поле обязательное'),
    groups: Yup.array().required('Это поле обязательное'),
});

const EmployeeCreateActions: FC = (): JSX.Element => {
    const { submitForm, isSubmitting } = useFormikContext();
    return (
        <Paper
            style={{
                borderTop: '1px solid #EAEAEA',
                marginLeft: '20px',
            }}
            fullWidth
            color={'white'}
            variant="filled"
            classes={{
                padding: { size: '24x24' },
            }}
        >
            <Row gap="16">
                <Button
                    classes={{
                        paper: {
                            variant: 'filled',
                            color: 'dark',
                        },
                    }}
                    disabled={isSubmitting}
                    color="secondary"
                    size={'large'}
                    type={'submit'}
                    label={'Создать'}
                    onClick={submitForm}
                />
                <Info
                    style={{
                        width: '24px',
                        height: '24px',
                        alignSelf: 'center',
                    }}
                />
                <Typography
                    weight="regular"
                    size="12"
                    style={{
                        color: '#878787',
                        alignSelf: 'center',
                    }}
                    as="div"
                >
                    По нажатию кнопки пользователь получит
                    письмо
                    <br /> с регистрационными данными на
                    email, указанный при создании
                </Typography>
            </Row>
        </Paper>
    );
};

const extractStringsFromBrackets = (
    s: string,
): string[] => {
    const start_index = s.indexOf('(');
    const end_index = s.indexOf(')', start_index);

    if (start_index !== -1 && end_index !== -1) {
        const content = s.substring(
            start_index + 1,
            end_index,
        );
        return content.split(', ');
    } else {
        return [];
    }
};

const EmployeeCreate: FC = (): JSX.Element => {
    const { createFromForm } = useEmployee();

    const handleSubmit = (
        values: typeof initialValues,
        actions,
    ) => {
        const result: EmployeeFormDataCreate = {
            ...values,
            author: values.author.id,
            sites: extractStringsFromBrackets(
                values.author.label,
            ),
            groups: values.groups.map((el) => el.id),
        };
        createFromForm(result, actions);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {() => (
                <Form>
                    <FormLayout
                        actions={<EmployeeCreateActions />}
                        className={extraClass}
                    >
                        <EmployeeFormBody type="create" />
                    </FormLayout>
                </Form>
            )}
        </Formik>
    );
};

export { EmployeeCreate };
