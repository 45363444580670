/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { FC, ReactElement, useState } from 'react';

import {
    Form,
    Formik,
    useField,
    useFormikContext,
} from 'formik';
import {
    Button,
    DatePickerField,
    FormLayout,
    // Loader,
    Paper,
    Row,
    Stack,
    Typography,
} from '@mega/ui';
import {
    ArticleReportPage,
    IndicatorsReportPage,
    SelectReportType,
    VideoReportPage,
} from './components';
import {
    ReportFormValues,
    ReportTypes,
} from './Reports.types';

import { NewsMosRegReportPage } from './components/NewMosRegReportPage';

// import { useReport } from './hooks/useReport';

export interface ReportEditProps {
    initialValue?: any;
    handleUpdate?: (value: any) => void;
}

const StepPage: FC<{
    page: number;
    currentPage: number;
    children: ReactElement | ReactElement[];
}> = ({ currentPage, page, children }) => {
    if (currentPage === page) {
        return <>{children}</>;
    }
    return null;
};

const FirstStep: FC<{ currentStep: number }> = ({
    currentStep,
}) => {
    const [fieldDateGte] = useField('pub_date__date__gte');
    return (
        <StepPage currentPage={currentStep} page={1}>
            <Row gap={'8'}>
                <Row gap={'4'}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minWidth: '90px',
                        }}
                    >
                        <Typography size="16">
                            Дата с:
                        </Typography>
                    </div>
                    <DatePickerField name="pub_date__date__gte" />
                </Row>
                <Row gap={'4'}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minWidth: '90px',
                        }}
                    >
                        <Typography size="16">
                            Дата по:
                        </Typography>
                    </div>
                    <DatePickerField
                        name="pub_date__date__lte"
                        minDate={
                            new Date(fieldDateGte.value)
                        }
                    />
                </Row>
            </Row>
        </StepPage>
    );
};

const TypeReportPage: FC<{
    currentStep: number;
}> = ({ currentStep }) => {
    return (
        <StepPage currentPage={currentStep} page={0}>
            <SelectReportType />
        </StepPage>
    );
};

const render = (type: ReportTypes) => {
    console.log(type);
    switch (type || 'unknown') {
        case 'articles':
            return (
                <ArticleReportPage
                    key={'SelectReportPage'}
                />
            );
        case 'videos':
            return (
                <VideoReportPage key={'SelectReportPage'} />
            );
        case 'indicators':
            return (
                <IndicatorsReportPage
                    key={'SelectReportPage'}
                />
            );
        case 'newsmosreg':
            return (
                <NewsMosRegReportPage
                    key={'SelectReportPage'}
                />
            );
        default:
            return <div></div>;
    }
};

const SelectReportPage: FC<{
    currentStep: number;
}> = ({ currentStep }) => {
    const {
        values: { report_type },
    } = useFormikContext<ReportFormValues>();

    return (
        <StepPage currentPage={currentStep} page={1}>
            {render(report_type?.id)}
        </StepPage>
    );
};

const ArticleReport: FC<ReportEditProps> = () => {
    const [currentStep, setStep] = useState<number>(0);

    const handleUpdate = (mod: 'next' | 'prev') => {
        if (mod === 'next') {
            setStep(currentStep + 1);
        }
        if (mod === 'prev') {
            setStep(currentStep - 1);
        }
    };

    const handleSubmit = () => {};

    return (
        <Formik initialValues={{}} onSubmit={handleSubmit}>
            <Form
                style={{
                    minHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <FormLayout
                    actions={
                        <ReportAction
                            currentStep={currentStep}
                            handleChangeStep={handleUpdate}
                        />
                    }
                >
                    <Paper
                        variant="outline"
                        color="white"
                        classes={{
                            padding: {
                                size: '24x24',
                            },
                        }}
                        style={{
                            height: '100%',
                            minHeight: '500px',
                        }}
                    >
                        <Stack gap="18">
                            <FirstStep
                                currentStep={currentStep}
                            />
                            <TypeReportPage
                                currentStep={currentStep}
                            />
                            <SelectReportPage
                                currentStep={currentStep}
                            />
                        </Stack>
                    </Paper>
                </FormLayout>
            </Form>
        </Formik>
    );
};

export { ArticleReport };

const ReportAction: FC<{
    currentStep: number;
    handleChangeStep: (mod: 'next' | 'prev') => void;
}> = ({ currentStep, handleChangeStep }) => {
    const { resetForm } = useFormikContext();
    const { values } = useFormikContext<ReportFormValues>();

    const handleClick = (mod: 'next' | 'prev') => {
        handleChangeStep(mod);
        if (mod === 'prev') resetForm();
    };

    const hasFileFormat = Boolean(values.file_format);
    const isIndicators =
        values?.report_type?.id === 'indicators';

    return (
        <Paper
            style={{
                borderTop: '1px solid #EAEAEA',
            }}
            fullWidth
            color={'white'}
            variant="filled"
            classes={{
                padding: {
                    size: '24x24',
                },
            }}
        >
            <div>
                {currentStep === 0 && (
                    <Button
                        disabled={
                            isIndicators
                                ? false
                                : !hasFileFormat
                        }
                        onClick={() => handleClick('next')}
                        type="submit"
                        size={'large'}
                        label={'Далее'}
                        classes={{
                            paper: {
                                color: 'dark',
                                variant: 'filled',
                            },
                        }}
                    />
                )}

                {currentStep == 1 && (
                    <Row gap="4">
                        <Button
                            onClick={() =>
                                handleClick('prev')
                            }
                            type={'submit'}
                            size={'large'}
                            label={'Назад'}
                            classes={{
                                paper: {
                                    color: 'dark',
                                    variant: 'filled',
                                },
                            }}
                        />
                    </Row>
                )}
            </div>
        </Paper>
    );
};
