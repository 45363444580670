import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgReference = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 18 18"
        fill="none"
        ref={ref}
        {...props}
    >
        <path
            d="M2.25 3.375c0-.3.112-.563.337-.788a1.08 1.08 0 0 1 .788-.337h11.25c.3 0 .563.112.787.337.225.225.338.488.338.788v11.25c0 .3-.113.563-.338.787a1.08 1.08 0 0 1-.787.338H3.375c-.3 0-.563-.113-.788-.338a1.08 1.08 0 0 1-.337-.787V3.375Zm1.125 0v11.25h11.25V3.375H3.375Z"
            fill="currentColor"
        />
        <rect
            x={5}
            y={5}
            width={8}
            height={1.5}
            rx={0.75}
            fill="currentColor"
        />
        <rect
            x={5}
            y={8.25}
            width={8}
            height={1.5}
            rx={0.75}
            fill="currentColor"
        />
        <rect
            x={5}
            y={11.5}
            width={4}
            height={1.5}
            rx={0.75}
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgReference);
export default ForwardRef;
