import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgInfo = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 21a9 9 0 1 1 0-18 9 9 0 0 1 0 18ZM1 12c0 6.075 4.925 11 11 11s11-4.925 11-11S18.075 1 12 1 1 5.925 1 12Z"
            fill="#C7C7C7"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 17a1 1 0 0 0 1-1v-4a1 1 0 1 0-2 0v4a1 1 0 0 0 1 1ZM11 8a1 1 0 0 0 1 1h.01a1 1 0 1 0 0-2H12a1 1 0 0 0-1 1Z"
            fill="#C7C7C7"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgInfo);
export default ForwardRef;
