import React from 'react';
import { ImgLoaderProps } from './VideoPicker.types';
import { DndFileLoader, FileViewer } from '@mega/core';
import { imgLoaderClasses } from './VideoPicker.css';
import { FilesObject } from '../MediaPickerModal/types';
import { useVideoDispatch } from '@mega/store';

export const VideoPicker: React.FC<ImgLoaderProps> = (
    props,
) => {
    const {
        handleLoad,
        handleDelete,
        id,
        fileSrc,
        poster,
        description,
        externalBlock,
    } = props;
    const { create, remove } = useVideoDispatch();

    const onUpload = async (cover: any) => {
        handleLoad(cover);
    };

    const handleCreate = async (data: any) => {
        const res = await create({
            payload: { data },
        });

        if (res?.data) {
            onUpload(res.data);
        }
    };

    return (
        <div className={imgLoaderClasses.base}>
            <DndFileLoader
                id={id}
                fileType={'video'}
                onDelete={handleDelete}
            >
                <FileViewer
                    isVideo
                    isModalView
                    fileSrc={fileSrc}
                    videoId={id}
                    videoPoster={poster}
                    description={description}
                    handleVideoSave={onUpload}
                />
            </DndFileLoader>
            {externalBlock &&
                React.cloneElement(externalBlock, {
                    handleSubmit: (values: FilesObject) => {
                        for (const [
                            key,
                            value,
                        ] of Object.entries(values)) {
                            if (value?.file?.src) {
                                handleCreate({
                                    video: value?.file?.src,
                                    title: value.title,
                                    description:
                                        description || '',
                                });
                            }
                        }
                    },
                })}
        </div>
    );
};
