import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgTableStrokeUp = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M12.75 15.25v3.25h-1.5v-3.25H8v-1.5h3.25V10.5h1.5v3.25H16v1.5h-3.25Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.25 5A2.75 2.75 0 0 1 5 2.25h14A2.75 2.75 0 0 1 21.75 5v14A2.75 2.75 0 0 1 19 21.75H5A2.75 2.75 0 0 1 2.25 19V5Zm1.5 0c0-.69.56-1.25 1.25-1.25h3.25v3.5h-4.5V5Zm6-1.25v3.5h4.5v-3.5h-4.5Zm-6 5V19c0 .69.56 1.25 1.25 1.25h14c.69 0 1.25-.56 1.25-1.25V8.75H3.75Zm12-1.5v-3.5H19c.69 0 1.25.56 1.25 1.25v2.25h-4.5Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgTableStrokeUp);
export default ForwardRef;
