import React, { FC, PropsWithChildren } from 'react';
import {
    components,
    IndicatorsContainerProps,
} from 'react-select';

interface IndClass {
    className?: string;
}

type IndicatorsContainerOverrideProps =
    IndicatorsContainerProps<Record<string, unknown>, true>;

const IndicatorsContainer =
    ({ className = '' }: IndClass) =>
    ({
        ...props
    }: PropsWithChildren<IndicatorsContainerOverrideProps>) =>
        (
            <components.IndicatorsContainer
                className={className}
                {...props}
            />
        );

export { IndicatorsContainer };
export default IndicatorsContainer;
