import React from 'react';
import { Form, Formik } from 'formik';
import { Button } from '@mega/ui';
import {
    useMainGridDispatch,
    useMainGridStore,
} from '@mega/store';
import { useSnackbar } from 'notistack';
import { GridEditorField } from '@pages/smartGrid/components/GridEditorField';
import { initialData } from '@pages/smartGrid/constants/data';
import { AcceptModal } from '@mega/core';

export interface FormikWrapperProps {}

export const FormikWrapper: React.FC<FormikWrapperProps> = (
    props,
) => {
    const { create, update, remove } =
        useMainGridDispatch();
    const store = useMainGridStore();

    const { goToSite } = useMainGridDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { active, latest } = store;

    const handlePreview = () => {
        goToSite('');
    };
    const handleCreate = (values: any) => {
        return create({
            payload: {
                ...values,
            },
            options: {
                notifier: {
                    enqueueSnackbar,
                },
            },
        });
    };

    const handleUpdate = (values: any) => {
        return update({
            query: {
                id: values.id,
            },
            payload: values,
            options: {
                notifier: {
                    enqueueSnackbar,
                },
            },
        });
    };

    const handleSave = (values: any) => {
        const saveHandler = values.id
            ? handleUpdate
            : handleCreate;

        saveHandler(values);
    };

    // if (!active?.id || !latest?.id) {
    //     return <Loader size="medium" />;
    // }

    const handleRemove = (id?: number) => {
        if (id) {
            remove({
                query: { id },
                options: {
                    notifier: {
                        enqueueSnackbar,
                    },
                },
            });
        }
    };

    const curData = active?.editor_data
        ? active
        : latest?.editor_data
        ? latest
        : initialData;

    return (
        <Formik
            initialValues={curData}
            onSubmit={handleSave}
            enableReinitialize
        >
            {({ values, handleSubmit }) => {
                return (
                    <Form
                        style={{
                            display: 'grid',
                            gap: '1rem',
                            alignContent: 'flex-end',
                        }}
                    >
                        <GridEditorField
                            name={'editor_data'}
                            key={values?.id}
                        />
                        {/* TODO вынести кнопки в компонент*/}
                        <div
                            style={{
                                display: 'flex',
                                gap: '1rem',
                            }}
                        >
                            {values.is_active ? (
                                <AcceptModal
                                    msg="Вы уверенны что хотите изменить активную сетку?"
                                    Trigger={
                                        <Button
                                            size={'large'}
                                            type={'button'} // Отличается от сабмита ниже из за acceptModal
                                            onClick={() =>
                                                handleSubmit()
                                            }
                                            label={
                                                'Сохранить'
                                            }
                                            classes={{
                                                paper: {
                                                    color: 'dark',
                                                    variant:
                                                        'filled',
                                                },
                                            }}
                                        />
                                    }
                                />
                            ) : (
                                <Button
                                    size={'large'}
                                    type={'submit'}
                                    label={'Сохранить'}
                                    classes={{
                                        paper: {
                                            color: 'dark',
                                            variant:
                                                'filled',
                                        },
                                    }}
                                />
                            )}
                            <Button
                                size={'large'}
                                label={
                                    active?.is_active
                                        ? 'Посмотреть на сайте'
                                        : 'Предварительный просмотр'
                                }
                                onClick={handlePreview}
                                classes={{
                                    paper: {
                                        color: 'dark',
                                        variant: 'outline',
                                    },
                                }}
                            />
                            {!values.is_active && (
                                <>
                                    <Button
                                        size={'large'}
                                        type={'button'}
                                        label={
                                            'Назначить активной'
                                        }
                                        onClick={() => {
                                            handleSave({
                                                ...values,
                                                is_active:
                                                    true,
                                            });
                                        }}
                                        classes={{
                                            paper: {
                                                color: 'dark',
                                                variant:
                                                    'filled',
                                            },
                                        }}
                                    />
                                    <AcceptModal
                                        msg="Вы уверенны что хотите удалить сетку?"
                                        Trigger={
                                            <Button
                                                size={
                                                    'large'
                                                }
                                                label={
                                                    'Удалить'
                                                }
                                                onClick={() =>
                                                    handleRemove(
                                                        values?.id,
                                                    )
                                                }
                                                status={
                                                    'error'
                                                }
                                                classes={{
                                                    paper: {
                                                        color: 'dark',
                                                        variant:
                                                            'filled',
                                                    },
                                                }}
                                            />
                                        }
                                    />
                                </>
                            )}
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
