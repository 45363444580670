import React from 'react';
import {
    audioWrapper,
    iconWrapper,
} from './AudioPreview.css';
import { FiMic } from 'react-icons/fi';

export interface VideoPreviewProps {
    src: string;
    title?: string;
}

export const AudioPreview: React.FC<VideoPreviewProps> = (
    props,
) => {
    const { src } = props;
    return (
        <div className={audioWrapper}>
            <div className={iconWrapper}>
                <FiMic />
            </div>
            <div>
                <audio controls src={src} />
            </div>
        </div>
    );
};
