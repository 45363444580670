import { Form, Formik } from 'formik';
import { FC } from 'react';
import { ThemesForm } from './ThemesForm/ThemesForm';
import {
    IInitValuesForm,
    initValuesForm,
} from './ThemesForm/config';
import { validateThemes } from './ThemesForm/validate';

interface ThemesSettingsProps {
    initialValues?: IInitValuesForm;
    onSave: (values: IInitValuesForm) => void;
}

export const ThemesSettings: FC<ThemesSettingsProps> = ({
    onSave,
    initialValues = initValuesForm,
}) => {
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validateThemes}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSave}
        >
            {({ handleSubmit }) => {
                return (
                    <Form>
                        <ThemesForm
                            onSubmit={handleSubmit}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};
