/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { FC, useMemo } from 'react';

import {
    Form,
    Formik,
    useFormikContext,
    FormikHelpers,
} from 'formik';
import * as Yup from 'yup';

import type { BannedOrganization } from '@mega/api';
import { typographyClasses } from '@mega/styles';
import {
    Accordion,
    Button,
    CheckboxSwitchField,
    FormLayout,
    InputField,
    Paper,
    SelectSingleField,
    Stack,
    Typography,
} from '@mega/ui';
import { throttle } from 'lodash-es';

export interface BannedOrganizationEditProps {
    initialValue?: BannedOrganization;
    handleUpdate?: (
        value: BannedOrganization,
        actions: FormikHelpers<BannedOrganization>,
    ) => void;
}

const BannedOrganizationEditActions = () => {
    const { submitForm, isSubmitting } = useFormikContext();

    const throttledSubmit = useMemo(
        () => throttle(submitForm, 1000),
        [],
    );

    return (
        <>
            <Paper
                style={{
                    borderTop: '1px solid #EAEAEA',
                }}
                fullWidth
                color={'white'}
                variant="filled"
                classes={{
                    padding: { size: '24x24' },
                }}
            >
                <Button
                    animate="none"
                    size={'large'}
                    type={'submit'}
                    label={'Обновить'}
                    onClick={throttledSubmit}
                    classes={{
                        paper: {
                            color: 'dark',
                            variant: 'filled',
                        },
                    }}
                    disabled={isSubmitting}
                />
            </Paper>
        </>
    );
};

const BannedOrganizationEdit: FC<
    BannedOrganizationEditProps
> = ({
    handleUpdate = () => null,
    initialValue = { title: '', org_type: 'extremist' },
}) => {
    const rules = [
        'Выберите тип запрещенной организации',
        'Введите название (Имя Фамилия)',
        'Заполните аннотацию (* - организация признана террористической на территории РФ)',
        'Заполните синонимы. Минимальное количество символов – 4. Например, для «Исламского государства» допустимый синоним ИГИЛ, не ИГ. Для физ. лиц, вводите уменьшительные имена (Вероника – Ника), псевдонимы Мирон Фёдоров (Оксимирон), написания псевдонимов на других языках или с комбинацией кириллицы-латиницы.',
    ];
    return (
        <Formik
            validationSchema={Yup.object({
                title: Yup.string()
                    .min(
                        2,
                        'Это поле явно должно быть больше одного символа',
                    )
                    .required('Это поле обязательное'),
                org_type: Yup.object({
                    label: Yup.string(),
                    value: Yup.string(),
                })
                    .nullable()
                    .required('Это поле обязательное'),
            })}
            initialValues={{
                ...initialValue,
            }}
            onSubmit={handleUpdate}
        >
            <Form
                style={{
                    minHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <FormLayout
                    actions={
                        <BannedOrganizationEditActions />
                    }
                    settings={
                        <Paper
                            contentHeight
                            color={'white'}
                            variant="outline"
                            classes={{
                                padding: {
                                    size: '24x24',
                                },
                            }}
                        >
                            <Stack gap="18">
                                <Accordion
                                    label={'Настройки'}
                                    value="settings"
                                    isOpened
                                >
                                    <Stack gap="16">
                                        <Stack gap="8">
                                            <CheckboxSwitchField
                                                name="is_active"
                                                label="Запрещенная организация включена"
                                            />
                                        </Stack>
                                    </Stack>
                                </Accordion>
                                <Accordion
                                    label={'Правила работы'}
                                    value="settings"
                                    isOpened
                                >
                                    <Stack gap="16">
                                        <ol
                                            style={{
                                                paddingLeft:
                                                    '15px',
                                            }}
                                            className={[
                                                typographyClasses.recipe(
                                                    {
                                                        size: '14',
                                                        weight: 'medium',
                                                    },
                                                ),
                                            ].join(' ')}
                                        >
                                            {rules.map(
                                                (item) => {
                                                    return (
                                                        <li
                                                            style={{
                                                                marginBottom:
                                                                    '10px',
                                                            }}
                                                        >
                                                            {
                                                                item
                                                            }
                                                        </li>
                                                    );
                                                },
                                            )}
                                        </ol>
                                    </Stack>
                                </Accordion>
                            </Stack>
                        </Paper>
                    }
                >
                    <Paper
                        variant="outline"
                        color="white"
                        classes={{
                            padding: {
                                size: '24x24',
                            },
                        }}
                    >
                        <Stack gap="18">
                            <SelectSingleField
                                name={'org_type'}
                                placeholder="Тип"
                                handleLoader={async () => [
                                    {
                                        value: 'terrorist',
                                        label: 'Террористы',
                                    },
                                    {
                                        value: 'foreign_agent',
                                        label: 'Иноагенты',
                                    },
                                    {
                                        value: 'extremist',
                                        label: 'Экстремисты',
                                    },
                                ]}
                                isCreatable={false}
                                isClearable={false}
                            />
                            <InputField
                                isRequired
                                type="text"
                                name={'title'}
                                placeholder="Заголовок"
                                dimension="medium"
                            />
                            <InputField
                                type="text"
                                name={'description'}
                                placeholder="Шаблон аннотации"
                                dimension="medium"
                            />
                            <InputField
                                type="text"
                                name={'synonyms'}
                                placeholder="Синонимы, вводить строкой через запятые"
                                dimension="medium"
                            />
                        </Stack>
                    </Paper>
                </FormLayout>
            </Form>
        </Formik>
    );
};

export { BannedOrganizationEdit };
