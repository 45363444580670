import React from 'react';
import { definitions } from '@mega/api';
import { MilkRender } from '../ApostrophSerializer/MilkRender';
import { Typography } from '@mega/ui';
import {
    contentWrapper,
    styledDate,
    styledTopBlock,
} from './InfoUnitPreview.css';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { UnitInfo } from './UnitInfo';
import { MediaListing } from './MediaListing';
import { Urgent } from '@mega/core';
dayjs.locale('ru');

export interface InfoUnitPreviewProps {
    data: definitions['AdminInfoUnit'];
}

const timeTemplate = 'DD MMMM YYYY, HH:mm';

export const InfoUnitPreview: React.FC<
    InfoUnitPreviewProps
> = (props) => {
    const { data } = props;

    const {
        created_at,
        title,
        editor_data,
        files,
        images,
        audios,
        videos,
        themes,
        source,
    } = data;

    return (
        <div>
            <div className={styledTopBlock}>
                {created_at && (
                    <Typography
                        className={styledDate}
                        size="12"
                    >
                        {dayjs(created_at).format(
                            timeTemplate,
                        )}
                    </Typography>
                )}
                <Urgent
                    embargo={data.embargo}
                    urgency={data.urgency}
                />
            </div>
            <div className={contentWrapper}>
                <Typography weight={'bold'} size="32">
                    {title}
                </Typography>
                {themes?.[0] && source && (
                    <UnitInfo
                        theme={themes?.[0]}
                        source={source}
                    />
                )}
                <MilkRender content={editor_data} />
                <MediaListing
                    files={files}
                    audios={audios}
                    images={images}
                    videos={videos}
                />
            </div>
        </div>
    );
};
