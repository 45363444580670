import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paper } from '@mega/ui';
import { baseClasses, placeClasses } from '@mega/styles';
import { CreateUser } from '@mega/forms';
import { useAuthStore } from '@mega/store';

const Register = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { isAuth } = useAuthStore();

    const from: string =
        //@ts-ignore
        location.state?.from?.pathname || '/';

    useEffect(() => {
        if (isAuth) {
            navigate(from, { replace: true });
        }
    }, [from, isAuth, navigate]);

    return (
        <div
            className={`
          ${baseClasses.recipe({
              round: 'none',
          })}
          ${placeClasses.recipe({
              layout: 'center',
          })}`}
        >
            <Paper>
                <CreateUser />
            </Paper>
        </div>
    );
};

export { Register };
