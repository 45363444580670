/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { FC, useMemo } from 'react';

import {
    Form,
    Formik,
    useFormikContext,
    FormikHelpers,
} from 'formik';
import * as Yup from 'yup';

import type { GetStream } from '@mega/api';

import {
    Accordion,
    Button,
    CheckboxSwitchField,
    FormLayout,
    InputField,
    Paper,
    Stack,
    SelectSingleField,
    WithLabel,
    InputDate,
    Row,
} from '@mega/ui';
import { throttle } from 'lodash-es';

export interface StreamEditProps {
    initialValue?: GetStream;
    handleUpdate?: (
        value: GetStream,
        actions: FormikHelpers<GetStream>,
    ) => void;
}

const StreamEditActions = () => {
    const { submitForm, isSubmitting } = useFormikContext();

    const throttledSubmit = useMemo(
        () => throttle(submitForm, 1000),
        [],
    );

    return (
        <>
            <Paper
                style={{
                    borderTop: '1px solid #EAEAEA',
                }}
                fullWidth
                color={'white'}
                variant="filled"
                classes={{
                    padding: { size: '24x24' },
                }}
            >
                <Button
                    animate="none"
                    size={'large'}
                    type={'submit'}
                    label={'Обновить'}
                    onClick={throttledSubmit}
                    classes={{
                        paper: {
                            color: 'dark',
                            variant: 'filled',
                        },
                    }}
                    disabled={isSubmitting}
                />
            </Paper>
        </>
    );
};

const StreamEdit: FC<StreamEditProps> = ({
    handleUpdate = () => null,
    initialValue = {
        title: '',
        site: {
            id: '',
            label: '',
        },
        link: '',
    },
}) => (
    <Formik
        validationSchema={Yup.object({
            title: Yup.string()
                .min(
                    1,
                    'Это поле явно должно быть больше одного символа',
                )
                .required('Это поле обязательное'),
        })}
        initialValues={{
            ...initialValue,
        }}
        onSubmit={handleUpdate}
    >
        <Form
            style={{
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <FormLayout
                actions={<StreamEditActions />}
                settings={
                    <Stack gap="16">
                        <Paper
                            contentHeight
                            color={'white'}
                            variant="outline"
                            classes={{
                                padding: {
                                    size: '24x24',
                                },
                            }}
                        >
                            <Stack gap="18">
                                <Accordion
                                    label={'Настройки'}
                                    value="settings"
                                    isOpened
                                >
                                    <Stack gap="16">
                                        <Stack gap="8">
                                            <CheckboxSwitchField
                                                name="is_active"
                                                label="Показать на главной после начала вещания"
                                            />
                                        </Stack>
                                    </Stack>
                                </Accordion>
                            </Stack>
                        </Paper>

                        <Paper
                            contentHeight
                            color={'white'}
                            variant="outline"
                            classes={{
                                padding: {
                                    size: '24x24',
                                },
                            }}
                        >
                            <WithLabel
                                id={'platform'}
                                title="Платформа"
                            >
                                <SelectSingleField
                                    name={'platform'}
                                    placeholder={
                                        'платформа'
                                    }
                                    handleLoader={async () => [
                                        {
                                            value: 'desktop',
                                            label: 'Широкий формат',
                                        },
                                        {
                                            value: 'mobile',
                                            label: 'Мобильный формат',
                                        },
                                        {
                                            value: 'desktop-mobile',
                                            label: 'Широкий и мобильный',
                                        },
                                    ]}
                                    classes={{
                                        paper: {
                                            color: 'dark',
                                        },
                                        select: {
                                            variant:
                                                'secondary',
                                        },
                                    }}
                                />
                            </WithLabel>
                        </Paper>

                        <Paper
                            contentHeight
                            color={'white'}
                            variant="outline"
                            classes={{
                                padding: {
                                    size: '24x24',
                                },
                            }}
                        >
                            <WithLabel
                                id={'authors'}
                                title="Состояние стрима"
                            >
                                <SelectSingleField
                                    name={'stream_status'}
                                    placeholder={
                                        'Состояние стрима'
                                    }
                                    handleLoader={async () => [
                                        {
                                            value: 'pending',
                                            label: 'В ожидании',
                                        },
                                        {
                                            value: 'airing',
                                            label: 'В эфире',
                                        },
                                        {
                                            value: 'finished',
                                            label: 'Закончен',
                                        },
                                    ]}
                                    classes={{
                                        paper: {
                                            color: 'dark',
                                        },
                                        select: {
                                            variant:
                                                'secondary',
                                        },
                                    }}
                                />
                            </WithLabel>
                        </Paper>
                        <Paper
                            contentHeight
                            color={'white'}
                            variant="outline"
                            classes={{
                                padding: {
                                    size: '24x24',
                                },
                            }}
                        >
                            <Row gap="4">
                                <WithLabel title="Вещание началось">
                                    <InputDate name="stream_start" />
                                </WithLabel>
                                <WithLabel title="Вещание закончилось">
                                    <InputDate name="stream_end" />
                                </WithLabel>
                            </Row>
                        </Paper>
                    </Stack>
                }
            >
                <Paper
                    variant="outline"
                    color="white"
                    classes={{
                        padding: {
                            size: '24x24',
                        },
                    }}
                >
                    <Stack gap="18">
                        <InputField
                            type="text"
                            name={'title'}
                            placeholder="Название"
                            dimension="medium"
                        />
                        <InputField
                            type="text"
                            name={'description'}
                            placeholder="Описание"
                            dimension="medium"
                        />
                        <InputField
                            type="text"
                            name={'link_redirect'}
                            placeholder="Ссылка при нажатии"
                            dimension="medium"
                        />
                        <InputField
                            type="text"
                            name={'link'}
                            placeholder="Ссылка"
                            dimension="medium"
                            disabled
                        />
                    </Stack>
                </Paper>
            </FormLayout>
        </Form>
    </Formik>
);

export { StreamEdit };
