import { Menu } from '@mega/ui';
import React, { FC, ReactElement } from 'react';
import { MenuSitesRecipeVariants } from './MenuSites.css';

import { customAccordionClasses } from './CustomAccordion.css';

import { AnimatePresence, motion } from 'framer-motion';
import * as AccordionPrimetive from '@radix-ui/react-accordion';
import {
    PaddingBaseClasses,
    paperClasses,
    PaperClasses,
} from '@mega/styles';
import { Divider } from '@mega/ui';

interface AccordionCustomProps {
    children: ReactElement | ReactElement[];
    label: string | ReactElement;
    value: string;
    handlerSidebar?: () => void;
    handleAccordion: () => void;
    isOpened: boolean;
    trigger?: ReactElement;
    className?: string;
    startIcon?: ReactElement;
    endIcon?: ReactElement;
    isSmall?: boolean;
    classes?: {
        paper?: PaperClasses['recipe'];
        padding?: PaddingBaseClasses['recipe'];
    };
}

const Content: FC<{
    children: ReactElement | ReactElement[];
    state: boolean;
}> = ({ children, state }) => {
    return (
        <AnimatePresence>
            {state && (
                <motion.div
                    initial={{
                        height: '0px',
                        opacity: 0,
                    }}
                    animate={{
                        height: 'auto',
                        opacity: 1,
                    }}
                    exit={{
                        height: '0px',
                        opacity: 0,
                    }}
                    transition={{ duration: 0.3 }}
                >
                    <AccordionPrimetive.Content
                        forceMount
                        className={
                            customAccordionClasses.content
                        }
                    >
                        <Divider />
                        {children}
                    </AccordionPrimetive.Content>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

const IconAnimated: FC<{
    state: boolean;
    icon?: ReactElement;
    className?: string;
}> = ({ state, icon, className = '' }) => (
    <motion.div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
        className={className}
        animate={state ? { rotate: 90 } : { rotate: 0 }}
        transition={{ duration: 0.1 }}
    >
        {icon}
    </motion.div>
);

const AccordionCustom: FC<AccordionCustomProps> = ({
    children,
    label,
    startIcon,
    endIcon,
    value,
    className = '',
    trigger,
    classes,
    isOpened,
    handlerSidebar,
    handleAccordion,
    isSmall,
}) => {
    const hasStartIcon = Boolean(startIcon);
    const hasEndIcon = Boolean(endIcon);
    const hasButton = Boolean(trigger);

    function handle() {
        if (isSmall && handlerSidebar) {
            handlerSidebar();
        }
        handleAccordion();
    }

    return (
        <AccordionPrimetive.Root
            className={[
                className,
                paperClasses.recipe({
                    borderRadius: 'small',
                    variant: 'filled',
                    color: 'dark',
                    withFocus: false,
                    ...(classes?.paper ?? {}),
                }),
            ].join(' ')}
            type="multiple"
        >
            <AccordionPrimetive.Item value={value}>
                <AccordionPrimetive.Header
                    className={
                        customAccordionClasses.wrapper
                    }
                >
                    <div
                        className={
                            customAccordionClasses.headerLayout
                        }
                    >
                        <AccordionPrimetive.Trigger
                            onClick={handle}
                            className={`${customAccordionClasses.triggerStyle}`}
                        >
                            <div
                                className={
                                    customAccordionClasses.base
                                }
                            >
                                {hasStartIcon && (
                                    <IconAnimated
                                        className={
                                            customAccordionClasses.iconLayout
                                        }
                                        state={isOpened}
                                        icon={startIcon}
                                    />
                                )}
                                {isSmall ? null : label}
                            </div>
                            {hasEndIcon && (
                                <IconAnimated
                                    state={isOpened}
                                    icon={endIcon}
                                />
                            )}
                            {hasButton && trigger}
                        </AccordionPrimetive.Trigger>
                    </div>
                    <AnimatePresence>
                        {isOpened ? (
                            <motion.div
                                initial={{
                                    height: '0px',
                                    opacity: 0,
                                }}
                                animate={{
                                    height: '20px',
                                    opacity: 1,
                                }}
                                exit={{
                                    height: '0px',
                                    opacity: 0,
                                }}
                                transition={{
                                    duration: 0.5,
                                }}
                                style={{
                                    height: '20px',
                                }}
                            ></motion.div>
                        ) : null}
                    </AnimatePresence>
                    <Content state={isOpened}>
                        {children}
                    </Content>
                </AccordionPrimetive.Header>
            </AccordionPrimetive.Item>
        </AccordionPrimetive.Root>
    );
};

export interface MenuSitesProps {
    children: ReactElement | ReactElement[];
    label: ReactElement | string;
    value: string;
    isOpened: boolean;
    handleAccordion: () => void;
    endIcon?: ReactElement;
    isSmall?: boolean;
    handlerSidebar?: () => void;
}

const MenuSites: FC<
    MenuSitesProps & MenuSitesRecipeVariants
> = ({
    children,
    label,
    endIcon,
    isSmall,
    handlerSidebar,
    isOpened,
    handleAccordion,
    value,
}) => {
    return (
        <AccordionCustom
            isSmall={isSmall}
            handlerSidebar={handlerSidebar}
            endIcon={endIcon}
            label={label}
            value={value}
            isOpened={isOpened}
            handleAccordion={handleAccordion}
        >
            {children}
        </AccordionCustom>
    );
};

export { MenuSites };
