import { Input } from '@mega/ui';
import { useUploaderContext } from '../model/Uploader.provider';

export function EditFileTitle({ id: key }: { id: string }) {
    const { title = '', updateTitleFile } =
        useUploaderContext((store) => ({
            ...store.files[key],
            updateTitleFile: store.effects.updateTitleFile,
        }));
    return (
        <Input
            label=""
            dimension="medium"
            placeholder="Название файла:"
            type="text"
            value={title}
            onChange={(e) => {
                updateTitleFile(key, e.target.value);
            }}
        />
    );
}
