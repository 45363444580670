import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';

const useCategoryStore = () => {
    const store = useStoreSelector(
        (state: RootState) => state.category,
    );
    const loading = useStoreSelector(
        (state: RootState) =>
            state.loading.effects.category,
    );

    return {
        store,
        loading,
    };
};

const useCategoryDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        get: dispatch.category.get,
        put: dispatch.category.put,
        create: dispatch.category.create,
        update: dispatch.category.update,
        remove: dispatch.category.remove,
    };
};

export { useCategoryStore, useCategoryDispatch };
