import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';
import { useRef } from 'react';
import { debounce } from 'lodash-es';

const useCategoriesStore = () => {
    const state = useStoreSelector(
        (state: RootState) => state.categories,
    );
    const loading = useStoreSelector(
        (state: RootState) =>
            state.loading.effects.categories,
    );

    return Object.assign(state, {
        loading: {
            get: loading.get,
            chungeCountItemsOnPage:
                loading.chungeCountItemsOnPage,
            jumpTo: loading.jumpTo,
        },
    });
};
const useCategoriesDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    const debouncedHandleSetCurrentPage = useRef(
        debounce(dispatch.categories.setCurrentPage, 1000),
    );

    return {
        get: dispatch.categories.get,
        nextPage: dispatch.categories.nextPage,
        jumpTo: dispatch.categories.jumpTo,
        setCurrentPage:
            debouncedHandleSetCurrentPage.current,
        search: dispatch.categories.search,
        clear: dispatch.categories.clear,
    };
};

export { useCategoriesStore, useCategoriesDispatch };
