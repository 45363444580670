import React, { useRef, useState } from 'react';
import { Modal } from '@mega/utils';
import {
    base,
    closeButton,
    downloadButton,
    fileAuthor,
    filePreview,
    mainSlider,
    navigationArrow,
    navigationArrowIcon,
    slide,
    slideInfo,
    sliderContainer,
    sliderThumbs,
    sliderWrapper,
    thumbItem,
} from './SliderModal.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Button, Typography } from '@mega/ui';
import SwiperCore, {
    FreeMode,
    Navigation,
    Thumbs,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import {
    FiChevronLeft,
    FiChevronRight,
    FiDownload,
} from 'react-icons/fi';
import { MediaPreview } from './MediaPreview';
import { exportFile, fetchFile } from '../Helper';
import { MediaCard } from '../MediaCard';
import { Close } from '@mega/icons';

interface VideoModalProps {
    isOpen: boolean;
    files: any[];
    onClose: () => void;
    initialSlide?: number;
    onCoverUpdate?: (file: any, desc: string) => void;
}

export const SliderModal: React.FC<VideoModalProps> = (
    props,
) => {
    const {
        isOpen,
        files,
        onClose,
        initialSlide = 2,
        onCoverUpdate,
    } = props;
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const [activeSlide, setActiveSlide] =
        useState(initialSlide);
    const [thumbsSwiper, setThumbsSwiper] =
        useState<SwiperCore | null>(null);

    const handleClose = () => {
        setThumbsSwiper(null);
        onClose();
    };
    const getFileUrl = (file: any) => {
        switch (file?.type) {
            case 'image': {
                return file.image;
            }
            case 'video': {
                return file.file_link || file.video;
            }
            case 'audio': {
                return file.audio;
            }
            case 'file': {
                return file.file;
            }
        }
    };
    const handleDownload = (url: string) => {
        fetchFile(url).then((file) => exportFile(file));
    };

    return (
        <Modal onClose={handleClose} open={isOpen}>
            <div className={base}>
                <div className={sliderContainer}>
                    <div
                        className={closeButton}
                        onClick={handleClose}
                    >
                        <Close />
                    </div>
                    <div className={sliderWrapper}>
                        <div
                            className={navigationArrow({
                                reversed: false,
                                disabled: activeSlide === 0,
                            })}
                            ref={prevRef}
                        >
                            <FiChevronLeft
                                className={
                                    navigationArrowIcon
                                }
                            />
                        </div>
                        <div
                            className={navigationArrow({
                                reversed: true,
                                disabled:
                                    activeSlide ===
                                    files?.length - 1,
                            })}
                            ref={nextRef}
                        >
                            <FiChevronRight
                                className={
                                    navigationArrowIcon
                                }
                            />
                        </div>
                        <div className={mainSlider}>
                            <Swiper
                                navigation={{
                                    prevEl: prevRef.current,
                                    nextEl: nextRef.current,
                                }}
                                initialSlide={initialSlide}
                                spaceBetween={10}
                                thumbs={{
                                    swiper: thumbsSwiper,
                                }}
                                modules={[
                                    Navigation,
                                    Thumbs,
                                    FreeMode,
                                ]}
                                onSlideChange={(swiper) =>
                                    setActiveSlide(
                                        swiper.realIndex,
                                    )
                                }
                            >
                                {!!files.length &&
                                    files.map((file) => {
                                        const fileDesc =
                                            file.description &&
                                            file.source_text
                                                ? `${file.description} (${file.source_text})`
                                                : '';

                                        const fileLink =
                                            getFileUrl(
                                                file,
                                            );
                                        const canBeCover =
                                            file.type ===
                                                'image' ||
                                            file.type ===
                                                'video';
                                        return (
                                            <SwiperSlide
                                                key={
                                                    file.id
                                                }
                                            >
                                                <div
                                                    className={
                                                        slide
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            filePreview
                                                        }
                                                    >
                                                        <MediaPreview
                                                            file={
                                                                file
                                                            }
                                                        />
                                                        <div
                                                            className={
                                                                downloadButton
                                                            }
                                                        >
                                                            <Button
                                                                startIcon={
                                                                    !onCoverUpdate ? (
                                                                        <FiDownload />
                                                                    ) : undefined
                                                                }
                                                                label={
                                                                    onCoverUpdate &&
                                                                    canBeCover
                                                                        ? 'Сделать обложкой'
                                                                        : 'Скачать'
                                                                }
                                                                onClick={() => {
                                                                    if (
                                                                        onCoverUpdate &&
                                                                        canBeCover
                                                                    ) {
                                                                        return onCoverUpdate(
                                                                            file,
                                                                            fileDesc,
                                                                        );
                                                                    }
                                                                    handleDownload(
                                                                        fileLink,
                                                                    );
                                                                }}
                                                                gap={
                                                                    8
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={
                                                            slideInfo
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                fileAuthor
                                                            }
                                                        >
                                                            <Typography
                                                                size={
                                                                    '16'
                                                                }
                                                                weight={
                                                                    'medium'
                                                                }
                                                            >
                                                                Автор:
                                                            </Typography>
                                                            <Typography
                                                                size={
                                                                    '16'
                                                                }
                                                            >
                                                                {
                                                                    file?.source_text
                                                                }
                                                            </Typography>
                                                        </div>
                                                        <Typography
                                                            size={
                                                                '16'
                                                            }
                                                        >
                                                            {
                                                                file?.description
                                                            }
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })}
                            </Swiper>
                        </div>

                        <div className={sliderThumbs}>
                            <Swiper
                                watchSlidesProgress={true}
                                modules={[
                                    FreeMode,
                                    Navigation,
                                    Thumbs,
                                ]}
                                spaceBetween={10}
                                onSwiper={setThumbsSwiper}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 2,
                                    },
                                    480: {
                                        slidesPerView: 3,
                                    },
                                    680: {
                                        slidesPerView: 4,
                                    },
                                    950: {
                                        slidesPerView: 6,
                                    },
                                }}
                            >
                                {files?.map((item, idx) => {
                                    return (
                                        <SwiperSlide
                                            key={item.id}
                                        >
                                            <div
                                                className={thumbItem(
                                                    {
                                                        isActive:
                                                            idx ===
                                                            activeSlide,
                                                    },
                                                )}
                                            >
                                                <MediaCard
                                                    file={
                                                        item
                                                    }
                                                    type={
                                                        item.type
                                                    }
                                                />
                                            </div>
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
