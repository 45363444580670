import { FC } from 'react';
import { PluginViewWrapper } from 'src/pages/smartGrid/components/shared/PluginViewWrapper';
import { StoriesProps } from './Stories.types';
import { styles as s } from './Stories.css';

const Stories: FC<StoriesProps> = ({
    id,
    type,
    meta,
    parentId,
}) => {
    const { label, dataId } = meta;

    return (
        <>
            <PluginViewWrapper
                id={id}
                parentId={parentId}
                title={'История'}
            >
                <div className={s.wrapper({})}>
                    <div className={s.container}>
                        <div className={s.innerContainer}>
                            <div className={s.content}>
                                <p className={s.text}>
                                    {`${label}`}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </PluginViewWrapper>
        </>
    );
};

export { Stories };
