import React, { FC, useEffect, useState } from 'react';
import { useSiteStore } from '@mega/store';
import { reports } from '@mega/api';
import {
    InputSelectMultiField,
    SelectSingleField,
    WithLabel,
} from '@mega/ui';
import { Filters, ReportTypes } from '../../Reports.types';

export const SelectFilters: FC<{
    page?: ReportTypes;
}> = ({ page = 'articles' }) => {
    const site = useSiteStore();

    const [filters, setFilters] = useState<Filters>();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                const res = await reports.getFilters?.({
                    site: site?.id,
                });

                if (res?.ok) {
                    setFilters(res?.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                setIsLoading(false);
            }
        })();
    }, []);

    const hasSources = page === 'articles';
    return (
        <>
            <WithLabel id={'authors'} title="Автор">
                <SelectSingleField
                    isLoading={isLoading}
                    name={'authors'}
                    //@ts-ignore
                    handleLoader={async () => {
                        if (
                            filters?.authors &&
                            !isLoading
                        ) {
                            return filters?.authors?.map(
                                (item) => ({
                                    value: item.id || '',
                                    label: item.label || '',
                                }),
                            );
                        }
                    }}
                    classes={{
                        paper: {
                            color: 'dark',
                        },
                        select: {
                            variant: 'secondary',
                        },
                    }}
                />
            </WithLabel>
            <WithLabel
                id={'article_types'}
                title="Тип статьи"
            >
                <SelectSingleField
                    isLoading={isLoading}
                    name={'article_types'}
                    //@ts-ignore
                    handleLoader={async () => {
                        return filters?.article_types?.map(
                            (item) => ({
                                value: item.id || '',
                                label: item.label || '',
                            }),
                        );
                    }}
                    classes={{
                        paper: {
                            color: 'dark',
                        },
                        select: {
                            variant: 'secondary',
                        },
                    }}
                />
            </WithLabel>
            {hasSources && (
                <WithLabel id={'sources'} title="Источники">
                    <InputSelectMultiField
                        isLoading={isLoading}
                        name={'sources'}
                        //@ts-ignore
                        handleLoader={async () => {
                            return filters?.sources?.map(
                                (item) => ({
                                    value: item.id || '',
                                    label: item.label || '',
                                }),
                            );
                        }}
                        classes={{
                            paper: {
                                color: 'dark',
                            },
                            select: {
                                variant: 'secondary',
                            },
                        }}
                    />
                </WithLabel>
            )}
        </>
    );
};
