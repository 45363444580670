import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgDzenRssOn = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 12 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M11.533 12.139v2.285H1.455v-2.285h10.078Zm-.146-10.303L2.754 14.424H.694v-1.68L9.393.205h1.993v1.63ZM10.38.206V2.5H.703V.205h9.678Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgDzenRssOn);
export default ForwardRef;
