import { createModel } from '@rematch/core';
import * as CLIENT from '@mega/api';
import type {
    Articles,
    ArticlesQuery,
    ArticlesWithPagination,
    UpdateBulk,
} from '@mega/api';
import { debounce, isUndefined } from 'lodash-es';
import { stringify } from 'query-string';
import type { RootModel } from '../rootModel';
import {
    DefaultStore,
    DispatchEffectWithDependency,
} from '../type';
import { calculatePagesCount } from '@mega/utils';

export interface UpdateBulkEffect
    extends DispatchEffectWithDependency<UpdateBulk> {}

const defaultStore: DefaultStore<Articles, ArticlesQuery> =
    {
        currentPage: 1,
        query: {
            per_page: 50,
        },
        hasMore: false,
        meta: {
            count: null,
        },
        pages: {},
    };

const articles = createModel<RootModel>()({
    name: 'articles',
    state: defaultStore,
    reducers: {
        changePerPage: (
            state,
            payload: { perPage: number },
        ) => {
            return { ...state, perPage: payload.perPage };
        },
        putCurrentPage: (
            state,
            payload: { triggerPage: number },
        ) => {
            return {
                ...state,
                currentPage: payload.triggerPage,
            };
        },
        putPage: (
            state,
            payload: ArticlesWithPagination,
        ) => {
            return {
                ...state,
                meta: {
                    count: isUndefined(payload.count)
                        ? null
                        : payload.count,
                },
                pages: {
                    ...state.pages,
                    [`${state.currentPage}`]:
                        payload.results,
                },
            };
        },
        putQuery: (state, payload: ArticlesQuery) => {
            return {
                ...state,
                query: { ...state.query, ...payload },
            };
        },
        clear: () => {
            return defaultStore;
        },
    },

    effects: (dispatch) => ({
        /* #region  Search */
        search: async (
            query: Pick<ArticlesQuery, 'search'>,
        ) => {
            await dispatch.articles.putQuery({
                ...query,
                page: 1,
            });

            await dispatch.articles.putCurrentPage({
                triggerPage: 1,
            });

            await dispatch.articles.afterSearch(null);
        },
        afterSearch: debounce((_, state) => {
            dispatch.articles.get(state.articles.query);
            dispatch.articles.updateUrl({});
        }, 1000),
        updateUrl: async (_, state) => {
            const query = state.articles.query;
            const queryString = stringify(query);

            window.history.replaceState(
                null,
                '',
                `?${queryString}`,
            );
        },
        get: async (query: ArticlesQuery, store) => {
            const { query: storeQuery } = store.articles;

            try {
                const response =
                    await CLIENT.articles.get?.(
                        {
                            ...storeQuery,
                            ...query,
                            is_count: true,
                            page: store.articles
                                .currentPage,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );

                if (response?.ok) {
                    const { data } = response;

                    const wait =
                        await dispatch.articles?.putPage(
                            data,
                        );
                }
            } catch (e) {
                if (
                    CLIENT.articles.get &&
                    e instanceof CLIENT.articles.get.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },
        nextPage: async (_: any, store) => {
            const { query, currentPage } = store.articles;

            await dispatch.articles.putCurrentPage({
                triggerPage: currentPage + 1,
            });
            await dispatch.articles.get({
                ...query,
                page: currentPage + 1,
            });
        },
        getOptions: async (query: ArticlesQuery, store) => {
            const { query: storeQuery } = store.articles;

            try {
                const response =
                    await CLIENT.articles.getOptions?.(
                        {
                            ...query,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );

                if (response?.ok) {
                    const { data } = response;
                    return data;
                }
            } catch (e) {
                if (
                    CLIENT.articles.getOptions &&
                    e instanceof
                        CLIENT.articles.getOptions.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },
        getMainGrid: async (
            query: ArticlesQuery,
            store,
        ) => {
            const { query: storeQuery } = store.articles;

            try {
                const response =
                    await CLIENT.articles.getMainGrid?.(
                        {
                            ...query,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );

                if (response?.ok) {
                    const { data } = response;
                    return data;
                }
            } catch (e) {
                if (
                    CLIENT.articles.getMainGrid &&
                    e instanceof
                        CLIENT.articles.getMainGrid.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },
        setCurrentPage: async (
            payload: {
                triggerPage: number;
                action: 'next' | 'prev' | 'body';
            },
            store,
        ) => {
            const { query, currentPage } = store.articles;
            const { triggerPage, action } = payload;

            switch (action) {
                case 'next': {
                    const count =
                        store?.articles?.meta?.count || 0;
                    const perPage =
                        store?.articles?.query?.per_page ||
                        1;
                    const isMaxPage =
                        calculatePagesCount(
                            perPage,
                            count,
                        ) <
                        triggerPage + 1;

                    if (isMaxPage) {
                        break;
                    }
                    dispatch.articles.get({
                        ...query,
                        page: triggerPage + 1,
                    });
                    break;
                }

                case 'body': {
                    if (triggerPage !== currentPage) {
                        dispatch.articles.putCurrentPage({
                            triggerPage,
                        });
                    }
                    break;
                }

                case 'prev': {
                    if (triggerPage !== 1) {
                        dispatch.articles.get({
                            ...query,
                            page: triggerPage - 1,
                        });
                    }
                    break;
                }

                default:
                    break;
            }
        },
        jumpTo: async (page: number) => {
            await dispatch.articles.putQuery({
                page: page,
            });
            await dispatch.articles.putCurrentPage({
                triggerPage: page,
            });
            await dispatch.articles.updateUrl({});
            await dispatch.articles.get({
                page: page,
            });
        },
        chungeCountItemsOnPage: async (
            perPage: number,
            state,
        ) => {
            if (
                Math.floor(
                    state?.articles?.meta.count ??
                        0 / perPage,
                ) > 0
            ) {
                await dispatch.articles.changePerPage({
                    perPage,
                });
                await dispatch.articles.get({});
            }
        },
        getWithCheckRooms: async (query: ArticlesQuery) => {
            await dispatch.articles.get(query);
            await dispatch.articlesRoom.checkPageRoom({});
        },
        /* #endregion */
        updateBulk: async (
            { payload, options }: UpdateBulkEffect,
            store,
        ) => {
            try {
                const response =
                    await CLIENT.articles.updateBulk?.(
                        payload,
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );

                if (response?.ok) {
                    if (options?.notifier) {
                        const { enqueueSnackbar } =
                            options.notifier;
                        enqueueSnackbar(
                            'Выбранные статьи успешно добавлены в госконтракт',
                            {
                                variant: 'success',
                            },
                        );
                    }
                }
            } catch (e) {
                if (
                    CLIENT.articles.updateBulk &&
                    e instanceof
                        CLIENT.articles.updateBulk.Error
                ) {
                    const error = e.getActualType();
                    return error.data;
                }
            }
        },
    }),
});

export { articles };
export default articles;
