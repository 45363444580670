import { Button } from '@mega/ui';
import React, { FC } from 'react';
import { ArticleDefaultForm } from '@pages/smartGrid/components/Forms/ArticleDefaultForm';

interface ArticleFormProps {
    onSubmit: (
        e?: React.FormEvent<HTMLFormElement> | undefined,
    ) => void;
}

export const ArticleForm: FC<ArticleFormProps> = ({
    onSubmit,
}) => {
    return (
        <div style={{ display: 'grid', gap: 20 }}>
            <ArticleDefaultForm />
            <Button
                type={'button'}
                label={'Сохранить'}
                onClick={() => onSubmit()}
                classes={{
                    paper: {
                        color: 'dark',
                        variant: 'filled',
                    },
                }}
            />
        </div>
    );
};
