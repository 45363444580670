import { JSXElementConstructor, ReactElement } from 'react';
import { MenuItem, IconButton } from '@mui/material';
import { ButtonConfig } from './types';
import { Menu } from '@mega/ui';
import { styles as s } from './ContextMenu.css';

interface ContextMenuProps<T> {
    config: ButtonConfig<T>[];
    item: T;
    label?: string;
    icon?:
        | ReactElement<
              any,
              string | JSXElementConstructor<any>
          >
        | undefined;
    renderItem?: () => ReactElement;
}

export const ContextMenu = <T,>({
    config,
    label = '',
    icon,
    item,
}: ContextMenuProps<T>) => {
    let handleCloseAction: Function;

    const handleButtonClick = (
        callback: (item: T) => void,
    ) => {
        callback(item);
        handleCloseAction();
    };

    return (
        <>
            <Menu
                style={{ padding: '2px' }}
                gap="4px"
                trigger={({ handleClick, handleClose }) => {
                    handleCloseAction = handleClose;

                    return (
                        <div
                            className={s.copyAll()}
                            onClick={handleClick}
                        >
                            {icon} {label}
                        </div>
                    );
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {config.map(
                    ({ label, icon, cb }, index) => (
                        <MenuItem
                            style={{
                                fontSize: '10px',
                                paddingRight: '10px',
                            }}
                            key={index}
                            onClick={() =>
                                handleButtonClick(cb)
                            }
                        >
                            <IconButton>{icon}</IconButton>
                            {label}
                        </MenuItem>
                    ),
                )}
            </Menu>
        </>
    );
};

export default ContextMenu;
