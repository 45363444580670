import { article } from 'src/models/Articles';
import { RootState } from '../../../models';

export type Articles = RootState['articles'];
export type ArticlesProgress =
    RootState['loading']['effects']['articles'];

export const selectArticles = (state: RootState) =>
    state.articles;

export const selectArticlesFull = (articles: Articles) =>
    articles;

export const selectArticlesQuery = (articles: Articles) =>
    articles.query;

export const selectArticlesPages = (articles: Articles) =>
    articles.pages;

export const selectArticlesMeta = (articles: Articles) =>
    articles.meta;

export const selectArticlesProgress = (state: RootState) =>
    state.loading.effects.articles;

export const pickArticlesGetProgress = (
    articles: ArticlesProgress,
) => articles.get;

export const pickArticlesChungeCountItemsOnPageProgress = (
    articles: ArticlesProgress,
) => articles.chungeCountItemsOnPage;

export const pickArticlesJumpToProgress = (
    articles: ArticlesProgress,
) => articles.jumpTo;
