import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';

const useDocumentStore = () => {
    const store = useStoreSelector(
        (state: RootState) => state.document,
    );
    const loading = useStoreSelector(
        (state: RootState) =>
            state.loading.effects.document,
    );

    return {
        store,
        loading,
    };
};

const useDocumentDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        get: dispatch.document.get,
        put: dispatch.document.put,
        create: dispatch.document.create,
        update: dispatch.document.update,
        remove: dispatch.document.remove,
    };
};

export { useDocumentStore, useDocumentDispatch };
