import React from 'react';
import {
    customArrow,
    customTooltip,
    fileAuthor,
    imgInfo,
    imgWrapper,
    styledTitle,
    styledWrapper,
} from './MediaCard.css';
import { Button, Typography } from '@mega/ui';
import { Tooltip } from '@mui/material';
import { exportFile, fetchFile } from '../Helper';
import { MediaViewer } from '../MediaViewer';

export interface MediaCardProps {
    file?: any;
    type?: string;
    files?: any[];
    text?: string;
    onClick?: () => void;
    noTitle?: boolean;
    hiddenFilesCount?: number;
}

export const MediaCard: React.FC<MediaCardProps> = (
    props,
) => {
    const {
        file,
        files,
        text,
        onClick,
        noTitle,
        hiddenFilesCount,
    } = props;

    const fileTitle = file?.title || 'Без названия';

    const linkArray = files?.map(
        (item) =>
            item.image ||
            item.file_link ||
            item.file ||
            item.audio,
    );

    const handleDownload = (
        e: React.MouseEvent<HTMLElement>,
    ) => {
        if (!linkArray?.length) return;
        e.preventDefault();
        for (const url of linkArray) {
            fetchFile(url).then((file) => exportFile(file));
        }
    };

    const hasTooltip = !!(
        file?.source_text || file?.description
    );
    return (
        <Tooltip
            classes={{
                tooltip: customTooltip,
                arrow: customArrow,
            }}
            title={
                hasTooltip ? (
                    <div className={customTooltip}>
                        <div className={fileAuthor}>
                            <Typography size={'12'}>
                                {file?.source_text}
                            </Typography>
                        </div>
                        <Typography size={'12'}>
                            {file?.description}
                        </Typography>
                    </div>
                ) : (
                    ''
                )
            }
            placement="top"
            arrow
        >
            <span>
                <div className={styledWrapper({ noTitle })}>
                    <div
                        className={imgWrapper}
                        onClick={() => {
                            if (onClick) {
                                onClick();
                            }
                        }}
                    >
                        <MediaViewer
                            file={file}
                            filesLength={hiddenFilesCount}
                        />
                    </div>
                    {!noTitle && (
                        <div className={imgInfo}>
                            {!!files?.length ? (
                                <Button
                                    size={'large'}
                                    label={text || ''}
                                    classes={{
                                        paper: {
                                            variant:
                                                'filled',
                                            color: 'dark',
                                        },
                                    }}
                                    onClick={handleDownload}
                                />
                            ) : (
                                <Typography
                                    className={styledTitle}
                                    size={'12'}
                                >
                                    {fileTitle}
                                </Typography>
                            )}
                        </div>
                    )}
                </div>
            </span>
        </Tooltip>
    );
};
