import React from 'react';
import { Modal } from '@mega/utils';
import {
    base,
    buttonBlock,
    content,
    modalTitle,
    modalTitleWrapper,
    titleBlock,
} from './ArticleLinkModal.css';
import {
    Button,
    Icon,
    IconButton,
    Row,
    SelectSingleField,
    Typography,
} from '@mega/ui';
import { Close } from '@mega/icons';
import { FiSearch } from 'react-icons/fi';
import { Form, Formik } from 'formik';
import { useArticlesDispatch } from '@mega/store';

interface ArticleLinkModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (articleId: number) => void;
}

export const ArticleLinkModal: React.FC<
    ArticleLinkModalProps
> = (props) => {
    const { isOpen, onClose, onSubmit } = props;
    const handleClose = () => {
        onClose();
    };
    const { getOptions } = useArticlesDispatch();

    const handleLoader = async (inputValue: string) => {
        const responce = await getOptions({
            search: inputValue,
        });
        if (responce?.results.length) {
            return (
                responce.results?.map((item: any) => ({
                    label: item?.label || '',
                    value: item?.id || '',
                })) ?? []
            );
        } else {
            return [];
        }
    };

    return (
        <Modal onClose={handleClose} open={isOpen}>
            <Formik
                initialValues={{}}
                onSubmit={({
                    articleTitle,
                }: {
                    articleTitle?: {
                        id?: number;
                        label?: string;
                    };
                }) => {
                    if (articleTitle?.id) {
                        onSubmit(articleTitle.id);
                    }
                }}
            >
                {() => {
                    return (
                        <Form>
                            <div className={base}>
                                <div
                                    className={
                                        modalTitleWrapper
                                    }
                                >
                                    <Row
                                        className={
                                            titleBlock
                                        }
                                        verticalAlign={
                                            'top'
                                        }
                                        gap={'16'}
                                    >
                                        <Typography
                                            className={
                                                modalTitle
                                            }
                                            weight={'bold'}
                                            size={'24'}
                                        >
                                            Добавьте ссылку
                                        </Typography>
                                        <IconButton
                                            animate="primary"
                                            size="medium"
                                            onClick={
                                                handleClose
                                            }
                                        >
                                            <Close />
                                        </IconButton>
                                    </Row>
                                    <Typography
                                        className={
                                            modalTitle
                                        }
                                        size={'16'}
                                    >
                                        Если инфоповод уже
                                        отработан – добавьте
                                        к нему публикацию:
                                    </Typography>
                                </div>

                                <div className={content}>
                                    <SelectSingleField
                                        debounceTimeout={
                                            300
                                        }
                                        handleLoader={async (
                                            inputValue: string,
                                        ) => {
                                            const data =
                                                await handleLoader(
                                                    inputValue,
                                                );

                                            return (
                                                data ?? []
                                            );
                                        }}
                                        name={
                                            'articleTitle'
                                        }
                                        placeholder={
                                            'Начните вводить заголовок'
                                        }
                                        startIcon={
                                            <Icon size="20">
                                                <FiSearch />
                                            </Icon>
                                        }
                                        classes={{
                                            paper: {
                                                color: 'dark',
                                            },
                                            select: {
                                                variant:
                                                    'secondary',
                                            },
                                        }}
                                    />
                                </div>

                                <div
                                    className={buttonBlock}
                                >
                                    <Button
                                        type={'submit'}
                                        label={'Добавить'}
                                        animate="none"
                                        classes={{
                                            paper: {
                                                color: 'blue',
                                            },
                                        }}
                                    />
                                    <Button
                                        label={'Отменить'}
                                        animate="none"
                                        classes={{
                                            paper: {
                                                color: 'dark',
                                            },
                                        }}
                                        onClick={() => {
                                            handleClose();
                                        }}
                                    />
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );
};
