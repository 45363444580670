/* eslint-disable @typescript-eslint/no-unused-vars */
import { Article } from './Article';
import { ArticlePlugin } from './ArticlePlugin';
import {
    ArticleControl,
    ArticleEnum,
    ArticlePluginDefault,
} from './Article.types';
import {
    coverTypeList,
    viewTypeList,
} from '@pages/smartGrid/constants/data';

const PLUGIN_ARTICLE_NAME = ArticleEnum.type;

const PLUGIN_META_360_DEFAULT = {
    dataId: '',
    label: '',
    cover: coverTypeList.nocover,
    viewType: viewTypeList.horizontal,
    withInfo: false,
    withLead: false,
};

const PLUGIN_META_RIAMO_DEFAULT = {
    dataId: '',
    label: '',
    cover: coverTypeList.photocover,
    viewType: viewTypeList.horizontal,
    withInfo: true,
    withLead: true,
};

const DEFAULT_360_ARTICLE: ArticlePluginDefault = {
    type: PLUGIN_ARTICLE_NAME,
    meta: PLUGIN_META_360_DEFAULT,
    childrenElement: [],
};

const DEFAULT_RIAMO_ARTICLE: ArticlePluginDefault = {
    type: PLUGIN_ARTICLE_NAME,
    meta: PLUGIN_META_RIAMO_DEFAULT,
    childrenElement: [],
};

const PLUGIN_360_ARTICLE = {
    [PLUGIN_ARTICLE_NAME]: {
        emptyObject: DEFAULT_360_ARTICLE,
        render: Article,
    },
};

const PLUGIN_RIAMO_ARTICLE = {
    [PLUGIN_ARTICLE_NAME]: {
        emptyObject: DEFAULT_RIAMO_ARTICLE,
        render: Article,
    },
};

const PLUGIN_360_ARTICLE_CONTROL: ArticleControl = {
    name: PLUGIN_ARTICLE_NAME,
    label: 'Article',
    render: ArticlePlugin,
    tooltip: 'Создать статью',
    meta: PLUGIN_META_360_DEFAULT,
    Icon: () => <></>,
};

const PLUGIN_RIAMO_ARTICLE_CONTROL: ArticleControl = {
    name: PLUGIN_ARTICLE_NAME,
    label: 'Article',
    render: ArticlePlugin,
    tooltip: 'Создать статью',
    meta: PLUGIN_META_RIAMO_DEFAULT,
    Icon: () => <></>,
};

export {
    DEFAULT_360_ARTICLE,
    PLUGIN_360_ARTICLE,
    DEFAULT_RIAMO_ARTICLE,
    PLUGIN_RIAMO_ARTICLE,
    PLUGIN_360_ARTICLE_CONTROL,
    PLUGIN_RIAMO_ARTICLE_CONTROL,
    PLUGIN_ARTICLE_NAME,
};
