import React, {
    ReactElement,
    useEffect,
    useState,
} from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import {
    Control,
    IndicatorsContainer,
    Container,
    Option,
    MenuListPrimary,
    MenuListSecondary,
    MultiValueRemove,
    MultiValueLabel,
    MultiValue,
    ValueContainer,
    Placeholder,
    ClearIndicator,
    DropdownIndicator,
    Menu,
    Input,
    MenuListFourth,
} from './components';
import {
    Options as OptionsType,
    Option as OptionType,
} from '../types';

import { paperClasses, PaperClasses } from '@mega/styles';

import {
    inputSelectMultiClasses,
    ClassesMultiInputSelect,
} from './InputSelectMulti.css';

type Additional = {
    page: number;
};

export interface InputSelectMultiProps {
    value?: OptionsType;
    placeholder?: string;
    handleLoader: (
        inputValue: string,
        page: number,
    ) => Promise<OptionsType>;
    defaultValue?: OptionsType;
    actionOnSelectedOption?: (
        selectedOption: OptionsType,
    ) => void;
    defaultOptions?: OptionsType;
    startIcon?: ReactElement;
    endIcon?: ReactElement;
    clearIcon?: ReactElement;
    buttonFooter?: ReactElement;
    classes?: {
        paper?: PaperClasses['recipe'];
        select?: ClassesMultiInputSelect['Container']['recipe'];
    };
    isError?: boolean;
    menuIsOpen?: boolean;
}

export const InputSelectMulti: React.FC<
    InputSelectMultiProps
> = ({
    value,
    placeholder = '',
    startIcon,
    endIcon = null,
    clearIcon = null,
    buttonFooter,
    classes = {
        select: {
            variant: 'secondary',
        },
    },
    isError = false,
    defaultValue,
    menuIsOpen,
    actionOnSelectedOption,
    handleLoader,
}) => {
    const [selectedOption, setSelectedOption] = useState(
        defaultValue || [],
    );

    const handleChange = (selectedOption: OptionsType) => {
        setSelectedOption(selectedOption);
        actionOnSelectedOption?.(selectedOption);
    };

    const optionsPerPage = 9;

    const loadOptions = async (
        search: string,
        page: number,
    ): Promise<{
        options: OptionType[];
        hasMore: boolean;
    }> => {
        const getOptions = await handleLoader(search, page);

        let filteredOptions;
        if (!search) {
            filteredOptions = getOptions;
        } else {
            const searchLower = search.toLowerCase();

            filteredOptions = getOptions.filter(
                ({ label }) =>
                    label
                        .toLowerCase()
                        .includes(searchLower),
            );
        }

        const hasMore =
            Math.ceil(
                filteredOptions.length / optionsPerPage,
            ) > page;

        const slicedOptions = filteredOptions.slice(
            (page - 1) * optionsPerPage,
            page * optionsPerPage,
        );

        return {
            options: slicedOptions,
            hasMore,
        };
    };

    useEffect(() => {
        if (selectedOption && selectedOption.length > 0) {
            actionOnSelectedOption?.(selectedOption);
        }
    }, []);

    return (
        <AsyncPaginate
            menuIsOpen={menuIsOpen}
            loadOptions={async (q, _, addition) => {
                const { options, hasMore } =
                    await loadOptions(
                        q,
                        addition?.page || 1,
                    );

                return {
                    options,
                    hasMore,
                    additional: {
                        page: (addition?.page || 1) + 1,
                    },
                };
            }}
            additional={{ page: 1 }}
            value={value || selectedOption}
            components={{
                Control: Control({
                    startIcon,
                    className: [
                        inputSelectMultiClasses.controMultiRecipe(
                            classes.select,
                        ),
                        inputSelectMultiClasses.controMultiRecipe(
                            { error: isError },
                        ),
                        paperClasses.recipe({
                            variant: 'outlineFilled',
                            color: 'gray',
                            borderRadius: 'small',
                            ...(classes?.paper ?? {}),
                        }),
                    ].join(' '),
                }),
                SelectContainer: Container({
                    className:
                        inputSelectMultiClasses.containerMultiRecipe(
                            classes.select,
                        ),
                }),
                Placeholder: Placeholder({
                    className:
                        inputSelectMultiClasses.placeholderRecipe(
                            classes.select,
                        ),
                }),
                MultiValueRemove: MultiValueRemove({
                    className:
                        inputSelectMultiClasses.multiValueRemoveRecipe(
                            classes.select,
                        ),
                }),
                MultiValueLabel: MultiValueLabel({
                    className:
                        inputSelectMultiClasses.multiValueLabel,
                }),
                MultiValue: MultiValue({
                    className:
                        inputSelectMultiClasses.multiValueRecipe(
                            classes.select,
                        ),
                }),
                Option: Option({
                    className:
                        inputSelectMultiClasses.optionMultiRecipe(
                            classes.select,
                        ),
                }),
                ValueContainer: ValueContainer({
                    className:
                        inputSelectMultiClasses.valueContainer,
                }),
                IndicatorsContainer: IndicatorsContainer({
                    className:
                        inputSelectMultiClasses.indicatorsContainer,
                }),
                DropdownIndicator: DropdownIndicator({
                    className:
                        inputSelectMultiClasses.dropDownIndicator,
                    endIcon,
                }),
                ClearIndicator: ClearIndicator({
                    clearIcon,
                    className:
                        inputSelectMultiClasses.clearIndicator,
                }),
                Input,
                //@ts-ignore
                MenuList:
                    classes.select?.variant === 'primary'
                        ? MenuListPrimary
                        : classes.select?.variant ===
                          'fourth'
                        ? MenuListFourth
                        : MenuListSecondary,
                Menu: Menu({
                    className: [
                        inputSelectMultiClasses.menuMultiRecipe(
                            classes.select,
                        ),
                    ].join(' '),
                    buttonFooter,
                    variantClass: classes.select?.variant,
                }),
            }}
            styles={{
                indicatorSeparator: (base) => ({
                    ...base,
                    width: '0px',
                }),
                control: () => ({}),
                input: (base) => ({
                    margin: '0',
                    padding: '0',
                    color: '#878787',
                    width: 'auto',
                    cursor: 'text',
                }),
                valueContainer: () => ({}),
                menu: (base) => ({
                    ...base,
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                }),
                placeholder: () => ({}),
                loadingIndicator: (base) => ({
                    ...base,
                    color:
                        classes.select?.variant ===
                        'primary'
                            ? '#fff'
                            : '#282828',
                }),
                loadingMessage: (base) => ({
                    ...base,
                    padding: '6px',
                }),
            }}
            placeholder={placeholder}
            onChange={(value) => {
                const typedValue = value as Array<{
                    value: string | number;
                    label: string;
                    slug?: string;
                }>;
                handleChange(
                    typedValue.map((item) => ({
                        value: item.value,
                        label: item.label,
                        slug: item?.slug || undefined,
                    })),
                );
            }}
            isMulti
        />
    );
};
