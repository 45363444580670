import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';

import { createSelector } from 'reselect';

type Employees = RootState['employees'];

const useEmployeesStore = () => {
    const state = useStoreSelector(
        (state: RootState) => state.employees,
    );
    const loading = useStoreSelector(
        (state: RootState) =>
            state.loading.effects.employees,
    );

    return Object.assign(state, {
        loading: {
            get: loading.get,
        },
    });
};
const useEmployeesDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        get: dispatch.employees.get,
        search: dispatch.employees.search,
        nextPage: dispatch.employees.nextPage,
        addCustomSiteQuery:
            dispatch.employees.addCustomSiteQuery,
        jumpTo: dispatch.employees.jumpTo,
    };
};

const usePickEmployeesGetProgress = () => {
    const loading = useStoreSelector(
        rePickEmployeesGetProgress,
    );
    return loading;
};

const selectEmployeesProgress = (state: RootState) =>
    state.loading.effects.employees;

const selectEmployees = (state: RootState) =>
    state.employees;

const selectEmployeesQuery = (employees: Employees) =>
    employees.query;

const pickEmployeesGetProgress = (employees: Employees) =>
    employees.get;

const rePickEmployeesGetProgress = createSelector(
    selectEmployeesProgress,
    pickEmployeesGetProgress,
);

const reselectEmployeesQuery = createSelector(
    selectEmployees,
    selectEmployeesQuery,
);

const useEmployeesQuery = () => {
    const state = useStoreSelector(reselectEmployeesQuery);
    return state;
};

export {
    useEmployeesStore,
    useEmployeesDispatch,
    usePickEmployeesGetProgress,
    useEmployeesQuery,
};
