import { Form, Formik } from 'formik';
import { FC } from 'react';
import { AutoArticleForm } from './AutoArticleForm/AutoArticleForm';
import {
    IInitValuesForm,
    initValuesForm,
} from './AutoArticleForm/config';
import { validateAutoArticle } from './AutoArticleForm/validate';

interface AutoArticleSettingsProps {
    initialValues?: IInitValuesForm;
    onSave: (values: IInitValuesForm) => void;
}

export const AutoArticleSettings: FC<
    AutoArticleSettingsProps
> = ({ onSave, initialValues = initValuesForm }) => {
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validateAutoArticle}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSave}
        >
            {({ handleSubmit }) => {
                return (
                    <Form>
                        <AutoArticleForm
                            onSubmit={handleSubmit}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};
