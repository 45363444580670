import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgEdit = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="m18.4 9.8-3.2-3.2 1.125-1.125c.3-.3.634-.45 1-.45.367 0 .7.15 1 .45l1.2 1.2c.3.3.45.633.45 1 0 .366-.15.7-.45 1L18.4 9.8ZM5.5 19.5h1.05l8.626-8.625-1.05-1.05L5.5 18.451v1.05ZM4 21v-3.2L14.125 7.675l3.2 3.2L7.2 21H4Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgEdit);
export default ForwardRef;
