import React, { FC } from 'react';
import { Form, Formik, useFormikContext } from 'formik';

import * as Yup from 'yup';

import { SourcesReportCreate as SourcesReportCreateType } from '@mega/api';

import {
    Button,
    FormLayout,
    InputField,
    Paper,
    Stack,
} from '@mega/ui';
import { CreatebleSelectField } from '@mega/core';

export interface SourceReportProps {
    initialValue?: SourcesReportCreateType;
    handleCreate?: (value: SourcesReportCreateType) => any;
}

const SourcesReportCreate: FC<SourceReportProps> = ({
    initialValue = { title: '', codes: [] },
    handleCreate = (value) => null,
}) => {
    return (
        <Formik
            validationSchema={Yup.object({
                title: Yup.string()
                    .min(
                        2,
                        'Это поле явно должно быть больше одного символа',
                    )
                    .required('Это поле обязательное'),
                codes: Yup.array()
                    .min(1, 'Это поле обязательноe')
                    .required('Это поле обязательноe'),
            })}
            initialValues={initialValue}
            onSubmit={handleCreate}
        >
            <Form>
                <FormLayout actions={<TagCreateActions />}>
                    <Paper
                        variant="outline"
                        color="white"
                        classes={{
                            padding: { size: '24x24' },
                        }}
                    >
                        <Stack gap="18">
                            <InputField
                                type="text"
                                name={'title'}
                                placeholder="Заголовок"
                                dimension="medium"
                                isRequired
                            />
                            <CreatebleSelectField
                                name="codes"
                                placeholder="Домены"
                            />
                        </Stack>
                    </Paper>
                </FormLayout>
            </Form>
        </Formik>
    );
};

const TagCreateActions = () => {
    const { submitForm, isSubmitting } = useFormikContext();

    return (
        <Paper
            style={{
                borderTop: '1px solid #EAEAEA',
            }}
            fullWidth
            color={'white'}
            variant="filled"
            classes={{
                padding: { size: '24x24' },
            }}
        >
            <Button
                classes={{
                    paper: {
                        variant: 'filled',
                        color: 'dark',
                    },
                }}
                color="secondary"
                size={'large'}
                type={'submit'}
                label={'Создать'}
                onClick={submitForm}
                disabled={isSubmitting}
            />
        </Paper>
    );
};

export { SourcesReportCreate };
