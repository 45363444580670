import {
    createModel,
    RematchDispatch,
} from '@rematch/core';
import type { definitions } from '@mega/api';
import * as CLIENT from '@mega/api';
import { delay } from '@mega/utils';
import type { RootModel } from '../rootModel';
import {
    ApiError,
    Middleware,
} from 'openapi-typescript-fetch';
import {
    SnackbarKey,
    OptionsObject,
    SnackbarMessage,
} from 'notistack';

const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN_KEY';

const snackbarMiddleware =
    (
        enqueueSnackbar: (
            message: SnackbarMessage,
            options?: OptionsObject,
        ) => SnackbarKey,
    ): Middleware =>
    async (url, init, next) => {
        try {
            const response = await next(url, init);
            return response;
        } catch (error) {
            if (error instanceof ApiError) {
                const { data, status } = error;
                // if(Object.values(data).length > 0)
                //   {
                //     data.map((msg) => {
                //       enqueueSnackbar(`${msg.detail}`, { variant: 'error' });
                //   });

                //   }
                if (status === 403)
                    enqueueSnackbar(`${data.detail}`, {
                        variant: 'error',
                    });
            } else {
                enqueueSnackbar('Request failed', {
                    variant: 'error',
                });
            }
            throw error;
        }
    };

const auth = createModel<RootModel>()({
    name: 'auth',
    state: {
        isAuth: false,
        refreshToken: '',
        accessToken: undefined,
    } as {
        isAuth: boolean;
        refreshToken: string;
        accessToken?: string;
    },
    reducers: {
        logined(_state) {
            return { ..._state, isAuth: true };
        },
        setTokens(
            _state,
            payload: {
                accessToken?: string;
                refreshToken: string;
            },
        ) {
            return { ..._state, ...payload };
        },
    },
    effects: (dispatch) => ({
        logout() {
            window.localStorage.clear();
            window.location.reload();
        },
        subscribeNotifyMiddleWare: (enqueueSnackbar) => {
            const middleware =
                snackbarMiddleware(enqueueSnackbar);
            CLIENT.apiClient.subscribeMiddleware(
                middleware,
                'notifyMiddleware',
            );
        },

        reinitApi: async (
            payload: definitions['TokenRefreshExpiresAt'],
        ) => {
            await dispatch.auth.setTokens({
                accessToken: payload.access,
                refreshToken: payload.refresh,
            });
            CLIENT.apiClient.Init({
                accessToken: payload.access,
                refreshToken: payload.refresh,
            });
        },

        saveToken(
            payload: definitions['TokenRefreshExpiresAt'],
        ) {
            window.localStorage.setItem(
                REFRESH_TOKEN_KEY,
                payload.refresh,
            );
        },

        async retry({ fn, count = 3 }): Promise<{
            isOk?: boolean;
            error?: any;
        }> {
            const refreshToken =
                window.localStorage.getItem(
                    REFRESH_TOKEN_KEY,
                );

            let initCount = count;
            let response;
            while (initCount != 0) {
                const delayed = await delay(1000);
                if (delayed) {
                    response = await fn?.();
                    if (response?.isOk) {
                        initCount = 0;
                    } else {
                        const response =
                            await CLIENT.auth.refreshToken?.(
                                {
                                    refresh: `${refreshToken}`,
                                },
                            );
                        if (response?.ok) {
                            await dispatch.auth.reinitApi(
                                response.data,
                            );
                        }
                        initCount = initCount - 1;
                    }
                }
            }
            return response;
        },
        async stageInit() {
            const refreshToken =
                window.localStorage.getItem(
                    REFRESH_TOKEN_KEY,
                );
            if (refreshToken) {
                try {
                    const response =
                        await CLIENT.auth.refreshToken?.({
                            refresh: refreshToken,
                        });

                    if (response?.ok) {
                        await dispatch.auth.reinitApi(
                            response.data,
                        );
                        await dispatch.auth.saveToken(
                            response.data,
                        );
                        await dispatch.auth.logined();

                        await dispatch.user.getMe();
                        // await dispatch.settings.getNavigationConfig(
                        //     {},
                        // );
                        return { OK: true };
                    } else {
                        throw new Error(
                            response?.statusText ?? '',
                        );
                    }
                } catch (e) {
                    if (
                        CLIENT.auth.refreshToken &&
                        e instanceof
                            CLIENT.auth.refreshToken.Error
                    ) {
                        const error = e.getActualType();
                        switch (error.status) {
                            case 401: {
                                dispatch.auth[401](error);
                            }
                        }
                        return { OK: false };
                    }
                }
            }
        },
        async stageLogin(
            payload: definitions['TokenObtainPairExpiresAt'],
        ) {
            try {
                // await delay(1000);
                // await delay(1000);
                const response =
                    await CLIENT.auth.getToken?.(payload);
                if (response?.ok) {
                    await dispatch.auth.reinitApi(
                        response.data,
                    );
                    await dispatch.auth.saveToken(
                        response.data,
                    );
                    await dispatch.auth.logined();
                    await dispatch.user.getMe();
                    // await dispatch.settings.getNavigationConfig(
                    //     {},
                    // );
                }
            } catch (e) {
                console.log(e, 'error');

                if (
                    CLIENT.auth.getToken &&
                    e instanceof CLIENT.auth.getToken.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            dispatch.auth[401](error);
                            return error.data;
                        }
                    }
                }
            }
        },
        async getNavigation() {
            try {
                await dispatch.settings.getNavigationConfig(
                    {},
                );
            } catch (e) {
                console.error(e);
            }
        },
        async 401(error: {
            status: number;
            data: any;
        }): Promise<{
            isOk: boolean;
            message: string;
        }> {
            if (error.data?.code === 'user_inactive') {
                if (
                    window.location.pathname !==
                    '/user_inactive'
                ) {
                    window.location.pathname =
                        '/user_inactive';
                }
            }
            if (error.data?.code === 'token_not_valid') {
                const refreshToken =
                    window.localStorage.getItem(
                        REFRESH_TOKEN_KEY,
                    );
                const response =
                    await CLIENT.auth.refreshToken?.({
                        refresh: `${refreshToken}`,
                    });
                if (response?.ok) {
                    await dispatch.auth.reinitApi(
                        response.data,
                    );
                    return {
                        isOk: true,
                        message: 'api reinit',
                    };
                }
            }

            return {
                isOk: false,
                message: 'reinit api not success',
            };
        },
    }),
});

export { auth };
export default auth;
