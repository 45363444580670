import React, { ChangeEvent, FC, forwardRef } from 'react';
import DatePicker, {
    registerLocale,
} from 'react-datepicker';
import InputMask from 'react-input-mask';
import 'react-datepicker/dist/react-datepicker.css';
import { format, Locale } from 'date-fns';
import rulocale from 'date-fns/locale/ru'; // the locale you want

import {
    PaperClasses,
    paperClasses,
    paddingBaseClasses,
    PaddingBaseClasses,
    typographyClasses,
} from '@mega/styles';
import { useField, useFormikContext } from 'formik';
import dayjs from 'dayjs';
import { inputDate } from './InputDate.css';

registerLocale('ru', rulocale);

export interface DatePickerProps {
    name: string;
    onChange?: () => void;
    classes?: {
        paper?: PaperClasses['recipe'];
        padding?: PaddingBaseClasses['recipe'];
    };
    minDate?: Date | null;
    maxDate?: Date | null;
}

const InputDate: FC<DatePickerProps> = ({
    name,
    classes,
    minDate = null,
    maxDate = new Date(),
}) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField<string>({ name });

    const timeTemplate = 'YYYY-MM-DD';

    const onChangeWithFormat = (value: Date | null) => {
        if (!value) {
            return;
        }
        const data = dayjs(value).format(timeTemplate);

        setFieldValue(field.name, `${data}`);
        return value;
    };

    return (
        <>
            <DatePicker
                name={name}
                selected={
                    (field.value &&
                        new Date(field.value)) ||
                    null
                }
                onChange={onChangeWithFormat}
                disabledKeyboardNavigation
                placeholderText="дд.мм.гггг"
                dateFormat="dd.MM.yyyy"
                locale={'ru'}
                timeInputLabel="Время:"
                minDate={minDate}
                maxDate={maxDate}
                className={[
                    paperClasses.recipe({
                        color: 'gray',
                        borderRadius: 'small',
                        variant: 'outlineFilled',
                        ...(classes?.paper ?? {}),
                    }),
                    paddingBaseClasses.recipe({
                        size: '8x8',
                        ...(classes?.padding ?? {}),
                    }),
                    typographyClasses.recipe({
                        size: '14',
                        weight: 'regular',
                    }),
                    inputDate,
                ].join(' ')}
                customInput={
                    <InputMask
                        mask="99.99.9999"
                        maskChar={null}
                    />
                }
            />
        </>
    );
};

export { InputDate as DatePickerField };
