import { Options } from '@mega/ui/src/components/Inputs/types';

export const handleSelectTagConfig = (
    tags: Options | undefined,
    currentTagLength: number | undefined,
    action: string,
    query: any,
) => {
    return {
        add: {
            initial:
                tags &&
                action === 'add' &&
                tags.length > 0 &&
                tags.length !== currentTagLength,
        },
        delete: {
            initial: tags && action === 'delete',
            last:
                query.tags__in &&
                query.tags__in.split('').length === 1,
        },
        clear:
            tags &&
            tags.length === 0 &&
            query.tags__in &&
            query.tags__in.split('').length === 1,
    };
};
