import React, {
    createContext,
    FC,
    ReactElement,
    useContext,
} from 'react';
import type { EditorCore } from '../../types/core';

const dependencyEditorContext: EditorCore = {
    groups: {},
    controls: {},
    rootElements: {},
    pluginElements: {},
};

const context = createContext<EditorCore>(
    dependencyEditorContext,
);

const DependencyEditorContext: FC<{
    config: EditorCore;
    children: ReactElement | ReactElement[];
}> = ({ config, children }) => (
    <context.Provider value={config}>
        {children}
    </context.Provider>
);

const useDependencyEditorContext = () =>
    useContext(context);

export {
    DependencyEditorContext,
    useDependencyEditorContext,
};
