import * as CLIENT from '@mega/api';
import type { definitions, operations } from '@mega/api';
import { createModel } from '@rematch/core';
import type { RootModel } from '../rootModel';

type PermissionConfig =
    operations['admin_admin_menus-conf_list']['responses']['200']['schema']['models'];

export interface PermissionConfRead {
    [key: string]: {
        name?: string | undefined;
        verbose_name?: string | undefined;
        verbose_name_plural?: string | undefined;
        in_dashboard?: boolean | undefined;
    };
}

type UserState = {
    me: definitions['AdminUserInfo'] | null;
};

const user = createModel<RootModel>()({
    name: 'user',
    state: {
        me: null,
    } as UserState,
    reducers: {
        putMe(
            state,
            payload: definitions['AdminUserInfo'],
        ) {
            return { ...state, me: payload };
        },
    },
    selectors: (slice) => ({
        getUser() {
            return slice((store) => store.me);
        },
        getSites() {
            return slice((store) => store.me?.sites ?? []);
        },
        getRootPermission() {
            return slice((store) =>
                Array.from(
                    new Set(
                        store.me?.permissions.map(
                            (permission) =>
                                permission.split('.')[0],
                        ),
                    ).values(),
                ).filter((item) =>
                    [
                        'article',
                        'author',
                        'category',
                        'hostvideo',
                        'tag',
                        'mainpage',
                        'story',
                        'infounittask',
                        'report',
                        'copyright',
                        'issue',
                        'mainpagestream',
                        'block',
                        'headscript',
                        'infinitemix',
                        'newspaper',
                        'bannedorganization',
                    ].find((per) => item === per),
                ),
            );
        },
        getSubRootPermission() {
            return slice((store) =>
                store.me?.permissions.reduce(
                    (
                        acc: {
                            [key: string]: Array<string>;
                        },
                        item,
                    ) => {
                        const [key, value] =
                            item.split('.');

                        return {
                            ...acc,
                            [key]: [
                                ...(acc?.[key] ?? []),
                                value,
                            ],
                        };
                    },
                    {},
                ),
            );
        },
    }),
    effects: (dispatch) => ({
        async getMe() {
            try {
                const response =
                    await CLIENT.user.getUserInfoAuth?.({});
                if (response?.ok) {
                    dispatch.user.putMe(response.data);
                }
            } catch (e) {
                if (
                    CLIENT.user.getUserInfoAuth &&
                    e instanceof
                        CLIENT.user.getUserInfoAuth.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            dispatch.auth[401](error);
                            return error.data;
                        }
                    }
                }
            }
        },
    }),
});

export { user };
export default user;
