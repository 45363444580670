import { useStoreSelector, RootState } from '../../models';
import { useAuthDispatch } from '@mega/store';
import { useSnackbar } from 'notistack';

const useNotifyStore = () => {
    return useStoreSelector(
        (store: RootState) => store.notify,
    );
};

const useSubscribeNotify = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { subscribeNotifyMiddleWare } = useAuthDispatch();

    return { enqueueSnackbar, subscribeNotifyMiddleWare };
};

export { useNotifyStore, useSubscribeNotify };
