import { Client, MsClient } from './client';

let API_URL: string = '';
let MEDIASHARE_API_URL: string = '';

try {
    if (
        Boolean(
            //@ts-ignore
            process.env.REACT_APP_API_URL ||
                //@ts-ignore
                process.env.STORYBOOK_API_URL,
        )
    ) {
        //@ts-ignore

        if (process.env.REACT_APP_API_URL) {
            //@ts-ignore

            API_URL = process.env.REACT_APP_API_URL;
        }
        if (process.env.REACT_APP_API_URL_MEDIASHARE) {
            //@ts-ignore

            MEDIASHARE_API_URL =
                process.env.REACT_APP_API_URL_MEDIASHARE;
        }
        //@ts-ignore
        if (process.env.STORYBOOK_API_URL) {
            //@ts-ignore
            API_URL = process.env.STORYBOOK_API_URL;
        }
    } else {
        throw new Error(
            'YOU NOT PROVIDE API_URL FOR YOUR APP',
        );
    }
} catch (e) {
    console.error(e);
}

const mediashareApiClient = new MsClient({
    baseUrl: MEDIASHARE_API_URL,
});
const apiClient = new Client({
    baseUrl: API_URL,
});

const auth = apiClient.Auth();
const user = apiClient.User();
const articles = apiClient.Articles();
const images = apiClient.Images();
const videos = apiClient.Videos();
const mainGrid = apiClient.MainGrid();
const authors = apiClient.Authors();
const categories = apiClient.Categories();
const tags = apiClient.Tags();
const permissionConf = apiClient.Nav();
const rss = apiClient.RSS();
const rooms = apiClient.Rooms();
const infounittask = apiClient.Infounittask();
const notify = apiClient.Notify();
const reports = apiClient.Reports();
const copyrights = apiClient.Copyrights();
const stories = apiClient.Stories();
const reposts = apiClient.Reposts();
const articleHistory = apiClient.ArticleHistory();
const newspapers = apiClient.Newspapers();
const redactors = apiClient.Redactors();
const infounit = apiClient.Infounits();
const papers = apiClient.Papers();
const documents = apiClient.Documents();
const streams = apiClient.Streams();
const ads = apiClient.Ads();
const infiniteMix = apiClient.InfinityMix();
const bannedOrganizations = apiClient.BannedOrganizations();
const mediashare = mediashareApiClient.Mediashare();
const files = apiClient.Files();
const mediashareAuth = mediashareApiClient.MediashareAuth();

export {
    apiClient,
    mediashareApiClient,
    auth,
    user,
    articles,
    images,
    videos,
    authors,
    categories,
    tags,
    permissionConf,
    rss,
    rooms,
    infounittask,
    notify,
    reports,
    copyrights,
    stories,
    reposts,
    articleHistory,
    redactors,
    infounit,
    papers,
    documents,
    streams,
    ads,
    infiniteMix,
    newspapers,
    bannedOrganizations,
    mediashare,
    mediashareAuth,
    mainGrid,
    files,
};

export * from './api';
export * from './types';
