import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';

const useStreamsStore = () => {
    const state = useStoreSelector(
        (state: RootState) => state.streams,
    );
    const loading = useStoreSelector(
        (state: RootState) => state.loading.effects.streams,
    );

    return Object.assign(state, {
        loading: {
            get: loading.get,
            chungeCountItemsOnPage:
                loading.chungeCountItemsOnPage,
        },
    });
};
const useStreamsDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        get: dispatch.streams.get,
        nextPage: dispatch.streams.nextPage,
        search: dispatch.streams.search,
        clear: dispatch.streams.clear,
        jumpTo: dispatch.streams.jumpTo,
    };
};

export { useStreamsStore, useStreamsDispatch };
