/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
    CSSProperties,
    FC,
    MouseEvent,
    ReactElement,
    useState,
} from 'react';

import { Popover, PopoverOrigin } from '@mui/material';
import {
    paddingTBClasses,
    PaddingTBClasses,
} from '@mega/styles';

import {
    menuClasses as classes,
    MenuClasses,
} from './Menu.css';

export interface MenuProps {
    style?: CSSProperties;
    paddingTB?: PaddingTBClasses['recipe'];
    children: JSX.Element | JSX.Element[];
    className?: string;
    gap?: '4px' | '8px' | '16px' | '18px' | '24px';
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;

    trigger: (props: {
        ref: HTMLButtonElement | null;
        handleClose: (e: MouseEvent) => void;
        handleClick: (e: MouseEvent) => void;
    }) => ReactElement;
}

const Menu: FC<MenuProps & MenuClasses['recipe']> = ({
    paddingTB = { size: '16' },
    children,
    className = '',
    trigger,
    variant = 'primary',
    gap = '4px',
    style = {},
    anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin = {
        vertical: 'top',
        horizontal: 'left',
    },
}) => {
    const [anchorEl, setAnchorEl] =
        useState<HTMLButtonElement | null>(null);

    const handleClick = (event: unknown) => {
        if (typeof event === 'object' && event) {
            if (
                Object.prototype.hasOwnProperty.call(
                    event,
                    'currentTarget',
                )
            ) {
                // @ts-ignore
                setAnchorEl(event.currentTarget);
            }
        }
    };

    const handleClose = (e: MouseEvent) => {
        e?.preventDefault?.();
        e?.stopPropagation?.();
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <>
            {trigger({
                ref: anchorEl,
                handleClose,
                handleClick,
            })}
            <Popover
                id="test"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                }}
            >
                <div
                    style={{
                        margin: gap,
                        ...style,
                    }}
                    className={[
                        className,
                        classes.recipe({
                            variant,
                        }),
                        paddingTBClasses.recipe(paddingTB),
                    ].join(' ')}
                >
                    {children}
                </div>
            </Popover>
        </>
    );
};

export { Menu };
