/* eslint-disable @typescript-eslint/no-unused-vars */
import { Ads } from './Ads';
import {
    AdsControl,
    AdsEnum,
    AdsPluginDefault,
} from './Ads.types';

const PLUGIN_ADS_NAME = AdsEnum.type;

const DEFAULT_ADS: AdsPluginDefault = {
    type: PLUGIN_ADS_NAME,
    meta: {
        dataId: '',
        label: '',
        body_script: '',
        height: 120,
    },
    childrenElement: [],
};

const PLUGIN_ADS = {
    [PLUGIN_ADS_NAME]: {
        emptyObject: DEFAULT_ADS,
        render: Ads,
    },
};

const PLUGIN_META_DEFAULT = {
    dataId: '',
    label: '',
    body_script: '',
    height: 120,
};

const PLUGIN_ADS_CONTROL: AdsControl = {
    name: PLUGIN_ADS_NAME,
    label: 'Ads',
    tooltip: 'Создать рекламу',
    meta: PLUGIN_META_DEFAULT,
    Icon: () => <></>,
};

export {
    DEFAULT_ADS,
    PLUGIN_ADS,
    PLUGIN_ADS_CONTROL,
    PLUGIN_ADS_NAME,
};
