/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Control } from '../../../../types/core';
import { LastNews } from './LastNews';
import {
    LastNewsEnum,
    LastNewsPluginDefault,
} from './LastNews.types';

const PLUGIN_LAST_NEWS_NAME = LastNewsEnum.type;

const DEFAULT_LAST_NEWS: LastNewsPluginDefault = {
    type: PLUGIN_LAST_NEWS_NAME,
    meta: {},
    childrenElement: [],
};

const PLUGIN_LAST_NEWS = {
    [PLUGIN_LAST_NEWS_NAME]: {
        emptyObject: DEFAULT_LAST_NEWS,
        render: LastNews,
    },
};

const PLUGIN_LAST_NEWS_CONTROL: Control = {
    name: PLUGIN_LAST_NEWS_NAME,
    label: 'LastNews',
    tooltip: 'Последние новости',
    Icon: () => <></>,
};

export {
    DEFAULT_LAST_NEWS,
    PLUGIN_LAST_NEWS,
    PLUGIN_LAST_NEWS_CONTROL,
    PLUGIN_LAST_NEWS_NAME,
};
