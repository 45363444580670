import React, { FC, useState, useEffect } from 'react';

import {
    usePapersStore,
    usePapersDispatch,
    useSiteStore,
} from '@mega/store';
import {
    useLocation,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import {
    Button,
    EntityListLayout,
    Icon,
    Input,
    ListContent,
    SelectSingle,
    WithTrigger,
    Pagination,
} from '@mega/ui';
import { Add, ArrowDown, Search } from '@mega/icons';
import {
    ActiveFilter,
    getOptionsNewspapers,
    ListingNewspapers,
    NoResultsFoundMessage,
} from '@mega/core';

interface NewspapersProps {
    children?: never;
}

export type Option = {
    label: string;
    value: string | number;
};

const Newspapers: FC<NewspapersProps> = () => {
    const site = useSiteStore();
    const store = usePapersStore();
    const { search, jumpTo } = usePapersDispatch();
    let navigate = useNavigate();
    let location = useLocation();
    const [, setSearchParams] = useSearchParams();

    const resetPaginatorToFirstPage = () => {
        setSearchParams({ page: '1' });
        jumpTo(1);
    };

    const [newspaperFilter, setNewspaperFilter] = useState<{
        [key: string]: string;
    } | null>(null);

    const newspapersListName = 'newspapersList';

    const allNewspapersCount = store.meta.count || 0;
    const newspapersPerPage = store.query.per_page || 1;
    const allPagesCount = Math.ceil(
        allNewspapersCount / newspapersPerPage,
    );

    const hasPage = store.pages['1'] ? true : false;
    const isItLoadingNow = store.loading.get.loading;
    const isPageEmpty =
        !isItLoadingNow &&
        hasPage &&
        store.pages['1'].length === 0;

    useEffect(() => {
        if (!isItLoadingNow) {
            window.scrollTo(0, 0);
        }
    }, [isItLoadingNow]);

    const handleSelect = (
        type: string,
        e: Option | undefined,
    ) => {
        resetPaginatorToFirstPage();
        search({
            [type]: e?.value || null,
        });
        setNewspaperFilter({
            [type]: e?.label || '',
        });
    };
    //@ts-ignore
    const {
        newspaper = null,
    }: { newspaper: number | null } = store.query;
    return (
        <EntityListLayout
            setting={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '18px',
                        }}
                    >
                        <div style={{ minWidth: '300px' }}>
                            <Input
                                startIcon={<Search />}
                                color="secondary"
                                dimension="medium"
                                value={
                                    store?.query?.search ||
                                    ''
                                }
                                type="text"
                                onChange={(e) => {
                                    resetPaginatorToFirstPage();
                                    return search({
                                        search: e.target
                                            .value,
                                    });
                                }}
                            />
                        </div>
                        <div>
                            <WithTrigger
                                trigger={({
                                    handleClick,
                                }) => (
                                    <Button
                                        classes={{
                                            paper: {
                                                color: 'gray',
                                            },
                                        }}
                                        size="large"
                                        onClick={
                                            handleClick
                                        }
                                        label="Издание"
                                        endIcon={
                                            <Icon size="18">
                                                <ArrowDown />
                                            </Icon>
                                        }
                                    />
                                )}
                            >
                                <SelectSingle
                                    classes={{
                                        paper: {
                                            color: 'dark',
                                        },
                                        select: {
                                            variant:
                                                'primary',
                                        },
                                    }}
                                    menuIsOpen
                                    handleLoader={getOptionsNewspapers(
                                        {
                                            site:
                                                site?.id ||
                                                '',
                                        },
                                    )}
                                    actionOnSelectedOption={(
                                        e,
                                    ) => {
                                        if (e) {
                                            handleSelect(
                                                'newspaper',
                                                e,
                                            );
                                        }
                                    }}
                                />
                            </WithTrigger>
                        </div>
                        <div style={{ marginLeft: 'auto' }}>
                            <Button
                                size={'large'}
                                endIcon={<Add />}
                                label="Добавить"
                                classes={{
                                    paper: {
                                        variant: 'filled',
                                        color: 'dark',
                                    },
                                }}
                                onClick={() => {
                                    navigate(`add`, {
                                        replace: true,
                                        state: location,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        {newspaper && newspaperFilter && (
                            <ActiveFilter
                                onDelete={() => {
                                    handleSelect(
                                        'newspaper',
                                        undefined,
                                    );
                                    setNewspaperFilter(
                                        null,
                                    );
                                }}
                                text={`${newspaperFilter['newspaper']}`}
                            />
                        )}
                    </div>
                </div>
            }
        >
            <>
                <ListContent name={newspapersListName}>
                    {() => {
                        return <ListingNewspapers />;
                    }}
                </ListContent>
                <NoResultsFoundMessage
                    isPageEmpty={isPageEmpty}
                />
                <Pagination
                    pageCount={allPagesCount}
                    jumpTo={jumpTo}
                    loading={isItLoadingNow}
                />
            </>
        </EntityListLayout>
    );
};

export { Newspapers };
