/* eslint-disable no-debugger */
import { createModel } from '@rematch/core';
import type {
    EditorModel,
    Elements,
    RecursiveArray,
} from '@apostroph/types';
import { v4 as uuidv4 } from 'uuid';
import { isEqual } from 'lodash-es';
import { arrayMoveImmutable } from 'array-move';
import type { RootModel } from '../connector';

const editor = createModel<RootModel>()({
    state: {
        items: [],
        data: {},
    } as EditorModel,
    reducers: {
        initEditor: (_, payload: EditorModel) => payload,
        createElement: (
            state,
            data: {
                payload: Omit<Elements, 'id'>;
                where?: string;
            },
        ) => {
            const { payload, where } = data;
            const createId = uuidv4() as string;

            if (where) {
                const newStore: EditorModel = {
                    ...state,
                    data: {
                        ...state.data,
                        [where]: {
                            ...state.data[where],
                            childrenElement: [
                                ...(state.data[where]
                                    ?.childrenElement ||
                                    []),
                                createId,
                            ],
                        },
                        [createId]: {
                            id: createId,
                            ...payload,
                        },
                    },
                };
                return newStore;
            }

            const newStore: EditorModel = {
                ...state,
                items: [...state.items, createId],
                data: {
                    ...state.data,
                    [createId]: {
                        ...payload,
                        id: createId,
                    },
                },
            };
            return newStore;
        },
        updateElement: (state, payload: Elements) => {
            const updateElementId = payload.id;
            const clearElementData =
                state.data[updateElementId];
            if (!isEqual(payload, clearElementData)) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        [payload.id]: payload,
                    },
                };
            }
            return state;
        },
        removeElement: (
            state,
            payload: { what: string; parent?: string },
        ) => {
            const { what, parent } = payload;

            if (parent) {
                const currentObject = state.data[what];

                const searchChildren = (
                    element: Elements,
                ): RecursiveArray<string> => {
                    const children =
                        element.childrenElement;
                    return [
                        ...children,
                        ...children.map((childId) =>
                            searchChildren(
                                state.data[childId],
                            ),
                        ),
                    ];
                };

                const allChildren: Array<string> =
                    // @ts-ignore
                    searchChildren(currentObject).flat(
                        Infinity,
                    );

                const newData = Object.fromEntries(
                    Object.entries(state.data).filter(
                        ([key]) =>
                            [what, ...allChildren].includes(
                                key,
                            ),
                    ),
                );

                const parentData = state.data[parent];
                const newParent = {
                    ...parentData,
                    childrenElement:
                        parentData.childrenElement.filter(
                            (id) => id !== what,
                        ),
                };

                const exclude = new Set(
                    Object.keys(newData),
                );

                return {
                    items: state.items,
                    data: {
                        ...Object.fromEntries(
                            Object.entries(
                                state.data,
                            ).filter(
                                (e) => !exclude.has(e[0]),
                            ),
                        ),
                        [parent]: newParent,
                    },
                };
            }

            const currentObject = state.data[what];

            const searchChildren = (
                element: Elements,
            ): RecursiveArray<string> => {
                const children = element.childrenElement;
                return [
                    ...children,
                    ...children.map((childId) =>
                        searchChildren(state.data[childId]),
                    ),
                ];
            };

            const allChildren: Array<string> =
                searchChildren(currentObject).flat(
                    Infinity,
                );

            const newItems = state.items.filter(
                (id) => id !== what,
            );

            const exclude = new Set(
                Object.keys(allChildren),
            );

            return {
                items: newItems,
                data: {
                    ...Object.fromEntries(
                        Object.entries(state.data).filter(
                            (e) => !exclude.has(e[0]),
                        ),
                    ),
                },
                // data: omit(state.data, allChildren),
            };
        },

        moveElementUp: (
            state,
            payload: { what: string; parent?: string },
        ) => {
            const { what, parent } = payload;
            if (parent) {
                const currentParent = state.data[parent];
                const currentPosition =
                    currentParent.childrenElement.findIndex(
                        (item) => item === what,
                    );
                if (currentPosition === 0) {
                    return state;
                }

                return {
                    ...state,
                    data: {
                        ...state.data,
                        [parent]: {
                            ...currentParent,
                            childrenElement:
                                arrayMoveImmutable(
                                    state.data[parent]
                                        .childrenElement,
                                    currentPosition,
                                    currentPosition - 1,
                                ),
                        },
                    },
                };
            }

            const currentPosition = state.items.findIndex(
                (item) => item === what,
            );

            if (currentPosition === 0) {
                return state;
            }

            return {
                ...state,
                items: arrayMoveImmutable(
                    state.items,
                    currentPosition,
                    currentPosition - 1,
                ),
            };
        },
        moveElementDown: (
            state,
            payload: { what: string; parent?: string },
        ) => {
            const { what, parent } = payload;

            if (parent) {
                const currentParent = state.data[parent];
                const endList =
                    currentParent.childrenElement.length;
                const currentPosition =
                    currentParent.childrenElement.findIndex(
                        (item) => item === what,
                    );

                if (currentPosition === endList) {
                    return state;
                }
                return {
                    ...state,
                    data: {
                        ...state.data,
                        [parent]: {
                            ...currentParent,
                            childrenElement:
                                arrayMoveImmutable(
                                    state.data[parent]
                                        .childrenElement,
                                    currentPosition,
                                    currentPosition + 1,
                                ),
                        },
                    },
                };
            }

            const endList = state.items.length;
            const currentPosition = state.items.findIndex(
                (item) => item === what,
            );

            if (currentPosition === endList) {
                return state;
            }
            return {
                ...state,
                items: arrayMoveImmutable(
                    state.items,
                    currentPosition,
                    currentPosition + 1,
                ),
            };
        },
        insertElement: (
            state,
            data: {
                payload: Omit<Elements, 'id'>;
                anchor: string;
                where?: string;
                mod?: 'after' | 'before';
            },
        ) => {
            const { payload, where, anchor } = data;
            const createId = uuidv4();

            if (where) {
                const currentParent = state.data[where];
                const currentPosition2 =
                    currentParent.childrenElement.findIndex(
                        (item) => item === anchor,
                    );

                if (
                    payload.type === 'SECTION' &&
                    currentParent.type === 'SECTION'
                )
                    return { ...state };

                const updatedChildrenElement = [
                    ...(state.data[
                        where
                    ]?.childrenElement?.slice(
                        0,
                        currentPosition2,
                    ) || []),
                    createId,
                    ...(state.data[
                        where
                    ]?.childrenElement?.slice(
                        currentPosition2,
                    ) || []),
                ];

                const updatedWhere = {
                    ...state.data[where],
                    childrenElement: updatedChildrenElement,
                };

                const updatedStateData = {
                    ...state.data,
                    [where]: updatedWhere,
                    [createId]: {
                        id: createId,
                        ...payload,
                    },
                };

                return {
                    ...state,
                    data: updatedStateData,
                };
            }
        },
    },
});

export { editor };
export default editor;
