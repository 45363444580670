import { Typography } from '@mega/ui';
import classes from './ActionMenuAction.css';
function ActionMenuAction({
    Icon,
    onClick,
    onClose,
    label,
}: {
    Icon?: React.ReactNode;
    label?: string;
    onClick?: () => void;
    onClose?: () => void;
}) {
    return (
        <button
            className={classes.base}
            onClick={() => {
                onClick?.();
                onClose?.();
            }}
        >
            {Icon}

            <Typography size="14" weight="regular">
                {label}
            </Typography>
        </button>
    );
}

export { ActionMenuAction };
