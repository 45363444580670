import { Form, Formik } from 'formik';
import { FC } from 'react';
import { ArticleForm } from './ArticleForm/ArticleForm';
import {
    IInitValuesForm,
    initValuesForm,
} from './ArticleForm/config';
import { validateArticle } from './ArticleForm/validate';

interface ArticleSettingsProps {
    initialValues?: IInitValuesForm;
    onSave: (values: IInitValuesForm) => void;
}

export const ArticleSettings: FC<ArticleSettingsProps> = ({
    onSave,
    initialValues = initValuesForm,
}) => {
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validateArticle}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSave}
        >
            {({ handleSubmit }) => (
                <Form>
                    <ArticleForm onSubmit={handleSubmit} />
                </Form>
            )}
        </Formik>
    );
};
