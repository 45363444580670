import React, { FC } from 'react'; // TODO перенести стили
import { styles as s } from './FlowSettings.css';
import { Button, CheckboxSwitchField } from '@mega/ui';
import { Form, Formik } from 'formik';
export type InitFormValues = { has_divider?: boolean };

export interface FlowSettingsProps {
    onSave: (values: InitFormValues) => void;
    onChange?: ({
        value,
        field,
    }: {
        value: string;
        field: string;
    }) => void;
    align?: string;
}

export type AlignConfig = Array<{
    value: string;
    icon: FC;
}>;
const AlignBlock: React.FC<{
    config: AlignConfig;
    onChange: (value: string) => void;
    activeItem?: string;
}> = ({ config, onChange, activeItem }) => {
    return (
        <div className={s.alignList}>
            {config.map((item, idx) => {
                const Icon = item.icon;
                return (
                    <button
                        type={'button'}
                        className={s.alignItem({
                            isActive:
                                activeItem === item.value,
                        })}
                        key={idx}
                        onClick={() => onChange(item.value)}
                    >
                        <Icon />
                    </button>
                );
            })}
        </div>
    );
};

export const FlowSettings: React.FC<FlowSettingsProps> = (
    props,
) => {
    const { onChange, onSave, align } = props;

    return (
        <Formik
            initialValues={{ has_divider: false }}
            onSubmit={onSave}
        >
            {({ handleSubmit }) => {
                return (
                    <Form>
                        <div className={s.content}>
                            <p className={s.helper}>
                                Теперь вы можете изменить
                                размеры блоков при помощи
                                появившихся ползунков между
                                ними.
                            </p>

                            <div
                                style={{
                                    background: '#EAEAEA',
                                    borderRadius: 4,
                                    padding: '0.5rem',
                                    display: 'grid',
                                    gap: 20,
                                }}
                            >
                                <CheckboxSwitchField
                                    name="has_divider"
                                    label="С разделителем"
                                    theme={'secondary'}
                                />
                            </div>

                            {/*<AlignBlock*/}
                            {/*    config={verticalConfig}*/}
                            {/*    activeItem={align || 'start'}*/}
                            {/*    onChange={(value: string) => {*/}
                            {/*        onChange({*/}
                            {/*            value,*/}
                            {/*            field: 'alignItems',*/}
                            {/*        });*/}
                            {/*    }}*/}
                            {/*/>*/}
                            {/*<AlignBlock config={horizontalConfig} />*/}
                            <Button
                                type={'button'}
                                onClick={() =>
                                    handleSubmit()
                                }
                                label={'Сохранить'}
                                classes={{
                                    paper: {
                                        color: 'dark',
                                        variant: 'filled',
                                    },
                                }}
                            />
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
