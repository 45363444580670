import * as CLIENT from '@mega/api';
import type {
    GetADSHeadScript,
    GetADSHeadScriptQuery,
    GetADSHeadScriptError,
    IisOk,
    IisNotOk,
} from '@mega/api';

export type GetAdsHeadScriptPromise = Promise<
    | IisOk<GetADSHeadScript>
    | IisNotOk<GetADSHeadScriptError | null>
>;

export const getAdsHeadScript = async (
    query: GetADSHeadScriptQuery,
    site: string,
): GetAdsHeadScriptPromise => {
    try {
        const response =
            await CLIENT?.ads?.getAdsHeadScripts?.(
                {
                    ...query,
                },
                {
                    headers: {
                        'X-Site': site,
                    },
                },
            );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.streams.get &&
            e instanceof CLIENT.streams.get.Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: null,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};
