import React, { FC, ReactElement } from 'react';

import {
    indicatorsClasses,
    IndicatorsClasses,
} from './Indicators.css';

export interface IndicatorsProps {
    label?: string | (() => ReactElement);
    className?: string;
    loading?: boolean;
    renderLoader?: () => React.ReactNode;
}

const Indi: FC<
    IndicatorsProps & IndicatorsClasses['recipe']
> = ({
    label,
    colors,
    wrap,
    widthMin,
    className,
    renderLoader,
    loading,
}) => (
    <div
        className={[
            indicatorsClasses.recipe({
                colors,
                wrap,
                widthMin,
            }),
            className,
        ].join(' ')}
    >
        {typeof label === 'string' ? label : label?.()}

        {loading && renderLoader && renderLoader()}
    </div>
);

export { Indi };
