import React, {
    FC,
    ReactElement,
    cloneElement,
    useState,
    MouseEvent,
} from 'react';

import { assignInlineVars } from '@vanilla-extract/dynamic';

import { Modal as ModalUtility } from '@mega/utils';

import {
    centerLayoutClasses,
    paperClasses,
    paddingBaseClasses,
    PaperClasses,
    PaddingBaseClasses,
    typographyClasses,
} from '@mega/styles';

import { modalClasses } from './Modal.css';

export interface ModalProps {
    Trigger: ReactElement;
    classes?: {
        paper?: PaperClasses['recipe'];
        padding?: PaddingBaseClasses['recipe'];
    };
    Content: (
        fn: (e?: MouseEvent<HTMLButtonElement>) => void,
    ) => ReactElement;
    width?: string;
    height?: string;
}

const Modal: FC<ModalProps> = ({
    Trigger,
    Content,
    classes,
    width = 'min-content',
    height = 'min-content',
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const handleOpen = () => {
        setIsOpen(true);
    };
    const handleClose = (
        e?: MouseEvent<HTMLButtonElement>,
    ) => {
        if (e) {
            e.stopPropagation();
        }
        setIsOpen(false);
    };

    const CloneTrigger = cloneElement(Trigger, {
        onClick: handleOpen,
    });

    return (
        <>
            {CloneTrigger}
            <ModalUtility
                open={isOpen}
                onClose={() => handleClose()}
            >
                <div
                    className={[
                        centerLayoutClasses.recipe({}),
                    ].join(' ')}
                    style={{
                        pointerEvents: 'none',
                    }}
                >
                    <div
                        className={[
                            paperClasses.recipe({
                                variant: 'outlineFilled',
                                color: 'gray',
                                borderRadius: 'small',
                                ...(classes?.paper ?? {}),
                            }),
                            paddingBaseClasses.recipe({
                                size: '18x18',
                                ...(classes?.padding ?? {}),
                            }),
                            typographyClasses.recipe({
                                size: '14',
                                weight: 'medium',
                            }),
                            modalClasses.base,
                        ].join(' ')}
                        style={assignInlineVars({
                            [modalClasses.vars.width]:
                                width,
                            [modalClasses.vars.height]:
                                height,
                        })}
                    >
                        {Content(handleClose)}
                    </div>
                </div>
            </ModalUtility>
        </>
    );
};

export { Modal };
