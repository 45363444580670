import React, { FC, PropsWithChildren } from 'react';
import {
    components,
    MenuListProps,
    MenuProps,
} from 'react-select';
import { wrapMenuList } from 'react-select-async-paginate';
import { selectSingleClasses } from '../../SelectSingle.css';
export type MenuListOverideProps = MenuListProps<
    Record<string, unknown>,
    true
>;

const CustomMenuListPrimary = ({
    ...props
}: PropsWithChildren<MenuListOverideProps>) => (
    <components.MenuList
        className={selectSingleClasses.menuListRecipe({
            variant: 'primary',
        })}
        {...props}
    ></components.MenuList>
);

const CustomMenuListSecondary = ({
    ...props
}: PropsWithChildren<MenuListOverideProps>) => (
    <components.MenuList
        className={selectSingleClasses.menuListRecipe({
            variant: 'secondary',
        })}
        {...props}
    ></components.MenuList>
);

const CustomMenuListThird = ({
    ...props
}: PropsWithChildren<MenuListOverideProps>) => (
    <components.MenuList
        className={selectSingleClasses.menuListRecipe({
            variant: 'third',
        })}
        {...props}
    ></components.MenuList>
);

const MenuListPrimary = wrapMenuList(CustomMenuListPrimary);
const MenuListSecondary = wrapMenuList(
    CustomMenuListSecondary,
);
const MenuListThird = wrapMenuList(CustomMenuListThird);

export {
    MenuListPrimary,
    MenuListSecondary,
    MenuListThird,
};
export default MenuListPrimary;
