import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgRefresh = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M8.05 14a5.864 5.864 0 0 1-2.35-.475 6.202 6.202 0 0 1-1.925-1.292 6.033 6.033 0 0 1-1.3-1.917A5.864 5.864 0 0 1 2 7.967c0-.834.158-1.612.475-2.333a5.986 5.986 0 0 1 1.3-1.893A6.158 6.158 0 0 1 5.7 2.467 5.955 5.955 0 0 1 8.05 2c.889 0 1.73.195 2.524.583.79.385 1.491.93 2.059 1.6V2.418h1v3.466H10.15v-1h1.75a5.796 5.796 0 0 0-1.725-1.368A4.533 4.533 0 0 0 8.051 3c-1.39 0-2.578.475-3.567 1.425C3.494 5.375 3 6.539 3 7.916c0 1.412.489 2.612 1.467 3.6C5.445 12.506 6.639 13 8.05 13c1.39 0 2.562-.489 3.517-1.467.956-.978 1.433-2.16 1.433-3.55h1c0 1.667-.578 3.086-1.733 4.258C11.11 13.414 9.706 14 8.05 14Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgRefresh);
export default ForwardRef;
