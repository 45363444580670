import { isObject, isString, throttle } from 'lodash-es';

import {
    Dispatch,
    RootState,
    useGridEditorDispatch,
    useGridEditorSelector,
} from '../store';
import { useDependencyEditorContext } from '../context';
import { Elements } from '../../types/blocks';
import { Control } from '../../types/core';
import { EditorModel } from '../../types/state';
import { useRef } from 'react';

function isOmitedElementsGuards(
    element: unknown,
): element is Omit<Elements, 'id'> {
    const checker = element as Elements;
    return (
        isObject(checker) &&
        Boolean(checker?.type) &&
        Boolean(checker?.childrenElement) &&
        Boolean(checker?.meta) &&
        Boolean(!checker?.id)
    );
}

function isId(id: unknown): id is string {
    return typeof id === 'string';
}

const useEditor = () => {
    const smartGridEditor = useGridEditorSelector(
        (state: RootState) => state.smartGridEditor,
    );
    const dispatch = useGridEditorDispatch<Dispatch>();
    const potokus = Boolean(
        Object.values(smartGridEditor.data).find(
            (el) => el.type === 'POTOKUS',
        )?.id,
    );

    const { controls, rootElements, pluginElements } =
        useDependencyEditorContext();

    const handleCreateElement = (
        payload: Omit<Elements, 'id'> | unknown,
        where?: string | unknown,
        meta?: Record<string, unknown>,
    ) => {
        if (
            isOmitedElementsGuards(payload) &&
            isId(where)
        ) {
            return dispatch.smartGridEditor.createElement({
                payload,
                where,
                meta,
            });
        }
        if (isOmitedElementsGuards(payload)) {
            return dispatch.smartGridEditor.createElement({
                payload,
            });
        }
        throw new Error('handleCreateElement - wtf?');
    };

    const handleCreateElementAtCustomPosition = (
        payload: Omit<Elements, 'id'> | unknown,
        where?: string | unknown,
        meta?: Record<string, unknown>,
        neighbour?: string | unknown,
        childrenElement?: string[],
    ) => {
        if (
            isOmitedElementsGuards(payload) &&
            isId(where) &&
            isId(neighbour)
        ) {
            return dispatch.smartGridEditor.createElementAtCustomPosition(
                {
                    payload,
                    where,
                    meta,
                    neighbour,
                    childrenElement,
                },
            );
        }

        if (isOmitedElementsGuards(payload)) {
            return dispatch.smartGridEditor.createElement({
                payload,
            });
        }
        throw new Error('handleCreateElement - wtf?');
    };

    const handleMoveElement = ({
        what,
        where,
        parent,
    }: {
        what: string | unknown;
        where: string | unknown;
        parent: string | unknown;
    }) => {
        if (parent) {
            if (isId(what) && isId(where) && isId(parent)) {
                return dispatch.smartGridEditor.moveElement(
                    {
                        what,
                        where,
                        parent,
                    },
                );
            }
        }
        throw new Error('handleMoveElement - wtf?');
    };

    const handleChangeElements = ({
        payload,
        id,
        parentId,
        newId,
    }: {
        payload: any;
        id: string;
        parentId: string;
        newId: string;
    }) => {
        if (
            payload &&
            isId(id) &&
            isId(parentId) &&
            isId(parentId)
        ) {
            return dispatch.smartGridEditor.changeElements({
                payload,
                id,
                parentId,
                newId,
            });
        }

        throw new Error('handleCreateElement - wtf?');
    };

    const handleRemoveElement = ({
        what,
        parent,
    }: {
        what: string | unknown;
        parent?: string | unknown;
    }) => {
        if (isId(what) && isId(parent)) {
            return dispatch.smartGridEditor.removeElement({
                what,
                parent,
            });
        }
        if (isId(what)) {
            return dispatch.smartGridEditor.removeElement({
                what,
            });
        }
        throw new Error('handleCreateElement - wtf?');
    };

    const handleUpdateElement = (
        payload: Partial<Elements>,
    ) => dispatch.smartGridEditor.updateElement(payload);

    const throttledHandleUpdateBlock = useRef(
        throttle(handleUpdateElement, 1000),
    );
    const getControlsList = () => {
        return Object.keys(controls).map((key) => ({
            name: key,
            label: controls[key].label,
        }));
    };
    const getControlsByType = (
        typeBlock: string,
    ): Array<Control> | [] => {
        return controls?.[typeBlock].list || [];
    };

    const getParent = (parentId: string | unknown) => {
        if (isId(parentId)) {
            return smartGridEditor.data[parentId];
        }
        return null;
    };

    const getTemplate = (type: string | unknown) => {
        if (isString(type)) {
            const tempalteRoot =
                rootElements?.[type]?.emptyObject;
            const templatePlugin =
                pluginElements?.[type]?.emptyObject;

            if (templatePlugin) {
                return templatePlugin;
            }
            if (tempalteRoot) {
                return tempalteRoot;
            }
            return null;
        }
        return null;
    };

    const getCurrentDataElement = <T>(id: string) => {
        return smartGridEditor.data[id];
    };
    const getCurrentState = () => smartGridEditor;

    return {
        smartGridEditor,
        handleInit: (state: EditorModel) =>
            dispatch.smartGridEditor.initEditor(state),
        handleChangeElements,
        handleCreateElement,
        handleCreateElementAtCustomPosition,
        handleUpdateElement:
            throttledHandleUpdateBlock.current,
        handleRemoveElement,
        handleMoveElement,
        getControlsList,
        getControlsByType,
        getParent,
        getTemplate,
        getCurrentDataElement,
        getCurrentState,
        potokus,
    };
};

export { useEditor };
export default { useEditor };
