export const GROUP_GRID_NAME = 'GROUP_GRID';
export const GROUP_GRID_LABEL = 'Инструменты сетки';
export const GROUP_CONTENT_NAME = 'GROUP_CONTENT_NAME';
export const GROUP_CONTENT_LABEL = 'Контент блоки';
export const GROUP_INTERACTIVE_NAME =
    'GROUP_INTERCATIVE_NAME';
export const GROUP_INTERACTIVE_LABEL =
    'Интерактивные блоки';

export const GROUP_TEMPLATES_NAME = 'GROUP_TEMPLATE';
export const GROUP_TEMPLATES_LABEL = 'Шаблоны';

export const GROUP_GRID = {
    name: GROUP_GRID_NAME,
    label: GROUP_GRID_LABEL,
};
export const GROUP_CONTENT = {
    name: GROUP_CONTENT_NAME,
    label: GROUP_CONTENT_LABEL,
};
export const GROUP_INTERACTIVE = {
    name: GROUP_INTERACTIVE_NAME,
    label: GROUP_INTERACTIVE_LABEL,
};
export const GROUP_TEMPLATES = {
    name: GROUP_TEMPLATES_NAME,
    label: GROUP_TEMPLATES_LABEL,
};

export const ToolsList = {
    GROUP_GRID,
    GROUP_CONTENT,
    GROUP_INTERACTIVE,
    GROUP_TEMPLATES,
};
