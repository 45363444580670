import React, { FC } from 'react';
import {
    Form,
    Formik,
    useFormikContext,
    FormikHelpers,
} from 'formik';

import * as Yup from 'yup';
import { getOptionsSites } from '@mega/core';
import { GetByIdInfiniteMix, articles } from '@mega/api';

import {
    Button,
    FormLayout,
    InputDateField,
    InputField,
    Paper,
    Row,
    SelectSingleField,
    Stack,
    WithLabel,
} from '@mega/ui';
import { useSiteStore } from '@mega/store';

export interface InfiniteMixEditProps {
    initialValue?: GetByIdInfiniteMix;
    handleUpdate?: (
        payload: GetByIdInfiniteMix,
        actions: FormikHelpers<GetByIdInfiniteMix>,
    ) => void;
}

const InfiniteMixEdit: FC<InfiniteMixEditProps> = ({
    initialValue = {
        site: '',
        active_from: '',
        active_to: '',
        // article: 9999999999,
    },
    handleUpdate = () => null,
}) => {
    const site = useSiteStore();
    return (
        <Formik
            //@ts-ignore
            initialValues={initialValue}
            onSubmit={handleUpdate}
        >
            <Form>
                <FormLayout
                    actions={<StreamCreateActions />}
                >
                    <Paper
                        variant="outline"
                        color="white"
                        classes={{
                            padding: { size: '24x24' },
                        }}
                    >
                        <Stack gap="18">
                            <SelectSingleField
                                name={'article'}
                                placeholder="Статья"
                                handleLoader={async (
                                    inputValue: string,
                                    page: number,
                                ) => {
                                    const responce =
                                        await articles.getOptions?.(
                                            {
                                                search: inputValue,
                                                per_page:
                                                    10 *
                                                    page,
                                                is_active:
                                                    'true',
                                            },
                                            {
                                                headers: {
                                                    'X-Site':
                                                        site?.id ??
                                                        '',
                                                },
                                            },
                                        );
                                    if (responce?.ok) {
                                        return (
                                            responce.data.results?.map(
                                                (item) => ({
                                                    label:
                                                        item?.label ||
                                                        '',
                                                    value:
                                                        item?.id ||
                                                        '',
                                                }),
                                            ) ?? []
                                        );
                                    } else {
                                        return [];
                                    }
                                }}
                                classes={{
                                    paper: {
                                        color: 'dark',
                                    },
                                    select: {
                                        variant:
                                            'secondary',
                                    },
                                }}
                            />
                            <SelectSingleField
                                name={'site'}
                                placeholder="Сайт"
                                handleLoader={getOptionsSites()}
                            />
                            <InputField
                                type="number"
                                name={'order'}
                                placeholder="Порядок"
                                dimension="medium"
                            />
                            <InputField
                                type="number"
                                name={'target_shows'}
                                placeholder="Цель просмотров"
                                dimension="medium"
                            />

                            <Row gap="4">
                                <WithLabel title="Активно с">
                                    <InputDateField name="active_from" />
                                </WithLabel>
                                <WithLabel title="Активно до">
                                    <InputDateField name="active_to" />
                                </WithLabel>
                            </Row>
                        </Stack>
                        <div style={{ height: '500px' }} />
                    </Paper>
                </FormLayout>
            </Form>
        </Formik>
    );
};

const StreamCreateActions = () => {
    const { submitForm, isSubmitting } = useFormikContext();

    return (
        <Paper
            style={{
                borderTop: '1px solid #EAEAEA',
            }}
            fullWidth
            color={'white'}
            variant="filled"
            classes={{
                padding: { size: '24x24' },
            }}
        >
            <Button
                classes={{
                    paper: {
                        variant: 'filled',
                        color: 'dark',
                    },
                }}
                color="secondary"
                size={'large'}
                type={'submit'}
                label={'Обновить'}
                onClick={submitForm}
                disabled={isSubmitting}
            />
        </Paper>
    );
};

export { InfiniteMixEdit };
