import React, {
    createContext,
    FC,
    ReactElement,
    useContext,
} from 'react';

interface LexDataContextProps {
    isLead: boolean;
    id: string;
}

export const LexDataContext =
    createContext<LexDataContextProps | null>(null);

export interface LexDataContextProviderProps
    extends LexDataContextProps {
    children: ReactElement | ReactElement[];
}

const LexDataContextProvider: FC<
    LexDataContextProviderProps
> = ({ children, isLead = false, id }) => (
    <LexDataContext.Provider value={{ isLead, id }}>
        {children}
    </LexDataContext.Provider>
);

const useLexDataContext = () => {
    const context = useContext(LexDataContext);
    return context;
};

export { LexDataContextProvider, useLexDataContext };
