import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { InfiniteMixCreate } from '@mega/forms';
import { CreateInfiniteMixQuery } from '@mega/api';
import {
    useInfiniteMixDispatch,
    useSiteStore,
} from '@mega/store';

export const InfiniteMixNew: FC<{}> = () => {
    const { create } = useInfiniteMixDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const site = useSiteStore();

    const handleCreate = async (
        value: CreateInfiniteMixQuery,
    ) => {
        if (!Boolean(site && site.id)) {
            return enqueueSnackbar(
                'Простите что-то пошло не так, обратитесь пожалуйста в тех поддержку',
                {
                    variant: 'error',
                },
            );
        }

        const response = await create({
            payload: {
                ...value,
                //@ts-ignore
                article: value.article.id,
                //@ts-ignore
                site: value.site?.id || '',
            },
        });

        if (response.isOk) {
            navigate(
                `/${site?.id || ''}/infinitemix/change/`,
                {
                    replace: true,
                },
            );

            enqueueSnackbar('Инфинити-скролл блок создан', {
                variant: 'success',
            });
        } else {
            if (response.value) {
                const errormsgs = Object.values(
                    response.value,
                );

                errormsgs?.map((msg) => {
                    enqueueSnackbar(msg as string, {
                        variant: 'error',
                    });
                });
            }
        }

        return response;
    };

    return (
        <InfiniteMixCreate handleCreate={handleCreate} />
    );
};
