import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';

const useStreamStore = () => {
    const store = useStoreSelector(
        (state: RootState) => state.stream,
    );
    const loading = useStoreSelector(
        (state: RootState) => state.loading.effects.stream,
    );

    return {
        store,
        loading,
    };
};

const useStreamDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        get: dispatch.stream.get,
        put: dispatch.stream.put,
        create: dispatch.stream.create,
        update: dispatch.stream.update,
        remove: dispatch.stream.remove,
    };
};

export { useStreamStore, useStreamDispatch };
