import { Option } from '@mega/ui/src/components/Inputs/types';

export interface IInitValuesForm {
    categories: Option[];
    tags: Option[];
}

export const initValuesForm: IInitValuesForm = {
    categories: [],
    tags: [],
};
