import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgArrowLeft = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 18 18"
        fill="none"
        ref={ref}
        {...props}
    >
        <path
            d="m6.347 8.897 4.5-4.5.806.806L7.96 8.897l3.694 3.694-.806.806-4.5-4.5Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgArrowLeft);
export default ForwardRef;
