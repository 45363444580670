import { createModel } from '@rematch/core';
import * as CLIENT from '@mega/api';
import type {
    Papers,
    PapersQuery,
    PapersWithPagination,
} from '@mega/api';
import { debounce, isUndefined } from 'lodash-es';

import type { RootModel } from '../rootModel';
import { DefaultStore } from '../type';
import { calculatePagesCount } from '@mega/utils';

const urlParams = new URLSearchParams(location.search);

const defaultStore: DefaultStore<Papers, PapersQuery> = {
    currentPage: 1,
    query: {
        per_page: 30,
    },
    meta: {
        count: null,
    },
    hasMore: false,
    pages: {},
};

const papers = createModel<RootModel>()({
    name: 'papers',
    state: defaultStore,
    reducers: {
        changePerPage: (
            state,
            payload: { perPage: number },
        ) => {
            return { ...state, perPage: payload.perPage };
        },
        putCurrentPage: (
            state,
            payload: { triggerPage: number },
        ) => {
            return {
                ...state,
                currentPage: payload.triggerPage,
            };
        },
        putPage: (state, payload: PapersWithPagination) => {
            return {
                ...state,
                meta: {
                    count: isUndefined(payload.count)
                        ? null
                        : payload.count,
                },
                pages: {
                    ...state.pages,
                    [`${state.currentPage}`]:
                        payload.results,
                },
            };
        },
        putQuery: (state, payload: PapersQuery) => {
            return {
                ...state,
                query: { ...state.query, ...payload },
            };
        },
        clear: () => {
            return defaultStore;
        },
    },
    effects: (dispatch) => ({
        /* #region  Search */
        search: async (
            query: Pick<PapersQuery, 'search'>,
        ) => {
            await dispatch.papers.putQuery(query);
            await dispatch.papers.afterSearch(null);
        },
        afterSearch: debounce((_, state) => {
            dispatch.papers.get(state.papers.query);
        }, 1000),
        /* #endregion */
        /* #region  Tags list for Read and pagination */
        deleteById: async (id: string, state) => {
            try {
                const response =
                    await CLIENT.papers.deleteById?.(
                        {
                            id,
                        },
                        {
                            headers: {
                                'X-Site':
                                    state.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    await dispatch.papers.get(
                        state.papers.query,
                    );
                    return {
                        isOk: true,
                    };
                } else {
                    return {
                        isOk: false,
                    };
                }
            } catch (e) {
                return {
                    isOk: false,
                };
            }
        },
        get: async (query: PapersQuery, store) => {
            const { query: storeQuery } = store.papers;
            try {
                const response = await CLIENT.papers.get?.(
                    {
                        ...storeQuery,
                        ...query,
                        is_count: true,
                        page: store.papers.currentPage,
                        // @ts-ignore
                        site: store.site.site?.id || '',
                    },
                    {
                        headers: {
                            'X-Site':
                                store.site.site?.id || '',
                        },
                    },
                );
                if (response?.ok) {
                    const { data } = response;
                    dispatch.papers?.putPage(data);
                }
            } catch (e) {
                if (
                    CLIENT.papers.get &&
                    e instanceof CLIENT.papers.get.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },
        nextPage: async (_: any, store) => {
            const { query, currentPage } = store.papers;

            await dispatch.papers.putCurrentPage({
                triggerPage: currentPage + 1,
            });

            await dispatch.papers.get({
                ...query,
                page: currentPage + 1,
            });
        },
        setCurrentPage: async (
            payload: {
                triggerPage: number;
                action: 'next' | 'prev' | 'body';
            },
            store,
        ) => {
            const { query, currentPage } = store.papers;
            const { triggerPage, action } = payload;

            switch (action) {
                case 'next': {
                    const count =
                        store?.papers?.meta?.count || 0;
                    const perPage =
                        store?.papers?.query?.per_page || 1;
                    const isMaxPage =
                        calculatePagesCount(
                            perPage,
                            count,
                        ) <
                        triggerPage + 1;

                    if (isMaxPage) {
                        break;
                    }
                    dispatch.papers.get({
                        ...query,
                        page: triggerPage + 1,
                    });
                    break;
                }

                case 'body': {
                    if (triggerPage !== currentPage) {
                        dispatch.papers.putCurrentPage({
                            triggerPage,
                        });
                        dispatch.papers.get({
                            ...query,
                            page: triggerPage,
                        });
                    }
                    break;
                }

                case 'prev': {
                    if (triggerPage !== 1) {
                        dispatch.papers.get({
                            ...query,
                            page: triggerPage - 1,
                        });
                    }
                    break;
                }

                default:
                    break;
            }
        },
        jumpTo: async (page: number) => {
            await dispatch.papers.putQuery({ page: page });
            await dispatch.papers.putCurrentPage({
                triggerPage: page,
            });
            dispatch.papers.get({
                page: page,
            });
        },
        chungeCountItemsOnPage: async (
            perPage: number,
            state,
        ) => {
            if (
                Math.floor(
                    state?.papers?.meta.count ??
                        0 / perPage,
                ) > 0
            ) {
                await dispatch.papers.changePerPage({
                    perPage,
                });
                await dispatch.papers.get({});
            }
        },
        /* #endregion */
    }),
});

export { papers };
export default papers;
