import React, { FC, ReactElement } from 'react';
import { groupClasses } from '@mega/styles';

// export interface GroupProps {}

const Group: FC<{
    children: ReactElement[] | ReactElement;
}> = ({ children }) => (
    <div className={groupClasses.base}>{children}</div>
);

export { Group };
