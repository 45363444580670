import {
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useEditor } from '@pages/smartGrid/store/hooks';

export const innerDimensions = (node: any) => {
    const computedStyle = getComputedStyle(node);

    let width = node.clientWidth; // width with padding
    let height = node.clientHeight; // height with padding

    height -=
        parseFloat(computedStyle.paddingTop) +
        parseFloat(computedStyle.paddingBottom);
    width -=
        parseFloat(computedStyle.paddingLeft) +
        parseFloat(computedStyle.paddingRight);
    return { height, width };
};

const GRID_COLUMNS = 12;

const minSize = 1 / GRID_COLUMNS;

const mulByMin = [...new Array(GRID_COLUMNS)].map(
    (item, idx) => minSize * (idx + 1),
);

const findClosest = (arr: number[], num: number) =>
    arr.reduce(
        (closest, n) =>
            Math.abs(n - num) > Math.abs(closest - num)
                ? closest
                : n,
        0,
    );

export const useGridConfig = (
    id: string,
    isVert: boolean,
    initialData: string = '',
) => {
    const { getCurrentDataElement } = useEditor();

    const data = getCurrentDataElement(id);

    const formatData = useMemo(
        () =>
            initialData
                ? initialData
                      .split(' ')
                      .map((item) =>
                          parseFloat(item.slice(0, -2)),
                      )
                : [],
        [],
    );

    const [isEdit, setIsEdit] = useState(false);
    const elParent = useRef<HTMLInputElement>(null);
    const [template, setTemplate] =
        useState<number[]>(formatData);

    useEffect(() => {
        if (isEdit) {
            window.dispatchEvent(new Event('resize'));
        } else {
            clearEvents();
        }
    }, [isEdit]);

    useEffect(() => {
        if (
            (!initialData &&
                data?.childrenElement?.length) ||
            (initialData &&
                template.length !==
                    data?.childrenElement.length)
        ) {
            let fr = data?.childrenElement.map(() =>
                Math.max(
                    minSize,
                    1 / data?.childrenElement.length,
                ),
            );
            setTemplate(fr);
        }
    }, [data?.childrenElement, initialData]);

    const [isResizing, setIsResizing] = useState(false);

    let elPaneCurr: Element | null = null;
    let paneIndex = -1;
    let frStart = 0;
    let frNext = 0;

    function onPointerDown(evt: any) {
        if (
            isResizing ||
            !elParent.current ||
            !evt.target.closest('.gutter')
        )
            return;
        setIsResizing(true);

        const elsPanes = Array.from(
            elParent.current.getElementsByClassName(
                `${id}_part`,
            ),
        );

        elPaneCurr =
            evt.currentTarget.previousElementSibling;

        const fr = [...elsPanes].map((elPane) => {
            return isVert
                ? elPane.clientHeight /
                      // @ts-ignore
                      innerDimensions(elParent.current)
                          .height
                : elPane.clientWidth /
                      // @ts-ignore
                      innerDimensions(elParent.current)
                          .width;
        });

        setTemplate(fr);

        // @ts-ignore
        paneIndex = [...elsPanes].indexOf(elPaneCurr);
        frStart = fr[paneIndex];
        frNext = fr[paneIndex + 1];

        elParent.current.addEventListener(
            'pointermove',
            onPointerMove,
        );

        elParent.current.addEventListener(
            'pointerup',
            onPointerUp,
        );
    }

    const onPointerMove = (evt: any) => {
        if (!elPaneCurr || !elParent) return null;
        evt.preventDefault();
        const paneBCR = elPaneCurr.getBoundingClientRect();

        const parentSize = isVert
            ? // @ts-ignore
              elParent.current.clientHeight
            : // @ts-ignore
              elParent.current.clientWidth;

        const pointer = {
            x: Math.max(
                0,
                Math.min(
                    evt.clientX - paneBCR.left,
                    // @ts-ignore

                    elParent.current.clientWidth,
                ),
            ),
            y: Math.max(
                0,
                Math.min(
                    evt.clientY - paneBCR.top,
                    // @ts-ignore
                    elParent.current.clientHeight,
                ),
            ),
        };

        const frRel =
            pointer[isVert ? 'y' : 'x'] / parentSize;
        const frDiff = frStart - frRel;

        if (
            minSize >= frNext + frDiff ||
            minSize >= frRel
        ) {
            return;
        }
        setTemplate((prev) => {
            const newTemplate = [...prev];
            newTemplate[paneIndex] = Math.max(
                minSize,
                frRel,
            );
            newTemplate[paneIndex + 1] = Math.max(
                minSize,
                frNext + frDiff,
            );

            return newTemplate;
        });
        elPaneCurr.dispatchEvent(new Event('resize'));
    };

    const clearEvents = () => {
        elParent.current?.removeEventListener(
            'pointermove',
            onPointerMove,
        );

        elParent.current?.removeEventListener(
            'pointerup',
            onPointerUp,
        );
    };

    const onPointerUp = (evt: any) => {
        setTemplate((prev) => {
            const newTemplate = [...prev];
            newTemplate[paneIndex] = findClosest(
                mulByMin,
                newTemplate[paneIndex],
            );
            newTemplate[paneIndex + 1] = findClosest(
                mulByMin,
                newTemplate[paneIndex + 1],
            );

            return newTemplate;
        });
        clearEvents();
        setIsResizing(false);
    };

    const config = template
        .map((item) => item + 'fr')
        .join(' ');

    const clientGrid = useMemo(() => {
        return template
            .map(
                (configItem) =>
                    mulByMin.findIndex(
                        (arrItem) =>
                            findClosest(
                                mulByMin,
                                configItem,
                            ) === arrItem,
                    ) + 1,
            )
            .join(' ');
    }, [template]);

    return {
        setIsEdit,
        isEdit,
        elParent,
        config,
        clientGrid,
        onPointerDown,
    };
};
