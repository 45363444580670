import type { PluginTemplate } from '@apostroph/types';
import { definitions } from '@mega/api';

export enum InfoGraphEnum {
    type = 'INFOGRAPH',
}

export interface InfoGraphProps {
    id: string;
    type: string;
    parentId: string | null;
}

export type InfoGraphMeta = definitions['AdminImage'];

export type InfoGraphPlugin = PluginTemplate<
    InfoGraphEnum.type,
    InfoGraphMeta
>;
export type InfoGraphPluginDefault = Omit<
    InfoGraphPlugin,
    'id'
>;
