import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { StreamCreate as StreamCreateForm } from '@mega/forms';
import { CreateStreamQuery } from '@mega/api';
import {
    useStreamDispatch,
    useSiteStore,
} from '@mega/store';

export const StreamNew: FC<{}> = () => {
    const { create } = useStreamDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const location = useLocation();
    const navigate = useNavigate();

    const site = useSiteStore();

    const handleCreate = async (
        value: CreateStreamQuery,
    ) => {
        if (!Boolean(site && site.id)) {
            return enqueueSnackbar(
                'Простите что-то пошло не так, обратитесь пожалуйста в тех поддержку',
                {
                    variant: 'error',
                },
            );
        }

        const response = await create({
            payload: {
                site: site?.id || '',
                title: value.title,
                link: value.link,
            },
        });

        if (response.isOk) {
            navigate(
                `/${site?.id || ''}/mainpagestream/change/${
                    response.value.id
                }`,
                {
                    state: location,
                },
            );

            enqueueSnackbar('Трансляция создана', {
                variant: 'success',
            });
        } else {
            if (response.value) {
                const errormsgs = Object.values(
                    response.value,
                );

                errormsgs?.map((msg) => {
                    enqueueSnackbar(msg as string, {
                        variant: 'error',
                    });
                });
            }
        }

        return response;
    };

    return <StreamCreateForm handleCreate={handleCreate} />;
};
