import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgCode = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="m6 13.463-4.5-4.5 4.537-4.538.807.806-3.732 3.732 3.694 3.693-.806.806Zm5.963.037-.807-.806 3.731-3.732-3.693-3.693.806-.806 4.5 4.5-4.537 4.537Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgCode);
export default ForwardRef;
