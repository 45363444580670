import React from 'react';
import { Control } from '../../../../types/core';
import { StoriesList } from './StoriesList';
import {
    StoriesListEnum,
    StoriesListPluginDefault,
} from './StoriesList.types';
import { PLUGIN_STORIES_NAME } from '../../Content';

const PLUGIN_STORIES_LIST_NAME = StoriesListEnum.type;

const DEFAULT_STORIES_LIST: StoriesListPluginDefault = {
    type: PLUGIN_STORIES_LIST_NAME,
    meta: {},
    isContainer: true,
    acceptedTypes: [PLUGIN_STORIES_NAME],
    childrenElement: [],
};

const PLUGIN_STORIES_LIST = {
    [PLUGIN_STORIES_LIST_NAME]: {
        emptyObject: DEFAULT_STORIES_LIST,
        render: StoriesList,
    },
};

const PLUGIN_STORIES_LIST_CONTROL: Control = {
    name: PLUGIN_STORIES_LIST_NAME,
    label: 'StoriesList',
    tooltip: 'Создать ленту историй',
    Icon: () => <></>,
};

export {
    DEFAULT_STORIES_LIST,
    PLUGIN_STORIES_LIST,
    PLUGIN_STORIES_LIST_CONTROL,
    PLUGIN_STORIES_LIST_NAME,
};
export default PLUGIN_STORIES_LIST;
