import { Form, Formik } from 'formik';
import { FC } from 'react';
import { AdsForm } from './AdsForm/AdsForm';
import {
    IInitValuesForm,
    initValuesForm,
} from './AdsForm/config';
import { validateAds } from './AdsForm/validate';

interface AdsSettingsProps {
    initialValues?: IInitValuesForm;
    onSave: (values: IInitValuesForm) => void;
}

export const AdsSettings: FC<AdsSettingsProps> = ({
    onSave,
    initialValues = initValuesForm,
}) => {
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validateAds}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSave}
        >
            {({ handleSubmit }) => {
                return (
                    <Form>
                        <AdsForm onSubmit={handleSubmit} />
                    </Form>
                );
            }}
        </Formik>
    );
};
