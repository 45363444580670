import React from 'react';
import {
    imgPreview,
    imgPreviewWrapper,
    playButton,
    styledIcon,
} from './VideoPoster.css';
import { PlayCircle } from '@mega/icons';

export interface VideoPosterProps {
    img: string;
    title?: string;
    onClick?: () => void;
    size?: 'small' | 'medium' | 'large';
}

export const VideoPoster: React.FC<VideoPosterProps> = (
    props,
) => {
    const { img, title, onClick, size = 'large' } = props;
    return (
        <div className={imgPreviewWrapper}>
            <img
                className={imgPreview}
                src={img}
                alt={title || ''}
            />
            <div
                className={playButton}
                onClick={onClick || undefined}
            >
                <PlayCircle
                    className={styledIcon({
                        size,
                    })}
                />
            </div>
        </div>
    );
};
