import * as CLIENT from '@mega/api';
import type {
    GetInfiniteMixList,
    GetInfiniteMixQuery,
    GetInfiniteMixError,
    IisOk,
    IisNotOk,
} from '@mega/api';

export type GetInfiniteMixPromise = Promise<
    | IisOk<GetInfiniteMixList>
    | IisNotOk<GetInfiniteMixError | null>
>;

export const getInfiniteMix = async (
    query: GetInfiniteMixQuery,
    site: string,
): GetInfiniteMixPromise => {
    try {
        const response = await CLIENT?.infiniteMix?.get?.(
            query,
            {
                headers: {
                    'X-Site': site,
                },
            },
        );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.streams.get &&
            e instanceof CLIENT.streams.get.Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: null,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};
