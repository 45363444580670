import React, { useCallback, useEffect } from 'react';
import { EditorCore } from '../../types/core';
import { useEditor } from '../../store/hooks';
import { EditorModel } from '../../types/state';
import { DependencyEditorContext } from '@pages/smartGrid/store/context';
import { DndWrapper } from '@pages/smartGrid/components/DndWrapper';

const defaultConfig: EditorModel & EditorCore = {
    items: [],
    data: {},
    groups: {},
    controls: {},
    rootElements: {},
    pluginElements: {},
};

export interface GridInitializerProps {
    dependencyConfig?: Partial<EditorCore>;
    initialState?: EditorModel;
    onChange?: (state: object) => void;
}

export const GridInitializer: React.FC<
    GridInitializerProps
> = (props) => {
    const {
        dependencyConfig,
        initialState,
        onChange = undefined,
    } = props;
    const { handleInit, smartGridEditor } = useEditor();

    // console.log(
    //     smartGridEditor,
    //     'editor at GRID INITIALIZER',
    // );

    const memoizedCallback = useCallback(() => {
        handleInit({
            items: [
                ...defaultConfig.items,
                ...(initialState?.items || []),
            ],
            data: {
                ...defaultConfig.data,
                ...(initialState?.data || {}),
            },
        });
    }, []);

    useEffect(() => {
        memoizedCallback();
    }, [memoizedCallback]);

    useEffect(() => {
        onChange?.(smartGridEditor);
    }, [smartGridEditor]);

    return (
        <DependencyEditorContext
            config={{
                groups: {
                    ...defaultConfig.groups,
                    ...(dependencyConfig?.groups || {}),
                },
                controls: {
                    ...defaultConfig.controls,
                    ...(dependencyConfig?.controls || {}),
                },
                rootElements: {
                    ...defaultConfig.rootElements,
                    ...(dependencyConfig?.rootElements ||
                        {}),
                },
                pluginElements: {
                    ...defaultConfig.pluginElements,
                    ...(dependencyConfig?.pluginElements ||
                        {}),
                },
            }}
        >
            <DndWrapper />
        </DependencyEditorContext>
    );
};
