import React, { CSSProperties, FC, ReactNode } from 'react';

import { stackClasses, StackClasses } from '@mega/styles';

export interface StackProps {
    style?: CSSProperties;
    children: ReactNode;
}

const Stack: FC<StackProps & StackClasses['recipe']> = ({
    style,
    children,
    gap,
}) => (
    <div
        className={stackClasses.recipe({ gap })}
        style={style}
    >
        {children}
    </div>
);

export { Stack };
