import type {
    StreamEntity,
    GetStreams,
    GetStreamsQuery as StreamsQuery,
    GetStreamsError,
} from '@mega/api';
import { isOk } from '@mega/api';
import { createModel } from '@rematch/core';
import { debounce, isUndefined } from 'lodash-es';
import { RootModel } from '../rootModel';
import { DefaultStore, Pages } from '../type';
import { getStreams } from './streams.api';

const urlParams = new URLSearchParams(location.search);

const defaultStore: DefaultStore<
    StreamEntity,
    StreamsQuery
> = {
    currentPage: 1,
    query: {
        per_page: 30,
    },
    meta: {
        count: null,
    },
    hasMore: false,
    pages: {},
};

export const streams = createModel<RootModel>()({
    name: 'streams',
    state: defaultStore,
    reducers: {
        changePerPage: (
            state,
            payload: { perPage: number },
        ) => {
            return {
                ...state,
                perPage: payload.perPage,
            };
        },
        putCurrentPage: (
            state,
            payload: { triggerPage: number },
        ) => {
            return {
                ...state,
                currentPage: payload.triggerPage,
            };
        },
        putPage: (state, payload: GetStreams) => {
            return {
                ...state,
                meta: {
                    count: isUndefined(payload.count)
                        ? null
                        : payload.count,
                },
                pages: {
                    ...state.pages,
                    [`${state.currentPage}`]:
                        payload.results,
                },
            };
        },
        putPages: (state, payload: Pages<StreamEntity>) => {
            return { ...state, pages: payload };
        },
        putQuery: (state, payload: StreamsQuery) => {
            return {
                ...state,
                query: { ...state.query, ...payload },
            };
        },
        clear: () => {
            return defaultStore;
        },
    },
    effects: (dispatch) => ({
        search: async (query: StreamsQuery) => {
            await dispatch.streams.putQuery(query);
            await dispatch.streams.afterSearch(null);
        },
        afterSearch: debounce((_, state) => {
            dispatch.streams.get(state.streams.query);
        }, 1000),
        get: async (query: StreamsQuery, store) => {
            const { query: storeQuery } = store.streams;

            const site = store.site.site?.id || '';
            const response = await getStreams(
                {
                    ...query,
                    ...storeQuery,
                    is_count: true,
                    page: store.streams.currentPage,
                },
                site,
            );
            if (
                isOk<GetStreams, GetStreamsError | null>(
                    response,
                )
            ) {
                dispatch.streams.putPage(response.value);
                return response;
            }
            return response;
        },
        nextPage: async (_: any, store) => {
            const { query, currentPage } = store.streams;
            await dispatch.streams.putCurrentPage({
                triggerPage: currentPage + 1,
            });
            await dispatch.streams.get({
                ...query,
                page: currentPage + 1,
            });
        },
        chungeCountItemsOnPage: async (
            perPage: number,
            state,
        ) => {
            if (
                Math.floor(
                    state?.streams?.meta.count ??
                        0 / perPage,
                ) > 0
            ) {
                await dispatch.streams.changePerPage({
                    perPage,
                });
                await dispatch.streams.get({});
            }
        },
        jumpTo: async (page: number) => {
            await dispatch.streams.putQuery({ page: page });
            await dispatch.streams.putCurrentPage({
                triggerPage: page,
            });
            dispatch.streams.get({
                page: page,
            });
        },
    }),
});
