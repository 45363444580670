import type { PluginTemplate } from '../../../../types/blocks';

export enum PotokusEnum {
    type = 'POTOKUS',
}

export interface PotokusProps {
    id: string;
    type: string;
    parentId: string | null;
}

// biome-ignore lint/complexity/noBannedTypes: <explanation>
export type PotokusMeta = {};

export type PotokusPlugin = PluginTemplate<
    PotokusEnum.type,
    PotokusMeta
>;
export type PotokusPluginDefault = Omit<
    PotokusPlugin,
    'id'
>;
