import { ReactNode } from 'react';
import * as classes from './CardContent.css';

export function CardContent({
    children,
}: {
    children: ReactNode | ReactNode;
}) {
    return (
        <div className={classes.cardContent}>
            {children}
        </div>
    );
}
