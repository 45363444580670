import React, { FC, ReactElement } from 'react';
import { useMatch } from 'react-router-dom';

interface DashboardProps {
    children?: ReactElement | ReactElement[];
}

const Dashboard: FC<DashboardProps> = ({ children }) => {
    const matchDashboard = useMatch('/dashboard');
    const isDashboard = Boolean(matchDashboard);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '30px',
            }}
        >
            <span>Dashboaasrd</span>
            <div>{children}</div>
        </div>
    );
};

export { Dashboard };
