import React, { useState } from 'react';
import { definitions, StoryCard } from '@mega/api';
import {
    Icon,
    InputTextarea,
    Radio,
    Typography,
} from '@mega/ui';
import {
    colorBlock,
    content,
    customTextarea,
    helper,
    info,
    infoItem,
    loader,
    radio,
    titleSection,
} from './StoryImg.css';
import { Image as ImageIcon } from '@mega/icons';
import { ImgLoader } from '@mega/core';

export interface StoryImgProps {
    card?: StoryCard;
    handleChange: (field: string, value: any) => void;
}

const LIMIT_TITLE = 50;
const LIMIT_SUBTITLE = 70;
const LIMIT_BUTTON = 20;

const DEFAULT_COLORS = [
    {
        label: (
            <div
                style={{
                    width: '24px',
                    height: '24px',
                    borderRadius: '4px',
                    background: '#282828',
                }}
            />
        ),
        value: 'black',
    },
    {
        label: (
            <div
                style={{
                    width: '24px',
                    height: '24px',
                    borderRadius: '4px',
                    background: '#EAEAEA',
                }}
            />
        ),
        value: 'white',
    },
];

function isValidHttpUrl(string: string) {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return (
        url.protocol === 'http:' ||
        url.protocol === 'https:'
    );
}

const BUTTON_COLORS = [
    ...DEFAULT_COLORS,
    {
        label: (
            <div
                style={{
                    width: '24px',
                    height: '24px',
                    borderRadius: '4px',
                    background: '#FF4F47',
                }}
            />
        ),
        value: 'red',
    },
    {
        label: (
            <div
                style={{
                    width: '24px',
                    height: '24px',
                    borderRadius: '4px',
                    background: '#0086E8',
                }}
            />
        ),
        value: 'blue',
    },
];
export const StoryImg: React.FC<StoryImgProps> = (
    props,
) => {
    const { card, handleChange } = props;
    const [image, setImage] = useState<
        definitions['AdminImage'] | null
    >(card?.image || null);
    const handleCoverUpdate = (
        res: definitions['AdminImage'],
    ) => {
        setImage(res);
        handleChange('image', res);
    };

    const handleDelete = (id?: number) => {
        if (id) {
            setImage(null);
            handleChange('image', null);
        }
        return id;
    };

    if (!card) return null;

    return (
        <div>
            <div className={titleSection}>
                <Icon size="14">
                    <ImageIcon />
                </Icon>
                <Typography weight={'medium'} size={'14'}>
                    Изображение
                </Typography>
            </div>
            <div className={content}>
                <div className={loader}>
                    <ImgLoader
                        handleLoad={handleCoverUpdate}
                        handleDelete={handleDelete}
                        image={image}
                        type={'stories'}
                        id={image?.id}
                        noData
                        hasCrop
                        fileSrc={image?.image}
                        maxFileSizeMb={100}
                        accept={{
                            'image/jpeg': [
                                '.jpeg',
                                '.png',
                                '.gif',
                                '.webp',
                            ],
                        }}
                    />
                    <span className={helper}>
                        Не более 100 Мб, разрешение
                        1080x1920 пикселей
                    </span>
                </div>

                <div className={info}>
                    <div className={infoItem}>
                        <InputTextarea
                            classes={{
                                paper: {
                                    color: 'white',
                                    variant:
                                        'outlineFilled',
                                    withFocus: true,
                                },
                            }}
                            name="title"
                            value={card.title}
                            placeholder="Заголовок"
                            dimension="small"
                            color="transparent"
                            onChange={(value) =>
                                handleChange('title', value)
                            }
                            limit={LIMIT_TITLE}
                        />
                        <div className={colorBlock}>
                            <p>Цвет текста:</p>
                            <Radio
                                value={
                                    card.button_text_color
                                }
                                name={`button_text_color_${card.id}`}
                                onChange={(value) =>
                                    handleChange(
                                        'button_text_color',
                                        value.value as string,
                                    )
                                }
                                data={DEFAULT_COLORS}
                                className={radio}
                            />
                        </div>
                    </div>
                    <div className={infoItem}>
                        <InputTextarea
                            classes={{
                                paper: {
                                    color: 'white',
                                    variant:
                                        'outlineFilled',
                                    withFocus: true,
                                },
                            }}
                            name="description"
                            value={card.description}
                            placeholder="Подзаголовок"
                            dimension="small"
                            color="transparent"
                            onChange={(value) =>
                                handleChange(
                                    'description',
                                    value,
                                )
                            }
                            limit={LIMIT_SUBTITLE}
                        />
                        <div className={colorBlock}></div>
                    </div>
                    <div className={infoItem}>
                        <div className={customTextarea}>
                            <InputTextarea
                                classes={{
                                    paper: {
                                        color: 'white',
                                        variant:
                                            'outlineFilled',
                                        withFocus: true,
                                    },
                                }}
                                name="button_text"
                                value={card.button_text}
                                placeholder="Кнопка"
                                dimension="small"
                                color="transparent"
                                onChange={(value) =>
                                    handleChange(
                                        'button_text',
                                        value,
                                    )
                                }
                                limit={LIMIT_BUTTON}
                            />
                        </div>

                        <div className={colorBlock}>
                            <p>Цвет кнопки:</p>
                            <Radio
                                value={card.button_color}
                                name={`button_color`}
                                onChange={(value) => {
                                    handleChange(
                                        'button_color',
                                        value.value as string,
                                    );
                                }}
                                data={BUTTON_COLORS}
                                className={radio}
                            />
                        </div>
                    </div>

                    <div className={infoItem}>
                        <div>
                            <InputTextarea
                                name="url"
                                classes={{
                                    paper: {
                                        color: 'white',
                                        variant:
                                            'outlineFilled',
                                        withFocus: true,
                                    },
                                }}
                                dimension="small"
                                value={card.link}
                                placeholder="Ссылка"
                                onChange={(value) =>
                                    handleChange(
                                        'link',
                                        value,
                                    )
                                }
                            />
                            {!isValidHttpUrl(
                                card?.link || '',
                            ) && (
                                <span
                                    style={{
                                        fontSize: '10px',
                                        color: 'red',
                                        fontFamily:
                                            'roboto',
                                    }}
                                >
                                    Эта строка должна быть
                                    ссылкой
                                </span>
                            )}
                        </div>
                        <div className={colorBlock}></div>
                    </div>
                </div>
            </div>
        </div>
    );
};
