import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgCopy = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <g
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#878787"
        >
            <path d="M7.963 2.833h2.074c.911 0 1.646 0 2.224.078.6.08 1.106.253 1.507.654.401.402.574.907.654 1.507.078.578.078 1.313.078 2.224v3.407c0 .912 0 1.647-.078 2.225-.08.6-.253 1.105-.654 1.506-.402.401-.907.574-1.507.655-.578.077-1.313.077-2.224.077H7.963c-.911 0-1.646 0-2.224-.077-.6-.081-1.105-.254-1.507-.655-.401-.401-.574-.906-.654-1.506-.078-.579-.078-1.313-.078-2.225V7.296c0-.911 0-1.646.078-2.224.08-.6.253-1.105.654-1.507.402-.401.907-.574 1.507-.654.578-.078 1.313-.078 2.224-.078Zm-2.09 1.069c-.49.066-.75.186-.934.37-.184.185-.304.444-.37.933C4.5 5.71 4.5 6.376 4.5 7.333v3.333c0 .957.001 1.625.069 2.128.066.49.186.748.37.933.185.184.444.305.933.37.504.068 1.171.07 2.128.07h2c.957 0 1.624-.002 2.128-.07.49-.065.748-.185.933-.37.184-.184.305-.444.37-.933.068-.503.069-1.17.069-2.128V7.333c0-.957-.001-1.624-.069-2.128-.065-.49-.186-.748-.37-.933-.185-.184-.444-.304-.933-.37-.504-.068-1.171-.069-2.128-.069H8c-.957 0-1.624.001-2.128.069Z" />
            <path d="M7.296.833H10a2.5 2.5 0 0 1 2.5 2.5h-1a1.5 1.5 0 0 0-1.5-1.5H7.333c-1.27 0-2.174.001-2.86.093-.67.09-1.056.26-1.338.542-.283.282-.452.668-.542 1.339-.092.685-.093 1.588-.093 2.86v4a1.5 1.5 0 0 0 1.5 1.5v1a2.5 2.5 0 0 1-2.5-2.5V6.628c0-1.225 0-2.196.102-2.955.105-.782.327-1.415.826-1.913.498-.5 1.131-.72 1.913-.826C5.1.833 6.07.833 7.296.833Z" />
        </g>
    </svg>
);
const ForwardRef = forwardRef(SvgCopy);
export default ForwardRef;
