import React, { FC } from 'react';
import { Typography } from '@mega/ui';

type NoResultsFoundMessageProps = {
    isPageEmpty: boolean;
};

const NoResultsFoundMessage: FC<
    NoResultsFoundMessageProps
> = ({ isPageEmpty }) => {
    return (
        <>
            {isPageEmpty ? (
                <Typography
                    size="18"
                    weight="bold"
                    style={{ paddingTop: '20px' }}
                >
                    По вашему запросу ничего не найдено
                </Typography>
            ) : (
                <></>
            )}
        </>
    );
};

export { NoResultsFoundMessage };
export default NoResultsFoundMessage;
