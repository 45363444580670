import { createModel } from '@rematch/core';
import * as CLIENT from '@mega/api';
import type {
    Copyrights,
    CopyrightsQuery,
    CopyrightsWithPagination,
} from '@mega/api';
import { debounce, isUndefined } from 'lodash-es';
import { DefaultStore } from '../type';
import type { RootModel } from '../rootModel';
import { parseQueryString } from '../transformers';
import { calculatePagesCount } from '@mega/utils';

const urlParams = new URLSearchParams(location.search);

const defaultStore: DefaultStore<
    Copyrights,
    CopyrightsQuery
> = {
    currentPage: 1,
    query: {
        per_page: 30,
    },
    meta: {
        count: null,
    },
    hasMore: false,
    pages: {},
};

const copyrights = createModel<RootModel>()({
    name: 'copyrights',
    state: defaultStore,
    reducers: {
        changePerPage: (
            state,
            payload: { perPage: number },
        ) => {
            return { ...state, perPage: payload.perPage };
        },
        putCurrentPage: (
            state,
            payload: { triggerPage: number },
        ) => {
            return {
                ...state,
                currentPage: payload.triggerPage,
            };
        },
        putPage: (
            state,
            payload: CopyrightsWithPagination,
        ) => {
            return {
                ...state,
                meta: {
                    count: isUndefined(payload.count)
                        ? null
                        : payload.count,
                },
                pages: {
                    ...state.pages,
                    [`${state.currentPage}`]:
                        payload.results,
                },
            };
        },
        putQuery: (state, payload: CopyrightsQuery) => {
            return {
                ...state,
                query: { ...state.query, ...payload },
            };
        },
        clear: () => {
            return defaultStore;
        },
    },
    effects: (dispatch) => ({
        /* #region  Search */
        search: async (
            query: Pick<CopyrightsQuery, 'search'>,
        ) => {
            await dispatch.copyrights.putQuery(query);
            await dispatch.copyrights.afterSearch('');
        },
        afterSearch: debounce((_, state) => {
            dispatch.copyrights.get(state.copyright.query);
        }, 1000),
        /* #endregion */
        /* #region  Copyrights list for Read and pagination */
        get: async (query: CopyrightsQuery, store) => {
            const { query: storeQuery } = store.copyrights;

            try {
                const response =
                    await CLIENT.copyrights.get?.(
                        {
                            ...storeQuery,
                            ...query,
                            is_count: true,
                            page: store.copyrights
                                .currentPage,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    const { data } = response;
                    dispatch.copyrights?.putPage(data);
                }
            } catch (e) {
                if (
                    CLIENT.copyrights.get &&
                    e instanceof CLIENT.copyrights.get.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },
        nextPage: async (_: null, store) => {
            const { query, currentPage } = store.copyrights;

            await dispatch.copyrights.putCurrentPage({
                triggerPage: currentPage + 1,
            });

            await dispatch.copyrights.get({
                ...query,
                page: currentPage + 1,
            });
        },
        setCurrentPage: async (
            payload: {
                triggerPage: number;
                action: 'next' | 'prev' | 'body';
            },
            store,
        ) => {
            const { query, currentPage } = store.copyrights;
            const { triggerPage, action } = payload;

            switch (action) {
                case 'next': {
                    const count =
                        store?.copyrights?.meta?.count || 0;
                    const perPage =
                        store?.copyrights?.query
                            ?.per_page || 1;
                    const isMaxPage =
                        calculatePagesCount(
                            perPage,
                            count,
                        ) <
                        triggerPage + 1;

                    if (isMaxPage) {
                        break;
                    }
                    dispatch.copyrights.get({
                        ...query,
                        page: triggerPage + 1,
                    });
                    break;
                }

                case 'body': {
                    if (triggerPage !== currentPage) {
                        dispatch.copyrights.putCurrentPage({
                            triggerPage,
                        });
                        dispatch.copyrights.get({
                            ...query,
                            page: triggerPage,
                        });
                    }
                    break;
                }

                case 'prev': {
                    if (triggerPage !== 1) {
                        dispatch.copyrights.get({
                            ...query,
                            page: triggerPage - 1,
                        });
                    }
                    break;
                }

                default:
                    break;
            }
        },
        jumpTo: async (page: number) => {
            await dispatch.copyrights.putQuery({
                page: page,
            });
            await dispatch.copyrights.putCurrentPage({
                triggerPage: page,
            });
            dispatch.copyrights.get({
                page: page,
            });
        },
        chungeCountItemsOnPage: async (
            perPage: number,
            state,
        ) => {
            if (
                Math.floor(
                    state?.copyrights?.meta.count ??
                        0 / perPage,
                ) > 0
            ) {
                await dispatch.copyrights.changePerPage({
                    perPage,
                });
                await dispatch.copyrights.get({});
            }
        },
        /* #endregion */
    }),
});

export { copyrights };
export default copyrights;
