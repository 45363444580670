import { createModel } from '@rematch/core';
import * as CLIENT from '@mega/api';
import type {
    Tags,
    TagsQuery,
    TagsWithPagination,
} from '@mega/api';
import { debounce, isUndefined } from 'lodash-es';

import type { RootModel } from '../rootModel';
import { DefaultStore } from '../type';
import { parseQueryString } from '../transformers';
import { calculatePagesCount } from '@mega/utils';

const urlParams = new URLSearchParams(location.search);

const defaultStore: DefaultStore<Tags, TagsQuery> = {
    currentPage: 1,
    query: {
        per_page: 30,
    },
    meta: {
        count: null,
    },
    hasMore: false,
    pages: {},
};

const tags = createModel<RootModel>()({
    name: 'tags',
    state: defaultStore,
    reducers: {
        changePerPage: (
            state,
            payload: { perPage: number },
        ) => {
            return { ...state, perPage: payload.perPage };
        },
        putCurrentPage: (
            state,
            payload: { triggerPage: number },
        ) => {
            return {
                ...state,
                currentPage: payload.triggerPage,
            };
        },
        putPage: (state, payload: TagsWithPagination) => {
            return {
                ...state,
                meta: {
                    count: isUndefined(payload.count)
                        ? null
                        : payload.count,
                },
                pages: {
                    ...state.pages,
                    [`${state.currentPage}`]:
                        payload.results,
                },
            };
        },
        putQuery: (state, payload: TagsQuery) => {
            return {
                ...state,
                query: { ...state.query, ...payload },
            };
        },
        clear: () => {
            return defaultStore;
        },
    },
    effects: (dispatch) => ({
        /* #region  Search */
        search: async (
            query: Pick<TagsQuery, 'search'>,
        ) => {
            await dispatch.tags.putQuery(query);
            await dispatch.tags.afterSearch(null);
        },
        afterSearch: debounce((_, state) => {
            dispatch.tags.get(state.tags.query);
        }, 1000),
        /* #endregion */
        /* #region  Tags list for Read and pagination */
        get: async (query: TagsQuery, store) => {
            const { query: storeQuery } = store.tags;

            try {
                const response = await CLIENT.tags.get?.(
                    {
                        ...storeQuery,
                        ...query,
                        is_count: true,
                        page: store.tags.currentPage,
                        ordering: '-created_at',
                        //@ts-ignore
                        site: store.site.site?.id || '',
                    },
                    {
                        headers: {
                            'X-Site':
                                store.site.site?.id || '',
                        },
                    },
                );
                if (response?.ok) {
                    const { data } = response;
                    dispatch.tags?.putPage(data);
                }
            } catch (e) {
                if (
                    CLIENT.tags.get &&
                    e instanceof CLIENT.tags.get.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },
        nextPage: async (_: any, store) => {
            const { query, currentPage } = store.tags;

            await dispatch.tags.putCurrentPage({
                triggerPage: currentPage + 1,
            });

            await dispatch.tags.get({
                ...query,
                page: currentPage + 1,
            });
        },

        setCurrentPage: async (
            payload: {
                triggerPage: number;
                action: 'next' | 'prev' | 'body';
            },
            store,
        ) => {
            const { query, currentPage } = store.tags;
            const { triggerPage, action } = payload;

            switch (action) {
                case 'next': {
                    const count =
                        store?.tags?.meta?.count || 0;
                    const perPage =
                        store?.tags?.query?.per_page || 1;
                    const isMaxPage =
                        calculatePagesCount(
                            perPage,
                            count,
                        ) <
                        triggerPage + 1;

                    if (isMaxPage) {
                        break;
                    }
                    dispatch.tags.get({
                        ...query,
                        page: triggerPage + 1,
                    });
                    break;
                }

                case 'body': {
                    if (triggerPage !== currentPage) {
                        dispatch.tags.putCurrentPage({
                            triggerPage,
                        });
                        dispatch.tags.get({
                            ...query,
                            page: triggerPage,
                        });
                    }
                    break;
                }

                case 'prev': {
                    if (triggerPage !== 1) {
                        dispatch.tags.get({
                            ...query,
                            page: triggerPage - 1,
                        });
                    }
                    break;
                }

                default:
                    break;
            }
        },
        jumpTo: async (page: number) => {
            await dispatch.tags.putQuery({ page: page });
            await dispatch.tags.putCurrentPage({
                triggerPage: page,
            });
            dispatch.tags.get({
                page: page,
            });
        },
        chungeCountItemsOnPage: async (
            perPage: number,
            state,
        ) => {
            if (
                Math.floor(
                    state?.tags?.meta.count ?? 0 / perPage,
                ) > 0
            ) {
                await dispatch.tags.changePerPage({
                    perPage,
                });
                await dispatch.tags.get({});
            }
        },
        /* #endregion */
    }),
});

export { tags };
export default tags;
