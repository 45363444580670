import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';

const useApostrophStore = () => {
    const { isFormatted, isSearchedInAgent } =
        useStoreSelector(
            (state: RootState) => state.apostroph,
        );

    return {
        isFormatted,
        isSearchedInAgent,
    };
};

const useApostrophDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        setIsFormatted: dispatch.apostroph.setIsFormatted,
        setIsSearchedInAgent:
            dispatch.apostroph.setIsSearchedInAgent,
    };
};

export { useApostrophStore, useApostrophDispatch };
