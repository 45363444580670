import React from 'react';
import { Icon, Typography } from '@mega/ui';
import {
    helper,
    loader,
    titleSection,
} from './StoryVideo.css';
import { FileViewer } from '../../../FileViewer';
import { DndFileLoader } from '../../../DndFileLoader';
import { definitions } from '@mega/api';
import { Videocam as VideocamIcon } from '@mega/icons';
import { useVideoDispatch } from '@mega/store';

export interface StoryVideoProps {
    video?: definitions['AdminStoryCardVideo'];
    handleChange: (field: string, value: any) => void;
}

export const StoryVideo: React.FC<StoryVideoProps> = ({
    video,
    handleChange,
}) => {
    const { create, remove } = useVideoDispatch();

    const handleCoverUpdate = (res: any) => {
        handleChange('video', res);
    };

    const handleCreate = async (data: any) => {
        const newData = {
            video: data,
            title: data.name,
            description: data.name,
        };
        const formData = new FormData();
        for (const [key, value] of Object.entries(
            newData,
        )) {
            // @ts-ignore
            formData.append(key, value);
        }
        const res = await create({
            payload: { data: formData, type: 'stories' },
            options: {},
        });
        // @ts-ignore
        handleCoverUpdate(res.data);
    };

    const handleDelete = async (id?: number) => {
        if (id) {
            handleCoverUpdate(null);
            await remove({ id, type: 'stories' });
        }
        return id;
    };

    return (
        <div>
            <div className={titleSection}>
                <Icon size="14">
                    <VideocamIcon />
                </Icon>
                <Typography weight={'medium'} size={'14'}>
                    Видео
                </Typography>
            </div>
            <div className={loader}>
                <DndFileLoader
                    onUpload={handleCreate}
                    fileType={'video'}
                    onDelete={handleDelete}
                    id={video?.id}
                    maxFileSizeMb={100}
                    accept={{
                        'video/mp4': ['.mp4'],
                    }}
                >
                    <FileViewer
                        fileSrc={video?.video}
                        isVideo
                        videoPoster={''}
                    />
                </DndFileLoader>
            </div>
            <span className={helper}>
                Не более 100 Мб, не более 120 секунд,
                разрешение 1080x1920 пикселей
            </span>
        </div>
    );
};
