import { Typography } from '@mega/ui';
import React from 'react';
import {
    activeFilter,
    activeFilterText,
    activeFilterIcon,
} from '../ArticlesFilters.css';
import { motion } from 'framer-motion';
import { MdClose } from 'react-icons/md';

export enum FilterVariant {
    category = 'category',
    tag = 'tag',
    author = 'author',
    owner = 'owner',
    base = 'base',
}

export const ActiveFilter: React.FC<{
    text: string;
    onDelete: () => void;
    variant?: FilterVariant;
}> = ({ text, onDelete, variant = 'base' }) => {
    return (
        <div
            className={activeFilter({
                [`${variant}`]: true,
            })}
        >
            <Typography className={activeFilterText}>
                {text}
            </Typography>
            <motion.div
                onClick={onDelete}
                className={activeFilterIcon}
                whileHover={{
                    scale: 1.1,
                    transition: {
                        duration: 0.2,
                    },
                }}
            >
                <MdClose />
            </motion.div>
        </div>
    );
};
