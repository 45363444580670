import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgSettings = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M7.275 16.5 6.9 14.137a5.025 5.025 0 0 1-.75-.356 5.144 5.144 0 0 1-.694-.469l-2.212 1.013L1.5 11.25l2.025-1.481a2.23 2.23 0 0 1-.047-.385 8.949 8.949 0 0 1 0-.768 2.23 2.23 0 0 1 .047-.385L1.5 6.75l1.744-3.075 2.212 1.013c.2-.163.431-.32.694-.47a3.88 3.88 0 0 1 .75-.337L7.275 1.5h3.45l.375 2.362c.238.088.49.204.76.347.268.144.496.304.684.479l2.212-1.013L16.5 6.75l-2.025 1.444c.025.125.04.26.047.403a9.39 9.39 0 0 1 0 .797 2.617 2.617 0 0 1-.047.393L16.5 11.25l-1.744 3.075-2.212-1.012c-.2.162-.428.321-.685.478-.256.156-.509.271-.759.346l-.375 2.363h-3.45ZM9 11.437a2.35 2.35 0 0 0 1.725-.712A2.35 2.35 0 0 0 11.438 9a2.35 2.35 0 0 0-.713-1.725A2.35 2.35 0 0 0 9 6.562a2.35 2.35 0 0 0-1.725.713A2.35 2.35 0 0 0 6.562 9c0 .675.238 1.25.713 1.725A2.35 2.35 0 0 0 9 11.438Zm0-1.124c-.363 0-.672-.129-.928-.385A1.265 1.265 0 0 1 7.688 9c0-.363.128-.672.384-.928s.566-.384.928-.384c.363 0 .672.128.928.384s.384.566.384.928c0 .363-.128.672-.384.928a1.265 1.265 0 0 1-.928.384Zm-.825 5.062h1.65l.263-2.1c.412-.1.803-.256 1.171-.469a4.807 4.807 0 0 0 1.003-.768l1.988.862.75-1.35-1.762-1.294c.05-.212.09-.422.121-.628.032-.206.047-.415.047-.628 0-.213-.012-.422-.037-.628a3.88 3.88 0 0 0-.131-.628L15 6.45l-.75-1.35-1.988.863a4.18 4.18 0 0 0-.975-.816 3.162 3.162 0 0 0-1.2-.422l-.262-2.1h-1.65l-.263 2.1a3.973 3.973 0 0 0-1.19.45 4.135 4.135 0 0 0-.985.788L3.75 5.1 3 6.45l1.763 1.294c-.05.212-.091.422-.122.628a4.188 4.188 0 0 0 0 1.256c.03.206.072.416.122.628L3 11.55l.75 1.35 1.987-.863c.3.3.635.557 1.004.77.368.212.759.368 1.171.468l.263 2.1Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgSettings);
export default ForwardRef;
