import { RootState } from '../../models';
import { createSelector } from 'reselect';

const loadingMediashareAuth = (state: RootState) =>
    state.loading.effects.mediashareAuth;

export const selectMediashareAuthLoading = createSelector(
    loadingMediashareAuth,
    (loading) => loading,
);
