import React, { FC, useEffect, useState } from 'react';
import {
    HeadTemplatePlugin,
    TemplatePlugin,
} from '@mega/editor';
import { useEditor } from '@apostroph/store';
import {
    BlockquoteMeta,
    BlockquoteProps,
} from './Blockquote.types';
import { Formik } from 'formik';
import { QuoteForm } from './QuoteForm';
import { Icon, Typography } from '@mega/ui';
import { FormatQuote } from '@mega/icons';

const initialValues: BlockquoteMeta = {
    text: '',
    regalia: '',
    owner: '',
};
const Blockquote: FC<BlockquoteProps> = ({
    id,
    parentId,
    type,
}) => {
    const { handleUpdateElement, getCurrentDataElement } =
        useEditor();
    const { meta, childrenElement } =
        getCurrentDataElement<BlockquoteMeta>(id);
    const [state, setState] =
        useState<BlockquoteMeta>(meta);
    const handleUpdate = (data: BlockquoteMeta) => {
        setState(data);
    };

    useEffect(() => {
        handleUpdateElement({
            type,
            id,
            meta: state,
            childrenElement,
        });
    }, [state]);

    useEffect(() => {
        setState(meta);
    }, [meta]);

    return (
        <Formik
            initialValues={state || initialValues}
            enableReinitialize
            onSubmit={() => undefined}
        >
            {() => {
                return (
                    <TemplatePlugin
                        head={
                            <HeadTemplatePlugin
                                id={id}
                                parentId={parentId}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection:
                                            'row',
                                        justifyContent:
                                            'center',
                                        alignItems:
                                            'center',
                                        gap: '4px',
                                    }}
                                >
                                    <Icon size="16">
                                        <FormatQuote />
                                    </Icon>
                                    <Typography size={'12'}>
                                        Цитата
                                    </Typography>
                                </div>
                            </HeadTemplatePlugin>
                        }
                    >
                        <QuoteForm
                            handleUpdate={handleUpdate}
                        />
                    </TemplatePlugin>
                );
            }}
        </Formik>
    );
};

export { Blockquote };
