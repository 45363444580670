import { createModel } from '@rematch/core';
import * as CLIENT from '@mega/api';
import type {
    SourcesReports,
    SourcesReportsQuery,
    SourcesReportsWithPagination,
} from '@mega/api';
import { debounce, isUndefined } from 'lodash-es';

import type { RootModel } from '../rootModel';
import { DefaultStore } from '../type';
import { parseQueryString } from '../transformers';
import { calculatePagesCount } from '@mega/utils';

const urlParams = new URLSearchParams(location.search);

const defaultStore: DefaultStore<
    SourcesReports,
    SourcesReportsQuery
> = {
    currentPage: 1,
    query: {
        per_page: 30,
    },
    meta: {
        count: null,
    },
    hasMore: false,
    pages: {},
};

const sourcesReports = createModel<RootModel>()({
    name: 'sourcesReports',
    state: defaultStore,
    reducers: {
        changePerPage: (
            state,
            payload: { perPage: number },
        ) => {
            return { ...state, perPage: payload.perPage };
        },
        putCurrentPage: (
            state,
            payload: { triggerPage: number },
        ) => {
            return {
                ...state,
                currentPage: payload.triggerPage,
            };
        },
        putPage: (
            state,
            payload: SourcesReportsWithPagination,
        ) => {
            return {
                ...state,
                meta: {
                    count: isUndefined(payload.count)
                        ? null
                        : payload.count,
                },
                pages: {
                    ...state.pages,
                    [`${state.currentPage}`]:
                        payload.results,
                },
            };
        },
        putQuery: (state, payload: SourcesReportsQuery) => {
            return {
                ...state,
                query: { ...state.query, ...payload },
            };
        },
        clear: () => {
            return defaultStore;
        },
    },
    effects: (dispatch) => ({
        /* #region  Search */
        search: async (
            query: Pick<SourcesReportsQuery, 'search'>,
        ) => {
            await dispatch.sourcesReports.putQuery(query);
            await dispatch.sourcesReports.afterSearch(null);
        },

        afterSearch: debounce((_, state) => {
            dispatch.sourcesReports.get(
                state.sourcesReports.query,
            );
        }, 1000),

        /* #endregion */
        /* #region  SourcesReports list for Read and pagination */
        get: async (query: SourcesReportsQuery, store) => {
            const { query: storeQuery } =
                store.sourcesReports;

            try {
                const response =
                    await CLIENT.reports.getSource?.({
                        ...storeQuery,
                        ...query,
                        is_count: true,
                        page: store.sourcesReports
                            .currentPage,
                    });
                if (response?.ok) {
                    const { data } = response;
                    dispatch.sourcesReports?.putPage(data);
                }
            } catch (e) {
                if (
                    CLIENT.reports.getSource &&
                    e instanceof
                        CLIENT.reports.getSource.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },
        nextPage: async (_: any, store) => {
            const { query, currentPage } =
                store.sourcesReports;

            await dispatch.sourcesReports.putCurrentPage({
                triggerPage: currentPage + 1,
            });

            await dispatch.sourcesReports.get({
                ...query,
                page: currentPage + 1,
            });
        },
        setCurrentPage: async (
            payload: {
                triggerPage: number;
                action: 'next' | 'prev' | 'body';
            },
            store,
        ) => {
            const { query, currentPage } =
                store.sourcesReports;
            const { triggerPage, action } = payload;

            switch (action) {
                case 'next': {
                    const count =
                        store?.sourcesReports?.meta
                            ?.count || 0;
                    const perPage =
                        store?.sourcesReports?.query
                            ?.per_page || 1;
                    const isMaxPage =
                        calculatePagesCount(
                            perPage,
                            count,
                        ) <
                        triggerPage + 1;

                    if (isMaxPage) {
                        break;
                    }
                    dispatch.sourcesReports.get({
                        ...query,
                        page: triggerPage + 1,
                    });
                    break;
                }

                case 'body': {
                    if (triggerPage !== currentPage) {
                        dispatch.sourcesReports.putCurrentPage(
                            {
                                triggerPage,
                            },
                        );
                        dispatch.sourcesReports.get({
                            ...query,
                            page: triggerPage,
                        });
                    }
                    break;
                }

                case 'prev': {
                    if (triggerPage !== 1) {
                        dispatch.sourcesReports.get({
                            ...query,
                            page: triggerPage - 1,
                        });
                    }
                    break;
                }

                default:
                    break;
            }
        },
        jumpTo: async (page: number) => {
            await dispatch.sourcesReports.putQuery({
                page: page,
            });
            await dispatch.sourcesReports.putCurrentPage({
                triggerPage: page,
            });
            dispatch.sourcesReports.get({
                page: page,
            });
        },
        chungeCountItemsOnPage: async (
            perPage: number,
            state,
        ) => {
            if (
                Math.floor(
                    state?.sourcesReports?.meta.count ??
                        0 / perPage,
                ) > 0
            ) {
                await dispatch.sourcesReports.changePerPage(
                    {
                        perPage,
                    },
                );
                await dispatch.sourcesReports.get({});
            }
        },
        /* #endregion */
    }),
});

export { sourcesReports };
export default sourcesReports;
