import { useArticlesQuery } from '@mega/store';
import { filtersBody } from '../../ArticlesFilters.css';
import React from 'react';
import {
    ActiveFilter,
    FilterVariant,
} from '../ActiveFilter';
import dayjs from 'dayjs';
import { Options } from '@mega/ui/src/components/Inputs/types';
import { Option } from '../../ArticlesFilters';

interface IFilterDisplayProps {
    currentTags: Options | null;
    currentAuthor: Option | null;
    currentCategory: Option | null;
    owner: Option | null;
    handleSelect: (type: string, e: any) => void;
    handleSelectTag: (
        action: string,
        tags: Options | undefined,
    ) => void;
}

export const FilterDisplay = ({
    currentAuthor,
    currentCategory,
    currentTags,
    owner,
    handleSelect,
    handleSelectTag,
}: IFilterDisplayProps) => {
    const query = useArticlesQuery();

    const {
        is_active,
        pub_date__lte,
        pub_date__gte,
        is_feed_hidden,
        ordering,
        is_gov_contract,
    } = query;

    return (
        <div className={filtersBody}>
            {currentCategory && (
                <ActiveFilter
                    onDelete={() =>
                        handleSelect('category', undefined)
                    }
                    variant={FilterVariant.category}
                    text={currentCategory.label}
                />
            )}

            {currentAuthor && (
                <ActiveFilter
                    variant={FilterVariant.author}
                    onDelete={() =>
                        handleSelect('authors', undefined)
                    }
                    text={`${
                        currentAuthor.label.split(' ')[1]
                    } ${
                        currentAuthor.label.split(' ')[0][0]
                    }.`}
                />
            )}
            {owner && (
                <ActiveFilter
                    variant={FilterVariant.owner}
                    onDelete={() =>
                        handleSelect('owner', undefined)
                    }
                    text={`${owner.label.split(' ')[1]} ${
                        owner.label.split(' ')[0][0]
                    }.`}
                />
            )}
            {currentTags &&
                currentTags.length > 0 &&
                currentTags.map((t) => (
                    <div key={t.value}>
                        <ActiveFilter
                            variant={FilterVariant.tag}
                            onDelete={() =>
                                handleSelectTag('delete', [
                                    t,
                                ])
                            }
                            text={t.label}
                        />
                    </div>
                ))}
            {is_active && (
                <ActiveFilter
                    onDelete={() =>
                        handleSelect('is_active', undefined)
                    }
                    text={
                        is_active === 'true'
                            ? 'Опубликована'
                            : 'Не опубликована'
                    }
                />
            )}
            {is_feed_hidden && (
                <ActiveFilter
                    onDelete={() =>
                        handleSelect(
                            'is_feed_hidden',
                            undefined,
                        )
                    }
                    text={
                        is_feed_hidden === 'true'
                            ? 'Вне ленты'
                            : 'В ленте'
                    }
                />
            )}
            {ordering && (
                <ActiveFilter
                    onDelete={() =>
                        handleSelect('ordering', undefined)
                    }
                    text={
                        ordering === '-created_at'
                            ? 'По дате создания по убыванию'
                            : 'По дате создания по возрастанию'
                    }
                />
            )}
            {pub_date__lte && pub_date__gte && (
                <ActiveFilter
                    onDelete={() => {
                        handleSelect(
                            'pub_date__lte',
                            undefined,
                        );
                        handleSelect(
                            'pub_date__gte',
                            undefined,
                        );
                    }}
                    text={`${dayjs(pub_date__gte).format(
                        'DD.MM.YYYY',
                    )} - ${dayjs(pub_date__lte).format(
                        'DD.MM.YYYY',
                    )}`}
                />
            )}
            {is_gov_contract && (
                <ActiveFilter
                    onDelete={() =>
                        handleSelect(
                            'is_gov_contract',
                            undefined,
                        )
                    }
                    text={
                        is_gov_contract === 'true'
                            ? 'Входит в Госконтракт'
                            : 'Не входит в Госконтракт'
                    }
                />
            )}
        </div>
    );
};
