import React, { useReducer } from 'react';

import { Search } from '@mega/icons';
import { useTagsDispatch, useTagsStore } from '@mega/store';
import { Input } from '@mega/ui';
import { Form, Formik } from 'formik';
import { TagsButtonFilter } from '@mega/core';
import { searchRecipe } from './TagFilters.css';

import {
    useSearchParams,
    URLSearchParamsInit,
} from 'react-router-dom';

import { hasOwnProperty } from '@mega/utils';

export type TagOption = {
    label: string;
    value: string | number | boolean;
};

export type TagFiltersState = {
    is_theme: boolean;
    is_tag: boolean;
};

const initialFilterState: TagFiltersState = {
    is_theme: false,
    is_tag: false,
};

const TagFilters = () => {
    const store = useTagsStore();
    const { search, jumpTo } = useTagsDispatch();
    const [, setSearchParams] = useSearchParams();

    const cb = (
        prevInit: URLSearchParams,
    ): URLSearchParamsInit => {
        prevInit.set('page', '1');
        return prevInit.toString();
    };

    const resetPaginatorToFirstPage = () => {
        setSearchParams(cb);
        jumpTo(1);
    };

    const [filterState, setFilterState] = useReducer(
        (
            filterState: TagFiltersState,
            newFilterState: Partial<TagFiltersState>,
        ): TagFiltersState => ({
            ...filterState,
            ...newFilterState,
        }),
        initialFilterState,
    );

    const handleSelect = (id: string, value: boolean) => {
        if (hasOwnProperty(filterState, id)) {
            const themeType = 'is_theme';
            switch (true) {
                case id === 'is_tag' && value === true:
                    setFilterState({
                        is_theme: false,
                        is_tag: true,
                    });
                    search({
                        [`${themeType}`]: false,
                    });
                    resetPaginatorToFirstPage();
                    break;

                case id === 'is_theme' && value === true:
                    setFilterState({
                        is_theme: true,
                        is_tag: false,
                    });
                    search({
                        [`${themeType}`]: true,
                    });
                    resetPaginatorToFirstPage();
                    break;

                default:
                    (id === 'is_tag' ||
                        id === 'is_theme') &&
                        value === false;
                    setFilterState({
                        is_theme: false,
                        is_tag: false,
                    });
                    search({
                        [`${themeType}`]: null,
                    });
                    resetPaginatorToFirstPage();
                    break;
            }
        }
    };

    return (
        <Formik initialValues={{}} onSubmit={() => {}}>
            <Form
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        gap: '18px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            gap: '9px',
                        }}
                    >
                        <TagsButtonFilter
                            label="Теги"
                            value={filterState.is_tag}
                            id="is_tag"
                            handleSelect={handleSelect}
                            style={{ width: '60px' }}
                        />
                        <TagsButtonFilter
                            label="Темы"
                            value={filterState.is_theme}
                            id="is_theme"
                            handleSelect={handleSelect}
                            style={{ width: '60px' }}
                        />
                    </div>
                    <div
                        className={searchRecipe({
                            hasText: !!store.query?.search,
                        })}
                    >
                        <Input
                            startIcon={<Search />}
                            color="secondary"
                            dimension="small"
                            borderRadius={'small'}
                            placeholderBase={'Поиск'}
                            value={
                                store.query?.search || ''
                            }
                            type="text"
                            onChange={(e) => {
                                resetPaginatorToFirstPage();
                                search({
                                    search: e.target.value,
                                });
                            }}
                        />
                    </div>
                </div>
            </Form>
        </Formik>
    );
};

export { TagFilters };
export default TagFilters;
