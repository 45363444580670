import * as CLIENT from '@mega/api';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Category,
    CategoryCreate,
    CategoryUpdate,
} from '@mega/api';
import { createModel } from '@rematch/core';
import { DispatchEffectWithDependency } from '../type';

import type { RootModel } from '../rootModel';

export interface CategoryCreateEffect
    extends DispatchEffectWithDependency<
        Pick<CategoryCreate, 'title'>
    > {}
export interface CategoryUpdateEffect
    extends DispatchEffectWithDependency<CategoryUpdate> {
    payload: any;
    query: {
        id: number;
    };
}

const category = createModel<RootModel>()({
    name: 'category',
    state: {} as Category,
    reducers: {
        put: (_state, payload: Category) => {
            return { ...payload };
        },
    },
    effects: (dispatch) => ({
        get: async (params: { id: number }, store) => {
            try {
                const response =
                    await CLIENT.categories.getById?.(
                        {
                            ...params,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    dispatch.category.put(response.data);
                }
            } catch (e) {
                if (
                    CLIENT.categories.getById &&
                    e instanceof
                        CLIENT.categories.getById.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },
        create: async (
            { payload, options }: CategoryCreateEffect,
            store,
        ) => {
            try {
                const response =
                    await CLIENT.categories?.create?.({
                        ...payload,
                        site: store.site.site?.id || '',
                    });
                if (response?.ok) {
                    if (options?.router) {
                        const { navigate, location } =
                            options.router;
                        navigate(
                            `/${
                                store.site.site?.id || ''
                            }/category/change/${
                                response.data.id
                            }`,
                            {
                                state: location,
                            },
                        );
                    }
                    if (options?.notifier) {
                        const { enqueueSnackbar } =
                            options.notifier;
                        enqueueSnackbar('Рубрика создана', {
                            variant: 'success',
                        });
                    }
                }
            } catch (e) {
                if (
                    CLIENT.categories.create &&
                    e instanceof
                        CLIENT.categories.create.Error
                ) {
                    const error = e.getActualType();
                    if (
                        options?.notifier?.enqueueSnackbar
                    ) {
                        const { enqueueSnackbar } =
                            options.notifier;

                        const errormsgs = Object.values(
                            error.data,
                        );

                        errormsgs?.map((msg) => {
                            enqueueSnackbar(msg as string, {
                                variant: 'error',
                            });
                        });
                    }

                    return {
                        isOk: false,
                        error: error,
                    };
                }
            }
        },
        update: async (
            {
                query,
                payload,
                options,
            }: CategoryUpdateEffect,
            store,
        ) => {
            try {
                const responseForm =
                    await CLIENT.categories?.updatePartialById?.(
                        {
                            ...payload.formData,
                            id: query.id,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );

                if (responseForm?.ok) {
                    const icon =
                        payload.iconData.get('icon');
                    let responseIcon;
                    if (icon !== 'null') {
                        responseIcon =
                            await CLIENT.categories.updateIcon(
                                {
                                    data: payload.iconData,
                                    id: query.id.toString(),
                                    headers: {
                                        'X-Site':
                                            store.site.site
                                                ?.id || '',
                                    },
                                },
                            );
                        if (
                            responseIcon?.data &&
                            responseIcon.status == 200
                        ) {
                            dispatch.category.put(
                                responseIcon.data,
                            );
                            if (options?.notifier) {
                                const { enqueueSnackbar } =
                                    options.notifier;
                                enqueueSnackbar(
                                    'Рубрика обновлена',
                                );
                            }
                        } else {
                            dispatch.category.put(
                                responseForm.data,
                            );
                            if (options?.notifier) {
                                const { enqueueSnackbar } =
                                    options.notifier;
                                enqueueSnackbar(
                                    'Основная форма успешно отправлена, но возникла проблема с загрузкой иконки.',
                                );
                                const errormsgsIcon =
                                    Object.values(
                                        responseIcon.data,
                                    );
                                errormsgsIcon?.map(
                                    (msg) => {
                                        enqueueSnackbar(
                                            msg as string,
                                            {
                                                variant:
                                                    'error',
                                            },
                                        );
                                    },
                                );
                            }
                        }
                    } else {
                        dispatch.category.put(
                            responseForm.data,
                        );
                        if (options?.notifier) {
                            const { enqueueSnackbar } =
                                options.notifier;

                            enqueueSnackbar(
                                'Рубрика обновлена',
                            );
                        }
                    }
                } else {
                    if (options?.notifier) {
                        const { enqueueSnackbar } =
                            options.notifier;
                        const errormsgsForm = Object.values(
                            responseForm?.data || {},
                        );
                        errormsgsForm?.map((msg) => {
                            enqueueSnackbar(msg as string, {
                                variant: 'error',
                            });
                        });
                    }
                }
            } catch (e) {
                return e;
            }
        },
        remove: async (id: number, store) => {
            const query = store.categories.query;

            try {
                const response =
                    await CLIENT?.categories?.deleteById?.(
                        {
                            id,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    dispatch.categories.get(query);
                }
            } catch {}
        },
    }),
});

export { category };
export default category;
