import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { styles as s } from './SortableContainer.css';
import { useMainGridStore } from '@mega/store';

export interface DraggableContainerProps {
    id: string;
    children: React.ReactElement;
    parentId?: string | null;
    isContainer?: boolean;
    isEditing?: boolean;
}

export const SortableContainer: React.FC<
    DraggableContainerProps
> = (props) => {
    const { id, children, parentId, isContainer } = props;
    const store = useMainGridStore();
    const { settingsSmartGridIsOff, settingsIsOpen } =
        store;

    const { attributes, listeners, setNodeRef, transform } =
        useSortable({
            id: id,
            data: { id, parentId, isContainer },
            disabled:
                settingsSmartGridIsOff || settingsIsOpen,
        });
    const style = transform
        ? {
              transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
          }
        : undefined;

    return (
        <div
            ref={setNodeRef}
            className={s.wrapper()}
            style={style}
            {...listeners}
            {...attributes}
        >
            {children}
        </div>
    );
};
