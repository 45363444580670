import { createSelector } from 'reselect';
import { RootState, useStoreSelector } from '../../models';
import { createUnparsedSourceFile } from 'typescript';

const selectMe = (state: RootState) => state.user.me;

const selectUserInfo = createSelector(selectMe, (me) => {
    const rootPermissions = Array.from(
        new Set(
            me?.permissions.map(
                (permission) => permission.split('.')[0],
            ),
        ).values(),
    ).filter((item) =>
        [
            'article',
            'author',
            'category',
            'hostvideo',
            'tag',
            'mainpage',
            'story',
            'infounittask',
            'report',
            'copyright',
            'reportsource',
            'repost',
            'issue',
            'newspaperdocument',
            'mainpagestream',
            'block',
            'headscript',
            'infinitemix',
            'newspaper',
            'bannedorganization',
            'user',
            'maingrid',
        ].find((per) => item === per),
    );

    const mapPermission = new Map<
        string | undefined,
        Map<string | undefined, boolean>
    >();

    const subRootPermissions = me?.permissions.reduce(
        (
            acc: {
                [key: string]: Array<string>;
            },
            item,
        ) => {
            const [key, value] = item.split('.');

            return {
                ...acc,
                [key]: [...(acc?.[key] ?? []), value],
            };
        },
        {},
    );

    Object.entries(subRootPermissions || {}).forEach(
        ([key, value]) => {
            mapPermission.set(
                key,
                new Map<string | undefined, boolean>(
                    value.map((item) => [item, true]),
                ),
            );
        },
    );

    const isPermitted = ({
        rootPermissions,
        subRootPermissions,
    }: {
        rootPermissions: string;
        subRootPermissions?: string;
    }): boolean => {
        if (rootPermissions && subRootPermissions) {
            return Boolean(
                mapPermission
                    .get(rootPermissions)
                    ?.get(subRootPermissions),
            );
        }
        return Boolean(mapPermission.get(rootPermissions));
    };

    return {
        me,
        sites: me?.sites ?? [],
        smi: me?.smi || null,
        rootPermissions,
        subRootPermissions,
        isPermitted,
    };
});

const useUserInfo = () => {
    const userInfo = useStoreSelector(selectUserInfo);
    return userInfo;
};

export { useUserInfo };
