import React, { FC, useEffect } from 'react';

import { useTagsStore, useTagsDispatch } from '@mega/store';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Button,
    EntityListLayout,
    ListContent,
    Pagination,
} from '@mega/ui';
import { TagFilters } from '@mega/core';
import { Add } from '@mega/icons';
import {
    ListingTags,
    NoResultsFoundMessage,
} from '@mega/core';

interface TagsProps {
    children?: never;
}

const Tags: FC<TagsProps> = () => {
    const store = useTagsStore();
    const { jumpTo } = useTagsDispatch();
    let navigate = useNavigate();
    let location = useLocation();

    const tagsListName = 'tagList';
    const allTagsCount = store.meta.count || 0;
    const tagsPerPage = store.query.per_page || 1;
    const allPagesCount = Math.ceil(
        allTagsCount / tagsPerPage,
    );

    const hasPage = store.pages['1'] ? true : false;
    const isItLoadingNow = store.loading.get.loading;
    const isPageEmpty =
        !isItLoadingNow &&
        hasPage &&
        store.pages['1'].length === 0;

    useEffect(() => {
        if (!isItLoadingNow) {
            window.scrollTo(0, 0);
        }
    }, [isItLoadingNow]);
    return (
        <EntityListLayout
            setting={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: '18px',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                            }}
                        >
                            <TagFilters />
                        </div>
                        <Button
                            size={'large'}
                            endIcon={<Add />}
                            label="Добавить"
                            classes={{
                                paper: {
                                    variant: 'filled',
                                    color: 'dark',
                                },
                            }}
                            onClick={() => {
                                navigate(`add`, {
                                    replace: true,
                                    state: location,
                                });
                            }}
                        />
                    </div>
                </div>
            }
        >
            <>
                <ListContent name={tagsListName}>
                    {() => {
                        return <ListingTags />;
                    }}
                </ListContent>
                <NoResultsFoundMessage
                    isPageEmpty={isPageEmpty}
                />
                <Pagination
                    pageCount={allPagesCount}
                    jumpTo={jumpTo}
                    loading={isItLoadingNow}
                />
            </>
        </EntityListLayout>
    );
};

export { Tags };
