import React from 'react';
import { VideoPoster } from '../SliderModal/VideoPreview';
import {
    iconWrapper,
    styledIcon,
    styledImg,
    styledPlug,
} from './MediaViewer.css';
import { FiFile, FiMic } from 'react-icons/fi';
import { Typography } from '@mega/ui';

export interface MediaViewerProps {
    file?: any;
    filesLength?: number;
}

export const MediaViewer: React.FC<MediaViewerProps> = (
    props,
) => {
    const { file, filesLength } = props;
    if (!file && filesLength) {
        return (
            <div className={styledPlug}>
                <Typography size={'14'}>
                    Еще {filesLength}
                </Typography>
            </div>
        );
    }
    switch (file.type) {
        case 'video': {
            return (
                <VideoPoster
                    size={'small'}
                    img={file?.frame}
                />
            );
        }
        case 'audio': {
            return (
                <div className={styledPlug}>
                    <div className={iconWrapper}>
                        <FiMic className={styledIcon} />
                    </div>
                </div>
            );
        }
        case 'file': {
            return (
                <div className={styledPlug}>
                    <div className={iconWrapper}>
                        <FiFile className={styledIcon} />
                    </div>
                </div>
            );
        }
        case 'image': {
            return (
                <img
                    className={styledImg}
                    src={file?.image}
                    alt=""
                />
            );
        }
        default: {
            return (
                <div className={styledPlug}>
                    <Typography size={'14'}>
                        Еще {filesLength}
                    </Typography>
                </div>
            );
        }
    }
};
