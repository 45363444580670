/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { FC, useMemo } from 'react';

import {
    Form,
    Formik,
    useFormikContext,
    FormikHelpers,
} from 'formik';
import * as Yup from 'yup';

import type { Copyright } from '@mega/api';

import {
    Accordion,
    Button,
    CheckboxSwitchField,
    FormLayout,
    InputField,
    Paper,
    Stack,
} from '@mega/ui';
import { throttle } from 'lodash-es';

export interface CopyrightEditProps {
    initialValue?: Copyright;
    handleUpdate?: (
        value: Copyright,
        actions: FormikHelpers<Copyright>,
    ) => void;
}

const CopyrightEditActions = () => {
    const { submitForm, isSubmitting } = useFormikContext();
    const throttledSubmit = useMemo(
        () => throttle(submitForm, 1000),
        [],
    );
    return (
        <>
            <Paper
                style={{
                    borderTop: '1px solid #EAEAEA',
                }}
                fullWidth
                color={'white'}
                variant="filled"
                classes={{
                    padding: { size: '24x24' },
                }}
            >
                <Button
                    size={'large'}
                    type={'submit'}
                    label={'Обновить'}
                    onClick={throttledSubmit}
                    classes={{
                        paper: {
                            color: 'dark',
                            variant: 'filled',
                        },
                    }}
                    disabled={isSubmitting}
                />
            </Paper>
        </>
    );
};

const CopyrightEdit: FC<CopyrightEditProps> = ({
    handleUpdate = () => null,
    initialValue = { title: '' },
}) => (
    <Formik
        validationSchema={Yup.object({
            title: Yup.string()
                .min(
                    1,
                    'Это поле явно должно быть больше одного символа',
                )
                .required('Это поле обязательное'),
        })}
        initialValues={{
            ...initialValue,
        }}
        onSubmit={handleUpdate}
    >
        <Form
            style={{
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <FormLayout
                actions={<CopyrightEditActions />}
                settings={
                    <Paper
                        contentHeight
                        color={'white'}
                        variant="outline"
                        classes={{
                            padding: {
                                size: '24x24',
                            },
                        }}
                    >
                        <Stack gap="18">
                            <CheckboxSwitchField
                                name="is_active"
                                label="Активно"
                            />
                        </Stack>
                    </Paper>
                }
            >
                <Paper
                    variant="outline"
                    color="white"
                    classes={{
                        padding: {
                            size: '24x24',
                        },
                    }}
                >
                    <Stack gap="18">
                        <InputField
                            type="text"
                            name={'title'}
                            placeholder="Название"
                            dimension="medium"
                            isRequired
                        />
                    </Stack>
                </Paper>
            </FormLayout>
        </Form>
    </Formik>
);

export { CopyrightEdit };
