import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgUpdate = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="m11.344 12.056-2.888-2.85V5.194h1.125v3.544l2.569 2.512-.806.806ZM9.056 15.75a6.596 6.596 0 0 1-2.643-.534 6.978 6.978 0 0 1-2.166-1.454 6.786 6.786 0 0 1-1.463-2.156 6.597 6.597 0 0 1-.534-2.643c0-.938.178-1.813.534-2.625a6.734 6.734 0 0 1 1.463-2.129 6.928 6.928 0 0 1 2.165-1.434 6.699 6.699 0 0 1 2.644-.525c1 0 1.947.219 2.84.656a7.136 7.136 0 0 1 2.316 1.8V2.72h1.126v3.9h-3.92V5.494h1.97a6.52 6.52 0 0 0-1.941-1.538 5.1 5.1 0 0 0-2.39-.581c-1.563 0-2.9.534-4.013 1.603S3.375 7.356 3.375 8.906c0 1.588.55 2.938 1.65 4.05 1.1 1.113 2.444 1.669 4.031 1.669 1.563 0 2.882-.55 3.957-1.65 1.075-1.1 1.612-2.431 1.612-3.994h1.125c0 1.875-.65 3.472-1.95 4.79-1.3 1.32-2.881 1.979-4.744 1.979Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgUpdate);
export default ForwardRef;
