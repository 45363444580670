import React, { FC, useEffect, useMemo } from 'react';

import { Column, CellValue } from 'react-table';
import type { Papers as PapersType } from '@mega/api';
import {
    IconButton,
    ListingTable,
    Icon,
    Bread,
    Crumbs,
} from '@mega/ui';
import { typographyClasses } from '@mega/styles';
import {
    usePapersDispatch,
    usePapersStore,
    useUserInfo,
} from '@mega/store';
import { TitleColumn } from '../Columns';
import { headItem, listingRecipe } from '../Listing.css';
import dayjs from 'dayjs';
import { AcceptModal } from '@mega/core';
import { PubOn, PubOff, Close } from '@mega/icons';
import { useSnackbar } from 'notistack';

export interface ListingNewspapersProps {}

const ListingNewspapers: FC<
    ListingNewspapersProps
> = () => {
    const store = usePapersStore();
    const { get, clear, deleteById } = usePapersDispatch();
    const { isPermitted } = useUserInfo();
    const { enqueueSnackbar } = useSnackbar();

    const isDeletePermitted = isPermitted({
        rootPermissions: 'issue',
        subRootPermissions: 'delete',
    });

    useEffect(() => {
        get({});
        return () => {
            clear();
        };
    }, [get, clear]);

    const columns: Column<PapersType>[] = React.useMemo(
        () => [
            {
                Header: 'Заголовок',
                accessor: (row) => {
                    return {
                        title: row.title,
                        id: row.id,
                    };
                },
                Cell: ({ value }: CellValue) => (
                    <TitleColumn {...value} />
                ),
                minWidth: 200,
                width: '100%',
            },
            {
                Header: 'Издание',
                accessor: 'newspaper',
                width: '100%',
                maxWidth: 100,
                Cell: ({ value }: CellValue) => {
                    if (!value) {
                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    justifyContent:
                                        'center',
                                    alignItems: 'center',
                                }}
                            >
                                —
                            </div>
                        );
                    }
                    return (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {value.label}
                        </div>
                    );
                },
            },
            {
                Header: 'Активность',
                accessor: 'is_active',
                width: '100%',
                maxWidth: 100,
                Cell: ({ value }: CellValue) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Icon size="25">
                                {value ? (
                                    <PubOn />
                                ) : (
                                    <PubOff />
                                )}
                            </Icon>
                        </div>
                    );
                },
            },
            {
                Header: 'Публикация',
                accessor: 'date',
                width: '100%',
                maxWidth: 150,
                Cell: ({ value }: CellValue) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Bread>
                                <Crumbs>
                                    <span>
                                        {dayjs(
                                            value,
                                        ).format(
                                            'DD.MM.YYYY в HH:mm',
                                        )}
                                    </span>
                                </Crumbs>
                            </Bread>
                        </div>
                    );
                },
            },
            {
                Header: 'Действия',
                accessor: 'id',
                width: '100%',
                maxWidth: 100,
                Cell: ({ value }: CellValue) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignContent: 'center',
                                gap: '10px',
                            }}
                        >
                            {isDeletePermitted && (
                                <AcceptModal
                                    msg="Вы уверенны что хотите удалить Выпуск ?"
                                    Trigger={
                                        <IconButton
                                            animate="none"
                                            onClick={async () => {
                                                if (value) {
                                                    const res =
                                                        await deleteById(
                                                            value,
                                                        );

                                                    if (
                                                        res.isOk
                                                    ) {
                                                        return enqueueSnackbar(
                                                            'Выпуск газеты удален',
                                                            {
                                                                variant:
                                                                    'success',
                                                            },
                                                        );
                                                    } else {
                                                        return enqueueSnackbar(
                                                            'Что-то пошло не так, повторите попытку позже',
                                                            {
                                                                variant:
                                                                    'error',
                                                            },
                                                        );
                                                    }
                                                } else {
                                                }
                                            }}
                                            classes={{
                                                paper: {
                                                    color: 'white',
                                                },
                                            }}
                                        >
                                            <Icon size="25">
                                                <Close
                                                    style={{
                                                        color: '#EB5757CC',
                                                    }}
                                                />
                                            </Icon>
                                        </IconButton>
                                    }
                                />
                            )}
                        </div>
                    );
                },
            },
        ],
        [],
    );

    const data = useMemo(() => {
        const values =
            store.pages?.[store.currentPage] || [];
        return Object.values(values).flat();
    }, [store.pages]);

    return (
        <>
            <ListingTable
                getCellProps={(cellInfo) => {
                    return {
                        style: {},
                    };
                }}
                getHeaderGroupProps={(cellInfo) => {
                    return {
                        className: listingRecipe({
                            header: true,
                        }),
                    };
                }}
                getHeaderProps={(cellInfo) => {
                    return {
                        className: headItem({
                            title:
                                cellInfo?.id ===
                                'Заголовок',
                        }),
                        style: {},
                    };
                }}
                getRowGroupProps={() => {
                    return {
                        className: [
                            typographyClasses.recipe({
                                size: '14',
                            }),
                            listingRecipe({
                                header: false,
                            }),
                        ].join(' '),
                    };
                }}
                columns={columns}
                data={data}
            />
        </>
    );
};

export { ListingNewspapers };
