import { PluginTemplate } from '@pages/smartGrid/types/blocks';

export enum LastNewsEnum {
    type = 'LAST_NEWS',
}

export interface LastNewsProps {
    id: string;
    type: string;
    parentId: string | null;
    text: string;
}

export type LastNewsMeta = {};

export type LastNewsPlugin = PluginTemplate<
    LastNewsEnum.type,
    LastNewsMeta
>;
export type LastNewsPluginDefault = Omit<
    LastNewsPlugin,
    'id'
>;
