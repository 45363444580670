import { Option } from '@mega/ui/src/components/Inputs/types';
import { PluginTemplate } from '@pages/smartGrid/types/blocks';
import { Control } from '@pages/smartGrid/types/core';
import { CoverType } from '@pages/smartGrid/types/const';

export enum AutoArticleEnum {
    type = 'CONTENT_AUTO_ARTICLE',
}

export interface AutoArticleProps {
    id: string;
    meta: AutoArticleMeta;
    type: string;
    parentId: string | null;
}

export type AutoArticleMeta = {
    categories: Option[];
    tags: Option[];
    cover: CoverType;
    withLead: boolean;
    withInfo: boolean;
};

export interface AutoArticleControl extends Control {
    meta: AutoArticleMeta;
}

export type AutoArticlePlugin = PluginTemplate<
    AutoArticleEnum.type,
    AutoArticleMeta
>;
export type AutoArticlePluginDefault = Omit<
    AutoArticlePlugin,
    'id'
>;
