import { useDropzone } from 'react-dropzone';
import { FiPlus } from 'react-icons/fi';
import * as classes from './DropZone.css';

export function DropZone({
    onDrop,
}: {
    onDrop: (acceptedFiles: File[]) => void;
}) {
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: onDrop,
        accept: {
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                ['.docx'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                ['.xlsx'],
        },
    });

    return (
        <div
            {...getRootProps({
                className: 'dropzone',
            })}
            className={classes.dropzone}
        >
            <input {...getInputProps()} />
            <div className={classes.constainer}>
                <FiPlus />
            </div>
        </div>
    );
}
