import React, {
    Children,
    cloneElement,
    FC,
    ReactElement,
} from 'react';

import { iconClasses, IconClasses } from '@mega/styles';

export interface IconProps {
    children: ReactElement;
    className?: string;
}

export const Icon: FC<
    IconProps & IconClasses['recipe']
> = ({ children, size = '14' }) => {
    const newChildren = Children.map(children, (child) =>
        cloneElement(child, {
            className: [
                child?.props?.className,
                iconClasses.recipe({ size }),
            ].join(' '),
        }),
    );
    return <>{newChildren}</>;
};
