import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgDzenRssOff = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 14 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M13.033 13.139v-.5H6.27l6.53-9.52.087-.128V.706H1.203V4h6.643l-6.563 9.46-.09.128v2.336h11.84v-2.785Z"
            stroke="#EAEAEA"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgDzenRssOff);
export default ForwardRef;
