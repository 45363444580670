import React from 'react';
import {
    Lexical,
    // @ts-ignore
} from '@tv360/storybook';

export interface MilkRenderProps {
    content?: string | Record<string, unknown>;
}

export const MilkRender: React.FC<MilkRenderProps> = ({
    content,
}) => {
    if (!content) {
        return null;
    }

    const parse =
        typeof content === 'string'
            ? JSON.parse(content)
            : content;
    const data = parse.root.children;

    return <Lexical data={data} />;
};
