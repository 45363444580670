import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgAdd = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M8.438 14.25V9.562H3.75V8.438h4.688V3.75h1.124v4.688h4.688v1.124H9.562v4.688H8.438Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgAdd);
export default ForwardRef;
