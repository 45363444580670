import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';
import { useRef } from 'react';
import { debounce } from 'lodash-es';
import {
    selectArticles,
    selectArticlesFull,
    selectArticlesPages,
    selectArticlesMeta,
    selectArticlesQuery,
    selectArticlesProgress,
    pickArticlesChungeCountItemsOnPageProgress,
    pickArticlesGetProgress,
    pickArticlesJumpToProgress,
} from './private';
import { createSelector } from 'reselect';

const reselectArticlesFull = createSelector(
    selectArticles,
    selectArticlesFull,
);

const reselectArticlesPages = createSelector(
    selectArticles,
    selectArticlesPages,
);

const reselectArticlesMeta = createSelector(
    selectArticles,
    selectArticlesMeta,
);

const reselectArticlesQuery = createSelector(
    selectArticles,
    selectArticlesQuery,
);

const rePickArticlesProgressFull = createSelector(
    selectArticlesProgress,
    (progres) => progres,
);

const rePickArticlesChungeCountItemsOnPageProgress =
    createSelector(
        selectArticlesProgress,
        pickArticlesChungeCountItemsOnPageProgress,
    );

const rePickArticlesGetProgress = createSelector(
    selectArticlesProgress,
    pickArticlesGetProgress,
);

const rePickArticlesJumpToProgress = createSelector(
    selectArticlesProgress,
    pickArticlesJumpToProgress,
);

const useArticlesStore = () => {
    const state = useStoreSelector(reselectArticlesFull);

    const loading = useStoreSelector(
        (state: RootState) =>
            state.loading.effects.articles,
    );

    return Object.assign(state, {
        loading: {
            get: loading.get,
            search: loading.search,
            nextPage: loading.nextPage,
            getOptions: loading.getOptions,
            getMainGrid: loading.getMainGrid,
            chungeCountItemsOnPage:
                loading.chungeCountItemsOnPage,
            jumpTo: loading.jumpTo,
        },
    });
};

const useArticlesPages = () => {
    const state = useStoreSelector(reselectArticlesPages);
    return state;
};

const useArticlesMeta = () => {
    const state = useStoreSelector(reselectArticlesMeta);
    return state;
};

const useArticlesQuery = () => {
    const state = useStoreSelector(reselectArticlesQuery);
    return state;
};

const usePickArticlesChungeCountItemsOnPageProgress =
    () => {
        const loading = useStoreSelector(
            rePickArticlesChungeCountItemsOnPageProgress,
        );
        return loading;
    };

const usePickArticlesGetProgress = () => {
    const loading = useStoreSelector(
        rePickArticlesGetProgress,
    );
    return loading;
};

const usePickArticlesJumpToProgress = () => {
    const loading = useStoreSelector(
        rePickArticlesJumpToProgress,
    );
    return loading;
};

const useArticlesDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    const debouncedHandleSetCurrentPage = useRef(
        debounce(dispatch.articles.setCurrentPage, 1000),
    );

    return {
        get: dispatch.articles.get,
        nextPage: dispatch.articles.nextPage,
        getOptions: dispatch.articles.getOptions,
        getMainGrid: dispatch.articles.getMainGrid,
        jumpTo: dispatch.articles.jumpTo,
        setCurrentPage:
            debouncedHandleSetCurrentPage.current,
        search: dispatch.articles.search,
        clear: dispatch.articles.clear,
        getWithCheckRooms:
            dispatch.articles.getWithCheckRooms,
        updateBulk: dispatch.articles.updateBulk,
    };
};

export {
    useArticlesStore,
    useArticlesDispatch,
    useArticlesPages,
    useArticlesMeta,
    useArticlesQuery,
    usePickArticlesChungeCountItemsOnPageProgress,
    usePickArticlesGetProgress,
    usePickArticlesJumpToProgress,
};
