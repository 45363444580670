import { useCallback } from 'react';

import {
    useArticlesDispatch,
    useArticlesQuery,
    useSiteStore,
} from '@mega/store';
import {
    getOptionsAuthors,
    getOptionsAuthorsById,
    getOptionsCategories,
    getOptionsCategoriesById,
    getOptionsTagsArticlePage,
    getOptionsTagsById,
    getOptionsUser,
    getOptionsUserById,
} from '@mega/core';
import dayjs from 'dayjs';

const timeTemplate = 'YYYY-MM-DDTHH:mm';

export const useArticleFilters = () => {
    const urlParams = new URLSearchParams(location.search);
    const currentSite = useSiteStore();
    const { search } = useArticlesDispatch();
    const query = useArticlesQuery();

    const {
        authors: queryAuthors,
        category: queryCategories,
        tags__in: queryTags,
    } = query;

    const authorsCallback = useCallback(
        getOptionsAuthors({
            site: currentSite?.id || '',
        }),
        [],
    );

    const authorsByIdCallback = useCallback(
        getOptionsAuthorsById({
            site: currentSite?.id || '',
        }),
        [],
    );

    const categoriesCallback = useCallback(
        getOptionsCategories({
            site: currentSite?.id || '',
        }),
        [],
    );

    const ownerCallback = useCallback(
        getOptionsUser({
            site: currentSite?.id || '',
        }),
        [],
    );

    const ownerByIdCallback = useCallback(
        getOptionsUserById({
            site: currentSite?.id || '',
        }),
        [],
    );

    const tagsCallback = useCallback(
        getOptionsTagsArticlePage({
            site: currentSite?.id || '',
        }),
        [],
    );

    const tagsByIdCallback = useCallback(
        getOptionsTagsById({
            site: currentSite?.id || '',
        }),
        [],
    );

    const categoriesByIdCallback = useCallback(
        getOptionsCategoriesById({
            site: currentSite?.id || '',
        }),
        [],
    );

    const setDateRange = (dates: Date[]) => {
        const ltDate = dayjs(dates[1]).format(timeTemplate);
        const gtDate = dayjs(dates[0]).format(timeTemplate);

        urlParams.set('pub_date__gte', gtDate.toString());
        urlParams.set('pub_date__lte', ltDate.toString());
        window.history.replaceState(
            null,
            '',
            `?${urlParams.toString()}`,
        );

        search({
            //@ts-ignore
            ['pub_date__gte']: gtDate,
            ['pub_date__lte']: ltDate,
        });
    };

    return {
        authorsCallback,
        categoriesCallback,
        tagsCallback,
        tagsByIdCallback,
        categoriesByIdCallback,
        authorsByIdCallback,
        ownerCallback,
        ownerByIdCallback,
        setDateRange,
        urlParams,
        query,
        queryAuthors,
        queryCategories,
        queryTags,
    };
};
