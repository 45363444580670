import { createModel } from '@rematch/core';
import * as CLIENT from '@mega/api';

import { flatten } from 'lodash-es';

import type { RootModel } from '../rootModel';

type RoomUser = {
    id: number;
    name: string;
    read_only?: boolean;
};

export interface Rooms {
    room: {
        [key: string]: Array<RoomUser>;
    };
}

const defaultStore: Rooms = {
    room: {},
};

const articlesRoom = createModel<RootModel>()({
    name: 'articlesRoom',
    state: defaultStore,
    reducers: {
        putRoom: (state, payload: Rooms['room']) => {
            return {
                ...state,
                room: {
                    ...state.room,
                    ...payload,
                },
            };
        },
    },
    effects: (dispatch) => ({
        checkPageRoom: async (_, store) => {
            const { pages, currentPage } = store.articles;
            const allRoom = flatten(
                pages?.[currentPage] ?? [],
            );
            const queryPagesId = allRoom
                .map((item) => item.id)
                .join(',');
            try {
                const responce =
                    await CLIENT?.rooms?.checkinList?.({
                        obj_ids: queryPagesId,
                    });
                if (responce?.ok) {
                    const flatRooms = responce.data.reduce(
                        (acc, item) => {
                            if (item.id) {
                                return {
                                    ...acc,
                                    [item.id]:
                                        item?.users || [],
                                };
                            }
                            return { ...acc };
                        },
                        {},
                    ) as { [key: string]: Array<RoomUser> };
                    // if(!isEqual(
                    //     store.articleRoom.rooms,
                    //     checkinUser.sort(),
                    // ))
                    dispatch.articlesRoom.putRoom(
                        flatRooms,
                    );
                }
            } catch (e) {
                if (
                    CLIENT.rooms.checkinList &&
                    e instanceof
                        CLIENT.rooms.checkinList.Error
                ) {
                    const error = e.getActualType();
                    if (error.status === 401) {
                        const { isOk } =
                            await dispatch.auth[401](error);
                    }
                }
            }
        },
    }),
});

export { articlesRoom };
export default articlesRoom;
