import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgTableColumnLeft = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M15.25 11.25h3.25v1.5h-3.25V16h-1.5v-3.25H10.5v-1.5h3.25V8h1.5v3.25Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.25 5A2.75 2.75 0 0 1 5 2.25h14A2.75 2.75 0 0 1 21.75 5v14A2.75 2.75 0 0 1 19 21.75H5A2.75 2.75 0 0 1 2.25 19V5ZM5 20.25c-.69 0-1.25-.56-1.25-1.25v-3.25h3.5v4.5H5Zm3.75 0V3.75H19c.69 0 1.25.56 1.25 1.25v14c0 .69-.56 1.25-1.25 1.25H8.75Zm-1.5-6h-3.5v-4.5h3.5v4.5Zm0-6h-3.5V5A1.246 1.246 0 0 1 5 3.75h2.25v4.5Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgTableColumnLeft);
export default ForwardRef;
