import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import {
    $getRoot,
    COMMAND_PRIORITY_EDITOR,
    createCommand,
    LexicalCommand,
} from 'lexical';

import { useApostrophStore } from '@mega/store';
import { bannedOrganizations } from '@mega/api';
import { SetInAgent } from '../../Lex.types';

const SEARCH_IN_AGENT_COMMAND: LexicalCommand<string> =
    createCommand();

export function SearchInAgent(props: {
    setInAgent?: SetInAgent;
}) {
    const [editor] = useLexicalComposerContext();
    const { isSearchedInAgent } = useApostrophStore();

    const test = async () => {
        const root = $getRoot();
        const textContent = root.getTextContent();

        const newState = JSON.stringify(
            editor.getEditorState().toJSON(),
        );

        try {
            const res =
                await bannedOrganizations.findMatches?.({
                    text: textContent,
                });

            if (res?.ok) {
                props.setInAgent?.(
                    res?.data?.matched || [],
                    newState,
                );
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        editor.registerCommand(
            SEARCH_IN_AGENT_COMMAND,
            () => {
                test();
                return false;
            },
            COMMAND_PRIORITY_EDITOR,
        );
    }, []);

    useEffect(() => {
        if (isSearchedInAgent) {
            editor.dispatchCommand(
                SEARCH_IN_AGENT_COMMAND,
                '',
            );
        }
    }, [isSearchedInAgent]);

    return null;
}
