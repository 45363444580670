import React, { useMemo } from 'react';
import { useField, useFormikContext } from 'formik';
import { isEqual } from 'lodash-es';
import {
    smartGridEditorContext,
    store,
} from '../../store/store';
import { Provider } from 'react-redux';
import { GridInitializer } from '../GridInitializer';
import {
    PLUGIN_ADS,
    PLUGIN_ADS_CONTROL,
    PLUGIN_360_ARTICLE,
    PLUGIN_RIAMO_ARTICLE,
    PLUGIN_360_ARTICLE_CONTROL,
    PLUGIN_RIAMO_ARTICLE_CONTROL,
    PLUGIN_ARTICLE_LIST,
    PLUGIN_ARTICLE_LIST_CONTROL,
    PLUGIN_360_AUTO_ARTICLE,
    PLUGIN_RIAMO_AUTO_ARTICLE,
    PLUGIN_360_AUTO_ARTICLE_CONTROL,
    PLUGIN_RIAMO_AUTO_ARTICLE_CONTROL,
    PLUGIN_CAROUSEL,
    PLUGIN_CAROUSEL_CONTROL,
    PLUGIN_CATEGORIES,
    PLUGIN_CATEGORIES_CONTROL,
    PLUGIN_FLOW_ROW,
    PLUGIN_FLOW_ROW_CONTROL,
    PLUGIN_FLOW_STACK,
    PLUGIN_FLOW_STACK_CONTROL,
    PLUGIN_HEADLINE,
    PLUGIN_HEADLINE_CONTROL,
    PLUGIN_POTOKUS,
    PLUGIN_POTOKUS_CONTROL,
    PLUGIN_LAST_NEWS,
    PLUGIN_LAST_NEWS_CONTROL,
    PLUGIN_MOST_READ,
    PLUGIN_MOST_READ_CONTROL,
    PLUGIN_NUMOFTHEDAY,
    PLUGIN_NUMOFTHEDAY_CONTROL,
    PLUGIN_STORIES,
    PLUGIN_STORIES_CONTROL,
    PLUGIN_STORIES_LIST,
    PLUGIN_STORIES_LIST_CONTROL,
    PLUGIN_THEMES,
    PLUGIN_THEMES_CONTROL,
} from '../Plugins';
import { ToolsList } from '@pages/smartGrid/constants/tools';
import {
    PLUGIN_SECOND_TEMPLATE,
    PLUGIN_SECOND_TEMPLATE_CONTROL,
} from '@pages/smartGrid/components/Plugins/Templates/Second';
import {
    PLUGIN_MAIN_TEMPLATE,
    PLUGIN_MAIN_TEMPLATE_CONTROL,
} from '@pages/smartGrid/components/Plugins/Templates/Main';
import {
    RootState,
    useMainGridDispatch,
    useSiteStore,
    useStoreSelector,
} from '@mega/store';

const {
    GROUP_INTERACTIVE,
    GROUP_CONTENT,
    GROUP_GRID,
    GROUP_TEMPLATES,
} = ToolsList;

export interface GridEditorFieldProps {
    name: string;
    isReadonly?: boolean;
}

export type GridEditorProps = {
    initialState: any;
    onChange: (e: unknown) => void;
    isReadonly: boolean;
};

const GridEditor: React.FC<GridEditorProps> = React.memo(
    (props) => {
        const currenSite = useSiteStore();

        // TODO написать конфиг для обработки плагинов сетки и их настроек
        const is360 = currenSite?.id === '360tv.ru';
        const { config } = useStoreSelector(
            (store: RootState) => store.settings,
        );

        const isPotokus = config.main_grid.potokus;

        const memoStore = useMemo(() => {
            return props.isReadonly
                ? store('smartGrid/preview')
                : store(undefined);
        }, [props.isReadonly]);

        const CONTROLS_OBJECT = {
            [GROUP_GRID.name]: {
                ...GROUP_GRID,
                list: [
                    is360
                        ? undefined
                        : {
                              ...PLUGIN_FLOW_ROW_CONTROL,
                              label: 'Ряд',
                              groupName: GROUP_GRID.name,
                          },
                    {
                        ...PLUGIN_FLOW_STACK_CONTROL,
                        label: 'Столбец',
                        groupName: GROUP_GRID.name,
                    },
                ].filter((item) => item),
            },
            [GROUP_CONTENT.name]: {
                ...GROUP_CONTENT,
                list: [
                    {
                        ...(is360
                            ? PLUGIN_360_AUTO_ARTICLE_CONTROL
                            : PLUGIN_RIAMO_AUTO_ARTICLE_CONTROL),
                        label: 'Автостатьи',
                        groupName: GROUP_CONTENT.name,
                    },
                    {
                        ...(is360
                            ? PLUGIN_360_ARTICLE_CONTROL
                            : PLUGIN_RIAMO_ARTICLE_CONTROL),
                        label: 'Статьи',
                        groupName: GROUP_CONTENT.name,
                    },
                    is360
                        ? {
                              ...PLUGIN_STORIES_CONTROL,
                              label: 'Истории',
                              groupName: GROUP_CONTENT.name,
                          }
                        : undefined,
                    {
                        ...PLUGIN_THEMES_CONTROL,
                        label: 'Темы',
                        groupName: GROUP_CONTENT.name,
                    },
                    {
                        ...PLUGIN_CATEGORIES_CONTROL,
                        label: 'Категории',
                        groupName: GROUP_CONTENT.name,
                    },
                    // {
                    //     ...PLUGIN_AUTHOR_CONTROL,
                    //     label: 'Авторы',
                    //     groupName:
                    //         GROUP_CONTENT.name,
                    // },
                    is360
                        ? undefined
                        : {
                              ...PLUGIN_LAST_NEWS_CONTROL,
                              label: 'Последние новости',
                              groupName: GROUP_CONTENT.name,
                          },
                    {
                        ...PLUGIN_MOST_READ_CONTROL,
                        label: 'Самое читаемое',
                        groupName: GROUP_CONTENT.name,
                    },
                ].filter((item) => item),
            },
            [GROUP_INTERACTIVE.name]: {
                ...GROUP_INTERACTIVE,
                list: [
                    {
                        ...PLUGIN_HEADLINE_CONTROL,
                        label: 'Заголовок',
                        groupName: GROUP_INTERACTIVE.name,
                    },
                    !isPotokus
                        ? undefined
                        : {
                              ...PLUGIN_POTOKUS_CONTROL,
                              label: 'Потокус',
                              groupName:
                                  GROUP_INTERACTIVE.name,
                          },
                    is360
                        ? undefined
                        : {
                              ...PLUGIN_NUMOFTHEDAY_CONTROL,
                              label: 'Цифра дня',
                              groupName:
                                  GROUP_INTERACTIVE.name,
                          },
                    {
                        ...PLUGIN_CAROUSEL_CONTROL,
                        label: 'Карусель',
                        groupName: GROUP_INTERACTIVE.name,
                    },
                    is360
                        ? {
                              ...PLUGIN_STORIES_LIST_CONTROL,
                              label: 'Лента историй',
                              groupName:
                                  GROUP_INTERACTIVE.name,
                          }
                        : undefined,
                    is360
                        ? undefined
                        : {
                              ...PLUGIN_ARTICLE_LIST_CONTROL,
                              label: ' Лента из новостей',
                              groupName:
                                  GROUP_INTERACTIVE.name,
                          },
                    {
                        ...PLUGIN_ADS_CONTROL,
                        label: 'Реклама',
                        groupName: GROUP_INTERACTIVE.name,
                    },
                ].filter((item) => item),
            },
        };

        if (!is360) {
            CONTROLS_OBJECT[GROUP_TEMPLATES.name] = {
                ...GROUP_TEMPLATES,
                list: [
                    {
                        ...PLUGIN_MAIN_TEMPLATE_CONTROL,
                        label: 'Главная секция',
                        groupName: GROUP_TEMPLATES.name,
                    },
                    {
                        ...PLUGIN_SECOND_TEMPLATE_CONTROL,
                        label: 'Шаблон 2',
                        groupName: GROUP_TEMPLATES.name,
                    },
                ],
            };
        }
        return (
            <Provider
                store={memoStore}
                // @ts-ignore
                context={smartGridEditorContext}
            >
                <GridInitializer
                    initialState={{
                        data:
                            props?.initialState?.data ?? {},
                        items:
                            props?.initialState?.items ||
                            [],
                    }}
                    onChange={(e) => {
                        props?.onChange?.(e);
                    }}
                    dependencyConfig={{
                        // @ts-ignore
                        controls: CONTROLS_OBJECT,
                        pluginElements: {
                            // GRID
                            ...PLUGIN_FLOW_ROW,
                            ...PLUGIN_FLOW_STACK,
                            // CONTENT
                            ...(is360
                                ? PLUGIN_360_AUTO_ARTICLE
                                : PLUGIN_RIAMO_AUTO_ARTICLE),
                            ...PLUGIN_THEMES,
                            ...PLUGIN_CATEGORIES,
                            ...(is360
                                ? PLUGIN_360_ARTICLE
                                : PLUGIN_RIAMO_ARTICLE),
                            ...PLUGIN_STORIES,
                            // ...PLUGIN_AUTHOR,
                            ...PLUGIN_LAST_NEWS,
                            ...PLUGIN_MOST_READ,
                            // INTERACTIVE
                            ...PLUGIN_HEADLINE,
                            ...PLUGIN_POTOKUS,
                            ...PLUGIN_NUMOFTHEDAY,
                            ...PLUGIN_CAROUSEL,
                            ...PLUGIN_STORIES_LIST,
                            ...PLUGIN_ARTICLE_LIST,
                            ...PLUGIN_ADS,
                            // TEMPLATES
                            ...PLUGIN_MAIN_TEMPLATE,
                            ...PLUGIN_SECOND_TEMPLATE,
                        },
                    }}
                />
            </Provider>
        );
    },
    areEqual,
);

function areEqual(
    prevProps: {
        initialState: any;
        onChange: (e: unknown) => void;
        isReadonly: boolean;
    },
    nextProps: {
        initialState: any;
        onChange: (e: unknown) => void;
        isReadonly: boolean;
    },
) {
    return isEqual(
        prevProps.initialState,
        nextProps.initialState,
    );
}

export const GridEditorField: React.FC<
    GridEditorFieldProps
> = (props) => {
    const { name, isReadonly } = props;
    const { values, setFieldValue } = useFormikContext();
    const { getItems } = useMainGridDispatch();
    const [field, , setters] = useField({
        name,
        type: 'text',
    });

    return (
        <GridEditor
            isReadonly={!!isReadonly}
            initialState={field.value}
            onChange={(e) => {
                if (typeof e === 'object') {
                    const value = e;
                    const thisIsPropertyIntoFormikContext =
                        typeof values === 'object' &&
                        values !== null &&
                        name in values;

                    // @ts-ignore
                    if (!!value.items.length) {
                        // @ts-ignore
                        const test = value.items;
                        // console.log(
                        //     test,
                        //     'item at value GRID EDITOR',
                        // );
                        getItems({
                            payload: {
                                // @ts-ignore
                                editor_data: value,
                            },
                        });
                    }

                    if (thisIsPropertyIntoFormikContext) {
                        setters.setValue(value);
                        setters.setTouched(true);
                    } else {
                        setFieldValue(field.name, value);
                        setters.setTouched(true);
                    }
                }
            }}
        />
    );
};
