import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgStory = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.295.333h1.407c.912 0 1.647 0 2.225.078.6.08 1.105.253 1.506.655.401.401.574.906.655 1.506.077.578.077 1.313.077 2.225v5.406c0 .912 0 1.647-.077 2.225-.081.6-.254 1.105-.655 1.507-.401.4-.906.573-1.506.654-.578.078-1.313.078-2.225.078H7.295c-.911 0-1.646 0-2.224-.078-.6-.08-1.105-.253-1.507-.654-.401-.402-.574-.907-.654-1.507-.078-.578-.078-1.313-.078-2.225V4.797c0-.912 0-1.647.078-2.225.08-.6.253-1.105.654-1.506.402-.402.907-.574 1.507-.655C5.649.333 6.384.333 7.295.333Zm-2.09 1.07c-.49.065-.75.185-.934.37-.184.184-.304.443-.37.933-.068.503-.069 1.17-.069 2.127v5.334c0 .957.001 1.624.069 2.128.066.489.186.748.37.932.185.185.444.305.933.371.504.068 1.171.069 2.128.069h1.333c.957 0 1.625-.001 2.128-.069.49-.066.748-.186.933-.37.184-.185.305-.444.37-.933.068-.504.07-1.171.07-2.128V4.834c0-.957-.002-1.625-.07-2.128-.065-.49-.185-.749-.37-.933-.185-.185-.444-.305-.933-.37-.503-.068-1.17-.07-2.128-.07H7.332c-.957 0-1.624.002-2.128.07ZM1.332 2.333a.5.5 0 0 1 .5.5v9.334a.5.5 0 1 1-1 0V2.833a.5.5 0 0 1 .5-.5ZM14.665 2.333a.5.5 0 0 1 .5.5v9.334a.5.5 0 1 1-1 0V2.833a.5.5 0 0 1 .5-.5Z"
            fill="currentColor"
        />
        <path
            d="M10.25 7.778 7.183 9.82a.333.333 0 0 1-.519-.277V5.456c0-.266.297-.425.519-.277l3.065 2.044a.333.333 0 0 1 0 .555Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgStory);
export default ForwardRef;
