import React, { useState } from 'react';

import {
    Button,
    Modal,
    Stack,
    Row,
    Loader,
    Typography,
    WithLabel,
} from '@mega/ui';
import {
    Caution,
    Confirmed,
    Error,
    Info,
} from '@mega/icons';

import { useField } from 'formik';
import { changePasswordModalClasses } from './ChangePasswordModal.css';
import { useEmployeeDispatch } from '@mega/store';
import { useParams } from 'react-router-dom';

const ModalContent = (closeModal) => {
    const { id } = useParams();
    const [modalStage, setModalStage] = useState<
        'confirmation' | 'loading' | 'result'
    >('confirmation');
    const [
        isChangePasswordSuccessfully,
        setIsChangePasswordSuccessfully,
    ] = useState<boolean>();
    const { resetPassword } = useEmployeeDispatch();

    const handleChangePassword = async (id: string) => {
        if (id) {
            setModalStage('loading');
            const result = await resetPassword({ id });
            if (result.value) {
                setModalStage('result');
                setIsChangePasswordSuccessfully(
                    result.isOk,
                );
            }
        }
    };
    const close = () => {
        setModalStage('confirmation');
        closeModal();
    };

    return (
        <div
            className={changePasswordModalClasses.container}
        >
            {modalStage === 'confirmation' ? (
                <>
                    <Caution
                        style={{
                            width: '80px',
                            height: '80px',
                            alignSelf: 'center',
                        }}
                    />
                    <Typography size="18" weight="bold">
                        {' '}
                        Пароль сотрудника будет изменен{' '}
                    </Typography>
                    <Typography
                        size="12"
                        weight="regular"
                        style={{
                            color: '#878787',
                        }}
                    >
                        Вы уверены?
                    </Typography>

                    <Row
                        gap="16"
                        className={
                            changePasswordModalClasses.rowButtons
                        }
                    >
                        <Button
                            label="Да"
                            onClick={() =>
                                handleChangePassword(id)
                            }
                            size="large"
                            className={changePasswordModalClasses.recipe(
                                {
                                    width: 'large',
                                    color: 'black',
                                },
                            )}
                        />

                        <Button
                            label="Нет"
                            onClick={close}
                            size="large"
                            className={changePasswordModalClasses.recipe(
                                {
                                    width: 'large',
                                    color: 'white',
                                },
                            )}
                        />
                    </Row>
                </>
            ) : null}
            {modalStage === 'loading' ? (
                <Stack gap="16">
                    <Typography
                        size="18"
                        weight="bold"
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        {' '}
                        <Loader />
                    </Typography>
                </Stack>
            ) : null}
            {modalStage === 'result' ? (
                <>
                    {isChangePasswordSuccessfully ? (
                        <Stack>
                            <Confirmed
                                style={{
                                    width: '80px',
                                    height: '80px',
                                    alignSelf: 'center',
                                    marginBottom: '10px',
                                }}
                            />
                            <Typography
                                size="24"
                                weight="bold"
                            >
                                Пароль успешно <br />
                                изменен
                            </Typography>
                        </Stack>
                    ) : (
                        <Stack gap="12">
                            <Error
                                style={{
                                    width: '80px',
                                    height: '80px',
                                    alignSelf: 'center',
                                }}
                            />
                            <Typography
                                weight="bold"
                                size="24"
                            >
                                Произошла ошибка
                            </Typography>
                            <Typography
                                weight="regular"
                                size="12"
                                style={{
                                    color: '#878787',
                                }}
                            >
                                Повторите попытку позже
                            </Typography>
                        </Stack>
                    )}

                    <Button
                        animate="primary"
                        size="large"
                        onClick={close}
                        className={changePasswordModalClasses.recipe(
                            {
                                color: 'black',
                            },
                        )}
                        label={'Закрыть'}
                        style={{
                            alignSelf: 'center',
                            width: '115px',
                        }}
                    ></Button>
                </>
            ) : null}
        </div>
    );
};
type ChangePasswordModalProps = {
    className?: string;
};

const ChangePasswordModal: React.FC<
    ChangePasswordModalProps
> = ({ className }): JSX.Element => {
    const [input] = useField('is_active');
    return (
        <WithLabel
            id={'change_password'}
            title={'Пароль'}
            className={className}
        >
            <div style={{ display: 'flex', gap: '8px' }}>
                <Modal
                    Trigger={
                        <Button
                            label={'Изменить пароль'}
                            size="large"
                            className={
                                changePasswordModalClasses.changePassworButton
                            }
                            disabled={!input.value}
                        />
                    }
                    Content={(handleClose) =>
                        ModalContent(handleClose)
                    }
                ></Modal>
                <Row
                    className={
                        changePasswordModalClasses.infoTextContainer
                    }
                >
                    <Info
                        style={{
                            width: '24px',
                            height: '24px',
                            alignSelf: 'center',
                        }}
                    />
                    <Typography
                        weight="regular"
                        size="12"
                        style={{
                            color: '#878787',
                        }}
                        as="div"
                    >
                        По нажатию кнопки пользователь
                        получит
                        <br /> новый пароль на указанный
                        email. Изменение пароля доступно для
                        активных пользователей.
                    </Typography>
                </Row>
            </div>
        </WithLabel>
    );
};

export { ChangePasswordModal };
