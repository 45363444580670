import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgArrowRight = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 18 18"
        fill="none"
        ref={ref}
        {...props}
    >
        <path
            d="m11.653 8.897-4.5 4.5-.806-.806 3.694-3.694-3.694-3.694.806-.806 4.5 4.5Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgArrowRight);
export default ForwardRef;
