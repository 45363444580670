import {
    createModel,
    RematchDispatch,
} from '@rematch/core';
import {
    definitions,
    mediashareApiClient,
} from '@mega/api';
import * as CLIENT from '@mega/api';
import { delay } from '@mega/utils';
import type { RootModel } from '../rootModel';
import {
    ApiError,
    Middleware,
} from 'openapi-typescript-fetch';
import {
    SnackbarKey,
    OptionsObject,
    SnackbarMessage,
} from 'notistack';

const MS_REFRESH_TOKEN_KEY = 'MS_REFRESH_TOKEN_KEY';

const snackbarMiddleware =
    (
        enqueueSnackbar: (
            message: SnackbarMessage,
            options?: OptionsObject,
        ) => SnackbarKey,
    ): Middleware =>
    async (url, init, next) => {
        try {
            const response = await next(url, init);
            return response;
        } catch (error) {
            if (error instanceof ApiError) {
                const { data, status } = error;
                if (status === 403)
                    enqueueSnackbar(`${data.detail}`, {
                        variant: 'error',
                    });
            } else {
                enqueueSnackbar('Request failed', {
                    variant: 'error',
                });
            }
            throw error;
        }
    };

const mediashareAuth = createModel<RootModel>()({
    name: 'mediashareAuth',
    state: {
        isAuth: false,
        refreshToken: '',
        accessToken: undefined,
    } as {
        isAuth: boolean;
        refreshToken: string;
        accessToken?: string;
    },
    reducers: {
        logined(_state) {
            return { ..._state, isAuth: true };
        },
        setTokens(
            _state,
            payload: {
                accessToken?: string;
                refreshToken: string;
            },
        ) {
            return { ..._state, ...payload };
        },
    },
    effects: (dispatch) => ({
        logout() {
            window.localStorage.clear();
            window.location.reload();
        },
        reinitApi: async (
            payload: definitions['TokenRefreshExpiresAt'],
        ) => {
            await dispatch.mediashareAuth.setTokens({
                accessToken: payload.access,
                refreshToken: payload.refresh,
            });
            CLIENT.mediashareApiClient.Init({
                accessToken: payload.access,
                refreshToken: payload.refresh,
            });
        },

        saveToken(
            payload: definitions['TokenRefreshExpiresAt'],
        ) {
            window.localStorage.setItem(
                MS_REFRESH_TOKEN_KEY,
                payload.refresh,
            );
        },

        async retry({ fn, count = 3 }): Promise<{
            isOk?: boolean;
            error?: any;
        }> {
            const refreshToken =
                window.localStorage.getItem(
                    MS_REFRESH_TOKEN_KEY,
                );

            let initCount = count;
            let response;
            while (initCount != 0) {
                const delayed = await delay(1000);
                if (delayed) {
                    response = await fn?.();
                    if (response?.isOk) {
                        initCount = 0;
                    } else {
                        const response =
                            await CLIENT.mediashareAuth.refreshToken?.(
                                {
                                    refresh: `${refreshToken}`,
                                },
                            );
                        if (response?.ok) {
                            await dispatch.mediashareAuth.reinitApi(
                                response.data,
                            );
                        }
                        initCount = initCount - 1;
                    }
                }
            }
            return response;
        },
        async stageInit() {
            const refreshToken =
                window.localStorage.getItem(
                    MS_REFRESH_TOKEN_KEY,
                );
            if (refreshToken) {
                try {
                    const response =
                        await CLIENT.mediashareAuth.refreshToken?.(
                            {
                                refresh: refreshToken,
                            },
                        );

                    if (response?.ok) {
                        await dispatch.mediashareAuth.reinitApi(
                            response.data,
                        );
                        await dispatch.mediashareAuth.saveToken(
                            response.data,
                        );
                        await dispatch.mediashareAuth.logined();
                        return { OK: true };
                    } else {
                        throw new Error(
                            response?.statusText ?? '',
                        );
                    }
                } catch (e) {
                    if (
                        CLIENT.mediashareAuth
                            .refreshToken &&
                        e instanceof
                            CLIENT.mediashareAuth
                                .refreshToken.Error
                    ) {
                        const error = e.getActualType();
                        switch (error.status) {
                            case 401: {
                                dispatch.mediashareAuth[401](
                                    error,
                                );
                            }
                        }
                        return { OK: false };
                    }
                }
            }
        },
        async stageLogin(payload: {
            email: string;
            password: string;
        }) {
            try {
                const response =
                    await CLIENT.mediashareAuth.getToken?.(
                        payload,
                    );
                if (response?.ok) {
                    await dispatch.mediashareAuth.reinitApi(
                        response.data,
                    );
                    await dispatch.mediashareAuth.saveToken(
                        response.data,
                    );
                    await dispatch.mediashareAuth.logined();
                }
            } catch (e) {
                console.log(e, 'error');

                if (
                    CLIENT.mediashareAuth.getToken &&
                    e instanceof
                        CLIENT.mediashareAuth.getToken.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            dispatch.mediashareAuth[401](
                                error,
                            );
                            return error.data;
                        }
                    }
                }
            }
        },
        async 401(error: {
            status: number;
            data: any;
        }): Promise<{
            isOk: boolean;
            message: string;
        }> {
            if (error.data?.code === 'token_not_valid') {
                const refreshToken =
                    window.localStorage.getItem(
                        MS_REFRESH_TOKEN_KEY,
                    );
                const response =
                    await CLIENT.mediashareAuth.refreshToken?.(
                        {
                            refresh: `${refreshToken}`,
                        },
                    );
                if (response?.ok) {
                    await dispatch.mediashareAuth.reinitApi(
                        response.data,
                    );
                    return {
                        isOk: true,
                        message: 'api reinit',
                    };
                }
            }

            return {
                isOk: false,
                message: 'reinit api not success',
            };
        },
    }),
});

export { mediashareAuth };
export default mediashareAuth;
