import React, { FC, useEffect, useState } from 'react';
import {
    useField,
    ErrorMessage,
    useFormikContext,
} from 'formik';
import {
    SelectSingle,
    SelectSingleProps,
} from './SelectSingle';
import { Options, Option } from '../types';

import { selectSingleClasses } from './SelectSingle.css';

export interface SelectSingleFieldProps
    extends SelectSingleProps {
    name: string;
    //   label?: string;
    placeholder?: string;
    isLabelHidden?: boolean;
    debounceTimeout?: number;
    handleClose?: () => void;
}

const transform = (item: {
    value: string | number;
    label: string;
}) => ({
    id: item.value,
    label: item.label,
});

const reTransform = (item: {
    id: string | number;
    label: string;
}) => ({
    value: item.id,
    label: item.label,
});

const SelectSingleField: FC<SelectSingleFieldProps> = ({
    name,
    handleLoader,
    handleClose,
    actionOnSelectedOption,
    ...props
}) => {
    const [defaultOptions, setDefaultOptions] =
        useState<Options>([]);
    const { values, setFieldValue } = useFormikContext();

    const [field, meta, setters] = useField({
        name,
    });
    const isError = Boolean(meta?.error && meta.touched);

    const handleOnChange = (option: Option) => {
        const value = option ? transform(option) : option;
        const thisIsPropertyIntoFormikContext =
            typeof values === 'object' &&
            values !== null &&
            name in values;

        if (thisIsPropertyIntoFormikContext) {
            setters.setValue(value);
            setters.setTouched(true);
        } else {
            setFieldValue(field.name, value);
            setters.setTouched(true);
        }
        if (actionOnSelectedOption)
            actionOnSelectedOption(option);
        if (handleClose) handleClose();
    };

    useEffect(() => {
        if (handleLoader) {
            (async () => {
                const def = await handleLoader('', 1);
                setDefaultOptions(def);
            })().catch(() => {});
        }
    }, []);

    return (
        <div>
            <SelectSingle
                {...props}
                handleLoader={handleLoader}
                key={field.name}
                actionOnSelectedOption={handleOnChange}
                defaultValue={
                    field?.value
                        ? reTransform(field?.value)
                        : props.defaultValue
                }
                isError={isError}
            />
            {isError ? (
                <div
                    className={
                        selectSingleClasses.errorMessage
                    }
                >
                    {meta.error}
                </div>
            ) : null}
        </div>
    );
};

export { SelectSingleField };
