/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
    createContext,
    FC,
    useContext,
} from 'react';
import { PreviewCore } from '@apostroph/types';

const dependencyContextPreview: PreviewCore = {
    rootElements: {},
    pluginElements: {},
};

const context = createContext<PreviewCore>(
    dependencyContextPreview,
);

const DependencyContextPreview: FC<{
    config: PreviewCore;
}> = ({ config, children }) => (
    <context.Provider value={{ ...config }}>
        {children}
    </context.Provider>
);

const useDependencyContextPreview = () =>
    useContext(context);

export {
    DependencyContextPreview,
    useDependencyContextPreview,
};
