import React, {
    useRef,
    useEffect,
    FC,
    useCallback,
    useState,
} from 'react';
import { delay } from '@mega/utils';
import { Loader } from '@mega/ui';

interface TriggerProps {
    onTrigger: (entry: IntersectionObserverEntry) => void;
    hasMore: boolean;
}

const Trigger: FC<TriggerProps> = ({
    onTrigger,
    hasMore,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (
                    entry.isIntersecting &&
                    entry.intersectionRatio >= 1 &&
                    hasMore
                ) {
                    onTrigger(entry);
                }
            },
            {
                threshold: 1.0,
            },
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => observer.disconnect();
    }, [onTrigger, hasMore]);

    return <div className="next" ref={ref} />;
};

interface InfiniteLoaderProps {
    nextPage: (payload: any) => Promise<void>;
    dataLength: number;
    page: number;
}

export const InfinityLoader: FC<InfiniteLoaderProps> = ({
    nextPage,
    dataLength,
    page,
}) => {
    const [load, setLoad] = useState(true);
    const [hasMore, sethasMore] = useState(true);

    useEffect(() => {
        if (dataLength === 0) {
            sethasMore(false);
        } else {
            if (dataLength % 20 !== 0) {
                sethasMore(false);
            } else {
                sethasMore(true);
            }
        }
    }, [dataLength]);

    const requestNextPage = async () => {
        setLoad(true);
        await delay(1000);
        nextPage({});
    };

    useEffect(() => {
        async function fetchPage() {
            await delay(1000);
            setLoad(false);
        }

        if (hasMore) {
            fetchPage();
        }
    }, [page]);

    if (load) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100px',
                }}
            >
                <Loader size="small" />
            </div>
        );
    }

    return (
        <Trigger
            onTrigger={requestNextPage}
            hasMore={hasMore}
        />
    );
};
