import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgArrowDown = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M12 15.463 6 9.526l1.075-1.063L12 13.336l4.925-4.873L18 9.526l-6 5.937Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgArrowDown);
export default ForwardRef;
