import { ReactNode } from 'react';
import * as classes from './FileCard.css';

export function FileCard({
    children,
}: {
    children: ReactNode | ReactNode;
}) {
    return (
        <div className={classes.fileCard}>{children}</div>
    );
}
