import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgVideocam = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M2.625 15.5c-.3 0-.563-.113-.788-.338a1.08 1.08 0 0 1-.337-.787v-9.75c0-.3.113-.563.337-.788a1.08 1.08 0 0 1 .788-.337h9.75c.3 0 .563.112.787.337.225.225.338.488.338.788v4.031l3-3v7.688l-3-3v4.031c0 .3-.113.563-.338.787a1.08 1.08 0 0 1-.787.338h-9.75Zm0-1.125h9.75v-9.75h-9.75v9.75Zm0 0v-9.75 9.75Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgVideocam);
export default ForwardRef;
