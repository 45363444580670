import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';

const useRepostStore = () => {
    const store = useStoreSelector(
        (state: RootState) => state.repost,
    );
    const loading = useStoreSelector(
        (state: RootState) => state.loading.effects.repost,
    );

    return {
        store,
        loading,
    };
};

const useRepostDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        get: dispatch.repost.get,
        put: dispatch.repost.put,
        create: dispatch.repost.create,
        update: dispatch.repost.update,
        remove: dispatch.repost.remove,
    };
};

export { useRepostStore, useRepostDispatch };
