import React from 'react';
import { Button } from '@mega/ui';
import { FiltersState, Option } from '../ArticlesFilters';
import { useArticleFilters } from '../useArticleFilters';

interface IMyArticlesProps {
    setFilterState: React.Dispatch<Partial<FiltersState>>;

    handleSelect: (
        type: string,
        e: Option | undefined,
    ) => void;
    current?: number | string;
    value: Option;
}

export const MyArticles = ({
    setFilterState,
    handleSelect,
    current,
    value,
}: IMyArticlesProps) => {
    const { authorsByIdCallback } = useArticleFilters();

    const handleClick = () => {
        if (current !== value.value) {
            setFilterState({ loading: true });
            authorsByIdCallback(+value.value, 1).then(
                (authors) =>
                    handleSelect('authors', authors[0]),
            );
        } else {
            handleSelect('authors', undefined);
        }
    };

    return (
        <Button
            size={'large'}
            label="Мои статьи"
            classes={{
                paper: {
                    variant: 'filled',
                    color:
                        current === value.value
                            ? 'green'
                            : 'gray',
                },
            }}
            onClick={handleClick}
        />
    );
};
