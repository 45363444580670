import React, { FC, ReactElement, useEffect } from 'react';

import {
    Navigate,
    Outlet,
    Route,
    Routes,
    useLocation,
    useMatch,
    useParams,
} from 'react-router-dom';

import { Header } from '@mega/core';
import {
    useAppIntializingInfo,
    useAuthStore,
    useSettings,
    useSiteDispatch,
    useSiteStore,
    useUserInfo,
} from '@mega/store';
import { MainLayout } from '@layouts';
import {
    CenterLayout,
    ContentLayout,
    Loader,
} from '@mega/ui';
import {
    CategoryDashboard,
    Dashboard,
    Login,
    Register,
} from '@pages';

import { mapPages } from './mapPages';

interface MainRouterProps {
    children?: ReactElement | ReactElement[];
    login?: boolean;
}

const RequireAuth: FC<{
    children?: ReactElement | ReactElement[];
}> = ({ children }) => {
    const { isAuth } = useAuthStore();
    let location = useLocation();

    if (!isAuth) {
        return (
            <Navigate
                to="/login"
                state={{ from: location }}
                replace
            />
        );
    }

    return <>{children}</>;
};

const SelectSites: FC<{
    children: ReactElement | ReactElement[];
}> = ({ children }) => {
    const { isMeInitializing } = useAppIntializingInfo();
    const { pinSite } = useSiteDispatch();

    const user = useUserInfo();
    const sites = user.me?.sites || [];
    const { siteslug } = useParams();
    const location = useLocation();
    const isSiteDashboard = useMatch('/dashboard');
    const matchSite = useMatch('/:siteslug');
    const isSite = Boolean(matchSite);
    const defaultSite = user?.sites?.[0]?.id
        ? `/${user?.sites?.[0]?.id}`
        : '';
    useEffect(() => {
        if (siteslug) {
            const currentSite = sites.find(
                (site: { id: string }) =>
                    site.id === siteslug,
            );
            if (currentSite) {
                pinSite(currentSite);
            }
        }
    }, [siteslug, pinSite, sites]);

    if (isSite || siteslug || isSiteDashboard) {
        return <>{children}</>;
    }
    if (isMeInitializing) {
        return (
            <div>
                <Loader />
            </div>
        );
    }
    if (!Boolean(defaultSite)) {
        return (
            <Navigate
                to={`/404`}
                state={{ from: location }}
                replace
            />
        );
    }

    return (
        <Navigate
            to={defaultSite}
            state={{ from: location }}
            replace
        />
    );
};

const Content = () => {
    const isSiteNav = useMatch('/:siteslug');
    const currentSite = useSiteStore();
    if (!Boolean(currentSite)) {
        return null;
    }
    return (
        <ContentLayout>
            <Header />
            {isSiteNav ? <CategoryDashboard /> : <Outlet />}
        </ContentLayout>
    );
};

const EntitiesOutlet: FC<{}> = () => {
    const location = useLocation();
    const { rootPermissions } = useUserInfo();
    const { entities, action } = useParams();
    const { permissionConfig } = useSettings();

    const isRootPermission = rootPermissions.includes(
        entities ?? '',
    );
    const isActionRoute = Boolean(action);

    const hasNoPermissionConfig =
        Object.keys(permissionConfig).length &&
        entities &&
        !permissionConfig?.[entities];

    if (!isRootPermission || hasNoPermissionConfig) {
        return (
            <Navigate
                to={`/404`}
                state={{ from: location }}
                replace
            />
        );
    }

    if (isActionRoute) {
        return <Outlet />;
    }

    return mapPages?.[entities ?? ''];
};

const ActionOutlet: FC<{}> = () => {
    const location = useLocation();
    const {
        siteslug,
        entities = '',
        action = '',
        id,
    } = useParams();
    const { subRootPermissions } = useUserInfo();
    const isSubPermission = Boolean(
        subRootPermissions?.[entities].includes(action),
    );

    if (!isSubPermission) {
        return (
            <Navigate
                to={`/404`}
                state={{ from: location }}
                replace
            />
        );
    }
    switch (action) {
        case 'add': {
            return (
                mapPages?.[`${entities}_add`] || (
                    <div>work in progress</div>
                )
            );
        }
        case 'view': {
            if (id) {
                return <Outlet />;
            }
            return (
                <Navigate
                    to={`/${siteslug}/${entities}`}
                    state={{ from: location }}
                    replace
                />
            );
        }
        case 'change': {
            if (id) {
                return <Outlet />;
            }
            return (
                <Navigate
                    to={`/${siteslug}/${entities}`}
                    state={{ from: location }}
                    replace
                />
            );
        }
        default: {
            return <Outlet />;
        }
    }
};

const EntityOutlet: FC<{}> = () => {
    const location = useLocation();
    const {
        siteslug = '',
        entities = '',
        action = '',
    } = useParams();

    switch (action) {
        case 'add': {
            return (
                <Navigate
                    to={`/${siteslug}/${entities}/${action}`}
                    state={{ from: location }}
                    replace
                />
            );
        }
        default: {
            return (
                mapPages?.[`${entities}_${action}`] ?? (
                    <div>work in progress</div>
                )
            );
        }
    }
};

export const MainRouter: FC<MainRouterProps> = () => {
    const { isAppInitializing } = useAppIntializingInfo();
    if (isAppInitializing) {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#efefef',
                }}
            >
                <Loader size={'large'} />
            </div>
        );
    }
    return (
        <MainLayout showDrawer={true}>
            <Routes>
                <Route
                    path="/"
                    element={
                        <RequireAuth>
                            <SelectSites>
                                <Outlet />
                            </SelectSites>
                        </RequireAuth>
                    }
                >
                    <Route
                        path="/dashboard"
                        element={
                            <Dashboard>
                                <div>WIP</div>
                            </Dashboard>
                        }
                    />
                    <Route
                        path=":siteslug"
                        element={<Content />}
                    >
                        <Route
                            path={`:entities`}
                            element={<EntitiesOutlet />}
                        >
                            <Route
                                path={`:action`}
                                element={<ActionOutlet />}
                            >
                                <Route
                                    path={`:id`}
                                    element={
                                        <EntityOutlet />
                                    }
                                >
                                    <Route
                                        path={`:field`}
                                        element={
                                            <EntityOutlet />
                                        }
                                    />
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                </Route>
                <Route
                    path="/login"
                    element={
                        <CenterLayout>
                            <Login />
                        </CenterLayout>
                    }
                />
                <Route
                    path="/register"
                    element={<Register />}
                />
                <Route
                    path="/404"
                    element={<div>Not Found</div>}
                />
            </Routes>
        </MainLayout>
    );
};
