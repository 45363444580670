import { PluginTemplate } from '@pages/smartGrid/types/blocks';
import { Control } from '@pages/smartGrid/types/core';

export enum AdsEnum {
    type = 'CONTENT_ADS',
}

export interface AdsProps {
    id: string;
    meta: AdsMeta;
    type: string;
    parentId: string | null;
}

export type AdsMeta = {
    label: string;
    dataId: string;
    body_script: string;
    height: number;
};

export interface AdsControl extends Control {
    meta: AdsMeta;
}

export type AdsPlugin = PluginTemplate<
    AdsEnum.type,
    AdsMeta
>;
export type AdsPluginDefault = Omit<AdsPlugin, 'id'>;
