import React, {
    FC,
    ReactElement,
    CSSProperties,
} from 'react';
import { contentLayoutClasses } from '@mega/styles';

export interface CenterProps {
    style?: CSSProperties;
    children?: ReactElement | ReactElement[];
}

const ContentLayout: FC<CenterProps> = ({
    children,
    ...props
}) => (
    <div
        className={contentLayoutClasses.content}
        {...props}
    >
        {children}
    </div>
);

export { ContentLayout };
