import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgAds = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M11.56 2.69A1.5 1.5 0 0 1 12 3.75v1.5H6v-1.5a1.5 1.5 0 0 1 1.5-1.5h3a1.5 1.5 0 0 1 1.06.44ZM15 5.25H3a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h12a1.5 1.5 0 0 0 1.5-1.5v-7.5a1.5 1.5 0 0 0-1.5-1.5Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M3.989 12.614h-.99L4.507 8.25h1.19L7.2 12.614h-.99L5.119 9.247h-.034l-1.095 3.367Zm-.062-1.715h2.335v.72H3.927v-.72ZM8.761 12.667c-.248 0-.473-.064-.675-.192a1.33 1.33 0 0 1-.477-.569c-.117-.251-.175-.56-.175-.924 0-.375.06-.687.181-.936a1.3 1.3 0 0 1 .482-.56c.201-.125.422-.188.662-.188a1 1 0 0 1 .458.094.881.881 0 0 1 .299.23c.076.091.134.18.174.269h.028V8.25h.905v4.363H9.73v-.524H9.69a1.17 1.17 0 0 1-.18.27.9.9 0 0 1-.301.22 1.035 1.035 0 0 1-.448.087Zm.288-.722a.58.58 0 0 0 .37-.12.756.756 0 0 0 .235-.338c.055-.145.083-.315.083-.51 0-.194-.027-.363-.08-.507a.728.728 0 0 0-.235-.332.596.596 0 0 0-.373-.117c-.15 0-.275.04-.377.121a.743.743 0 0 0-.232.337c-.053.143-.08.31-.08.498 0 .19.027.36.08.505a.78.78 0 0 0 .232.341.588.588 0 0 0 .377.122ZM14.093 10.274l-.831.051a.429.429 0 0 0-.092-.191.487.487 0 0 0-.185-.139.63.63 0 0 0-.27-.053c-.141 0-.26.03-.357.09-.096.058-.145.136-.145.234 0 .078.032.144.094.198.063.054.17.097.322.13l.592.12c.319.064.556.17.712.314a.743.743 0 0 1 .234.572.89.89 0 0 1-.19.564 1.23 1.23 0 0 1-.515.38c-.217.089-.468.134-.752.134-.433 0-.778-.09-1.036-.27a1.054 1.054 0 0 1-.45-.742l.894-.047a.458.458 0 0 0 .196.302.712.712 0 0 0 .398.103.71.71 0 0 0 .384-.092c.098-.063.147-.143.149-.24a.25.25 0 0 0-.105-.203.75.75 0 0 0-.315-.124l-.567-.113c-.32-.064-.557-.174-.713-.332a.825.825 0 0 1-.233-.603c0-.21.057-.391.17-.543.116-.152.277-.27.484-.352a1.99 1.99 0 0 1 .733-.124c.414 0 .739.088.976.263.239.174.378.412.418.713Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgAds);
export default ForwardRef;
