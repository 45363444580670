import React, { Fragment } from 'react';
import { Typography } from '@mega/ui';
import {
    styledInfoItem,
    styledSeparator,
    styleInfoWrapper,
} from './UnitInfo.css';

export interface UnitInfoProps {
    theme?: string;
    source?: string;
}

export const UnitInfo: React.FC<UnitInfoProps> = (
    props,
) => {
    const currentItemsList = [
        ...Object.values(props),
    ].filter((item) => item);

    return (
        <div className={styleInfoWrapper}>
            {currentItemsList?.map((item, idx) => (
                <Fragment key={idx}>
                    <div className={styledInfoItem}>
                        <Typography
                            size={'12'}
                            weight={'medium'}
                            mod={'uppercase'}
                        >
                            {item}
                        </Typography>
                    </div>
                    {currentItemsList.length - 1 !== idx ? (
                        <Typography
                            className={styledSeparator}
                            size={'12'}
                            weight={'medium'}
                        >
                            /
                        </Typography>
                    ) : null}
                </Fragment>
            ))}
        </div>
    );
};
