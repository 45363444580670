import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useField, useFormikContext } from 'formik';

import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { rowClasses } from '@mega/styles';
import { radioClasses } from './Radio.css';
import { Typography } from '@mega/ui';

export interface RadioProps {
    name: string;
    data: Array<{
        label: string | ReactElement;
        value: string;
        checked?: boolean;
    }>;
    classes?: Partial<typeof radioClasses>;
    className?: string;
    onChange?: (meta: {
        name: string;
        value: string | null;
    }) => void;
    disabled?: boolean;
    reinitialize?: boolean;
    value?: string | undefined;
}

export const Radio: FC<RadioProps> = ({
    onChange,
    data,
    name,
    disabled = false,
    classes: externalClasses,
    value: initValue,
    reinitialize = false,
    className,
}) => {
    const rootClasses = {
        ...radioClasses,
        ...externalClasses,
    };
    const [value, setChecked] = useState<
        string | undefined
    >(initValue);

    useEffect(() => {
        if (reinitialize) {
            setChecked(initValue);
        }
    }, [initValue]);
    const handleChange = (selectedValue: string) => {
        if (selectedValue === value) {
            setChecked(undefined);
            onChange?.({
                name,
                value: null,
            });
        } else {
            setChecked(selectedValue);
            onChange?.({
                name,
                value: selectedValue,
            });
        }
    };

    return (
        <RadioGroupPrimitive.Root
            className={[
                className,
                rowClasses.recipe({ gap: '8' }),
            ].join(' ')}
            name={name}
            value={value}
            onValueChange={handleChange}
        >
            {data.map(({ value: dataValue, label }) => {
                return (
                    <div
                        key={`${name}-${dataValue}`}
                        className={rootClasses.decor}
                    >
                        <RadioGroupPrimitive.Item
                            id={`${name}-${dataValue}`}
                            className={rootClasses.root({
                                theme: 'light',
                            })}
                            value={dataValue}
                        >
                            <RadioGroupPrimitive.Indicator>
                                <div
                                    className={
                                        rootClasses.indicator
                                    }
                                />
                            </RadioGroupPrimitive.Indicator>
                        </RadioGroupPrimitive.Item>
                        <label
                            className={rootClasses.label}
                            htmlFor={`${name}-${
                                dataValue ?? ''
                            }`}
                        >
                            <Typography
                                as={'span'}
                                size="16"
                            >
                                {label}
                            </Typography>
                        </label>
                    </div>
                );
            })}
        </RadioGroupPrimitive.Root>
    );
};

export interface RadioGroupFieldProps {
    name: string;
    data: Array<{
        label: string | ReactElement;
        value: string;
        checked?: boolean;
    }>;
}

export const RadioField: FC<RadioGroupFieldProps> = ({
    name,
    data,
}) => {
    const { values, setFieldValue } = useFormikContext();
    const [field, , setters] = useField({
        name,
        type: 'radio',
    });
    return (
        <Radio
            name={name}
            data={data}
            onChange={({ value }) => {
                const thisIsPropertyIntoFormilContext =
                    typeof values === 'object' &&
                    values !== null &&
                    name in values;

                if (thisIsPropertyIntoFormilContext) {
                    setters.setValue(value);
                    setters.setTouched(true);
                } else {
                    setFieldValue(field.name, value);
                }
            }}
        />
    );
};
