import React, {
    FC,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { DndFileLoaderProps } from './DndFileLoader.types';
import { motion } from 'framer-motion';
import { MdClose, MdContentCut } from 'react-icons/md';
import { dndFileLoaderClasses } from './DndFileLoader.css';
import { imgLoaderClasses } from '../ImgLoader/ImgLoader.css';
import { useDropzone } from 'react-dropzone';
import { debounce } from 'lodash-es';
import {
    CropModal,
    getOptionsCopyrights,
} from '@mega/core';
import {
    InputTextareaField,
    Loader,
    SelectSingle,
    Stack,
    WithLabel,
    Checkbox,
} from '@mega/ui';
import {
    useApostrophStore,
    useSiteStore,
} from '@mega/store';
import { getTypographedText, Modal } from '@mega/utils';
import { useSnackbar } from 'notistack';

const SelectCopyright: FC<{
    defaultValue?: {
        value: string | number;
        label: string;
    };
    defaultText?: {
        value: undefined;
        label: string;
    };
    handleChange?: (
        item: {
            label: string;
            value: number;
        } | null,
    ) => void;
    handleCreate?: (value: string) => void;
}> = ({
    defaultValue,
    defaultText,
    handleChange = () => {
        return;
    },
    handleCreate,
}) => {
    const site = useSiteStore();
    return (
        <WithLabel
            id={'article_types'}
            title="Источник"
            gap={'small'}
        >
            <SelectSingle
                isCreatable
                defaultValue={defaultValue}
                defaultText={defaultText}
                //@ts-ignore
                actionOnSelectedOption={handleChange}
                actionOnCreatedOption={handleCreate}
                handleLoader={getOptionsCopyrights({
                    site: site?.id || '',
                })}
                classes={{
                    paper: {
                        color: 'dark',
                    },
                    select: {
                        variant: 'secondary',
                    },
                }}
            />
        </WithLabel>
    );
};

function isValidHttpUrl(string: string) {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return (
        url.protocol === 'http:' ||
        url.protocol === 'https:'
    );
}

export const DndFileLoader: React.FC<DndFileLoaderProps> = (
    props,
) => {
    const {
        id,
        noData,
        onUpload,
        onUpdate,
        onDelete,
        children,
        description = '',
        updateDesc,
        isLoading,
        isCover,
        sourceText,
        copyright: copyrightProps,
        sourceUrl,
        updateSU,
        is_watermarked,
        updateIsWaterMarked,
        minWidth = 0,
        minHeight = 0,
        maxFileSizeMb,
        multiple = true,
        accept,
        updateSource,
        isFieldCopyright = true,
        isFieldSourceUrl = true,
        isFieldDescription = true,
        isInfographic = false,
        hasCrop = false,
    } = props;
    const { enqueueSnackbar } = useSnackbar();

    const [desc, setDesc] = useState('');

    const [copyright, setCopyright] = useState<{
        id?: number;
        label?: string;
    } | null>(copyrightProps ?? null);
    const [open, setOpen] = useState<boolean>(false);
    const [suIsUrl, setSuIsUrl] = useState<boolean | null>(
        true,
    );

    const deleteHandler = () => {
        if (onDelete) {
            onDelete(id);
        }
    };
    const { isFormatted } = useApostrophStore();

    const handleUpdateDesc = () => {
        if (id && updateDesc) {
            updateDesc(desc);
        }
    };

    const handleUpdateIsWaterMarked = () => {
        if (id && updateIsWaterMarked) {
            updateIsWaterMarked(id);
        }
    };

    const handleUpdateSourceURL = (
        newSourceURL: string | null,
    ) => {
        if (!id || !updateSU) {
            return;
        }

        if (!newSourceURL) {
            updateSU(null);
            setSuIsUrl(true);
        } else if (isValidHttpUrl(newSourceURL)) {
            updateSU(newSourceURL);
            setSuIsUrl(true);
        } else {
            setSuIsUrl(false);
        }
    };

    const delayedDesc = useCallback(
        debounce(handleUpdateDesc, 300),
        [desc],
    );

    const handleUpdateSource = ({
        copyright,
        sourceText,
    }: {
        copyright: any;
        sourceText: string | null;
    }) => {
        if (updateSource) {
            setCopyright(copyright);

            updateSource({
                copyright: copyright?.value ?? null,
                sourceText: sourceText,
            });
        }
    };

    const delayedUpdateSourceURL = useCallback(
        debounce(handleUpdateSourceURL, 300),
        [copyright],
    );

    useEffect(() => {
        if (noData) return;
        delayedDesc();
        return delayedDesc.cancel;
    }, [desc, delayedDesc]);

    useEffect(() => {
        if (noData) return;
        if (isFormatted) {
            setDesc(getTypographedText(desc));
        }
    }, [isFormatted, desc]);

    useEffect(() => {
        if (noData) return;
        setDesc(description);
    }, [id]);

    useEffect(() => {
        if (noData) return;
        if (!copyright && copyrightProps) {
            setCopyright(copyrightProps);
        }
    }, [copyrightProps]);

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            for (let file of acceptedFiles) {
                const type = file.type.split('/')?.[0];
                if (type === 'image') {
                    const image = new Image();
                    image.onload = () => {
                        if (
                            image.width < minWidth ||
                            image.height < minHeight
                        ) {
                            enqueueSnackbar(
                                `Минимальный размер изображения ${minWidth}x${minHeight}`,
                                {
                                    variant: 'error',
                                },
                            );
                            return;
                        }

                        if (
                            maxFileSizeMb &&
                            file.size >
                                maxFileSizeMb * 1024 * 1024
                        ) {
                            enqueueSnackbar(
                                `${file.name} превышает допустимый размер в ${maxFileSizeMb}Мб.`,
                                {
                                    variant: 'error',
                                },
                            );
                            return;
                        }

                        onUpload && onUpload(file);
                    };
                    image.src = URL.createObjectURL(file);
                } else {
                    onUpload && onUpload(file);
                }
            }
        },
        [id],
    );

    const { getRootProps, getInputProps, isDragActive } =
        useDropzone({
            accept,
            onDrop,
            multiple,
        });

    return (
        <div
            className={[
                dndFileLoaderClasses.base,
                dndFileLoaderClasses.recipe({
                    isActive: isDragActive,
                }),
            ].join(' ')}
        >
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                {children}
                {isLoading && (
                    <div
                        className={
                            dndFileLoaderClasses.loader
                        }
                    >
                        <Loader size="small" />
                    </div>
                )}
            </div>

            {updateIsWaterMarked && id && (
                <Checkbox
                    labelProps={{
                        size: '12',
                        weight: 'semiMeduim',
                    }}
                    iconSize="12"
                    theme="third"
                    name={String(id)}
                    checked={
                        is_watermarked
                            ? is_watermarked
                            : false
                    }
                    label="Наложить вотермарку"
                    value="is_watermarked_image"
                    onChange={handleUpdateIsWaterMarked}
                />
            )}

            {updateDesc && id && !noData && (
                <Stack gap={'8'}>
                    {isFieldCopyright && (
                        <SelectCopyright
                            defaultValue={
                                copyright
                                    ? {
                                          value:
                                              copyright?.id ||
                                              '',
                                          label:
                                              copyright?.label ||
                                              '',
                                      }
                                    : undefined
                            }
                            defaultText={
                                copyright
                                    ? undefined
                                    : {
                                          value: undefined,
                                          label:
                                              sourceText ||
                                              '',
                                      }
                            }
                            handleChange={(cp) => {
                                if (cp) {
                                    handleUpdateSource({
                                        copyright: cp,
                                        sourceText: null,
                                    });
                                } else {
                                    handleUpdateSource({
                                        copyright: null,
                                        sourceText: null,
                                    });
                                }
                            }}
                            handleCreate={(value) => {
                                handleUpdateSource({
                                    copyright: null,
                                    sourceText: value,
                                });
                            }}
                        />
                    )}

                    {isFieldSourceUrl && (
                        <WithLabel
                            id={'sourceURL'}
                            title="Источник URL"
                            gap={'small'}
                        >
                            <>
                                <InputTextareaField
                                    name="description"
                                    placeholderDefault={
                                        'http://'
                                    }
                                    color={'transparent'}
                                    dimension="none"
                                    verticalTextCenter
                                    value={sourceUrl}
                                    classes={{
                                        paper: {
                                            color: undefined,
                                            variant:
                                                undefined,
                                        },
                                    }}
                                    className={
                                        imgLoaderClasses.textField
                                    }
                                    onChange={(e) => {
                                        delayedUpdateSourceURL(
                                            e.target.value,
                                        );
                                    }}
                                />
                                {!suIsUrl && (
                                    <span
                                        style={{
                                            fontSize:
                                                '10px',
                                            color: 'red',
                                            fontFamily:
                                                'roboto',
                                        }}
                                    >
                                        Эта строка должна
                                        быть ссылкой
                                    </span>
                                )}
                            </>
                        </WithLabel>
                    )}

                    {isFieldDescription && (
                        <WithLabel
                            id={'description'}
                            title={
                                isInfographic
                                    ? 'Невидимое описание для поисковиков (alt)'
                                    : 'Описание'
                            }
                            gap={'small'}
                        >
                            <InputTextareaField
                                name="description"
                                placeholderDefault={
                                    'Подпись к фото'
                                }
                                color={'transparent'}
                                dimension="none"
                                verticalTextCenter
                                value={desc}
                                classes={{
                                    paper: {
                                        color: undefined,
                                        variant: undefined,
                                    },
                                }}
                                className={
                                    imgLoaderClasses.textField
                                }
                                onChange={(e) =>
                                    setDesc(e.target.value)
                                }
                            />
                        </WithLabel>
                    )}
                </Stack>
            )}

            {hasCrop &&
                id &&
                onUpdate &&
                updateIsWaterMarked && (
                    <>
                        <Modal
                            onClose={() => setOpen(false)}
                            open={open}
                        >
                            <CropModal
                                isLoading={isLoading}
                                id={id}
                                onUpdate={onUpdate}
                                setOpen={setOpen}
                                is_watermarked={
                                    is_watermarked
                                }
                                onUpdateIsWaterMarked={
                                    updateIsWaterMarked
                                }
                            />
                        </Modal>
                        <motion.div
                            onClick={() => setOpen(true)}
                            className={
                                dndFileLoaderClasses.cutIcon
                            }
                            whileHover={{
                                scale: 1.1,
                                transition: {
                                    duration: 0.2,
                                },
                            }}
                        >
                            <MdContentCut />
                        </motion.div>
                    </>
                )}

            {onDelete && id && (
                <motion.div
                    onClick={deleteHandler}
                    className={
                        dndFileLoaderClasses.closeIcon
                    }
                    whileHover={{
                        scale: 1.1,
                        transition: {
                            duration: 0.2,
                        },
                    }}
                >
                    <MdClose />
                </motion.div>
            )}
        </div>
    );
};
