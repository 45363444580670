/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Control } from '../../../../types/core';
import { Headline } from './Headline';
import {
    HeadlineEnum,
    HeadlinePluginDefault,
} from './Headline.types';

const PLUGIN_HEADLINE_NAME = HeadlineEnum.type;

const DEFAULT_HEADLINE: HeadlinePluginDefault = {
    type: PLUGIN_HEADLINE_NAME,
    meta: {},
    childrenElement: [],
};

const PLUGIN_HEADLINE = {
    [PLUGIN_HEADLINE_NAME]: {
        emptyObject: DEFAULT_HEADLINE,
        render: Headline,
    },
};

const PLUGIN_HEADLINE_CONTROL: Control = {
    name: PLUGIN_HEADLINE_NAME,
    label: 'Headline',
    tooltip: 'Создать заголовок',
    Icon: () => <></>,
};

export {
    DEFAULT_HEADLINE,
    PLUGIN_HEADLINE,
    PLUGIN_HEADLINE_CONTROL,
    PLUGIN_HEADLINE_NAME,
};
