import React, { useEffect } from 'react';
import { CopyrightEdit as CopyrightEditForm } from '@mega/forms';
import {
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';

import {
    useSiteStore,
    useCopyrightStore,
    useCopyrightDispatch,
} from '@mega/store';
import { useSnackbar } from 'notistack';
import { copyrights, Copyright } from '@mega/api';
import { FormikHelpers } from 'formik';

export const CopyrightEdit: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();

    const { id } = useParams();
    const { loading, store } = useCopyrightStore();
    const { get, update } = useCopyrightDispatch();
    const currentSite = useSiteStore();

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            get({ id: parseInt(id) });
        }
    }, [id, get]);

    if (loading.get.loading || !loading.get.success) {
        return <div>loading</div>;
    }

    let editedId: number;

    if (id) {
        editedId = parseInt(id);
    } else {
        throw new Error('The editable entity has no id.');
    }

    const handleUpdate = async (
        payload: Copyright,
        actions: FormikHelpers<Copyright>,
    ) => {
        await update({
            query: {
                id: editedId,
            },
            payload: {
                id: editedId,
                title: payload.title,
                is_active: payload.is_active,
            },
            options: {
                notifier: {
                    enqueueSnackbar,
                },
                router: {
                    navigate,
                    location,
                },
            },
        });
        actions.setSubmitting(false);
    };

    return (
        <CopyrightEditForm
            handleUpdate={handleUpdate}
            initialValue={store}
        />
    );
};
