import { Button, InputField, WithLabel } from '@mega/ui';
import React, { FC, useCallback } from 'react';
import { IInitValuesForm } from './config/config';
import { ArticleCover } from '@mega/core';
import { styles as s } from './NumOfTheDayForm.css';
import { useMainGridDispatch } from '@mega/store';

interface NumOfTheDayFormProps {
    values: IInitValuesForm;
    onSubmit: (
        e?: React.FormEvent<HTMLFormElement> | undefined,
    ) => void;
}

export const NumOfTheDayForm: FC<NumOfTheDayFormProps> = ({
    onSubmit,
    values,
}) => {
    const { setsettingsSmartGridIsOff } =
        useMainGridDispatch();

    const onOpen = useCallback(() => {
        setsettingsSmartGridIsOff(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClose = useCallback(() => {
        setsettingsSmartGridIsOff(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={s.listFields}>
            <div className={s.imageFieldIn()}>
                <WithLabel
                    id={'image_cover'}
                    title="Изображение"
                >
                    <ArticleCover
                        id={'cover'}
                        isFieldCopyright={false}
                        isFieldSourceUrl={false}
                        isFieldDescription={false}
                        onOpen={onOpen}
                        onClose={onClose}
                    />
                </WithLabel>
            </div>
            <InputField
                name="count"
                placeholder="Цифра"
                dimension="medium"
                type="text"
                isError
            />
            <InputField
                name="title"
                placeholder="Описание"
                dimension="medium"
                type="text"
                isError
            />
            <InputField
                name="url"
                placeholder="https://"
                dimension="medium"
                type="text"
                isError
            />
            <Button
                type={'button'}
                label={'Сохранить'}
                onClick={() => onSubmit()}
                classes={{
                    paper: {
                        color: 'dark',
                        variant: 'filled',
                    },
                }}
            />
        </div>
    );
};
