import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgTableDeleteColumn = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="m14.25 10.653 1.5 1.5V8.017l-1.5 1.5v1.136ZM9.75 9.689l-1.5-1.5v3.792l1.5-1.5V9.69Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.25 19A2.75 2.75 0 0 0 5 21.75h14A2.75 2.75 0 0 0 21.75 19V5A2.75 2.75 0 0 0 19 2.25H5A2.75 2.75 0 0 0 2.25 5v14Zm12 1.25h-4.5v-6.233l-1.5 1.5v4.733H5c-.69 0-1.25-.56-1.25-1.25V5c0-.69.56-1.25 1.25-1.25h3.25v.904l1.5 1.5V3.75h4.5v2.231l1.5-1.5V3.75H19c.69 0 1.25.56 1.25 1.25v14c0 .69-.56 1.25-1.25 1.25h-3.25v-4.561l-1.5-1.5v6.061Z"
            fill="currentColor"
        />
        <path
            d="M10.853 10.085 6.468 14.47l1.061 1.06 4.385-4.384 4.384 4.384 1.06-1.06-4.384-4.385 4.384-4.383-1.061-1.06-4.383 4.383L7.53 4.64 6.47 5.702l4.383 4.383Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgTableDeleteColumn);
export default ForwardRef;
