/* eslint-disable no-param-reassign */
import React, {
    FC,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { createPortal } from 'react-dom';

import { TextField, Tooltip } from '@mui/material';

import {
    $createParagraphNode,
    $getRoot,
    $getSelection,
    $isRangeSelection,
    $isTextNode,
    COMMAND_PRIORITY_EDITOR,
    COMMAND_PRIORITY_LOW,
    createCommand,
    createEditor,
    ElementNode,
    FORMAT_ELEMENT_COMMAND,
    FORMAT_TEXT_COMMAND,
    GridSelection,
    LexicalCommand,
    LexicalEditor,
    LexicalNode,
    NodeSelection,
    RangeSelection,
    SELECTION_CHANGE_COMMAND,
    TextNode,
    $isElementNode,
} from 'lexical';
import { $isDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode';

import {
    $createHeadingNode,
    $isHeadingNode,
    $isQuoteNode,
} from '@lexical/rich-text';
import {
    $getNearestBlockElementAncestorOrThrow,
    $getNearestNodeOfType,
    mergeRegister,
} from '@lexical/utils';
import Typograf from 'typograf';

import { LexClasses } from '@apostroph/styles';
import { $isCodeHighlightNode } from '@lexical/code';
import {
    $isLinkNode,
    TOGGLE_LINK_COMMAND,
} from '@lexical/link';
import {
    $isListNode,
    $isListItemNode,
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
    ListNode,
    REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
    $isAtNodeEnd,
    $setBlocksType,
} from '@lexical/selection';
import {
    Button,
    IconButton,
    Paper,
    Typography,
    WithLabel,
} from '@mega/ui';
import {
    ContentCut as ContentCutIcon,
    FormatBold as FormatBoldIcon,
    FormatItalic as FormatItalicIcon,
    FormatListBulleted as FormatListBulletedIcon,
    FormatListNumbered as FormatListNumberedIcon,
    FormatUnderlined as FormatUnderlinedIcon,
    Link as LinkIcon,
} from '@mui/icons-material';

import { ContentCutCommand } from '../../type';
import { getTypographText } from './TypographAllPlugin';
import { useLexDataContext } from '../../context/lexData.context';
import { Eraser } from '@mega/icons';

function positionEditorElement(
    editor: HTMLDivElement,
    rect: any,
) {
    if (rect === null) {
        editor.style.opacity = '0';
        editor.style.top = '-1000px';
        editor.style.left = '-1000px';
    } else {
        editor.style.opacity = '1';
        editor.style.top = `${
            rect.top + rect.height + window.pageYOffset + 10
        }px`;
        editor.style.left = `${
            rect.left +
            window.pageXOffset -
            editor.offsetWidth / 2 +
            rect.width / 2
        }px`;
    }
}

function setPopupPosition(
    editor: HTMLElement,
    rect: ClientRect,
    rootElementRect: ClientRect,
): void {
    let top = rect.top - 8 + window.pageYOffset;
    let left =
        rect.left +
        50 +
        window.pageXOffset -
        editor.offsetWidth +
        rect.width;
    if (
        left +
            editor.offsetWidth +
            50 +
            editor.clientWidth >
        rootElementRect.right
    ) {
        left =
            rect.right -
            editor.offsetWidth -
            editor.clientWidth +
            50;
        top = rect.top + window.pageYOffset;
    }
    if (left < 0) {
        left = rect.left;
        top = rect.bottom;
    }
    if (rect.width >= rootElementRect.width - 25) {
        left = rect.left;
        top = rect.top + window.pageYOffset - 60;
    }
    if (top < rootElementRect.top) {
        top = rect.bottom;
    }

    if (rect.y < 90) {
        top = rect.bottom + 50 + window.pageYOffset;
    }

    if (rect.left > rootElementRect.left) {
        if (rect.height > 20) {
            top = rect.top - 50 + window.pageYOffset;
        } else {
            top = window.pageYOffset + rect.top - 8;
        }
    }

    editor.style.opacity = '1';
    editor.style.top = `${top}px`;
    editor.style.left = `${left}px`;
}

const TYPOGRAPHY_COMMAND: LexicalCommand<string> =
    createCommand();
const CONTENT_CUT_COMMAND: LexicalCommand<string> =
    createCommand();

function TextFormatFloatingToolbar({
    editor,
    isLink,
    isBold,
    isItalic,
    isUnderline,
    blockType,
}: {
    editor: LexicalEditor;
    isBold: boolean;
    isItalic: boolean;
    isLink: boolean;
    isUnderline: boolean;
    blockType: string;
}): JSX.Element {
    const { isLead } = useLexDataContext();
    const isParagraph = blockType !== 'paragraph';
    const isH1 = blockType !== 'h1';
    const isH2 = blockType !== 'h2';
    const isH3 = blockType !== 'h3';
    const isBullet = blockType !== 'bullet';
    const isNumber = blockType !== 'number';
    const [activeEditor, setActiveEditor] =
        useState(editor);
    const popupCharStylesEditorRef =
        useRef<HTMLDivElement | null>(null);

    const insertLink = useCallback(() => {
        if (!isLink) {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, '');
        } else {
            editor.dispatchCommand(
                TOGGLE_LINK_COMMAND,
                null,
            );
        }
    }, [editor, isLink]);

    const updateTextFormatFloatingToolbar =
        useCallback(() => {
            const selection = $getSelection();

            const popupCharStylesEditorElem =
                popupCharStylesEditorRef.current;
            const nativeSelection = window.getSelection();

            if (popupCharStylesEditorElem === null) {
                return;
            }

            const rootElement = editor.getRootElement();
            if (
                selection !== null &&
                nativeSelection !== null &&
                !nativeSelection.isCollapsed &&
                rootElement !== null &&
                rootElement.contains(
                    nativeSelection.anchorNode,
                )
            ) {
                const domRange =
                    nativeSelection.getRangeAt(0);
                const rootElementRect =
                    rootElement.getBoundingClientRect();
                let rect;

                if (
                    nativeSelection.anchorNode ===
                    rootElement
                ) {
                    let inner = rootElement;

                    while (
                        inner.firstElementChild != null
                    ) {
                        inner =
                            inner.firstElementChild as HTMLElement;
                    }
                    rect = inner.getBoundingClientRect();
                } else {
                    rect = domRange.getBoundingClientRect();
                }

                setPopupPosition(
                    popupCharStylesEditorElem,
                    rect,
                    rootElementRect,
                );
            }
        }, [editor]);

    useEffect(() => {
        const onResize = () => {
            editor.getEditorState().read(() => {
                updateTextFormatFloatingToolbar();
            });
        };
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [editor, updateTextFormatFloatingToolbar]);

    useEffect(() => {
        editor.getEditorState().read(() => {
            updateTextFormatFloatingToolbar();
        });
        return mergeRegister(
            editor.registerUpdateListener(
                ({ editorState }) => {
                    editorState.read(() => {
                        updateTextFormatFloatingToolbar();
                    });
                },
            ),

            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    updateTextFormatFloatingToolbar();
                    return false;
                },
                COMMAND_PRIORITY_LOW,
            ),
        );
    }, [editor, updateTextFormatFloatingToolbar]);

    const formatParagraph = () => {
        if (isParagraph) {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $setBlocksType(selection, () =>
                        $createParagraphNode(),
                    );
                }
            });
        }
    };

    const formatHeading = (
        headingSize: 'h1' | 'h2' | 'h3',
    ) => {
        if (isH1 || isH2 || isH3) {
            editor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection)) {
                    $setBlocksType(selection, () =>
                        $createHeadingNode(headingSize),
                    );
                }
            });
        }
    };

    const formatBulletList = () => {
        if (isBullet) {
            editor.dispatchCommand(
                INSERT_UNORDERED_LIST_COMMAND,
                undefined,
            );
        } else {
            editor.dispatchCommand(
                REMOVE_LIST_COMMAND,
                undefined,
            );
        }
    };

    const formatNumberedList = () => {
        if (isNumber) {
            editor.dispatchCommand(
                INSERT_ORDERED_LIST_COMMAND,
                undefined,
            );
        } else {
            editor.dispatchCommand(
                REMOVE_LIST_COMMAND,
                undefined,
            );
        }
    };

    const clearFormatting = useCallback(() => {
        activeEditor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                const anchor = selection.anchor;
                const focus = selection.focus;
                const nodes = selection.getNodes();

                const startOffset = Math.min(
                    anchor.offset,
                    focus.offset,
                );
                const endOffset =
                    Math.max(anchor.offset, focus.offset) -
                    startOffset;

                if (
                    anchor.key === focus.key &&
                    anchor.offset === focus.offset
                ) {
                    return;
                }

                nodes.forEach((node, idx) => {
                    if ($isElementNode(node)) {
                        activeEditor.dispatchCommand(
                            FORMAT_ELEMENT_COMMAND,
                            'left',
                        );
                    }

                    if ($isHeadingNode(node)) {
                        node.replace(
                            $createParagraphNode(),
                            true,
                        );
                    }

                    if ($isQuoteNode(node)) {
                        node.replace(
                            $createParagraphNode(),
                            true,
                        );
                    }

                    if ($isLinkNode(node)) {
                        activeEditor.dispatchCommand(
                            TOGGLE_LINK_COMMAND,
                            null,
                        );
                    }

                    if ($isListItemNode(node)) {
                        activeEditor.dispatchCommand(
                            REMOVE_LIST_COMMAND,
                            undefined,
                        );
                    }

                    if ($isTextNode(node)) {
                        if (
                            idx === 0 &&
                            startOffset !== 0
                        ) {
                            node =
                                node.splitText(
                                    startOffset,
                                )[1] || node;
                        }
                        if (idx === nodes.length - 1) {
                            node =
                                node.splitText(
                                    endOffset,
                                )[0] || node;
                        }

                        if (node.__style !== '') {
                            node.setStyle('');
                        }

                        if (node.__format !== 0) {
                            node.setFormat(0);
                            const block =
                                $getNearestBlockElementAncestorOrThrow(
                                    node,
                                );
                            block.setFormat('');
                        }
                    } else if (
                        $isDecoratorBlockNode(node)
                    ) {
                        node.setFormat('');
                    }
                });
            }
        });
    }, [activeEditor]);

    return (
        <div
            ref={popupCharStylesEditorRef}
            style={{
                display: 'flex',
                marginBottom: '1px',
                padding: '4px',
                verticalAlign: 'middle',
                position: 'absolute',
                zIndex: '10',
                top: '-10000px',
                left: '-10000px',
                marginTop: '-6px',
                opacity: '0',
                borderRadius: '8px',
                transition: 'opacity 0.5s',
                height: '10px',
                width: '10px',
            }}
        >
            <Paper
                color={'dark'}
                variant={'filled'}
                contentHeight
                classes={{ padding: { size: '8x8' } }}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: 'min-content',
                    gap: '8px',
                }}
            >
                <Tooltip title="Параграф">
                    <span>
                        <IconButton
                            onClick={() => {
                                formatParagraph();
                            }}
                            classes={{
                                paper: {
                                    color: 'dark',
                                    variant: 'filled',
                                },
                            }}
                        >
                            <Typography
                                mod="uppercase"
                                size="16"
                            >
                                p
                            </Typography>
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title="Заголовок">
                    <span>
                        <IconButton
                            onClick={() => {
                                formatHeading('h2');
                            }}
                            classes={{
                                paper: {
                                    color: 'dark',
                                    variant: 'filled',
                                },
                            }}
                        >
                            <Typography
                                mod="uppercase"
                                size="16"
                            >
                                h2
                            </Typography>
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title="Подзаголовок">
                    <span>
                        <IconButton
                            onClick={() => {
                                formatHeading('h3');
                            }}
                            classes={{
                                paper: {
                                    color: 'dark',
                                    variant: 'filled',
                                },
                            }}
                        >
                            <Typography
                                mod="uppercase"
                                size="16"
                            >
                                h3
                            </Typography>
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title="Маркеры">
                    <span>
                        <IconButton
                            onClick={() => {
                                formatBulletList();
                            }}
                            classes={{
                                paper: {
                                    color: 'dark',
                                    variant: 'filled',
                                },
                            }}
                        >
                            <FormatListBulletedIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title="Нумерация">
                    <span>
                        <IconButton
                            onClick={() => {
                                formatNumberedList();
                            }}
                            classes={{
                                paper: {
                                    color: 'dark',
                                    variant: 'filled',
                                },
                            }}
                        >
                            <FormatListNumberedIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title="Полужирный">
                    <span>
                        <IconButton
                            color="secondary"
                            onClick={() => {
                                editor.dispatchCommand(
                                    FORMAT_TEXT_COMMAND,
                                    'bold',
                                );
                            }}
                            aria-label="Format text as bold"
                        >
                            <FormatBoldIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title="Курсив">
                    <span>
                        <IconButton
                            color="secondary"
                            onClick={() => {
                                editor.dispatchCommand(
                                    FORMAT_TEXT_COMMAND,
                                    'italic',
                                );
                            }}
                            aria-label="Format text as italics"
                        >
                            <FormatItalicIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title="Подчеркнутый">
                    <span>
                        <IconButton
                            color="secondary"
                            onClick={() => {
                                editor.dispatchCommand(
                                    FORMAT_TEXT_COMMAND,
                                    'underline',
                                );
                            }}
                            aria-label="Format text to underlined"
                        >
                            <FormatUnderlinedIcon />
                        </IconButton>
                    </span>
                </Tooltip>

                <Tooltip title="Ссылка">
                    <span>
                        <IconButton
                            color="secondary"
                            onClick={insertLink}
                            aria-label="Insert link"
                        >
                            <LinkIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title="Очистить форматирование">
                    <span>
                        <IconButton
                            color="secondary"
                            onClick={clearFormatting}
                            aria-label="Typography"
                        >
                            <Eraser />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title="Типографика">
                    <span>
                        <IconButton
                            color="secondary"
                            onClick={() => {
                                editor.dispatchCommand(
                                    TYPOGRAPHY_COMMAND,
                                    'lel',
                                );
                            }}
                            aria-label="Typography"
                        >
                            <span>A</span>
                        </IconButton>
                    </span>
                </Tooltip>

                {isLead ? (
                    <></>
                ) : (
                    <IconButton
                        color="secondary"
                        onClick={() => {
                            editor.dispatchCommand(
                                CONTENT_CUT_COMMAND,
                                'lel',
                            );
                        }}
                        aria-label="Cut block"
                    >
                        <ContentCutIcon />
                    </IconButton>
                )}
            </Paper>
        </div>
    );
}

function getSelectedNode(
    selection: RangeSelection,
): TextNode | ElementNode {
    const { anchor } = selection;
    const { focus } = selection;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
        return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
        return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    }
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
}

function FloatingLinkEditor({
    editor,
    onFocus = () => null,
    isLink,
}: {
    editor: LexicalEditor;
    onFocus?: (e: boolean) => void;
    isLink: boolean;
}): JSX.Element {
    const editorRef = useRef<HTMLDivElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [linkUrl, setLinkUrl] = useState('');
    const [isEditMode, setEditMode] = useState(false);
    const [lastSelection, setLastSelection] = useState<
        | RangeSelection
        | NodeSelection
        | GridSelection
        | null
    >(null);

    const insertLink = useCallback(() => {
        if (!isLink) {
            editor.dispatchCommand(
                TOGGLE_LINK_COMMAND,
                'https://',
            );
        } else {
            editor.dispatchCommand(
                TOGGLE_LINK_COMMAND,
                null,
            );
        }
    }, [editor, isLink]);

    const updateLinkEditor = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent)) {
                setLinkUrl(parent.getURL());
            } else if ($isLinkNode(node)) {
                setLinkUrl(node.getURL());
            } else {
                setLinkUrl('');
            }
        }
        const editorElem = editorRef.current;
        const nativeSelection = window.getSelection();
        const { activeElement } = document;

        if (editorElem === null) {
            return;
        }

        const rootElement = editor.getRootElement();

        if (
            selection !== null &&
            rootElement !== null &&
            nativeSelection !== null
        ) {
            if (
                !nativeSelection.isCollapsed &&
                rootElement.contains(
                    nativeSelection.anchorNode,
                )
            ) {
                const domRange =
                    nativeSelection.getRangeAt(0);
                let rect;
                if (
                    nativeSelection.anchorNode ===
                    rootElement
                ) {
                    let inner = rootElement;
                    while (
                        inner.firstElementChild != null
                    ) {
                        inner =
                            inner.firstElementChild as HTMLElement;
                    }
                    rect = inner.getBoundingClientRect();
                } else {
                    rect = domRange.getBoundingClientRect();
                }
                positionEditorElement(editorElem, rect);
                setLastSelection(selection);
            }
        } else if (
            !activeElement ||
            activeElement.className !== 'link-input'
        ) {
            positionEditorElement(editorElem, null);
            setLastSelection(null);
            setEditMode(false);
            setLinkUrl('');
        }
    }, [editor]);

    useEffect(() => {
        const onResize = () => {
            editor.getEditorState().read(() => {
                updateLinkEditor();
            });
        };
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [editor, updateLinkEditor]);

    useEffect(
        () =>
            mergeRegister(
                editor.registerUpdateListener(
                    ({ editorState }) => {
                        editorState.read(() => {
                            updateLinkEditor();
                        });
                    },
                ),

                editor.registerCommand(
                    SELECTION_CHANGE_COMMAND,
                    () => {
                        updateLinkEditor();
                        return true;
                    },
                    COMMAND_PRIORITY_LOW,
                ),
            ),
        [editor, updateLinkEditor],
    );

    useEffect(() => {
        editor.getEditorState().read(() => {
            updateLinkEditor();
        });
    }, [editor, updateLinkEditor]);

    useEffect(() => {
        if (isEditMode && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isEditMode]);

    return (
        <div
            ref={editorRef}
            className={LexClasses.floatingInput}
            onMouseEnter={() => onFocus(true)}
            onMouseLeave={() => {
                if (isEditMode) {
                } else {
                    onFocus(false);
                }
            }}
        >
            <Paper
                color={'dark'}
                variant={'filled'}
                classes={{ padding: { size: '8x8' } }}
            >
                {isEditMode ? (
                    <TextField
                        autoFocus
                        ref={inputRef}
                        value={linkUrl}
                        label="Нажмите enter чтобы добавить или выйти"
                        size="small"
                        style={{
                            width: '400px',
                            color: 'white !important',
                        }}
                        onChange={(event) => {
                            setLinkUrl(event.target.value);
                        }}
                        variant="filled"
                        InputLabelProps={{
                            style: {
                                color: 'white',
                            },
                        }}
                        InputProps={{
                            style: {
                                color: 'white',
                            },
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                event.preventDefault();
                                if (
                                    lastSelection !== null
                                ) {
                                    if (linkUrl !== '') {
                                        editor.dispatchCommand(
                                            TOGGLE_LINK_COMMAND,
                                            linkUrl,
                                        );
                                    } else {
                                        editor.dispatchCommand(
                                            TOGGLE_LINK_COMMAND,
                                            null,
                                        );
                                    }
                                    setEditMode(false);
                                    onFocus(false);
                                }
                            } else if (
                                event.key === 'Escape'
                            ) {
                                event.preventDefault();
                                setEditMode(false);
                            }
                        }}
                    />
                ) : (
                    <div ref={editorRef}>
                        <WithLabel
                            title="Редактирование ссылки"
                            style={{ color: 'white' }}
                            gap="small"
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '10px',
                                }}
                            >
                                <Button
                                    style={{
                                        maxWidth: '250px',
                                    }}
                                    className="link-edit"
                                    tabIndex={0}
                                    onMouseDown={(event) =>
                                        event.preventDefault()
                                    }
                                    onClick={() => {
                                        setEditMode(true);
                                    }}
                                    label={
                                        linkUrl !== ''
                                            ? linkUrl
                                            : 'Вставьте ссылку'
                                    }
                                />

                                <Button
                                    className="link-remove"
                                    tabIndex={0}
                                    onClick={insertLink}
                                    label={'Удалить'}
                                />
                            </div>
                        </WithLabel>
                    </div>
                )}
            </Paper>
        </div>
    );
}

function useTextFormatFloatingToolbar(
    editor: LexicalEditor,
): JSX.Element | null {
    const [isText, setIsText] = useState(false);
    const [isLink, setIsLink] = useState(false);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [selectedElementKey, setSelectedElementKey] =
        useState(null);
    const [blockType, setBlockType] = useState('paragraph');
    const [linkEditorFocus, setLinkEditorFocus] =
        useState(false);

    const updatePopup = useCallback(() => {
        editor.getEditorState().read(() => {
            const selection = $getSelection();
            const nativeSelection = window.getSelection();
            const rootElement = editor.getRootElement();

            if (
                nativeSelection !== null &&
                (!$isRangeSelection(selection) ||
                    rootElement === null ||
                    !rootElement.contains(
                        nativeSelection.anchorNode,
                    ))
            ) {
                setIsText(false);
                return;
            }

            if (!$isRangeSelection(selection)) {
                return;
            }

            const node = getSelectedNode(selection);

            // Update text format
            setIsBold(selection.hasFormat('bold'));
            setIsItalic(selection.hasFormat('italic'));
            setIsUnderline(
                selection.hasFormat('underline'),
            );

            // Update links
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
            } else {
                setIsLink(false);
            }

            if (
                !$isCodeHighlightNode(
                    selection.anchor.getNode(),
                ) &&
                selection.getTextContent() !== ''
            ) {
                setIsText($isTextNode(node));
                setLinkEditorFocus($isLinkNode(node));
            } else {
                setIsText(false);
            }
            const anchorNode = selection.anchor.getNode();
            const element =
                anchorNode.getKey() === 'root'
                    ? anchorNode
                    : anchorNode.getTopLevelElementOrThrow();
            const elementKey = element.getKey();
            const elementDOM =
                editor.getElementByKey(elementKey);
            if (elementDOM !== null) {
                // @ts-ignore
                setSelectedElementKey(elementKey);
                if ($isListNode(element)) {
                    const parentList =
                        $getNearestNodeOfType<ListNode>(
                            anchorNode,
                            ListNode,
                        );
                    const type = parentList
                        ? parentList.getListType()
                        : element.getListType();
                    setBlockType(type);
                } else {
                    const type = $isHeadingNode(element)
                        ? element.getTag()
                        : element.getType();
                    setBlockType(type);
                }
            }
        });
    }, [editor]);

    useEffect(() => {
        document.addEventListener(
            'selectionchange',
            updatePopup,
        );
        return () => {
            document.removeEventListener(
                'selectionchange',
                updatePopup,
            );
        };
    }, [updatePopup]);

    useEffect(
        () =>
            editor.registerUpdateListener(() => {
                updatePopup();
            }),
        [editor, updatePopup],
    );

    return (
        <>
            {!isText || isLink
                ? null
                : createPortal(
                      <TextFormatFloatingToolbar
                          editor={editor}
                          isLink={isLink}
                          isBold={isBold}
                          isItalic={isItalic}
                          isUnderline={isUnderline}
                          blockType={blockType}
                      />,
                      document.body,
                  )}
            {(isText || linkEditorFocus) && isLink
                ? createPortal(
                      <FloatingLinkEditor
                          editor={editor}
                          onFocus={(e) => {
                              setLinkEditorFocus(e);
                          }}
                          isLink={isLink}
                      />,
                      document.body,
                  )
                : null}
        </>
    );
}

const tp = new Typograf({ locale: ['ru', 'en-US'] });
tp.disableRule('common/symbols/cf');

const nodeSerelization = (
    node:
        | LexicalNode
        | LexicalNode[]
        | ElementNode
        | ElementNode[],
): any => {
    if (Array.isArray(node)) {
        return node.map((item) => nodeSerelization(item));
    } else {
        const children = node?.getChildren?.() || null;

        if (children) {
            if (children.length !== 0) {
                let serialized = node.exportJSON();
                if (serialized.hasOwnProperty('children')) {
                    serialized.children =
                        nodeSerelization(children);
                    return serialized;
                }
            } else {
                return node.exportJSON();
            }
        } else {
            return node.exportJSON();
        }
    }
};

interface TextFormatFloatingToolbarPluginProps
    extends ContentCutCommand {}

export const TextFormatFloatingToolbarPlugin: FC<
    TextFormatFloatingToolbarPluginProps
> = ({ handleContentCut }) => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        // SEPARATE_COMMAND
        editor.registerCommand(
            TYPOGRAPHY_COMMAND,
            (payload: string) => {
                const selectedNode =
                    $getSelection()?.getNodes();
                if (selectedNode) {
                    getTypographText(selectedNode);
                }

                return false;
            },
            COMMAND_PRIORITY_EDITOR,
        );
        editor.registerCommand(
            CONTENT_CUT_COMMAND,
            (payload: string) => {
                const selection = $getSelection();
                const selectedNode = selection?.getNodes();
                if ($isRangeSelection(selection)) {
                    const mainEditor =
                        editor.getEditorState();
                    if (selectedNode?.length === 0) {
                        return false;
                    }

                    const decoratorNode =
                        selectedNode?.filter((item) => {
                            const root =
                                $getRoot().getChildrenKeys();

                            return root.includes(
                                item.getKey(),
                            );
                        });

                    if (decoratorNode) {
                        if (decoratorNode?.length === 0) {
                            let node = selection?.anchor
                                ?.getNode()
                                ?.getParent();
                            let isNotRootChild = true;

                            while (isNotRootChild) {
                                const nodeKey =
                                    node?.getKey?.() ??
                                    'none';
                                const rootChild =
                                    $getRoot().getChildrenKeys();
                                const isRootChild =
                                    rootChild.includes(
                                        nodeKey,
                                    );
                                isNotRootChild =
                                    !Boolean(isRootChild);
                                if (
                                    isNotRootChild &&
                                    node
                                ) {
                                    node =
                                        node?.getParent();
                                }
                            }

                            if (node) {
                                const topSiblings =
                                    node.getPreviousSiblings();

                                const bottomSiblings =
                                    node.getNextSiblings();

                                const middle =
                                    nodeSerelization([
                                        node,
                                    ]);

                                const top =
                                    nodeSerelization(
                                        topSiblings,
                                    );

                                const bottom =
                                    nodeSerelization(
                                        bottomSiblings,
                                    );

                                const topEditor =
                                    createEditor().toJSON();
                                topEditor.editorState.root.children =
                                    top;

                                const middleEditor =
                                    createEditor().toJSON();
                                middleEditor.editorState.root.children =
                                    middle;

                                const bottomEditor =
                                    createEditor().toJSON();
                                bottomEditor.editorState.root.children =
                                    bottom;
                                handleContentCut({
                                    topEditor:
                                        topSiblings.length ===
                                        0
                                            ? null
                                            : JSON.stringify(
                                                  topEditor.editorState,
                                              ),
                                    middleEditor:
                                        JSON.stringify(
                                            middleEditor.editorState,
                                        ),
                                    bottomEditor:
                                        bottomSiblings.length ===
                                        0
                                            ? null
                                            : JSON.stringify(
                                                  bottomEditor.editorState,
                                              ),
                                });
                                return false;
                            }

                            return false;
                        } else {
                            const selectedNode =
                                decoratorNode;

                            const firstChild =
                                selectedNode[0];
                            const lastChild =
                                selectedNode[
                                    decoratorNode.length - 1
                                ];

                            const topSiblings =
                                firstChild.getPreviousSiblings();
                            const bottomSiblings =
                                lastChild.getNextSiblings();

                            const top =
                                nodeSerelization(
                                    topSiblings,
                                );
                            const middle =
                                nodeSerelization(
                                    selectedNode,
                                );

                            const bottom =
                                nodeSerelization(
                                    bottomSiblings,
                                );

                            const topEditor =
                                createEditor().toJSON();
                            topEditor.editorState.root.children =
                                top;

                            const middleEditor =
                                createEditor().toJSON();
                            middleEditor.editorState.root.children =
                                middle;

                            const bottomEditor =
                                createEditor().toJSON();
                            bottomEditor.editorState.root.children =
                                bottom;

                            handleContentCut({
                                topEditor:
                                    topSiblings.length === 0
                                        ? null
                                        : JSON.stringify(
                                              topEditor.editorState,
                                          ),
                                middleEditor:
                                    selectedNode.length ===
                                    0
                                        ? null
                                        : JSON.stringify(
                                              middleEditor.editorState,
                                          ),
                                bottomEditor:
                                    bottomSiblings.length ===
                                    0
                                        ? null
                                        : JSON.stringify(
                                              bottomEditor.editorState,
                                          ),
                            });
                            return false;
                        }
                    }
                    return false;
                }
                return false;
            },
            COMMAND_PRIORITY_EDITOR,
        );
    }, []);

    return useTextFormatFloatingToolbar(editor);
};
