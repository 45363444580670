import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { BannedOrganizationCreate } from '@mega/forms';
import { BannedOrganizationCreate as BannedOrganizationCreateType } from '@mega/api';
import {
    useBannedOrganizationDispatch,
    useSiteStore,
} from '@mega/store';

export const BannedOrganizationNew: React.FC = () => {
    const { create } = useBannedOrganizationDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const location = useLocation();
    const navigate = useNavigate();

    const site = useSiteStore();

    const handleCreate = async (
        value: Pick<BannedOrganizationCreateType, 'title'>,
    ) => {
        if (!Boolean(site && site.id)) {
            return enqueueSnackbar(
                'Простите что-то пошло не так, обратитесь пожалуйста в тех поддержку',
                {
                    variant: 'error',
                },
            );
        }

        return await create({
            payload: value,
            options: {
                router: {
                    navigate,
                    location,
                },
                notifier: {
                    enqueueSnackbar,
                },
            },
        });
    };

    return (
        <BannedOrganizationCreate
            handleCreate={handleCreate}
        />
    );
};
