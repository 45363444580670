import React, { FC, ReactElement } from 'react';
import { Divider, Paper, Stack } from '@mega/ui';
import {
    positionColumn,
    wrapper,
} from './CardStoryTemplate.css';

export interface CardStoryProps {
    renderForm?: () => ReactElement;
    renderFileLoader?: () => ReactElement;
    renderButton?: () => ReactElement;
    reorderAnchor?: () => ReactElement;
    renderCards?: () => ReactElement;
}

const CardStoryTemplate: FC<CardStoryProps> = ({
    renderForm,
    renderCards,
}) => {
    return (
        <div className={wrapper}>
            <Paper
                style={{ background: '#eaeaea' }}
                classes={{
                    padding: {
                        size: '8x8',
                    },
                }}
                fullWidth
                variant={'filled'}
                color={'gray'}
            >
                <div className={positionColumn}>
                    <Stack gap="8">{renderForm?.()}</Stack>
                    <Divider />
                    {renderCards?.()}
                </div>
            </Paper>
        </div>
    );
};

export { CardStoryTemplate };
