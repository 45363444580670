import { FC } from 'react';
import { PluginViewWrapper } from 'src/pages/smartGrid/components/shared/PluginViewWrapper';
import { GridSettings } from '@pages/smartGrid/components/shared/GridSettings';
import { useEditor } from '@pages/smartGrid/store/hooks';
import { ArticleProps } from './Article.types';
import { IInitValuesForm } from './ArticleSettings/ArticleForm/config';
import { ArticleSettings } from './ArticleSettings';
import { styles as s } from './Article.css';
import { PluginInfo } from '@pages/smartGrid/components/shared/PluginInfo/PluginInfo';
import { useSiteStore } from '@mega/store';

const Article: FC<ArticleProps> = ({
    id,
    type,
    meta,
    parentId,
}) => {
    const { handleUpdateElement } = useEditor();

    const { label, dataId } = meta;

    const currenSite = useSiteStore();

    // TODO написать конфиг для обработки плагинов сетки и их настроек
    const is360 = currenSite?.id === '360tv.ru';

    const onSubmit = (values: IInitValuesForm) => {
        handleUpdateElement({
            id,
            type,
            meta: values as Record<string, any>,
        });
    };

    return (
        <>
            <PluginViewWrapper
                id={id}
                parentId={parentId}
                title={'Статья'}
                render={(isEdit: any, setIsEdit: any) => {
                    return (
                        <GridSettings
                            isOpen={isEdit}
                            setIsOpen={setIsEdit}
                            title={'Статья - настройка'}
                            render={({ handleClose }) => {
                                return (
                                    <ArticleSettings
                                        initialValues={meta}
                                        onSave={(
                                            values: IInitValuesForm,
                                        ) => {
                                            onSubmit(
                                                values,
                                            );
                                            handleClose();
                                        }}
                                    />
                                );
                            }}
                        />
                    );
                }}
            >
                <div className={s.wrapper({})}>
                    <div className={s.container}>
                        <div className={s.innerContainer}>
                            <div className={s.content}>
                                <p className={s.text}>
                                    {`${label}`}
                                </p>
                            </div>
                            <PluginInfo
                                data={{
                                    label: {
                                        label: meta.cover
                                            .label,
                                        value: !!meta.cover,
                                    },
                                    withLead: {
                                        label: 'Лид-абзац',
                                        value: meta.withLead,
                                    },
                                    withInfo: {
                                        label: 'Инфо',
                                        value: meta.withInfo,
                                    },
                                    viewType: {
                                        label: meta.viewType
                                            ?.label,
                                        value:
                                            is360 &&
                                            !!meta.viewType,
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>
            </PluginViewWrapper>
        </>
    );
};

export { Article };
