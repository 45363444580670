import React, { FC } from 'react';
import type { Videos as VideosType } from '@mega/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { useVideoDispatch } from '@mega/store';
import {
    AdditionalMenu,
    IconButton,
    Indi,
    ItemNews,
    ListItem,
    Typography,
} from '@mega/ui';
import { More } from '@mega/icons';
import {
    infoDivider,
    infoTime,
    infoWrapper,
    videoCardWrapper,
} from './VideoCard.css';
import { fmtMSS } from '@mega/admin/src/utils';

export interface VideoCardProps extends VideosType {}

const VideoCard: FC<VideoCardProps> = (props) => {
    const { title, status, id, video_duration } = props;
    const { remove } = useVideoDispatch();
    let navigate = useNavigate();
    let location = useLocation();

    const time = video_duration
        ? fmtMSS(Math.trunc(video_duration))
        : '-';
    return (
        <div className={videoCardWrapper}>
            <ItemNews
                key={id}
                onClick={() => {
                    navigate(`change/${id}`, {
                        state: location,
                    });
                }}
                lead={title || 'Без названия'}
                actions={() => (
                    <AdditionalMenu
                        id={id}
                        trigger={({ handleClick }) => (
                            <IconButton
                                onClick={handleClick}
                                withoutVerticlaPadding
                                // color="primary"
                                size="medium"
                                animate="secondary"
                                classes={{
                                    paper: {
                                        variant:
                                            'outlineFilled',
                                        color: 'gray',
                                    },
                                }}
                            >
                                <More
                                    style={{
                                        fontSize: '24px',
                                    }}
                                />
                            </IconButton>
                        )}
                    >
                        <ListItem
                            label="Редактировать"
                            onClick={() => {
                                navigate(`change/${id}`, {
                                    state: location,
                                });
                            }}
                        />
                        <ListItem
                            label="Удалить"
                            onClick={() => {
                                if (id) {
                                    remove(id);
                                }
                            }}
                        />
                    </AdditionalMenu>
                )}
                indicators={() => {
                    return (
                        <div className={infoWrapper}>
                            {status === 'processed' ? (
                                <Indi
                                    colors="published"
                                    label={'Обработано'}
                                />
                            ) : status === 'deleted' ? (
                                <Indi
                                    colors="deleted"
                                    label="Удалено"
                                />
                            ) : (
                                <Indi
                                    colors="transparent"
                                    label="Не обработано"
                                />
                            )}

                            <div className={infoDivider} />
                            <Typography
                                className={infoTime}
                            >
                                / {time}
                            </Typography>
                        </div>
                    );
                }}
            />
        </div>
    );
};

export { VideoCard };
export default VideoCard;
