import React from 'react';
import { AuthorCreate } from '@mega/forms';
import { useSiteStore } from '@mega/store';

export const AuthorsNew: React.FC = () => {
    const siteStore = useSiteStore();

    return (
        <AuthorCreate
            initialValue={{
                name: '',
                sites: siteStore?.id
                    ? [
                          {
                              id: siteStore?.id,
                              label: siteStore.label,
                          },
                      ]
                    : [],
            }}
        />
    );
};
