import type { PluginTemplate } from '@apostroph/types';

export enum AdsSourceEnum {
    type = 'ADS_SOURCE',
}

export interface AdsSourceProps {
    id: string;
    type: string;
    parentId: string | null;
}

export type AdsSourceMeta = {
    text: string;
};

export type AdsSourcePlugin = PluginTemplate<
    AdsSourceEnum.type,
    AdsSourceMeta
>;
export type AdsSourcePluginDefault = Omit<
    AdsSourcePlugin,
    'id'
>;
