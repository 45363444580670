import React, { useState } from 'react';
import {
    Add,
    ArrowDown,
    Story as StoryIcon,
} from '@mega/icons';
import {
    counter,
    radioItem,
    sortingBlock,
    topSection,
    wrapper,
} from './CardStoryInnerCard.css';
import {
    Divider,
    Icon,
    IconButton,
    Radio,
    Typography,
} from '@mega/ui';
import { StoryVideo } from '../StoryVideo';
import { StoryImg } from '../StoryImg';
import { StoryCard } from '@mega/api';

export interface CardStoryInnerCardProps {
    idx: number;
    curLength: number;
    card: StoryCard;
    handleChange: (field: string, value: any) => void;
    handleRemoveCard: ({
        id,
        idx,
    }: {
        id?: number;
        idx?: number;
    }) => void;
    handleMoveCard: (curId: number, newId: number) => void;
}

const MAX_COUNT = 10;

const data = [
    { label: 'Изображение', value: 'img' },
    { label: 'Видео', value: 'video' },
];

export const CardStoryInnerCard: React.FC<
    CardStoryInnerCardProps
> = (props) => {
    const {
        idx,
        handleRemoveCard,
        handleMoveCard,
        handleChange,
        curLength,
        card,
    } = props;

    const [activeType, setActiveType] = useState<
        string | null
    >(card?.video?.id ? 'video' : 'img');

    return (
        <div className={wrapper}>
            <div className={topSection}>
                <div className={sortingBlock}>
                    <IconButton
                        type="button"
                        classes={{
                            paper: {
                                variant: 'outline',
                                color: 'white',
                            },
                        }}
                        size="medium"
                        disabled={idx === 0}
                        onClick={() =>
                            handleMoveCard(idx, idx - 1)
                        }
                    >
                        <ArrowDown
                            style={{
                                transform: 'rotate(180deg)',
                            }}
                        />
                    </IconButton>
                    <IconButton
                        type="button"
                        classes={{
                            paper: {
                                variant: 'outline',
                                color: 'white',
                            },
                        }}
                        disabled={idx === curLength}
                        size="medium"
                        onClick={() =>
                            handleMoveCard(idx, idx + 1)
                        }
                    >
                        <ArrowDown />
                    </IconButton>
                </div>
                <div className={counter}>
                    <Icon size="16">
                        <StoryIcon />
                    </Icon>
                    <Typography
                        size={'12'}
                        weight={'medium'}
                    >
                        История {idx + 1}/{MAX_COUNT}
                    </Typography>
                </div>
                <IconButton
                    onClick={() =>
                        handleRemoveCard({
                            id: card?.id,
                            idx: idx,
                        })
                    }
                >
                    <Icon size="25">
                        <Add
                            style={{
                                transform: 'rotate(-45deg)',
                            }}
                        />
                    </Icon>
                </IconButton>
            </div>
            <Divider />
            <div
                style={{
                    margin: '8px 0 16px',
                    width: 'max-content',
                }}
            >
                <Radio
                    name={`type_${idx}`}
                    onChange={(e) => {
                        setActiveType(e.value);
                    }}
                    value={
                        !!activeType
                            ? activeType
                            : undefined
                    }
                    data={data.map((item) => ({
                        ...item,
                        label: (
                            <div
                                className={radioItem({
                                    isActive:
                                        activeType ===
                                        item.value,
                                })}
                            >
                                {item.label}
                            </div>
                        ),
                    }))}
                />
            </div>
            <div>
                {activeType === 'img' ? (
                    <StoryImg
                        card={card}
                        handleChange={handleChange}
                    />
                ) : (
                    <StoryVideo
                        video={card?.video}
                        handleChange={handleChange}
                    />
                )}
            </div>
        </div>
    );
};
