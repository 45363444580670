import type { PluginTemplate } from '@apostroph/types';

type ClientFile = {
    file: File;
    title: string;
    loading: boolean;
};

type ServerFile = {
    id?: number;
    title?: string;
    file?: string;
};

export enum UploadFileEnum {
    type = 'FILES',
}

export interface UploadFileProps {
    id: string;
    type: UploadFileEnum.type;
    parentId: string | null;
}

export type UploadFileMeta = {
    files: {
        [key: string]: ServerFile;
    };
};

export type UploadFilePlugin = PluginTemplate<
    UploadFileEnum.type,
    UploadFileMeta
>;
export type UploadFilePluginDefault = Omit<
    UploadFilePlugin,
    'id'
>;
