import React, { FC } from 'react';
import { Warning } from '@mega/icons';
import { Stack, Row } from '@mega/ui';
import {
    NotifyVideoPresetsRecipeVariants,
    classes,
} from './NotifyVideoPresets.css';

interface NotifyVideoPresetsProps {}

const NotifyVideoPresets: FC<
    NotifyVideoPresetsProps &
        NotifyVideoPresetsRecipeVariants
> = ({ isRow }) => {
    return (
        <div
            className={[
                classes.notifyVideoPresetsRecipe({ isRow }),
            ].join('')}
        >
            <Warning className={classes.icon} />
            {isRow ? (
                <div>
                    <p>
                        Проверьте соответствие видео
                        следующим параметрам: кодеки
                        H264(4.1-main, CRF 20), AAC(2 ch,
                        stereo, 44100, 192k), MP4(MOOV AT
                        BEGIN).
                    </p>
                    <p>
                        Также файл должен весить менее 250
                        Мб. В ином случае видео может
                        работать нестабильно.
                    </p>
                </div>
            ) : (
                <Stack gap="16">
                    <p>
                        Проверьте соответствие видео
                        следующим параметрам:
                    </p>
                    <ul className={classes.wrapList}>
                        <li>
                            кодеки H264(4.1-main, CRF 20)
                        </li>
                        <li>
                            AAC(2 ch, stereo, 44100, 192k)
                        </li>
                        <li>MP4(MOOV AT BEGIN)</li>
                        <li>файл менее 250 Мб</li>
                    </ul>
                    <p>
                        В ином случае видео может работать
                        нестабильно
                    </p>
                </Stack>
            )}
        </div>
    );
};

export { NotifyVideoPresets };
