import React from 'react';
import {
    Blockquote,
    CardSection,
    Divider,
    Gallery,
    Image,
    AdsLabel,
    Video,
    // @ts-ignore
} from '@tv360/storybook';
import { InitPreview } from '@apostroph/view';
import { WorkInProgress } from '@mega/icons';
import { MilkRender } from './MilkRender';

export type ApostrophSerializerProps = {
    initData: any;
};

const ApostrophSerializer = ({
    initData,
}: ApostrophSerializerProps) => {
    if (!initData) return null;
    return (
        <InitPreview
            initialState={{
                data: initData?.data || {},
                items: initData?.items || [],
            }}
            dependencyPreview={{
                pluginElements: {
                    Block: {
                        render: (props: any) => (
                            <>{props.children}</>
                        ),
                    },
                    LeadBlock: {
                        render: (props: any) => (
                            <>{props.children}</>
                        ),
                    },
                    MILK: {
                        render: MilkRender,
                    },
                    IMG: {
                        render: (props: any) => {
                            return (
                                <Image
                                    {...props}
                                    source_text={
                                        props?.copyright
                                            ?.label ??
                                        props?.source_text
                                    }
                                />
                            );
                        },
                    },
                    INFOGRAPH: {
                        render: (props: any) => {
                            const { description, ...rest } =
                                props;
                            return (
                                <Image
                                    {...rest}
                                    alt={description}
                                />
                            );
                        },
                    },
                    BLOCKQUOTE: {
                        render: Blockquote,
                    },
                    MAIN: {
                        render: Blockquote,
                    },
                    HORIZONTAL_LINE: {
                        render: Divider,
                    },
                    HTML_TEXT: {
                        render: (props: any) => (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: props.html,
                                }}
                            />
                        ),
                    },
                    IN_PROGRESS: {
                        render: () => <WorkInProgress />,
                    },
                    ADS_SOURCE: {
                        render: (props) => {
                            return (
                                <AdsLabel
                                    text={props?.text}
                                />
                            );
                        },
                    },
                    VIDEO: {
                        render: (props: any) => {
                            const {
                                cover,
                                video_duration,
                                title,
                                file_link,
                            } = props;
                            return (
                                <figure>
                                    <video>
                                        <source
                                            type="video/mp4"
                                            src={file_link}
                                            data-duration={
                                                video_duration
                                            }
                                            data-title={
                                                title
                                            }
                                        />
                                    </video>
                                    {cover && (
                                        <img src={cover} />
                                    )}
                                </figure>
                            );
                        },
                    },
                    GALLERY: {
                        render: (props) => {
                            return (
                                <Gallery
                                    loop={false}
                                    {...props}
                                />
                            );
                        },
                    },
                    SECTION: {
                        render: (props: any) => {
                            return (
                                <CardSection
                                    order={''}
                                    name={props.name}
                                >
                                    {props.children}
                                </CardSection>
                            );
                        },
                    },
                },
            }}
        />
    );
};

export const ApostrophSerializerMemo = React.memo(
    ApostrophSerializer,
);
