import { useEffect } from 'react';
import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';
import { useSnackbar } from 'notistack';
import {
    useParams,
    useLocation,
    useNavigate,
} from 'react-router-dom';
//
import { FormikHelpers } from 'formik';
import { useSiteStore } from '..';
import { omit } from 'lodash-es';
import {
    EmployeeFormDataCreate,
    EmployeeFormDataUpdate,
} from '@mega/api';

const useEmployeeStore = () => {
    const store = useStoreSelector(
        (state: RootState) => state.employee.employee,
    );
    const loading = useStoreSelector(
        (state: RootState) =>
            state.loading.effects.employee,
    );

    return {
        store,
        loading,
    };
};
const useEmployeeDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        get: dispatch.employee.get,
        put: dispatch.employee.put,
        create: dispatch.employee.create,
        update: dispatch.employee.update,
        resetPassword: dispatch.employee.resetPassword,
    };
};

const useEmployee = () => {
    const store = useSiteStore();
    const location = useLocation();
    const navigate = useNavigate();

    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { get, create, update, resetPassword } =
        useEmployeeDispatch();

    useEffect(() => {
        if (id) {
            get({
                payload: { id: parseInt(id) },
            });
        }
    }, [id, get]);

    return {
        id: id,
        createFromForm: async (
            payload: EmployeeFormDataCreate,
            actions: FormikHelpers<EmployeeFormDataCreate>,
        ) => {
            const res = await create({ payload });
            if (res.isOk) {
                enqueueSnackbar('Сотрудник создан');
                navigate(
                    `/${store?.id || ''}/user/change/${
                        res.value.id
                    }`,
                    {
                        state: location,
                    },
                );
            } else {
                //@ts-ignore
                Object.entries(res.value).forEach(
                    ([key, value]) => {
                        try {
                            enqueueSnackbar(value);
                        } catch (e) {}
                    },
                );
            }
            actions.setSubmitting(false);
        },
        updateFromForm: async (
            payload: EmployeeFormDataUpdate,
            actions: FormikHelpers<EmployeeFormDataUpdate>,
        ) => {
            const form = omit(payload, 'photo');

            if (id) {
                const res = await update({
                    id: parseInt(id),
                    ...form,
                });
                if (res.isOk) {
                    enqueueSnackbar('Сотрудник обновлен');
                } else {
                    Object.entries(res.value).forEach(
                        ([key, value]) => {
                            try {
                                enqueueSnackbar(value);
                            } catch (e) {}
                        },
                    );
                }
            } else {
                enqueueSnackbar(
                    'Форма не была проинициализирована, повторите попытку сохранить снова',
                );
            }
            actions.setSubmitting(false);
        },
        changePasswordFromForm: async (
            payload: EmployeeFormDataUpdate,
            actions: FormikHelpers<EmployeeFormDataUpdate>,
        ) => {
            if (id) {
                const res = await resetPassword({
                    payload,
                });
                if (res.isOk) {
                    enqueueSnackbar(
                        'Пароль успешно изменен',
                    );
                } else {
                    Object.entries(res.value).forEach(
                        ([key, value]) => {
                            try {
                                enqueueSnackbar(value);
                            } catch (e) {}
                        },
                    );
                }
            } else {
                enqueueSnackbar(
                    'Произошла ошибка, повторите попытку позже',
                );
            }
            actions.setSubmitting(false);
        },
    };
};

export {
    useEmployeeStore,
    useEmployeeDispatch,
    useEmployee,
};
