import React from 'react';
import {
    base,
    chevron,
    counter,
    hiddenSection,
    icon,
    inputs,
    title,
    titleWrapper,
    toggle,
} from './Filters.css';
import cn from 'classnames';
import {
    Button,
    InputSelectMultiField,
    Typography,
    WithLabel,
} from '@mega/ui';
import { mediashare } from '@mega/api';
import {
    PaginatedGenericList,
    Tag,
    TagTypes,
} from '@mega/api/typesMS';
import { Filter as FiltersIcon } from '@mega/icons';
import { FiChevronLeft } from 'react-icons/fi';
import { useFormikContext } from 'formik';
import { FormValues } from '../../types';

const defaultPerPage = 25;
const getOptions =
    ({ tag_type }: { tag_type?: TagTypes }) =>
    async (inputValue: string, page: number) => {
        const responce = await mediashare.getTags?.({
            search:
                inputValue === '' ? undefined : inputValue,
            per_page: defaultPerPage * page,
            tag_type: tag_type,
            mediafiles_count__gte: '1',
        });

        if (responce?.ok) {
            const data =
                responce.data as PaginatedGenericList<Tag>;

            return (
                data?.results?.map((item) => ({
                    label: item.title || '',
                    value: item.id || 1,
                })) ?? []
            );
        } else {
            return [];
        }
    };

export type FiltersProps = {
    isOpen: boolean;
    handleToggle: () => void;
};

const FilterHeader: React.FC<FiltersProps> = ({
    handleToggle,
    isOpen,
}) => {
    const { values, setFieldValue } =
        useFormikContext<FormValues>();
    const { places, events, people, blank } = values;

    const tagsLength =
        places.length +
        events.length +
        people.length +
        blank.length;
    return (
        <div className={toggle} onClick={handleToggle}>
            <div className={titleWrapper({ isOpen })}>
                <div className={icon}>
                    <FiltersIcon />
                </div>
                <Typography className={title({ isOpen })}>
                    Фильтры
                </Typography>
                {!!tagsLength && (
                    <Typography className={counter}>
                        {tagsLength}
                    </Typography>
                )}
            </div>
            <FiChevronLeft
                className={chevron({ isOpen })}
            />
        </div>
    );
};
export const Filters: React.FC<FiltersProps> = ({
    isOpen,
    handleToggle,
}) => {
    const { values, setFieldValue } =
        useFormikContext<FormValues>();

    const handleReset = () => {
        setFieldValue('places', []);
        setFieldValue('events', []);
        setFieldValue('people', []);
        setFieldValue('blank', []);
    };
    return (
        <div className={base}>
            <FilterHeader
                handleToggle={handleToggle}
                isOpen={isOpen}
            />
            <div
                className={cn(
                    inputs,
                    hiddenSection({ isOpen }),
                )}
            >
                <WithLabel id={'places'} title="Места">
                    <InputSelectMultiField
                        classes={{
                            paper: {
                                color: 'dark',
                            },
                            select: {
                                variant: 'secondary',
                            },
                        }}
                        name="places"
                        handleLoader={getOptions({
                            tag_type: 'places',
                        })}
                    />
                </WithLabel>
                <WithLabel id={'events'} title="Событий">
                    <InputSelectMultiField
                        classes={{
                            paper: {
                                color: 'dark',
                            },
                            select: {
                                variant: 'secondary',
                            },
                        }}
                        name="events"
                        handleLoader={getOptions({
                            tag_type: 'events',
                        })}
                    />
                </WithLabel>
                <WithLabel id={'people'} title="Люди">
                    <InputSelectMultiField
                        classes={{
                            paper: {
                                color: 'dark',
                            },
                            select: {
                                variant: 'secondary',
                            },
                        }}
                        name="people"
                        handleLoader={getOptions({
                            tag_type: 'people',
                        })}
                    />
                </WithLabel>
                <WithLabel id={'blank'} title="Теги">
                    <InputSelectMultiField
                        classes={{
                            paper: {
                                color: 'dark',
                            },
                            select: {
                                variant: 'secondary',
                            },
                        }}
                        name="blank"
                        handleLoader={getOptions({
                            tag_type: 'blank',
                        })}
                    />
                </WithLabel>
            </div>
            <div className={hiddenSection({ isOpen })}>
                <Button
                    size={'large'}
                    fullWidth
                    type={'button'}
                    label={'Сбросить все фильтры'}
                    onClick={handleReset}
                    classes={{
                        paper: {
                            color: 'dark',
                            variant: 'outline',
                        },
                    }}
                    animate={'secondary'}
                />
            </div>
        </div>
    );
};
