import React, {
    PropsWithChildren,
    ReactElement,
} from 'react';

import { components, MenuProps } from 'react-select';
import { Divider } from '@mega/ui';

interface MenuClass {
    className?: string;
    buttonFooter?: ReactElement;
    variantClass?: string;
}

export type OverideMenu = MenuProps<
    Record<string, unknown>,
    true
>;

const Menu =
    ({
        className = '',
        buttonFooter,
        variantClass,
    }: MenuClass) =>
    ({ ...props }: PropsWithChildren<OverideMenu>) => {
        const hasButton = Boolean(buttonFooter);
        return (
            <>
                <components.Menu
                    className={className}
                    {...props}
                >
                    {variantClass === 'primary' ? (
                        <Divider />
                    ) : null}
                    {variantClass === 'fourth' ? (
                        <Divider />
                    ) : null}
                    {props.children}
                    {hasButton ? buttonFooter : null}
                </components.Menu>
            </>
        );
    };

export { Menu };
export default Menu;
