import { useEffect } from 'react';
import {
    useParams,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';
import { FormikHelpers } from 'formik';
import {
    AuthorFormDataCreate,
    AuthorFormDataUpdate,
} from '@mega/types';
import { useSiteStore } from '..';
import { omit, pick } from 'lodash-es';

const useAuthorStore = () => {
    const store = useStoreSelector(
        (state: RootState) => state.author,
    );
    const loading = useStoreSelector(
        (state: RootState) => state.loading.effects.author,
    );

    return {
        store,
        loading,
    };
};

const useAuthorDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        get: dispatch.author.get,
        put: dispatch.author.put,
        create: dispatch.author.create,
        update: dispatch.author.update,
        updateImage: dispatch.author.updateImage,
        remove: dispatch.author.remove,
    };
};

const useAuthor = () => {
    const store = useSiteStore();
    const location = useLocation();
    const navigate = useNavigate();

    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { get, create, update, updateImage } =
        useAuthorDispatch();

    useEffect(() => {
        if (id) {
            get({
                payload: { id: parseInt(id) },
            });
        }
    }, [id, get]);

    return {
        id: id,
        createFromForm: async (
            payload: AuthorFormDataCreate,
            actions: FormikHelpers<AuthorFormDataCreate>,
        ) => {
            const res = await create({ payload });
            if (res.isOk) {
                enqueueSnackbar('Автор создан');
                navigate(
                    `/${store?.id || ''}/author/change/${
                        res.value.id
                    }`,
                    {
                        state: location,
                    },
                );
            } else {
                //@ts-ignore
                enqueueSnackbar(res.value, {});
            }
            actions.setSubmitting(false);
        },
        updateFromForm: async (
            payload: AuthorFormDataUpdate,
            actions: FormikHelpers<AuthorFormDataUpdate>,
        ) => {
            const form = omit(payload, 'photo');
            const photo = pick(payload, 'photo');
            let formData = new FormData();

            if (id) {
                const res = await update({
                    id: parseInt(id),
                    ...form,
                });
                if (res.isOk) {
                    enqueueSnackbar('Автор обновлен');
                    if (
                        photo.photo &&
                        typeof photo.photo !== 'string'
                    ) {
                        formData.append(
                            'photo',
                            photo.photo,
                        );
                        const res = await updateImage({
                            id: id,
                            data: formData,
                        });
                        if (res.isOk) {
                            enqueueSnackbar(
                                'Аватар автора обновлен',
                            );
                        } else {
                            enqueueSnackbar(
                                'При обновлении аватара произошла ошибка',
                            );
                        }
                    }
                } else {
                    // actions.setErrors({
                    //     email: 'wtf',
                    // });
                    //@ts-ignore
                    Object.entries(res.value).forEach(
                        ([key, value]) => {
                            try {
                                enqueueSnackbar(value);
                            } catch (e) {}
                        },
                    );
                }
            } else {
                enqueueSnackbar(
                    'форма была не проинициализирована, повторите попытку сохранить снова',
                );
            }
            // actions.setSubmitting(false);
        },
    };
};

export { useAuthorStore, useAuthorDispatch, useAuthor };
