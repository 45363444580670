import React, { FC } from 'react';
import { Form, Formik, useFormikContext } from 'formik';

import * as Yup from 'yup';

import {
    tags,
    TagCreate as TagCreateType,
} from '@mega/api';

import {
    Button,
    FormLayout,
    InputField,
    Paper,
    Stack,
} from '@mega/ui';
import { TagCreateActionForm } from './Tag.type';

export interface TagCreateProps {
    initialValue?: Pick<TagCreateType, 'title'>;
    handleCreate?: (
        value: Pick<TagCreateType, 'title'>,
    ) => any;
}

const TagCreate: FC<TagCreateProps> = ({
    initialValue = { title: '' },
    handleCreate = () => null,
}) => (
    <Formik
        validationSchema={Yup.object({
            title: Yup.string()
                .min(
                    1,
                    'Это поле явно должно быть больше одного символа',
                )
                .required('Это поле обязательное'),
        })}
        initialValues={initialValue}
        onSubmit={handleCreate}
    >
        <Form>
            <FormLayout actions={<TagCreateActions />}>
                <Paper
                    variant="outline"
                    color="white"
                    classes={{
                        padding: { size: '24x24' },
                    }}
                >
                    <Stack gap="18">
                        <InputField
                            isRequired
                            type="text"
                            name={'title'}
                            placeholder="Заголовок"
                            dimension="medium"
                        />
                    </Stack>
                </Paper>
            </FormLayout>
        </Form>
    </Formik>
);

const TagCreateActions: TagCreateActionForm = () => {
    const { submitForm, isSubmitting } = useFormikContext();

    return (
        <Paper
            style={{
                borderTop: '1px solid #EAEAEA',
            }}
            fullWidth
            color={'white'}
            variant="filled"
            classes={{
                padding: { size: '24x24' },
            }}
        >
            <Button
                classes={{
                    paper: {
                        variant: 'filled',
                        color: 'dark',
                    },
                }}
                color="secondary"
                size={'large'}
                type={'submit'}
                label={'Создать'}
                onClick={submitForm}
                disabled={isSubmitting}
            />
        </Paper>
    );
};

export { TagCreate };
