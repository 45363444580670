import { createModel } from '@rematch/core';
import { DefaultStore } from '../type';
import { RootModel } from '../rootModel';

import {
    EmployeeFormDataCreate,
    EmployeeFormDataUpdate,
    EmployeePartialUpdateReturn,
    EmployeePartialUpdateError,
    EmployeeCreateError,
    EmployeeCreateReturn,
    EmployeeGetByIdQuery,
    EmployeeGetByIdReturn,
    EmployeeGetByIdError,
    isOk,
} from '@mega/api';

import {
    employeeGetById,
    employeeCreate,
    employeePartialUpdateById,
    resetPassword,
    EmployeeGetByIdPromise,
    EmployeeCreatePromise,
    EmployeePartialUpdatePromise,
} from './employee.api';

// const defaultStore: DefaultStore<> = {};

const defaultStore = {
    employee: {},
    changePasswordResult: {},
};

const employee = createModel<RootModel>()({
    name: 'employee',
    state: defaultStore,
    reducers: {
        put: (state, payload) => {
            return { ...state, employee: payload };
        },
        putChangePasswordResult: (state, payload) => {
            return {
                ...state,
                changePasswordResult: payload,
            };
        },
    },
    effects: (dispatch) => ({
        get: async (
            props: {
                payload: EmployeeGetByIdQuery;
            },
            store,
        ): EmployeeGetByIdPromise => {
            const response = await employeeGetById(
                props.payload,
                store.site.site?.id || '',
            );
            if (
                isOk<
                    EmployeeGetByIdReturn,
                    EmployeeGetByIdError | null
                >(response)
            ) {
                dispatch.employee.put(response.value);
                return response;
            }
            return response;
        },
        create: async (
            props: {
                payload: EmployeeFormDataCreate;
            },
            store,
        ): EmployeeCreatePromise => {
            const obj = props.payload;
            const response = await employeeCreate(
                {
                    ...obj,
                },
                store.site.site?.id || '',
            );

            if (
                isOk<
                    EmployeeCreateReturn,
                    EmployeeCreateError | null
                >(response)
            ) {
                dispatch.employee.put(response.value);
                return response;
            }
            return response;
        },
        update: async (
            payload: EmployeeFormDataUpdate & {
                id: number;
            },
            store,
        ): EmployeePartialUpdatePromise => {
            const response =
                await employeePartialUpdateById(
                    payload,
                    store.site.site?.id || '',
                );

            if (
                response &&
                isOk<
                    EmployeePartialUpdateReturn,
                    EmployeePartialUpdateError | null
                >(response)
            ) {
                dispatch.employee.put(response.value);
                return response;
            }
            return response;
        },
        resetPassword: async (
            payload: {
                id: string;
            },
            store,
        ): EmployeePartialUpdatePromise => {
            const response = await resetPassword(
                {
                    id: parseInt(payload.id),
                },
                store.site.site?.id || '',
            );

            if (
                response &&
                isOk<
                    EmployeePartialUpdateReturn,
                    EmployeePartialUpdateError | null
                >(response)
            ) {
                return response;
            } else {
                return response;
            }
        },
    }),
});

export { employee };
export default employee;
