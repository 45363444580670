import { useEffect } from 'react';

import { Form, Formik } from 'formik';
import { motion } from 'framer-motion';
import { useSearchParams } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import { ArrowDown, Search, FilterList } from '@mega/icons';
import { employeeFiltersClasses } from './EmployeesFilters.css';

import {
    useUserInfo,
    useEmployeesDispatch,
    useEmployeesStore,
    useEmployeesQuery,
} from '@mega/store';

import {
    Button,
    Group,
    Icon,
    Input,
    SelectSingleField,
    InputSelectMulti,
    WithTrigger,
    Typography,
} from '@mega/ui';

export type Option = {
    label: string;
    value: string | number;
};

export const ActiveFilter: React.FC<{
    text: string;
    onDelete: () => void;
}> = ({ text, onDelete }) => {
    return (
        <div
            className={employeeFiltersClasses.activeFilter}
        >
            <Typography
                className={
                    employeeFiltersClasses.activeFilterText
                }
            >
                {text}
            </Typography>
            <motion.div
                onClick={onDelete}
                className={
                    employeeFiltersClasses.activeFilterIcon
                }
                whileHover={{
                    scale: 1.1,
                    transition: {
                        duration: 0.2,
                    },
                }}
            >
                <MdClose />
            </motion.div>
        </div>
    );
};

export const EmployeesFilters = (): JSX.Element => {
    const urlParams = new URLSearchParams(
        window.location.search,
    );
    const { search, jumpTo } = useEmployeesDispatch();
    const { me } = useUserInfo();
    const [, setSearchParams] = useSearchParams();

    const sitesList = me?.sites.map((el) => {
        return {
            label: el.label,
            value: el.id,
        };
    });

    const resetPaginatorToFirstPage = () => {
        setSearchParams({ page: '1' });
        jumpTo(1);
    };

    const isSitesListLongEnoughForFilter = sitesList
        ? sitesList?.length > 1
        : false;
    const store = useEmployeesStore();
    const query = useEmployeesQuery();

    const handleSelect = (
        type: string,
        e: Option | undefined,
    ) => {
        resetPaginatorToFirstPage();
        search({
            [type]: e?.value || null,
        });
    };

    useEffect(() => {
        search({
            //@ts-ignore
            ['is_active']: urlParams
                .get('is_active')
                ?.toString(),
            ['ordering']: urlParams
                .get('ordering')
                ?.toString(),
            ['sites_in']: urlParams
                .get('pub_date__gte')
                ?.toString(),
            ['search']: urlParams.get('search')?.toString(),
        });
    }, []);

    const { is_active, ordering } = query;

    return (
        <Formik initialValues={{}} onSubmit={() => {}}>
            <Form>
                <div
                    style={{
                        display: 'flex',
                        gap: '18px',
                    }}
                >
                    <div style={{ minWidth: '300px' }}>
                        <Input
                            startIcon={<Search />}
                            color="secondary"
                            dimension="small"
                            value={
                                store?.query?.search || ''
                            }
                            type="text"
                            onChange={(e) => {
                                resetPaginatorToFirstPage();
                                return search({
                                    search: e.target.value,
                                });
                            }}
                            placeholderBase="ФИО или логин"
                        />
                    </div>
                    <Group>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: '#F4F4F4',
                                border: '1px solid #EAEAEA',
                                borderRadius: 4,
                                padding: '8px 10px',
                            }}
                        >
                            <FilterList />
                            <WithTrigger
                                trigger={({
                                    handleClick,
                                }) => (
                                    <Button
                                        classes={{
                                            paper: {
                                                color: 'gray',
                                            },
                                        }}
                                        size="large"
                                        onClick={
                                            handleClick
                                        }
                                        label="ФИО"
                                        endIcon={
                                            <Icon size="18">
                                                <ArrowDown />
                                            </Icon>
                                        }
                                    />
                                )}
                            >
                                <SelectSingleField
                                    classes={{
                                        paper: {
                                            color: 'dark',
                                        },
                                        select: {
                                            variant:
                                                'primary',
                                        },
                                    }}
                                    menuIsOpen
                                    name="filter_by_surname"
                                    actionOnSelectedOption={(
                                        e,
                                    ) => {
                                        if (e) {
                                            handleSelect(
                                                'ordering',
                                                e,
                                            );
                                        }
                                    }}
                                    handleLoader={async () => [
                                        {
                                            label: 'От А до Я',
                                            value: 'last_name',
                                        },
                                        {
                                            label: 'От Я до А',
                                            value: '-last_name',
                                        },
                                    ]}
                                />
                            </WithTrigger>

                            <WithTrigger
                                trigger={({
                                    handleClick,
                                }) => (
                                    <Button
                                        classes={{
                                            paper: {
                                                color: 'gray',
                                            },
                                        }}
                                        size="large"
                                        onClick={
                                            handleClick
                                        }
                                        label="Активность"
                                        endIcon={
                                            <Icon size="18">
                                                <ArrowDown />
                                            </Icon>
                                        }
                                    />
                                )}
                            >
                                <SelectSingleField
                                    classes={{
                                        paper: {
                                            color: 'dark',
                                        },
                                        select: {
                                            variant:
                                                'primary',
                                        },
                                    }}
                                    menuIsOpen
                                    name="is_active"
                                    actionOnSelectedOption={(
                                        e,
                                    ) => {
                                        if (e) {
                                            handleSelect(
                                                'is_active',
                                                e,
                                            );
                                        }
                                    }}
                                    handleLoader={async () => [
                                        {
                                            label: 'Да',
                                            value: 'true',
                                        },
                                        {
                                            label: 'Нет',
                                            value: 'false',
                                        },
                                    ]}
                                />
                            </WithTrigger>
                        </div>
                    </Group>
                    {isSitesListLongEnoughForFilter ? (
                        <div style={{ minWidth: '200px' }}>
                            <InputSelectMulti
                                handleLoader={async () => {
                                    return sitesList
                                        ? sitesList
                                        : [];
                                }}
                                actionOnSelectedOption={(
                                    e,
                                ) => {
                                    const values = {
                                        value: e.map(
                                            (el) =>
                                                el.value,
                                        ),
                                    };
                                    if (e) {
                                        handleSelect(
                                            'sites_in',
                                            //@ts-ignore
                                            values,
                                        );
                                    }
                                }}
                                placeholder="Сайты"
                                classes={{
                                    select: {
                                        variant:
                                            'secondary',
                                    },
                                }}
                                endIcon={
                                    <ArrowDown
                                        style={{
                                            alignSelf:
                                                'center',
                                        }}
                                    />
                                }
                            />
                        </div>
                    ) : null}
                </div>

                <div
                    className={
                        employeeFiltersClasses.filtersBody
                    }
                >
                    {is_active && (
                        <ActiveFilter
                            onDelete={() =>
                                handleSelect(
                                    'is_active',
                                    undefined,
                                )
                            }
                            text={
                                is_active === 'true'
                                    ? 'Активен'
                                    : 'Не активен'
                            }
                        />
                    )}
                    {ordering && (
                        <ActiveFilter
                            onDelete={() =>
                                handleSelect(
                                    'ordering',
                                    undefined,
                                )
                            }
                            text={
                                ordering === 'last_name'
                                    ? 'От А до Я'
                                    : 'От Я до А'
                            }
                        />
                    )}
                </div>
            </Form>
        </Formik>
    );
};

export default EmployeesFilters;
