import React, { useState } from 'react';
import { VideoCreate as VideoCreateType } from '@mega/api';
import { useVideoDispatch } from '@mega/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { VideoCreate } from '@mega/forms';

export const VideoNew: React.FC = () => {
    const { create } = useVideoDispatch();
    const [progress, setProgress] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();

    const handleCreate = (values: VideoCreateType) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(values)) {
            // @ts-ignore
            formData.append(key, value);
        }
        formData.delete('copyright');
        create({
            payload: { data: formData, setProgress },
            options: {
                router: {
                    navigate,
                    location,
                },
            },
        });
    };

    return (
        <VideoCreate
            handleCreate={handleCreate}
            progress={progress}
        />
    );
};
