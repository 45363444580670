import React, { FC, useEffect, useState } from 'react';
import {
    HeadTemplatePlugin,
    TemplatePlugin,
} from '@mega/editor';
import { useEditor } from '@apostroph/store';
import { ImgLoader } from '@mega/core';
import {
    InfoGraphMeta,
    InfoGraphProps,
} from './InfoGraph.types';
import { Icon, Typography } from '@mega/ui';
import { Graph as GraphIcon } from '@mega/icons';
import { useFormikContext } from 'formik';
import { Article, definitions } from '@mega/api';
import { removeFromFileArray } from '@mega/utils/src/updateFileArrays';
import { useMediaDispatch } from '@mega/store';

const InfoGraph: FC<InfoGraphProps> = ({
    id,
    parentId,
    type,
}) => {
    const { handleUpdateElement, getCurrentDataElement } =
        useEditor();
    const { meta, childrenElement } =
        getCurrentDataElement<InfoGraphMeta>(id);
    const { setIsLoading } = useMediaDispatch();

    const { setFieldValue, values } =
        useFormikContext<Article>();

    const articleFile = values.images?.find(
        (item) => item?.id === meta.id,
    );

    const [state, setState] = useState(articleFile || meta);

    useEffect(() => {
        const newGlobalState = {
            id,
            type,
            childrenElement,
            meta: state,
        };

        handleUpdateElement(newGlobalState);
        setIsLoading({
            isLoading: false,
        });
    }, [state]);

    const handleDelete = (id: number) => {
        removeFromFileArray({
            ids: [id],
            field: 'images',
            values,
            setFieldValue,
        });
        setState({ image: '' });
    };

    return (
        <TemplatePlugin
            head={
                <HeadTemplatePlugin
                    id={id}
                    onDelete={() =>
                        state.id && handleDelete(state.id)
                    }
                    parentId={parentId}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '4px',
                        }}
                    >
                        <Icon size="16">
                            <GraphIcon />
                        </Icon>
                        <Typography size={'12'}>
                            Инфографика
                        </Typography>
                    </div>
                </HeadTemplatePlugin>
            }
        >
            <ImgLoader
                isFieldCopyright={false}
                isFieldSourceUrl={false}
                isInfographic={true}
                description={state.description}
                noDelete
                fileSrc={state.image}
                id={state.id}
                handleLoad={(
                    res: definitions['AdminImage'],
                ) => {
                    setState(res);
                }}
                handleDelete={handleDelete}
            />
        </TemplatePlugin>
    );
};

export { InfoGraph };
