import React, {
    CSSProperties,
    FC,
    ReactElement,
} from 'react';

import {
    paddingBaseClasses,
    PaddingBaseClasses,
    paperClasses,
    PaperClasses,
} from '@mega/styles';

export interface PaperProps {
    style?: CSSProperties;
    children:
        | ReactElement
        | ReactElement[]
        | null
        | undefined;
    classes?: {
        padding?: PaddingBaseClasses['recipe'];
    };
}

export const Paper: FC<
    PaperProps & PaperClasses['recipe']
> = ({
    children,
    variant,
    color,
    borderRadius = 'small',
    classes,
    fullHeight,
    fullWidth,
    contentHeight,
    contentWidth,
    ...props
}) => (
    <div
        className={[
            paperClasses.recipe({
                variant,
                color,
                borderRadius,
                fullHeight,
                fullWidth,
                contentHeight,
                contentWidth,
            }),
            paddingBaseClasses.recipe({
                ...(classes?.padding || {}),
            }),
        ].join(' ')}
        {...props}
    >
        {children}
    </div>
);
