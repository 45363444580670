/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
    Control,
    PositionsVariants,
} from '@apostroph/types';
import { Video } from './Video';
import {
    videoPluginDefault,
    videoEnum,
} from './Video.types';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
const PLUGIN_VIDEO_NAME = videoEnum.type;

const DEFAULT_VIDEO: videoPluginDefault = {
    type: PLUGIN_VIDEO_NAME,
    meta: {
        id: undefined,
        status: 'pending',
        title: '',
        file_link: '',
        frame: '',
    },
    childrenElement: [],
};

const PLUGIN_VIDEO = {
    [PLUGIN_VIDEO_NAME]: {
        emptyObject: DEFAULT_VIDEO,
        render: Video,
    },
};

const PLUGIN_VIDEO_CONTROL: Control = {
    name: PLUGIN_VIDEO_NAME,
    label: 'Sharing',
    tooltip: '',
    position: PositionsVariants.actionFeed,
    Icon: () => <OndemandVideoIcon />,
};

export {
    DEFAULT_VIDEO,
    PLUGIN_VIDEO,
    PLUGIN_VIDEO_CONTROL,
    PLUGIN_VIDEO_NAME,
};
