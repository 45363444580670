import React, {
    ReactElement,
    PropsWithChildren,
} from 'react';
import { components, ControlProps } from 'react-select';
import { selectSingleClasses } from '../../SelectSingle.css';
import { paperClasses } from '@mega/styles';
interface WithIconControlProps {
    startIcon?: ReactElement;
    className?: string;
}

type ControlOverideProps = ControlProps<
    Record<string, unknown>,
    true
>;

const ControlPrimary =
    // ({ startIcon, className = '' }: WithIconControlProps) =>
    ({
        children,

        ...props
    }: PropsWithChildren<ControlOverideProps>) => {
        return (
            <components.Control
                // className={[className].join(' ')}
                className={[
                    selectSingleClasses.controlRecipe({
                        variant: 'primary',
                    }),
                    paperClasses.recipe({
                        variant: 'outlineFilled',
                        color: 'gray',
                        borderRadius: 'small',
                    }),
                ].join(' ')}
                {...props}
            >
                {/* {startIcon} */}
                {children}
            </components.Control>
        );
    };

const ControlSecondary =
    // ({ startIcon, className = '' }: WithIconControlProps) =>
    ({
        children,

        ...props
    }: PropsWithChildren<ControlOverideProps>) => {
        return (
            <components.Control
                // className={[className].join(' ')}
                className={[
                    selectSingleClasses.controlRecipe({
                        variant: 'secondary',
                    }),
                    paperClasses.recipe({
                        variant: 'outlineFilled',
                        color: 'gray',
                        borderRadius: 'small',
                    }),
                ].join(' ')}
                {...props}
            >
                {/* {startIcon} */}
                {children}
            </components.Control>
        );
    };

const ControlThird =
    // ({ startIcon, className = '' }: WithIconControlProps) =>
    ({
        children,

        ...props
    }: PropsWithChildren<ControlOverideProps>) => {
        return (
            <components.Control
                // className={[className].join(' ')}
                className={[
                    selectSingleClasses.controlRecipe({
                        variant: 'third',
                    }),
                    paperClasses.recipe({
                        variant: 'outlineFilled',
                        color: 'gray',
                        borderRadius: 'small',
                    }),
                ].join(' ')}
                {...props}
            >
                {/* {startIcon} */}
                {children}
            </components.Control>
        );
    };

export { ControlPrimary, ControlSecondary, ControlThird };
export default ControlPrimary;
