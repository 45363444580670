import * as Yup from 'yup';

const HeadlineObj = {
    title: Yup.string()
        .required('Обязательное поле')
        .min(2, 'Слишком короткое название'),
    url: Yup.string().matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Укажите ссылку',
    ),
};

export const validateHeadline =
    Yup.object().shape(HeadlineObj);
