import React, { useEffect } from 'react';
import { RepostEdit as RepostEditForm } from '@mega/forms';
import {
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';

import {
    useRepostDispatch,
    useRepostStore,
} from '@mega/store';
import { useSnackbar } from 'notistack';
import { Repost } from '@mega/api';

export const RepostEdit: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();

    const { id } = useParams();
    const { loading, store } = useRepostStore();
    const { get, update } = useRepostDispatch();

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            get({ id: parseInt(id) });
        }
    }, [id, get]);

    if (loading.get.loading || !loading.get.success) {
        return <div>loading</div>;
    }

    let editedId: number;

    if (id) {
        editedId = parseInt(id);
    } else {
        throw new Error('The editable entity has no id.');
    }

    const handleUpdate = async (payload: Repost) => {
        await update({
            query: {
                id: editedId,
            },
            payload: {
                id: editedId,
                link: payload.link,
                is_important: payload.is_important,
                link_redirect: payload.link_redirect,
                is_active: payload.is_active,
            },
            options: {
                notifier: {
                    enqueueSnackbar,
                },
                router: {
                    navigate,
                    location,
                },
            },
        });
    };

    return (
        <RepostEditForm
            handleUpdate={handleUpdate}
            initialValue={store}
        />
    );
};
