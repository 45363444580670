import { parse, ParsedQuery } from 'query-string';
import { omit } from 'lodash-es';

export interface StatePaginateTransformType {
    readonly count: number;
    readonly page: number;
    readonly next: number | null;
    readonly prev: number | null;
    readonly nextQuery: ParsedQuery<string>;
    readonly prevQuery: ParsedQuery<string>;
    readonly pageCount: number;
}

class StatePaginateTransform
    implements StatePaginateTransformType
{
    public readonly count: number;
    public readonly page: number;
    public readonly next: number | null;
    public readonly prev: number | null;
    public readonly nextQuery: ParsedQuery<string>;
    public readonly prevQuery: ParsedQuery<string>;
    public readonly pageCount: number;

    constructor(
        {
            count,
            next: nextC,
            previous: prevC,
        }: {
            count: number;
            next: string | null;
            previous: string | null;
            results: unknown;
        },
        perPage: number,
    ) {
        this.count = count;
        const prevPaginateObject = parse(prevC || '');
        const nextPaginateObject = parse(nextC || '');

        const prevPage = prevPaginateObject.per_page
            ? prevPaginateObject?.page || '1'
            : prevPaginateObject?.page || null;

        const nextPage = nextPaginateObject?.page || null;

        this.page =
            nextPage && !Array.isArray(nextPage)
                ? parseInt(nextPage) - 1
                : prevPage && !Array.isArray(prevPage)
                ? parseInt(prevPage) + 1
                : 1;

        this.next =
            nextPage && !Array.isArray(nextPage)
                ? parseInt(nextPage)
                : null;

        this.prev =
            prevPage && !Array.isArray(prevPage)
                ? parseInt(prevPage)
                : null;

        this.nextQuery = omit(nextPaginateObject, 'page');
        this.prevQuery = omit(prevPaginateObject, 'page');
        this.pageCount = Math.ceil(count / perPage);
    }
}

export { StatePaginateTransform };
