import React, {
    FC,
    useState,
    useMemo,
    useEffect,
} from 'react';
import { Formik, Form, useField } from 'formik';
import { useTable } from 'react-table';

import {
    Button,
    Row,
    InputDateField,
    SelectSingleField,
    IconButton,
} from '@mega/ui';
import { Add } from '@mega/icons';
import { articleEditMultipostClasses } from './ArticleMultipostForm.css';

import {
    useArticleMultipostStore,
    useArticleMultipost,
    useArticleStore,
    useSiteStore,
    useUserInfo,
} from '@mega/store';
import { getOptionsCategoriesMultipost } from '@mega/core';

import { categories } from '@mega/api';
const { get } = categories;

type ArticleCopy = {
    site: { id: string; label: string };
    label: string;
    is_active: boolean;
    category: {
        id?: number | null;
        label?: string | null;
    } | null;
    hasSameCategory: boolean | null;
    pub_date: string;
    selected: boolean;
};

type ArticleEditMultipostFormProps = {};

const ArticleEditMultipostForm: FC<
    ArticleEditMultipostFormProps
> = (): JSX.Element => {
    const { sites } = useUserInfo();
    const { label } = useSiteStore();
    const store = useArticleStore();
    const dataArticle = store.data;
    const categoryArticleLabel =
        dataArticle?.category.label;
    const dateArticle = dataArticle?.pub_date;
    const storeMultipost = useArticleMultipostStore();
    const articlesMultiposted = storeMultipost.data
        ? storeMultipost.data.copies
        : [];
    const sitesMultiposted = articlesMultiposted.map(
        (el) => el.site.label,
    );
    const availableSites = sites.filter((el) => {
        if (el.label === label) return false;
        return !sitesMultiposted.includes(el.label);
    });
    const { createCopies } = useArticleMultipost();
    const [
        isDisabledButtonSubmit,
        setIsDisabledButtonSubmit,
    ] = useState<boolean>();

    const [data, setData] = useState<ArticleCopy[]>(() => {
        const res = availableSites?.map((el) => {
            return {
                site: { id: el.id, label: el.label },
                label: el.label,
                category: null,
                pub_date: dateArticle,
                is_active: true,
                selected: false,
                hasSameCategory: null,
            };
        });
        return res;
    });

    useEffect(() => {
        const fetchData = async () => {
            const promises = data.map((item) =>
                item.hasSameCategory !== null
                    ? Promise.resolve(item)
                    : get(
                          {
                              search: categoryArticleLabel,
                              //@ts-ignore
                              site: item.site.id,
                          },
                          {
                              headers: {
                                  'X-Site': item.site.id,
                              },
                          },
                      ).then((response) => {
                          const categories =
                              response.data.results;

                          const hasSameCategory =
                              categories.length > 0;
                          const exactMatchCategory =
                              categories.find(
                                  (el) =>
                                      el.title ===
                                      categoryArticleLabel,
                              );
                          return {
                              ...item,
                              hasSameCategory:
                                  hasSameCategory,
                              category: exactMatchCategory
                                  ? {
                                        id: exactMatchCategory.id,
                                        label: exactMatchCategory.title,
                                    }
                                  : item.category,
                          };
                      }),
            );

            const results = await Promise.all(promises);
            setData(results);
        };

        fetchData().catch(console.error);
    }, []);

    const handleSelectChange = (siteLabel: string) => {
        setData((prevState) =>
            prevState.map((row) => {
                if (row.label === siteLabel) {
                    return {
                        ...row,
                        selected: !row.selected,
                    };
                }
                return row;
            }),
        );
    };

    const handleSelectIsActive = (siteLabel: string) => {
        setData((prevState) =>
            prevState.map((row) => {
                if (row.label === siteLabel) {
                    return {
                        ...row,
                        is_active: !row.is_active,
                    };
                }
                return row;
            }),
        );
    };

    const handleAddCategoty = (
        siteLabel: string,
        category: {
            id: string | null | number;
            label: string;
        } | null = null,
    ) => {
        setData((prevState) => {
            return prevState.map((row) => {
                if (row.label === siteLabel) {
                    return {
                        ...row,
                        category: category
                            ? category
                            : {
                                  id: null,
                                  label: categoryArticleLabel,
                              },
                    };
                }
                return row;
            });
        });
    };

    const [fieldselectedAll] = useField(`all_selected`);
    const [fieldIsActiveAll] = useField(`all_is_active`);

    const columns = useMemo(
        () => [
            {
                Header: () => {
                    return (
                        <Row gap="8">
                            <input
                                type="checkbox"
                                name={`all_selected`}
                                onChange={(e) => {
                                    setData((prevState) => {
                                        return prevState.map(
                                            (el) => {
                                                return {
                                                    ...el,
                                                    selected:
                                                        e
                                                            .target
                                                            .checked,
                                                };
                                            },
                                        );
                                    });
                                    fieldselectedAll.onChange(
                                        e,
                                    );
                                }}
                            />
                            <p>Выбрать всё</p>
                        </Row>
                    );
                },
                accessor: 'selected',
                Cell: ({ row }) => {
                    return (
                        <Row gap="8">
                            <input
                                type="checkbox"
                                name={`rows[${row.id}].selected`}
                                checked={
                                    row.original.selected ||
                                    false
                                }
                                onChange={() =>
                                    handleSelectChange(
                                        row.original.label,
                                    )
                                }
                            />
                            <p>{row.original.label}</p>
                        </Row>
                    );
                },
            },
            {
                Header: () => {
                    return (
                        <Row gap="8">
                            <input
                                type="checkbox"
                                name={`all_is_active`}
                                onChange={(e) => {
                                    setData((prevState) => {
                                        return prevState.map(
                                            (el) => {
                                                return {
                                                    ...el,
                                                    is_active:
                                                        !e
                                                            .target
                                                            .checked,
                                                };
                                            },
                                        );
                                    });
                                    fieldIsActiveAll.onChange(
                                        e,
                                    );
                                }}
                            />
                            <p>Черновик</p>
                        </Row>
                    );
                },
                accessor: 'is_active',
                Cell: ({ row }) => {
                    return (
                        <>
                            {row.cells[0].value ? (
                                <input
                                    type="checkbox"
                                    name={`rows[${row.id}].is_active`}
                                    checked={
                                        !row.values
                                            .is_active
                                    }
                                    onChange={() =>
                                        handleSelectIsActive(
                                            row.original
                                                .label,
                                        )
                                    }
                                />
                            ) : (
                                <></>
                            )}
                        </>
                    );
                },
            },
            {
                Header: () => (
                    <p style={{ textAlign: 'center' }}>
                        Рубрика
                    </p>
                ),
                accessor: 'category',
                Cell: ({ row }) => {
                    const isIconButton =
                        row.original.hasSameCategory ===
                        false;

                    return (
                        <div>
                            <Row>
                                {isIconButton ? (
                                    <IconButton
                                        type="button"
                                        onClick={() => {
                                            handleAddCategoty(
                                                row.original
                                                    .label,
                                            );
                                        }}
                                        style={{
                                            width: '100%',
                                            backgroundColor:
                                                '#EAEAEA',
                                            marginRight:
                                                '5px',
                                        }}
                                        size="large"
                                        classes={{
                                            paper: {
                                                color: 'gray',
                                            },
                                        }}
                                    >
                                        <Add />
                                    </IconButton>
                                ) : null}
                                <SelectSingleField
                                    name={`rows[${row.id}].category`}
                                    handleLoader={getOptionsCategoriesMultipost(
                                        {
                                            site: row
                                                .original
                                                .site.id,
                                        },
                                    )}
                                    actionOnSelectedOption={(
                                        e,
                                    ) => {
                                        if (e) {
                                            handleAddCategoty(
                                                row.original
                                                    .label,
                                                {
                                                    id: e?.value,
                                                    label: e?.label,
                                                },
                                            );
                                        }
                                    }}
                                />
                            </Row>
                        </div>
                    );
                },
            },
            {
                Header: () => (
                    <p style={{ textAlign: 'center' }}>
                        Время публикации
                    </p>
                ),
                accessor: 'date',
                Cell: ({ row }) => {
                    return (
                        <InputDateField
                            name={`rows[${row.id}].pub_date`}
                        />
                    );
                },
            },
        ],
        [],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    const handleSubmit = (values: {
        rows: ArticleCopy[];
    }) => {
        if (isDisabledButtonSubmit) {
            return;
        } else {
            setIsDisabledButtonSubmit(true);
            const selectedRows = values.rows.filter(
                (row) => row.selected,
            );
            const processedRows = selectedRows.map(
                ({ selected, label, ...row }) => row,
            );
            const filteredRows = processedRows.map((el) => {
                return {
                    ...el,
                    category: el.category
                        ? el.category.id
                        : null,
                    site: el.site.id,
                };
            });
            createCopies(filteredRows).then(() => {
                window.location.reload();
                setIsDisabledButtonSubmit(false);
            });
        }
    };

    return (
        <Formik
            initialValues={{ rows: data }}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {() => (
                <Form>
                    <table
                        {...getTableProps()}
                        className={
                            articleEditMultipostClasses.table
                        }
                    >
                        <thead>
                            {headerGroups.map(
                                (headerGroup) => (
                                    <tr
                                        {...headerGroup.getHeaderGroupProps()}
                                        className={
                                            articleEditMultipostClasses.tr
                                        }
                                    >
                                        {headerGroup.headers.map(
                                            (column) => (
                                                <th
                                                    {...column.getHeaderProps()}
                                                    className={
                                                        articleEditMultipostClasses.th
                                                    }
                                                >
                                                    {column.render(
                                                        'Header',
                                                    )}
                                                </th>
                                            ),
                                        )}
                                    </tr>
                                ),
                            )}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                        className={
                                            articleEditMultipostClasses.tr
                                        }
                                    >
                                        {row.cells.map(
                                            (cell) => (
                                                <td
                                                    {...cell.getCellProps()}
                                                    className={
                                                        articleEditMultipostClasses.td
                                                    }
                                                >
                                                    {cell.render(
                                                        'Cell',
                                                    )}
                                                </td>
                                            ),
                                        )}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <Button
                        label="Опубликовать"
                        type="submit"
                        size="large"
                        style={{
                            marginTop: '30px',
                            marginBottom: '30px',
                        }}
                        disabled={
                            isDisabledButtonSubmit ||
                            data.filter((el) => el.selected)
                                .length === 0
                        }
                    />
                </Form>
            )}
        </Formik>
    );
};

export default ArticleEditMultipostForm;
export { ArticleEditMultipostForm };
