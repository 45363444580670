import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLayoutEffect } from 'react';
import { EditorState } from 'lexical';

export const CounterInitPlugin = ({
    handleChange,
}: {
    handleChange: (editorState: EditorState) => void;
}) => {
    const [editor] = useLexicalComposerContext();

    useLayoutEffect(() => {
        const editorState = editor.getEditorState();
        handleChange(editorState);
    }, [editor]);

    return null;
};
