import React, { FC, useEffect, useMemo } from 'react';
import { CellValue, Column } from 'react-table';
import type { Videos as VideosType } from '@mega/api';
import { Icon, IconButton, ListingTable } from '@mega/ui';
import { typographyClasses } from '@mega/styles';
import { fmtMSS } from '@mega/admin/src/utils';
import {
    useUserInfo,
    useVideosDispatch,
    useVideosStore,
} from '@mega/store';
import { headItem, listingRecipe } from '../Listing.css';
import { TitleColumn, CoverColumn } from '../Columns';
import { format } from 'date-fns';
import { Close } from '@mega/icons';
import { useParams } from 'react-router';
import { AcceptModal } from '../../AcceptModal';
export interface ListingVideosProps {}

const videoStatus = new Map<string | undefined, string>(
    Object.entries({
        deleted: 'Удалено',
        pending: 'В ожидании',
        processed: 'Обработано',
        uploaded: 'Загружено',
    }),
).set(undefined, 'неизвестно');

const ListingVideos: FC<ListingVideosProps> = () => {
    const test = useParams();
    const { isPermitted } = useUserInfo();

    const store = useVideosStore();
    const { get, clear, removeFromListiong } =
        useVideosDispatch();

    useEffect(() => {
        get({});
        return () => {
            clear();
        };
    }, [get, clear]);

    const columns: Column<
        VideosType & { actions: string }
    >[] = React.useMemo(
        () => [
            {
                Header: 'Заголовок',
                accessor: (row) => {
                    return {
                        title: row.title,
                        id: row.id,
                    };
                },
                Cell: ({ value }: CellValue) => (
                    <TitleColumn {...value} />
                ),
                minWidth: 200,
                width: '100%',
            },
            {
                Header: 'Статус',
                accessor: 'status',

                Cell: ({ value }) => {
                    return (
                        <div
                            style={{ textAlign: 'center' }}
                        >
                            {videoStatus.get(value)}
                        </div>
                    );
                },
            },
            {
                Header: 'Обложка',
                accessor: (row) => {
                    return {
                        video: row.file_link,
                        cover: row.cover,
                    };
                },
                width: '100%',
                minWidth: 50,
                maxWidth: 80,
                Cell: ({ value }: CellValue) => {
                    return (
                        <CoverColumn
                            video={value.video}
                            cover={value.cover}
                        />
                    );
                },
            },
            {
                Header: 'Продолжительность',
                accessor: 'video_duration',
                width: '100%',
                minWidth: 100,
                maxWidth: 150,
                Cell: ({ value }) => {
                    const time = value
                        ? fmtMSS(Math.trunc(value))
                        : '-';

                    return (
                        <div
                            style={{ textAlign: 'center' }}
                        >
                            {time}
                        </div>
                    );
                },
            },
            {
                Header: 'Дата добавления',
                accessor: 'video_release_date',
                width: '100%',
                minWidth: 100,
                maxWidth: 150,
                Cell: ({ value }) => {
                    const test = format(
                        new Date(value),
                        'dd.MM.yyyy в HH:mm',
                    );
                    return (
                        <div
                            style={{ textAlign: 'center' }}
                        >
                            {test}
                        </div>
                    );
                },
            },
            {
                Header: 'Действия',
                accessor: 'id',
                Cell: ({ value }) => {
                    const id = value || null;
                    if (!id) {
                        return null;
                    }
                    const isDeletePermitted = isPermitted({
                        rootPermissions:
                            test?.entities || '',
                        //TODO add subrootPermissions string to constant or object keys
                        subRootPermissions: 'delete',
                    });

                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            {isDeletePermitted && (
                                <AcceptModal
                                    msg="Вы уверенны что хотите удалить новость ?"
                                    Trigger={
                                        <IconButton
                                            animate="none"
                                            onClick={() => {
                                                if (id) {
                                                    removeFromListiong(
                                                        id,
                                                    );
                                                }
                                            }}
                                            classes={{
                                                paper: {
                                                    color: 'white',
                                                },
                                            }}
                                        >
                                            <Icon size="25">
                                                <Close
                                                    style={{
                                                        color: '#EB5757CC',
                                                    }}
                                                />
                                            </Icon>
                                        </IconButton>
                                    }
                                />
                            )}
                        </div>
                    );
                },
            },
        ],
        [],
    );

    const data = useMemo(() => {
        const values =
            store.pages?.[store.currentPage] || [];
        return Object.values(values).flat();
    }, [store.pages]);

    return (
        <>
            <ListingTable
                getCellProps={(cellInfo) => {
                    return {
                        style: {},
                    };
                }}
                getHeaderGroupProps={(cellInfo) => {
                    return {
                        className: listingRecipe({
                            header: true,
                        }),
                    };
                }}
                getHeaderProps={(cellInfo) => {
                    return {
                        className: headItem({
                            title:
                                cellInfo?.id ===
                                'Заголовок',
                        }),
                    };
                }}
                getRowGroupProps={() => {
                    return {
                        className: [
                            typographyClasses.recipe({
                                size: '14',
                            }),
                            listingRecipe({
                                header: false,
                            }),
                        ].join(' '),
                    };
                }}
                columns={columns}
                data={data}
            />
        </>
    );
};

export { ListingVideos };
