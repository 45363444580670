/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Control } from '../../../../types/core';
import { NumOfTheDay } from './NumOfTheDay';
import {
    NumOfTheDayEnum,
    NumOfTheDayPluginDefault,
} from './NumOfTheDay.types';

const PLUGIN_NUMOFTHEDAY_NAME = NumOfTheDayEnum.type;

const DEFAULT_NUMOFTHEDAY: NumOfTheDayPluginDefault = {
    type: PLUGIN_NUMOFTHEDAY_NAME,
    meta: {},
    childrenElement: [],
};

const PLUGIN_NUMOFTHEDAY = {
    [PLUGIN_NUMOFTHEDAY_NAME]: {
        emptyObject: DEFAULT_NUMOFTHEDAY,
        render: NumOfTheDay,
    },
};

const PLUGIN_NUMOFTHEDAY_CONTROL: Control = {
    name: PLUGIN_NUMOFTHEDAY_NAME,
    label: 'NumOfTheDay',
    tooltip: 'Создать цифру дня',
    Icon: () => <></>,
};

export {
    DEFAULT_NUMOFTHEDAY,
    PLUGIN_NUMOFTHEDAY,
    PLUGIN_NUMOFTHEDAY_CONTROL,
    PLUGIN_NUMOFTHEDAY_NAME,
};
