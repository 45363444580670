import React, {
    FC,
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    HeadTemplatePlugin,
    TemplatePlugin,
} from '@mega/editor';
import { useEditor } from '@apostroph/store';
import { RichEditor } from './Editor';
import { BlockEnum } from '../Block/Block.types';
import { InAgents, LexMeta, LexProps } from './Lex.types';
import { Typography, Icon, Divider } from '@mega/ui';
import { Subject } from '@mega/icons';
import {
    LexContext,
    LexContextProvider,
} from './context/lex.context';
import { Portal } from '@mui/material';
import { LexDataContextProvider } from './context/lexData.context';
import { useAphostrophExternalDataContext } from '../../feature/context/AphostrophExternalData';

const LexMenuPortal: FC<{
    children: ReactElement | ReactElement[];
}> = ({ children }) => {
    const [isMount, setIsMount] = useState(false);
    const context = useContext(LexContext);

    useEffect(() => {
        if (context?.menu?.current) {
            setIsMount(true);
        }
    }, [context?.menu?.current]);

    if (isMount) {
        return (
            <Portal container={context?.menu?.current}>
                {children}
            </Portal>
        );
    }

    return null;
};

const Lex: FC<LexProps> = ({ id, parentId, type }) => {
    const menuRef = useRef<HTMLDivElement>(null);

    const {
        handleUpdateElement,
        handleCreateElement,
        getCurrentDataElement,
        editor,
        handleInsertElement,
        getTemplate,
        handleRemoveElement,
    } = useEditor();
    const context = useAphostrophExternalDataContext();

    const {
        meta: { content = '', ...other },
        childrenElement,
    } = getCurrentDataElement<LexMeta>(id);

    const contentBlock =
        editor?.data?.[editor?.items?.[1]]?.id || null;

    const isLead =
        editor?.data?.[`${parentId}`]?.type ===
        BlockEnum.altType;

    const handleCreate =
        (content: string, type?: string) => () => {
            const template = getTemplate('MILK');

            if (template && content) {
                handleCreateElement(
                    {
                        ...template,
                        meta: {
                            ...template.meta,
                            content: JSON.stringify({
                                root: {
                                    children: [
                                        {
                                            children: [
                                                {
                                                    detail: 0,
                                                    format: 2,
                                                    mode: 'normal',
                                                    style: '',
                                                    text: content,
                                                    type: 'text',
                                                    version: 1,
                                                },
                                            ],
                                            direction:
                                                'ltr',
                                            format: '',
                                            indent: 0,
                                            type: 'paragraph',
                                            version: 1,
                                        },
                                    ],
                                    direction: 'ltr',
                                    format: '',
                                    indent: 0,
                                    type: 'root',
                                    version: 1,
                                },
                            }),
                            isAnnotation: true,
                            annotation: type,
                        },
                    },
                    contentBlock,
                );
            }
        };

    if (
        typeof content === 'string' &&
        !Boolean(content === '')
    ) {
        const obj = JSON.parse(content);
        //@ts-ignore
        if (obj?.root?.children?.length === 0) {
            return (
                <LexDataContextProvider
                    isLead={isLead}
                    id={id}
                >
                    <LexContextProvider menu={menuRef}>
                        <>
                            <TemplatePlugin
                                style={{
                                    border: 'red 1px solid',
                                    backgroundColor:
                                        other.isAnnotation
                                            ? other.annotation ===
                                              'extremist'
                                                ? '#fce5cd70'
                                                : other.annotation ===
                                                  'terrorist'
                                                ? '#f4cccc70'
                                                : '#c9daf870'
                                            : 'white',
                                }}
                                head={
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection:
                                                'column',
                                            gap: '10px',
                                        }}
                                    >
                                        <HeadTemplatePlugin
                                            id={id}
                                            parentId={
                                                parentId
                                            }
                                            showMove={
                                                !isLead
                                            }
                                            onDelete={() => {
                                                context?.removeGliphs(
                                                    id,
                                                );
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display:
                                                        'flex',
                                                    flexDirection:
                                                        'row',
                                                    justifyContent:
                                                        'center',
                                                    alignItems:
                                                        'center',
                                                    gap: '4px',
                                                }}
                                            >
                                                <Icon size="16">
                                                    <Subject />
                                                </Icon>
                                                <Typography
                                                    size={
                                                        '12'
                                                    }
                                                >
                                                    {isLead
                                                        ? 'Лид-абзац'
                                                        : 'Текст'}
                                                </Typography>
                                                {!isLead && (
                                                    <div
                                                        ref={
                                                            menuRef
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </HeadTemplatePlugin>
                                        <div>
                                            {other.inAgents?.map(
                                                (item) => {
                                                    if (
                                                        other.isAnnotation
                                                    ) {
                                                        return null;
                                                    }
                                                    return (
                                                        <button
                                                            type="button"
                                                            onClick={handleCreate(
                                                                item.description ||
                                                                    item.title,
                                                                item.org_type,
                                                            )}
                                                            style={{
                                                                cursor: 'pointer',
                                                                display:
                                                                    'flex',
                                                                flexDirection:
                                                                    'row',
                                                                textAlign:
                                                                    'left',
                                                                border: 'none',
                                                                borderRadius:
                                                                    '5px',
                                                                padding:
                                                                    '5px 10px',
                                                                backgroundColor:
                                                                    item.org_type ===
                                                                    'extremist'
                                                                        ? '#fce5cd'
                                                                        : item.org_type ===
                                                                          'terrorist'
                                                                        ? '#f4cccc'
                                                                        : '#c9daf8',
                                                            }}
                                                        >
                                                            {item.org_type ===
                                                            'extremist'
                                                                ? 'Экстремисты: '
                                                                : item.org_type ===
                                                                  'terrorist'
                                                                ? 'Террористы: '
                                                                : 'Иноагент: '}
                                                            {
                                                                item.title
                                                            }
                                                        </button>
                                                    );
                                                },
                                            )}
                                        </div>
                                    </div>
                                }
                            >
                                <div
                                    style={{
                                        padding: '10px',
                                        color: 'red',
                                    }}
                                >
                                    Данный блок содержит
                                    критическую ошибку.
                                    Пожалуйста, удалите
                                    данный блок или
                                    обратитесь к
                                    администратору.
                                </div>
                            </TemplatePlugin>
                            {isLead && (
                                <div
                                    style={{
                                        marginTop: 12,
                                    }}
                                >
                                    <Divider />
                                </div>
                            )}
                        </>
                    </LexContextProvider>
                </LexDataContextProvider>
            );
        }
    }

    return (
        <LexDataContextProvider isLead={isLead} id={id}>
            <LexContextProvider menu={menuRef}>
                <>
                    <TemplatePlugin
                        style={{
                            backgroundColor:
                                other.isAnnotation
                                    ? other.annotation ===
                                      'extremist'
                                        ? '#fce5cd70'
                                        : other.annotation ===
                                          'terrorist'
                                        ? '#f4cccc70'
                                        : '#c9daf870'
                                    : 'white',
                        }}
                        head={
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                }}
                            >
                                <HeadTemplatePlugin
                                    id={id}
                                    parentId={parentId}
                                    showMove={!isLead}
                                    onDelete={() => {
                                        context?.removeGliphs(
                                            id,
                                        );
                                    }}
                                >
                                    <>
                                        <div
                                            style={{
                                                display:
                                                    'flex',
                                                flexDirection:
                                                    'row',
                                                justifyContent:
                                                    'center',
                                                alignItems:
                                                    'center',
                                                gap: '4px',
                                            }}
                                        >
                                            <Icon size="16">
                                                <Subject />
                                            </Icon>
                                            <Typography
                                                size={'12'}
                                            >
                                                {isLead
                                                    ? 'Лид-абзац'
                                                    : 'Текст'}
                                            </Typography>
                                        </div>
                                        {!isLead && (
                                            <div
                                                style={{
                                                    display:
                                                        'flex',
                                                    flexDirection:
                                                        'row-reverse',
                                                    width: '100%',
                                                }}
                                                ref={
                                                    menuRef
                                                }
                                            />
                                        )}
                                    </>
                                </HeadTemplatePlugin>
                                <div>
                                    {other.inAgents?.map(
                                        (item) => {
                                            if (
                                                other.isAnnotation
                                            ) {
                                                return null;
                                            }
                                            return (
                                                <button
                                                    type="button"
                                                    onClick={handleCreate(
                                                        item.description ||
                                                            item.title,
                                                        item.org_type,
                                                    )}
                                                    style={{
                                                        cursor: 'pointer',
                                                        display:
                                                            'flex',
                                                        flexDirection:
                                                            'row',
                                                        textAlign:
                                                            'left',
                                                        border: 'none',
                                                        borderRadius:
                                                            '5px',
                                                        padding:
                                                            '5px 10px',
                                                        backgroundColor:
                                                            item.org_type ===
                                                            'extremist'
                                                                ? '#fce5cd'
                                                                : item.org_type ===
                                                                  'terrorist'
                                                                ? '#f4cccc'
                                                                : '#c9daf8',
                                                    }}
                                                >
                                                    {item.org_type ===
                                                    'extremist'
                                                        ? 'Экстремисты: '
                                                        : item.org_type ===
                                                          'terrorist'
                                                        ? 'Террористы: '
                                                        : 'Иноагент: '}
                                                    {
                                                        item.title
                                                    }
                                                </button>
                                            );
                                        },
                                    )}
                                </div>
                            </div>
                        }
                    >
                        <RichEditor
                            {...(context?.isReadonly
                                ? { key: content }
                                : {})}
                            isLead={isLead}
                            content={content}
                            onChange={(content) => {
                                handleUpdateElement({
                                    type,
                                    id,
                                    meta: {
                                        ...other,
                                        content,
                                    },
                                    childrenElement,
                                });
                            }}
                            setInAgent={(
                                inAgents,
                                content,
                            ) => {
                                handleUpdateElement({
                                    type,
                                    id,
                                    meta: {
                                        ...other,
                                        content: content,
                                        inAgents: inAgents,
                                    },
                                    childrenElement,
                                });
                            }}
                            handleContentCut={(props) => {
                                let template =
                                    getTemplate('MILK');

                                const topTemplate = {
                                    ...template,
                                    meta: {
                                        ...(template?.meta ||
                                            {}),
                                        content:
                                            props.topEditor,
                                    },
                                };

                                const middleTemplate = {
                                    ...template,
                                    meta: {
                                        ...(template?.meta ||
                                            {}),
                                        content:
                                            props.middleEditor,
                                    },
                                };

                                const bottomTemplate = {
                                    ...template,
                                    meta: {
                                        ...(template?.meta ||
                                            {}),
                                        content:
                                            props.bottomEditor,
                                    },
                                };

                                if (template) {
                                    if (props.topEditor) {
                                        handleInsertElement(
                                            topTemplate,
                                            id,
                                            parentId,
                                        );
                                    }
                                    if (
                                        props.middleEditor
                                    ) {
                                        handleInsertElement(
                                            middleTemplate,
                                            id,
                                            parentId,
                                        );
                                    }

                                    if (
                                        props.bottomEditor
                                    ) {
                                        handleInsertElement(
                                            bottomTemplate,
                                            id,
                                            parentId,
                                        );
                                    }

                                    handleRemoveElement({
                                        what: id,
                                        parent: parentId,
                                    });
                                    context?.removeGliphs(
                                        id,
                                    );
                                }
                            }}
                        />
                    </TemplatePlugin>
                    {isLead && (
                        <div style={{ marginTop: 12 }}>
                            <Divider />
                        </div>
                    )}
                </>
            </LexContextProvider>
        </LexDataContextProvider>
    );
};

export { Lex, LexMenuPortal };
export default Lex;
