import { createModel } from '@rematch/core';
import * as CLIENT from '@mega/api';
import type {
    Authors,
    AuthorsQuery,
    AuthorsWithPagination,
} from '@mega/api';
import { debounce, compact, isUndefined } from 'lodash-es';
import type { RootModel } from '../rootModel';
import { DefaultStore } from '../type';
import { calculatePagesCount } from '@mega/utils';

interface AuthorsStoreCustom
    extends DefaultStore<Authors, AuthorsQuery> {
    customQuery?: {
        sites?: Array<string>;
    };
}
const urlParams = new URLSearchParams(location.search);

const defaultStore: AuthorsStoreCustom = {
    currentPage: 1,
    query: {
        per_page: 50,
    },
    meta: {
        count: null,
    },
    pages: {},
    hasMore: false,
};

const authors = createModel<RootModel>()({
    name: 'authors',
    state: defaultStore,
    reducers: {
        changePerPage: (
            state,
            payload: { perPage: number },
        ) => {
            return { ...state, perPage: payload.perPage };
        },
        putCurrentPage: (
            state,
            payload: { triggerPage: number },
        ) => {
            return {
                ...state,
                currentPage: payload.triggerPage,
            };
        },
        putPage: (
            state,
            payload: AuthorsWithPagination,
        ) => {
            return {
                ...state,
                meta: {
                    count: isUndefined(payload.count)
                        ? null
                        : payload.count,
                },
                pages: {
                    ...state.pages,
                    [`${state.currentPage}`]:
                        payload.results,
                },
            };
        },
        putQuery: (state, payload: AuthorsQuery) => {
            return {
                ...state,
                query: { ...state.query, ...payload },
            };
        },
        putCustomQuery: (
            state,
            payload: AuthorsStoreCustom['customQuery'],
        ) => {
            return {
                ...state,
                customQuery: {
                    ...state.customQuery,
                    ...payload,
                },
            };
        },
        clear: () => {
            return defaultStore;
        },
    },
    effects: (dispatch) => ({
        /* #region  Search */
        search: async (
            query: Pick<AuthorsQuery, 'search'>,
        ) => {
            await dispatch.authors.putQuery(query);
            await dispatch.authors.afterSearch(null);
        },
        afterSearch: debounce((_, state) => {
            dispatch.authors.get(state.authors.query);
        }, 1000),
        addCustomSiteQuery: async (
            payload: string | string[],
        ) => {
            if (Array.isArray(payload)) {
                await dispatch.authors.putCustomQuery({
                    sites: payload,
                });
                await dispatch.authors.get(null);
            } else {
                await dispatch.authors.putCustomQuery({
                    sites: [payload],
                });
                await dispatch.authors.get(null);
            }
        },
        /* #endregion */
        /* #region  Tags list for Read and pagination */
        get: async (query: AuthorsQuery | null, store) => {
            const { query: storeQuery } = store.authors;

            try {
                const response = await CLIENT.authors.get?.(
                    //@ts-ignore
                    // тут проблема с типом sites/ ничего страшного
                    {
                        ...storeQuery,
                        ...(query ? query : {}),
                        ...(Boolean(store.site.site?.id)
                            ? {
                                  sites: compact([
                                      ...(store.authors
                                          .customQuery
                                          ?.sites || []),
                                  ]),
                              }
                            : {}),
                        is_count: true,
                        page: store.authors.currentPage,
                    },
                );
                if (response?.ok) {
                    const { data } = response;
                    dispatch.authors?.putPage(data);
                }
            } catch (e) {
                if (
                    CLIENT.authors.get &&
                    e instanceof CLIENT.authors.get.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },
        getOptions: async (query: AuthorsQuery, store) => {
            const { query: storeQuery } = store.authors;

            try {
                const response =
                    await CLIENT.authors.getOptions?.(
                        {
                            ...query,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );

                if (response?.ok) {
                    const { data } = response;
                    return data;
                }
            } catch (e) {
                if (
                    CLIENT.authors.getOptions &&
                    e instanceof
                        CLIENT.authors.getOptions.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },

        nextPage: async (_: any, store) => {
            const { query, currentPage } = store.authors;

            await dispatch.authors.putCurrentPage({
                triggerPage: currentPage + 1,
            });

            await dispatch.authors.get({
                ...query,
                page: currentPage + 1,
            });
        },
        setCurrentPage: async (
            payload: {
                triggerPage: number;
                action: 'next' | 'prev' | 'body';
            },
            store,
        ) => {
            const query = store.authors.query;
            const { triggerPage, action } = payload;

            switch (action) {
                case 'next': {
                    const count =
                        store?.authors?.meta?.count || 0;
                    const perPage =
                        store?.authors?.query?.per_page ||
                        1;
                    const isMaxPage =
                        calculatePagesCount(
                            perPage,
                            count,
                        ) <
                        triggerPage + 1;

                    if (isMaxPage) {
                        break;
                    }
                    dispatch.authors.get({
                        ...query,
                        page: triggerPage + 1,
                    });
                    break;
                }

                case 'body': {
                    dispatch.authors.putCurrentPage({
                        triggerPage,
                    });
                    dispatch.authors.get({
                        ...query,
                        page: triggerPage,
                    });

                    break;
                }

                case 'prev': {
                    if (triggerPage !== 1) {
                        dispatch.authors.get({
                            ...query,
                            page: triggerPage - 1,
                        });
                    }
                    break;
                }

                default:
                    break;
            }
        },
        jumpTo: async (page: number) => {
            await dispatch.authors.putQuery({ page: page });
            await dispatch.authors.putCurrentPage({
                triggerPage: page,
            });
            dispatch.authors.get({
                page: page,
            });
        },
        chungeCountItemsOnPage: async (
            perPage: number,
            state,
        ) => {
            if (
                Math.floor(
                    state?.authors?.meta.count ??
                        0 / perPage,
                ) > 0
            ) {
                await dispatch.authors.changePerPage({
                    perPage,
                });
                await dispatch.authors.get({});
            }
        },
        /* #endregion */
    }),
});

export { authors };
export default authors;
