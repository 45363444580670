/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Control } from '../../../../types/core';
import { MostRead } from './MostRead';
import {
    MostReadEnum,
    MostReadPluginDefault,
} from './MostRead.types';

const PLUGIN_MOST_READ_NAME = MostReadEnum.type;

const DEFAULT_MOST_READ: MostReadPluginDefault = {
    type: PLUGIN_MOST_READ_NAME,
    meta: {},
    childrenElement: [],
};

const PLUGIN_MOST_READ = {
    [PLUGIN_MOST_READ_NAME]: {
        emptyObject: DEFAULT_MOST_READ,
        render: MostRead,
    },
};

const PLUGIN_MOST_READ_CONTROL: Control = {
    name: PLUGIN_MOST_READ_NAME,
    label: 'MostRead',
    tooltip: 'Самое читаемое',
    Icon: () => <></>,
};

export {
    DEFAULT_MOST_READ,
    PLUGIN_MOST_READ,
    PLUGIN_MOST_READ_CONTROL,
    PLUGIN_MOST_READ_NAME,
};
