import React, { FC, useEffect } from 'react';
import { AcceptModal, CardStory } from '@mega/core';
import {
    useStoryDispatch,
    useStoryStore,
} from '@mega/store';
import {
    Button,
    Loader,
    Paper,
    Row,
    Stack,
} from '@mega/ui';
import { Form, Formik } from 'formik';
import {
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Story } from '@mega/api';
import { getTypographedText } from '@mega/utils';

export interface StoryEditProps {}

const StoriesAction: FC<{
    onTypograph?: () => void;
    onDelete?: () => void;
    save?: () => void;
}> = ({
    onTypograph = () => undefined,
    onDelete = () => undefined,
    save = () => undefined,
}) => {
    return (
        <Paper
            style={{
                borderTop: '1px solid #EAEAEA',
                padding: '24px 0',
            }}
            fullWidth
            color={'white'}
            variant="filled"
        >
            <Row gap="8">
                <Button
                    classes={{
                        paper: {
                            variant: 'filled',
                            color: 'dark',
                        },
                    }}
                    color="secondary"
                    size={'large'}
                    type={'submit'}
                    label={'Сохранить изменения'}
                    onClick={save}
                />
                <Button
                    size={'large'}
                    label="Типографировать текст"
                    classes={{
                        paper: {
                            variant: 'filled',
                            color: 'dark',
                        },
                    }}
                    onClick={() => {
                        onTypograph();
                    }}
                />
                <AcceptModal
                    msg="Вы уверенны что хотите удалить историю?"
                    Trigger={
                        <Button
                            size={'large'}
                            label="Удалить"
                            classes={{
                                paper: {
                                    variant: 'filled',
                                    color: 'red',
                                },
                            }}
                            onClick={() => {
                                onDelete();
                            }}
                        />
                    }
                />
            </Row>
        </Paper>
    );
};

const StoryEdit: FC<StoryEditProps> = () => {
    const { id } = useParams();
    const { loading, store } = useStoryStore();
    const { get, remove, update } = useStoryDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            get({ id });
        }
    }, [id, get]);

    if (loading.get.loading || !loading.get.success) {
        return <Loader size="large" />;
    }

    const handleSave = async (values: Story) => {
        const formattedValues = {
            ...values,
            cover: values.cover?.id,
            cards: values.cards?.map((card) => ({
                ...card,
                id:
                    typeof card.id === 'number'
                        ? card?.id
                        : undefined,
                image: card.image?.id || null,
                video: card.video?.id || null,
            })),
        };

        if (values.id) {
            await update({
                query: {
                    id: values.id,
                },
                // @ts-ignore
                payload: formattedValues,
                options: {
                    notifier: {
                        enqueueSnackbar,
                    },
                    router: {
                        navigate,
                        location,
                    },
                },
            });
        }
    };

    const handleDelete = () => {
        if (id) {
            remove({
                payload: { id },
                options: {
                    router: {
                        navigate,
                        location,
                    },
                    notifier: {
                        enqueueSnackbar,
                    },
                },
            });
        }
    };

    return (
        <Formik
            initialValues={store}
            onSubmit={handleSave}
            enableReinitialize
        >
            {({ values, setValues }) => {
                const typographFields = async () => {
                    const formattedValues = {
                        ...values,
                        title: getTypographedText(
                            values.title,
                        ),
                        theme: getTypographedText(
                            values.theme || '',
                        ),
                        cards: values?.cards?.map(
                            (card) => ({
                                ...card,
                                title: getTypographedText(
                                    card.title || '',
                                ),
                                description:
                                    getTypographedText(
                                        card.description ||
                                            '',
                                    ),
                                button_text:
                                    getTypographedText(
                                        card.button_text ||
                                            '',
                                    ),
                            }),
                        ),
                    };

                    await setValues(formattedValues);
                };

                return (
                    <Form>
                        <Stack gap={'16'}>
                            <CardStory />
                            <StoriesAction
                                onDelete={handleDelete}
                                onTypograph={
                                    typographFields
                                }
                            />
                        </Stack>
                    </Form>
                );
            }}
        </Formik>
    );
};

export { StoryEdit };
