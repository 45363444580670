import React, { useEffect } from 'react';
import { Loader } from '@mega/ui';
import {
    useMainGridDispatch,
    useMainGridStore,
} from '@mega/store';
import { useSnackbar } from 'notistack';
import { FormikWrapper } from '@pages/smartGrid/components/FormikWrapper';
import { Tabs } from '@pages/smartGrid/components/Tabs';
import { MainGrid } from '@mega/api';
import { v4 as uuidv4 } from 'uuid';
import { defaultItem } from '@pages/smartGrid/constants/data';

export interface SmartGridProps {}

//  TODO упростить
export const SmartGrid: React.FC<SmartGridProps> = (
    props,
) => {
    const {
        get,
        clear,
        getLatest,
        setActive,
        create,
        update,
        getItems,
    } = useMainGridDispatch();
    const store = useMainGridStore();
    const { enqueueSnackbar } = useSnackbar();

    const { loading, list, settingsIsOpen } = store;
    useEffect(() => {
        get({});
        return () => {
            clear();
        };
    }, [get, clear, getLatest]);

    const {
        loading: isLoading,
        error,
        success,
    } = loading.get;

    useEffect(() => {
        if (success) {
            getLatest({});
        }
    }, [success]);

    const {
        loading: isLoadingLatest,
        error: errorLatest,
        success: successLatest,
    } = loading.getLatest;

    const isLoadingGlobal =
        isLoading ||
        (!error && !success) ||
        isLoadingLatest ||
        (!errorLatest && !successLatest);

    const handleActive = (activeItem: MainGrid) => {
        setActive(activeItem);
        // if (activeItem.editor_data)
        //     getItems({
        //         payload: {
        //             editor_data: activeItem.editor_data,
        //         },
        //     });
    };

    const handleCopy = (values?: MainGrid) => {
        let payload: MainGrid = {
            editor_data: defaultItem(uuidv4()),
        };
        if (values?.id) {
            payload = { ...values, is_active: false };
        }
        return create({
            payload,
            options: {
                notifier: {
                    enqueueSnackbar,
                },
            },
        });
    };

    const handleUpdate = (
        id: number,
        payload: MainGrid,
    ) => {
        return update({
            query: { id: id },
            payload: payload,
            options: {
                notifier: {
                    enqueueSnackbar,
                },
            },
        });
    };

    return (
        <div>
            <Tabs
                data={list}
                onChange={handleActive}
                handleCopy={handleCopy}
                disabled={settingsIsOpen}
                handleUpdate={handleUpdate}
            />
            {isLoadingGlobal ? (
                <Loader size="medium" />
            ) : (
                <FormikWrapper />
            )}
        </div>
    );
};
