import * as CLIENT from '@mega/api';
import { ArticleForm } from '@mega/api';
import { createModel } from '@rematch/core';
import type { RootModel } from '../rootModel';
import { useSnackbar } from 'notistack';

export interface ArticleMultipostModel {
    data: { copies: [] } | null;
}

const articleMultipost = createModel<RootModel>()({
    name: 'articleMultipost',
    state: {
        data: null,
    } as ArticleMultipostModel,
    reducers: {
        put: (state, payload: { copies: [] }) => {
            return {
                ...state,
                data: { ...state.data, ...payload },
            };
        },
    },
    effects: (dispatch) => ({
        get: async (id: number, store) => {
            try {
                const response =
                    await CLIENT.articles.getMultipostedArticles?.(
                        { id },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    dispatch.articleMultipost.put(
                        response.data,
                    );

                    return {
                        isOk: true,
                        data: response.data,
                    };
                }
            } catch (e) {
                if (
                    CLIENT.articles
                        .getMultipostedArticles &&
                    e instanceof
                        CLIENT.articles
                            .getMultipostedArticles.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return {
                                isOk: false,
                                value: error.data,
                            };
                        }
                        default: {
                            return {
                                isOk: false,
                                value: error.data,
                            };
                        }
                    }
                }
            }
        },
        update: async ({ query, payload }, store) => {
            try {
                const response =
                    await CLIENT.articles?.createMultipostedArticles?.(
                        {
                            copies: payload,
                            id: query.id,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    dispatch.articleMultipost.put(
                        response.data,
                    );

                    return {
                        isOk: true,
                        data: response.data,
                    };
                }
            } catch (e) {
                if (
                    CLIENT.articles
                        .createMultipostedArticles &&
                    e instanceof
                        CLIENT.articles
                            .createMultipostedArticles.Error
                ) {
                    const error = e.getActualType();

                    if (
                        CLIENT.articles
                            .createMultipostedArticles &&
                        e instanceof
                            CLIENT.articles
                                .createMultipostedArticles
                                .Error
                    ) {
                        const error = e.getActualType();
                        switch (error.status) {
                            case 401: {
                                return {
                                    isOk: false,
                                    value: error.data,
                                };
                            }
                            default: {
                                return {
                                    isOk: false,
                                    value: error.data,
                                };
                            }
                        }
                    }

                    return {
                        isOk: false,
                        error: error,
                    };
                }
            }
        },
    }),
});

export { articleMultipost };
export default articleMultipost;
