/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { FC, useMemo } from 'react';

import {
    Form,
    Formik,
    useFormikContext,
    FormikHelpers,
    Field,
} from 'formik';
import * as Yup from 'yup';

import type { Tag } from '@mega/api';

import {
    Accordion,
    Button,
    CheckboxSwitchField,
    FormLayout,
    InputField,
    Paper,
    Stack,
    WithLabel,
} from '@mega/ui';
import { throttle } from 'lodash-es';
import { IconLoader } from '@mega/core';

export interface TagEditProps {
    initialValue?: Tag;
    handleUpdate?: (
        value: Tag,
        actions: FormikHelpers<Tag>,
    ) => void;
}

const TagEditActions = () => {
    const { submitForm, isSubmitting } = useFormikContext();

    const throttledSubmit = useMemo(
        () => throttle(submitForm, 1000),
        [],
    );

    return (
        <>
            <Paper
                style={{
                    borderTop: '1px solid #EAEAEA',
                }}
                fullWidth
                color={'white'}
                variant="filled"
                classes={{
                    padding: { size: '24x24' },
                }}
            >
                <Button
                    animate="none"
                    size={'large'}
                    type={'submit'}
                    label={'Обновить'}
                    onClick={throttledSubmit}
                    classes={{
                        paper: {
                            color: 'dark',
                            variant: 'filled',
                        },
                    }}
                    disabled={isSubmitting}
                />
            </Paper>
        </>
    );
};

const TagEdit: FC<TagEditProps> = ({
    handleUpdate = () => null,
    initialValue = { title: '' },
}) => (
    <Formik
        validationSchema={Yup.object({
            title: Yup.string()
                .min(
                    1,
                    'Это поле явно должно быть больше одного символа',
                )
                .required('Это поле обязательное'),
        })}
        initialValues={{
            ...initialValue,
        }}
        onSubmit={handleUpdate}
    >
        <Form
            style={{
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <FormLayout
                actions={<TagEditActions />}
                settings={
                    <Paper
                        contentHeight
                        color={'white'}
                        variant="outline"
                        classes={{
                            padding: {
                                size: '24x24',
                            },
                        }}
                    >
                        <Stack gap="18">
                            <Accordion
                                label={'Настройки'}
                                value="settings"
                                isOpened
                            >
                                <Stack gap="16">
                                    <Stack gap="8">
                                        <CheckboxSwitchField
                                            name="is_active"
                                            label="Тег включен"
                                        />
                                        <CheckboxSwitchField
                                            name="is_theme"
                                            label="Тема для Дзен.Новостей"
                                        />
                                    </Stack>
                                </Stack>
                            </Accordion>
                        </Stack>
                    </Paper>
                }
            >
                <Paper
                    variant="outline"
                    color="white"
                    classes={{
                        padding: {
                            size: '24x24',
                        },
                    }}
                >
                    <Stack gap="18">
                        <InputField
                            type="text"
                            name={'title'}
                            placeholder="Название"
                            dimension="medium"
                            isRequired
                        />
                        <InputField
                            type="text"
                            name={'annotation'}
                            placeholder="Аннотация для Дзен.Новостей, если этот тег — тема"
                            dimension="medium"
                        />
                        <InputField
                            type="text"
                            name={'meta_title'}
                            placeholder="SEO-заголовок (заполняет специалист)"
                            dimension="medium"
                        />
                        <InputField
                            type="text"
                            name={'meta_description'}
                            placeholder="SEO-описание (заполняет специалист)"
                            dimension="medium"
                        />
                        <InputField
                            type="text"
                            name={'long_title'}
                            placeholder="Особый <h1> на странице (заполняет специалист)"
                            dimension="medium"
                        />
                        <InputField
                            type="text"
                            name={'slug'}
                            placeholder="Буквенная часть url-адреса (заполняется автоматически)"
                            dimension="medium"
                        />
                        <WithLabel title={'Иконка'}>
                            <Field
                                name="icon"
                                component={IconLoader}
                            />
                        </WithLabel>
                    </Stack>
                </Paper>
            </FormLayout>
        </Form>
    </Formik>
);

export { TagEdit };
