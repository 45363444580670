import React, { FC, useEffect } from 'react';
import {
    useLocation,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import {
    Button,
    Loader,
    EntityListLayout,
    ListContent,
    Pagination,
} from '@mega/ui';
import { Add } from '@mega/icons';
import {
    useRepostsDispatch,
    useRepostsStore,
} from '@mega/store';
import {
    ListingReposts,
    NoResultsFoundMessage,
} from '@mega/core';

interface RepostsProps {
    children?: never;
}

const Reposts: FC<RepostsProps> = () => {
    let navigate = useNavigate();
    let location = useLocation();
    const [, setSearchParams] = useSearchParams();

    const store = useRepostsStore();
    const { jumpTo } = useRepostsDispatch();
    const repostListName = 'repostList';
    const allRepostsCount = store.meta.count || 0;
    const repostsPerPage = store.query.per_page || 1;
    const allPagesCount = Math.ceil(
        allRepostsCount / repostsPerPage,
    );

    const hasPage = store.pages['1'] ? true : false;
    const isItLoadingNow = store.loading.get.loading;
    const isPageEmpty =
        !isItLoadingNow &&
        hasPage &&
        store.pages['1'].length === 0;

    useEffect(() => {
        if (!isItLoadingNow) {
            window.scrollTo(0, 0);
        }
    }, [isItLoadingNow]);

    return (
        <EntityListLayout
            setting={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'end',
                        gap: '18px',
                    }}
                >
                    <Button
                        size={'large'}
                        endIcon={<Add />}
                        label="Добавить"
                        classes={{
                            paper: {
                                variant: 'filled',
                                color: 'dark',
                            },
                        }}
                        onClick={() => {
                            navigate(`add`, {
                                replace: true,
                                state: location,
                            });
                        }}
                    />
                </div>
            }
        >
            <ListContent name={repostListName}>
                {({ className }) => {
                    return <ListingReposts />;
                }}
            </ListContent>
            <NoResultsFoundMessage
                isPageEmpty={isPageEmpty}
            />
            <Pagination
                pageCount={allPagesCount}
                jumpTo={jumpTo}
                loading={isItLoadingNow}
            />
        </EntityListLayout>
    );
};

export { Reposts };
