import React, {
    ButtonHTMLAttributes,
    CSSProperties,
    FC,
    forwardRef,
    MouseEvent,
    ReactElement,
} from 'react';

import { motion } from 'framer-motion';

import { paperClasses, PaperClasses } from '@mega/styles';

import {
    iconButtonClasses,
    IconButtonClasses,
} from './IconButton.css';

export interface IconButtonProps
    extends ButtonHTMLAttributes<HTMLButtonElement> {
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    onHoverStart?: () => void;
    onHoverEnd?: () => void;
    children?: ReactElement;
    style?: CSSProperties;
    classes?: {
        paper?: PaperClasses['recipe'];
    };
}

const IconButton: FC<
    IconButtonProps & IconButtonClasses['recipe']
> = forwardRef(
    ({
        children,
        onClick,
        onHoverStart,
        onHoverEnd,
        className = '',
        type = 'button',
        withoutVerticlaPadding = false,
        animate = 'primary',
        size = 'medium',
        classes,
        style,
        disabled = false,
    }) => (
        <motion.button
            type={type}
            onClick={(e) => {
                e.stopPropagation();
                onClick && onClick(e);
            }}
            onHoverStart={onHoverStart}
            onHoverEnd={onHoverEnd}
            whileTap={{ scale: 0.9 }}
            whileHover={{ scale: 1.05 }}
            className={[
                iconButtonClasses.recipe({
                    withoutVerticlaPadding,
                    animate,
                    size,
                }),
                className,
                paperClasses.recipe({
                    color: 'dark',
                    variant: 'filled',
                    borderRadius: 'small',

                    ...(classes?.paper ?? {}),
                }),
            ].join(' ')}
            style={style}
            disabled={disabled}
        >
            {children}
        </motion.button>
    ),
);

export { IconButton };
