import React, { FC } from 'react';
import { Form, Formik, useFormikContext } from 'formik';

import * as Yup from 'yup';

import { GetByIdADSHeadScript } from '@mega/api';

import {
    Button,
    FormLayout,
    InputField,
    InputTextareaField,
    Paper,
    Stack,
} from '@mega/ui';

export interface HeadScriptCreateProps {
    initialValue?: GetByIdADSHeadScript;
    handleCreate?: (value: GetByIdADSHeadScript) => any;
}

const HeadScriptCreate: FC<HeadScriptCreateProps> = ({
    initialValue = {
        title: '',
        script: '',
        site: { id: '', label: '' },
    },
    handleCreate = () => null,
}) => {
    return (
        <Formik
            validationSchema={Yup.object({
                title: Yup.string()
                    .min(
                        2,
                        'Это поле явно должно быть больше одного символа',
                    )
                    .required('Это поле обязательное'),
                script: Yup.string()
                    .min(
                        2,
                        'Это поле явно должно быть больше одного символа',
                    )
                    .required('Это поле обязательное'),
            })}
            initialValues={initialValue}
            onSubmit={handleCreate}
        >
            <Form>
                <FormLayout
                    actions={<HeadScriptCreateActions />}
                >
                    <Paper
                        variant="outline"
                        color="white"
                        classes={{
                            padding: { size: '24x24' },
                        }}
                    >
                        <Stack gap="18">
                            <InputField
                                type="text"
                                name={'title'}
                                placeholder="Заголовок"
                                dimension="medium"
                            />
                            <InputTextareaField
                                name="script"
                                color={'transparent'}
                                placeholder="Скрипт"
                            />
                        </Stack>
                    </Paper>
                </FormLayout>
            </Form>
        </Formik>
    );
};

const HeadScriptCreateActions = () => {
    const { submitForm, isSubmitting } = useFormikContext();

    return (
        <Paper
            style={{
                borderTop: '1px solid #EAEAEA',
            }}
            fullWidth
            color={'white'}
            variant="filled"
            classes={{
                padding: { size: '24x24' },
            }}
        >
            <Button
                classes={{
                    paper: {
                        variant: 'filled',
                        color: 'dark',
                    },
                }}
                color="secondary"
                size={'large'}
                type={'submit'}
                label={'Создать'}
                onClick={submitForm}
                disabled={isSubmitting}
            />
        </Paper>
    );
};

export { HeadScriptCreate };
