import {
    articles,
    authors,
    bannedOrganizations,
    categories,
    infounit,
    newspapers,
    redactors,
    reports,
    user,
    rss,
    tags,
} from '@mega/api';
import { compact } from 'lodash-es';

const defaultPerPage = 10;
const getOptionsAuthors =
    ({ site }: { site?: string }) =>
    async (inputValue: string, page: number) => {
        const responce = await authors.getOptions?.(
            {
                search:
                    inputValue === ''
                        ? undefined
                        : inputValue,
                per_page: defaultPerPage * page,
            },
            {
                headers: {
                    'X-Site': site || '',
                },
            },
        );

        if (responce?.ok) {
            return (
                responce?.data?.results?.map((item) => ({
                    label: item.label || '',
                    value: item.id || 1,
                })) ?? []
            );
        } else {
            return [];
        }
    };

const getOptionsAuthorsById =
    ({ site }: { site?: string }) =>
    async (id: number, page: number) => {
        const responce = await authors.getOptions?.(
            {
                id: id === undefined ? undefined : id,
                per_page: defaultPerPage * page,
            },
            {
                headers: {
                    'X-Site': site || '',
                },
            },
        );

        if (responce?.ok) {
            return (
                responce?.data?.results?.map((item) => ({
                    label: item.label || '',
                    value: item.id || 1,
                })) ?? []
            );
        } else {
            return [];
        }
    };

const getOptionsReports =
    ({ site }: { site?: string }) =>
    async () => {
        const responce = await reports.getOptions?.(
            {},
            {
                headers: {
                    'X-Site': site || '',
                },
            },
        );

        if (responce?.ok) {
            return responce?.data?.report_options ?? [];
        } else {
            return [];
        }
    };

const getOptionsAuthorsFull = ({
    site,
    sites,
}: {
    site?: string;
    sites?: string[];
}) => {
    return async (inputValue: string, page: number) => {
        const responce = await authors.get?.(
            {
                search:
                    inputValue === ''
                        ? undefined
                        : inputValue,
                per_page: defaultPerPage * page,
                //@ts-ignore
                sites: compact(sites),
                is_active: 'true',
            },
            {
                headers: {
                    'X-Site': site || '',
                },
            },
        );

        if (responce?.ok) {
            return (
                responce?.data?.results?.map((item) => {
                    const sites: string = item.sites
                        .map((el) => el.id)
                        .join(', ');
                    const label = item.name;
                    return {
                        label: `${label} (${sites})` || '',
                        value: item.id || 1,
                    };
                }) ?? []
            );
        } else {
            return [];
        }
    };
};

const getOptionsTags =
    ({
        site,
        is_theme,
        has_articles,
    }: {
        site?: string;
        is_theme?: boolean;
        has_articles?: boolean;
    }) =>
    async (inputValue: string, page: number) => {
        const responce = await tags.getOptions?.({
            search:
                inputValue === '' ? undefined : inputValue,
            site: site,
            is_theme: is_theme ? 'true' : undefined,
            has_articles: has_articles ? 'true' : undefined,
            per_page: defaultPerPage * page,
        });
        if (responce?.ok) {
            return (
                responce?.data?.results?.map((item) => ({
                    label: item.label || '',
                    value: item.id || 1,
                    slug: item?.slug || undefined,
                })) ?? []
            );
        } else {
            return [];
        }
    };

const getOptionsTagsArticlePage =
    ({
        site,
        is_theme,
        has_articles,
    }: {
        site?: string;
        is_theme?: boolean;
        has_articles?: boolean;
    }) =>
    async (inputValue: string, page: number) => {
        const responce = await tags.getOptions?.({
            search:
                inputValue === '' ? undefined : inputValue,
            site: site,
            is_theme: is_theme ? 'true' : undefined,
            has_articles: has_articles ? 'true' : undefined,
            per_page: defaultPerPage * page,
        });
        if (responce?.ok) {
            return (
                [
                    { label: 'Без тега', value: 0 },
                    ...responce?.data?.results?.map(
                        (item) => ({
                            label: item.label || '',
                            value: item.id || 1,
                            slug: item?.slug || undefined,
                        }),
                    ),
                ] ?? []
            );
        } else {
            return [];
        }
    };

const getOptionsUser =
    ({
        site,
        is_theme,
        has_articles,
    }: {
        site?: string;
        is_theme?: boolean;
        has_articles?: boolean;
    }) =>
    async (inputValue: string, page: number) => {
        const responce = await user.getUsersOptions?.({
            search:
                inputValue === '' ? undefined : inputValue,
            site: site,
            is_theme: is_theme ? 'true' : undefined,
            has_articles: has_articles ? 'true' : undefined,
            per_page: defaultPerPage * page,
        });
        if (responce?.ok) {
            return (
                responce?.data?.results?.map((item) => ({
                    label: item.label || '',
                    value: item.id || 1,
                    slug: item?.slug || undefined,
                })) ?? []
            );
        } else {
            return [];
        }
    };

const getOptionsUserById =
    ({
        site,
        is_theme,
        has_articles,
    }: {
        site?: string;
        is_theme?: boolean;
        has_articles?: boolean;
    }) =>
    async (id: number, page: number) => {
        const responce = await user.getUsersOptions?.({
            id: id === undefined ? undefined : id,
            site: site,
            is_theme: is_theme ? 'true' : undefined,
            has_articles: has_articles ? 'true' : undefined,
            per_page: defaultPerPage * page,
        });
        if (responce?.ok) {
            return (
                responce?.data?.results?.map((item) => ({
                    label: item.label || '',
                    value: item.id || 1,
                    slug: item?.slug || undefined,
                })) ?? []
            );
        } else {
            return [];
        }
    };

const getOptionsTagsById =
    ({
        site,
        is_theme,
        has_articles,
    }: {
        site?: string;
        is_theme?: boolean;
        has_articles?: boolean;
    }) =>
    async (ids: string, page: number) => {
        const responce = await tags.getOptions?.({
            id__in: ids === '' ? undefined : ids,
            site: site,
            is_theme: is_theme ? 'true' : undefined,
            has_articles: has_articles ? 'true' : undefined,
            per_page: defaultPerPage * page,
        });
        if (responce?.ok) {
            return (
                responce?.data?.results?.map((item) => ({
                    label: item.label || '',
                    value: item.id || 1,
                    slug: item?.slug || undefined,
                })) ?? []
            );
        } else {
            return [];
        }
    };

const getOptionsNewspapers =
    ({ site }: { site?: string }) =>
    async (inputValue: string, page: number) => {
        const responce = await newspapers.getOptions?.({
            search:
                inputValue === '' ? undefined : inputValue,
            site: site,
            per_page: defaultPerPage * page,
        });
        if (responce?.ok) {
            return (
                responce?.data?.results?.map((item) => ({
                    label: item.label || '',
                    value: item.id || 1,
                })) ?? []
            );
        } else {
            return [];
        }
    };

const getOptionsCategories = ({
    site,
    has_articles,
}: {
    site?: string;
    has_articles?: boolean;
}) => {
    return async (inputValue: string, page: number) => {
        const responce = await categories.getOptions?.({
            search:
                inputValue === '' ? undefined : inputValue,
            site: site,
            has_articles: has_articles ? 'true' : undefined,
            per_page: defaultPerPage * page,
        });
        if (responce?.ok) {
            return (
                responce?.data?.results?.map((item) => ({
                    label: item.label || '',
                    value: item.id || 1,
                })) ?? []
            );
        } else {
            return [];
        }
    };
};

const getOptionsCategoriesById = ({
    site,
    has_articles,
}: {
    site?: string;
    has_articles?: boolean;
}) => {
    return async (id: number, page: number) => {
        const responce = await categories.getOptions?.({
            id: id === undefined ? undefined : id,
            site: site,
            has_articles: has_articles ? 'true' : undefined,
            per_page: defaultPerPage * page,
        });
        if (responce?.ok) {
            return (
                responce?.data?.results?.map((item) => ({
                    label: item.label || '',
                    value: item.id || 1,
                })) ?? []
            );
        } else {
            return [];
        }
    };
};

const getOptionsCategoriesMultipost = ({
    site,
}: {
    site?: string;
}) => {
    interface Item {
        id: number;
        label: string;
    }

    return async (inputValue: string, page: number) => {
        const responce = await categories.getOptions?.({
            search:
                inputValue === '' ? undefined : inputValue,
            site: site,
            per_page: defaultPerPage * page,
        });
        if (responce?.ok) {
            return (
                responce?.data?.results?.map((item) => ({
                    label: item.label || '',
                    value: item.id || 1,
                })) ?? []
            );
        } else {
            return [];
        }
    };
};

const getOptionsRss =
    ({ site }: { site?: string }) =>
    async (inputValue: string, page: number) => {
        const responce = await rss.getOptions?.({
            search:
                inputValue === '' ? undefined : inputValue,
            site: site,
            per_page: defaultPerPage * page,
        });
        if (responce?.ok) {
            return (
                responce?.data?.results?.map((item) => ({
                    label: item.label || '',
                    value: item.id || 1,
                })) ?? []
            );
        } else {
            return [];
        }
    };

const getOptionsRedactors =
    ({ site }: { site?: string }) =>
    async (inputValue: string, page: number) => {
        const responce = await redactors.get?.({
            search:
                inputValue === '' ? undefined : inputValue,
            per_page: defaultPerPage * page,
            editor_in_site: site,
        });
        if (responce?.ok) {
            return (
                responce?.data?.results?.map((item) => ({
                    label: item.label || '',
                    value: item.id || 1,
                })) ?? []
            );
        } else {
            return [];
        }
    };

const getOptionsInfounits = async (
    inputValue: string,
    page: number,
) => {
    const responce = await infounit.get?.({
        search: inputValue === '' ? undefined : inputValue,
        per_page: defaultPerPage * page,
    });
    if (responce?.ok) {
        return (
            responce?.data?.results?.map((item) => ({
                label: item.label || '',
                value: item.id || 1,
            })) ?? []
        );
    } else {
        return [];
    }
};
const getOptionsSites =
    () => async (inputValue: string, page: number) => {
        const responce = await articles.getSitesOptions?.({
            search:
                inputValue === '' ? undefined : inputValue,
            per_page: defaultPerPage * page,
        });
        if (responce?.ok) {
            return (
                responce?.data?.results?.map((item) => ({
                    label: item.label || '',
                    value: item.id || 1,
                })) ?? []
            );
        } else {
            return [];
        }
    };

const getOptionsBO =
    () => async (inputValue: string, page: number) => {
        const responce =
            await bannedOrganizations.getOptions?.({
                search:
                    inputValue === ''
                        ? undefined
                        : inputValue,
                per_page: defaultPerPage * page,
            });
        if (responce?.ok) {
            return (
                responce?.data?.results?.map((item) => ({
                    label: item.title || '',
                    value: item.id || 1,
                })) ?? []
            );
        } else {
            return [];
        }
    };

const getOptionsCopyrights =
    ({ site }: { site: string }) =>
    async (inputValue: string, page: number) => {
        const responce =
            await articles.getCopyrightsOptions?.(
                {
                    search:
                        inputValue === ''
                            ? undefined
                            : inputValue,
                    per_page: defaultPerPage * page,
                },
                {
                    headers: {
                        'X-Site': site,
                    },
                },
            );
        if (responce?.ok) {
            return (
                responce?.data?.results?.map((item) => ({
                    label: item.label || '',
                    value: item.id || 1,
                })) ?? []
            );
        } else {
            return [];
        }
    };

export {
    getOptionsAuthors,
    getOptionsAuthorsById,
    getOptionsUser,
    getOptionsUserById,
    getOptionsTags,
    getOptionsTagsById,
    getOptionsTagsArticlePage,
    getOptionsCategories,
    getOptionsCategoriesById,
    getOptionsCategoriesMultipost,
    getOptionsRss,
    getOptionsRedactors,
    getOptionsInfounits,
    getOptionsSites,
    getOptionsCopyrights,
    getOptionsNewspapers,
    getOptionsBO,
    getOptionsAuthorsFull,
    getOptionsReports,
};
