import { useSubscribeNotify } from '@mega/store';
import React from 'react';

import { MainRouter } from './router';

const App = () => {
    const { subscribeNotifyMiddleWare, enqueueSnackbar } =
        useSubscribeNotify();

    subscribeNotifyMiddleWare(enqueueSnackbar);

    return <MainRouter />;
};

export default App;
