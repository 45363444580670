import React, { useState } from 'react';
import { Icon, Typography } from '@mega/ui';
import { PreviewModal } from '../../../PreviewModal';
import { InfounittaskArray as InfoUnitType } from '@mega/api';
import {
    styledCell,
    styledDate,
    styledInfo,
    styledMedia,
    styledSeparator,
    styledTheme,
} from './TitleCell.css';
import dayjs from 'dayjs';
import { Image, Videocam } from '@mega/icons';
import { FiMic, FiFile } from 'react-icons/fi';
import { Tooltip } from '@mui/material';

export interface TitleCellProps extends InfoUnitType {}

export const TitleCell: React.FC<TitleCellProps> = (
    props,
) => {
    const { info_unit, article } = props;
    const [isOpen, setIsOpen] = useState(false);

    const {
        created_at,
        has_audios,
        has_files,
        has_images,
        has_videos,
        themes,
        has_pending_media = true,
    } = info_unit;
    const formatDate = created_at
        ? dayjs(created_at).format('DD.MM.YYYY в HH:mm')
        : '';

    const hasMedia =
        has_audios || has_files || has_videos || has_images;

    return (
        <>
            <Tooltip
                title={
                    <div
                        style={{
                            whiteSpace: 'pre-line',
                            textAlign: 'center',
                        }}
                    >
                        {has_pending_media
                            ? 'Инфоповод в процессе обработки. \nПодождите, пожалуйста'
                            : `Посмотреть инфоповод \nОткроется справа`}
                    </div>
                }
                placement="top"
            >
                <span>
                    <div
                        className={styledCell}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (!has_pending_media) {
                                setIsOpen(true);
                            }
                        }}
                    >
                        <div
                            style={
                                has_pending_media
                                    ? {
                                          color: '#e2e2e2',
                                      }
                                    : {
                                          color: article?.is_active
                                              ? '#6FCF97'
                                              : undefined,
                                      }
                            }
                        >
                            {info_unit.title}
                        </div>
                        <div className={styledInfo}>
                            <Typography
                                size="14"
                                className={styledDate}
                                style={
                                    has_pending_media
                                        ? {
                                              color: '#e2e2e2',
                                          }
                                        : {}
                                }
                            >
                                {formatDate}
                            </Typography>
                            {hasMedia && (
                                <>
                                    <div
                                        className={
                                            styledSeparator
                                        }
                                    />
                                    <div
                                        className={
                                            styledMedia
                                        }
                                    >
                                        {has_images && (
                                            <Icon size="25">
                                                <Image />
                                            </Icon>
                                        )}
                                        {has_videos && (
                                            <Icon size="25">
                                                <Videocam />
                                            </Icon>
                                        )}
                                        {/* Привести иконки к одному виду*/}
                                        {has_audios && (
                                            <Icon size="20">
                                                <FiMic />
                                            </Icon>
                                        )}
                                        {has_files && (
                                            <Icon size="20">
                                                <FiFile />
                                            </Icon>
                                        )}
                                    </div>
                                </>
                            )}

                            {themes?.[0]?.label && (
                                <>
                                    <div
                                        className={
                                            styledSeparator
                                        }
                                    />
                                    <Typography
                                        size="14"
                                        className={
                                            styledTheme
                                        }
                                    >
                                        {themes?.[0].label}
                                    </Typography>
                                </>
                            )}
                        </div>
                    </div>
                </span>
            </Tooltip>
            {info_unit.id && (
                <PreviewModal
                    isOpen={isOpen}
                    unitId={info_unit.id}
                    onClose={() => setIsOpen(false)}
                />
            )}
        </>
    );
};
