import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgGoogleRssOff = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 15 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M14.02 7.992v-.5H7.414v3.09h2.676v1.788c-.056.04-.123.085-.204.134a2.424 2.424 0 0 1-.619.228h-.002c-.252.065-.59.102-1.029.102-.494 0-.904-.087-1.243-.246a2.267 2.267 0 0 1-.863-.711c-.235-.33-.423-.746-.553-1.263-.131-.524-.2-1.14-.2-1.85v-.909c0-.72.066-1.336.191-1.855.126-.522.304-.939.524-1.262a2.09 2.09 0 0 1 .772-.706l.003-.001c.304-.16.659-.246 1.076-.246.541 0 .933.093 1.207.242l.003.002c.297.159.516.373.67.644.166.296.286.656.35 1.088l.064.426h3.829l-.06-.553c-.104-.974-.384-1.852-.848-2.624-.469-.798-1.149-1.415-2.02-1.851C10.25.709 9.15.5 7.864.5c-.95 0-1.827.163-2.625.498a5.725 5.725 0 0 0-2.063 1.464c-.579.644-1.018 1.425-1.323 2.334v.003c-.3.913-.446 1.94-.446 3.076v.889c0 1.137.153 2.167.467 3.082v.001c.313.904.763 1.681 1.355 2.324l.002.002a5.923 5.923 0 0 0 2.131 1.46l.003.002c.821.325 1.722.484 2.695.484 1.068 0 2.002-.114 2.791-.354.76-.23 1.404-.511 1.918-.852l.004-.003c.48-.325.866-.645 1.13-.964l.116-.139V7.992Z"
            stroke="#EAEAEA"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgGoogleRssOff);
export default ForwardRef;
