import React, { FC, useEffect, useMemo } from 'react';
import { CellValue, Column } from 'react-table';
import { Icon, IconButton, ListingTable } from '@mega/ui';
import { typographyClasses } from '@mega/styles';
import {
    useDocumentsDispatch,
    useDocumentsStore,
    useUserInfo,
} from '@mega/store';
import { TitleColumn } from '../Columns';
import { headItem, listingRecipe } from '../Listing.css';
import dayjs from 'dayjs';
import { AcceptModal } from '@mega/core';
import { Close } from '@mega/icons';
import { useSnackbar } from 'notistack';
import { Documents } from '@mega/api';

export interface ListingDocumentsProps {}

// TODO переписать компонент вместе с newspapers логика одна и та же.
const ListingDocuments: FC<ListingDocumentsProps> = () => {
    const store = useDocumentsStore();
    const { get, clear, deleteById } =
        useDocumentsDispatch();
    const { isPermitted } = useUserInfo();
    const { enqueueSnackbar } = useSnackbar();

    const isDeletePermitted = isPermitted({
        rootPermissions: 'issue',
        subRootPermissions: 'delete',
    });

    useEffect(() => {
        get({});
        return () => {
            clear();
        };
    }, [get, clear]);

    const columns: Column<Documents>[] = React.useMemo(
        () => [
            {
                Header: 'Заголовок',
                accessor: (row) => {
                    return {
                        title: row.title,
                        id: row.id,
                    };
                },
                Cell: ({ value }: CellValue) => (
                    <TitleColumn {...value} />
                ),
                minWidth: 200,
                width: '100%',
            },
            {
                Header: 'Публикация',
                accessor: 'created_at',
                Cell: ({ value }: CellValue) => {
                    return (
                        <div
                            style={{ textAlign: 'center' }}
                        >
                            {dayjs(value).format(
                                'DD.MM.YYYY в HH:mm',
                            )}
                        </div>
                    );
                },
            },
            {
                Header: 'Действия',
                accessor: 'id',
                Cell: ({ value }: CellValue) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignContent: 'center',
                                gap: '10px',
                            }}
                        >
                            {isDeletePermitted && (
                                <AcceptModal
                                    msg="Вы уверенны что хотите удалить документ?"
                                    Trigger={
                                        <IconButton
                                            animate="none"
                                            onClick={async () => {
                                                if (value) {
                                                    const res =
                                                        await deleteById(
                                                            value,
                                                        );

                                                    if (
                                                        res.isOk
                                                    ) {
                                                        return enqueueSnackbar(
                                                            'Документ удален',
                                                            {
                                                                variant:
                                                                    'success',
                                                            },
                                                        );
                                                    } else {
                                                        return enqueueSnackbar(
                                                            'Что-то пошло не так, повторите попытку позже',
                                                            {
                                                                variant:
                                                                    'error',
                                                            },
                                                        );
                                                    }
                                                } else {
                                                }
                                            }}
                                            classes={{
                                                paper: {
                                                    color: 'white',
                                                },
                                            }}
                                        >
                                            <Icon size="25">
                                                <Close
                                                    style={{
                                                        color: '#EB5757CC',
                                                    }}
                                                />
                                            </Icon>
                                        </IconButton>
                                    }
                                />
                            )}
                        </div>
                    );
                },
            },
        ],
        [],
    );

    const data = useMemo(() => {
        const values =
            store.pages?.[store.currentPage] || [];
        return Object.values(values).flat();
    }, [store.pages]);

    return (
        <>
            <ListingTable
                getCellProps={(cellInfo) => {
                    return {
                        style: {},
                    };
                }}
                getHeaderGroupProps={(cellInfo) => {
                    return {
                        className: listingRecipe({
                            header: true,
                        }),
                    };
                }}
                getHeaderProps={(cellInfo) => {
                    return {
                        className: headItem({
                            title:
                                cellInfo?.id ===
                                'Заголовок',
                        }),
                        style: {},
                    };
                }}
                getRowGroupProps={() => {
                    return {
                        className: [
                            typographyClasses.recipe({
                                size: '14',
                            }),
                            listingRecipe({
                                header: false,
                            }),
                        ].join(' '),
                    };
                }}
                columns={columns}
                data={data}
            />
        </>
    );
};

export { ListingDocuments };
