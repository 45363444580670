import { FC } from 'react';
import { ArticleProps } from './Article.types';
import { pluginStyles as s } from './Article.css';
import { Dropdown } from 'src/pages/smartGrid/components/shared/Dropdown';
import {
    useArticlesDispatch,
    useMainGridStore,
    useSiteStore,
} from '@mega/store';
import {
    DEFAULT_360_ARTICLE,
    DEFAULT_RIAMO_ARTICLE,
} from '@pages/smartGrid/components/Plugins';

const ArticlePlugin: FC<ArticleProps> = ({}) => {
    const { getMainGrid } = useArticlesDispatch();
    const store = useMainGridStore();

    const currenSite = useSiteStore();
    const is360 = currenSite?.id === '360tv.ru';

    const handleLoader = async (
        inputValue?: string,
        pageNumber?: number,
    ) => {
        const responce = await getMainGrid({
            search: inputValue,
            page: pageNumber,
            per_page: 20,
        });

        if (responce?.results.length) {
            return (
                responce.results?.map((item: any) => ({
                    label: item?.title || '',
                    value: item?.id || '',
                })) ?? []
            );
        } else {
            return [];
        }
    };

    // TODO: разобраться с типами и с неймингом компонента ArticlePlugin  - действительно ли это нужно было назвать плагином?
    const autoArticleIds = Object.values(
        store.itemsList.CONTENT_AUTO_ARTICLE,
        // @ts-ignore
    ).map((item) => item.id);

    const usedIdList = [
        ...store.itemsList.CONTENT_ARTICLE,
        ...autoArticleIds,
    ];

    return (
        <div className={s.wrapper({})}>
            <Dropdown
                meta={
                    is360
                        ? DEFAULT_360_ARTICLE.meta
                        : DEFAULT_RIAMO_ARTICLE.meta
                }
                type={
                    is360
                        ? DEFAULT_360_ARTICLE.type
                        : DEFAULT_RIAMO_ARTICLE.type
                }
                title={'Статьи'}
                usedIdList={usedIdList}
                getItemsLoader={handleLoader}
            />
        </div>
    );
};

export { ArticlePlugin };
