import { Form, Formik } from 'formik';
import React, { FC } from 'react';
import { validateNumOfTheDay } from './NumOfTheDayForm/config/validate';
import {
    IInitValuesForm,
    initValuesForm,
} from './NumOfTheDayForm/config/config';
import { NumOfTheDayForm } from './NumOfTheDayForm/NumOfTheDayForm';

interface NumOfTheDaySettingsProps {
    initialValues?: IInitValuesForm;
    onSave: (values: IInitValuesForm) => void;
}

export const NumOfTheDaySettings: FC<
    NumOfTheDaySettingsProps
> = ({ onSave, initialValues = initValuesForm }) => {
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validateNumOfTheDay}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSave}
        >
            {({ handleSubmit, values }) => (
                <Form>
                    <NumOfTheDayForm
                        onSubmit={handleSubmit}
                        values={values}
                    />
                </Form>
            )}
        </Formik>
    );
};
