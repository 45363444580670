import React, { FC, useState, useEffect } from 'react';
import {
    Button,
    Row,
    IconButton,
    Typography,
} from '@mega/ui';
import { Drawer } from '@mega/utils';
import { Close } from '@mega/icons';
import { articleEditMultipostClasses } from './ArticleEditMultipost.css';
import { ArticleMultipostedTable } from '../ArticleMultipostForm';
import { ArticleEditMultipostForm } from '../ArticleMultipostForm';
import {
    useArticleMultipostDispatch,
    useArticleMultipostStore,
    useArticleStore,
    useUserInfo,
} from '@mega/store';

export const ArticleEditMultipost: FC = (): JSX.Element => {
    const me = useUserInfo();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const storeArticle = useArticleStore();
    const storeArticleMultipost =
        useArticleMultipostStore();
    const countCopies =
        storeArticleMultipost.data?.copies.length || 0;
    const { get } = useArticleMultipostDispatch();

    useEffect(() => {
        if (storeArticle.data && storeArticle.data.id) {
            get(storeArticle.data.id);
        }
    }, []);

    const handleClose = () => {
        setIsOpen(false);
    };
    const handleOpen = () => {
        setIsOpen(true);
    };

    if (
        me.sites.length < 2 ||
        !storeArticle.data?.is_active
    ) {
        return <></>;
    }

    if (storeArticle.data.original) {
        return <></>;
    }
    return (
        <div>
            <Button
                size={'large'}
                label={`Мультипостинг ${
                    countCopies > 0 && countCopies
                        ? ` (${countCopies})`
                        : ''
                }`}
                classes={{
                    paper: {
                        variant: 'filled',
                        color:
                            countCopies > 0 && countCopies
                                ? 'dark'
                                : 'blue',
                    },
                }}
                onClick={handleOpen}
                disabled={
                    storeArticleMultipost.data === null
                }
            />
            <Drawer
                open={isOpen}
                onClose={handleClose}
                anchor="right"
            >
                <div
                    className={
                        articleEditMultipostClasses.drawer
                    }
                >
                    <Row reverse>
                        <IconButton
                            animate="primary"
                            size="medium"
                            onClick={handleClose}
                        >
                            <Close />
                        </IconButton>
                    </Row>
                    <Typography
                        className={
                            articleEditMultipostClasses.title
                        }
                        size="24"
                    >
                        Мультипост
                    </Typography>
                    <ArticleMultipostedTable />
                    <Typography
                        size="12"
                        style={{
                            color: '#878787',
                            marginTop: '10px',
                        }}
                        weight="regular"
                    >
                        Выберите сайты, для которых нужно
                        сделать копии этой статьи.
                    </Typography>

                    <Typography
                        size="12"
                        weight="regular"
                        style={{
                            color: '#878787',
                            marginBottom: '30px',
                        }}
                    >
                        Все настройки будут скопированы с
                        основной статьи.
                    </Typography>
                    <ArticleEditMultipostForm />
                </div>
            </Drawer>
        </div>
    );
};
