import React from 'react';

export const coordsToFloat = (
    pos: number,
    range: number,
) => {
    return Number((pos / range).toFixed(2));
};

export const getPointPosition = ({
    event,
    ref,
}: {
    event: React.MouseEvent<HTMLDivElement>;
    ref: React.RefObject<HTMLDivElement>;
}) => {
    if (ref?.current) {
        const rect = ref.current.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        const fp_x = coordsToFloat(x, rect.width);
        const fp_y = coordsToFloat(y, rect.height);
        return { x, y, fp_y, fp_x };
    }
};

export const getPositionFromFocus = ({
    fp_x,
    fp_y,
    ref,
}: {
    fp_x: number;
    fp_y: number;
    ref: React.RefObject<HTMLDivElement>;
}) => {
    if (ref?.current) {
        const rect = ref.current.getBoundingClientRect();
        const x = (rect.width / 100) * (fp_x * 100);
        const y = (rect.height / 100) * (fp_y * 100);
        return { x, y };
    }
};
export const setCropPosition = (
    ref: React.RefObject<HTMLDivElement>,
    x: number,
    y: number,
) => {
    if (ref?.current) {
        ref.current.style.top = `${y}px`;
        ref.current.style.left = `${x}px`;
    }
};
