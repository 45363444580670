import React from 'react';
import { CardStoryProps } from './CardStory.types';
import {
    CardStoryTemplate,
    IconButton,
    InputTextarea,
    Loader,
    Radio,
    Stack,
} from '@mega/ui';
import { v4 as uuidv4 } from 'uuid';

import { useFormikContext } from 'formik';
import { definitions, Story } from '@mega/api';
import { CardStoryInnerCard } from './components';
import { Add } from '@mega/icons';
import { ImgLoader } from '../ImgLoader';

const TITLE_LIMIT = 70;
const THEME_LIMIT = 20;
const MAX_CARDS_LENGTH = 10;

const storyCardTemplate = {
    id: null,
    title: 'Заголовок',
    description: '',
    image: null,
    video: null,
    link: '',
    button_text: '',
    button_color: 'black',
    button_text_color: 'black',
};

const swap = (arr: any[], a: number, b: number) => {
    arr[a] = arr.splice(b, 1, arr[a])[0];
};
export const CardStory: React.FC<CardStoryProps> = (
    props,
) => {
    const { values, setFieldValue } =
        useFormikContext<Story>();

    if (!values?.id) return <Loader size={'large'} />;
    const {
        cards = [],
        id,
        title,
        theme,
        cover,
        background,
    } = values;

    const handleMoveCard = (
        curId: number,
        newId: number,
    ) => {
        let newArray: definitions['AdminStoryCard'][] = [
            ...cards,
        ];

        swap(newArray, curId, newId);

        setFieldValue('cards', newArray);
    };

    const changeState =
        (name: keyof Omit<Story, 'background'>) =>
        (value: string) => {
            setFieldValue(name, value);
        };

    const handleAddCard = (idx?: number) => {
        if (id) {
            let newArray = [];

            const newStory = {
                ...storyCardTemplate,
                id: uuidv4(),
            };
            if (idx) {
                newArray = [...cards];
                // @ts-ignore
                newArray.splice(idx, 0, newStory);
            } else {
                newArray = [...cards, newStory];
            }

            setFieldValue('cards', newArray);
        }
    };
    const handleRemoveCard = ({
        id,
        idx,
    }: {
        id?: number;
        idx?: number;
    }) => {
        let newArray = [];
        if (id) {
            newArray = cards.filter((c) => c.id !== id);
        } else {
            newArray = cards.filter(
                (c, cIdx) => cIdx !== idx,
            );
        }

        setFieldValue('cards', newArray);
    };

    const maxIdx = values?.cards?.length
        ? values?.cards?.length - 1
        : 0;

    const handleChange =
        (idx: number) =>
        (field: string, value: string | null) => {
            setFieldValue(`cards[${idx}][${field}]`, value);
        };

    return (
        <CardStoryTemplate
            renderForm={() => {
                return (
                    <>
                        <InputTextarea
                            classes={{
                                paper: {
                                    color: 'white',
                                    variant:
                                        'outlineFilled',
                                    withFocus: true,
                                },
                            }}
                            limit={TITLE_LIMIT}
                            name="title"
                            value={title}
                            placeholder="Заголовок"
                            dimension="small"
                            color="transparent"
                            onChange={changeState('title')}
                        />
                        <InputTextarea
                            name="theme"
                            classes={{
                                paper: {
                                    color: 'white',
                                    variant:
                                        'outlineFilled',
                                    withFocus: true,
                                },
                            }}
                            limit={THEME_LIMIT}
                            value={theme}
                            dimension="small"
                            placeholder="Тема"
                            onChange={changeState('theme')}
                        />
                        <div
                            style={{
                                display: 'flex',
                                gap: 8,
                            }}
                        >
                            <div style={{ width: '230px' }}>
                                <ImgLoader
                                    hasCrop
                                    fileSrc={cover?.image}
                                    handleLoad={(value) =>
                                        setFieldValue(
                                            'cover',
                                            value,
                                        )
                                    }
                                    handleDelete={() =>
                                        setFieldValue(
                                            'cover',
                                            null,
                                        )
                                    }
                                    noData
                                    id={cover?.id}
                                    type={'stories'}
                                    image={cover}
                                />
                            </div>
                            <div
                                style={{
                                    maxWidth: 115,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 4,
                                }}
                            >
                                <p>Выберите готовый фон:</p>
                                <Radio
                                    value={background}
                                    name={'background'}
                                    onChange={(value) =>
                                        setFieldValue(
                                            'background',
                                            value.value,
                                        )
                                    }
                                    data={[
                                        {
                                            label: (
                                                <div
                                                    style={{
                                                        width: '24px',
                                                        height: '24px',
                                                        borderRadius:
                                                            '4px',
                                                        background:
                                                            'linear-gradient(0deg, #2B5CAA 0%, #ED221A 100%)',
                                                    }}
                                                />
                                            ),
                                            value: 'orange',
                                        },
                                        {
                                            label: (
                                                <div
                                                    style={{
                                                        width: '24px',
                                                        height: '24px',
                                                        borderRadius:
                                                            '4px',
                                                        background:
                                                            'linear-gradient(0deg, #254E91 0%, #A2E264 100%)',
                                                    }}
                                                />
                                            ),
                                            value: 'green',
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    </>
                );
            }}
            renderCards={() => {
                return (
                    <Stack gap={'8'}>
                        <IconButton
                            type="button"
                            classes={{
                                paper: {
                                    variant: 'dashed',
                                },
                            }}
                            disabled={
                                cards.length ===
                                MAX_CARDS_LENGTH
                            }
                            onClick={() => handleAddCard()}
                        >
                            <Add />
                        </IconButton>
                        {cards?.map((item, idx) => {
                            return (
                                <Stack
                                    gap={'8'}
                                    key={item.id}
                                >
                                    <CardStoryInnerCard
                                        idx={idx}
                                        card={item}
                                        curLength={maxIdx}
                                        handleMoveCard={
                                            handleMoveCard
                                        }
                                        handleRemoveCard={
                                            handleRemoveCard
                                        }
                                        handleChange={handleChange(
                                            idx,
                                        )}
                                    />
                                    <IconButton
                                        type="button"
                                        classes={{
                                            paper: {
                                                variant:
                                                    'dashed',
                                            },
                                        }}
                                        disabled={
                                            cards.length ===
                                            MAX_CARDS_LENGTH
                                        }
                                        onClick={() =>
                                            handleAddCard(
                                                idx + 1,
                                            )
                                        }
                                    >
                                        <Add />
                                    </IconButton>
                                </Stack>
                            );
                        })}
                    </Stack>
                );
            }}
            renderButton={props.renderButton}
        />
    );
};
