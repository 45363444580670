import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';

const useSourcesReportStore = () => {
    const store = useStoreSelector(
        (state: RootState) => state.sourcesReport,
    );
    const loading = useStoreSelector(
        (state: RootState) =>
            state.loading.effects.sourcesReport,
    );

    return {
        store,
        loading,
    };
};

const useSourcesReportDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        get: dispatch.sourcesReport.get,
        put: dispatch.sourcesReport.put,
        create: dispatch.sourcesReport.create,
        update: dispatch.sourcesReport.update,
        remove: dispatch.sourcesReport.remove,
    };
};

export { useSourcesReportStore, useSourcesReportDispatch };
