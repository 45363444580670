import type { PluginTemplate } from '@apostroph/types';

export enum MainEnum {
    type = 'MAIN',
}

export interface MainProps {
    id: string;
    type: string;
    parentId: string | null;
}

export type MainMeta = {
    text: string;
};

export type MainPlugin = PluginTemplate<
    MainEnum.type,
    MainMeta
>;
export type MainPluginDefault = Omit<MainPlugin, 'id'>;
