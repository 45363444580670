import React, {
    ButtonHTMLAttributes,
    FC,
    MouseEvent,
    ReactElement,
} from 'react';

import { motion } from 'framer-motion';

import { paperClasses, PaperClasses } from '@mega/styles';
import { buttonClasses, ButtonClasses } from './Button.css';

export interface ButtonProps
    extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    label: string | (() => ReactElement | string);
    startIcon?: ReactElement;
    endIcon?: ReactElement;
    orientation?: string;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    onHoverStart?: () => void;
    onHoverEnd?: () => void;
    fullWidth?: boolean;
    disabled?: boolean;
    classes?: {
        paper?: PaperClasses['recipe'];
        button?: ButtonClasses['recipe'];
    };
}

const Button: FC<ButtonProps & ButtonClasses['recipe']> = ({
    label,
    startIcon,
    endIcon,
    className = '',
    type = 'button',
    onClick,
    onHoverEnd,
    onHoverStart,
    classes,
    gap,
    size = 'medium',
    animate,
    status = 'none',
    fullWidth = false,
    disabled,
    style,
}) => (
    <motion.button
        style={style}
        disabled={disabled}
        whileTap={{ scale: 0.9 }}
        whileHover={{ scale: 1.05 }}
        onHoverEnd={onHoverEnd}
        onHoverStart={onHoverStart}
        onClick={onClick}
        type={type}
        className={[
            className,
            paperClasses.recipe({
                borderRadius: 'small',
                color: 'dark',
                disabled,
                variant: 'filled',
                contentWidth: true,
                fullWidth,
                ...(classes?.paper ?? {}),
            }),
            buttonClasses.recipe({
                gap,
                size,
                animate,
                status,
            }),
        ].join(' ')}
    >
        {startIcon}
        {label && (
            <span>
                {typeof label === 'string'
                    ? label
                    : label()}
            </span>
        )}
        {endIcon}
    </motion.button>
);

export { Button };
