import { FC } from 'react';

import { styles as s } from './Carousel.css';
import { PluginViewWrapper } from '@pages/smartGrid/components/shared'; // TODO: add type annotation

// TODO: add type annotation

export const Carousel: FC<any> = (props) => {
    const { children } = props;

    return (
        <PluginViewWrapper
            id={props.id}
            parentId={props.parentId}
            title={'Карусель'}
        >
            <div className={s.base}>
                {children?.props.children.map(
                    // @ts-ignore
                    (child) => (
                        <div
                            className={s.item}
                            key={child.key}
                        >
                            {child}
                        </div>
                    ),
                )}
            </div>
        </PluginViewWrapper>
    );
};
