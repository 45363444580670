import {
    GetStream,
    GetStreamError,
    GetStreamQuery,
    CreateStream,
    CreateStreamError,
    CreateStreamQuery,
    PartialUpdateStream,
    PartialUpdateStreamError,
    PartialUpdateStreamQuery,
    DeleteStream,
    DeleteStreamError,
    DeleteStreamQuery,
    isOk,
} from '@mega/api';
import { createModel } from '@rematch/core';

import type { RootModel } from '../rootModel';
import {
    getStreamById,
    GetStreamPromise,
    createStream,
    CreateStreamPromise,
    partialUpdateStreamById,
    PartialUpdateStreamPromise,
    deleteStreamById,
    DeleteStreamPromise,
} from './stream.api';

const stream = createModel<RootModel>()({
    name: 'stream',
    state: {} as GetStream,
    reducers: {
        put: (state, payload: GetStream) => {
            return { ...payload };
        },
    },
    effects: (dispatch) => ({
        get: async (
            props: { payload: GetStreamQuery },
            store,
        ): GetStreamPromise => {
            const response = await getStreamById(
                props.payload,
                store.site.site?.id || '',
            );
            if (
                isOk<GetStream, GetStreamError | null>(
                    response,
                )
            ) {
                dispatch.stream.put(response.value);
                return response;
            }
            return response;
        },
        create: async (
            props: { payload: CreateStreamQuery },
            store,
        ): CreateStreamPromise => {
            const response = await createStream(
                props.payload,
                store.site.site?.id || '',
            );
            if (
                isOk<
                    CreateStream,
                    CreateStreamError | null
                >(response)
            ) {
                dispatch.stream.put(response.value);
                return response;
            }
            return response;
        },
        update: async (
            props: { payload: PartialUpdateStreamQuery },
            store,
        ): PartialUpdateStreamPromise => {
            const response = await partialUpdateStreamById(
                props.payload,
                store.site.site?.id || '',
            );

            if (
                isOk<
                    PartialUpdateStream,
                    PartialUpdateStreamError | null
                >(response)
            ) {
                dispatch.stream.get({
                    payload: {
                        id: props.payload.id,
                    },
                });
                return response;
            }
            return response;
        },
        remove: async (
            props: { payload: DeleteStreamQuery },
            store,
        ): DeleteStreamPromise => {
            const responses = await deleteStreamById(
                props.payload,
                store.site.site?.id || '',
            );
            if (
                isOk<
                    DeleteStream,
                    DeleteStreamError | null
                >(responses)
            ) {
                return responses;
            }
            return responses;
        },
    }),
});

export { stream };
export default stream;
