import { PLUGIN_FLOW_STACK_NAME } from '@pages/smartGrid/components/Plugins';
import { v4 as uuidv4 } from 'uuid';
import {
    CoverTypes,
    EnumCoverType,
    EnumLabelType,
    EnumViewLabelType,
    EnumViewType,
    ViewTypes,
} from '@pages/smartGrid/types/const';

export const defaultItem = (contentBlockId: string) => ({
    items: [contentBlockId],
    data: {
        [contentBlockId]: {
            id: contentBlockId,
            meta: { isMain: true },
            isContainer: true,
            type: PLUGIN_FLOW_STACK_NAME,
            childrenElement: [],
        },
    },
});

export const initialData = {
    id: undefined,
    is_active: false,
    site: '',
    updated_at: '',
    url: '',
    editor_data: defaultItem(uuidv4()),
};

export const coverTypeList: CoverTypes = {
    nocover: {
        id: EnumCoverType.nocover,
        label: EnumLabelType.nocover,
    },
    photocover: {
        id: EnumCoverType.photocover,
        label: EnumLabelType.photocover,
    },
    videocover: {
        id: EnumCoverType.videocover,
        label: EnumLabelType.videocover,
    },
};

export const viewTypeList: ViewTypes = {
    vertical: {
        id: EnumViewType.vertical,
        label: EnumViewLabelType.vertical,
    },
    horizontal: {
        id: EnumViewType.horizontal,
        label: EnumViewLabelType.horizontal,
    },
};
