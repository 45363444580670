import * as CLIENT from '@mega/api';
import { Document, DocumentUpdate } from '@mega/api';
import { createModel } from '@rematch/core';
import { DispatchEffectWithDependency } from '../type';

import type { RootModel } from '../rootModel';

export interface DocumentUpdateEffect
    extends DispatchEffectWithDependency<DocumentUpdate> {
    query: {
        id: number;
    };
}

const document = createModel<RootModel>()({
    name: 'document',
    state: {} as Document,
    reducers: {
        put: (_state, payload: Document) => {
            return { ...payload };
        },
    },
    effects: (dispatch) => ({
        get: async (params: { id: number }, store) => {
            try {
                const response =
                    await CLIENT.documents.getById?.({
                        ...params,
                        // @ts-ignore
                        site: store.site.site?.id || '',
                    });
                if (response?.ok) {
                    dispatch.document.put(response.data);
                }
            } catch (e) {
                if (
                    CLIENT.documents.getById &&
                    e instanceof
                        CLIENT.documents.getById.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },
        create: async (payload: Document, store) => {
            try {
                const response =
                    await CLIENT.documents?.create?.({
                        ...payload,
                        // @ts-ignore
                        site: store.site.site?.id || '',
                    });
                if (response) {
                    return {
                        isOk: true,
                        data: response.data,
                    };
                }
            } catch (e) {
                return {
                    isOk: false,
                    error: e,
                };
            }
        },
        update: async (
            {
                query,
                payload,
                options,
            }: DocumentUpdateEffect,
            store,
        ) => {
            try {
                const response =
                    await CLIENT.documents?.updatePartialById?.(
                        {
                            data: payload,
                            id: String(query.id),
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );

                if (
                    response?.data &&
                    response.status == 200
                ) {
                    dispatch.paper.put(response.data);
                    if (options?.notifier) {
                        const { enqueueSnackbar } =
                            options.notifier;
                        enqueueSnackbar(
                            'Документ обновлен',
                            {
                                variant: 'success',
                            },
                        );
                    }
                } else {
                    if (options?.notifier) {
                        const { enqueueSnackbar } =
                            options.notifier;

                        const errormsgs = Object.values(
                            response.data,
                        );

                        errormsgs?.map((msg) => {
                            enqueueSnackbar(msg as string, {
                                variant: 'error',
                            });
                        });
                    }
                }
            } catch (e) {
                return e;
            }
        },
        remove: async (id: string, store) => {
            const query = store.documents.query;

            try {
                const response =
                    await CLIENT?.documents?.deleteById?.(
                        {
                            id,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    dispatch.documents.get(query);
                }
            } catch {}
        },
    }),
});

export { document };
export default document;
