import React, { useMemo, useState } from 'react';
import { styles as s } from './Dropdown.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Input } from '@mega/ui';
import { Search } from '@mega/icons';
import { DraggableContainer } from '../DraggableContainer';
import { ArticleMeta } from '../../Plugins/Content/Article/Article.types';
import { StoriesMeta } from '../../Plugins/Content/Stories/Stories.types';
import { InfinityLoader, Option } from '@mega/core';
import { useInfiniteLoader } from '../../../hooks/useInfiniteLoader';
import { debounce } from 'lodash-es'; //TODO: Сделать динамическую типизацию для поля meta
import { useDndContext } from '@dnd-kit/core';

//TODO: Сделать динамическую типизацию для поля meta
export interface IDropdown {
    title: string;
    type: string;
    meta?: ArticleMeta | StoriesMeta;
    options?: Option[];
    usedIdList?: number[];
    getItemsLoader: (
        inputValue?: string,
        page?: number,
    ) => Promise<[]>;
}

export const Dropdown = (props: IDropdown) => {
    const {
        title,
        type,
        meta,
        usedIdList,
        getItemsLoader,
    } = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [input, setInput] = useState<string>('');
    const [search, setSearch] = useState<string>('');

    const { items, page, loadMore } =
        useInfiniteLoader<Option>({
            inputValue: input,
            getItemsLoader,
        });

    const dndContext = useDndContext();
    const activeElementLabel =
        dndContext?.active?.data?.current?.meta?.label;

    const onButtonClick = (e: any) => {
        setIsOpen((prev) => !prev);
    };

    const handleBlur = (
        event: React.FocusEvent<HTMLDivElement>,
    ) => {
        if (
            !event.currentTarget.contains(
                event.relatedTarget,
            )
        ) {
            setIsOpen(false);
        }
    };

    const debouncedSearch = useMemo(
        () => debounce((value) => setInput(value), 800),
        [],
    );

    const changeHandler = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const value = e.currentTarget.value;
        setSearch(value);
        debouncedSearch(value);
    };

    return (
        <div
            className={s.dropdown}
            tabIndex={0}
            onBlur={handleBlur}
        >
            <div
                className={s.header}
                onClick={onButtonClick}
            >
                <span>{title}</span>
                <div className={s.arrow({ isOpen })}>
                    <ExpandMoreIcon />
                </div>
            </div>
            <div
                className={s.dropdownContent({
                    isOpen,
                })}
            >
                <div className={s.search}>
                    <Input
                        startIcon={<Search />}
                        color="secondary"
                        dimension="small"
                        borderRadius={'small'}
                        placeholderBase={'Поиск'}
                        value={search || ''}
                        type="text"
                        onChange={changeHandler}
                    />
                </div>
                <div className={s.scrollable}>
                    <div className={s.list}>
                        {items.map(
                            (option: Option, idx) => {
                                const isUsed =
                                    usedIdList?.some(
                                        (id) =>
                                            id ===
                                            option.value,
                                    );

                                return (
                                    <DraggableContainer
                                        type={type}
                                        id={
                                            option.value as string
                                        }
                                        meta={{
                                            ...meta,
                                            label: option.label,
                                            dataId: option.value,
                                        }}
                                        key={idx}
                                    >
                                        <div
                                            className={s.listItem(
                                                {
                                                    isUsed,
                                                    isDragging:
                                                        activeElementLabel ===
                                                        option.label,
                                                },
                                            )}
                                        >
                                            {option.label}
                                        </div>
                                    </DraggableContainer>
                                );
                            },
                        )}
                        <InfinityLoader
                            nextPage={loadMore}
                            page={page}
                            dataLength={items.length}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
