import { FC } from 'react';
import { PluginViewWrapper } from 'src/pages/smartGrid/components/shared/PluginViewWrapper';
import { GridSettings } from '@pages/smartGrid/components/shared/GridSettings';
import { useEditor } from '@pages/smartGrid/store/hooks';
import { AdsProps } from './Ads.types';
import { IInitValuesForm } from './AdsSettings/AdsForm/config';
import { AdsSettings } from './AdsSettings';
import { styles as s } from './Ads.css';
import { Tooltip } from '@mui/material';
import { Ads as AdsIcon } from '@mega/icons';

const Ads: FC<AdsProps> = ({
    id,
    type,
    meta,
    parentId,
}) => {
    const { handleUpdateElement } = useEditor();

    const onSubmit = (values: IInitValuesForm) => {
        handleUpdateElement({
            id,
            type,
            meta: values as Record<string, any>,
        });
    };

    return (
        <>
            <PluginViewWrapper
                title={'Реклама'}
                id={id}
                parentId={parentId}
                render={(isEdit: any, setIsEdit: any) => {
                    return (
                        <GridSettings
                            isOpen={isEdit}
                            setIsOpen={setIsEdit}
                            title={'Реклама - настройка'}
                            render={({ handleClose }) => {
                                return (
                                    <AdsSettings
                                        initialValues={meta}
                                        onSave={(
                                            values: IInitValuesForm,
                                        ) => {
                                            onSubmit(
                                                values,
                                            );
                                            handleClose();
                                        }}
                                    />
                                );
                            }}
                        />
                    );
                }}
            >
                <div
                    className={s.wrapper({})}
                    style={{ height: meta.height }}
                >
                    <div className={s.container}>
                        <div className={s.innerContainer}>
                            <div className={s.content}>
                                {meta.body_script?.length >
                                    0 && (
                                    <Tooltip title="Добавлен код рекламного блока">
                                        <div>
                                            <AdsIcon />
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </PluginViewWrapper>
        </>
    );
};

export { Ads };
