import React, { FC, useEffect, useState } from 'react';
import {
    HeadTemplatePlugin,
    TemplatePlugin,
} from '@mega/editor';
import { useEditor } from '@apostroph/store';
import {
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import {
    HorizontalLineMeta,
    HorizontalLineProps,
} from './HorizontalLine.types';
import { Divider, Icon, Typography } from '@mega/ui';
import { HorizontalRule as HorizontalRuleIcon } from '@mega/icons';
const marginValues = ['none', '15', '30'];
const HorizontalLine: FC<HorizontalLineProps> = ({
    id,
    parentId,
    type,
}) => {
    return (
        <TemplatePlugin
            head={
                <HeadTemplatePlugin
                    id={id}
                    parentId={parentId}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '4px',
                        }}
                    >
                        <Icon size="16">
                            <HorizontalRuleIcon />
                        </Icon>
                        <Typography size={'12'}>
                            Разделитель
                        </Typography>
                    </div>
                </HeadTemplatePlugin>
            }
        />
    );
};

export { HorizontalLine };
