import React, { FC, useEffect, useCallback } from 'react';
import { renderToString } from 'react-dom/server';
import { useField } from 'formik';
import { ApostrophSerializerMemo } from '../ApostrophSerializer';
import { debounce } from 'lodash-es';

//remove later
const EditorDataToHtml: FC<{
    updater: string;
    updated: string;
}> = ({ updater, updated }) => {
    // const [, { value }] = useField({
    //     name: updater,
    // });
    // const [, , { setValue }] = useField({
    //     type: 'text',
    //     name: updated,
    // });

    // const debouncedSave = useCallback(
    //     debounce(
    //         (nextValue) => {
    //             setValue(nextValue);
    //         },
    //         250,
    //         {
    //             maxWait: 1000,
    //         },
    //     ),
    //     [],
    // );

    // useEffect(() => {
    //     const editor_html = renderToString(
    //         <ApostrophSerializerMemo initData={value} />,
    //     );
    //     debouncedSave(editor_html);
    // }, [value]);

    return null;
};

export { EditorDataToHtml };
