import * as CLIENT from '@mega/api';
import {
    Article,
    ArticleCreate,
    ArticleForm,
    ArticleUpdate,
    Articles,
    ArticlesQuery,
} from '@mega/api';
import { createModel } from '@rematch/core';
import {
    DispatchEffectWithDependency,
    EmptyDispatchEffectWithDependency,
} from '../type';
import { v4 as uuidv4 } from 'uuid';

import type { RootModel } from '../rootModel';
import { compact, isEqual } from 'lodash-es';

export interface ArticleCreateEffect
    extends DispatchEffectWithDependency<ArticleCreate> {}

export interface ArticleUpdateEffect
    extends DispatchEffectWithDependency<ArticleUpdate> {
    query: {
        id: number;
    };
}

const defaultItem = (
    lidBlockID: string,
    contentBlockId: string,
    milkId: string,
    textId: string,
) => ({
    items: [lidBlockID, contentBlockId],
    data: {
        [contentBlockId]: {
            id: contentBlockId,
            meta: {
                gap: '60px',
                name: 'Корневой блок',
                wrap: 'nowrap',
                align: 'column',
                padding: '0px',
                stretch: 'nostretch',
                verticalAlign: 'top',
                horizontalAlign: 'left',
            },
            type: 'Block',
            childrenElement: [textId],
        },
        [milkId]: {
            id: milkId,
            meta: JSON.stringify({
                content: {
                    root: {
                        type: 'root',
                        format: '',
                        indent: 0,
                        version: 1,
                        children: [
                            {
                                type: 'paragraph',
                                format: '',
                                indent: 0,
                                version: 1,
                                children: [],
                                direction: 'ltr',
                            },
                        ],
                        direction: 'ltr',
                    },
                },
            }),
            type: 'MILK',
            childrenElement: [],
        },
        [lidBlockID]: {
            id: lidBlockID,
            meta: {
                gap: '60px',
                name: 'Лид-абзац',
                wrap: 'nowrap',
                align: 'column',
                padding: '0px',
                stretch: 'nostretch',
                verticalAlign: 'top',
                horizontalAlign: 'left',
            },
            type: 'LeadBlock',
            childrenElement: [milkId],
        },
        [textId]: {
            id: textId,
            meta: {
                content: '',
            },
            type: 'MILK',
            childrenElement: [],
        },
    },
});

export const typesArticleEnum = {
    article: {
        value: 'article',
        label: 'Новость',
    },
    text: {
        value: 'text',
        label: 'Текст',
    },
    video: {
        value: 'video',
        label: 'Видео',
    },
    story: {
        value: 'story',
        label: 'История',
    },
};

export const timeTemplate = 'YYYY-MM-DDTHH:mm';

export interface ArticleModel {
    data: Article | null;
    meta: {
        wasChanged: boolean;
        wasInit: boolean;
        hasDiff: boolean;
        showDiff: boolean;
    };
}

export const formatArticleData = (
    article: Article | null,
) => {
    if (!article) return {};
    return {
        ...article,
        article_type:
            typesArticleEnum[
                article?.article_type || 'article'
            ],
        show_cover_in_body: !article?.show_cover_in_body,
    };
};

const article = createModel<RootModel>()({
    name: 'article',
    state: {
        data: null,
        meta: {
            wasChanged: false,
            wasInit: false,
            hasDiff: false,
            showDiff: false,
        },
    } as ArticleModel,
    reducers: {
        put: (state, payload: Article | null) => {
            return { ...state, data: payload };
        },
        clear: (state) => ({
            ...state,
            data: null,
        }),
        default: () => ({
            data: null,
            meta: {
                wasChanged: false,
                wasInit: false,
                hasDiff: false,
                showDiff: false,
            },
        }),
    },
    selectors: (slice) => ({
        formView() {
            return slice<ArticleForm | {}>(
                ({ data: article }) =>
                    formatArticleData(article),
            );
        },
    }),
    effects: (dispatch) => ({
        transformFromFormViewToModel: (
            article: ArticleForm,
            store,
        ): ArticleUpdate => {
            //FIXME
            const articleType =
                article?.article_type?.value ||
                //@ts-ignore
                article?.article_type?.id;

            return {
                id: store.article.data?.id,
                slug: article.slug,
                pub_date: article.pub_date,
                title: article.title || '',
                //@ts-ignore
                article_type:
                    articleType === 'text'
                        ? 'text'
                        : articleType === 'article'
                        ? 'article'
                        : null,
                files: article.files || [],
                //@ts-ignore
                cover: article.cover?.id || null,
                snippet_cover: article.snippet_cover,
                is_gov_contract: article.is_gov_contract,
                //@ts-ignore
                main_video: article.main_video?.id || null,
                editor_data: article.editor_data,
                explanation: article.explanation,
                dzen_title: article.dzen_title,
                meta_title: article.meta_title,
                show_cover_in_body:
                    !article.show_cover_in_body,
                is_important: article.is_important,
                is_active: article.is_active,
                is_exclusive: article.is_exclusive,
                is_muted: article.is_muted,
                is_feed_hidden: article.is_feed_hidden,
                is_noindex: article.is_noindex,
                is_autogrid: article.is_autogrid,
                tags: compact(
                    article.tags?.map((tag) => tag.id),
                ),
                telegram_post:
                    article.telegram_post || undefined,
                redirect_url:
                    article.redirect_url || undefined,
                category: article.category?.id,
                rssfeeds: compact(
                    article.rssfeeds?.map((rss) => rss.id),
                ),

                authors: compact(
                    article.authors?.map(
                        (author) => author.id,
                    ),
                ),
                images: compact(
                    Array.from(
                        new Set(
                            article.images?.map(
                                (image) => image.id,
                            ),
                        ),
                    ),
                ),
                videos: compact(
                    Array.from(
                        new Set(
                            article.videos?.map(
                                (video) => video.id,
                            ),
                        ),
                    ),
                ),
                owner: article.owner?.id,
                editor_html: article.editor_html,
            };
        },
        transformFromModelToFormView: (
            article: Article,
        ): ArticleForm => {
            return {
                ...article,
                article_type:
                    typesArticleEnum[
                        article?.article_type || 'article'
                    ],
            };
        },

        get: async (id: number, store) => {
            try {
                const response =
                    await CLIENT.articles.getById?.(
                        { id },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    dispatch.article.put(response.data);

                    return {
                        isOk: true,
                        data: response.data,
                    };
                }
            } catch (e) {
                if (
                    CLIENT.articles.getById &&
                    e instanceof
                        CLIENT.articles.getById.Error
                ) {
                    const error = e.getActualType();
                    return {
                        isOk: false,
                        error: error,
                    };
                }
            }
        },
        create: async (
            { payload, options }: ArticleCreateEffect,
            store,
        ) => {
            try {
                const response =
                    await CLIENT.articles?.create?.(
                        {
                            ...payload,
                            site: store.site.site?.id || '',
                            editor_data: defaultItem(
                                uuidv4(),
                                uuidv4(),
                                uuidv4(),
                                uuidv4(),
                            ),
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );

                if (response?.ok) {
                    if (options?.router) {
                        const { navigate, location } =
                            options.router;
                        navigate(
                            `/${
                                store.site.site?.id || ''
                            }/article/change/${
                                response.data.id
                            }`,
                            {
                                state: location,
                            },
                        );
                    }
                    return {
                        isOk: true,
                        data: response.data,
                    };
                }
            } catch (e) {
                if (
                    CLIENT.articles.create &&
                    e instanceof
                        CLIENT.articles.create.Error
                ) {
                    const error = e.getActualType();

                    if (
                        options?.notifier?.enqueueSnackbar
                    ) {
                        const { enqueueSnackbar } =
                            options.notifier;
                        if (error.data.title[0]) {
                            enqueueSnackbar(
                                error.data.title[0],
                                {
                                    variant: 'error',
                                },
                            );
                        }
                    }

                    return {
                        isOk: false,
                        error: error,
                    };
                }
            }
        },
        update: async (
            { query, payload }: ArticleUpdateEffect,
            store,
        ) => {
            try {
                const response =
                    await CLIENT.articles?.updatePartialById?.(
                        {
                            ...payload,
                            id: query.id,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    dispatch.article.put(response.data);
                    if (response.data?.id) {
                        dispatch.articleProgress.clear(
                            response.data.id,
                        );
                    }

                    return {
                        isOk: true,
                        data: response.data,
                    };
                }
            } catch (e) {
                if (
                    CLIENT.articles.updatePartialById &&
                    e instanceof
                        CLIENT.articles.updatePartialById
                            .Error
                ) {
                    const error = e.getActualType();

                    if (
                        CLIENT.articles.updatePartialById &&
                        e instanceof
                            CLIENT.articles
                                .updatePartialById.Error
                    ) {
                        const error = e.getActualType();
                        if (error.status === 401) {
                            const { isOk } =
                                await dispatch.auth[401](
                                    error,
                                );
                            if (isOk) {
                                dispatch.article.update({
                                    query,
                                    payload,
                                });
                            }
                        }
                    }

                    return {
                        isOk: false,
                        error: error,
                    };
                }
            }
        },
        remove: async (id: number, store) => {
            const query = store.articles.query;
            try {
                const response =
                    await CLIENT?.articles?.deleteById?.({
                        id,
                    });
                if (response?.ok) {
                    dispatch.articles.get(query);
                    return {
                        isOk: true,
                        data: response.data,
                    };
                }
            } catch (e) {
                if (
                    CLIENT.articles.deleteById &&
                    e instanceof
                        CLIENT.articles.deleteById.Error
                ) {
                    const error = e.getActualType();

                    return {
                        isOk: false,
                        error: error,
                    };
                }
            }
        },
        removeByIdWithRoomCheck: async (
            id: number,
            store,
        ) => {
            const query = store.articles.query;

            const pages = store.articles.pages;

            const getPayloadWithPageElement = (pages: {
                [key: string]: Array<Articles>;
            }): ArticlesQuery => {
                let page;
                for (let key in pages) {
                    if (
                        pages[key].find(
                            (item) => item.id === id,
                        )
                    ) {
                        page = { page: Number(key) };
                    }
                }
                return {
                    ...query,
                    ...(page as { page: number }),
                };
            };

            const payload =
                getPayloadWithPageElement(pages);

            try {
                const response =
                    await CLIENT?.articles?.deleteByIdWithRoomCheck?.(
                        {
                            id,
                        },
                    );
                if (response?.ok) {
                    dispatch.articles.get(payload);
                    return {
                        isOk: true,
                        data: response.data,
                    };
                }
            } catch (e) {
                if (
                    CLIENT.articles
                        .deleteByIdWithRoomCheck &&
                    e instanceof
                        CLIENT.articles
                            .deleteByIdWithRoomCheck.Error
                ) {
                    const error = e.getActualType();

                    return {
                        isOk: false,
                        error: error,
                    };
                }
            }
        },
        goToSite: async (
            _: EmptyDispatchEffectWithDependency,
            store,
        ) => {
            if (store.article.data) {
                const { url } = store.article.data;
                if (url) {
                    window.open(url, '_blank');
                }
            }
        },
        txnGet: async (id: number) => {
            const currentData = await dispatch.article.get(
                id,
            );

            if (currentData?.isOk && currentData?.data) {
                const formData =
                    dispatch.article.transformFromModelToFormView(
                        currentData.data,
                    );
                dispatch.articleProgress.init({
                    id,
                    data: formData,
                });
            }
        },
        getTagsRecommends: async (
            payload: {
                title: string;
                text: string;
            },
            store,
        ) => {
            const siteId = store.site.site?.id;

            if (siteId) {
                const res =
                    await CLIENT.articles.getTagsRecommends?.(
                        {
                            title: payload.title,
                            text: payload.text,
                        },
                        {
                            headers: {
                                'X-Site': siteId,
                            },
                        },
                    );
                if (typeof res === 'undefined') {
                    return {
                        ok: false,
                        data: null,
                    };
                }
                if (res.ok) {
                    return {
                        ok: true,
                        data: res.data,
                    };
                }
                return {
                    ok: false,
                    data: null,
                };
            }
            return {
                ok: false,
                data: null,
            };
        },

        getTagsRecommended: async (id, store) => {
            const siteId = store.site.site?.id;
            if (id && siteId) {
                const res =
                    await CLIENT.articles.getTagsRecommended?.(
                        { id: id, tags_count: 50 },
                        {
                            headers: {
                                'X-Site': siteId,
                            },
                        },
                    );
                if (typeof res === 'undefined') {
                    return {
                        ok: false,
                        data: null,
                    };
                }
                if (res.ok) {
                    return {
                        ok: true,
                        data: res.data,
                    };
                }
                return {
                    ok: false,
                    data: null,
                };
            }
            return {
                ok: false,
                data: null,
            };
        },
    }),
});

export { article };
export default article;
