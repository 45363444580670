import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';

const usePaperStore = () => {
    const store = useStoreSelector(
        (state: RootState) => state.paper,
    );
    const loading = useStoreSelector(
        (state: RootState) => state.loading.effects.paper,
    );

    return {
        store,
        loading,
    };
};

const usePaperDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        get: dispatch.paper.get,
        put: dispatch.paper.put,
        create: dispatch.paper.create,
        update: dispatch.paper.update,
        remove: dispatch.paper.remove,
    };
};

export { usePaperStore, usePaperDispatch };
