import React, { PropsWithChildren } from 'react';
import Select, {
    components,
    ContainerProps,
} from 'react-select';

interface ContainerClassName {
    className?: string;
}

type ContainerOverideProps = ContainerProps<
    Record<string, unknown>,
    true
>;

const Container =
    ({ className = '' }: ContainerClassName) =>
    ({
        children,
        ...props
    }: PropsWithChildren<ContainerOverideProps>) =>
        (
            <div className={className}>
                <components.SelectContainer {...props}>
                    {children}
                </components.SelectContainer>
            </div>
        );

export { Container };
export default Container;
