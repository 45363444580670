import React, {
    FC,
    ReactElement,
    cloneElement,
    useState,
    MouseEvent,
} from 'react';
import { Modal } from '@mega/utils';
import {
    centerLayoutClasses,
    paperClasses,
    paddingBaseClasses,
} from '@mega/styles';
import { Stack, Row, Typography, Button } from '@mega/ui';
export interface AcceptModalProps {
    Trigger: ReactElement;
    msg: string;
}

const AcceptModal: FC<AcceptModalProps> = ({
    Trigger,
    msg,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const handleOpen = () => {
        setIsOpen(true);
    };
    const handleClose = (
        e?: MouseEvent<HTMLButtonElement>,
    ) => {
        if (e) {
            e.stopPropagation();
        }
        setIsOpen(false);
    };

    const CloneTrigger = cloneElement(Trigger, {
        onClick: handleOpen,
    });

    const handleAccept = (
        e: MouseEvent<HTMLButtonElement>,
    ) => {
        if (e) {
            e.stopPropagation();
        }
        Trigger?.props?.onClick?.();
        handleClose();
    };

    return (
        <>
            {CloneTrigger}
            <Modal
                open={isOpen}
                onClose={() => handleClose()}
            >
                <div
                    className={[
                        centerLayoutClasses.recipe({}),
                    ].join(' ')}
                    style={{
                        pointerEvents: 'none',
                    }}
                >
                    <div
                        className={[
                            paperClasses.recipe({
                                variant: 'outlineFilled',
                                color: 'gray',
                            }),
                            paddingBaseClasses.recipe({
                                size: '18x18',
                            }),
                        ].join(' ')}
                        style={{
                            pointerEvents: 'visible',
                        }}
                    >
                        <Stack gap="16">
                            <Typography size="16">
                                {msg}
                            </Typography>
                            <Row gap="24">
                                <Button
                                    onClick={handleAccept}
                                    label={'Подтвердить'}
                                />

                                <Button
                                    onClick={handleClose}
                                    label={'Отменить'}
                                />
                            </Row>
                        </Stack>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export { AcceptModal };
