import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgPubOff = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <rect
            width={26}
            height={26}
            rx={4}
            fill="#EB5757"
        />
        <path
            fill="currentColor"
            d="M10 9h2v8h-2zM14 9h2v8h-2z"
        />
        <rect
            x={4.5}
            y={4.5}
            width={17}
            height={17}
            rx={8.5}
            stroke="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgPubOff);
export default ForwardRef;
