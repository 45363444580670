import React, {
    FC,
    memo,
    useCallback,
    useEffect,
} from 'react';
import { Paper, Stack } from '@mega/ui';

import {
    DependencyEditorContext,
    useEditor,
} from '@apostroph/store';
import type {
    EditorCore,
    EditorModel,
} from '@apostroph/types';
import { CustomSerializer } from './CustomSerializer';
import { EditorControls } from './EditorControls';
import { useAphostrophExternalDataContext } from './context/AphostrophExternalData';
import { typographyClasses } from '@mega/styles';
const defaultConfig: EditorModel & EditorCore = {
    items: [],
    data: {},
    groups: {},
    controls: {},
    rootElements: {},
    pluginElements: {},
};

const Counter = () => {
    const context = useAphostrophExternalDataContext();

    return (
        <div
            className={typographyClasses.recipe({
                size: '12',
            })}
            style={{
                color: '#878787',
            }}
        >
            <span>Символов: </span>
            <span>
                {context?.gliphs ? (
                    Object.values(context.gliphs).reduce(
                        (acc, item) => {
                            return acc + item.count;
                        },
                        0,
                    )
                ) : (
                    <></>
                )}
            </span>
        </div>
    );
};

const EditorInitializer: FC<{
    dependencyConfig?: Partial<EditorCore>;
    initialState?: EditorModel;
    onChange?: (state: object) => void;
}> = ({
    dependencyConfig,
    initialState,
    onChange = undefined,
}) => {
    const { editor, handleInit } = useEditor();

    const memoizedCallback = useCallback(() => {
        handleInit({
            items: [
                ...defaultConfig.items,
                ...(initialState?.items || []),
            ],
            data: {
                ...defaultConfig.data,
                ...(initialState?.data || {}),
            },
        });
    }, []);

    useEffect(() => {
        onChange?.(editor);
    }, [editor]);

    useEffect(() => {
        memoizedCallback();
    }, [memoizedCallback]);

    return (
        <Paper
            color={'gray'}
            variant={'filled'}
            classes={{
                padding: {
                    size: '24x24',
                },
            }}
        >
            <Stack gap="12">
                <DependencyEditorContext
                    config={{
                        groups: {
                            ...defaultConfig.groups,
                            ...(dependencyConfig?.groups ||
                                {}),
                        },
                        controls: {
                            ...defaultConfig.controls,
                            ...(dependencyConfig?.controls ||
                                {}),
                        },
                        rootElements: {
                            ...defaultConfig.rootElements,
                            ...(dependencyConfig?.rootElements ||
                                {}),
                        },
                        pluginElements: {
                            ...defaultConfig.pluginElements,
                            ...(dependencyConfig?.pluginElements ||
                                {}),
                        },
                    }}
                >
                    <CustomSerializer />
                    <EditorControls />
                    <Counter />
                </DependencyEditorContext>
            </Stack>
        </Paper>
    );
};

const MemoEditorInitializer = memo(EditorInitializer);

export {
    MemoEditorInitializer as EditorInitializer,
    defaultConfig,
};
