import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgConfirmed = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 81 81"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.5 17.512c-12.557 0-22.858 10.226-22.858 22.988 0 12.762 10.301 22.989 22.858 22.989 12.556 0 22.857-10.227 22.857-22.989S53.057 17.512 40.5 17.512ZM13.833 40.5c0-14.662 11.872-26.667 26.667-26.667 14.794 0 26.666 12.005 26.666 26.667 0 14.661-11.872 26.667-26.666 26.667-14.795 0-26.667-12.006-26.667-26.667Z"
            fill="#C7C7C7"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.163 31.797c.831.709.898 1.921.15 2.708l-16.195 17.03-9.22-7.25c-.86-.676-.979-1.885-.264-2.7.714-.815 1.991-.927 2.852-.25l6.226 4.895 13.59-14.29c.749-.788 2.03-.852 2.86-.143Z"
            fill="#C7C7C7"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgConfirmed);
export default ForwardRef;
