import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgGallery = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M6.469 11.119H13.8l-2.381-3.207-1.931 2.532-1.275-1.632-1.744 2.307Zm-1.594 3.319c-.3 0-.563-.113-.787-.338a1.08 1.08 0 0 1-.338-.787v-10.5c0-.3.112-.563.338-.788a1.08 1.08 0 0 1 .787-.337h10.5c.3 0 .563.112.788.337.224.225.337.488.337.788v10.5c0 .3-.113.562-.337.787a1.08 1.08 0 0 1-.788.338h-10.5Zm0-1.126h10.5v-10.5h-10.5v10.5Zm-2.25 3.376c-.3 0-.563-.113-.788-.338a1.08 1.08 0 0 1-.337-.788V3.938h1.125v11.624H14.25v1.126H2.625Zm2.25-13.875v10.5-10.5Z"
            fill="#F4F4F4"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgGallery);
export default ForwardRef;
