import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgClose = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="m4.669 14.119-.788-.788L8.212 9l-4.33-4.331.787-.788L9 8.213l4.331-4.332.788.788L9.787 9l4.332 4.331-.788.788L9 9.787 4.669 14.12Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgClose);
export default ForwardRef;
