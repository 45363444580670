import * as CLIENT from '@mega/api';
import {
    SourcesReport,
    SourcesReportCreate,
    SourcesReportUpdate,
} from '@mega/api';
import { createModel } from '@rematch/core';
import { DispatchEffectWithDependency } from '../type';

import type { RootModel } from '../rootModel';

export interface SourcesReportCreateEffect
    extends DispatchEffectWithDependency<SourcesReportCreate> {}

export interface SourcesReportUpdateEffect
    extends DispatchEffectWithDependency<SourcesReportUpdate> {
    query: {
        id: number;
    };
}

const sourcesReport = createModel<RootModel>()({
    name: 'sourcesReport',
    state: {} as SourcesReport,
    reducers: {
        put: (state, payload: SourcesReport) => {
            return { ...payload };
        },
    },
    effects: (dispatch) => ({
        get: async (params: { id: number }, store) => {
            try {
                const response =
                    await CLIENT.reports.getById?.({
                        ...params,
                    });
                if (response?.ok) {
                    dispatch.sourcesReport.put(
                        response.data,
                    );
                }
            } catch (e) {
                if (
                    CLIENT.reports.getById &&
                    e instanceof
                        CLIENT.reports.getById.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },
        create: async (
            { payload, options }: SourcesReportCreateEffect,
            store,
        ) => {
            try {
                const response =
                    await CLIENT.reports?.create?.({
                        ...payload,
                    });
                if (response?.ok) {
                    if (options?.router) {
                        const { navigate, location } =
                            options.router;
                        navigate(
                            `/${
                                store.site.site?.id || ''
                            }/reportsource/change/${
                                response.data.id
                            }`,
                            {
                                state: location,
                            },
                        );
                    }
                    if (options?.notifier) {
                        const { enqueueSnackbar } =
                            options.notifier;
                        enqueueSnackbar('Источник создан', {
                            variant: 'success',
                        });
                    }
                }
            } catch (e) {
                if (
                    CLIENT.reports.create &&
                    e instanceof CLIENT.reports.create.Error
                ) {
                    const error = e.getActualType();

                    if (
                        options?.notifier?.enqueueSnackbar
                    ) {
                        const { enqueueSnackbar } =
                            options.notifier;

                        const errormsgs = Object.values(
                            error.data,
                        );

                        errormsgs?.map((msg) => {
                            enqueueSnackbar(msg as string, {
                                variant: 'error',
                            });
                        });
                    }
                    return {
                        isOk: false,
                        error: error,
                    };
                }
            }
        },
        update: async (
            {
                query,
                payload,
                options,
            }: SourcesReportUpdateEffect,
            store,
        ) => {
            try {
                const response =
                    await CLIENT.reports?.updatePartialById?.(
                        {
                            ...payload,
                            id: query.id,
                        },
                    );
                if (response?.ok) {
                    dispatch.sourcesReport.put(
                        response.data,
                    );
                    if (options?.notifier) {
                        const { enqueueSnackbar } =
                            options.notifier;
                        enqueueSnackbar(
                            'Источник обновлен',
                        );
                    }
                }
            } catch (e) {
                if (
                    CLIENT.reports.updatePartialById &&
                    e instanceof
                        CLIENT.reports.updatePartialById
                            .Error
                ) {
                    const error = e.getActualType();
                    if (
                        options?.notifier?.enqueueSnackbar
                    ) {
                        const { enqueueSnackbar } =
                            options.notifier;

                        if (error.data.codes[0]) {
                            enqueueSnackbar(
                                error.data.codes[0],
                                {
                                    variant: 'error',
                                },
                            );
                        }
                    }
                    return error.data;
                }
            }
        },
        remove: async (id: number, store) => {
            const query = store.sourcesReports.query;

            try {
                const response =
                    await CLIENT?.reports?.deleteById?.({
                        id,
                    });
                if (response?.ok) {
                    dispatch.sourcesReports.get(query);
                }
            } catch {}
        },
    }),
});

export { sourcesReport };
export default sourcesReport;
