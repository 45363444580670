/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, {
    Children,
    cloneElement,
    FC,
    ReactElement,
} from 'react';

import { breadClasses } from '@mega/styles';

export interface BreadCrProps {
    children?:
        | ReactElement
        | ReactElement[]
        | null
        | undefined;
    startIcon?: ReactElement;
    separator?: ReactElement;
}

const Bread: FC<BreadCrProps> = ({
    children,
    startIcon,
    separator = <div className={breadClasses.dot} />,
}) => {
    const hasStartIcon = Boolean(startIcon);
    if (children) {
        return (
            <div className={breadClasses.base}>
                {hasStartIcon && startIcon}
                {Children.map(children, (child, index) =>
                    cloneElement(child, {
                        isFirst:
                            child?.props?.isFirst ??
                            index === 0,
                        separator:
                            child?.props?.isFirst ??
                            index === 0
                                ? null
                                : separator,
                    }),
                )}
            </div>
        );
    }
    return null;
};

export { Bread };
