import React, { FC, useEffect } from 'react';
import {
    EntityListLayout,
    Pagination,
    ListContent,
} from '@mega/ui';
import {
    ListingInfoUnit,
    NoResultsFoundMessage,
} from '@mega/core';
import { InfounitFilters } from './InfounitFilters';
import {
    useInfounittasksStore,
    useInfounittasksDispatch,
} from '@mega/store';

interface InfounittasksProps {
    children?: never;
}

const Infounittasks: FC<InfounittasksProps> = () => {
    const store = useInfounittasksStore();
    const { jumpTo } = useInfounittasksDispatch();

    const infounittasksListName = 'infounittasksList';
    const allInfounittasksCount = store.meta.count || 0;
    const infounittasksPerPage = store.query.per_page || 1;
    const allPagesCount = Math.ceil(
        allInfounittasksCount / infounittasksPerPage,
    );

    const hasPage = store.pages['1'] ? true : false;
    const isItLoadingNow = store.loading.get.loading;
    const isPageEmpty =
        !isItLoadingNow &&
        hasPage &&
        store.pages['1'].length === 0;

    useEffect(() => {
        if (!isItLoadingNow) {
            window.scrollTo(0, 0);
        }
    }, [isItLoadingNow]);
    return (
        <>
            <EntityListLayout
                setting={
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: '18px',
                        }}
                    >
                        <InfounitFilters />
                    </div>
                }
            >
                <ListContent name={infounittasksListName}>
                    {() => {
                        return <ListingInfoUnit />;
                    }}
                </ListContent>
            </EntityListLayout>
            <NoResultsFoundMessage
                isPageEmpty={isPageEmpty}
            />
            <Pagination
                currentPage={store.currentPage}
                pageCount={allPagesCount}
                jumpTo={jumpTo}
                loading={isItLoadingNow}
            />
        </>
    );
};

export { Infounittasks };
