import React, { FC } from 'react';

import {
    InputField,
    Stack,
    WithLabel,
    InputSelectMultiField,
    SelectSingleField,
    WithTrigger,
    IconButton,
    Row,
    Typography,
} from '@mega/ui';

import { getOptionsAuthorsFull } from '@mega/core';
import {
    useSiteStore,
    useUserInfo,
    useAuthorsDispatch,
} from '@mega/store';

import { Field, useField } from 'formik';
import { Link } from 'react-router-dom';
import { ArrowDown, FilterList, Info } from '@mega/icons';
import { ChangePasswordModal } from './ChangePasswordModal/ChangePasswordModal';
import { employeeFormBodyClasses } from './EmployeeFormBody.css';
interface EmployeeFormBodyProps {
    type: 'create' | 'edit';
}

const PhoneInput: React.FC = () => {
    const [field, meta, helpers] = useField('phone');
    const setValue = helpers.setValue;

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const { value } = event.target;
        let cleanedValue = value.replace(/\D+/g, '');
        let formattedValue = '+7 ';

        if (cleanedValue.length > 1) {
            formattedValue += `(${cleanedValue.substring(
                1,
                4,
            )}`;
        }
        if (cleanedValue.length >= 5) {
            formattedValue += `) ${cleanedValue.substring(
                4,
                7,
            )}`;
        }
        if (cleanedValue.length >= 8) {
            formattedValue += `-${cleanedValue.substring(
                7,
                9,
            )}`;
        }
        if (cleanedValue.length >= 10) {
            formattedValue += `-${cleanedValue.substring(
                9,
                11,
            )}`;
        }
        setValue(formattedValue);
    };

    return (
        <InputField
            name="phone"
            type="tel"
            dimension="small"
            placeholderBase="+7 ___ ____ __ __"
            onChange={handleChange}
        />
    );
};

const EmployeeFormBody: FC<EmployeeFormBodyProps> = ({
    type,
}): JSX.Element => {
    const currentSite = useSiteStore();
    const { me } = useUserInfo();
    const groups = me?.groups;
    const sites = me?.sites ? me?.sites : [];
    const { addCustomSiteQuery } = useAuthorsDispatch();
    const isTypeEdit = type === 'edit';
    const [field] = useField('filter');

    const sitesList = sites.map((el) => ({
        label: el.label,
        value: el.id,
    }));

    const sitesFilter =
        field.value.length > 0 ? field.value : sites;

    return (
        <div>
            <div
                className={
                    employeeFormBodyClasses.gridContainer
                }
            >
                <Row
                    gap="8"
                    className={
                        employeeFormBodyClasses.itemCheckbox
                    }
                >
                    <Field
                        id="is_active"
                        name="is_active"
                        type="checkbox"
                    />
                    <Typography size="16">
                        Активен
                    </Typography>
                </Row>
                <WithLabel
                    id="last_name"
                    title="Фамилия"
                    isRequired={true}
                    className={[
                        employeeFormBodyClasses.itemSurmane,
                        employeeFormBodyClasses.baseInput,
                    ].join(' ')}
                >
                    <InputField
                        name="last_name"
                        type="text"
                        dimension="small"
                        placeholderBase="Введите фамилию"
                    />
                </WithLabel>
                <WithLabel
                    id="first_name"
                    title="Имя"
                    isRequired={true}
                    className={[
                        employeeFormBodyClasses.itemName,
                        employeeFormBodyClasses.baseInput,
                    ].join(' ')}
                >
                    <InputField
                        name="first_name"
                        type="text"
                        dimension="small"
                        placeholderBase="Введите имя"
                    />
                </WithLabel>
                <WithLabel
                    id="patronymic"
                    title="Отчество"
                    className={[
                        employeeFormBodyClasses.itemPatrinymic,
                        employeeFormBodyClasses.baseInput,
                    ].join(' ')}
                >
                    <InputField
                        name="patronymic"
                        type="text"
                        dimension="small"
                        placeholderBase="Введите отчество"
                    />
                </WithLabel>

                <WithLabel
                    id="Email"
                    title="Адрес электронной почты"
                    isRequired={true}
                    className={[
                        employeeFormBodyClasses.itemEmail,
                        employeeFormBodyClasses.baseInput,
                    ].join(' ')}
                >
                    <InputField
                        name="email"
                        type="email"
                        dimension="small"
                        placeholderBase="sample@mail.ru"
                    />
                </WithLabel>
                <WithLabel
                    id={'username'}
                    title={'Логин'}
                    isRequired={true}
                    className={[
                        employeeFormBodyClasses.itemLogin,
                        employeeFormBodyClasses.baseInput,
                    ].join(' ')}
                >
                    <InputField
                        name="username"
                        type="text"
                        dimension="small"
                        disabled={isTypeEdit}
                        placeholderBase="Введите логин"
                    />
                </WithLabel>
                {isTypeEdit ? (
                    <ChangePasswordModal
                        className={[
                            employeeFormBodyClasses.itemPassword,
                        ].join(' ')}
                    />
                ) : null}
                <Row
                    className={[
                        employeeFormBodyClasses.itemAuthor,
                        employeeFormBodyClasses.baseInput,
                    ].join(' ')}
                >
                    <WithLabel
                        id={'author'}
                        title={'Автор'}
                        isRequired
                    >
                        <SelectSingleField
                            name="author"
                            handleLoader={getOptionsAuthorsFull(
                                {
                                    site:
                                        currentSite?.id ||
                                        '',
                                    sites: sitesFilter.map(
                                        (el) => el.id,
                                    ),
                                },
                            )}
                            placeholder="Выберите автора"
                            endIcon={<ArrowDown />}
                        />
                    </WithLabel>

                    <WithTrigger
                        trigger={({ handleClick }) => (
                            <IconButton
                                size="large"
                                onClick={handleClick}
                                style={{
                                    minHeight: '34px',
                                    alignSelf: 'center',
                                    position: 'relative',
                                    bottom: '3px',
                                    marginLeft: '10px',
                                }}
                            >
                                <FilterList />
                            </IconButton>
                        )}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <div
                            style={{
                                width: '300px',
                                height: '250px',
                            }}
                        >
                            <InputSelectMultiField
                                key="select_sites"
                                name="filter"
                                placeholder="Сайты"
                                actionOnSelectedOption={(
                                    test,
                                ) => {
                                    addCustomSiteQuery(
                                        test.map(
                                            (item) =>
                                                `${item.value}`,
                                        ),
                                    );
                                }}
                                handleLoader={async () => {
                                    return sitesList
                                        ? sitesList
                                        : [];
                                }}
                                endIcon={<ArrowDown />}
                            />
                        </div>
                    </WithTrigger>
                </Row>

                <WithLabel
                    id={'telegram'}
                    title={'Телеграм'}
                    className={[
                        employeeFormBodyClasses.itemTelegram,
                        employeeFormBodyClasses.baseInput,
                    ].join(' ')}
                >
                    <InputField
                        name="telegram"
                        type="text"
                        dimension="small"
                        placeholderBase="@имя пользователя"
                    />
                </WithLabel>

                <WithLabel
                    id={'phone'}
                    title={'Телефон'}
                    className={[
                        employeeFormBodyClasses.itemPhone,
                        employeeFormBodyClasses.baseInput,
                    ].join(' ')}
                >
                    <PhoneInput />
                </WithLabel>
                <Row
                    gap="8"
                    className={
                        employeeFormBodyClasses.itemText
                    }
                >
                    <Info
                        style={{
                            width: '24px',
                            height: '24px',
                            alignSelf: 'center',
                        }}
                    />

                    <Typography
                        weight="regular"
                        size="12"
                        style={{
                            color: '#878787',
                        }}
                        as="div"
                    >
                        Для выбора нового автора добавьте
                        его, перейдя{' '}
                        <Link
                            target="_blank"
                            to={`/${currentSite?.id}/author/add`}
                            state={{
                                state: location,
                            }}
                            style={{
                                color: 'black',
                            }}
                        >
                            по ссылке
                        </Link>
                        . Затем выберите созданного автора в
                        выпадающем списке.
                    </Typography>
                </Row>
                <WithLabel
                    id={'groups'}
                    title={'Группы'}
                    className={
                        employeeFormBodyClasses.itemGroups
                    }
                    isRequired
                >
                    <InputSelectMultiField
                        name="groups"
                        handleLoader={async () => {
                            return groups?.map((el) => {
                                return {
                                    value: el.id,
                                    label: el.name,
                                };
                            });
                        }}
                        placeholder="Выберите группы сотрудника"
                        endIcon={<ArrowDown />}
                    />
                </WithLabel>
            </div>
        </div>
    );
};

export { EmployeeFormBody };
export default EmployeeFormBody;
