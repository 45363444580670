import React, { FC, memo } from 'react';
import { ElementData } from '@apostroph/types';
import {
    useDependencyEditorContext,
    useEditor,
} from '@apostroph/store';
import { EditorControls } from '../EditorControls';
import { motion } from 'framer-motion';
import { renderWrapper } from './CustomSerializer.css';

const RenderPlugin: FC<{
    data: ElementData;
    currentId: string;
    parentId: string | null;
}> = ({ data, currentId, parentId }) => {
    const { rootElements, pluginElements } =
        useDependencyEditorContext();

    const currentElementData = data[currentId];
    const currentType = currentElementData.type;

    const parentElementType =
        parentId && data[parentId].type;

    const disableSection = parentElementType === 'Block';

    const currentElement =
        currentType in rootElements
            ? rootElements[currentType]
            : currentType in pluginElements
            ? pluginElements[currentType]
            : null;

    if (currentElement?.render) {
        const ComponentRender = currentElement.render;
        return (
            <ComponentRender
                key={currentId}
                id={currentId}
                parentId={parentId}
                type={currentType}
            >
                {currentElementData.childrenElement.map(
                    (currentChildrenId) => (
                        <div
                            className={renderWrapper}
                            key={currentChildrenId}
                        >
                            {currentElementData.type !==
                                'LeadBlock' && (
                                <motion.div
                                    whileHover={{
                                        opacity: 1,
                                        transition: {
                                            duration: 0.2,
                                        },
                                    }}
                                    style={{
                                        opacity: 0.6,
                                    }}
                                >
                                    <EditorControls
                                        id={
                                            currentChildrenId
                                        }
                                        parentId={
                                            currentElementData.id
                                        }
                                        disableSection={
                                            disableSection
                                        }
                                    />
                                </motion.div>
                            )}
                            <RenderPlugin
                                currentId={
                                    currentChildrenId
                                }
                                parentId={
                                    currentElementData.id
                                }
                                data={data}
                            />
                        </div>
                    ),
                )}
            </ComponentRender>
        );
    }

    return null;
};

const MemoRenderPlugin = memo(RenderPlugin);

export const CustomSerializer: FC<{}> = () => {
    const {
        editor: { items, data },
    } = useEditor();

    return (
        <>
            {items.map((rootCurrentID) => (
                <MemoRenderPlugin
                    key={rootCurrentID}
                    currentId={rootCurrentID}
                    parentId={null}
                    data={data}
                />
            ))}
        </>
    );
};
