import React, {
    CSSProperties,
    FC,
    ReactElement,
} from 'react';
import {
    Paper,
    Divider,
    Stack,
    IconButton,
    Row,
} from '@mega/ui';
import { ArrowDown, Close } from '@mega/icons';
import { useEditor } from '@apostroph/store';
import { pluginClasses } from './plugin.css';

export interface TemplatePluginProps {
    head?: ReactElement;
    children?: ReactElement | ReactElement[];
    style?: CSSProperties;
}

export interface HeadTemplatePluginProps {
    id: string;
    parentId?: string | null;
    showMove?: boolean;
    children?: ReactElement | ReactElement[];
    onDelete?: () => void;
}

const HeadTemplatePlugin: FC<HeadTemplatePluginProps> = ({
    id,
    parentId,
    showMove = true,
    children,
    onDelete,
}) => {
    const {
        handleMoveElementUp,
        handleMoveElementDown,
        handleRemoveElement,
    } = useEditor();

    return (
        <Row gap="4">
            {showMove && (
                <>
                    <IconButton
                        type="button"
                        classes={{
                            paper: {
                                variant: 'outline',
                                color: 'white',
                            },
                        }}
                        size="medium"
                        onClick={() =>
                            handleMoveElementUp({
                                what: id,
                                parent: parentId,
                            })
                        }
                    >
                        <ArrowDown
                            style={{
                                transform: 'rotate(180deg)',
                            }}
                        />
                    </IconButton>
                    <IconButton
                        type="button"
                        classes={{
                            paper: {
                                variant: 'outline',
                                color: 'white',
                            },
                        }}
                        size="medium"
                        onClick={() =>
                            handleMoveElementDown({
                                what: id,
                                parent: parentId,
                            })
                        }
                    >
                        <ArrowDown />
                    </IconButton>
                </>
            )}
            {children}
            {showMove && (
                <div
                    style={{
                        display: 'flex',
                        flexGrow: '1',
                        flexDirection: 'row-reverse',
                    }}
                >
                    <IconButton
                        type="button"
                        classes={{
                            paper: {
                                variant: 'outline',
                                color: 'white',
                            },
                        }}
                        size="medium"
                        onClick={() => {
                            handleRemoveElement({
                                what: id,
                                parent: parentId,
                            });
                            onDelete && onDelete();
                        }}
                    >
                        <Close />
                    </IconButton>
                </div>
            )}
        </Row>
    );
};

const TemplatePlugin: FC<TemplatePluginProps> = ({
    head = null,
    style = {},
    children,
}) => {
    return (
        <div className={pluginClasses.pluginRecipe({})}>
            <Paper
                color={'white'}
                variant={'outlineFilled'}
                style={style}
                classes={{
                    padding: { size: '8x8' },
                }}
            >
                <Stack gap="8">
                    <div>{head}</div>
                    {children && <Divider />}
                    {children && (
                        <div
                            className={
                                pluginClasses.valueStyle
                            }
                        >
                            {children}
                        </div>
                    )}
                </Stack>
            </Paper>
        </div>
    );
};

export { TemplatePlugin, HeadTemplatePlugin };
export default { TemplatePlugin };
