/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
    Control,
    PositionsVariants,
} from '@apostroph/types';
import { HtmlText } from './HtmlText';
import {
    HtmlTextPluginDefault,
    HtmlTextEnum,
} from './HtmlText.types';
import CodeIcon from '@mui/icons-material/Code';
const PLUGIN_HTML_TEXT_NAME = HtmlTextEnum.type;

const DEFAULT_HTML_TEXT: HtmlTextPluginDefault = {
    type: PLUGIN_HTML_TEXT_NAME,
    meta: {
        html: '',
    },
    childrenElement: [],
};

const PLUGIN_HTML_TEXT = {
    [PLUGIN_HTML_TEXT_NAME]: {
        emptyObject: DEFAULT_HTML_TEXT,
        render: HtmlText,
    },
};

const PLUGIN_HTML_TEXT_CONTROL: Control = {
    name: PLUGIN_HTML_TEXT_NAME,
    label: 'html вставка',
    tooltip: 'Добавть html вставку',
    position: PositionsVariants.actionFeed,
    Icon: () => <CodeIcon />,
};

export {
    DEFAULT_HTML_TEXT,
    PLUGIN_HTML_TEXT,
    PLUGIN_HTML_TEXT_CONTROL,
    PLUGIN_HTML_TEXT_NAME,
};
