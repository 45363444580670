import { EditorThemeClasses } from 'lexical';
import { LexClasses } from '@apostroph/styles';
import { listFix, headingFix } from './lexfix.css';
const theme: EditorThemeClasses = {
    characterLimit: LexClasses.editorTheme.characterLimit,
    heading: {
        h1: [
            LexClasses.editorTheme.heading.h1,
            headingFix,
        ].join(' '),
        h2: [
            LexClasses.editorTheme.heading.h2,
            headingFix({ heading: 'h2' }),
        ].join(' '),
        h3: [
            LexClasses.editorTheme.heading.h3,
            headingFix({ heading: 'h3' }),
        ].join(' '),
        h4: [
            LexClasses.editorTheme.heading.h4,
            headingFix,
        ].join(' '),
        h5: [
            LexClasses.editorTheme.heading.h5,
            headingFix,
        ].join(' '),
    },
    link: LexClasses.editorTheme.link,
    list: {
        listitem: LexClasses.editorTheme.list.listitem,
        listitemChecked:
            LexClasses.editorTheme.list.listitemChecked,
        listitemUnchecked:
            LexClasses.editorTheme.list.listitemUnchecked,
        nested: {
            listitem:
                LexClasses.editorTheme.list.nested.listitem,
        },
        olDepth: [
            LexClasses.editorTheme.list.olDepth[1],
            LexClasses.editorTheme.list.olDepth[2],
            LexClasses.editorTheme.list.olDepth[3],
            LexClasses.editorTheme.list.olDepth[4],
            LexClasses.editorTheme.list.olDepth[5],
        ],
        ul: [LexClasses.editorTheme.list.ul, listFix].join(
            ' ',
        ),
        ol: listFix,
    },
    ltr: LexClasses.editorTheme.ltr,
    mark: LexClasses.editorTheme.mark,
    markOverlap: LexClasses.editorTheme.markOverlap,
    paragraph: LexClasses.editorTheme.paragraph,
    quote: LexClasses.editorTheme.quote,
    rtl: LexClasses.editorTheme.rtl,
    table: LexClasses.editorTheme.table,
    tableCell: LexClasses.editorTheme.tableCell,
    tableCellHeader: LexClasses.editorTheme.tableCellHeader,
    text: {
        bold: LexClasses.editorTheme.text.bold,
        code: LexClasses.editorTheme.text.code,
        italic: LexClasses.editorTheme.text.italic,
        strikethrough:
            LexClasses.editorTheme.text.strikethrough,
        subscript: LexClasses.editorTheme.text.subscript,
        superscript:
            LexClasses.editorTheme.text.superscript,
        underline: LexClasses.editorTheme.text.underline,
        underlineStrikethrough:
            LexClasses.editorTheme.text
                .underlineStrikethrough,
    },
};

export { theme };
export default { theme };
