'use client';
import { ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

interface ModalProps {
    children?: ReactNode;
    customRoot?: string;
}

export const ReactPortal = ({
    children,
    customRoot = '#portal-root',
}: ModalProps) => {
    const el = useRef(document?.createElement?.('div'));

    useEffect(() => {
        if (customRoot) {
            const modalRoot = document.querySelector(
                customRoot,
            ) as HTMLElement;

            const current = el.current;
            modalRoot?.appendChild(current);
        }

        return () => {
            if (customRoot) {
                const modalRoot = document.querySelector(
                    customRoot,
                ) as HTMLElement;
                const current = el.current;
                modalRoot?.removeChild(current);
            }
        };
    }, []);

    return createPortal(children, el.current);
};
