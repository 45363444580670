import * as CLIENT from '@mega/api';
import { Article, ArticleForm } from '@mega/api';
import { createModel } from '@rematch/core';
import { isEqual, omit, debounce } from 'lodash-es';
import { RoomUser } from '../../models/type';
import * as idb from 'idb';

import type { RootModel } from '../rootModel';

// let currentUsage = 0;

// function estimateSize(item: Record<string, unknown>) {
//     const string = JSON.stringify(item);
//     return new Blob([string]).size; // size in bytes
// }

const usslessKeys = [
    'isPublish',
    'isSave',
    'isUnPublish',
    'pub_date',
    'editor_html',
];

export interface ArticleProgressModel {
    db: Promise<idb.IDBPDatabase<unknown>>;
    data: ArticleForm | null;
    meta: {
        initialized: boolean;
        wasChangedInit: boolean;
        wasChangedProgress: boolean;
        wasInit: boolean;
        wasProgress: boolean;
        showDiff: boolean;
    };
}

const dbPromise = idb.openDB('articlesDatabase', 1, {
    upgrade(db) {
        if (
            !db.objectStoreNames.contains('article_history')
        ) {
            db.createObjectStore('article_history', {
                keyPath: 'id',
            });
        }
        if (
            !db.objectStoreNames.contains(
                'init_article_history',
            )
        ) {
            db.createObjectStore('init_article_history', {
                keyPath: 'id',
            });
        }
    },
});

const checkIsReadonly = (room: RoomUser[], id: number) => {
    const separateUsers = room.reduce<{
        currentEditor: RoomUser | null;
        readonlyUsers: Array<RoomUser>;
    }>(
        (acc, user) => {
            let currentEditor = null;
            let readonlyUsers = null;

            if (!user.read_only) {
                currentEditor = user;
            }

            if (user.read_only) {
                readonlyUsers = user;
            }

            return {
                ...acc,
                ...(currentEditor ? { currentEditor } : {}),
                readonlyUsers: [
                    ...acc.readonlyUsers,
                    ...(readonlyUsers ? [user] : []),
                ],
            };
        },
        {
            currentEditor: null,
            readonlyUsers: [],
        },
    );
    return {
        isReadonly: separateUsers.currentEditor?.id !== id,
        ...separateUsers,
    };
};

const articleProgress = createModel<RootModel>()({
    name: 'articleProgress',
    state: {
        data: null,
        db: dbPromise,
        meta: {
            initialized: false,
            wasChangedInit: false,
            wasChangedProgress: false,
            wasInit: false,
            wasProgress: false,
            showDiff: false,
        },
    } as ArticleProgressModel,
    reducers: {
        updateMeta: (
            state,
            payload: Partial<ArticleProgressModel['meta']>,
        ) => {
            return {
                ...state,
                meta: {
                    ...state.meta,
                    ...payload,
                },
            };
        },
        updateData: (
            state,
            payload: ArticleForm | null,
        ) => {
            return { ...state, data: payload };
        },
        setWasInit: (state, payload: boolean) => {
            return {
                ...state,
                meta: {
                    ...state.meta,
                    wasInit: payload,
                },
            };
        },
        openDiff: (state) => {
            return {
                ...state,
                meta: {
                    ...state.meta,
                    showDiff: true,
                },
            };
        },
        closeDiff: (state) => {
            return {
                ...state,
                meta: {
                    ...state.meta,
                    showDiff: false,
                },
            };
        },
    },
    effects: (dispatch) => ({
        init: async (
            {
                id,
                data,
            }: {
                id: string | number;
                data: ArticleForm;
            },
            store,
        ) => {
            const currentUserID = store.user.me?.id || null;
            const rooms = store.articleRoom.rooms;
            let isReadonly: boolean = false;
            if (currentUserID) {
                const check = checkIsReadonly(
                    rooms,
                    currentUserID,
                );
                isReadonly = check.isReadonly;
            } else {
                return null;
            }

            const hasInit =
                await dispatch.articleProgress.checkHasInit(
                    id,
                );
            const hasProgress =
                await dispatch.articleProgress.checkHasProgress(
                    id,
                );
            let storedInit: ArticleForm | null = null;
            let storedProgress: ArticleForm | null = null;
            let initHasDiff: boolean = false;
            let progressHasDiff: boolean = false;
            let showDiff: boolean = false;

            if (hasInit) {
                storedInit =
                    await dispatch.articleProgress.getInit(
                        id,
                    );
                initHasDiff =
                    dispatch.articleProgress.hasDiff({
                        form: storedInit,
                        compare: data,
                    });
            } else {
                dispatch.articleProgress.saveInit(data);
            }
            if (hasProgress) {
                storedProgress =
                    await dispatch.articleProgress.getProgress(
                        id,
                    );
                progressHasDiff =
                    dispatch.articleProgress.hasDiff({
                        form: storedProgress,
                        compare: data,
                    });
            }
            if (hasProgress && storedProgress) {
                showDiff = true;
            }
            if (initHasDiff) {
                showDiff = false;
            }
            if (showDiff && storedProgress) {
                dispatch.articleProgress.updateData(
                    storedProgress,
                );
            }

            dispatch.articleProgress.updateMeta({
                initialized: true,
                wasInit: hasInit,
                wasProgress: hasProgress,
                wasChangedInit: initHasDiff,
                wasChangedProgress: progressHasDiff,
                showDiff: showDiff,
            });
        },
        checkHasInit: async (id: string | number) => {
            const init =
                await dispatch.articleProgress.getInit(id);
            if (init) {
                return true;
            }
            return false;
        },
        checkHasProgress: async (id: string | number) => {
            const progress =
                await dispatch.articleProgress.getProgress(
                    id,
                );
            if (progress) {
                return true;
            }
            return false;
        },
        hasDiff: ({
            form,
            compare,
        }: {
            form: Partial<ArticleForm> | null;
            compare: Partial<ArticleForm> | null;
        }) => {
            return !isEqual(
                omit(form, usslessKeys),
                omit(compare, usslessKeys),
            );
        },
        getInit: async (id: number | string, store) => {
            const db = await store.articleProgress.db;
            const wasInitData = await db
                .transaction('init_article_history')
                .store.get(id);

            if (
                wasInitData &&
                typeof wasInitData === 'string'
            ) {
                return JSON.parse(
                    wasInitData,
                ) as ArticleForm;
            }
            return null;
        },
        saveInit: async (payload: ArticleForm, store) => {
            //@ts-ignore
            // const size = estimateSize(payload);

            const db = await store.articleProgress.db;
            const tx = db.transaction(
                'init_article_history',
                'readwrite',
            );
            tx.store.put(payload);

            await tx.done;
        },
        getProgress: async (id: number | string, store) => {
            const db = await store.articleProgress.db;
            const wasInitData = await db
                .transaction('article_history')
                .store.get(id);

            if (
                wasInitData &&
                typeof wasInitData === 'string'
            ) {
                return JSON.parse(
                    wasInitData,
                ) as ArticleForm;
            }
            return null;
        },
        _saveProgress: async (
            payload: ArticleForm,
            store,
        ) => {
            const db = await store.articleProgress.db;
            const tx = db.transaction(
                'article_history',
                'readwrite',
            );

            tx.store.put(payload);

            await tx.done;
        },
        deleteInit: async (id: number | string) => {
            const db = await dbPromise;
            const tx = db.transaction(
                'init_article_history',
                'readwrite',
            );
            tx.store.delete(`${id}`);
            await tx.done;
        },
        deleteProgress: async (id: number | string) => {
            const db = await dbPromise;
            const tx = db.transaction(
                'article_history',
                'readwrite',
            );
            tx.store.delete(`${id}`);
            await tx.done;
        },
        toggleOpenDiff: async (
            id: number | string,
            state,
        ) => {
            const isOpen =
                state.articleProgress.meta.showDiff;

            if (isOpen) {
                dispatch.articleProgress.closeDiff();
                dispatch.articleProgress.updateData(null);
            } else {
                const data =
                    await dispatch.articleProgress.getProgress(
                        id,
                    );
                dispatch.articleProgress.updateData(data);
                dispatch.articleProgress.openDiff();
            }
        },
        clear: (id: string | number) => {
            dispatch.articleProgress.updateData(null);
            dispatch.articleProgress.updateMeta({
                initialized: false,
                wasChangedInit: false,
                wasChangedProgress: false,
                wasInit: false,
                wasProgress: false,
                showDiff: false,
            });
            dispatch.articleProgress.deleteInit(id);
            dispatch.articleProgress.deleteProgress(id);
        },
        saveProgress: async (
            payload: ArticleForm,
            store,
        ) => {
            if (!store.user.me?.id) {
                return null;
            }
            const currentUserID = store.user.me.id;

            const isReadonly = checkIsReadonly(
                store.articleRoom.rooms,
                currentUserID,
            ).isReadonly;

            if (isReadonly) {
                return null;
            }

            const storedProgress =
                await dispatch.articleProgress.getProgress(
                    payload?.id || '',
                );
            const hasDiff =
                dispatch.articleProgress.hasDiff({
                    form: storedProgress,
                    compare: payload,
                });

            if (!hasDiff) {
                return null;
            }

            const { wasChangedInit, showDiff } =
                store.articleProgress.meta;

            if (wasChangedInit) {
                if (showDiff) {
                    await dispatch.articleProgress.saveInit(
                        payload,
                    );
                    await dispatch.articleProgress._saveProgress(
                        payload,
                    );
                    return;
                }
                return null;
            }

            await dispatch.articleProgress.saveInit(
                payload,
            );
            await dispatch.articleProgress._saveProgress(
                payload,
            );
            return;
        },
    }),
});

export { articleProgress };
export default articleProgress;
