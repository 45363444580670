import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgYandexRssOn = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 13 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M3.203.205 6.172 6.69 9.14.205h3.193L7.656 9.248v5.176H4.688V9.248L0 .205h3.203Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgYandexRssOn);
export default ForwardRef;
