import { createStore } from 'zustand';
import { v4 as uuid } from 'uuid';
import { files as apiFiles } from '@mega/api';
import { FieldHelperProps, FieldInputProps } from 'formik';
import { UploadFileMeta } from '../UploadFile.types';
import { debounce } from 'lodash-es';
export type ClientFile = {
    file: File;
    title: string;
    loading: boolean;
};

export type ServerFile = {
    id?: number;
    title?: string;
    file?: string;
};

export interface UploaderProps {
    files: { [key: string]: ServerFile };
    externalState?: {
        formik?: {
            field?: FieldInputProps<number[]>;
        };
    };
    dependencies?: {
        api?: {
            deleteFile: (
                id: number,
            ) => Promise<{ ok: true } | { ok: false }>;
            uploadFile: (file: ClientFile) => Promise<
                | {
                      data: ServerFile;
                      ok: true;
                  }
                | {
                      ok: false;
                  }
            >;
            updateFile: (
                key: string,
            ) => Promise<
                | { data: ServerFile; ok: true }
                | { ok: false }
            >;
        };
        formik?: {
            helpers?: FieldHelperProps<number[]>;
        };
        apostroph?: {
            updateCurrentElement?: (
                payload: UploadFileMeta,
            ) => void;
        };
    };
}

export interface UploaderState extends UploaderProps {
    isRunningQueue: boolean;
    filesQueue: { [key: string]: ClientFile };
    actions: {
        startRunning: () => void;
        endRunning: () => void;
        addRawFilesToFilesQueue: (newFiles: {
            [key: string]: ClientFile;
        }) => void;
        setLoading: (id: string, loading: boolean) => void;
        removeFromQueue: (id: string) => void;
        addUploadedFile: (
            id: string,
            file: ServerFile,
        ) => void;
        updateTitleFile: (
            id: string,
            title: string,
        ) => void;
        removeFromFile: (key: string) => void;
    };
    effects: {
        deleteFile: (
            key: string,
            id?: number,
        ) => () => Promise<
            | {
                  ok: true;
                  files: UploaderState['files'];
              }
            | { ok: false }
        >;
        uploadFile: (
            id: string,
            file: ClientFile,
        ) => Promise<
            | {
                  data: ServerFile;
                  ok: true;
              }
            | {
                  ok: false;
              }
        >;

        onDrop: (acceptedFiles: File[]) => void;
        runQueue: () => Promise<void>;
        syncEffect: () => void;
        updateTitleFile: (
            key: string,
            title: string,
        ) => void;
    };
}

export type UploaderStore = ReturnType<
    typeof createUploaderStore
>;

export const createUploaderStore = (
    initialProps?: Partial<UploaderProps>,
) => {
    return createStore<UploaderState>((set, get) => ({
        ...initialProps,
        dependencies: {
            ...initialProps?.dependencies,
            api: {
                deleteFile: async (id) => {
                    try {
                        const responce =
                            await apiFiles.deleteById({
                                id: `${id}`,
                            });
                        if (responce.ok) {
                            return { ok: true };
                        } else {
                            return { ok: false };
                        }
                    } catch (e) {
                        return { ok: false };
                    }
                },
                uploadFile: async (file) => {
                    try {
                        const res = await apiFiles.create({
                            title: file.file.name,
                            file: file.file,
                        });
                        if (res.ok) {
                            return {
                                ok: res.ok,
                                data: res.data,
                            };
                        }
                        return {
                            ok: false,
                        };
                    } catch (e) {
                        return { ok: false };
                    }
                },
                //@ts-ignore
                updateFile: debounce(
                    async (key: string) => {
                        const { files } = get();
                        const file = files[key];
                        if (!file.id) {
                            return { ok: false };
                        }
                        try {
                            const res =
                                await apiFiles.updateById({
                                    id: `${file.id}`,
                                    title: file.title || '',
                                });

                            if (res.ok) {
                                const data =
                                    res.data as ServerFile;
                                return { ok: true, data };
                            } else {
                                return { ok: false };
                            }
                        } catch (e) {
                            return { ok: false };
                        }
                    },
                    1000,
                ),
            },
        },
        files: { ...(initialProps?.files ?? {}) },
        filesQueue: {},
        isRunningQueue: false,
        actions: {
            startRunning: () =>
                set({ isRunningQueue: true }),
            endRunning: () =>
                set({ isRunningQueue: false }),
            addRawFilesToFilesQueue: (newFiles) =>
                set((state) => ({
                    filesQueue: {
                        ...state.filesQueue,
                        ...newFiles,
                    },
                })),

            setLoading: (key, loading) =>
                set((state) => ({
                    filesQueue: {
                        ...state.filesQueue,
                        [key]: {
                            ...state.filesQueue[key],
                            loading,
                        },
                    },
                })),

            removeFromQueue: (key) =>
                set((state) => {
                    const newQueue = {
                        ...state.filesQueue,
                    };
                    delete newQueue[key];
                    return { filesQueue: newQueue };
                }),

            addUploadedFile: (key, file) =>
                set((state) => ({
                    files: { ...state.files, [key]: file },
                })),

            updateTitleFile: (key, title) =>
                set((state) => ({
                    files: {
                        ...state.files,
                        [key]: {
                            ...state.files[key],
                            title,
                        },
                    },
                })),

            removeFromFile: (key) =>
                set((state) => {
                    const newFiles = { ...state.files };
                    delete newFiles[key];
                    return { files: newFiles };
                }),
        },

        effects: {
            //key is id in block.meta; id is server id
            deleteFile: (key, id) => async () => {
                const {
                    actions,
                    dependencies,
                    externalState,
                } = get();
                if (!id) {
                    return { ok: false };
                }
                const res =
                    await dependencies?.api?.deleteFile?.(
                        id,
                    );
                if (!res) {
                    return { ok: false };
                }
                if (res.ok) {
                    actions.removeFromFile(key);
                    // add to action
                    const filtredField =
                        externalState?.formik?.field?.value.filter(
                            (fieldId) => {
                                return fieldId !== id;
                            },
                        ) || [];
                    dependencies?.formik?.helpers?.setValue?.(
                        filtredField,
                    );
                    //
                    dependencies?.apostroph?.updateCurrentElement?.(
                        {
                            files: get().files,
                        },
                    );
                    return {
                        ok: true,
                        files: get().files,
                    };
                }
                return { ok: false };
            },

            onDrop: (acceptedFiles) => {
                const { actions, effects } = get();
                const formatFiles = acceptedFiles.reduce<{
                    [key: string]: ClientFile;
                }>((acc, file) => {
                    const key = uuid();
                    return {
                        ...acc,
                        [key]: {
                            file: file,
                            title: '',
                            loading: false,
                        },
                    };
                }, {});
                actions.addRawFilesToFilesQueue(
                    formatFiles,
                );
                effects.runQueue();
            },

            uploadFile: async (key, file) => {
                const { dependencies, actions } = get();
                actions.setLoading(key, true);

                const res =
                    await dependencies?.api?.uploadFile(
                        file,
                    );

                if (res && res.ok) {
                    actions.removeFromQueue(key);
                    actions.addUploadedFile(key, {
                        id: res.data.id,
                        title: res.data.title,
                        file: res.data.file,
                    });

                    return res;
                } else {
                    actions.removeFromQueue(key);
                    return { ok: false };
                }
            },
            runQueue: async () => {
                const {
                    filesQueue,
                    isRunningQueue,
                    effects,
                    actions,
                } = get();
                const filesQueueEntries =
                    Object.entries(filesQueue);
                const filesQueueValues =
                    Object.values(filesQueue);

                if (filesQueueValues.length === 0) {
                    return;
                }
                if (isRunningQueue) {
                    return;
                }
                actions.startRunning();
                try {
                    await Promise.all(
                        filesQueueEntries.map(
                            async ([id, file]) => {
                                return effects.uploadFile(
                                    id,
                                    file,
                                );
                            },
                        ),
                    );
                    return;
                } catch (error) {
                    console.error(
                        'Error uploading files:',
                        error,
                    );
                    return;
                } finally {
                    actions.endRunning();
                    effects.syncEffect();
                    return;
                }
            },
            //send data to another stores
            syncEffect: async () => {
                const {
                    dependencies,
                    files,
                    externalState,
                } = get();
                const filesIds = Object.values(files).map(
                    (item) => item.id,
                ) as Array<number>;
                if (
                    externalState?.formik?.field?.value &&
                    dependencies?.formik?.helpers
                        ?.setValue &&
                    typeof dependencies.formik.helpers
                        .setValue === 'function'
                ) {
                    //send data tot formik
                    const setValue =
                        dependencies?.formik?.helpers
                            .setValue;
                    const oldFildValue =
                        externalState.formik.field.value;
                    const newValues = new Set([
                        ...oldFildValue,
                        ...filesIds,
                    ]);
                    setValue([...newValues]);
                }
                if (
                    dependencies?.apostroph
                        ?.updateCurrentElement &&
                    typeof dependencies.apostroph
                        .updateCurrentElement === 'function'
                ) {
                    dependencies.apostroph.updateCurrentElement(
                        {
                            files,
                        },
                    );
                }
            },
            updateTitleFile: (key, title) => {
                const { actions, dependencies, effects } =
                    get();
                actions.updateTitleFile(key, title);
                dependencies?.api?.updateFile(key);
                effects.syncEffect();
            },
        },
    }));
};
