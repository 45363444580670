import { useRef } from 'react';
import { ReportServerResponse } from '../Reports.types';

interface UsePollingProps {
    callApiFn?: () => Promise<Response | undefined>;
    testFn: (response: any) => boolean;
    feedBackFn: (fb: any) => void;
    interval: number;
    maxAttempts?: number | null;
}

export const usePolling = ({
    callApiFn,
    testFn,
    interval,
    feedBackFn,
    maxAttempts = null,
}: UsePollingProps) => {
    const pollingRef = useRef<boolean>(false);
    let rejectThis: ((reason?: any) => void) | null = null;
    let attempts = 0;

    const stopPolling = () => {
        if (pollingRef.current === true) {
            pollingRef.current = false;

            if (rejectThis) {
                rejectThis(new Error('Polling cancelled'));
            }
        }
    };

    const promise = new Promise<ReportServerResponse>(
        (resolve, reject) => {
            pollingRef.current = true;
            rejectThis = reject;

            const executePoll = async () => {
                try {
                    if (
                        !callApiFn ||
                        pollingRef.current === false
                    )
                        return;

                    const response = await callApiFn();
                    if (!response) {
                        throw new Error(
                            'API response is undefined',
                        );
                    }
                    const data = await response.json();

                    if (
                        testFn(data) &&
                        pollingRef.current === true
                    ) {
                        pollingRef.current = false;
                        resolve(data);
                    } else {
                        attempts++;
                        if (
                            maxAttempts &&
                            attempts >= maxAttempts
                        ) {
                            stopPolling();
                            reject(
                                new Error(
                                    'Maximum polling attempts reached',
                                ),
                            );
                        } else {
                            feedBackFn(data);
                            setTimeout(
                                executePoll,
                                interval,
                            );
                        }
                    }
                } catch (error) {
                    pollingRef.current = false;
                    reject(
                        new Error(
                            'Polling cancelled due to API error',
                        ),
                    );
                }
            };
            setTimeout(executePoll, interval);
        },
    ).catch((e) => {
        console.log(e, 'error in last catch in PROMISE');
        stopPolling();
    });

    return { promise, stopPolling };
};
