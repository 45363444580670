import { ReactElement } from 'react';
import { PluginTemplate } from '@pages/smartGrid/types/blocks';

export enum CarouselEnum {
    type = 'CAROUSEL',
}

export interface CarouselProps {
    id: string;
    type: string;
    parentId: string | null;
    meta?: Record<string, unknown>;
    children: ReactElement<any> | ReactElement<any>[];
}

export type CarouselMeta = {};

export type CarouselPlugin = PluginTemplate<
    CarouselEnum.type,
    CarouselMeta
>;
export type CarouselPluginDefault = Omit<
    CarouselPlugin,
    'id'
>;
