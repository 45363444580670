import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import {
    $getRoot,
    COMMAND_PRIORITY_EDITOR,
    createCommand,
    LexicalCommand,
    LexicalNode,
    TextNode,
} from 'lexical';
import Typograf from 'typograf';

const PASTE_COMMAND: LexicalCommand<string> =
    createCommand();

const tp = new Typograf({ locale: ['ru', 'en-US'] });
tp.disableRule('common/symbols/cf');

export const getPasteText = (
    textNodes: Array<TextNode> | Array<LexicalNode>,
) => {
    return textNodes?.map((node) => {
        if (node?.__text) {
            const typographedText = tp.execute(
                node.getTextContent(),
            );
            //@ts-ignore
            if (!node?.__text === ' ') {
                return node.setTextContent(` `);
            }

            if (!node?.__text.trim().length) {
                return node.setTextContent(` `);
            }

            if (typographedText.includes('\n')) {
                node.setTextContent(
                    `${typographedText.replaceAll(
                        '\n',
                        ' ',
                    )}`,
                );
            }

            return node;
        }
    });
};

export function PastePlugin() {
    const [editor] = useLexicalComposerContext();

    const handlePaste = () => {
        editor.dispatchCommand(PASTE_COMMAND, '');
    };
    useEffect(() => {
        editor.registerCommand(
            PASTE_COMMAND,
            () => {
                const textNodes =
                    $getRoot().getAllTextNodes();
                getPasteText(textNodes);
                return false;
            },
            COMMAND_PRIORITY_EDITOR,
        );

        const editableNodes = document.querySelectorAll(
            '.editableRichText',
        );
        editableNodes.forEach((node) => {
            node.addEventListener('paste', handlePaste);
        });

        return () => {
            editableNodes.forEach((node) => {
                node.removeEventListener(
                    'paste',
                    handlePaste,
                );
            });
        };
    }, []);

    return null;
}
