import React, { FC, ReactElement } from 'react';

import {
    entityListClasses,
    EntityListClasses,
} from '@mega/styles';

export interface EntityListProps {
    setting?: ReactElement | ReactElement[];
    children?: ReactElement | ReactElement[];
}

const EntityListLayout: FC<
    EntityListProps & EntityListClasses['recipe']
> = ({ setting, children, gap }) => {
    return (
        <div
            className={[
                entityListClasses.recipe({ gap }),
            ].join(' ')}
        >
            {setting && (
                <div className={entityListClasses.setting}>
                    {setting}
                </div>
            )}
            {children && (
                <div className={entityListClasses.content}>
                    {children}
                </div>
            )}
        </div>
    );
};

export { EntityListLayout };
