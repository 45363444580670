import React, { FC, ReactElement } from 'react';
import {
    centerLayoutClasses,
    CenterLayoutClasses,
} from '@mega/styles';

export interface CenterLayoutProps {
    children: ReactElement | ReactElement[];
}

const CenterLayout: FC<
    CenterLayoutProps & CenterLayoutClasses['recipe']
> = ({ children }) => (
    <div
        className={[centerLayoutClasses.recipe()].join(' ')}
    >
        {children}
    </div>
);

export { CenterLayout };
