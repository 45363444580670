import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../../models';

const useAdsBlockStore = () => {
    const store = useStoreSelector(
        (state: RootState) => state.adsBlock,
    );
    const loading = useStoreSelector(
        (state: RootState) =>
            state.loading.effects.adsBlock,
    );

    return {
        store,
        loading,
    };
};

const useAdsBlockDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        get: dispatch.adsBlock.get,
        put: dispatch.adsBlock.put,
        create: dispatch.adsBlock.create,
        update: dispatch.adsBlock.update,
        remove: dispatch.adsBlock.remove,
    };
};

export { useAdsBlockStore, useAdsBlockDispatch };
