import React, { FC } from 'react';
import { Paper, SelectSingle, WithLabel } from '@mega/ui';
import { useFormikContext } from 'formik';

import { PartialUpdateADSBlockQuery } from '@mega/api';

export interface BlockEditTestProps {}

//key - value; value - label
const mapOptions = {
    in_article_1: 'in_article_1',
    in_article_2: 'in_article_2',
    top_banner: 'top_banner',
    exchange: 'exchange',
    full_screen: 'full_screen',
    full_screen_modal: 'full_screen_modal',
    under_article_1: 'under_article_1',
    under_article_2: 'under_article_2',
    article_list: 'article_list',
    sidebar_1: 'sidebar_1',
    sidebar_2: 'sidebar_2',
    sidebar_3: 'sidebar_3',
    sidebar_4: 'sidebar_4',
    infinitive_1: 'infinitive_1',
    main_grid: 'main_grid',
    footer: 'footer',
    amp_in_article_1: 'amp_in_article_1',
    amp_in_article_2: 'amp_in_article_2',
    amp_in_article_3: 'amp_in_article_3',
    amp_in_article_4: 'amp_in_article_4',
    amp_under_article: 'amp_under_article',
    turbo_after_share: 'turbo_after_share',
    turbo_1: 'turbo_1',
    turbo_2: 'turbo_2',
    turbo_3: 'turbo_3',
    turbo_4: 'turbo_4',
    turbo_bookmark: 'turbo_bookmark',
    dzen_after_text: 'dzen_after_text',
};

const BlockEditSettings: FC<{}> = () => {
    const { values, setFieldValue } =
        useFormikContext<PartialUpdateADSBlockQuery>();

    const defaultPlatform = {
        value: values.platform || '',
        label: values.platform || '',
    };

    const transformOptions = new Map<string, string>(
        Object.entries(mapOptions),
    );

    const arrayOptions = Array.from(
        transformOptions,
    ).reduce(
        (
            acc: Array<{ value: string; label: string }>,
            item,
        ) => {
            return [
                ...acc,
                { value: item[0], label: item[1] },
            ];
        },
        [],
    );

    return (
        <>
            <Paper
                contentHeight
                color={'white'}
                variant="outline"
                classes={{
                    padding: {
                        size: '24x24',
                    },
                }}
            >
                <WithLabel title="Платформа">
                    <SelectSingle
                        actionOnSelectedOption={(e) => {
                            setFieldValue(
                                'platform',
                                e?.value,
                            );
                        }}
                        defaultValue={defaultPlatform}
                        handleLoader={async () => {
                            return [
                                {
                                    value: 'desktop',
                                    label: 'desktop',
                                },
                                {
                                    value: 'mobile',
                                    label: 'mobile',
                                },
                            ];
                        }}
                    />
                </WithLabel>
            </Paper>
            <Paper
                contentHeight
                color={'white'}
                variant="outline"
                classes={{
                    padding: {
                        size: '24x24',
                    },
                }}
            >
                <WithLabel title="Ячейка">
                    <SelectSingle
                        actionOnSelectedOption={(e) => {
                            setFieldValue('slot', e?.value);
                        }}
                        defaultValue={{
                            value: values.slot,
                            label:
                                transformOptions.get(
                                    values.slot,
                                ) ?? '',
                        }}
                        handleLoader={async () => {
                            return arrayOptions;
                        }}
                    />
                </WithLabel>
            </Paper>
        </>
    );
};

export { BlockEditSettings };
