import { useEffect, useState } from 'react';

interface useInfiniteLoaderProps<T> {
    inputValue: string;
    getItemsLoader: (
        inputValue?: string,
        page?: number,
    ) => Promise<T[]>;
}

export function useInfiniteLoader<T>({
    getItemsLoader,
    inputValue = '',
}: useInfiniteLoaderProps<T>) {
    const [page, setPage] = useState(1);
    const [items, setItems] = useState<T[]>([]);

    useEffect(() => {
        (async () => {
            const data = await getItemsLoader('', page);
            setItems(data);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            let ignore = false;
            setPage(1);

            const data = await getItemsLoader(
                inputValue,
                page,
            );

            if (!ignore) {
                setItems(data);
            }

            return () => {
                ignore = true;
            };
        })();
    }, [inputValue]);

    const loadMore = async () => {
        const data = await getItemsLoader(
            inputValue,
            page + 1,
        );

        setItems((prevItems) => [...prevItems, ...data]);
        setPage((page) => page + 1);
    };

    return { page, loadMore, items };
}
