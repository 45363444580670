import React, { FC } from 'react';
import { Form, Formik, useFormikContext } from 'formik';

import * as Yup from 'yup';

import { BannedOrganizationCreate as BannedOrganizationCreateType } from '@mega/api';

import {
    Button,
    FormLayout,
    InputField,
    Paper,
    SelectSingleField,
    Stack,
    WithLabel,
} from '@mega/ui';
import { BannedOrganizationCreateActionForm } from './BannedOrganization.type';
import { getOptionsBO } from '@mega/core';

export interface BannedOrganizationCreateProps {
    initialValue?: BannedOrganizationCreateType;
    handleCreate?: (
        value: BannedOrganizationCreateType,
    ) => any;
}

const BannedOrganizationCreate: FC<
    BannedOrganizationCreateProps
> = ({
    initialValue = { title: '', org_type: null },
    handleCreate = () => null,
}) => (
    <Formik
        validationSchema={Yup.object({
            title: Yup.string()
                .min(
                    1,
                    'Это поле явно должно быть больше одного символа',
                )
                .required('Это поле обязательное'),
            org_type: Yup.object({
                label: Yup.string(),
                value: Yup.string(),
            })
                .nullable()
                .required('Это поле обязательное'),
        })}
        initialValues={initialValue}
        // onSubmit={handleCreate}
        onSubmit={(item) =>
            handleCreate({
                title: item.title,
                //@ts-ignore
                org_type: item.org_type.id,
            })
        }
    >
        <Form>
            <FormLayout
                actions={
                    <BannedOrganizationCreateActions />
                }
            >
                <Paper
                    variant="outline"
                    color="white"
                    classes={{
                        padding: { size: '24x24' },
                    }}
                >
                    <Stack gap="18">
                        <WithLabel isRequired title={'Тип'}>
                            <SelectSingleField
                                name={'org_type'}
                                placeholder="Выберите тип"
                                handleLoader={async () => [
                                    {
                                        value: 'terrorist',
                                        label: 'Террористы',
                                    },
                                    {
                                        value: 'foreign_agent',
                                        label: 'Иноагенты',
                                    },
                                    {
                                        value: 'extremist',
                                        label: 'Экстремисты',
                                    },
                                ]}
                                isCreatable={false}
                                isClearable={false}
                            />
                        </WithLabel>
                        <WithLabel
                            isRequired
                            title={'Заголовок'}
                        >
                            <InputField
                                type="text"
                                name={'title'}
                                placeholder="Введите заголовок"
                                dimension="medium"
                            />
                        </WithLabel>
                    </Stack>
                </Paper>
            </FormLayout>
        </Form>
    </Formik>
);

const BannedOrganizationCreateActions: BannedOrganizationCreateActionForm =
    () => {
        const { submitForm, isSubmitting } =
            useFormikContext();

        return (
            <Paper
                style={{
                    borderTop: '1px solid #EAEAEA',
                }}
                fullWidth
                color={'white'}
                variant="filled"
                classes={{
                    padding: { size: '24x24' },
                }}
            >
                <Button
                    classes={{
                        paper: {
                            variant: 'filled',
                            color: 'dark',
                        },
                    }}
                    color="secondary"
                    size={'large'}
                    type={'submit'}
                    label={'Создать'}
                    onClick={submitForm}
                    disabled={isSubmitting}
                />
            </Paper>
        );
    };

export { BannedOrganizationCreate };
