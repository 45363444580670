import React, { FC, ReactElement, MouseEvent } from 'react';
import { listItemClasses as classes } from './ListItem.css';

export interface ListItemProps {
    label?: string | ReactElement | ReactElement[];
    startIcon?: ReactElement;
    endIcon?: ReactElement;
    className?: string;
    children?: ReactElement | ReactElement[];
    onClick?: (e: MouseEvent) => void;
    id?: string;
    isButton?: boolean;
}

const ListItem: FC<ListItemProps> = ({
    label,
    startIcon,
    endIcon,
    children,
    className = '',
    onClick,
    id,
    isButton = false,
}) => {
    const hasStartIcon = Boolean(startIcon);
    const hasEndIcon = Boolean(endIcon);

    if (isButton) {
        return (
            <li
                className={[classes.base].join(' ')}
                onClick={(e) => {
                    e.stopPropagation();
                    onClick && onClick(e);
                }}
                id={id}
                style={{}}
            >
                <button
                    type={'button'}
                    className={[
                        classes.button,
                        classes.wrapper,
                        className,
                    ].join(' ')}
                >
                    {hasStartIcon && startIcon}
                    {label}
                    {children ? (
                        <div className={classes.child}>
                            {children}
                        </div>
                    ) : null}
                    {hasEndIcon && endIcon}
                </button>
            </li>
        );
    }

    return (
        <li
            className={[classes.base, className].join(' ')}
            onClick={(e) => {
                e.stopPropagation();
                onClick && onClick(e);
            }}
            id={id}
        >
            {hasStartIcon && startIcon}
            {label}
            {children ? (
                <div className={classes.child}>
                    {children}
                </div>
            ) : null}
            {hasEndIcon && endIcon}
        </li>
    );
};

export { ListItem };
