import { ReactElement } from 'react';
import { PluginTemplate } from '@pages/smartGrid/types/blocks';

export enum MainTemplateEnum {
    type = 'MAIN_TEMPLATE',
}

export interface MainTemplateProps {
    id: string;
    type: string;
    meta: any;
    parentId: string | null;
    children: ReactElement | ReactElement[];
}

export type MainTemplateMeta = {};

export type MainTemplatePlugin = PluginTemplate<
    MainTemplateEnum.type,
    MainTemplateMeta
>;
export type MainTemplatePluginDefault = Omit<
    MainTemplatePlugin,
    'id'
>;
