import { createModel } from '@rematch/core';
import type { RootModel } from '../rootModel';

type UserState = {
    site: {
        id: string;
        label: string;
    } | null;
};

const site = createModel<RootModel>()({
    name: 'site',
    state: {
        site: null,
    } as UserState,
    reducers: {
        unpinSite(state) {
            return { ...state, site: null };
        },
        pinSite(
            state,
            payload: {
                id: string;
                label: string;
            } | null,
        ) {
            return { ...state, site: payload };
        },
    },
});

export { site };
export default site;
