import type { PluginTemplate } from '@apostroph/types';

export enum BlockquoteEnum {
    type = 'BLOCKQUOTE',
}

export interface BlockquoteProps {
    id: string;
    type: string;
    parentId: string | null;
}

export type BlockquoteMeta = {
    regalia: string;
    owner: string;
    text: string;
};

export type BlockquotePlugin = PluginTemplate<
    BlockquoteEnum.type,
    BlockquoteMeta
>;
export type BlockquotePluginDefault = Omit<
    BlockquotePlugin,
    'id'
>;
