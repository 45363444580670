import React, {
    cloneElement,
    CSSProperties,
    ReactElement,
} from 'react';
import {
    withLabelClasses as classes,
    WithLabelClasses,
} from './WithLabel.css';
import { Typography } from '../../../utility';

import { Row } from '@mega/ui';

interface WithLabelProps {
    id?: string;
    title: string | ReactElement;
    children: ReactElement;
    style?: CSSProperties;
    isRequired?: boolean;
    className?: string;
    color?: string;
}

function WithLabel({
    id = '',
    title,
    children: child,
    labelSide = 'top',
    gap = 'medium',
    style,
    color,
    isRequired = false,
    className,
}: WithLabelProps & WithLabelClasses['recipe']) {
    const childclassName = child?.props?.className as
        | string
        | null
        | undefined;
    const newChild = cloneElement(child, {
        ...child.props,
        className: `${childclassName ?? ''} ${
            classes.child
        }`,
    });

    return (
        <div
            style={style}
            className={[
                className,
                classes.recipe({ labelSide, gap }),
            ].join(' ')}
        >
            <label
                className={classes.label}
                {...(color
                    ? { style: { color: color } }
                    : {})}
                {...(id ? { htmlFor: id } : {})}
            >
                <Row gap="4">
                    <Typography size="12" weight="medium">
                        {title}
                    </Typography>

                    {isRequired && (
                        <div className={classes.isRequired}>
                            *
                        </div>
                    )}
                </Row>
            </label>
            {newChild}
        </div>
    );
}

export { WithLabel };
export default WithLabel;
