import { useAuthStore, useSiteStore } from '@mega/store';
import { useFormikContext } from 'formik';
import { stringify } from 'query-string';
import { ReportFormValues } from '../Reports.types';
import { reportTypeList } from '../constants';
import { useMemo } from 'react';

export const useReportOld = () => {
    const store = useAuthStore();
    const site = useSiteStore();

    const {
        values: {
            pub_date__date__gte,
            pub_date__date__lte,
            authors,
            article_types,
            report_type,
            sources,
        },
    } = useFormikContext<ReportFormValues>();

    // SelectSingleField handleChange имеет какой то костыльный трансформ даты непонятно зачем, уже несколько раз об него спотыкались но менять придется и клиенты и дашборд, поэтому пока так
    const curType =
        useMemo(() => {
            return reportTypeList.find(
                (item) => item.value === report_type?.id,
            );
        }, [report_type?.id]) || reportTypeList[0];

    let queryObject = {
        pub_date__date__gte,
        pub_date__date__lte,
        ...(authors?.id ? { authors: authors?.id } : {}),
        ...(article_types?.id
            ? { article_type: article_types?.id }
            : {}),
        quote_partner__in: [] as string[],
    };

    if (sources?.length) {
        queryObject.quote_partner__in = sources.map(
            (item) => item.id,
        );
    }

    const query = stringify(queryObject);

    const getReportFile = async () => {
        try {
            const newres = await fetch(
                `${
                    //@ts-ignore
                    process.env.REACT_APP_API_URL
                }/admin/reports/${
                    curType?.path || curType.value
                }.xlsx/?${query}`,
                {
                    headers: {
                        'Content-Type': 'application/xlsx',
                        authorization: `Bearer ${store?.accessToken}`,
                        'x-site': site?.id || '',
                    },
                },
            );

            const blob = await newres.blob();

            const newBlob = new Blob([blob]);

            const blobUrl =
                window.URL.createObjectURL(newBlob);

            const link = document.createElement('a');
            link.href = blobUrl;

            link.setAttribute(
                'download',
                `${
                    curType.value
                }_report_${pub_date__date__gte}_${pub_date__date__lte}_${new Date().getDate()}${
                    authors?.label
                        ? `_${authors?.label}`
                        : ''
                }${
                    article_types?.label
                        ? `_${article_types?.label}`
                        : ''
                }.${'xlsx'}`,
            );
            document.body.appendChild(link);
            link.click();
            link?.parentNode?.removeChild(link);

            window.URL.revokeObjectURL(blobUrl);
        } catch (e) {
            console.log(e);
        }
    };

    return { getReportFile };
};
