import React, { useState } from 'react';
import { fileViewerClasses } from './FileViewer.css';
import { motion } from 'framer-motion';
import { FiPlus } from 'react-icons/fi';
import { VideoModal } from '../VideoModal';
import { definitions } from '@mega/api';
import { View } from './View';
import { assignInlineVars } from '@vanilla-extract/dynamic';

interface FileViewerProps {
    isModalView?: boolean;
    isVideo?: boolean;
    fileSrc?: string;
    videoId?: number;
    description?: string;
    videoPoster?: string;
    height?: string;
    handleVideoSave?: (
        cover: definitions['AdminHostVideoList'],
        id?: number,
    ) => void;
}

export const FileViewer: React.FC<FileViewerProps> = (
    props,
) => {
    const {
        fileSrc,
        videoId,
        isModalView,
        handleVideoSave,
        height = '168px',
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    const handleClick = (
        e: React.MouseEvent<HTMLDivElement>,
    ) => {
        if (isModalView) {
            e.stopPropagation();
            return setIsOpen(true);
        }
        return null;
    };

    return (
        <>
            <div
                onClick={handleClick}
                className={fileViewerClasses.base}
                style={assignInlineVars({
                    [fileViewerClasses.vars.height]: height,
                })}
            >
                {fileSrc ? (
                    <motion.div
                        className={
                            fileViewerClasses.viewWrapper
                        }
                        whileHover={{
                            filter: 'brightness(0.9)',
                        }}
                    >
                        <View {...props} />
                    </motion.div>
                ) : (
                    <motion.div
                        className={
                            fileViewerClasses.plusIcon
                        }
                    >
                        <FiPlus />
                    </motion.div>
                )}
            </div>
            {isModalView && (
                <VideoModal
                    videoId={videoId}
                    handleVideoSave={(cover) =>
                        handleVideoSave &&
                        handleVideoSave(cover, videoId)
                    }
                    isOpen={isOpen || false}
                    setIsOpen={setIsOpen}
                />
            )}
        </>
    );
};
