import React from 'react';
import { wrapper } from './Embed.css';
import { VkEmbed, VideoEmbed } from './components';
import { tgEmbed } from '@mega/utils';

const DangerouslySetHtmlContent: React.FC<{
    html: string;
}> = ({ html }) => {
    const divRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const slotHtml = document
            .createRange()
            .createContextualFragment(html);

        if (divRef.current) {
            divRef.current.innerHTML = '';
            divRef.current.appendChild(slotHtml);
        }
    }, [html, divRef]);
    return <div ref={divRef} />;
};

export const Embed = ({ html }: { html: string }) => {
    if (html.includes('t.me')) {
        React.useEffect(() => {
            tgEmbed();
        }, []);

        return (
            <div
                id={'tgEmbed'}
                className="article-tg-sharing"
                data-telegram-source={html}
            />
        );
    }
    if (html.includes('telegram')) {
        return <DangerouslySetHtmlContent html={html} />;
    }
    if (html.includes('VK.Widgets.Post')) {
        return <VkEmbed html={html} />;
    }
    if (
        html.includes('youtube') ||
        html.includes('youtu.be')
    ) {
        return (
            <VideoEmbed className={wrapper} source={html} />
        );
    }
    return <DangerouslySetHtmlContent html={html} />;
};
