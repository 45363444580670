import React from 'react';
import { WithLabel } from '@mega/ui';
import { ArticleCover } from '../ArticleCover';
import { base } from './ArticleCoverBlock.css';
import { MediaPicker } from '../MediaPicker';

export const ArticleCoverBlock: React.FC = () => {
    return (
        <>
            <div className={base}>
                <WithLabel
                    id={'image_cover'}
                    title="Фотообложка"
                >
                    <ArticleCover id={'cover'} />
                </WithLabel>
                <WithLabel
                    id={'video_cover'}
                    title="Видеообложка"
                >
                    <ArticleCover
                        isVideo
                        id={'main_video'}
                    />
                </WithLabel>
            </div>
        </>
    );
};
