import { RootState } from '../../../models';
import { createSelector } from 'reselect';
import { RematchRootState } from '@rematch/core';

type ArticleState = RootState['article'];
type ArticleEffectProgressState =
    RootState['loading']['effects']['article'];

export const selectArticle = (state: RootState) =>
    state.article;

export const selectArticleFull = (article: ArticleState) =>
    article;

export const selectArticleData = (article: ArticleState) =>
    article.data;

export const selectArticleMeta = (article: ArticleState) =>
    article.meta;

export const fullProgress = (state: RootState) =>
    state.loading.effects.article;

export const pickCreateProgress = (
    progress: ArticleEffectProgressState,
) => progress.create;

export const pickGetProgress = (
    progress: ArticleEffectProgressState,
) => progress.get;

export const pickRemoveProgress = (
    progress: ArticleEffectProgressState,
) => progress.remove;

export const pickRemoveWithRoomCheckProgress = (
    progress: ArticleEffectProgressState,
) => progress.removeByIdWithRoomCheck;

export const pickUpdateProgress = (
    progress: ArticleEffectProgressState,
) => progress.update;

export const pickTxnGetProgress = (
    progress: ArticleEffectProgressState,
) => progress.txnGet;
