import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';

const useCopyrightStore = () => {
    const store = useStoreSelector(
        (state: RootState) => state.copyright,
    );
    const loading = useStoreSelector(
        (state: RootState) =>
            state.loading.effects.copyright,
    );

    return {
        store,
        loading,
    };
};

const useCopyrightDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        get: dispatch.copyright.get,
        put: dispatch.copyright.put,
        create: dispatch.copyright.create,
        update: dispatch.copyright.update,
        remove: dispatch.copyright.remove,
    };
};

export { useCopyrightStore, useCopyrightDispatch };
