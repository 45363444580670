import React, { FC, useEffect, useState } from 'react';
import {
    HeadTemplatePlugin,
    TemplatePlugin,
} from '@mega/editor';
import { useEditor } from '@apostroph/store';
import { ImgLoader, MediaLoaderWithMS } from '@mega/core';
import { ImgMeta, ImgProps } from './Img.types';
import { Icon, Loader, Typography } from '@mega/ui';
import { Image as ImageIcon } from '@mega/icons';
import { useFormikContext } from 'formik';
import { Article, definitions } from '@mega/api';
import { removeFromFileArray } from '@mega/utils/src/updateFileArrays';
import { useMediaDispatch } from '@mega/store';
import { useFetchPreviewImage } from '@mega/core';

const Img: FC<ImgProps> = ({ id, parentId, type }) => {
    const { handleUpdateElement, getCurrentDataElement } =
        useEditor();

    const { meta, childrenElement } =
        getCurrentDataElement<ImgMeta>(id);
    const { setIsLoading } = useMediaDispatch();

    const { setFieldValue, values } =
        useFormikContext<Article>();

    const articleFile = values.images?.find(
        (item) => item?.id === meta.id,
    );
    const [state, setState] = useState(articleFile || meta);

    const { preview } = useFetchPreviewImage(state);

    useEffect(() => {
        const newGlobalState = {
            id,
            type,
            childrenElement,
            meta: state,
        };
        handleUpdateElement(newGlobalState);

        setIsLoading({
            isLoading: false,
        });
    }, [state]);

    const handleDelete = (id: number) => {
        removeFromFileArray({
            ids: [id],
            field: 'images',
            values,
            setFieldValue,
        });
        setState({ image: '' });
    };

    const mainPreview = '16x9';

    const fileSrc = state.is_watermarked
        ? preview[`${mainPreview}`]
        : state.image;

    return (
        <TemplatePlugin
            head={
                <HeadTemplatePlugin
                    id={id}
                    onDelete={() =>
                        state.id && handleDelete(state.id)
                    }
                    parentId={parentId}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '4px',
                        }}
                    >
                        <Icon size="16">
                            <ImageIcon />
                        </Icon>
                        <Typography size={'12'}>
                            Картинка
                        </Typography>
                    </div>
                </HeadTemplatePlugin>
            }
        >
            <MediaLoaderWithMS type={'image'}>
                <ImgLoader
                    noDelete
                    fileSrc={fileSrc}
                    description={state.description}
                    is_watermarked={state.is_watermarked}
                    copyright={state.copyright}
                    id={state.id}
                    handleLoad={(
                        res: definitions['AdminImage'],
                    ) => {
                        setState(res);
                    }}
                    handleDelete={handleDelete}
                    sourceUrl={state?.source_url}
                    sourceText={state?.source_text}
                />
            </MediaLoaderWithMS>
        </TemplatePlugin>
    );
};

export { Img };
