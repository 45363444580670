import React, { FC, ReactElement, useState } from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import type { ArticleCreate as ArticleCreateType } from '@mega/api';
import * as Yup from 'yup';
import {
    Button,
    FormLayout,
    InputField as Input,
    Paper,
    Row,
    Stack,
    WithLabel,
} from '@mega/ui';

export interface ArticleCreateProps {
    initialValue?: ArticleCreateType;
    handleCreate?: (value: ArticleCreateType) => any;
    extendForm?: ReactElement | ReactElement[];
}

const ArticleCreateActions = () => {
    const { submitForm, isSubmitting } =
        useFormikContext<ArticleCreateType>();

    return (
        <Paper
            style={{
                borderTop: '1px solid #EAEAEA',
            }}
            fullWidth
            color={'white'}
            variant="filled"
            classes={{
                padding: { size: '24x24' },
            }}
        >
            <Row gap="16">
                <Button
                    classes={{
                        paper: {
                            variant: 'filled',
                            color: 'dark',
                        },
                    }}
                    color="secondary"
                    size={'large'}
                    type={'submit'}
                    label={'Создать'}
                    onClick={submitForm}
                    disabled={isSubmitting}
                />
            </Row>
        </Paper>
    );
};

const ArticleCreate: FC<ArticleCreateProps> = ({
    handleCreate = () => null,
    initialValue = { title: '' },
    extendForm,
}) => {
    const [isCreated, setIsCreated] = useState(false);

    const handleSubmit = async (
        values: ArticleCreateType,
    ) => {
        if (!isCreated) {
            const responce = await handleCreate(values);

            if (responce.isOk) {
                setIsCreated(true);
            }

            return;
        }
        return;
    };

    return (
        <Formik
            validationSchema={Yup.object({
                title: Yup.string()
                    .min(
                        2,
                        'Это поле явно должно быть больше одного символа',
                    )
                    .required('Это поле обязательное'),
            })}
            initialValues={initialValue}
            onSubmit={handleSubmit}
        >
            <Form>
                <FormLayout
                    actions={<ArticleCreateActions />}
                >
                    <Paper
                        variant="outline"
                        color="white"
                        classes={{
                            padding: { size: '24x24' },
                        }}
                    >
                        <Stack gap="18">
                            <Input
                                type="text"
                                name={'title'}
                                placeholder="Заголовок"
                                dimension="medium"
                                limit={120}
                            />
                            <WithLabel
                                id={'slug'}
                                title="URL страницы формируется автоматически"
                            >
                                <Input
                                    type="text"
                                    name={'slug'}
                                    placeholder="/"
                                    dimension="medium"
                                />
                            </WithLabel>
                        </Stack>
                    </Paper>
                </FormLayout>
            </Form>
        </Formik>
    );
};

export { ArticleCreate };
