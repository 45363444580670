// import type { ElementNode, LexicalEditor } from 'lexical';

import {
    TableCellHeaderStates,
    TableCellNode,
} from '@lexical/table';

import {
    TableStrokeUp,
    TableStrokeDown,
    TableCollumnRight,
    TableColumnLeft,
    TableDeleteColumn,
    TableDeleteStroke,
} from '@mega/icons';
import { ActionMenuAction } from './ActionMenuAction';
import classes from './Menu.css';

interface MenuProps {
    onClose: () => void;
    dropDownRef: React.RefObject<HTMLDivElement>;
    tableCellNode: TableCellNode;
    selectionCounts: { columns: number; rows: number };
    insertTableRowAtSelection: (
        shouldInsertAfter: boolean,
    ) => void;
    insertTableColumnAtSelection: (
        shouldInsertAfter: boolean,
    ) => void;
    deleteTableRowAtSelection: () => void;
    deleteTableAtSelection: () => void;
    deleteTableColumnAtSelection: () => void;
    toggleTableRowIsHeader: () => void;
    toggleTableColumnIsHeader: () => void;
}

function Menu(props: MenuProps) {
    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            className={classes.base}
            ref={props.dropDownRef}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <ActionMenuAction
                onClick={() =>
                    props.insertTableRowAtSelection(false)
                }
                onClose={props.onClose}
                Icon={<TableStrokeDown />}
                label={`Вставить
                ${
                    props.selectionCounts.rows === 1
                        ? 'строку'
                        : `${props.selectionCounts.rows} строки`
                }
                до`}
            />
            <ActionMenuAction
                onClick={() =>
                    props.insertTableRowAtSelection(true)
                }
                onClose={props.onClose}
                Icon={<TableStrokeUp />}
                label={`Вставить 
                    ${
                        props.selectionCounts.rows === 1
                            ? 'строку'
                            : `${props.selectionCounts.rows} строки`
                    } 
                    после`}
            />

            <ActionMenuAction
                onClick={() =>
                    props.insertTableColumnAtSelection(
                        false,
                    )
                }
                onClose={props.onClose}
                Icon={<TableColumnLeft />}
                label={`Вставить 
                    ${
                        props.selectionCounts.columns === 1
                            ? 'колонку'
                            : `${props.selectionCounts.columns} колонки`
                    } 
                    до`}
            />
            <ActionMenuAction
                onClick={() =>
                    props.insertTableColumnAtSelection(true)
                }
                onClose={props.onClose}
                Icon={<TableCollumnRight />}
                label={`Вставить 
                    ${
                        props.selectionCounts.columns === 1
                            ? 'колонку'
                            : `${props.selectionCounts.columns} колонки`
                    }
                    после`}
            />

            <ActionMenuAction
                onClick={() =>
                    props.deleteTableColumnAtSelection()
                }
                onClose={props.onClose}
                Icon={<TableDeleteColumn />}
                label={`Удалить колонку`}
            />

            <ActionMenuAction
                onClick={() =>
                    props.deleteTableRowAtSelection()
                }
                onClose={props.onClose}
                Icon={<TableDeleteStroke />}
                label={`Удалить строку`}
            />

            <ActionMenuAction
                onClick={() =>
                    props.deleteTableAtSelection()
                }
                onClose={props.onClose} // Icon={<TableDeleteStroke />}
                label={`Удалить таблицу`}
            />

            <ActionMenuAction
                onClick={() =>
                    props.toggleTableRowIsHeader()
                }
                onClose={props.onClose}
                label={`${
                    (props.tableCellNode.__headerState &
                        TableCellHeaderStates.ROW) ===
                    TableCellHeaderStates.ROW
                        ? 'Убрать модификацию заголовка'
                        : 'Модифицировать строку в заголовок'
                }`}
            />
            <ActionMenuAction
                onClick={() =>
                    props.toggleTableColumnIsHeader()
                }
                onClose={props.onClose}
                label={`${
                    (props.tableCellNode.__headerState &
                        TableCellHeaderStates.COLUMN) ===
                    TableCellHeaderStates.COLUMN
                        ? 'Убрать модификацию заголовка'
                        : 'Модифицировать колонку в заголовок'
                }`}
            />
        </div>
    );
}

export { Menu };
