/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { FC, ReactElement } from 'react';
import { Form, Formik } from 'formik';
import type { Article } from '@mega/api';
import { ArticleFormBody } from './ArticleFormBody';
import { FormLayout } from '@mega/ui';
import { ArticleFormSettings } from '@mega/forms/src/Article/ArticleFormSettings';
import { formatArticleData } from '@mega/store/src/models/Articles';
export interface ArticleEditProps {
    isReadonly?: boolean;
    extendForm?: ReactElement | ReactElement[];
}

export interface ArticleEditFormProps
    extends ArticleEditProps {
    isReadonly?: boolean;
    initialData?: Article | null;
}

const Preview: FC<ArticleEditFormProps> = ({
    initialData,
    isReadonly,
}) => {
    if (!initialData) {
        return null;
    }

    const data = formatArticleData(initialData);

    return (
        <Formik
            initialValues={{
                ...data,
            }}
            enableReinitialize
            onSubmit={() => {}}
        >
            <Form
                style={{
                    minHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    pointerEvents: isReadonly
                        ? 'none'
                        : 'all',
                }}
            >
                <FormLayout
                    settings={<ArticleFormSettings />}
                >
                    <ArticleFormBody />
                </FormLayout>
            </Form>
        </Formik>
    );
};

export { Preview };
