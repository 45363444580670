import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';
import { useRef } from 'react';
import { debounce } from 'lodash-es';

const useSourcesReportsStore = () => {
    const state = useStoreSelector(
        (state: RootState) => state.sourcesReports,
    );
    const loading = useStoreSelector(
        (state: RootState) =>
            state.loading.effects.sourcesReports,
    );

    return Object.assign(state, {
        loading: {
            get: loading.get,
            chungeCountItemsOnPage:
                loading.chungeCountItemsOnPage,
            jumpTo: loading.jumpTo,
        },
    });
};
const useSourcesReportsDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    const debouncedHandleSetCurrentPage = useRef(
        debounce(
            dispatch.sourcesReports.setCurrentPage,
            1000,
        ),
    );

    return {
        get: dispatch.sourcesReports.get,
        nextPage: dispatch.sourcesReports.nextPage,
        jumpTo: dispatch.sourcesReports.jumpTo,
        setCurrentPage:
            debouncedHandleSetCurrentPage.current,
        search: dispatch.sourcesReports.search,
        clear: dispatch.sourcesReports.clear,
    };
};

export {
    useSourcesReportsStore,
    useSourcesReportsDispatch,
};
