import React from 'react';
import { Button, Stack, Typography } from '@mega/ui';
import { SelectFilters } from '../SelectFilters';
import { Graph } from '@mega/icons';
import { ReportProgressBar } from '../ReportProgressBar';
import { icon, link } from '../Reports.Component.css';
import { ReportFormValues } from 'src/Reports/Reports.types';
import { useFormikContext } from 'formik';
import { useReport } from '../../hooks/useReport';

export const VideoReportPage: React.FC = () => {
    const {
        values: {
            pub_date__date__gte,
            pub_date__date__lte,
        },
    } = useFormikContext<ReportFormValues>();

    const {
        startRequestForReport,
        response,
        loading,
        isError,
    } = useReport();

    let reportFileName;
    if (response?.file) {
        reportFileName =
            response?.file.split('/')[
                response?.file.split('/').length - 1
            ];
    }

    const isButtonDisabled =
        !(
            Boolean(pub_date__date__gte) &&
            Boolean(pub_date__date__lte)
        ) || loading;

    return (
        <Stack gap="18">
            <SelectFilters page="videos" />
            <Button
                label={'Запросить'}
                onClick={startRequestForReport}
                disabled={isButtonDisabled}
            />
            {response && loading && (
                <>
                    <ReportProgressBar
                        loading={loading}
                        response={response}
                    />
                </>
            )}
            {isError && (
                <Typography size="18" weight="bold">
                    Ошибка сборки отчета
                </Typography>
            )}
            {response?.file && !loading && (
                <a className={link} href={response.file}>
                    <div className={icon} style={{}}>
                        {' '}
                        <Graph />{' '}
                    </div>

                    <Typography size="14">
                        Скачать: <em>{reportFileName}</em>
                    </Typography>
                </a>
            )}
        </Stack>
    );
};
