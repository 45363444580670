import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { iconLoaderClasses } from './IconLoader.css';
import { useFormikContext } from 'formik';
import { Tag } from '@mega/api';
import { FiPlus } from 'react-icons/fi';

type IconLoaderProps = {};

export const IconLoader: React.FC<IconLoaderProps> = () => {
    const { values, setFieldValue } =
        useFormikContext<Tag>();
    const [file, setFile] = useState<{
        file: File | null;
        preview: string | undefined;
    }>({
        file: null,
        preview:
            typeof values.icon === 'string'
                ? values.icon
                : undefined,
    });

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/svg+xml': [],
        },
        onDrop: (acceptedFiles) => {
            const newFile = acceptedFiles[0];
            setFile({
                file: newFile,
                preview: URL.createObjectURL(newFile),
            });
            setFieldValue('icon', newFile);
        },
    });

    useEffect(() => {
        return () => {
            if (
                file.preview &&
                typeof file.preview === 'string'
            ) {
                URL.revokeObjectURL(file.preview);
            }
        };
    }, [file]);

    return (
        <div
            {...getRootProps({
                className: iconLoaderClasses.dropzone,
            })}
        >
            <input {...getInputProps()} />
            {file.preview ? (
                <img
                    src={file.preview}
                    className={iconLoaderClasses.img}
                    onLoad={() => {
                        if (
                            file.preview &&
                            typeof file.preview === 'string'
                        ) {
                            URL.revokeObjectURL(
                                file.preview,
                            );
                        }
                    }}
                />
            ) : (
                <div className={iconLoaderClasses.plusIcon}>
                    <FiPlus />
                </div>
            )}
        </div>
    );
};
