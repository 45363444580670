import React, { FC } from 'react';
import { LastNewsProps } from './LastNews.types';
import { styles as s } from './LastNews.css';
import { PluginViewWrapper } from 'src/pages/smartGrid/components/shared/PluginViewWrapper';

const LastNews: FC<LastNewsProps> = (props) => {
    const { id, parentId, type } = props;
    return (
        <PluginViewWrapper
            id={id}
            parentId={parentId}
            title={'Последние новости'}
        >
            <div className={s.wrapper({})}>
                <p className={s.text}>Последние новости</p>
            </div>
        </PluginViewWrapper>
    );
};

export { LastNews };
