import React from 'react';
import { styles as s } from './PluginInfo.css';
import { Typography } from '@mega/ui';

export interface PluginInfoProps {
    data: Record<string, { label: string; value: boolean }>;
}

export const PluginInfo: React.FC<PluginInfoProps> = ({
    data,
}) => {
    return (
        <div className={s.metaWrapper}>
            {Object.keys(data).map((key) => {
                const curItem = data[key];
                if (!curItem.value) return null;
                return (
                    <div key={key}>
                        <div className={s.item}>
                            <Typography size={'12'}>
                                {curItem.label}
                            </Typography>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
