import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';

const useBannedOrganizationStore = () => {
    const store = useStoreSelector(
        (state: RootState) => state.bannedOrganization,
    );
    const loading = useStoreSelector(
        (state: RootState) =>
            state.loading.effects.bannedOrganization,
    );

    return {
        store,
        loading,
    };
};

const useBannedOrganizationDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        get: dispatch.bannedOrganization.get,
        put: dispatch.bannedOrganization.put,
        create: dispatch.bannedOrganization.create,
        update: dispatch.bannedOrganization.update,
        remove: dispatch.bannedOrganization.remove,
    };
};

export {
    useBannedOrganizationStore,
    useBannedOrganizationDispatch,
};
