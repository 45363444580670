import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgDraw = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M10.5 15.75a.54.54 0 0 1-.394-.169.54.54 0 0 1-.168-.393.53.53 0 0 1 .168-.404.555.555 0 0 1 .394-.159c.738 0 1.36-.15 1.866-.45.506-.3.759-.65.759-1.05 0-.287-.184-.569-.553-.844-.369-.275-.866-.487-1.49-.637l.88-.882c.788.238 1.366.566 1.735.985.369.419.553.878.553 1.378 0 .838-.381 1.484-1.144 1.94-.762.457-1.631.685-2.606.685Zm-6-5.512c-.8-.175-1.375-.45-1.725-.825-.35-.376-.525-.763-.525-1.163 0-.438.163-.831.487-1.181.325-.35 1.075-.738 2.25-1.163.825-.3 1.357-.543 1.594-.731.238-.188.356-.406.356-.656 0-.313-.137-.582-.412-.807-.275-.225-.7-.337-1.275-.337-.338 0-.625.044-.862.131a1.75 1.75 0 0 0-.638.413c-.1.1-.228.16-.384.178a.547.547 0 0 1-.422-.122.475.475 0 0 1-.216-.375.538.538 0 0 1 .14-.394c.213-.275.532-.503.957-.684.425-.181.9-.272 1.425-.272.85 0 1.531.203 2.044.61.512.406.769.959.769 1.659 0 .512-.179.947-.535 1.303-.356.356-1.053.728-2.09 1.115-.838.313-1.391.56-1.66.741-.269.181-.403.372-.403.572 0 .2.169.39.506.572.338.181.844.353 1.519.516l-.9.9Zm9.3-2.888-2.4-2.4.844-.844c.225-.225.475-.337.75-.337s.525.112.75.337l.9.9c.225.225.337.475.337.75s-.112.525-.337.75l-.844.844Zm-9.675 7.275h.787l6.47-6.469-.788-.787-6.469 6.469v.787ZM3 15.75v-2.4l7.594-7.594 2.4 2.4L5.4 15.75H3Zm7.594-8.381.787.787-.787-.787Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgDraw);
export default ForwardRef;
