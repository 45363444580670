/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
    Control,
    PositionsVariants,
} from '@apostroph/types';
import { InProgress } from './InProgress';
import {
    inProgressPluginDefault,
    inProgressEnum,
} from './InProgress.types';
import PendingIcon from '@mui/icons-material/Pending';
const PLUGIN_IN_PROGRESS_NAME = inProgressEnum.type;

const DEFAULT_IN_PROGRESS: inProgressPluginDefault = {
    type: PLUGIN_IN_PROGRESS_NAME,
    meta: {
        text: '',
    },
    childrenElement: [],
};

const PLUGIN_IN_PROGRESS = {
    [PLUGIN_IN_PROGRESS_NAME]: {
        emptyObject: DEFAULT_IN_PROGRESS,
        render: InProgress,
    },
};

const PLUGIN_IN_PROGRESS_CONTROL: Control = {
    name: PLUGIN_IN_PROGRESS_NAME,
    label: 'В процессе',
    tooltip: '',
    position: PositionsVariants.actionFeed,
    Icon: () => <PendingIcon />,
};

export {
    DEFAULT_IN_PROGRESS,
    PLUGIN_IN_PROGRESS,
    PLUGIN_IN_PROGRESS_CONTROL,
    PLUGIN_IN_PROGRESS_NAME,
};
