import React, {
    FC,
    ReactElement,
    CSSProperties,
} from 'react';
import {
    stackClasses,
    StackClasses,
    paddingLRClasses,
    PaddingLRClasses,
} from '@mega/styles';

import { listClasses } from './List.css';

export interface ListProps {
    children?: ReactElement | ReactElement[];
    className?: string;
    classes?: {
        stack?: StackClasses['recipe'];
        padding?: PaddingLRClasses['recipe'];
    };
    style?: CSSProperties;
}

const List: FC<ListProps> = ({
    children,
    className = '',
    classes,
    style,
}) => (
    <ul
        style={style}
        className={[
            stackClasses.recipe({
                gap: '18',
                ...(classes?.stack ?? {}),
            }),
            paddingLRClasses.recipe({
                size: '18',
                ...(classes?.padding ?? {}),
            }),
            className,
            listClasses.wrapper,
        ].join(' ')}
    >
        {children}
    </ul>
);

export { List };
