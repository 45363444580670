import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgGoogleRssOn = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 13 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M12.52 6.992v5.635c-.222.267-.564.557-1.026.87-.462.305-1.058.569-1.787.79-.73.221-1.611.332-2.646.332-.918 0-1.755-.15-2.51-.45a5.422 5.422 0 0 1-1.953-1.337c-.54-.586-.957-1.302-1.25-2.148-.293-.853-.44-1.827-.44-2.92v-.889c0-1.094.14-2.067.42-2.92.287-.853.693-1.572 1.22-2.158A5.225 5.225 0 0 1 4.435.459C5.163.153 5.974 0 6.865 0c1.237 0 2.253.202 3.047.605.794.398 1.4.951 1.816 1.66.424.704.684 1.511.782 2.422H9.668a3.633 3.633 0 0 0-.41-1.26 2.12 2.12 0 0 0-.87-.84c-.37-.2-.852-.302-1.445-.302-.488 0-.924.101-1.308.303a2.589 2.589 0 0 0-.957.869c-.26.384-.46.86-.596 1.426-.137.566-.205 1.224-.205 1.972v.909c0 .742.072 1.4.215 1.972.143.567.355 1.045.635 1.436.286.384.638.674 1.054.869.417.195.902.293 1.455.293.463 0 .847-.04 1.153-.117a2.89 2.89 0 0 0 .752-.283c.195-.118.345-.228.449-.332v-2.52H6.914v-2.09h5.606Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgGoogleRssOn);
export default ForwardRef;
