import React, { FC, useCallback } from 'react';

import { FlowRowProps } from './FlowRow.types';
import { styles as s } from './FlowRow.css';
import { PluginViewWrapper } from '@pages/smartGrid/components/shared';
import { useEditor } from '@pages/smartGrid/store/hooks';
import { useGridConfig } from '@pages/smartGrid/hooks/useGridConfig';
import cn from 'classnames';
import { GridSettings } from '@pages/smartGrid/components/shared/GridSettings';
import { FlowSettings } from '@pages/smartGrid/components/shared/FlowSettings';
import { InitFormValues } from '@pages/smartGrid/components/shared/FlowSettings/FlowSettings';

const FlowRow: FC<FlowRowProps> = (props) => {
    const { children, id, type, meta, parentId } = props;

    const { handleUpdateElement } = useEditor();

    const {
        config,
        clientGrid,
        elParent,
        setIsEdit: setIsEditConfig,
        isEdit,
        onPointerDown,
    } = useGridConfig(id, false, meta?.gridConfig?.grid);

    const saveMeta = useCallback(
        (values: InitFormValues) => {
            const newGlobalState = {
                id,
                meta: {
                    gridConfig: {
                        grid: config,
                        clientGrid,
                        ...values,
                        // alignItems: align,
                    },
                },
            };

            handleUpdateElement(newGlobalState);
        },
        [config],
    );

    // useEffect(() => {
    //     if (meta?.gridConfig?.alignItems) {
    //         setAlign(meta?.gridConfig?.alignItems);
    //     }
    // }, [meta?.gridConfig?.alignItems]);

    return (
        <PluginViewWrapper
            title={'Ряд'}
            id={id}
            parentId={parentId}
            onChange={setIsEditConfig}
            render={(isEdit: any, setIsEdit: any) => {
                return (
                    <GridSettings
                        isOpen={isEdit}
                        setIsOpen={setIsEdit}
                        title={'Ряд - настройки'}
                        render={({ handleClose }) => {
                            return (
                                <FlowSettings
                                    onSave={(
                                        values: InitFormValues,
                                    ) => {
                                        saveMeta(values);
                                        handleClose();
                                    }}
                                />
                            );
                        }}
                    />
                );
            }}
        >
            <div
                key={id}
                className={[s.wrapper()].join()}
                ref={elParent}
                // style={assignInlineVars({
                // [DynColumns]: config,// TODO разобраться почему прокидывается глобально
                // [DynAlign]: align, // Проблема с изменением размеров при align
                // })}
                style={{ gridTemplateColumns: config }}
            >
                {/*//@ts-ignore*/}
                {children?.props.children.map(
                    (child: any, idx: number) => {
                        const isVisible = idx !== 0;
                        return (
                            <div
                                onPointerDown={
                                    isEdit
                                        ? onPointerDown
                                        : undefined
                                }
                                key={`${id}_${idx}`}
                                className={cn(
                                    s.child,
                                    `${id}_part`,
                                )}
                            >
                                <div
                                    id={
                                        'not_draggable__gutter'
                                    }
                                    className={cn(
                                        s.gutter({
                                            isEdit,
                                            isVisible,
                                        }),
                                        `gutter`,
                                    )}
                                />
                                {child}
                            </div>
                        );
                    },
                )}
                <div className={s.gridView({ isEdit })}>
                    {[...new Array(12)].map((item, idx) => {
                        return (
                            <div
                                key={idx}
                                className={s.gridGutter({})}
                            />
                        );
                    })}
                </div>
                <div className={s.plug({ isEdit })} />
            </div>
        </PluginViewWrapper>
    );
};

export { FlowRow };
export default FlowRow;
