import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';

const useMainGridStore = () => {
    const state = useStoreSelector(
        (state: RootState) => state.mainGrid,
    );
    const loading = useStoreSelector(
        (state: RootState) =>
            state.loading.effects.mainGrid,
    );

    return Object.assign(state, {
        loading: {
            get: loading.get,
            getLatest: loading.getLatest,
        },
    });
};

const useMainGridDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        get: dispatch.mainGrid.get,
        getItems: dispatch.mainGrid.getItems,
        getLatest: dispatch.mainGrid.getLatest,
        create: dispatch.mainGrid.create,
        update: dispatch.mainGrid.update,
        remove: dispatch.mainGrid.remove,
        clear: dispatch.mainGrid.clear,
        goToSite: dispatch.mainGrid.goToSite,
        setActive: dispatch.mainGrid.putActive,
        setSettingsIsOpen:
            dispatch.mainGrid.setSettingsIsOpen,
        setsettingsSmartGridIsOff:
            dispatch.mainGrid.setsettingsSmartGridIsOff,
    };
};

export { useMainGridStore, useMainGridDispatch };
