import React, { Component, PropsWithChildren } from 'react';
import Select, {
    components,
    ValueContainerProps,
} from 'react-select';
import { selectSingleClasses } from '../../SelectSingle.css';

type ValueContainerOverideProps = ValueContainerProps<
    Record<string, unknown>,
    true
>;

const ValueContainer = ({
    children,
    ...props
}: PropsWithChildren<ValueContainerOverideProps>) => (
    <components.ValueContainer
        className={selectSingleClasses.valueContainer}
        {...props}
    >
        {children}
    </components.ValueContainer>
);

export { ValueContainer };
export default ValueContainer;
