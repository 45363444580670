import type {
    flexDirection,
    horizontalAlign,
    verticalAlign,
    wrap,
    stretch,
    PluginTemplate,
} from '@apostroph/types';
import { ReactElement } from 'react';

export enum BlockEnum {
    type = 'Block',
    altType = 'LeadBlock',
}

export interface BlockProps {
    id: string;
    type: string;
    parentId: string | null;
    children: ReactElement | ReactElement[];
}

export type BlockMeta = {
    name: string;
    align: flexDirection;
    verticalAlign: verticalAlign;
    horizontalAlign: horizontalAlign;
    backgroundColor: string | null;
    gap: string;
    padding: string;
    wrap: wrap;
    stretch: stretch;
};

export type BlockPlugin = PluginTemplate<
    BlockEnum.type,
    BlockMeta
>;
export type BlockPluginDefault = Omit<BlockPlugin, 'id'>;
