import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';
import { useRef } from 'react';
import { debounce } from 'lodash-es';

const useTagsStore = () => {
    const state = useStoreSelector(
        (state: RootState) => state.tags,
    );
    const loading = useStoreSelector(
        (state: RootState) => state.loading.effects.tags,
    );

    return Object.assign(state, {
        loading: {
            get: loading.get,
            chungeCountItemsOnPage:
                loading.chungeCountItemsOnPage,
            jumpTo: loading.jumpTo,
        },
    });
};
const useTagsDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    const debouncedHandleSetCurrentPage = useRef(
        debounce(dispatch.tags.setCurrentPage, 1000),
    );

    return {
        get: dispatch.tags.get,
        nextPage: dispatch.tags.nextPage,
        jumpTo: dispatch.tags.jumpTo,
        setCurrentPage:
            debouncedHandleSetCurrentPage.current,
        search: dispatch.tags.search,
        clear: dispatch.tags.clear,
    };
};

export { useTagsStore, useTagsDispatch };
