import * as CLIENT from '@mega/api';
import {
    InfounittaskArray,
    InfounittaskCreate,
    InfounittaskQuery,
    InfounittaskUpdate,
} from '@mega/api';
import { createModel } from '@rematch/core';
import { DispatchEffectWithDependency } from '../type';

import type { RootModel } from '../rootModel';

export interface InfounitTaskCreateEffect
    extends DispatchEffectWithDependency<InfounittaskCreate> {}

export interface InfounitTaskUpdateEffect
    extends DispatchEffectWithDependency<InfounittaskUpdate> {
    query: {
        id: number;
    };
}

const infounittask = createModel<RootModel>()({
    name: 'infounittask',
    state: {} as InfounittaskArray,
    reducers: {
        put: (_state, payload: InfounittaskArray) => {
            return { ...payload };
        },
    },
    effects: (dispatch) => ({
        get: async (params: { id: number }, store) => {
            try {
                const response =
                    await CLIENT.infounittask.getTask?.(
                        {
                            ...params,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );

                if (response?.ok) {
                    dispatch.infounittask.put(
                        response.data,
                    );
                    return {
                        data: response.data,
                    };
                }
            } catch (e) {
                if (
                    CLIENT.infounittask.getTask &&
                    e instanceof
                        CLIENT.infounittask.getTask.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },
        getUnit: async (params: { id: number }, store) => {
            try {
                const response =
                    await CLIENT.infounittask.getInfoUnit?.(
                        {
                            ...params,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );

                if (response?.ok) {
                    return {
                        data: response.data,
                    };
                }
            } catch (e) {
                if (
                    CLIENT.infounittask.getTask &&
                    e instanceof
                        CLIENT.infounittask.getTask.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },
        create: async (id: string, store) => {
            try {
                const response =
                    await CLIENT.infounittask?.createFromInfoUnitTask?.(
                        {
                            info_unit_task_id: id,
                        },
                    );
                if (response?.ok) {
                    dispatch.infounittask.get({
                        id: Number(id),
                    });
                    return {
                        isOk: true,
                        data: response.data,
                    };
                }
            } catch (e) {
                if (
                    CLIENT.infounittask
                        .createFromInfoUnitTask &&
                    e instanceof
                        CLIENT.infounittask
                            .createFromInfoUnitTask.Error
                ) {
                    const error = e.getActualType();

                    return {
                        isOk: false,
                        error: error,
                    };
                }
            }
        },
        update: async (
            {
                query,
                payload,
                options,
            }: InfounitTaskUpdateEffect,
            store,
        ) => {
            const getPayloadWithPageElement = (pages: {
                [key: string]: Array<InfounittaskArray>;
            }): InfounittaskQuery => {
                let page;
                for (let key in pages) {
                    if (
                        pages[key].find(
                            (item) => item.id === query.id,
                        )
                    ) {
                        page = { page: Number(key) };
                    }
                }
                return {
                    ...query,
                    ...(page as { page: number }),
                };
            };
            const pages = store.infounittasks.pages;

            const payloadTranfsorm =
                getPayloadWithPageElement(pages);

            try {
                const response =
                    await CLIENT.infounittask?.patchTask?.(
                        {
                            ...payload,
                            id: query.id,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );

                if (response?.ok) {
                    dispatch.infounittasks.get(
                        payloadTranfsorm,
                    );
                    if (options?.onSuccess) {
                        options?.onSuccess();
                    }
                    if (options?.notifier) {
                        const { enqueueSnackbar } =
                            options.notifier;
                        enqueueSnackbar('Задача обновлена');
                    }
                }
            } catch (e) {
                if (
                    CLIENT.infounittask.patchTask &&
                    e instanceof
                        CLIENT.infounittask.patchTask.Error
                ) {
                    const error = e.getActualType();

                    if (
                        options?.notifier?.enqueueSnackbar
                    ) {
                        const { enqueueSnackbar } =
                            options.notifier;
                        const localError =
                            error.data.status?.[0] ||
                            error.data?.article?.[0];
                        if (localError) {
                            enqueueSnackbar(localError, {
                                variant: 'error',
                            });
                        }
                    }
                    return error.data;
                }
            }
        },
        remove: async (id: number, store) => {
            const query = store.infounittasks.query;

            try {
                const response =
                    await CLIENT?.infounittask?.deleteTask?.(
                        {
                            id,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    dispatch.infounittasks.get(query);
                }
            } catch {}
        },
        unlinkTask: async (id: number, store) => {
            const query = store.infounittasks.query;

            try {
                const response =
                    await CLIENT?.infounittask?.unlinkTask?.(
                        {
                            id,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    dispatch.infounittasks.get(query);
                }
            } catch {}
        },
        unlinkArticle: async (id: number, store) => {
            const query = store.infounittasks.query;

            try {
                const response =
                    await CLIENT?.infounittask?.unlinkArticle?.(
                        {
                            id,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    dispatch.infounittasks.get(query);
                    return {
                        isOk: true,
                        data: response.data,
                    };
                } else {
                    return {
                        isOk: false,
                    };
                }
            } catch {}
        },
    }),
});

export { infounittask };
export default infounittask;
