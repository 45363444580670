import React, { FC, useState } from 'react';
import cn from 'classnames';
import * as classes from './ArticleSuggestedTags.css';
import {
    Button,
    Menu,
    Paper,
    Checkbox,
    Typography,
} from '@mega/ui';
import {
    paperClasses,
    typographyClasses,
} from '@mega/styles';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { useField } from 'formik';
import useResizeObserver from 'use-resize-observer';
import {
    useArticleData,
    useArticleDispatch,
} from '@mega/store';
import { LinearProgress } from '@mui/material';
import { groupBy } from 'lodash-es';
import { useFormikContext } from 'formik';
import { renderToString } from 'react-dom/server';
import { ApostrophSerializerMemo } from '@mega/core';

export interface ArticleSuggestedTagsProps {}

const borderColor = paperClasses.vars.borderColor;
const backgroundColor = paperClasses.vars.backgroundColor;
const ArticleSuggestedTags: FC<
    ArticleSuggestedTagsProps
> = () => {
    const { values } = useFormikContext();
    const [isLoading, setIsLoading] = useState(false);
    const [tagsList, setTagsList] = useState<
        Array<{ id: number; label: string }>
    >([]);

    const currentArticle = useArticleData();
    const {
        getTagsRecommended: getTagsRecommendedEffect,
        getTagsRecommends: getTagsRecommendsEffect,
    } = useArticleDispatch();
    const [
        { value: formValue = [] },
        _,
        { setValue: setValueTagsForm },
    ] = useField({
        name: 'tags',
    });

    const { ref, width: observerWidth = 0 } =
        useResizeObserver();

    const getTagsRecommends = async () => {
        setIsLoading(true);

        const editor_html = renderToString(
            <ApostrophSerializerMemo
                //@ts-ignore
                initData={values.editor_data}
            />,
        );

        const res = await getTagsRecommendsEffect({
            //@ts-ignore
            title: values.title,
            text: editor_html
                .replace(/<[^>]*>/g, ' ')
                .replace(/\s+/g, ' '),
        });

        if (res.ok && res.data) {
            const data = res.data
                .filter((item) => Boolean(item.id))
                .map((tag) => ({
                    label: tag.title,
                    id: tag.id,
                }));
            //@ts-ignore
            setTagsList(data);
        }

        setIsLoading(false);
    };

    const getTagsRecommended = async () => {
        setIsLoading(true);

        const res = await getTagsRecommendedEffect(
            currentArticle?.id,
        );
        if (res.ok && res.data) {
            const data = res.data
                .filter((item) => Boolean(item.id))
                .map((tag) => ({
                    label: tag.title,
                    id: tag.id,
                }));

            //@ts-ignore
            setTagsList(data);
        }

        setIsLoading(false);
    };

    const selectedTags = Object.keys(
        groupBy(formValue, 'id'),
    );

    const isAllTagSelected =
        tagsList.length === formValue.length;

    return (
        <div
            ref={ref}
            style={{
                display: 'flex',
                width: '100%',
            }}
        >
            <Menu
                style={{
                    margin: 0,
                    paddingBottom: 0,
                    width: observerWidth,
                    ...assignInlineVars({
                        [backgroundColor]:
                            'rgba(242, 242, 242, 1)',
                        [borderColor]:
                            'rgba(234, 234, 234, 1)',
                    }),
                }}
                trigger={(props) => (
                    <Button
                        label={'Подобрать'}
                        onClick={(e) => {
                            props.handleClick(e);
                            getTagsRecommended();
                        }}
                    />
                )}
            >
                <Paper
                    variant={'outlineFilled'}
                    borderRadius="small"
                >
                    <div
                        style={{
                            padding:
                                tagsList.length > 0
                                    ? '16px 8px 8px 8px'
                                    : '0px 0px 0px 8px',
                        }}
                    >
                        {tagsList.length === 0 && (
                            <span
                                className={classes.noItem}
                            >
                                <Typography size="15">
                                    Теги не найдены
                                </Typography>
                            </span>
                        )}
                        {tagsList.length > 0 && (
                            <Checkbox
                                className={
                                    classes.selectAllTags
                                }
                                labelProps={{
                                    size: '16',
                                    weight: 'medium',
                                }}
                                checked={isAllTagSelected}
                                label="Выбрать все теги"
                                name="checkAll"
                                value="checkAll"
                                onChange={(e) => {
                                    if (e.isChecked) {
                                        setValueTagsForm(
                                            tagsList,
                                        );
                                    } else {
                                        setValueTagsForm(
                                            [],
                                        );
                                    }
                                }}
                            />
                        )}
                        {isLoading ? (
                            <LinearProgress />
                        ) : null}
                    </div>
                    <div>
                        <ul className={classes.listing}>
                            {tagsList.map((item) => {
                                const isChecked =
                                    selectedTags.includes(
                                        `${item.id}`,
                                    );
                                return (
                                    <li key={item.id}>
                                        <Checkbox
                                            className={
                                                classes.listItem
                                            }
                                            checked={
                                                isChecked
                                            }
                                            label={
                                                item.label
                                            }
                                            name={`${item.id}`}
                                            value={`${item.id}`}
                                            onChange={(
                                                e,
                                            ) => {
                                                if (
                                                    isChecked
                                                ) {
                                                    setValueTagsForm(
                                                        formValue.filter(
                                                            (
                                                                //@ts-ignore
                                                                formItem,
                                                            ) =>
                                                                formItem.id !==
                                                                item.id,
                                                        ),
                                                    );
                                                } else {
                                                    setValueTagsForm(
                                                        [
                                                            ...formValue,
                                                            {
                                                                id: item.id,
                                                                label: item.label,
                                                            },
                                                        ],
                                                    );
                                                }
                                            }}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </Paper>
            </Menu>
        </div>
    );
};

export { ArticleSuggestedTags };
