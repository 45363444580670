/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { FC, useMemo } from 'react';

import {
    Form,
    Formik,
    useFormikContext,
    FormikHelpers,
} from 'formik';
import * as Yup from 'yup';

import type {
    PartialUpdateADSBlock,
    PartialUpdateADSBlockQuery,
} from '@mega/api';

import {
    Button,
    CheckboxSwitchField,
    FormLayout,
    InputField,
    Paper,
    Stack,
    InputTextareaField,
    SelectSingleField,
    WithLabel,
} from '@mega/ui';
import { throttle } from 'lodash-es';

import { BlockEditSettings } from './BlockEditSettings';

export interface BlockEditProps {
    initialValue: Omit<
        PartialUpdateADSBlockQuery,
        'site' | 'id'
    >;
    handleUpdate?: (
        value: Omit<
            PartialUpdateADSBlockQuery,
            'site' | 'id'
        >,
        actions: FormikHelpers<
            Omit<PartialUpdateADSBlockQuery, 'site' | 'id'>
        >,
    ) => void;
}

const BlockEditActions = () => {
    const { submitForm, isSubmitting } = useFormikContext();
    const throttledSubmit = useMemo(
        () => throttle(submitForm, 1000),
        [],
    );
    return (
        <>
            <Paper
                style={{
                    borderTop: '1px solid #EAEAEA',
                }}
                fullWidth
                color={'white'}
                variant="filled"
                classes={{
                    padding: { size: '24x24' },
                }}
            >
                <Button
                    size={'large'}
                    type={'submit'}
                    label={'Обновить'}
                    onClick={throttledSubmit}
                    classes={{
                        paper: {
                            color: 'dark',
                            variant: 'filled',
                        },
                    }}
                    disabled={isSubmitting}
                />
            </Paper>
        </>
    );
};

const BlockScriptEdit: FC<BlockEditProps> = ({
    handleUpdate = () => null,
    initialValue,
}) => (
    <Formik
        validationSchema={Yup.object({
            title: Yup.string()
                .min(
                    1,
                    'Это поле явно должно быть больше одного символа',
                )
                .required('Это поле обязательное'),
        })}
        initialValues={initialValue}
        onSubmit={handleUpdate}
    >
        <Form
            style={{
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <FormLayout
                actions={<BlockEditActions />}
                settings={
                    <Stack gap="16">
                        <Paper
                            contentHeight
                            color={'white'}
                            variant="outline"
                            classes={{
                                padding: {
                                    size: '24x24',
                                },
                            }}
                        >
                            <Stack gap="18">
                                <CheckboxSwitchField
                                    name="is_active"
                                    label="Активно"
                                />
                                <CheckboxSwitchField
                                    name="is_common"
                                    label="На всех страницах"
                                />
                            </Stack>
                        </Paper>

                        <BlockEditSettings />
                    </Stack>
                }
            >
                <Paper
                    variant="outline"
                    color="white"
                    classes={{
                        padding: {
                            size: '24x24',
                        },
                    }}
                >
                    <Stack gap="18">
                        <InputField
                            type="text"
                            name={'title'}
                            placeholder="Название"
                            dimension="medium"
                            isRequired
                        />
                        <InputField
                            type="number"
                            name={'rotation_weight'}
                            placeholder="Вес ротации"
                            dimension="medium"
                        />
                        <InputField
                            type="number"
                            name={'height'}
                            placeholder="Высота"
                            dimension="medium"
                        />
                        <InputTextareaField
                            name="body_script"
                            color={'transparent'}
                            placeholder="Скрипт"
                        />
                        <InputTextareaField
                            name="head_script"
                            color={'transparent'}
                            placeholder="Код в <head>"
                        />
                    </Stack>
                </Paper>
            </FormLayout>
        </Form>
    </Formik>
);

export { BlockScriptEdit };
