import { FC } from 'react';
import { PluginViewWrapper } from 'src/pages/smartGrid/components/shared/PluginViewWrapper';
import { GridSettings } from '@pages/smartGrid/components/shared/GridSettings';
import { useEditor } from '@pages/smartGrid/store/hooks';
import { AutoArticleProps } from './AutoArticle.types';
import { IInitValuesForm } from './AutoArticleSettings/AutoArticleForm/config';
import { AutoArticleSettings } from './AutoArticleSettings';
import { styles as s } from './AutoArticle.css';
import { Option } from '@mega/core';
import { PluginInfo } from '@pages/smartGrid/components/shared/PluginInfo/PluginInfo';
import { useMainGridStore } from '@mega/store';

const LabelRender: React.FC<{
    title: string;
    label: string;
}> = ({ label, title }) => {
    if (!label) return null;

    return (
        <div className={s.labelWrapper}>
            {title}:<div className={s.label}>{label}</div>
        </div>
    );
};

const TagRender: React.FC<{
    list: Option[];
    title: string;
}> = ({ list, title }) => {
    if (!list?.length) return null;

    return (
        <div className={s.listWrapper}>
            {title}:
            <div className={s.tagContainer}>
                {list.map((item) => (
                    <div key={item.label} className={s.tag}>
                        {item.label}
                    </div>
                ))}
            </div>
        </div>
    );
};

const AutoArticle: FC<AutoArticleProps> = ({
    id,
    type,
    meta,
    parentId,
}) => {
    const { handleUpdateElement } = useEditor();

    const store = useMainGridStore();
    const currentItem =
        store.itemsList.CONTENT_AUTO_ARTICLE[id];

    const onSubmit = (values: IInitValuesForm) => {
        handleUpdateElement({
            id,
            type,
            meta: values as Record<string, any>,
        });
    };

    return (
        <>
            <PluginViewWrapper
                title={'Автостатья'}
                id={id}
                parentId={parentId}
                render={(isEdit: any, setIsEdit: any) => {
                    return (
                        <GridSettings
                            isOpen={isEdit}
                            setIsOpen={setIsEdit}
                            title={'Автостатья - настройка'}
                            render={({ handleClose }) => {
                                return (
                                    <AutoArticleSettings
                                        initialValues={meta}
                                        onSave={(
                                            values: IInitValuesForm,
                                        ) => {
                                            onSubmit(
                                                values,
                                            );
                                            handleClose();
                                        }}
                                    />
                                );
                            }}
                        />
                    );
                }}
            >
                <div className={s.wrapper({})}>
                    <div className={s.content}>
                        <LabelRender
                            title={'Название'}
                            label={currentItem?.label}
                        />
                        <TagRender
                            title={'Рубрики'}
                            list={meta.categories}
                        />
                        <TagRender
                            title={'Теги'}
                            list={meta.tags}
                        />
                    </div>
                    <PluginInfo
                        data={{
                            label: {
                                label: meta?.cover?.label,
                                value: !!meta.cover,
                            },
                            withLead: {
                                label: 'Лид-абзац',
                                value: meta.withLead,
                            },
                            withInfo: {
                                label: 'Инфо',
                                value: meta.withInfo,
                            },
                        }}
                    />
                </div>
            </PluginViewWrapper>
        </>
    );
};

export { AutoArticle };
