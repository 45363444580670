import { srcset } from './types';

export const getActualPreview = (
    srcsets: srcset[],
    height: number,
    width: number,
    debug?: boolean,
) => {
    const isVertical = height > width;
    const searchValue = isVertical ? height : width;
    const searchKey = isVertical
        ? 'resize_height'
        : 'resize_width';
    const resizeArray = srcsets.filter(
        (item) => item[searchKey] >= searchValue,
    );

    const closestWidth = resizeArray.find((it) => {
        return (
            Math.abs(it[searchKey] - searchValue) ===
            Math.min(
                ...resizeArray.map((it) =>
                    Math.abs(it[searchKey] - searchValue),
                ),
            )
        );
    });

    const crop = closestWidth;

    return crop;
};
