import { ArticleForm } from '@mega/api';
import { createSelector } from 'reselect';
import { useLocalStorage } from 'usehooks-ts';
import {
    Dispatch,
    RootState,
    store,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';
import {
    selectArticle,
    selectArticleFull,
    selectArticleData,
    selectArticleMeta,
    fullProgress,
    pickCreateProgress,
    pickGetProgress,
    pickRemoveProgress,
    pickRemoveWithRoomCheckProgress,
    pickTxnGetProgress,
    pickUpdateProgress,
} from './private/article.selectors';

const reselectArticleFull = createSelector(
    selectArticle,
    selectArticleFull,
);

const reselectArticleData = createSelector(
    selectArticle,
    selectArticleData,
);

const reselectArticleMeta = createSelector(
    selectArticle,
    selectArticleMeta,
);

const useArticleStore = () => {
    const article = useStoreSelector(reselectArticleFull);
    return article;
};

const useArticleData = () => {
    const data = useStoreSelector(reselectArticleData);
    return data;
};

const useArticleMeta = () => {
    const meta = useStoreSelector(reselectArticleMeta);
    return meta;
};

const rePickCreateProgress = createSelector(
    fullProgress,
    pickCreateProgress,
);

const rePickGetProgress = createSelector(
    fullProgress,
    pickGetProgress,
);

const rePickRemoveProgress = createSelector(
    fullProgress,
    pickRemoveProgress,
);

const rePickRemoveWithRoomCheckProgress = createSelector(
    fullProgress,
    pickRemoveWithRoomCheckProgress,
);

const rePickTxnGetProgress = createSelector(
    fullProgress,
    pickTxnGetProgress,
);

const rePickUpdateProgress = createSelector(
    fullProgress,
    pickUpdateProgress,
);

const usePickCreateProgress = () => {
    const loading = useStoreSelector(rePickCreateProgress);
    return loading;
};

const usePickGetProgress = () => {
    const loading = useStoreSelector(rePickGetProgress);
    return loading;
};

const usePickUpdateProgress = () => {
    const loading = useStoreSelector(rePickUpdateProgress);
    return loading;
};

const usePickRemoveProgress = () => {
    const loading = useStoreSelector(rePickRemoveProgress);
    return loading;
};
const usePickRemoveWithRoomCheckProgress = () => {
    const loading = useStoreSelector(
        rePickRemoveWithRoomCheckProgress,
    );
    return loading;
};
const usePickTxnGetProgress = () => {
    const loading = useStoreSelector(rePickTxnGetProgress);
    return loading;
};

const selectArticleFormView = store.select((models) => ({
    formView: models.article.formView,
}));

const useArticleFormView = () => {
    const { formView } = useStoreSelector(
        selectArticleFormView,
    );
    return formView;
};

const useArticleDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        put: dispatch.article.put,
        get: dispatch.article.get,
        create: dispatch.article.create,
        update: dispatch.article.update,
        remove: dispatch.article.remove,
        removeByIdWithRoomCheck:
            dispatch.article.removeByIdWithRoomCheck,
        goToSite: dispatch.article.goToSite,
        clear: dispatch.article.clear,
        txnGet: dispatch.article.txnGet,
        transformFromFormViewToModel:
            dispatch.article.transformFromFormViewToModel,
        transformFromModelToFormView:
            dispatch.article.transformFromModelToFormView,
        getTagsRecommends:
            dispatch.article.getTagsRecommends,
        getTagsRecommended:
            dispatch.article.getTagsRecommended,
    };
};

export {
    useArticleStore,
    useArticleData,
    useArticleMeta,
    useArticleDispatch,
    useArticleFormView,
    usePickCreateProgress,
    usePickGetProgress,
    usePickRemoveProgress,
    usePickUpdateProgress,
    usePickRemoveWithRoomCheckProgress,
    usePickTxnGetProgress,
};
