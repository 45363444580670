import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../../models';

const useAdsHeadScriptsStore = () => {
    const state = useStoreSelector(
        (state: RootState) => state.adsHeadScripts,
    );
    const loading = useStoreSelector(
        (state: RootState) =>
            state.loading.effects.adsHeadScripts,
    );

    return Object.assign(state, {
        loading: {
            get: loading.get,
            chungeCountItemsOnPage:
                loading.chungeCountItemsOnPage,
        },
    });
};
const useAdsHeadScriptsDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        get: dispatch.adsHeadScripts.get,
        nextPage: dispatch.adsHeadScripts.nextPage,
        search: dispatch.adsHeadScripts.search,
        clear: dispatch.adsHeadScripts.clear,
        jumpTo: dispatch.adsHeadScripts.jumpTo,
    };
};

export {
    useAdsHeadScriptsStore,
    useAdsHeadScriptsDispatch,
};
