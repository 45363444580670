import React, { useEffect, useState } from 'react';
import {
    dropdownItem,
    styledDot,
    styledDots,
    styledDropdown,
    styledWrapper,
} from './MediaFunctionalCard.css';
import { MediaCard } from '../MediaCard';
import { Typography } from '@mega/ui';
import { exportFile, fetchFile } from '../Helper';

export interface MediaCardProps {
    onCoverUpdate: (file: any, desc: string) => void;
    file?: any;
    type?: string;
    files?: any[];
    text?: string;
    onClick?: () => void;
    noTitle?: boolean;
}

const getFileUrl = (file: any) => {
    switch (file?.type) {
        case 'image': {
            return file.image;
        }
        case 'video': {
            return file.file_link || file.video;
        }
        case 'audio': {
            return file.audio;
        }
        case 'file': {
            return file.file;
        }
    }
};
export const MediaFunctionalCard: React.FC<
    MediaCardProps
> = (props) => {
    const { file, onCoverUpdate } = props;

    const fileDesc =
        file.description && file.source_text
            ? `${file.description} (${file.source_text})`
            : '';
    const [copiedId, setCopiedId] = useState<number | null>(
        null,
    );
    const fileLink = getFileUrl(file);
    const handleDownload = () => {
        fetchFile(fileLink).then((file) =>
            exportFile(file),
        );
    };
    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text);
    };

    useEffect(() => {
        if (copiedId === null) return;
        const timer = setTimeout(() => {
            setCopiedId(null);
        }, 1500);
        return () => clearTimeout(timer);
    }, [copiedId]);

    const canBeCover =
        file.type === 'image' || file.type === 'video';
    return (
        <div className={styledWrapper}>
            <MediaCard {...props} />
            <div className={styledDots}>
                <div className={styledDot} />
                <div className={styledDot} />
                <div className={styledDot} />
                <div className={styledDropdown}>
                    {canBeCover && (
                        <div
                            onClick={() =>
                                onCoverUpdate(
                                    file,
                                    fileDesc,
                                )
                            }
                        >
                            <Typography
                                className={dropdownItem}
                            >
                                Сделать обложкой
                            </Typography>
                        </div>
                    )}
                    <div
                        onClick={() => {
                            handleCopy(file?.source_text);
                            setCopiedId(0);
                        }}
                    >
                        <Typography
                            className={dropdownItem}
                        >
                            {copiedId === 0
                                ? 'Скопировано!'
                                : 'Скопировать автора'}
                        </Typography>
                    </div>
                    <div
                        onClick={() => {
                            handleCopy(file.description);
                            setCopiedId(1);
                        }}
                    >
                        <Typography
                            className={dropdownItem}
                        >
                            {copiedId === 1
                                ? 'Скопировано!'
                                : 'Скопировать описание'}
                        </Typography>
                    </div>
                    <div onClick={handleDownload}>
                        <Typography
                            className={dropdownItem}
                        >
                            Скачать файл
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};
