import React from 'react';
import {
    Control,
    PositionsVariants,
} from '@apostroph/types';
import { ViewDay as ViewDayIcon } from '@mui/icons-material';
import { Block } from './Block';
import {
    BlockPluginDefault,
    BlockEnum,
} from './Block.types';

const PLUGIN_BLOCK_NAME = BlockEnum.type;
const PLUGIN_ALT_NAME = BlockEnum.altType;

const DEFAULT_BLOCK: BlockPluginDefault = {
    type: PLUGIN_BLOCK_NAME,
    meta: {
        name: 'Новый блок',
        align: 'column',
        verticalAlign: 'top',
        horizontalAlign: 'left',
        backgroundColor: null,
        gap: '60px',
        padding: '0px',
        wrap: 'nowrap',
        stretch: 'nostretch',
    },
    childrenElement: [],
};

const PLUGIN_BLOCK = {
    [PLUGIN_BLOCK_NAME]: {
        emptyObject: DEFAULT_BLOCK,
        render: Block,
    },
};

const PLUGIN_LEED = {
    [PLUGIN_ALT_NAME]: {
        emptyObject: DEFAULT_BLOCK,
        render: Block,
    },
};

const PLUGIN_BLOCK_CONTROL: Control = {
    name: PLUGIN_BLOCK_NAME,
    label: 'Блок',
    tooltip: 'Создать блок',
    position: PositionsVariants.actionFeed,
    Icon: () => <ViewDayIcon />,
};

export {
    DEFAULT_BLOCK,
    PLUGIN_BLOCK,
    PLUGIN_BLOCK_CONTROL,
    PLUGIN_BLOCK_NAME,
    PLUGIN_LEED,
};
export default PLUGIN_BLOCK;
