import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgSubject = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M3 14.25v-1.125h7.238v1.125H3Zm0-3.113v-1.125h12v1.125H3Zm0-3.13V6.88h12v1.125H3Zm0-3.132V3.75h12v1.125H3Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgSubject);
export default ForwardRef;
