/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { FC, ReactElement, useMemo } from 'react';
import { format } from 'date-fns';

import {
    Form,
    Formik,
    useFormikContext,
    FormikHelpers,
} from 'formik';
import * as Yup from 'yup';
import { FileWithPath } from 'react-dropzone';

import { Author, authors } from '@mega/api';

import {
    Accordion,
    Button,
    CheckboxSwitchField,
    InputField,
    Paper,
    FormLayout,
    Stack,
    WithLabel,
    InputSelectMultiField,
    Row,
} from '@mega/ui';
import { throttle } from 'lodash-es';
import { getOptionsSites } from '@mega/core';
import { AvatarFileLoader } from './Field/AvatarLoader.field';
import { useSnackbar } from 'notistack';
import {
    useAuthor,
    useAuthorDispatch,
    useAuthorStore,
} from '@mega/store';

export interface AuthorEditProps {}

const AuthorEditActions = () => {
    const { submitForm, isSubmitting } = useFormikContext();
    const throttledSubmit = useMemo(
        () => throttle(submitForm, 1000),
        [],
    );
    return (
        <>
            <Paper
                style={{
                    borderTop: '1px solid #EAEAEA',
                }}
                fullWidth
                color={'white'}
                variant="filled"
                classes={{
                    padding: { size: '24x24' },
                }}
            >
                <Button
                    size={'large'}
                    type={'submit'}
                    label={'Обновить'}
                    onClick={throttledSubmit}
                    classes={{
                        paper: {
                            color: 'dark',
                            variant: 'filled',
                        },
                    }}
                    disabled={isSubmitting}
                />
            </Paper>
        </>
    );
};

interface AuthorFormData {
    sites: Array<{
        id: string;
        label: string;
    }>;
    slug: string;
    isActive: boolean;
    name: string;
    email: string;
    position: string;
    description: string;
    photo: FileWithPath | string | null;
}

const AuthorEdit: FC<AuthorEditProps> = ({}) => {
    const { updateFromForm } = useAuthor();
    const { store, loading } = useAuthorStore();

    if (loading.get.loading || !loading.get.success) {
        return <div>loading</div>;
    }
    return (
        <Formik
            validationSchema={Yup.object({
                name: Yup.string()
                    .min(
                        2,
                        'Это поле явно должно быть больше одного символа',
                    )
                    .required('Это поле обязательное'),
                sites: Yup.array().of(
                    Yup.object().shape({
                        id: Yup.string(),
                        label: Yup.string(),
                    }),
                ),
            })}
            initialValues={
                {
                    sites: store?.sites || [],
                    slug: store?.slug || '',
                    isActive: store?.is_active || false,
                    name: store?.name || '',
                    email: store?.email || '',
                    position: store?.position || '',
                    description: store?.description || '',
                    photo: store?.photo || '',
                } as AuthorFormData
            }
            onSubmit={updateFromForm}
        >
            {({ values }) => {
                return (
                    <Form
                        style={{
                            minHeight: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <FormLayout
                            actions={<AuthorEditActions />}
                            settings={
                                <Paper
                                    contentHeight
                                    color={'white'}
                                    variant="outline"
                                    classes={{
                                        padding: {
                                            size: '24x24',
                                        },
                                    }}
                                >
                                    <Stack gap="18">
                                        <WithLabel
                                            id={'photo'}
                                            title="Аватар"
                                        >
                                            <AvatarFileLoader />
                                        </WithLabel>
                                        <Accordion
                                            isOpened
                                            label={
                                                'Настройки'
                                            }
                                            value="settings"
                                        >
                                            <Stack gap="16">
                                                <Stack gap="8">
                                                    <CheckboxSwitchField
                                                        name="isActive"
                                                        label="Активно"
                                                    />
                                                    <WithLabel
                                                        id={
                                                            'sites'
                                                        }
                                                        title="Сайты"
                                                    >
                                                        <InputSelectMultiField
                                                            classes={{
                                                                paper: {
                                                                    color: 'dark',
                                                                },
                                                                select: {
                                                                    variant:
                                                                        'secondary',
                                                                },
                                                            }}
                                                            name="sites"
                                                            handleLoader={getOptionsSites()}
                                                        />
                                                    </WithLabel>
                                                </Stack>
                                            </Stack>
                                        </Accordion>
                                    </Stack>
                                </Paper>
                            }
                        >
                            <Paper
                                variant="outline"
                                color="white"
                                classes={{
                                    padding: {
                                        size: '24x24',
                                    },
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection:
                                            'column',
                                        height: '800px',
                                    }}
                                >
                                    <Stack gap="18">
                                        <InputField
                                            type="text"
                                            name="name"
                                            placeholder="Имя Фамилия"
                                            dimension="medium"
                                            isRequired
                                        />
                                        <InputField
                                            type="text"
                                            name="description"
                                            placeholder="Описание"
                                            dimension="medium"
                                        />
                                        <InputField
                                            type="text"
                                            name="position"
                                            placeholder="Должность"
                                            dimension="medium"
                                        />
                                        <InputField
                                            type="text"
                                            name="email"
                                            placeholder="Почта"
                                            dimension="medium"
                                        />
                                    </Stack>
                                </div>
                            </Paper>
                        </FormLayout>
                    </Form>
                );
            }}
        </Formik>
    );
};

export { AuthorEdit };
