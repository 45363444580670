import { Form, Formik } from 'formik';
import React, { FC } from 'react';
import { validateHeadline } from './HeadlineForm/config/validate';
import {
    IInitValuesForm,
    initValuesForm,
} from './HeadlineForm/config/config';
import { HeadlineForm } from './HeadlineForm/HeadlineForm';

interface HeadlineSettingsProps {
    initialValues?: IInitValuesForm;
    onSave: (values: IInitValuesForm) => void;
}

export const HeadlineSettings: FC<
    HeadlineSettingsProps
> = ({ onSave, initialValues = initValuesForm }) => {
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validateHeadline}
            validateOnChange={false}
            validateOnBlur={false}
            // enableReinitialize
            onSubmit={onSave}
        >
            {({ handleSubmit, values }) => (
                <Form>
                    <HeadlineForm
                        onSubmit={handleSubmit}
                        values={values}
                    />
                </Form>
            )}
        </Formik>
    );
};
