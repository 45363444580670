/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
    CSSProperties,
    FC,
    MouseEvent,
    ReactElement,
    useState,
} from 'react';

import { assignInlineVars } from '@vanilla-extract/dynamic';
import { Popover, PopoverOrigin } from '@mui/material';
import {
    paddingTBClasses,
    PaddingTBClasses,
} from '@mega/styles';

import { withTriggerClasses } from './WithTrigger.css';

export interface TriggerProps {
    style?: CSSProperties;
    paddingTB?: PaddingTBClasses['recipe'];
    children: JSX.Element | JSX.Element[];
    className?: string;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    height?: string;
    width?: string;

    trigger: (props: {
        ref: HTMLButtonElement | HTMLElement | null;
        handleClose: (e: MouseEvent) => void;
        handleClick: (e: MouseEvent) => void;
        setOpen: (props: { target: HTMLElement }) => void;
        setClose: () => void;
    }) => ReactElement;
}

const WithTrigger: FC<TriggerProps> = ({
    paddingTB = { size: '16' },
    children,
    className = '',
    trigger,
    width = '300px',
    height = 'min-content',
    anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin = {
        vertical: 'top',
        horizontal: 'left',
    },
}) => {
    const [anchorEl, setAnchorEl] = useState<
        HTMLButtonElement | HTMLElement | null
    >(null);

    const handleClick = (event: unknown) => {
        if (typeof event === 'object' && event) {
            if (
                Object.prototype.hasOwnProperty.call(
                    event,
                    'currentTarget',
                )
            ) {
                // @ts-ignore
                setAnchorEl(event.currentTarget);
            }
        }
    };

    const handleClose = (e: MouseEvent) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setAnchorEl(null);
    };

    const childrenWithProps = React.Children.map(
        children,
        (child) => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child, {
                    // @ts-ignore
                    handleClose,
                });
            }
            return child;
        },
    );

    const handleSetOpen = (props: {
        target: HTMLElement;
    }) => {
        setAnchorEl(props.target);
    };

    const handleSetClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <>
            {trigger({
                ref: anchorEl,
                handleClose,
                handleClick,
                setOpen: handleSetOpen,
                setClose: handleSetClose,
            })}
            <Popover
                id="test"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                }}
            >
                <div
                    style={assignInlineVars({
                        [withTriggerClasses.vars.height]:
                            height,
                        [withTriggerClasses.vars.width]:
                            width,
                    })}
                    className={[
                        className,
                        withTriggerClasses.withTrigger,
                        paddingTBClasses.recipe(paddingTB),
                    ].join(' ')}
                >
                    {childrenWithProps}
                </div>
            </Popover>
        </>
    );
};

export { WithTrigger };
