import React from 'react';
import { Button } from '@mega/ui';
import { TagFiltersState } from '../TagFilters';

type TagFilterStateKeys = keyof TagFiltersState;
type TagButtonStyle = Record<string, string>;

interface ITagsButtonFilterProps {
    value: boolean | string | number;
    label: string;
    id: TagFilterStateKeys;
    handleSelect: (id: string, value: boolean) => void;
    style?: TagButtonStyle;
}

export const TagsButtonFilter = ({
    handleSelect,
    value,
    label,
    id,
    style,
}: ITagsButtonFilterProps) => {
    const handleClick = () => {
        handleSelect(id, !value);
    };

    return (
        <Button
            style={style}
            size={'large'}
            label={label}
            classes={{
                paper: {
                    variant: 'filled',
                    color: !value ? 'gray' : 'green',
                },
            }}
            onClick={handleClick}
        />
    );
};
