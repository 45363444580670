import React, { FC } from 'react';
import { format } from 'date-fns';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Icon,
    Bread,
    Crumbs,
    Button,
    Indi,
} from '@mega/ui';
import { fmtMSS } from '@mega/admin/src/utils';
import {
    listingInfoUnitTasksClasses,
    ListingInfoUnitTasksClasses,
} from './ListingInfoUnitTasks.css';
import {
    RootState,
    useInfounittasksDispatch,
    useStoreSelector,
} from '@mega/store';
import { InfounittaskArray } from '@mega/api';
import { article } from '@mega/store/src/models/Articles';

const PublicationColumn: FC<
    InfounittaskArray &
        ListingInfoUnitTasksClasses['recipe']
> = (props) => {
    const date = props?.info_unit.created_at || null;
    const formatDate = date
        ? `${format(
              new Date(date),
              'dd.MM.yyyy',
          )} | ${format(new Date(date), 'HH:mm')}`
        : '';

    return (
        <div
            className={listingInfoUnitTasksClasses.publication(
                {
                    variant: props.variant,
                },
            )}
        >
            <Crumbs>
                <span>{formatDate}</span>
            </Crumbs>
        </div>
    );
};

const ActionColumn: FC<
    InfounittaskArray &
        ListingInfoUnitTasksClasses['recipe']
> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const isLoading = useStoreSelector(
        (store: RootState) =>
            store.loading.effects.infounittasks
                .createArticle.loading,
    );
    const { createArticle } = useInfounittasksDispatch();
    const hasArticle = Boolean(props?.article);
    const id = props.id;
    return (
        <div
            className={listingInfoUnitTasksClasses.actions}
            style={{
                justifyContent: 'center',
            }}
        >
            <Button
                label={'Создать'}
                disabled={hasArticle || isLoading}
                onClick={async () => {
                    if (id) {
                        const resEffect =
                            await createArticle(`${id}`);
                        if (resEffect?.isOk) {
                            navigate(
                                `/${resEffect.data?.site?.id}/article/change/${resEffect.data?.id}`,
                                {
                                    replace: true,
                                    state: location,
                                },
                            );
                        }
                    }
                }}
            />
        </div>
    );
};

const InfounittaskColumns: FC<
    InfounittaskArray &
        ListingInfoUnitTasksClasses['recipe']
> = ({ variant, info_unit }) => {
    let labelUrgency;

    const deadline = {
        '2h': 'до 2 часов',
        '3h': 'В ближайшее время',
        '6h': 'до 6 часов',
        '12h': 'до 12 часов',
        '24h': 'В течение дня',
        urgent: 'Молния',
        not_urgent: 'эмбарго',
    };

    if (info_unit?.urgency) {
        labelUrgency = deadline[info_unit.urgency];
    }
    return variant === 'infounittasks' ? (
        <>
            <div
                className={
                    listingInfoUnitTasksClasses.deadline
                }
            >
                <Indi
                    label={labelUrgency}
                    colors={info_unit?.urgency}
                />
            </div>
            <ul
                className={
                    listingInfoUnitTasksClasses.themesStyle
                }
            >
                {info_unit?.themes?.map((theme) => {
                    return (
                        <li key={theme.id}>
                            {theme.label}
                        </li>
                    );
                })}
            </ul>
        </>
    ) : null;
};

const ListingInfoUnitTasks: FC<
    InfounittaskArray &
        ListingInfoUnitTasksClasses['recipe']
> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const hasArticle = Boolean(props?.article);

    const title = props.info_unit.title;
    return (
        <div
            style={{
                ...(hasArticle
                    ? { cursor: 'pointer' }
                    : {}),
            }}
            className={listingInfoUnitTasksClasses.wrapper({
                header: props.header,
                variant: props.variant,
            })}
            onClick={() => {
                if (hasArticle) {
                    const article = props.article?.id;
                    const site = props.site.id;
                    navigate(
                        `/${site}/article/change/${article}`,
                        {
                            replace: true,
                            state: location,
                        },
                    );
                }
            }}
        >
            <div
                className={listingInfoUnitTasksClasses.lead(
                    {
                        variant: 'title',
                    },
                )}
            >
                {title === '' ? 'Без названия' : title}
            </div>

            <PublicationColumn {...props} />
            <InfounittaskColumns {...props} />
            <ActionColumn {...props} />
        </div>
    );
};

export { ListingInfoUnitTasks };
