import { ReactElement } from 'react';
import { PluginTemplate } from '@pages/smartGrid/types/blocks';

export enum FlowRowEnum {
    type = 'FLOW_ROW',
}

export interface FlowRowProps {
    id: string;
    type: string;
    parentId: string | null;
    meta?: any;
    children: ReactElement | ReactElement[];
}

export type FlowRowMeta = {};

export type FlowRowPlugin = PluginTemplate<
    FlowRowEnum.type,
    FlowRowMeta
>;
export type FlowRowPluginDefault = Omit<
    FlowRowPlugin,
    'id'
>;
