import {
    Button,
    InputField,
    InputTextareaField,
} from '@mega/ui';
import React, { FC } from 'react';
import { styles as s } from '../../Ads.css';

interface AdsFormProps {
    onSubmit: (
        e?: React.FormEvent<HTMLFormElement> | undefined,
    ) => void;
}

export const AdsForm: FC<AdsFormProps> = ({ onSubmit }) => {
    return (
        <>
            <div className={s.settingsContainer}>
                <InputTextareaField
                    name="body_script"
                    rows={3}
                    placeholder="Код"
                />
                <InputField
                    name="height"
                    dimension="medium"
                    type="number"
                    isError
                    placeholder="Высота"
                />
                <Button
                    type={'button'}
                    label={'Сохранить'}
                    onClick={() => onSubmit()}
                    classes={{
                        paper: {
                            color: 'dark',
                            variant: 'filled',
                        },
                    }}
                />
            </div>
        </>
    );
};
