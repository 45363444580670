import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgFormatListBulleted = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M3.206 14.625a.937.937 0 0 1-.675-.272.87.87 0 0 1-.281-.647.92.92 0 0 1 .281-.675.92.92 0 0 1 .675-.281.87.87 0 0 1 .647.281.937.937 0 0 1 .272.675c0 .25-.09.466-.272.647a.883.883 0 0 1-.647.272ZM6 14.25v-1.125h9.75v1.125H6ZM3.206 9.937a.937.937 0 0 1-.675-.271A.89.89 0 0 1 2.25 9a.89.89 0 0 1 .281-.666.937.937 0 0 1 .675-.271.87.87 0 0 1 .647.28.915.915 0 0 1 .272.657c0 .25-.09.469-.272.656a.87.87 0 0 1-.647.281ZM6 9.563V8.437h9.75v1.126H6ZM3.187 5.25a.905.905 0 0 1-.665-.272.905.905 0 0 1-.272-.665c0-.263.09-.485.272-.666a.905.905 0 0 1 .666-.272c.262 0 .484.09.665.272a.905.905 0 0 1 .272.666c0 .262-.09.484-.272.665a.905.905 0 0 1-.666.272ZM6 4.875V3.75h9.75v1.125H6Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgFormatListBulleted);
export default ForwardRef;
