/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
    Control,
    PositionsVariants,
} from '@apostroph/types';
import { Reference } from '@mega/icons';
import { Main } from './Main';
import { MainPluginDefault, MainEnum } from './Main.types';

const PLUGIN_MAIN_NAME = MainEnum.type;

const DEFAULT_MAIN: MainPluginDefault = {
    type: PLUGIN_MAIN_NAME,
    meta: {
        text: '',
    },
    childrenElement: [],
};

const PLUGIN_MAIN = {
    [PLUGIN_MAIN_NAME]: {
        emptyObject: DEFAULT_MAIN,
        render: Main,
    },
};

const PLUGIN_MAIN_CONTROL: Control = {
    name: PLUGIN_MAIN_NAME,
    label: 'Блок Справочное',
    tooltip: 'Создать Блок Справочное',
    position: PositionsVariants.actionFeed,
    Icon: () => <Reference />,
};

export {
    DEFAULT_MAIN,
    PLUGIN_MAIN,
    PLUGIN_MAIN_CONTROL,
    PLUGIN_MAIN_NAME,
};
export default PLUGIN_MAIN;
