import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';

const useInfounitTaskStore = () => {
    const store = useStoreSelector(
        (state: RootState) => state.infounittask,
    );
    const loading = useStoreSelector(
        (state: RootState) =>
            state.loading.effects.infounittask,
    );

    return {
        store,
        loading,
    };
};

const useInfounitTaskDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        getTask: dispatch.infounittask.get,
        getUnit: dispatch.infounittask.getUnit,
        put: dispatch.infounittask.put,
        create: dispatch.infounittask.create,
        update: dispatch.infounittask.update,
        remove: dispatch.infounittask.remove,
        unlinkTask: dispatch.infounittask.unlinkTask,
        unlinkArticle: dispatch.infounittask.unlinkArticle,
    };
};

export { useInfounitTaskStore, useInfounitTaskDispatch };
