import React, { FC, useEffect, useState } from 'react';
import {
    HeadTemplatePlugin,
    TemplatePlugin,
} from '@mega/editor';
import { useEditor } from '@apostroph/store';
import { MainMeta, MainProps } from './Main.types';
import { Formik } from 'formik';
import { MainForm } from './MainForm';
import { Icon, Typography } from '@mega/ui';
import { Reference } from '@mega/icons';

const initialValues: MainMeta = {
    text: '',
};
const Main: FC<MainProps> = ({ id, parentId, type }) => {
    const { handleUpdateElement, getCurrentDataElement } =
        useEditor();
    const { meta, childrenElement } =
        getCurrentDataElement<MainMeta>(id);
    const [state, setState] = useState<MainMeta>(meta);
    const handleUpdate = (data: MainMeta) => {
        setState(data);
    };

    useEffect(() => {
        handleUpdateElement({
            type,
            id,
            meta: state,
            childrenElement,
        });
    }, [state]);

    useEffect(() => {
        setState(meta);
    }, [meta]);

    return (
        <Formik
            initialValues={state || initialValues}
            enableReinitialize
            onSubmit={() => undefined}
        >
            {() => {
                return (
                    <TemplatePlugin
                        head={
                            <HeadTemplatePlugin
                                id={id}
                                parentId={parentId}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection:
                                            'row',
                                        justifyContent:
                                            'center',
                                        alignItems:
                                            'center',
                                        gap: '4px',
                                    }}
                                >
                                    <Icon size="16">
                                        <Reference />
                                    </Icon>
                                    <Typography size={'12'}>
                                        Блок Справочное
                                    </Typography>
                                </div>
                            </HeadTemplatePlugin>
                        }
                    >
                        <MainForm
                            handleUpdate={handleUpdate}
                        />
                    </TemplatePlugin>
                );
            }}
        </Formik>
    );
};

export { Main };
