import Typograf from 'typograf';
const tp = new Typograf({ locale: ['ru', 'en-US'] });
tp.disableRule('common/symbols/cf');

const chars: Record<string, string> = { Ё: 'Е', ё: 'е' };
export const getTypographedText = (text: string) => {
    if (text === ' ') {
        return ' ';
    }
    if (!text) {
        return '';
    }
    let typographedText = tp.execute(text);
    typographedText = typographedText.replace(
        /[Ёё]/g,
        (m) => chars[m],
    );
    return typographedText;
};
