import React, {
    FC,
    ReactElement,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { motion } from 'framer-motion';
import { Portal } from '@mui/material';
import { mainLayoutClasses } from '@mega/styles';
import {
    LayoutContext,
    MainLayoutContextProvider,
} from './context/mainLayout.context';

const {
    drawerClass,
    headerClass,
    contentClass,
    bottomClass,
    layoutRecipe,
    vars,
} = mainLayoutClasses;

export interface MainLayoutProps {
    drawer?: ReactElement | ReactElement[];
    children?: ReactElement | ReactElement[];
    showDrawer?: boolean;
    isSmallSidebar?: boolean;
}

const MainLayout: FC<MainLayoutProps> = ({
    drawer,
    showDrawer,
    children,
    isSmallSidebar,
}) => {
    const headerRef = useRef<HTMLDivElement>(null);
    const bottomRef = useRef<HTMLDivElement>(null);

    const getWidthSidebar = isSmallSidebar
        ? '55px'
        : '240px';

    const drawerVariants = {
        visible: {
            ...assignInlineVars({
                [vars.widthDrawer]: getWidthSidebar,
            }),
        },
        hidden: {
            ...assignInlineVars({
                [vars.widthDrawer]: '0px',
            }),
        },
    };

    const animateStateDrawer = showDrawer
        ? 'visible'
        : 'hidden';

    return (
        <motion.div
            initial={'hidden'}
            animate={animateStateDrawer}
            variants={drawerVariants}
            className={[layoutRecipe()].join(' ')}
            transition={{
                delay: 0.4,
                duration: 0.5,
                ease: [0.4, 0, 0.2, 1],
            }}
        >
            <MainLayoutContextProvider
                headerRef={headerRef}
                bottomRef={bottomRef}
            >
                <div className={[drawerClass].join(' ')}>
                    {showDrawer && drawer}
                </div>
                <div
                    className={[headerClass].join(' ')}
                    ref={headerRef}
                />
                <div className={[contentClass].join(' ')}>
                    {children}
                </div>
                <div
                    className={[bottomClass].join(' ')}
                    ref={bottomRef}
                />
            </MainLayoutContextProvider>
        </motion.div>
    );
};

export interface MainLayoutBottomPortalProps {
    children: ReactElement | ReactElement[];
}

const MainLayoutBottomPortal: FC<
    MainLayoutBottomPortalProps
> = ({ children }) => {
    const [isMount, setIsMount] = useState(false);
    const context = useContext(LayoutContext);

    useEffect(() => {
        if (context?.bottomRef?.current) {
            setIsMount(true);
        }
    }, [context?.headerRef?.current]);

    if (isMount) {
        return (
            <Portal container={context?.bottomRef?.current}>
                {children}
            </Portal>
        );
    }

    return null;
};

export interface MainLayoutHeaderPortalProps {
    children: ReactElement | ReactElement[];
}

const MainLayoutHeaderPortal: FC<
    MainLayoutHeaderPortalProps
> = ({ children }) => {
    const [isMount, setIsMount] = useState(false);
    const context = useContext(LayoutContext);

    useEffect(() => {
        if (context?.headerRef?.current) {
            setIsMount(true);
        }
    }, [context?.headerRef?.current]);

    if (isMount) {
        return (
            <Portal container={context?.headerRef?.current}>
                {children}
            </Portal>
        );
    }

    return null;
};

export {
    MainLayout,
    MainLayoutHeaderPortal,
    MainLayoutBottomPortal,
};
