import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { useField, useFormikContext } from 'formik';
import { FC, useEffect } from 'react';
import { FiCheck } from 'react-icons/fi';
import { Typography } from '../../../utility';
import { Icon } from '../../../wrappers';
import {
    checkboxClasses,
    CheckboxClasses,
} from '../Checkbox/Checkbox.css';

export interface CheckboxSwitchFieldProps {
    label: string;
    name: string;
}

const CheckboxSwitchField: FC<
    CheckboxSwitchFieldProps & CheckboxClasses['theme']
> = ({ name, label, theme = 'primary' }) => {
    const { values, setFieldValue } = useFormikContext();

    const [field, , setters] = useField({
        name,
        type: 'checkbox',
    });

    useEffect(() => {
        const value = field.checked;
        const thisIsPropertyIntoFormikContext =
            typeof values === 'object' &&
            values !== null &&
            name in values;

        if (thisIsPropertyIntoFormikContext) {
            setters.setValue(value);
        } else {
            setFieldValue(field.name, value);
        }
    }, [field.checked]);

    const handleChange = (checked: boolean) => {
        setters.setValue(checked); // Update Formik state
        setters.setTouched(true);
    };

    return (
        <div
            className={`${checkboxClasses.root({
                theme,
            })}`}
        >
            <CheckboxPrimitive.Root
                id={`${name}`}
                className={checkboxClasses.checkbox}
                name={name}
                checked={field.value}
                onCheckedChange={handleChange}
            >
                <CheckboxPrimitive.Indicator>
                    <Icon
                        className={
                            checkboxClasses.indicator
                        }
                        size="20"
                    >
                        <FiCheck />
                    </Icon>
                </CheckboxPrimitive.Indicator>
            </CheckboxPrimitive.Root>
            <label htmlFor={`${name}`}>
                <Typography size="14">{label}</Typography>
            </label>
        </div>
    );
};

export { CheckboxSwitchField };
