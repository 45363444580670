import React, {
    FC,
    ReactNode,
    useState,
    useEffect,
} from 'react';
import { Drawer } from '@mega/utils';
import { Close } from '@mega/icons';
import {
    IconButton,
    Row,
    Button,
    StatePaginateTransform,
    StatePaginateTransformType,
} from '@mega/ui';
import {
    articleHistory,
    PaginatedHistory,
    CommitHistory,
} from '@mega/api';
import * as classes from './ArticleHistory.css';
import { useTable } from 'react-table';
import Pagination from '@mui/material/Pagination';
import {
    useArticleProgressDispatch,
    useSiteStore,
} from '@mega/store';
import { ArticleHistoryPreview } from './ArticleHistoryPreview';
import { format } from 'date-fns';
export interface ArticleHistoryProps {
    id: string;
    children: (props: {
        openArticleHistory?: () => void;
        closeArticleHistory?: () => void;
    }) => ReactNode | ReactNode[];
}

type TableHistoryData = {
    id: number;
    comment: string;
    dateCreate: string;
    userName: string;
};

interface ArticleHistoryTableProps {
    articleId: number;
    data: Array<CommitHistory>;
}

const ArticleHistoryTable: FC<ArticleHistoryTableProps> = (
    props,
) => {
    const articleId = props.articleId;
    const { clear } = useArticleProgressDispatch();
    const currentSite = useSiteStore();

    const data = React.useMemo(
        () =>
            props.data.map<TableHistoryData>((item) => ({
                id: item.id,
                comment: item.revision.comment,
                dateCreate: item.revision.date_created,
                userName: item.revision.user.label,
            })),
        [props.data],
    );
    const columns = React.useMemo(
        () => [
            {
                Header: '№',
                accessor: 'id', // accessor is the "key" in the data
            },
            {
                Header: 'Дата изменения',
                accessor: 'dateCreate',
                Cell: ({ value }: any) => {
                    return (
                        <span>
                            {format(
                                new Date(value),
                                'dd-MM-yyyy HH:mm',
                            )}
                        </span>
                    );
                },
            },
            {
                Header: 'Автор',
                accessor: 'userName',
                Cell: ({
                    cell: { value } = { value: '' },
                }) => {
                    return (
                        <span
                            style={{
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {value}
                        </span>
                    );
                },
            },
            {
                Header: 'Комментарий',
                accessor: 'comment',
                Cell: ({
                    cell: { value } = { value: '' },
                }) => {
                    return (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: value,
                            }}
                        ></div>
                    );
                },
            },
            {
                Header: 'Действие',
                Cell: (props: any) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '10px',
                                flexWrap: 'wrap',
                            }}
                        >
                            <ArticleHistoryPreview
                                id={articleId}
                                versionId={
                                    props?.cell?.row?.values
                                        ?.id
                                }
                            />
                            <Button
                                classes={{
                                    paper: {
                                        variant: 'outline',
                                    },
                                }}
                                label={
                                    'Откатить на эту версию'
                                }
                                onClick={async () => {
                                    if (
                                        props?.cell?.row
                                            ?.values?.id
                                    ) {
                                        try {
                                            const res =
                                                await articleHistory?.revert?.(
                                                    {
                                                        id: articleId,
                                                        version_pk: `${props?.cell?.row?.values?.id}`,
                                                    },
                                                    {
                                                        headers:
                                                            {
                                                                'x-site':
                                                                    currentSite?.id ||
                                                                    '',
                                                            },
                                                    },
                                                );

                                            if (res?.ok) {
                                                clear(
                                                    articleId,
                                                );
                                                location.reload();
                                            }
                                        } catch (e) {}
                                    }
                                }}
                            />
                        </div>
                    );
                },
            },
        ],
        [],
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        //@ts-ignore
    } = useTable({ columns, data });
    return (
        <table
            {...getTableProps()}
            className={classes.table}
        >
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className={classes.tr}
                    >
                        {headerGroup.headers.map(
                            (column) => (
                                <th
                                    {...column.getHeaderProps()}
                                    className={classes.th}
                                >
                                    {column.render(
                                        'Header',
                                    )}
                                </th>
                            ),
                        )}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <tr
                            {...row.getRowProps()}
                            className={classes.tr}
                        >
                            {row.cells.map((cell) => {
                                return (
                                    <td
                                        {...cell.getCellProps()}
                                        className={
                                            classes.td
                                        }
                                    >
                                        {cell.render(
                                            'Cell',
                                        )}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

const ArticleHistory: FC<ArticleHistoryProps> = ({
    id,
    children,
}) => {
    const perPage = 5;
    const [meta, setMeta] =
        useState<StatePaginateTransformType | null>(null);
    const [historyList, setHistoryList] = useState<
        Array<CommitHistory>
    >([]);
    const [isOpen, setIsOpen] = useState(false);
    const currentSite = useSiteStore();

    const handleClose = () => {
        setIsOpen(false);
    };
    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleGet = async (page: number) => {
        if (!isOpen) {
            return null;
        }
        try {
            const res = await articleHistory?.getList?.(
                {
                    id: parseInt(id),
                    //@ts-ignore
                    per_page: perPage,
                    is_count: true,
                    page,
                },
                {
                    headers: {
                        'x-site': currentSite?.id,
                    },
                },
            );
            if (res?.ok) {
                const data =
                    (await res.data) as unknown as PaginatedHistory;

                setHistoryList(data.results);
                setMeta(
                    new StatePaginateTransform(
                        data,
                        perPage,
                    ),
                );
            }
        } catch {}
    };

    useEffect(() => {
        handleGet(meta?.page || 1);
    }, [isOpen]);

    const handleChange = (_: any, value: number) => {
        handleGet(value);
    };

    return (
        <>
            {children({
                openArticleHistory: handleOpen,
                closeArticleHistory: handleClose,
            })}
            <Drawer
                anchor="right"
                open={isOpen}
                onClose={handleClose}
            >
                <div className={classes.drawer}>
                    <Row reverse>
                        <IconButton
                            animate="primary"
                            size="medium"
                            onClick={handleClose}
                        >
                            <Close />
                        </IconButton>
                    </Row>
                    <span className={classes.title}>
                        Версии статьи
                    </span>

                    <div>
                        <ArticleHistoryTable
                            articleId={parseInt(id)}
                            data={historyList}
                        />
                    </div>
                    {meta && (
                        <div
                            style={{
                                marginTop: 'auto',
                                height: '100%',
                                display: 'flex',
                                flexDirection:
                                    'column-reverse',
                                justifyContent: 'end',
                                alignItems: 'center',
                            }}
                        >
                            <Pagination
                                count={meta.pageCount}
                                variant="outlined"
                                shape="rounded"
                                page={meta.page}
                                onChange={handleChange}
                                disabled={
                                    meta.pageCount === 1
                                }
                            />
                        </div>
                    )}
                </div>
            </Drawer>
        </>
    );
};

export { ArticleHistory };
