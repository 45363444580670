import React, {
    ChangeEvent,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    inputTextareaClasses,
    InputTextareaClasses,
} from './InputTextarea.css';
import { useField, useFormikContext } from 'formik';

import { PaperClasses, paperClasses } from '@mega/styles';

export interface InputTextareaFieldProps {
    value?: string;
    name: string;
    rows?: number;
    placeholder?: string;
    onChange?: (e: ChangeEvent<any>) => void;
    textAlign?: 'center' | 'right' | 'left';
    className?: string;
    color?: string;
    variant?: 'gray';
    limit?: number;
    classes?: {
        paper?: PaperClasses['recipe'];
    };
    placeholderDefault?: string;
    dimension?: 'small' | 'medium' | 'large' | 'none';
    verticalTextCenter?: Boolean;
    isRequired?: boolean;
}

const limitList = {
    title: 120,
    subtitle: 90,
};

export const InputTextareaField: React.FC<
    InputTextareaFieldProps & InputTextareaClasses['theme']
> = (props) => {
    const {
        value,
        onChange,
        placeholder,
        name,
        rows = 1,
        textAlign,
        theme = 'primary',
        color,
        className,
        limit,
        classes,
        placeholderDefault,
        dimension = 'medium',
        verticalTextCenter = false,
        isRequired = false,
    } = props;
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [currentValue, setCurrentValue] = useState(value); // you can manage data with it
    const [field, meta] = useField<string>({
        name,
    });

    const { values, setFieldValue } = useFormikContext();

    const isError = Boolean(meta?.error);

    const [isFocus, setIsFocus] = useState(false);

    const placeholderMod =
        field.value !== '' ? true : isFocus;

    // const isShrink = placeholderMod ?

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const sizeRows = {
        small: 16,
        medium: 30,
        large: 40,
        none: 30,
    };

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = `${
                sizeRows[dimension] * rows
            }px`;
            const { scrollHeight } = textareaRef.current;
            textareaRef.current.style.height = `${scrollHeight}px`;
        }
    }, [currentValue, textareaRef]);

    const handleChange = (
        e: ChangeEvent<HTMLTextAreaElement>,
    ) => {
        setCurrentValue(e.target.value);
        onChange
            ? onChange(e)
            : setFieldValue(field.name, e.target.value);
    };

    const interceptValue = field?.value ?? currentValue;

    const maxLength = limit && limit;

    return (
        <div
            className={inputTextareaClasses.blockWithError}
        >
            <div
                className={[
                    inputTextareaClasses.inputWrapper({
                        isError: isError,
                    }),
                    paperClasses.recipe({
                        color: 'gray',
                        borderRadius: 'small',
                        variant: 'outlineFilled',
                        ...(classes?.paper ?? {}),
                    }),
                ].join(' ')}
            >
                <div
                    className={inputTextareaClasses.paperStyle(
                        {
                            isFocus:
                                placeholderMod &&
                                Boolean(placeholder),
                            dimension,
                        },
                    )}
                >
                    <textarea
                        ref={textareaRef}
                        value={field?.value || currentValue}
                        onChange={handleChange}
                        rows={rows}
                        name={name}
                        onFocus={() => setIsFocus(true)}
                        onBlur={() => setIsFocus(false)}
                        maxLength={maxLength}
                        placeholder={placeholderDefault}
                        className={`${inputTextareaClasses.root(
                            {
                                textAlign,
                                theme,
                                color,
                                verticalTextCenter,
                            },
                        )} ${className}`}
                    />
                    <div
                        className={inputTextareaClasses.placeholder(
                            {
                                isFocus: placeholderMod,
                            },
                        )}
                    >
                        {placeholder}
                        {isRequired ? (
                            <span
                                className={
                                    inputTextareaClasses.inpputTextareaIsRequired
                                }
                            >
                                *
                            </span>
                        ) : (
                            ''
                        )}
                        {maxLength && (
                            <p
                                className={
                                    inputTextareaClasses.textAreaPlaceholderCounter
                                }
                            >
                                {
                                    interceptValue?.toString()
                                        .length
                                }
                                /{maxLength}
                            </p>
                        )}
                    </div>
                </div>
            </div>
            {isError ? (
                <p
                    className={
                        inputTextareaClasses.errorText
                    }
                >
                    {meta?.error}
                </p>
            ) : null}
        </div>
    );
};
