import { authors, categories, tags } from '@mega/api';
import Modal from '@mui/material/Modal';
import Popover from '@mui/material/Popover';
import Portal from '@mui/material/Portal';
import TextareaAutosize from '@mui/material/TextareaAutosize';

function delay(ms: number) {
    return new Promise((resolve) =>
        setTimeout(resolve, ms),
    );
}
const fmtMSS = (s: number) =>
    (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;

const handleGetOptions =
    ({
        site,
        apiEntity,
    }: {
        site?: string;
        apiEntity:
            | (typeof authors)['getOptions']
            | (typeof categories)['getOptions']
            | (typeof tags)['getOptions'];
    }) =>
    async (inputValue: string) => {
        const responce = await apiEntity?.({
            //@ts-ignore
            site,
            search:
                inputValue === '' ? undefined : inputValue,
        });
        if (responce?.ok) {
            return (
                responce?.data?.results?.map((item) => ({
                    label: item.label || '',
                    value: item.id || 1,
                })) ?? []
            );
        } else {
            return [];
        }
    };

export {
    Modal,
    Popover,
    Portal,
    TextareaAutosize,
    delay,
    fmtMSS,
    handleGetOptions,
};
