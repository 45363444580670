import React, { FC } from 'react';

import { compact, take } from 'lodash-es';
import {
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';

import {
    Bread,
    Crumbs,
    HeadTemplate,
    MainLayoutHeaderPortal,
} from '@mega/ui';
import { Home } from '@mega/icons';
import { useSettings } from '@mega/store';
interface HeaderProps {}

const actionMap: {
    [key: string]: string;
} = {
    change: 'Редактирование',
    view: 'Просмотр',
    add: 'Создание',
    smartgrid: 'Умная сетка PROTOTYPE',
};

const Header: FC<HeaderProps> = () => {
    const { siteslug, entities, action, id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { permissionConfig } = useSettings();

    const breadcrumbs = compact([
        siteslug,
        entities,
        action,
        id,
    ]);

    return (
        <MainLayoutHeaderPortal>
            <HeadTemplate
                label={
                    id ||
                    actionMap?.[`${action}`] ||
                    permissionConfig?.[`${entities}`]
                        ?.verbose_name_plural ||
                    'Главная'
                }
                breadcrumbs={() => {
                    return (
                        <Bread
                            separator={<span>/</span>}
                            startIcon={<Home />}
                        >
                            {breadcrumbs?.map(
                                (label, index) => {
                                    const path = take(
                                        breadcrumbs,
                                        index + 1,
                                    ).reduce(
                                        (
                                            acc,
                                            item,
                                            index,
                                        ) => {
                                            if (
                                                index === 0
                                            ) {
                                                return (
                                                    acc +
                                                    item
                                                );
                                            }
                                            return (
                                                acc +
                                                `/${item}`
                                            );
                                        },
                                        '/',
                                    );

                                    return (
                                        <Crumbs
                                            key={label}
                                            onClick={() => {
                                                navigate(
                                                    path,
                                                    {
                                                        state: location,
                                                    },
                                                );
                                            }}
                                        >
                                            <span>
                                                {index === 0
                                                    ? 'Главная'
                                                    : permissionConfig?.[
                                                          label
                                                      ]
                                                          ?.verbose_name_plural ??
                                                      actionMap?.[
                                                          label
                                                      ] ??
                                                      label}
                                            </span>
                                        </Crumbs>
                                    );
                                },
                            )}
                        </Bread>
                    );
                }}
            />
        </MainLayoutHeaderPortal>
    );
};

export { Header };
