import React, { FC } from 'react';
import { Time } from '@mega/icons';
import dayjs from 'dayjs';
import { Indi } from '@mega/ui';
import { classes } from './UrgentCell.css';
import type { InfounittaskArray as InfoUnitType } from '@mega/api';
const deadline = {
    '2h': 'до 2 часов',
    '3h': 'В ближайшее время',
    '6h': 'до 6 часов',
    '12h': 'до 12 часов',
    '24h': 'В течение дня',
    urgent: 'Молния',
    not_urgent: 'эмбарго',
};

const mapDeadline = new Map(Object.entries(deadline));

interface UrgentProps {
    embargo?: string;
    urgency: any;
    center?: boolean;
}

export const Urgent: FC<UrgentProps> = ({
    embargo,
    urgency,
    center = false,
}) => {
    return (
        <div className={classes.transfer({ center })}>
            {embargo ? (
                <Indi
                    label={() => (
                        <div
                            className={classes.wrapEmbargo}
                        >
                            <Time />
                            {`Эмбарго ${dayjs(
                                embargo,
                            ).format(
                                'D.MM.YYYY до HH:mm',
                            )}`}
                        </div>
                    )}
                    colors={'wip'}
                />
            ) : (
                <Indi
                    label={mapDeadline.get(urgency)}
                    colors={urgency}
                />
            )}
        </div>
    );
};
