import * as CLIENT from '@mega/api';
import {
    Copyright,
    CopyrightCreate,
    CopyrightUpdate,
} from '@mega/api';
import { createModel } from '@rematch/core';
import { DispatchEffectWithDependency } from '../type';

import type { RootModel } from '../rootModel';

export interface CopyrightCreateEffect
    extends DispatchEffectWithDependency<CopyrightCreate> {}

export interface CopyrightUpdateEffect
    extends DispatchEffectWithDependency<CopyrightUpdate> {
    query: {
        id: number;
    };
}

const copyright = createModel<RootModel>()({
    name: 'copyright',
    state: {} as Copyright,
    reducers: {
        put: (state, payload: Copyright) => {
            return { ...payload };
        },
    },
    effects: (dispatch) => ({
        get: async (params: { id: number }, store) => {
            try {
                const response =
                    await CLIENT.copyrights.getById?.(
                        {
                            ...params,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    dispatch.copyright.put(response.data);
                }
            } catch (e) {
                if (
                    CLIENT.copyrights.getById &&
                    e instanceof
                        CLIENT.copyrights.getById.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },
        create: async (
            { payload, options }: CopyrightCreateEffect,
            store,
        ) => {
            try {
                const response =
                    await CLIENT.copyrights?.create?.(
                        {
                            ...payload,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    if (options?.router) {
                        const { navigate, location } =
                            options.router;
                        navigate(
                            `/${
                                store.site.site?.id || ''
                            }/copyright/change/${
                                response.data.id
                            }`,
                            {
                                state: location,
                            },
                        );
                    }
                    if (options?.notifier) {
                        const { enqueueSnackbar } =
                            options.notifier;
                        enqueueSnackbar(
                            'Новый копирайт добавлен',
                            {
                                variant: 'success',
                            },
                        );
                    }
                }
            } catch (e) {
                if (
                    CLIENT.copyrights.create &&
                    e instanceof
                        CLIENT.copyrights.create.Error
                ) {
                    const error = e.getActualType();

                    if (options?.notifier) {
                        const { enqueueSnackbar } =
                            options.notifier;

                        const errormsgs = Object.values(
                            error.data,
                        );

                        errormsgs?.map((msg) => {
                            enqueueSnackbar(msg as string, {
                                variant: 'error',
                            });
                        });
                    }
                    return error.data;
                }
            }
        },
        update: async (
            {
                query,
                payload,
                options,
            }: CopyrightUpdateEffect,
            store,
        ) => {
            try {
                const response =
                    await CLIENT.copyrights?.updatePartialById?.(
                        {
                            ...payload,
                            id: query.id,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    dispatch.copyright.put(response.data);
                    if (options?.notifier) {
                        const { enqueueSnackbar } =
                            options.notifier;
                        enqueueSnackbar(
                            'Копирайт обновлен',
                        );
                    }
                }
            } catch (e) {
                if (
                    CLIENT.copyrights.updatePartialById &&
                    e instanceof
                        CLIENT.copyrights.updatePartialById
                            .Error
                ) {
                    const error = e.getActualType();
                    if (
                        options?.notifier?.enqueueSnackbar
                    ) {
                        const { enqueueSnackbar } =
                            options.notifier;

                        const errormsgs = Object.values(
                            error.data,
                        );

                        errormsgs?.map((msg) => {
                            enqueueSnackbar(msg as string, {
                                variant: 'error',
                            });
                        });
                    }
                    return error.data;
                }
            }
        },
        remove: async (id: number, store) => {
            const query = store.copyrights.query;

            try {
                const response =
                    await CLIENT?.copyrights?.deleteById?.({
                        id,
                    });
                if (response?.ok) {
                    dispatch.copyrights.get(query);
                }
            } catch {}
        },
    }),
});

export { copyright };
export default copyright;
