import React from 'react';
import { Control } from '../../../../types/core';
import { FlowRow } from './FlowRow';
import {
    FlowRowEnum,
    FlowRowPluginDefault,
} from './FlowRow.types';

const PLUGIN_FLOW_ROW_NAME = FlowRowEnum.type;

const DEFAULT_FLOW_ROW: FlowRowPluginDefault = {
    type: PLUGIN_FLOW_ROW_NAME,
    meta: {},
    isContainer: true,
    childrenElement: [],
};

const PLUGIN_FLOW_ROW = {
    [PLUGIN_FLOW_ROW_NAME]: {
        emptyObject: DEFAULT_FLOW_ROW,
        render: FlowRow,
    },
};

const PLUGIN_FLOW_ROW_CONTROL: Control = {
    name: PLUGIN_FLOW_ROW_NAME,
    label: 'Flow Row',
    tooltip: 'Создать Flow Row',
    Icon: () => <></>,
};

export {
    DEFAULT_FLOW_ROW,
    PLUGIN_FLOW_ROW,
    PLUGIN_FLOW_ROW_CONTROL,
    PLUGIN_FLOW_ROW_NAME,
};
export default PLUGIN_FLOW_ROW;
