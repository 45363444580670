import React, { FC, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import {
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import {
    useDocumentDispatch,
    useDocumentStore,
} from '@mega/store';
import { Document } from '@mega/api';
import { FormikHelpers } from 'formik';
import { isNull } from 'lodash-es';
import { DocumentCreate } from '@mega/forms/src/Document';

export const DocumentsEdit: FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { loading, store } = useDocumentStore();
    const { get, update } = useDocumentDispatch();
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (id) {
            get({ id: parseInt(id) });
        }
    }, [id, get]);
    if (loading.get.loading || !loading.get.success) {
        return <div>loading</div>;
    }
    let editedId: number;
    if (id) {
        editedId = parseInt(id);
    } else {
        throw new Error('The editable entity has no id.');
    }

    const handleUpdate = async (
        payload: Document,
        actions: FormikHelpers<Document>,
    ) => {
        const formData = new FormData();
        for (const key in payload) {
            if (
                Object.prototype.hasOwnProperty.call(
                    payload,
                    key,
                )
            ) {
                if (
                    key === 'file' &&
                    typeof payload[key] === 'string'
                ) {
                    continue;
                }
                //@ts-ignore
                if (isNull(payload[key])) {
                    continue;
                }
                // @ts-ignore
                else formData.append(key, payload[key]);
            }
        }
        await update({
            query: {
                id: editedId,
            },
            // @ts-ignore
            payload: formData,
            options: {
                notifier: {
                    enqueueSnackbar,
                },
                router: {
                    navigate,
                    location,
                },
            },
        });
        actions.setSubmitting(false);
    };
    return (
        <DocumentCreate
            initialValue={store}
            handleUpdate={handleUpdate}
        />
    );
};
