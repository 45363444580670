import { useState, useEffect, useCallback } from 'react';
import * as CLIENT from '@mega/api';
import { ImgMeta } from '@mega/editor/src/plugins/Img/Img.types';

export const useFetchPreviewImage = (
    image: ImgMeta | null,
) => {
    const [preview, setPreview] = useState<
        Record<string, string>
    >({});
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const fetchPreviewImage = useCallback(
        async (id: number, fp_x = 0.5, fp_y = 0.5) => {
            setIsLoading(true);
            setError(null);
            try {
                if (
                    typeof CLIENT.images.getPreviewById !==
                    'function'
                ) {
                    throw new Error(
                        'getPreviewById function is not available',
                    );
                }
                const { data } =
                    await CLIENT.images.getPreviewById({
                        id,
                        fp_x,
                        fp_y,
                    });
                setPreview(data as Record<string, string>);
            } catch (err) {
                setError(
                    err instanceof Error
                        ? err
                        : new Error(
                              'Failed to fetch preview image',
                          ),
                );
            } finally {
                setIsLoading(false);
            }
        },
        [],
    );

    useEffect(() => {
        if (image?.id) {
            fetchPreviewImage(
                image.id,
                image.fp_x,
                image.fp_y,
            );
        }
    }, [
        image?.id,
        image?.is_watermarked,
        image?.fp_x,
        image?.fp_y,
    ]);

    return { preview, isLoading, error };
};
