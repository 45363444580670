import React, { FC, PropsWithChildren } from 'react';
import { components, MultiValueProps } from 'react-select';

interface MultiRemoveClass {
    className: string;
}

type MultiValueOverideProps = MultiValueProps<
    Record<string, unknown>,
    true
>;

const MultiValue =
    ({ className }: MultiRemoveClass) =>
    ({
        ...props
    }: PropsWithChildren<MultiValueOverideProps>) =>
        (
            <components.MultiValue
                {...props}
                innerProps={{
                    className,
                }}
            ></components.MultiValue>
        );

export { MultiValue };
export default MultiValue;
