// import type { ElementNode, LexicalEditor } from 'lexical';
import {
    ReactPortal,
    useCallback,
    useEffect,
    useRef,
    useState,
    MouseEvent,
} from 'react';
import {
    $getRoot,
    $getSelection,
    $isRangeSelection,
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalEditable } from '@lexical/react/useLexicalEditable';
import {
    $deleteTableColumn__EXPERIMENTAL,
    $deleteTableRow__EXPERIMENTAL,
    $getTableCellNodeFromLexicalNode,
    $getTableColumnIndexFromTableCellNode,
    $getTableNodeFromLexicalNodeOrThrow,
    $getTableRowIndexFromTableCellNode,
    $insertTableColumn__EXPERIMENTAL,
    $insertTableRow__EXPERIMENTAL,
    $isTableCellNode,
    $isTableRowNode,
    $isTableSelection,
    getTableObserverFromTableElement,
    HTMLTableElementWithWithTableSelectionState,
    TableCellHeaderStates,
    TableCellNode,
    TableRowNode,
    TableSelection,
} from '@lexical/table';

import { createPortal } from 'react-dom';

import { Menu } from './widgets/Menu';
import { CellAction } from './widgets/CellAction';

function computeSelectionCount(selection: TableSelection): {
    columns: number;
    rows: number;
} {
    const selectionShape = selection.getShape();
    return {
        columns:
            selectionShape.toX - selectionShape.fromX + 1,
        rows: selectionShape.toY - selectionShape.fromY + 1,
    };
}

type TableCellActionMenuProps = Readonly<{
    contextRef: { current: null | HTMLElement };
    onClose: () => void;
    setIsMenuOpen: (isOpen: boolean) => void;
    tableCellNode: TableCellNode;
}>;

function TableActionMenu({
    onClose,
    tableCellNode: _tableCellNode,
    setIsMenuOpen,
    contextRef,
}: TableCellActionMenuProps) {
    const [editor] = useLexicalComposerContext();
    const dropDownRef = useRef<HTMLDivElement | null>(null);
    const [tableCellNode, updateTableCellNode] =
        useState(_tableCellNode);
    const [selectionCounts, updateSelectionCounts] =
        useState({
            columns: 1,
            rows: 1,
        });

    useEffect(() => {
        return editor.registerMutationListener(
            TableCellNode,
            (nodeMutations) => {
                const nodeUpdated =
                    nodeMutations.get(
                        tableCellNode.getKey(),
                    ) === 'updated';

                if (nodeUpdated) {
                    editor.getEditorState().read(() => {
                        updateTableCellNode(
                            tableCellNode.getLatest(),
                        );
                    });
                }
            },
            { skipInitialization: true },
        );
    }, [editor, tableCellNode]);

    useEffect(() => {
        editor.getEditorState().read(() => {
            const selection = $getSelection();
            // Merge cells
            if ($isTableSelection(selection)) {
                updateSelectionCounts(
                    computeSelectionCount(selection),
                );
            }
        });
    }, [editor]);

    useEffect(() => {
        const menuButtonElement = contextRef.current;
        const dropDownElement = dropDownRef.current;
        const rootElement = editor.getRootElement();

        if (
            menuButtonElement != null &&
            dropDownElement != null &&
            rootElement != null
        ) {
            const rootEleRect =
                rootElement.getBoundingClientRect();
            const menuButtonRect =
                menuButtonElement.getBoundingClientRect();
            dropDownElement.style.opacity = '1';
            const dropDownElementRect =
                dropDownElement.getBoundingClientRect();

            const margin = 5;
            let leftPosition =
                menuButtonRect.right + margin;
            if (
                leftPosition + dropDownElementRect.width >
                    window.innerWidth ||
                leftPosition + dropDownElementRect.width >
                    rootEleRect.right
            ) {
                const position =
                    menuButtonRect.left -
                    dropDownElementRect.width -
                    margin;
                leftPosition =
                    (position < 0 ? margin : position) +
                    window.scrollX;
            }
            dropDownElement.style.left = `${
                leftPosition + window.scrollX
            }px`;

            let topPosition = menuButtonRect.top;
            if (
                topPosition + dropDownElementRect.height >
                window.innerHeight
            ) {
                const position =
                    menuButtonRect.bottom -
                    dropDownElementRect.height;
                topPosition =
                    (position < 0 ? margin : position) +
                    window.scrollY;
            }
            dropDownElement.style.top = `${
                topPosition + +window.scrollY
            }px`;
        }
    }, [contextRef, dropDownRef, editor]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                dropDownRef.current != null &&
                contextRef.current != null &&
                !dropDownRef.current.contains(
                    event.target as Node,
                ) &&
                !contextRef.current.contains(
                    event.target as Node,
                )
            ) {
                setIsMenuOpen(false);
            }
        }

        window.addEventListener(
            'click',
            //@ts-ignore
            handleClickOutside,
        );

        return () =>
            window.removeEventListener(
                'click',
                //@ts-ignore
                handleClickOutside,
            );
    }, [setIsMenuOpen, contextRef]);

    const clearTableSelection = useCallback(() => {
        editor.update(() => {
            if (tableCellNode.isAttached()) {
                const tableNode =
                    $getTableNodeFromLexicalNodeOrThrow(
                        tableCellNode,
                    );
                const tableElement = editor.getElementByKey(
                    tableNode.getKey(),
                ) as HTMLTableElementWithWithTableSelectionState;

                if (!tableElement) {
                    throw new Error(
                        'Expected to find tableElement in DOM',
                    );
                }

                const tableSelection =
                    getTableObserverFromTableElement(
                        tableElement,
                    );
                if (tableSelection !== null) {
                    tableSelection.clearHighlight();
                }

                tableNode.markDirty();
                updateTableCellNode(
                    tableCellNode.getLatest(),
                );
            }

            const rootNode = $getRoot();
            rootNode.selectStart();
        });
    }, [editor, tableCellNode]);

    const insertTableRowAtSelection = useCallback(
        (shouldInsertAfter: boolean) => {
            editor.update(() => {
                $insertTableRow__EXPERIMENTAL(
                    shouldInsertAfter,
                );
                onClose();
            });
        },
        [editor, onClose],
    );

    const insertTableColumnAtSelection = useCallback(
        (shouldInsertAfter: boolean) => {
            editor.update(() => {
                for (
                    let i = 0;
                    i < selectionCounts.columns;
                    i++
                ) {
                    $insertTableColumn__EXPERIMENTAL(
                        shouldInsertAfter,
                    );
                }
                onClose();
            });
        },
        [editor, onClose, selectionCounts.columns],
    );

    const deleteTableRowAtSelection = useCallback(() => {
        editor.update(() => {
            $deleteTableRow__EXPERIMENTAL();
            onClose();
        });
    }, [editor, onClose]);

    const deleteTableAtSelection = useCallback(() => {
        editor.update(() => {
            const tableNode =
                $getTableNodeFromLexicalNodeOrThrow(
                    tableCellNode,
                );
            tableNode.remove();

            clearTableSelection();
            onClose();
        });
    }, [
        editor,
        tableCellNode,
        clearTableSelection,
        onClose,
    ]);

    const deleteTableColumnAtSelection = useCallback(() => {
        editor.update(() => {
            $deleteTableColumn__EXPERIMENTAL();
            onClose();
        });
    }, [editor, onClose]);

    const toggleTableRowIsHeader = useCallback(() => {
        editor.update(() => {
            const tableNode =
                $getTableNodeFromLexicalNodeOrThrow(
                    tableCellNode,
                );

            const tableRowIndex =
                $getTableRowIndexFromTableCellNode(
                    tableCellNode,
                );

            const tableRows = tableNode.getChildren();

            if (
                tableRowIndex >= tableRows.length ||
                tableRowIndex < 0
            ) {
                throw new Error(
                    'Expected table cell to be inside of table row.',
                );
            }

            const tableRow = tableRows[tableRowIndex];

            if (!$isTableRowNode(tableRow)) {
                throw new Error('Expected table row');
            }

            tableRow.getChildren().forEach((tableCell) => {
                if (!$isTableCellNode(tableCell)) {
                    throw new Error('Expected table cell');
                }

                tableCell.toggleHeaderStyle(
                    TableCellHeaderStates.ROW,
                );
            });

            clearTableSelection();
            onClose();
        });
    }, [
        editor,
        tableCellNode,
        clearTableSelection,
        onClose,
    ]);

    const toggleTableColumnIsHeader = useCallback(() => {
        editor.update(() => {
            const tableNode =
                $getTableNodeFromLexicalNodeOrThrow(
                    tableCellNode,
                );

            const tableColumnIndex =
                $getTableColumnIndexFromTableCellNode(
                    tableCellNode,
                );

            const tableRows =
                tableNode.getChildren<TableRowNode>();

            const maxRowsLength = Math.max(
                ...tableRows.map(
                    (row) => row.getChildren().length,
                ),
            );

            if (
                tableColumnIndex >= maxRowsLength ||
                tableColumnIndex < 0
            ) {
                throw new Error(
                    'Expected table cell to be inside of table row.',
                );
            }

            for (let r = 0; r < tableRows.length; r++) {
                const tableRow = tableRows[r];

                if (!$isTableRowNode(tableRow)) {
                    throw new Error('Expected table row');
                }

                const tableCells = tableRow.getChildren();
                if (tableColumnIndex >= tableCells.length) {
                    continue;
                }

                const tableCell =
                    tableCells[tableColumnIndex];

                if (!$isTableCellNode(tableCell)) {
                    throw new Error('Expected table cell');
                }

                tableCell.toggleHeaderStyle(
                    TableCellHeaderStates.COLUMN,
                );
            }

            clearTableSelection();
            onClose();
        });
    }, [
        editor,
        tableCellNode,
        clearTableSelection,
        onClose,
    ]);

    return createPortal(
        <Menu
            onClose={onClose}
            dropDownRef={dropDownRef}
            tableCellNode={tableCellNode}
            selectionCounts={selectionCounts}
            insertTableRowAtSelection={
                insertTableRowAtSelection
            }
            insertTableColumnAtSelection={
                insertTableColumnAtSelection
            }
            deleteTableRowAtSelection={
                deleteTableRowAtSelection
            }
            deleteTableAtSelection={deleteTableAtSelection}
            deleteTableColumnAtSelection={
                deleteTableColumnAtSelection
            }
            toggleTableRowIsHeader={toggleTableRowIsHeader}
            toggleTableColumnIsHeader={
                toggleTableColumnIsHeader
            }
        />,
        document.body,
    );
}

function TableCellActionMenuContainer({
    anchorElem,
}: {
    anchorElem: HTMLElement;
}): JSX.Element {
    const [editor] = useLexicalComposerContext();

    const menuButtonRef = useRef(null);
    const menuRootRef = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [tableCellNode, setTableMenuCellNode] =
        useState<TableCellNode | null>(null);

    const $moveMenu = useCallback(() => {
        const menu = menuButtonRef.current;
        const selection = $getSelection();
        const nativeSelection = window.getSelection();
        const activeElement = document.activeElement;

        if (selection == null || menu == null) {
            setTableMenuCellNode(null);
            return;
        }

        const rootElement = editor.getRootElement();

        if (
            $isRangeSelection(selection) &&
            rootElement !== null &&
            nativeSelection !== null &&
            rootElement.contains(nativeSelection.anchorNode)
        ) {
            const tableCellNodeFromSelection =
                $getTableCellNodeFromLexicalNode(
                    selection.anchor.getNode(),
                );

            if (tableCellNodeFromSelection == null) {
                setTableMenuCellNode(null);
                return;
            }

            const tableCellParentNodeDOM =
                editor.getElementByKey(
                    tableCellNodeFromSelection.getKey(),
                );

            if (tableCellParentNodeDOM == null) {
                setTableMenuCellNode(null);
                return;
            }

            setTableMenuCellNode(
                tableCellNodeFromSelection,
            );
        } else if (!activeElement) {
            setTableMenuCellNode(null);
        }
    }, [editor]);

    useEffect(() => {
        return editor.registerUpdateListener(() => {
            editor.getEditorState().read(() => {
                $moveMenu();
            });
        });
    });

    useEffect(() => {
        const menuButtonDOM =
            menuButtonRef.current as HTMLButtonElement | null;

        if (
            menuButtonDOM != null &&
            tableCellNode != null
        ) {
            const tableCellNodeDOM = editor.getElementByKey(
                tableCellNode.getKey(),
            );

            if (tableCellNodeDOM != null) {
                const tableCellRect =
                    tableCellNodeDOM.getBoundingClientRect();
                const menuRect =
                    menuButtonDOM.getBoundingClientRect();
                const anchorRect =
                    anchorElem.getBoundingClientRect();

                const top =
                    tableCellRect.top - anchorRect.top + 4;

                const left =
                    tableCellRect.right -
                    menuRect.width -
                    10 -
                    anchorRect.left;

                menuButtonDOM.style.opacity = '1';
                menuButtonDOM.style.transform = `translate(${left}px, ${top}px)`;
            } else {
                menuButtonDOM.style.opacity = '0';
                menuButtonDOM.style.transform =
                    'translate(-10000px, -10000px)';
            }
        }
    }, [menuButtonRef, tableCellNode, editor, anchorElem]);

    const prevTableCellDOM = useRef(tableCellNode);

    useEffect(() => {
        if (prevTableCellDOM.current !== tableCellNode) {
            setIsMenuOpen(false);
        }

        prevTableCellDOM.current = tableCellNode;
    }, [prevTableCellDOM, tableCellNode]);

    return (
        <div
            style={{
                position: 'absolute',
                top: '0',
                left: '0',
                willChange: 'transform',
            }}
            ref={menuButtonRef}
        >
            {tableCellNode != null && (
                <>
                    <CellAction
                        ref={menuRootRef}
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsMenuOpen(!isMenuOpen);
                        }}
                    />

                    {isMenuOpen && (
                        <TableActionMenu
                            contextRef={menuRootRef}
                            setIsMenuOpen={setIsMenuOpen}
                            onClose={() =>
                                setIsMenuOpen(false)
                            }
                            tableCellNode={tableCellNode}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export default function TableActionMenuPlugin({
    anchorElem = document.body,
}: {
    anchorElem?: HTMLElement;
    cellMerge?: boolean;
}): null | ReactPortal {
    const isEditable = useLexicalEditable();
    return createPortal(
        isEditable ? (
            <TableCellActionMenuContainer
                anchorElem={anchorElem}
            />
        ) : null,
        anchorElem,
    );
}
