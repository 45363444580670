import React, { PropsWithChildren } from 'react';
import {
    components,
    MultiValueGenericProps,
} from 'react-select';

interface MultiValueTestProps {
    className?: string;
}
export type MultiLabelOverideProps = MultiValueGenericProps<
    Record<string, unknown>,
    true
>;

const MultiValueLabel =
    ({ className = '' }: MultiValueTestProps) =>
    ({
        ...props
    }: PropsWithChildren<MultiLabelOverideProps>) =>
        (
            <components.MultiValueLabel
                {...props}
                innerProps={{
                    className,
                }}
            />
        );

export { MultiValueLabel };
export default MultiValueLabel;
