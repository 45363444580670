import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgError = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 81 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.5 17.054c-12.673 0-22.946 10.273-22.946 22.946 0 12.673 10.273 22.946 22.946 22.946 12.672 0 22.945-10.273 22.945-22.946 0-12.673-10.273-22.946-22.945-22.946ZM13.833 40c0-14.728 11.94-26.667 26.667-26.667 14.727 0 26.666 11.94 26.666 26.667 0 14.728-11.939 26.667-26.666 26.667-14.728 0-26.667-11.94-26.667-26.667Z"
            fill="#C7C7C7"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.983 32.483a1.86 1.86 0 0 1 2.63 0l4.887 4.886 4.886-4.886a1.86 1.86 0 1 1 2.63 2.631L43.132 40l4.886 4.886a1.86 1.86 0 1 1-2.632 2.631L40.5 42.631l-4.886 4.886a1.86 1.86 0 1 1-2.631-2.631L37.869 40l-4.886-4.886a1.86 1.86 0 0 1 0-2.631Z"
            fill="#C7C7C7"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgError);
export default ForwardRef;
