import type { PluginTemplate } from '@apostroph/types';

export enum HtmlTextEnum {
    type = 'HTML_TEXT',
}

export interface HtmlTextProps {
    id: string;
    type: string;
    parentId: string | null;
}

export type HtmlTextMeta = {
    html: string;
};

export type HtmlTextPlugin = PluginTemplate<
    HtmlTextEnum.type,
    HtmlTextMeta
>;
export type HtmlTextPluginDefault = Omit<
    HtmlTextPlugin,
    'id'
>;
