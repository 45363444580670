/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
    Control,
    PositionsVariants,
} from '@apostroph/types';
import { Lex } from './Lex';
import { LexPluginDefault, LexEnum } from './Lex.types';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
const PLUGIN_LEX_NAME = LexEnum.type;

const DEFAULT_LEX: LexPluginDefault = {
    type: PLUGIN_LEX_NAME,
    meta: {
        content: '',
    },
    childrenElement: [],
};

const PLUGIN_LEX = {
    [PLUGIN_LEX_NAME]: {
        emptyObject: DEFAULT_LEX,
        render: Lex,
    },
};

const PLUGIN_LEX_CONTROL: Control = {
    name: PLUGIN_LEX_NAME,
    label: 'Текстовый редактор',
    tooltip: 'Создать текстовый редактор',
    position: PositionsVariants.actionFeed,
    Icon: () => <TextSnippetIcon />,
};

export {
    DEFAULT_LEX,
    PLUGIN_LEX,
    PLUGIN_LEX_CONTROL,
    PLUGIN_LEX_NAME,
};
export default PLUGIN_LEX;
