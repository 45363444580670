/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
    Control,
    PositionsVariants,
} from '@apostroph/types';
import { HorizontalLine } from './HorizontalLine';
import {
    HorizontalLinePluginDefault,
    HorizontalLineEnum,
} from './HorizontalLine.types';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
const PLUGIN_HORIZONTAL_LINE_NAME = HorizontalLineEnum.type;

const DEFAULT_HORIZONTAL_LINE: HorizontalLinePluginDefault =
    {
        type: PLUGIN_HORIZONTAL_LINE_NAME,
        meta: {
            marginTop: 'none',
            marginBottom: 'none',
        },
        childrenElement: [],
    };

const PLUGIN_HORIZONTAL_LINE = {
    [PLUGIN_HORIZONTAL_LINE_NAME]: {
        emptyObject: DEFAULT_HORIZONTAL_LINE,
        render: HorizontalLine,
    },
};

const PLUGIN_HORIZONTAL_LINE_CONTROL: Control = {
    name: PLUGIN_HORIZONTAL_LINE_NAME,
    label: 'Горизонтальная линия',
    tooltip: 'Создать горизонтальную линию',
    position: PositionsVariants.actionFeed,
    Icon: () => <HorizontalRuleIcon />,
};

export {
    DEFAULT_HORIZONTAL_LINE,
    PLUGIN_HORIZONTAL_LINE,
    PLUGIN_HORIZONTAL_LINE_CONTROL,
    PLUGIN_HORIZONTAL_LINE_NAME,
};
