/* eslint-disable @typescript-eslint/naming-convention */

export type pluginChildren = Array<string>;
export type uuid = string;

export enum EnumCoverType {
    nocover = 'nocover',
    photocover = 'photocover',
    videocover = 'videocover',
}

export enum EnumLabelType {
    nocover = 'Без обложки',
    photocover = 'Фотообложка',
    videocover = 'Видеообложка',
}

export enum EnumViewType {
    vertical = 'vertical',
    horizontal = 'horizontal',
}

export enum EnumViewLabelType {
    vertical = 'Вертикальный',
    horizontal = 'Горизонтальный',
}

export type CoverType = {
    id: EnumCoverType;
    label: EnumLabelType;
};
export type ViewType = {
    id: EnumViewType;
    label: EnumViewLabelType;
};
export type CoverTypes = Record<EnumCoverType, CoverType>;
export type ViewTypes = Record<EnumViewType, ViewType>;
