import React from 'react';
import { vkEmbed } from '@mega/utils';

export const VkEmbed = ({ html }: { html: string }) => {
    React.useEffect(() => {
        vkEmbed();
    }, []);

    const parse = html.match(/VK.Widgets.Post\((.*?)\)/);

    return (
        <div
            id="vkEmbed"
            data-vk-source={parse?.[1]}
            className={`article-vk-sharing`}
        />
    );
};
