import React, { FC, useState } from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import {
    Button,
    FormLayout,
    InputField,
    InputSelectMultiField,
    Paper,
    Stack,
    WithLabel,
} from '@mega/ui';
import { getOptionsSites } from '@mega/core';
import { AuthorFormDataCreate } from '@mega/types';
import { useAuthor } from '@mega/store';

export interface AuthorCreateProps {
    initialValue?: {
        name: string;
        sites: Array<{
            label: string;
            id: string;
        }>;
    };
}

const AuthorCreateActions = () => {
    const { submitForm, isSubmitting } = useFormikContext();

    return (
        <Paper
            style={{
                borderTop: '1px solid #EAEAEA',
            }}
            fullWidth
            color={'white'}
            variant="filled"
            classes={{
                padding: { size: '24x24' },
            }}
        >
            <Button
                classes={{
                    paper: {
                        variant: 'filled',
                        color: 'dark',
                    },
                }}
                color="secondary"
                size={'large'}
                type={'submit'}
                label={'Создать'}
                onClick={submitForm}
                disabled={isSubmitting}
            />
        </Paper>
    );
};

const AuthorCreate: FC<AuthorCreateProps> = ({
    initialValue = { name: '', sites: [] },
}) => {
    const { createFromForm } = useAuthor();
    return (
        <Formik
            validationSchema={Yup.object({
                name: Yup.string()
                    .min(
                        1,
                        'Это поле должно быть больше одного символа',
                    )
                    .required('Это поле обязательное'),
                sites: Yup.array().min(
                    1,
                    'Вы должны добавить хоть один сайт',
                ),
            })}
            initialValues={
                {
                    name: initialValue.name || '',
                    sites: initialValue.sites || [],
                } as AuthorFormDataCreate
            }
            onSubmit={createFromForm}
        >
            <Form>
                <FormLayout
                    actions={<AuthorCreateActions />}
                >
                    <Paper
                        variant="outline"
                        color="white"
                        classes={{
                            padding: { size: '24x24' },
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '500px',
                            }}
                        >
                            <Stack gap="18">
                                <InputField
                                    type="text"
                                    name="name"
                                    placeholder="Имя Фамилия"
                                    dimension="medium"
                                />
                                <WithLabel
                                    id={'sites'}
                                    title="Сайты"
                                >
                                    <InputSelectMultiField
                                        classes={{
                                            paper: {
                                                color: 'dark',
                                            },
                                            select: {
                                                variant:
                                                    'secondary',
                                            },
                                        }}
                                        name="sites"
                                        handleLoader={getOptionsSites()}
                                    />
                                </WithLabel>
                            </Stack>
                        </div>
                    </Paper>
                </FormLayout>
            </Form>
        </Formik>
    );
};
export { AuthorCreate };
