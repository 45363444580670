import { MouseEvent, forwardRef } from 'react';
import { Edit } from '@mega/icons';
import classes from './CellAction.css';
const CellAction = forwardRef(function CellAction(
    {
        onClick,
    }: {
        onClick: (e: MouseEvent<HTMLButtonElement>) => void;
    },
    ref: React.Ref<HTMLButtonElement>,
) {
    return (
        <button
            type="button"
            onClick={onClick}
            ref={ref}
            className={classes.base}
        >
            <Edit />
        </button>
    );
});

export { CellAction };
