import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgTableCollumnRight = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M8.75 12.75H5.5v-1.5h3.25V8h1.5v3.25h3.25v1.5h-3.25V16h-1.5v-3.25Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19 2.25A2.75 2.75 0 0 1 21.75 5v14A2.751 2.751 0 0 1 19 21.75H5A2.75 2.75 0 0 1 2.25 19V5A2.75 2.75 0 0 1 5 2.25h14Zm0 1.5c.69 0 1.25.56 1.25 1.25v3.25h-3.5v-4.5H19Zm1.25 6h-3.5v4.5h3.5v-4.5Zm-5-6H5c-.69 0-1.25.56-1.25 1.25v14c0 .69.56 1.25 1.25 1.25h10.25V3.75Zm1.5 12h3.5V19c0 .69-.56 1.25-1.25 1.25h-2.25v-4.5Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgTableCollumnRight);
export default ForwardRef;
