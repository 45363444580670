import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import {
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import { compact } from 'lodash-es';
import {
    useCategoryStore,
    useCategoryDispatch,
    useSiteStore,
} from '@mega/store';
import { Category } from '@mega/api';
import { CategoryEdit } from '@mega/forms';
import { getOptionsTags } from '@mega/core';
import { FormikHelpers } from 'formik';

export const CategoriesEdit: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();

    const { siteslug = '' } = useParams();
    const { loading, store } = useCategoryStore();
    const { get, update } = useCategoryDispatch();
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const currentSite = useSiteStore();

    useEffect(() => {
        if (id) {
            get({ id: parseInt(id) });
        }
    }, [id, get]);

    if (loading.get.loading || !loading.get.success) {
        return <div>loading</div>;
    }

    let editedId: number;

    if (id) {
        editedId = parseInt(id);
    } else {
        throw new Error('The editable entity has no id.');
    }

    const handleUpdate = async (
        payload: Category,
        actions: FormikHelpers<Category>,
    ) => {
        const formData = new FormData();
        if (typeof payload.icon === 'object') {
            formData.append('icon', payload.icon);
        }

        await update({
            query: {
                id: editedId,
            },
            payload: {
                iconData: formData,
                formData: {
                    site: currentSite?.id || '',
                    title: payload.title,
                    slug: payload.slug,
                    is_active: payload.is_active,
                    long_title: payload.long_title,
                    meta_title: payload.meta_title,
                    meta_description:
                        payload.meta_description,
                    tags: compact(
                        payload.tags?.map(
                            (item) => item.id,
                        ) || [],
                    ),
                },
            },
            options: {
                notifier: {
                    enqueueSnackbar,
                },
                router: {
                    navigate,
                    location,
                },
            },
        });
        actions.setSubmitting(false);
    };
    return (
        <CategoryEdit
            initialValue={store}
            apiConnector={{
                handleGetTagsOptions: getOptionsTags({
                    site: siteslug,
                }),
            }}
            handleUpdate={handleUpdate}
        />
    );
};
