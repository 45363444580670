import * as CLIENT from '@mega/api';
import {
    Employees,
    EmployeesQuery,
    EmployeesWithPagination,
} from '@mega/api';
import { createModel } from '@rematch/core';
import { debounce, isUndefined } from 'lodash-es';
import { DefaultStore } from '../type';
import { RootModel } from '../rootModel';
interface EmployeesStoreCustom
    extends DefaultStore<Employees, EmployeesQuery> {
    customQuery?: {
        sites?: Array<string>;
    };
}

const urlParams = new URLSearchParams(location.search);

const defaultStore: EmployeesStoreCustom = {
    currentPage: 1,
    query: {
        per_page: 30,
    },
    meta: {
        count: null,
    },
    pages: {},
    hasMore: false,
};

const employees = createModel<RootModel>()({
    name: 'employees',
    state: defaultStore,
    reducers: {
        putPage: (
            state,
            payload: EmployeesWithPagination,
        ) => {
            return {
                ...state,
                meta: {
                    count: isUndefined(payload.count)
                        ? null
                        : payload.count,
                },
                pages: {
                    ...state.pages,
                    [`${state.currentPage}`]:
                        payload.results,
                },
            };
        },
        putQuery: (state, payload) => {
            return {
                ...state,
                query: { ...state.query, ...payload },
            };
        },
        putCurrentPage: (
            state,
            payload: { triggerPage: number },
        ) => {
            return {
                ...state,
                currentPage: payload.triggerPage,
            };
        },
        putCustomQuery: (
            state,
            payload: EmployeesStoreCustom['customQuery'],
        ) => {
            return {
                ...state,
                customQuery: {
                    ...state.customQuery,
                    ...payload,
                },
            };
        },
    },
    effects: (dispatch) => ({
        addCustomSiteQuery: async (
            payload: string | string[],
        ) => {
            if (Array.isArray(payload)) {
                await dispatch.employees.putCustomQuery({
                    sites: payload,
                });

                await dispatch.employees.get({});
            } else {
                await dispatch.employees.putCustomQuery({
                    sites: [payload],
                });
                await dispatch.employees.get({});
            }
        },
        get: async (
            query: EmployeesQuery | null,
            store,
        ) => {
            const { query: storeQuery } = store.employees;
            try {
                const response =
                    await CLIENT.user.getUsers?.(
                        {
                            ...(query ? query : {}),
                            ...(storeQuery
                                ? storeQuery
                                : {}),
                            sites_in: Array.isArray(
                                //@ts-ignore
                                storeQuery.sites_in,
                            )
                                ? storeQuery.sites_in.join(
                                      ',',
                                  )
                                : storeQuery.sites_in,
                            sites:
                                store.site.site?.id || '',
                            is_count: true,
                            page: store.employees
                                .currentPage,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    const { data } = response;
                    dispatch.employees.putPage(data);
                }
            } catch (e) {
                if (
                    CLIENT.user.getUsers &&
                    e instanceof CLIENT.user.getUsers.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },
        search: async (query) => {
            await dispatch.employees.putQuery(query);
            await dispatch.employees.afterSearch(null);
        },
        afterSearch: debounce((_, state) => {
            dispatch.employees.get(state.employees.query);
        }, 1000),
        nextPage: async (_: any, store) => {
            const { query, currentPage } = store.employees;

            await dispatch.employees.putCurrentPage({
                triggerPage: currentPage + 1,
            });

            await dispatch.employees.get({
                ...query,
                page: currentPage + 1,
            });
        },
        jumpTo: async (page: number) => {
            await dispatch.employees.putQuery({
                page: page,
            });
            await dispatch.employees.putCurrentPage({
                triggerPage: page,
            });
            dispatch.employees.get({
                page: page,
            });
        },
    }),
});

export { employees };
export default employees;
