import React, { FC } from 'react';
import {
    CardStoryListing,
    NoResultsFoundMessage,
} from '@mega/core';
import {
    useSiteStore,
    useStoriesDispatch,
    useStoriesStore,
    useStoryDispatch,
} from '@mega/store';
import {
    Button,
    EntityListLayout,
    Input,
    ListContent,
    Pagination,
} from '@mega/ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { Add, Search } from '@mega/icons';
import { useSnackbar } from 'notistack';

export interface StoriesProps {}

const Stories: FC<StoriesProps> = () => {
    const { enqueueSnackbar } = useSnackbar();

    const store = useStoriesStore();
    const { search, jumpTo } = useStoriesDispatch();
    const { create } = useStoryDispatch();
    let navigate = useNavigate();
    let location = useLocation();
    const site = useSiteStore();

    const articleListName = 'articleList';
    const allTagsCount = store.meta.count || 0;
    const tagsPerPage = store.query.per_page || 1;
    const allPagesCount = Math.ceil(
        allTagsCount / tagsPerPage,
    );
    const hasPage = store.pages['1'] ? true : false;
    const isItLoadingNow = store.loading.get.loading;
    const isPageEmpty =
        !isItLoadingNow &&
        hasPage &&
        store.pages['1'].length === 0;

    const handleCreate = async () => {
        if (!Boolean(site && site.id)) {
            return enqueueSnackbar('Что-то пошло не так', {
                variant: 'error',
            });
        }

        const response = await create({
            payload: {
                title: 'Новая история',
                background: 'orange',
                cards: [],
            },
            options: {
                router: {
                    navigate,
                    location,
                },
                notifier: {
                    enqueueSnackbar,
                },
            },
        });

        return response;
    };

    return (
        <EntityListLayout
            setting={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: '18px',
                    }}
                >
                    <div style={{ minWidth: '300px' }}>
                        <Input
                            startIcon={<Search />}
                            color="secondary"
                            dimension="medium"
                            value={
                                store?.query?.search || ''
                            }
                            type="text"
                            onChange={(e) =>
                                search({
                                    search: e.target.value,
                                })
                            }
                        />
                    </div>
                    <Button
                        size={'large'}
                        endIcon={<Add />}
                        label="Добавить"
                        classes={{
                            paper: {
                                variant: 'filled',
                                color: 'dark',
                            },
                        }}
                        onClick={handleCreate}
                    />
                </div>
            }
        >
            <>
                <ListContent name={articleListName}>
                    {() => {
                        return <CardStoryListing />;
                    }}
                </ListContent>
                <NoResultsFoundMessage
                    isPageEmpty={isPageEmpty}
                />
                <Pagination
                    pageCount={allPagesCount}
                    jumpTo={jumpTo}
                    loading={isItLoadingNow}
                />
            </>
        </EntityListLayout>
    );
};

export { Stories };
