import React, {
    FC,
    PropsWithChildren,
    ReactElement,
} from 'react';
import {
    components,
    DropdownIndicatorProps,
} from 'react-select';

interface DownIndicatorProps {
    className?: string;
    endIcon?: ReactElement | null;
}

type DropdownIndicatorOverrideProps =
    DropdownIndicatorProps<Record<string, unknown>, true>;

const DropdownIndicator =
    ({ className = '', endIcon }: DownIndicatorProps) =>
    ({
        ...props
    }: PropsWithChildren<DropdownIndicatorOverrideProps>) => {
        const hasEndIcon = Boolean(endIcon);

        return hasEndIcon ? (
            <components.DropdownIndicator
                className={className}
                {...props}
            >
                {endIcon}
            </components.DropdownIndicator>
        ) : null;
    };

export { DropdownIndicator };
export default DropdownIndicator;
