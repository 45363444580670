/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Control } from '../../../../types/core';
import { Author } from './Author';
import { AuthorPlugin } from './AuthorPlugin';
import {
    AuthorEnum,
    AuthorPluginDefault,
} from './Author.types';

const PLUGIN_AUTHOR_NAME = AuthorEnum.type;

const DEFAULT_AUTHOR: AuthorPluginDefault = {
    type: PLUGIN_AUTHOR_NAME,
    meta: {},
    childrenElement: [],
};

const PLUGIN_AUTHOR = {
    [PLUGIN_AUTHOR_NAME]: {
        emptyObject: DEFAULT_AUTHOR,
        render: Author,
    },
};

const PLUGIN_AUTHOR_CONTROL: Control = {
    name: PLUGIN_AUTHOR_NAME,
    label: 'Author',
    render: AuthorPlugin,
    tooltip: 'Добавить автора',
    Icon: () => <></>,
};

export {
    DEFAULT_AUTHOR,
    PLUGIN_AUTHOR,
    PLUGIN_AUTHOR_CONTROL,
    PLUGIN_AUTHOR_NAME,
};
