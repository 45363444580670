import {
    Button,
    InputSelectMulti,
    WithLabel,
} from '@mega/ui';
import React, { FC } from 'react';
import { IInitValuesForm } from './config';
import { getOptionsCategories } from '@mega/core';
import { useSiteStore } from '@mega/store';
import { useFormikContext } from 'formik';
import { styles as s } from '../../Categories.css';

interface CategoriesFormProps {
    onSubmit: (
        e?: React.FormEvent<HTMLFormElement> | undefined,
    ) => void;
}

export const CategoriesForm: FC<CategoriesFormProps> = ({
    onSubmit,
}) => {
    const currentSite = useSiteStore();
    const { setFieldValue, values } =
        useFormikContext<IInitValuesForm>();
    return (
        <>
            <div className={s.settingsContainer}>
                <WithLabel
                    title={'Категории'}
                    id={'categories'}
                    color={'white'}
                >
                    <InputSelectMulti
                        defaultValue={values?.categories}
                        key="categories"
                        classes={{
                            paper: { color: 'white' },
                            select: { variant: 'third' },
                        }}
                        actionOnSelectedOption={(
                            formValues,
                        ) => {
                            setFieldValue(
                                'categories',
                                formValues,
                            );
                        }}
                        handleLoader={getOptionsCategories({
                            site: currentSite?.id || '',
                        })}
                    />
                </WithLabel>

                <Button
                    type={'button'}
                    label={'Сохранить'}
                    onClick={() => onSubmit()}
                    classes={{
                        paper: {
                            color: 'dark',
                            variant: 'filled',
                        },
                    }}
                />
            </div>
        </>
    );
};
