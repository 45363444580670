import React, { FC, useEffect } from 'react';
import { isEqual } from 'lodash-es';

import {
    useSiteStore,
    useAuthorsStore,
    useAuthorsDispatch,
} from '@mega/store';
import {
    useLocation,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import {
    Button,
    EntityListLayout,
    Input,
    ListContent,
    InputSelectMulti,
    Pagination,
    Row,
} from '@mega/ui';
import { Add, Search } from '@mega/icons';
import {
    ListingAuthors,
    NoResultsFoundMessage,
    getOptionsSites,
} from '@mega/core';

interface TagsProps {
    children?: never;
}

const Authors: FC<TagsProps> = () => {
    let location = useLocation();
    const [searchParams, setSearchParams] =
        useSearchParams();
    const siteStore = useSiteStore();
    const store = useAuthorsStore();
    const { search, addCustomSiteQuery, jumpTo } =
        useAuthorsDispatch();
    let navigate = useNavigate();

    const authorsListName = 'authorsList';
    const allAuthorsCount = store.meta.count || 0;
    const authorsPerPage = store.query.per_page || 1;
    const allPagesCount = Math.ceil(
        allAuthorsCount / authorsPerPage,
    );

    const resetPaginatorToFirstPage = () => {
        setSearchParams({ page: '1' });
        jumpTo(1);
    };
    const hasPage = store.pages['1'] ? true : false;
    const isItLoadingNow = store.loading.get.loading;
    const isPageEmpty =
        !isItLoadingNow &&
        hasPage &&
        store.pages['1'].length === 0;

    useEffect(() => {
        if (!isItLoadingNow) {
            window.scrollTo(0, 0);
        }
    }, [isItLoadingNow]);

    return (
        <EntityListLayout
            setting={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: '18px',
                    }}
                >
                    <Row gap="16">
                        <div style={{ minWidth: '300px' }}>
                            <Input
                                startIcon={<Search />}
                                color="secondary"
                                dimension="small"
                                value={
                                    store?.query?.search ||
                                    ''
                                }
                                type="text"
                                onChange={(e) => {
                                    resetPaginatorToFirstPage();
                                    return search({
                                        search: e.target
                                            .value,
                                    });
                                }}
                            />
                        </div>

                        <div
                            style={{
                                width: '100%',
                                minWidth: '300px',
                                maxWidth: '300px',
                            }}
                        >
                            <InputSelectMulti
                                key="select_authors"
                                placeholder="Сайты"
                                defaultValue={
                                    siteStore?.id
                                        ? [
                                              {
                                                  value:
                                                      siteStore?.id ||
                                                      '',
                                                  label: siteStore?.id,
                                              },
                                          ]
                                        : undefined
                                }
                                actionOnSelectedOption={(
                                    test,
                                ) => {
                                    if (
                                        !isEqual(test, [
                                            {
                                                value:
                                                    siteStore?.id ||
                                                    '',
                                                label: siteStore?.id,
                                            },
                                        ])
                                    ) {
                                        resetPaginatorToFirstPage();
                                    }

                                    addCustomSiteQuery(
                                        test.map(
                                            (item) =>
                                                `${item.value}`,
                                        ),
                                    );
                                }}
                                handleLoader={getOptionsSites()}
                            />
                        </div>
                    </Row>
                    <Button
                        size={'large'}
                        endIcon={<Add />}
                        label="Добавить"
                        classes={{
                            paper: {
                                variant: 'filled',
                                color: 'dark',
                            },
                        }}
                        onClick={() => {
                            navigate(`add`, {
                                replace: true,
                                state: location,
                            });
                        }}
                    />
                </div>
            }
        >
            <>
                <ListContent name={authorsListName}>
                    {() => {
                        return <ListingAuthors />;
                    }}
                </ListContent>
                <NoResultsFoundMessage
                    isPageEmpty={isPageEmpty}
                />
                <Pagination
                    pageCount={allPagesCount}
                    jumpTo={jumpTo}
                    loading={isItLoadingNow}
                />
            </>
        </EntityListLayout>
    );
};

export { Authors };
