import React, {
    Fragment,
    PropsWithChildren,
    ReactElement,
} from 'react';

import Select, {
    components,
    MenuProps,
} from 'react-select';
import { Divider } from '@mega/ui';
import selectSingleClasses from '../../SelectSingle.css';
import { paperClasses } from '@mega/styles';

export type OverideMenu = MenuProps<
    Record<string, unknown>,
    true
>;

const MenuPrimary = ({
    ...props
}: PropsWithChildren<OverideMenu>) => (
    <>
        <components.Menu
            className={[
                selectSingleClasses.menuRecipe,
                paperClasses.recipe({
                    variant: 'outlineFilled',
                    color: 'dark',
                    borderRadius: 'small',
                }),
            ].join(' ')}
            {...props}
        >
            <Divider />
            {props.children}
        </components.Menu>
    </>
);

const MenuSecondary = ({
    ...props
}: PropsWithChildren<OverideMenu>) => (
    <>
        <components.Menu
            className={[
                selectSingleClasses.menuRecipe,
                paperClasses.recipe({
                    variant: 'outlineFilled',
                    color: 'gray',
                    borderRadius: 'small',
                }),
            ].join(' ')}
            {...props}
        >
            {props.children}
        </components.Menu>
    </>
);

const MenuThird = ({
    ...props
}: PropsWithChildren<OverideMenu>) => (
    <>
        <components.Menu
            className={[
                selectSingleClasses.menuRecipe,
                paperClasses.recipe({
                    color: 'gray',
                    borderRadius: 'small',
                }),
            ].join(' ')}
            {...props}
        >
            <Divider />
            {props.children}
        </components.Menu>
    </>
);

export { MenuPrimary, MenuSecondary, MenuThird };
export default MenuPrimary;
