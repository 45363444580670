import React, {
    createContext,
    FC,
    ReactElement,
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react';

type Gliph = {
    [id: string]: {
        count: number;
    };
};

interface AphostrophExternalDataContextProps {
    gliphs: Gliph;
    setGliphs: (id: string | number, newValue: any) => void;
    removeGliphs: (id: string | number) => void;
    isReadonly: boolean;
}

export const AphostrophExternalDataContext =
    createContext<AphostrophExternalDataContextProps | null>(
        null,
    );

export interface AphostrophExternalDataContextProviderProps {
    isReadonly?: boolean;
    children: ReactElement | ReactElement[];
}

const initialState = {} as Gliph;

function reducer(
    state: Gliph,
    action:
        | {
              type: 'update';
              payload: {
                  id: string | number;
                  newValue: {
                      gliphs: number;
                  };
              };
          }
        | {
              type: 'remove';
              payload: {
                  id: string | number;
              };
          },
) {
    switch (action.type) {
        case 'update':
            return {
                ...state,
                [action.payload.id]: {
                    count: action.payload.newValue.gliphs,
                },
            };
        case 'remove': {
            const newState = { ...state };
            delete newState[action.payload.id];
            return newState;
        }
        default:
            throw new Error();
    }
}

const AphostrophExternalDataContextProvider: FC<
    AphostrophExternalDataContextProviderProps
> = ({ children, isReadonly = false }) => {
    const [state, dispatch] = useReducer(
        reducer,
        initialState,
    );

    const handleSetGliphs = (
        id: string | number,
        newValue: {
            gliphs: number;
        },
    ) => {
        return dispatch({
            type: 'update',
            payload: { id, newValue },
        });
    };

    const handleRemoveGliphs = (id: string | number) => {
        return dispatch({
            type: 'remove',
            payload: { id },
        });
    };

    return (
        <AphostrophExternalDataContext.Provider
            value={{
                gliphs: state,
                setGliphs: handleSetGliphs,
                removeGliphs: handleRemoveGliphs,
                isReadonly,
            }}
        >
            {children}
        </AphostrophExternalDataContext.Provider>
    );
};

const useAphostrophExternalDataContext = () => {
    const context = useContext(
        AphostrophExternalDataContext,
    );
    return context;
};

export {
    AphostrophExternalDataContextProvider,
    useAphostrophExternalDataContext,
};
