import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paper } from '@mega/ui';
import { LoginForm } from '@mega/forms';
import { useAuthStore } from '@mega/store';

const Login = () => {
    const navigate = useNavigate();
    const { isAuth } = useAuthStore();

    const from: string = '/';

    useEffect(() => {
        if (isAuth) {
            navigate(from, { replace: true });
        }
    }, [from, isAuth, navigate]);

    return (
        <Paper
            variant="filled"
            classes={{
                padding: { size: '24x24' },
            }}
        >
            <LoginForm />
        </Paper>
    );
};

export { Login };
