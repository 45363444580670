import React, { useEffect, useMemo } from 'react';
import { CardStoryListingProps } from '../../CardStory.types';
import { ListingTable } from '@mega/ui';
import {
    useStoriesDispatch,
    useStoriesStore,
} from '@mega/store';
import { CellValue, Column } from 'react-table';
import type { Story as StoryType } from '@mega/api';
import { TitleColumn } from '../../../Listing/Columns';
import {
    headItem,
    listingRecipe,
} from '../../../Listing/Listing.css';
import { typographyClasses } from '@mega/styles';

export const CardStoryListing: React.FC<
    CardStoryListingProps
> = (props) => {
    const store = useStoriesStore();
    const { get, clear } = useStoriesDispatch();

    useEffect(() => {
        get({ ordering: '-id' });
        return () => {
            clear();
        };
    }, [get, clear]);

    const columns: Column<StoryType>[] = React.useMemo(
        () => [
            {
                Header: 'ID',
                accessor: (row) => {
                    return {
                        id: row.id,
                        title: row.id?.toString(),
                    };
                },
                Cell: ({ value }: CellValue) => (
                    <TitleColumn {...value} />
                ),
                width: 'max-content',
                minWidth: 30,
            },
            {
                Header: 'Заголовок',
                accessor: (row) => {
                    return {
                        title: row.title,
                        id: row.id,
                    };
                },
                Cell: ({ value }: CellValue) => (
                    <TitleColumn {...value} />
                ),
                minWidth: 200,
                width: '100%',
            },
        ],
        [],
    );
    const value = Object.values(store.pages).flat();
    const data = useMemo(() => value, [value]);

    return (
        <>
            <ListingTable
                getHeaderGroupProps={(cellInfo) => {
                    return {
                        className: listingRecipe({
                            header: true,
                        }),
                    };
                }}
                getHeaderProps={(cellInfo) => {
                    return {
                        className: headItem({
                            title: true,
                        }),
                    };
                }}
                getRowGroupProps={() => {
                    return {
                        className: [
                            typographyClasses.recipe({
                                size: '14',
                            }),
                            listingRecipe({
                                header: false,
                            }),
                        ].join(' '),
                    };
                }}
                columns={columns}
                data={data}
            />
        </>
    );
};
