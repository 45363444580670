import "src/pages/smartGrid/components/Tabs/Tabs.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/pages/smartGrid/components/Tabs/Tabs.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71WzY6bMBC+5yksrSp1pQWZbLKbGKnHnvoAvVUONuAEbGRMflrtu1cYg20WklTdNlGk2PM/882Mwx/bzcuBEgh+LQAgrK4KfEEgLeg5XgCAC5bxgCla1t1lQDlpCRmuEIDhWtKyPZ4YUTkCEYSf4sXbIjRao3+idam1VpgQxjOXvxI1U0xwBCQtsGJH2t4mjayFRKASjCsq45seJbTnU/SsAiUxr1MhSwSaqqIywbXWq++NPVHhhKkLgOGyfgIllhnj5mD8HEjnQIelz62aHU4OmRQNJwg8UNx+vWBRLo5U6pCNFQQiLSgkoTKQmLCmRmBVnfUPAhjb9AQ7oZQoEYhMjjrfhmvo2np2E/tO8rq9a3pXWu+Yw5ZtZNBShoBhuJ52wTWz1mYSUbTVfnjdvG7TyKVvOrqBAxcqwEUhTpS4TFuPacCMZcCawS3+ABCv/N9oqoYi90jeVBpuOWVZruzZAhfvalE0qgOYqBAIYPhisiE7IefmZ8A4oeceEC6S+vCnk3Y9QgdyDvjrBBf0cxQuH13m3Qybx5RMM8FHP7FfQH3MvCJ+1Z+bGbRKiBbvqc+w4y4ZD4yC4WoOjX4/rhKSpn8yMvZNrVh6CRLBFeXKJd1ZCB9c1h0DJidWp06Ttbes9N602KnampuZGUOu3pdpBoP3Zs9YKmiqEMCNEh+VtydvRLupyfzRqieNpea3xgG5B7XLtY/a/nxtimhlszW3Ric61G8+Njl9bWLbUcip4227owx84/+yaK2v+w7MI79GAFi3zwHHr35BfUCLDi+O0ZSBf9ur+/t7de8Ut/dnNfJntPkO0+8s/ZwyA3O6iWYqPrmOPOh0Har/tnGlhTghkDNCKL8FbPdBdGOJ9c8hB+WBkCxjHGkP3BwUk93wXW8iD8TtVBuwbOXLGXk4ko8Xb78BLaig2zULAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var addGridIsDisabled = _7a468({defaultClassName:'_986kedf',variantClassNames:{isDisabled:{true:'_986kedg',false:'_986kedh'}},defaultVariants:{},compoundVariants:[]});
export var addIcon = '_986kedd';
export var copyAll = _7a468({defaultClassName:'_986keda',variantClassNames:{isActive:{true:'_986kedb',false:'_986kedc'}},defaultVariants:{},compoundVariants:[]});
export var disabled = _7a468({defaultClassName:'_986ked7',variantClassNames:{isDisabled:{true:'_986ked8',false:'_986ked9'}},defaultVariants:{},compoundVariants:[]});
export var editInputField = '_986kedi';
export var editTitleWrapper = _7a468({defaultClassName:'_986kedk',variantClassNames:{isEditMode:{true:'_986kedl',false:'_986kedm'}},defaultVariants:{},compoundVariants:[]});
export var gridCounter = '_986kede';
export var navItem = _7a468({defaultClassName:'_986ked2',variantClassNames:{isCurActive:{true:'_986ked3',false:'_986ked4'},isActive:{true:'_986ked5',false:'_986ked6'}},defaultVariants:{},compoundVariants:[]});
export var styles = {wrapper:'_986ked0',navItem:_7a468({defaultClassName:'_986ked2',variantClassNames:{isCurActive:{true:'_986ked3',false:'_986ked4'},isActive:{true:'_986ked5',false:'_986ked6'}},defaultVariants:{},compoundVariants:[]}),tabList:'_986ked1',addIcon:'_986kedd',gridCounter:'_986kede',disabled:_7a468({defaultClassName:'_986ked7',variantClassNames:{isDisabled:{true:'_986ked8',false:'_986ked9'}},defaultVariants:{},compoundVariants:[]}),copyAll:_7a468({defaultClassName:'_986keda',variantClassNames:{isActive:{true:'_986kedb',false:'_986kedc'}},defaultVariants:{},compoundVariants:[]}),editInputField:'_986kedi',editTitleButton:_7a468({defaultClassName:'_986kedj',variantClassNames:{},defaultVariants:{},compoundVariants:[]}),addGridIsDisabled:_7a468({defaultClassName:'_986kedf',variantClassNames:{isDisabled:{true:'_986kedg',false:'_986kedh'}},defaultVariants:{},compoundVariants:[]}),editTitleWrapper:_7a468({defaultClassName:'_986kedk',variantClassNames:{isEditMode:{true:'_986kedl',false:'_986kedm'}},defaultVariants:{},compoundVariants:[]})};
export var tabList = '_986ked1';
export var wrapper = '_986ked0';