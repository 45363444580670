import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { HeadScriptCreate as HeadScriptCreateForm } from '@mega/forms';
import { GetByIdADSHeadScript } from '@mega/api';
import {
    useAdsHeadScriptDispatch,
    useSiteStore,
} from '@mega/store';

export const HeadScriptNew: FC<{}> = () => {
    const { create } = useAdsHeadScriptDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const location = useLocation();
    const navigate = useNavigate();

    const site = useSiteStore();

    const handleCreate = async (
        value: GetByIdADSHeadScript,
    ) => {
        if (!Boolean(site && site.id)) {
            return enqueueSnackbar(
                'Простите что-то пошло не так, обратитесь пожалуйста в тех поддержку',
                {
                    variant: 'error',
                },
            );
        }

        const response = await create({
            payload: {
                site: site?.id || '',
                id: value.id,
                script: value.script,
                title: value.title,
            },
        });

        if (response.isOk) {
            navigate(
                `/${site?.id || ''}/headscript/change/${
                    response.value.id
                }`,
                {
                    state: location,
                },
            );

            enqueueSnackbar('Скрипт <head> создан', {
                variant: 'success',
            });
        } else {
            if (response.value) {
                const errormsgs = Object.values(
                    response.value,
                );

                errormsgs?.map((msg) => {
                    enqueueSnackbar(msg as string, {
                        variant: 'error',
                    });
                });
            }
        }

        return response;
    };

    return (
        <HeadScriptCreateForm handleCreate={handleCreate} />
    );
};
