import React, { FC, useEffect, useState } from 'react';
import {
    TemplatePlugin,
    HeadTemplatePlugin,
} from '@mega/editor';
import { useEditor } from '@apostroph/store';
import { Box } from '@mui/material';
import {
    AdsSourceMeta,
    AdsSourceProps,
} from './AdsSource.types';
import { Icon, Typography } from '@mega/ui';
import { Code } from '@mega/icons';
import { InputTextareaField } from '@mega/ui/src/components/Inputs/InputTextarea';

const AdsSourceText: FC<AdsSourceProps> = ({
    id,
    parentId,
    type,
}) => {
    const { handleUpdateElement, getCurrentDataElement } =
        useEditor();
    const { meta, childrenElement } =
        getCurrentDataElement<AdsSourceMeta>(id);
    const [content, setContent] = useState(meta.text);

    useEffect(() => {
        const newGlobalState = {
            id,
            type,
            childrenElement,
            meta: {
                text: content,
            },
        };
        handleUpdateElement(newGlobalState);
    }, [content]);

    return (
        <TemplatePlugin
            head={
                <HeadTemplatePlugin
                    id={id}
                    parentId={parentId}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '4px',
                            flexShrink: 0,
                        }}
                    >
                        <Icon size="16">
                            <Code />
                        </Icon>
                        <Typography size={'12'}>
                            ADS источник
                        </Typography>
                    </div>
                </HeadTemplatePlugin>
            }
        >
            <Box sx={{ pb: 2 }}>
                <InputTextareaField
                    name={'html'}
                    placeholderDefault={'ADS источник'}
                    value={content}
                    rows={1}
                    dimension={'none'}
                    verticalTextCenter
                    color={'transparent'}
                    onChange={(e) => {
                        const newValue = e.target.value;
                        setContent(newValue);
                    }}
                />
            </Box>
        </TemplatePlugin>
    );
};

export { AdsSourceText };
