import { useCallback, useRef } from 'react';
import { throttle } from 'lodash-es';
import type { Elements } from '@apostroph/types';
import {
    RootState,
    Dispatch,
    useEditorSelector,
    useEditorDispatch,
} from '../store';

const useBlock = (id: string) => {
    const block = useEditorSelector(
        (state: RootState) => state.editor.data[id],
    );
    const dispatch = useEditorDispatch<Dispatch>();

    const handleUpdateElement = useCallback(
        (payload: Elements) => {
            dispatch.editor.updateElement(payload);
        },
        [dispatch.editor],
    );

    const throttledHandleUpdateBlock = useRef(
        throttle(handleUpdateElement, 1000),
    );

    const getCurrentDataElement = useCallback(<T>(): {
        meta: T;
        childrenElement: Array<string>;
    } => {
        if (!block) {
            throw new Error(
                `No element found with id: ${id}`,
            );
        }
        return {
            meta: block.meta as T,
            childrenElement: block.childrenElement,
        };
    }, [block, id]);

    if (!block) {
        throw new Error(`No element found with id: ${id}`);
    }

    return {
        handleUpdateElement:
            throttledHandleUpdateBlock.current,
        getCurrentDataElement,
    };
};

export { useBlock };
export default { useBlock };
