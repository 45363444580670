import React, { FC, useEffect } from 'react';
import { InfiniteMixEdit as InfiniteMixEditForm } from '@mega/forms';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
    useSiteStore,
    useInfiniteMixDispatch,
    useInfiniteMixStore,
} from '@mega/store';
import { useSnackbar } from 'notistack';
import { GetByIdInfiniteMix } from '@mega/api';
import { FormikHelpers } from 'formik';

export const InfiniteMixEdit: FC<{}> = () => {
    const { enqueueSnackbar } = useSnackbar();

    const { id } = useParams();
    const { loading, store } = useInfiniteMixStore();
    const { get, update } = useInfiniteMixDispatch();
    const currentSite = useSiteStore();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            get({ paylaod: { id: parseInt(id) } });
        }
    }, [id, get]);

    if (loading.get.loading || !loading.get.success) {
        return <div>loading</div>;
    }

    let editedId: number;

    if (id) {
        editedId = parseInt(id);
    } else {
        throw new Error('The editable entity has no id.');
    }

    const handleUpdate = async (
        payload: GetByIdInfiniteMix,
        actions: FormikHelpers<GetByIdInfiniteMix>,
    ) => {
        const response = await update({
            payload: {
                //@ts-ignore
                id: editedId,
                site: payload.site?.id || '',
                article: payload.article?.id || 9999999,
                order: payload.order,
                active_from: payload.active_from,
                active_to: payload.active_to,
                target_shows: payload.target_shows,
            },
        });

        actions.setSubmitting(false);
        if (response.isOk) {
            navigate(
                `/${
                    currentSite?.id || ''
                }/infinitemix/change/`,
                {
                    replace: true,
                },
            );
            enqueueSnackbar(
                'Инфинити-скролл блок обновлен',
                {
                    variant: 'success',
                },
            );
        } else {
            if (response.value) {
                const errormsgs = Object.values(
                    response.value,
                );

                errormsgs?.map((msg) => {
                    enqueueSnackbar(msg as string, {
                        variant: 'error',
                    });
                });
            }
        }

        // return response;
    };

    return (
        <InfiniteMixEditForm
            handleUpdate={handleUpdate}
            initialValue={store}
        />
    );
};
