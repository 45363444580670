import React, {
    ChangeEvent,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    inputTextareaClasses,
    InputTextareaClasses,
} from './InputTextarea.css';
import { TextareaAutosize } from '@mui/material';
import { PaperClasses, paperClasses } from '@mega/styles';

export interface InputTextareaProps {
    value?: string;
    name: string;
    rows?: number;
    placeholder?: string;
    onChange?: (value: string) => void;
    textAlign?: 'center' | 'right' | 'left';
    className?: string;
    color?: string;
    variant?: 'gray';
    limit?: number;
    classes?: {
        paper?: PaperClasses['recipe'];
    };
    placeholderDefault?: string;
    dimension?: 'small' | 'medium' | 'large' | 'none';
    verticalTextCenter?: Boolean;
}

const limitList = {
    title: 120,
    subtitle: 90,
};

export const InputTextarea: React.FC<
    InputTextareaProps & InputTextareaClasses['theme']
> = (props) => {
    const {
        value,
        onChange = () => undefined,
        placeholder,
        name,
        textAlign,
        theme = 'primary',
        color,
        className,
        limit,
        classes,
        placeholderDefault,
        dimension = 'medium',
        verticalTextCenter = false,
    } = props;
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const [isFocus, setIsFocus] = useState(false);

    const placeholderMod = value !== '' ? true : isFocus;

    const interceptValue = value;

    const maxLength = limit;

    return (
        <div
            className={inputTextareaClasses.blockWithError}
        >
            <div
                className={[
                    inputTextareaClasses.inputWrapper({
                        isError: false,
                        // isError &&
                        // meta.touched &&
                        // meta.value === '',
                    }),
                    paperClasses.recipe({
                        color: 'gray',
                        borderRadius: 'small',
                        variant: 'outlineFilled',
                        ...(classes?.paper ?? {}),
                    }),
                ].join(' ')}
            >
                <div
                    className={inputTextareaClasses.paperStyle(
                        {
                            isFocus:
                                placeholderMod &&
                                Boolean(placeholder),
                            dimension,
                        },
                    )}
                >
                    <TextareaAutosize
                        ref={textareaRef}
                        value={value}
                        onChange={(e) => {
                            const value = e.target.value;
                            onChange(value);
                        }}
                        name={name}
                        onFocus={() => setIsFocus(true)}
                        onBlur={() => setIsFocus(false)}
                        maxLength={maxLength}
                        placeholder={placeholderDefault}
                        className={`${inputTextareaClasses.root(
                            {
                                textAlign,
                                theme,
                                color,
                                verticalTextCenter,
                            },
                        )} ${className}`}
                    />
                    <div
                        className={inputTextareaClasses.placeholder(
                            {
                                isFocus: placeholderMod,
                            },
                        )}
                    >
                        {placeholder}
                        {maxLength && (
                            <p
                                className={
                                    inputTextareaClasses.textAreaPlaceholderCounter
                                }
                            >
                                {
                                    interceptValue?.toString()
                                        .length
                                }
                                /{maxLength}
                            </p>
                        )}
                    </div>
                </div>
            </div>
            {/* {isError &&
                meta.touched &&
                meta.value === '' && (
                    <p
                        className={
                            inputTextareaClasses.errorText
                        }
                    >
                        {meta?.error}
                    </p>
                )} */}
        </div>
    );
};
