import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';

const useVideoStore = () => {
    const store = useStoreSelector(
        (state: RootState) => state.video,
    );
    const loading = useStoreSelector(
        (state: RootState) => state.loading.effects.video,
    );

    return {
        store,
        loading,
    };
};

const useVideoDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        get: dispatch.video.get,
        put: dispatch.video.put,
        create: dispatch.video.create,
        update: dispatch.video.update,
        remove: dispatch.video.remove,
    };
};

export { useVideoStore, useVideoDispatch };
