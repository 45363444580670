import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    useArticleDispatch,
    useArticleStore,
} from '@mega/store';
import { ApostrophSerializerMemo as Serializer } from '@mega/core';

interface ArticleProps {}

export const Article: FC<ArticleProps> = () => {
    const { id } = useParams();
    const { get } = useArticleDispatch();
    const store = useArticleStore();
    useEffect(() => {
        if (id) {
            get(parseInt(id));
        }
    }, [id, get]);

    // if (loading.get.loading || !loading.get.success) {
    //     return <div>loading</div>;
    // }

    if (store?.data) {
        return (
            <div id={'editor_content'}>
                <Serializer
                    initData={store.data.editor_data}
                />
            </div>
        );
    }
    return null;
};
