import type {
    GetADSBlock,
    AdsBlocks,
    GetADSBlockError,
    GetADSBlockQuery,
} from '@mega/api';
import { isOk } from '@mega/api';
import { createModel } from '@rematch/core';
import { debounce, isUndefined } from 'lodash-es';
import { RootModel } from '../../rootModel';
import { DefaultStore } from '../../type';
import {
    getAdsBlock,
    GetAdsBlockPromise,
} from './adsBlocks.api';

const urlParams = new URLSearchParams(location.search);

const defaultStore: DefaultStore<
    AdsBlocks,
    GetADSBlockQuery
> = {
    currentPage: 1,
    query: {
        per_page: 30,
    },
    meta: {
        count: null,
    },
    hasMore: false,
    pages: {},
};

export const adsBlocks = createModel<RootModel>()({
    name: 'adsBlocks',
    state: defaultStore,
    reducers: {
        changePerPage: (
            state,
            payload: { perPage: number },
        ) => {
            return {
                ...state,
                perPage: payload.perPage,
            };
        },
        putCurrentPage: (
            state,
            payload: { triggerPage: number },
        ) => {
            return {
                ...state,
                currentPage: payload.triggerPage,
            };
        },
        putPage: (state, payload) => {
            return {
                ...state,
                meta: {
                    count: isUndefined(payload.count)
                        ? null
                        : payload.count,
                },
                pages: {
                    ...state.pages,
                    [`${state.currentPage}`]:
                        payload.results,
                },
            };
        },
        putPages: (state, payload) => {
            return { ...state, pages: payload };
        },
        putQuery: (state, payload: GetADSBlockQuery) => {
            return {
                ...state,
                query: { ...state.query, ...payload },
            };
        },
        clear: () => {
            return defaultStore;
        },
    },
    effects: (dispatch) => ({
        search: async (query: GetADSBlockQuery) => {
            await dispatch.adsBlocks.putQuery(query);
            await dispatch.adsBlocks.afterSearch(null);
        },
        afterSearch: debounce((_, state) => {
            dispatch.adsBlocks.get(state.adsBlocks.query);
        }, 1000),
        get: async (query, store): GetAdsBlockPromise => {
            const { query: storeQuery } = store.adsBlocks;

            const site = store.site.site?.id || '';
            const response = await getAdsBlock(
                {
                    ...query,
                    ...storeQuery,
                    is_count: true,
                    page: store.adsBlocks.currentPage,
                },
                site,
            );
            if (
                isOk<GetADSBlock, GetADSBlockError | null>(
                    response,
                )
            ) {
                dispatch.adsBlocks.putPage(response.value);
                return response;
            }
            return response;
        },
        nextPage: async (_: null, store) => {
            const { query, currentPage } = store.adsBlocks;
            await dispatch.adsBlocks.putCurrentPage({
                triggerPage: currentPage + 1,
            });
            await dispatch.adsBlocks.get({
                ...query,
                page: currentPage + 1,
            });
        },
        jumpTo: async (page: number) => {
            await dispatch.adsBlocks.putQuery({
                page: page,
            });
            await dispatch.adsBlocks.putCurrentPage({
                triggerPage: page,
            });
            dispatch.adsBlocks.get({
                page: page,
            });
        },
        chungeCountItemsOnPage: async (
            perPage: number,
            state,
        ) => {
            if (
                Math.floor(
                    state?.adsBlocks?.meta.count ??
                        0 / perPage,
                ) > 0
            ) {
                await dispatch.adsBlocks.changePerPage({
                    perPage,
                });
                await dispatch.adsBlocks.get({});
            }
        },
    }),
});
