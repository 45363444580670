const TELEGRAMM_SRC =
    'https://telegram.org/js/telegram-widget.js?13';

export const tgEmbed = () => {
    const containers = document.querySelectorAll(
        '.article-tg-sharing:empty',
    );

    if (containers.length === 0) return;

    const renderTelegram = (
        container: HTMLElement,
        src?: string,
    ) => {
        const urlObj = document.createElement('a');

        if (src) {
            urlObj.href = src;
        }

        const s = document.createElement('script');

        s.async = true;
        s.src = TELEGRAMM_SRC;
        s.dataset.width = '100%';

        if (src) {
            s.setAttribute(
                'data-telegram-post',
                urlObj.pathname,
            );
        }

        container.appendChild(s);
    };

    containers.forEach((container) => {
        if (container instanceof HTMLElement) {
            renderTelegram(
                container,
                container.dataset.telegramSource,
            );
        }
    });
};
