import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgFilter = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M7.5 13.5v-1.125h3V13.5h-3Zm-3-3.938V8.438h9v1.124h-9ZM2.25 5.626V4.5h13.5v1.125H2.25Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgFilter);
export default ForwardRef;
