import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../../models';

const useAdsBlocksStore = () => {
    const state = useStoreSelector(
        (state: RootState) => state.adsBlocks,
    );
    const loading = useStoreSelector(
        (state: RootState) =>
            state.loading.effects.adsBlocks,
    );

    return {
        ...state,

        loading: {
            get: loading.get,
            search: loading.search,
            chungeCountItemsOnPage:
                loading.chungeCountItemsOnPage,
        },
    };
};
const useAdsBlocksDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        get: dispatch.adsBlocks.get,
        nextPage: dispatch.adsBlocks.nextPage,
        search: dispatch.adsBlocks.search,
        clear: dispatch.adsBlocks.clear,
        jumpTo: dispatch.adsBlocks.jumpTo,
    };
};

export { useAdsBlocksStore, useAdsBlocksDispatch };
