import { Button, InputField } from '@mega/ui';
import React, { FC } from 'react';
import { IInitValuesForm } from './config/config';

interface HeadlineFormProps {
    values: IInitValuesForm;
    onSubmit: (
        e?: React.FormEvent<HTMLFormElement> | undefined,
    ) => void;
}

export const HeadlineForm: FC<HeadlineFormProps> = ({
    onSubmit,
    values,
}) => {
    return (
        <div>
            <br />
            <InputField
                name="title"
                placeholder="Название"
                dimension="medium"
                type="text"
                isRequired
                isError
            />
            <br />
            <InputField
                name="url"
                placeholder="https://"
                dimension="medium"
                type="text"
                isError
            />
            <br />
            <Button
                type={'button'}
                label={'Сохранить'}
                onClick={() => onSubmit()}
                classes={{
                    paper: {
                        color: 'dark',
                        variant: 'filled',
                    },
                }}
            />
        </div>
    );
};
