import React, { FC, memo } from 'react';

import { Input, Loader } from '@mega/ui';

import { useUploaderContext } from '../model/Uploader.provider';
import { DropZone } from '../ui/DropZone';
import { CardActions } from '../ui/CardActions';
import { FileCard } from '../ui/FileCard';
import { CardContent } from '../ui/CardContent';
import { EditFileTitle } from '../ui/EditFileTitle.field';

export interface UploaderProps {
    children?: never;
}

const Uploader: FC<UploaderProps> = () => {
    const { onDrop, filesQueue, files, deleteFile } =
        useUploaderContext((store) => ({
            files: store.files,
            filesQueue: store.filesQueue,
            onDrop: store.effects.onDrop,
            deleteFile: store.effects.deleteFile,
        }));

    // const updateFile = useCallback(
    //     (key: string) =>
    //         (event: ChangeEvent<HTMLInputElement>) => {
    //             dispatch({
    //                 type: 'UPDATE_TITLE_FILE',
    //                 payload: {
    //                     id: key,
    //                     title: event?.target.value,
    //                 },
    //             });
    //         },
    //     [],
    // );

    return (
        <section
            className="container"
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
            }}
        >
            <DropZone onDrop={onDrop} />
            <div
                style={{
                    display: 'flex',
                    gap: '30px',
                    width: '100%',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                }}
            >
                {Object.entries(files).map(
                    ([key, serverFile], index) => (
                        <FileCard key={key}>
                            <CardActions
                                deleteFile={deleteFile(
                                    key,
                                    serverFile.id,
                                )}
                                key={key}
                                serverFile={serverFile}
                                order={index + 1}
                            />
                            <CardContent>
                                <EditFileTitle id={key} />
                            </CardContent>
                        </FileCard>
                    ),
                )}
                {Object.entries(filesQueue).map(
                    ([key, clientFile]) => (
                        <FileCard key={key}>
                            {clientFile.loading && (
                                <Loader size="small" />
                            )}
                        </FileCard>
                    ),
                )}
            </div>
        </section>
    );
};

const MemoUploader = memo(Uploader);
export { MemoUploader as Uploader };
