import React, { FC, useEffect } from 'react';
import * as Yup from 'yup';
import { isEqual } from 'lodash-es';
import { useParams } from 'react-router-dom';
import { Formik, Form, useFormikContext } from 'formik';
import { extraClass } from './EmployeeEdit.css';

import {
    useEmployeeStore,
    useEmployee,
    useAuthorDispatch,
    useAuthorStore,
} from '@mega/store';

import { Button, FormLayout, Row, Paper } from '@mega/ui';
import { EmployeeFormDataUpdate } from '@mega/api';
import { EmployeeFormBody } from '../EmployeeFormBody';

const validationSchema = Yup.object({
    is_active: Yup.boolean(),
    first_name: Yup.string().required(
        'Это поле обязательное',
    ),
    last_name: Yup.string().required(
        'Это поле обязательное',
    ),
    patronymic: Yup.string(),
    email: Yup.string()
        .email('Невалидный адрес электронной почты')
        .required('Это поле обязательное'),
    username: Yup.string()
        .matches(
            /^[a-zA-Z0-9@.+/_'-]{1,150}$/,
            'Логин невалидный',
        )
        .required('Это поле обязательное'),
    telegram: Yup.string().matches(
        /^@[a-zA-Z0-9](?:[a-zA-Z0-9_]{0,28}[a-zA-Z0-9])?$/,
        'Телеграм невалидный',
    ),
    phone: Yup.string().matches(
        /^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/,
        'Номер телефона невалидный',
    ),
    author: Yup.object()
        .typeError('Это поле обязательное')
        .required('Это поле обязательное'),
    groups: Yup.array().required('Это поле обязательное'),
});

type EmployeeEditActionsProps = {
    resetForm: {
        resetFrom: () => void;
        isSubmitting: boolean;
    };
    initialValues: {};
};

type FormValues = {
    id: string;
    is_active: boolean;
    first_name: string;
    last_name: string;
    patronymic: string;
    email: string;
    username: string;
    author: null | {};
    telegram: string;
    phone: string;
    sites: {}[];
    groups: [];
    filter: string[];
};

const EmployeeEditActions: FC<EmployeeEditActionsProps> = ({
    resetForm,
    initialValues,
}): JSX.Element => {
    const { submitForm, values } = useFormikContext();
    return (
        <Paper
            style={{
                borderTop: '1px solid #EAEAEA',
            }}
            fullWidth
            color={'white'}
            variant="filled"
            classes={{
                padding: { size: '24x24' },
            }}
        >
            <Row gap="16">
                <Button
                    classes={{
                        paper: {
                            variant: 'filled',
                            color: 'dark',
                        },
                    }}
                    disabled={isEqual(
                        values,
                        initialValues,
                    )}
                    color="secondary"
                    size={'large'}
                    type={'submit'}
                    label={'Обновить'}
                    onClick={submitForm}
                />
                <Button
                    style={{
                        borderRadius: '4px',
                        border: '1px solid black',
                    }}
                    classes={{
                        paper: {
                            variant: 'filled',
                            color: 'white',
                        },
                    }}
                    disabled={Object.is(
                        values,
                        initialValues,
                    )}
                    color="secondary"
                    size={'large'}
                    type={'reset'}
                    label={'Сбросить изменения'}
                    onClick={() => resetForm()}
                />
            </Row>
        </Paper>
    );
};
const extractStringsFromBrackets = (
    s: string,
): string[] => {
    const start_index = s.indexOf('(');
    const end_index = s.indexOf(')', start_index);

    if (start_index !== -1 && end_index !== -1) {
        const content = s.substring(
            start_index + 1,
            end_index,
        );
        return content.split(', ');
    } else {
        return [];
    }
};

const EmployeeEdit: FC = () => {
    const { id } = useParams();
    const employee = useEmployeeStore();
    const { store: data } = employee;
    const { store, loading } = useAuthorStore();
    const { get } = useAuthorDispatch();
    const { updateFromForm } = useEmployee();

    let initialValues: FormValues = {
        id: id ? id : '',
        is_active: false,
        first_name: '',
        last_name: '',
        patronymic: '',
        email: '',
        username: '',
        author: null,
        telegram: '',
        phone: '',
        sites: [],
        groups: [],
        filter: [],
    };

    useEffect(() => {
        if (employee.loading.get.loading === false) {
            get({ payload: { id: data.author } });
        }
    }, [employee.loading.get.loading]);

    if (loading.get.loading === false) {
        const listSites = Array.isArray(store.sites)
            ? `(${store.sites
                  .map((el) => el.id)
                  .join(', ')})`
            : '';

        initialValues = {
            ...initialValues,
            author: {
                id: store.id,
                label: `${store.name} ${listSites}`,
            },
            sites: store.sites,
        };
    }

    if (employee.loading.get.loading === false) {
        initialValues = {
            ...initialValues,
            is_active: data.is_active || false,
            first_name: data.first_name || '',
            last_name: data.last_name || '',
            patronymic: data.patronymic || '',
            email: data.email || '',
            username: data.username || '',
            telegram: data.telegram || '',
            phone: data.phone || '',
            groups: data.groups || [],
        };
    }

    const handleSubmit = (values, actions) => {
        const result: EmployeeFormDataUpdate = {
            ...values,
            author: values.author.id,
            sites: extractStringsFromBrackets(
                values.author.label,
            ),
            groups: values.groups.map((el) => el.id),
        };
        updateFromForm(result, actions);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ resetForm }) => (
                <Form>
                    <FormLayout
                        actions={
                            <EmployeeEditActions
                                resetForm={resetForm}
                                initialValues={
                                    initialValues
                                }
                            />
                        }
                        className={extraClass}
                    >
                        <EmployeeFormBody type="edit" />
                    </FormLayout>
                </Form>
            )}
        </Formik>
    );
};

export { EmployeeEdit };
