/* eslint-disable @typescript-eslint/ban-types */
import React, { PropsWithChildren } from 'react';
import { components, InputProps } from 'react-select';

type InputOverrideProps = InputProps<{}, true>;

const Input = (
    props: PropsWithChildren<
        InputProps<Record<string, unknown>, true>
    >,
) => <components.Input {...props} />;

export { Input };
export default Input;
