import type { PluginTemplate } from '@apostroph/types';

export enum HorizontalLineEnum {
    type = 'HORIZONTAL_LINE',
}

export interface HorizontalLineProps {
    id: string;
    type: string;
    parentId: string | null;
}

export type MarginValues = '15' | '30' | 'none';

export type HorizontalLineMeta = {
    marginTop?: MarginValues;
    marginBottom?: MarginValues;
};

export type HorizontalLinePlugin = PluginTemplate<
    HorizontalLineEnum.type,
    HorizontalLineMeta
>;
export type HorizontalLinePluginDefault = Omit<
    HorizontalLinePlugin,
    'id'
>;
