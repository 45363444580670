import React, { FC, ReactElement } from 'react';

import { assignInlineVars } from '@vanilla-extract/dynamic';

import { baseClasses, BaseClasses } from '@mega/styles';

export interface BaseProps {
    children?: ReactElement;
    classname?: string;
    width?: string;
    height?: string;
}

const Base: FC<BaseProps & BaseClasses['recipe']> = ({
    children,
    classname = '',
    color = 'transparent',
    width = '100%',
    height = 'auto',
}) => (
    <div
        style={assignInlineVars({
            [baseClasses.vars.width]: width,
            [baseClasses.vars.height]: height,
        })}
        className={[
            baseClasses.recipe({ color }),
            classname,
        ].join(' ')}
    >
        {children}
    </div>
);

export { Base };
