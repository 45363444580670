import {
    RootState,
    store,
    useStoreSelector,
} from '../models';
import { useUserInfo } from './User';
import { definitions } from '@mega/api';

const selectNavigationConfig = store.select((models) => ({
    permissionConfig: models.settings.getPermissonConfig,
}));

const getPermissionNavigation = (
    nav: definitions['DashboardNavigation'][],
    rootPermissions: string[],
) => {
    return nav
        .map((item) => {
            if (
                item.model &&
                rootPermissions.includes(item?.model)
            ) {
                return item;
            }
            if (
                rootPermissions.some(
                    (permissionItem) =>
                        item.children &&
                        item.children
                            ?.map(
                                (child: any) => child.model,
                            )
                            ?.indexOf(permissionItem) >= 0,
                )
            ) {
                return {
                    ...item,
                    children: item.children?.filter(
                        (child) =>
                            child.model &&
                            rootPermissions.includes(
                                child.model,
                            ),
                    ),
                };
            }
        })
        .filter(
            (item) => item,
        ) as definitions['DashboardNavigation'][];
};

const useSettings = () => {
    const { rootPermissions } = useUserInfo();
    const { models, navigation, config } = useStoreSelector(
        (store: RootState) => store.settings,
    );
    const { permissionConfig } = useStoreSelector(
        selectNavigationConfig,
    );

    const permissionNavigation = getPermissionNavigation(
        navigation,
        rootPermissions,
    );

    return {
        config,
        models,
        navigation,
        permissionNavigation,
        permissionConfig,
    };
};

export { useSettings };
