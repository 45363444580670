import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgYandexRssOff = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M5.658.997 5.524.705H1.178l.378.73 4.631 8.935v5.554h3.97V10.37l4.621-8.935.378-.73H10.82l-.134.292-2.514 5.491L5.658.997Z"
            stroke="#EAEAEA"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgYandexRssOff);
export default ForwardRef;
