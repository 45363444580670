import React, { FC, ReactElement } from 'react';
import { ListItemInfoClasses } from './ListItemInfo.css';
import { Indi } from '@mega/ui';

import dayjs from 'dayjs';

type UrgencyTypes =
    | '2h'
    | '6h'
    | '12h'
    | '24h'
    | 'urgent'
    | 'not_urgent';

const ListItemInfo: FC<{
    label: string;
    data: string;
    embargo?: boolean;
    urgency?: boolean;
    urg?: UrgencyTypes;
}> = ({
    label,
    data,
    embargo = false,
    urgency = false,
    urg,
}) => {
    return embargo ? (
        <div
            style={{ alignItems: 'start' }}
            className={ListItemInfoClasses.listItemInfo}
        >
            <span>{label}</span>
            <div
                className={
                    ListItemInfoClasses.listIteminfoStatus
                }
            >
                <Indi label={'Эмбарго'} colors={'wip'} />
                <div>
                    {dayjs(data).format(
                        'DD.MM.YYYY до HH:mm',
                    )}
                </div>
            </div>
        </div>
    ) : urgency ? (
        <div className={ListItemInfoClasses.listItemInfo}>
            <span>{label}:</span>
            <Indi label={data} colors={urg} wrap={true} />
        </div>
    ) : (
        <div className={ListItemInfoClasses.listItemInfo}>
            <span>{label ? `${label}:` : ''}</span>
            <Indi
                label={data}
                colors={'deleted'}
                wrap={true}
            />
        </div>
    );
};

export { ListItemInfo };
export default ListItemInfo;
