import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { base, listing } from './Listing.css';
import { pagination } from '../../../VideoModal/VideoModal.css';
import { Pagination } from '@mui/material';
import { usePagination } from '../../../VideoModal/usePagination';
import { Card } from '../Card';
import { debounce } from 'lodash-es';
import * as CLIENT from '@mega/api';
import {
    Mediafile,
    PaginatedGenericList,
} from '@mega/api/typesMS';
import { Button, Loader, Typography } from '@mega/ui';
import { useFormikContext } from 'formik';
import { FormValues, MediaFormat } from '../../types';

const perPage = 9;

const { getFiles } =
    CLIENT.mediashareApiClient.Mediashare();

export const Listing = () => {
    const [mediaList, setMediaList] = useState<Mediafile[]>(
        [],
    );

    const { values, setFieldValue } =
        useFormikContext<FormValues>();
    const [isLoaded, setIsLoaded] = useState(false);
    const [count, setCount] = useState<number>(0);
    const { page, setPage, totalPages } = usePagination({
        count,
        perPage,
    });

    const {
        places,
        events,
        people,
        blank,
        search,
        type,
        fileIdList,
        isMulti,
    } = values;

    const getFilesMedia = async (query: {
        search?: string;
        page?: number;
        per_page?: number;
        tags__id__in?: string;
        media_type: MediaFormat;
    }) => {
        setIsLoaded(false);
        const res = await getFiles?.(query, {});

        if (res?.data) {
            setIsLoaded(true);
            const { results, count } =
                res.data as PaginatedGenericList<Mediafile>;
            setCount(count);
            setFieldValue('total', count);
            setMediaList(results);
        }
    };

    const searchArticleVideos = (page: number = 1) => {
        const tags__id__in = [
            ...places,
            ...events,
            ...people,
            ...blank,
        ].map((item) => item.id);
        setPage(page);

        getFilesMedia({
            search,
            page,
            per_page: perPage,
            tags__id__in:
                tags__id__in?.toString() || undefined,
            media_type: type,
        });
    };

    const getDebounceFunc = debounce(
        (page?: number) => searchArticleVideos(page),
        300,
    );

    const delayedSearch = useCallback(getDebounceFunc, [
        search,
        places,
        events,
        people,
        blank,
    ]);

    useEffect(() => {
        delayedSearch();
        return delayedSearch.cancel;
    }, [delayedSearch]);

    const toggleList = useCallback(
        (media: Mediafile) => {
            let curObject = { ...fileIdList };

            if (!!curObject[media.id]) {
                delete curObject[media.id];
            } else {
                isMulti
                    ? (curObject[media.id] = media)
                    : (curObject = { [media.id]: media });
            }

            return setFieldValue('fileIdList', curObject);
        },
        [fileIdList],
    );

    if (!isLoaded) return <Loader size="small" />;

    if (isLoaded && !count) {
        return (
            <Typography size={'18'}>
                Ничего не найдено
            </Typography>
        );
    }
    return (
        <div className={base}>
            <div className={listing}>
                {!!mediaList.length &&
                    mediaList.map((media) => {
                        const isActive =
                            !!fileIdList[media.id];
                        return (
                            <Card
                                isActive={isActive}
                                handleClick={toggleList}
                                media={media}
                                key={media.id}
                            />
                        );
                    })}
            </div>
            <div className={pagination}>
                <Pagination
                    count={totalPages}
                    onChange={(e, value) => {
                        delayedSearch(value);
                    }}
                    page={page}
                    size="small"
                />
            </div>
            <Button
                size={'large'}
                type={'submit'}
                label={'Сохранить'}
                classes={{
                    paper: {
                        color: 'dark',
                        variant: 'filled',
                    },
                }}
            />
        </div>
    );
};
