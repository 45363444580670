import React, { FC } from 'react';

import { Indi, Loader, Row, Typography } from '@mega/ui';

import {
    percentNumber,
    progressBar,
} from './ReportProgressBar.css';

import { ReportServerResponse } from 'src/Reports/Reports.types';

export interface ReportLoadingProps {
    response: ReportServerResponse;
    loading?: boolean;
}

const ReportProgressBar: FC<ReportLoadingProps> = ({
    response,
    loading,
}) => {
    const renderLoader = () => {
        if (!loading) return;

        return <Loader size="micro" />;
    };

    return (
        <>
            {response && loading && (
                <Row gap="16">
                    <Indi
                        loading={loading}
                        renderLoader={renderLoader}
                        label={
                            response.progress.stage.includes(
                                'Обработка',
                            )
                                ? 'Обработка'
                                : ''
                                ? 'Подготовка'
                                : response.progress.stage
                        }
                        colors={
                            response.progress.state ===
                            'processing'
                                ? 'in_progress'
                                : response.progress
                                      .state === 'completed'
                                ? 'success'
                                : 'transparent'
                        }
                    />

                    <div
                        className={progressBar}
                        style={{
                            width: `${response.progress.percent}%`,
                        }}
                    >
                        <Typography
                            size="12"
                            className={percentNumber}
                        >
                            {`${
                                response.progress.percent >
                                5
                                    ? response.progress.percent
                                          .toString()
                                          .slice(0, 4)
                                          .replace(
                                              '.',
                                              ',',
                                          ) + '%'
                                    : ''
                            }`}
                        </Typography>
                    </div>
                </Row>
            )}
        </>
    );
};

export { ReportProgressBar };
