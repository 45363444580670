import React, {
    FC,
    useEffect,
    KeyboardEventHandler,
} from 'react';
import { useFormikContext, useField } from 'formik';
import CreatableSelect from 'react-select/creatable';
import { IndicatorsContainer } from './IndicatorsContainer';
import { Placeholder } from './Placeholder';
import { typographyClasses } from '@mega/styles';
import { useSnackbar } from 'notistack';
import {
    placeholder as placeholderStyle,
    textHelper,
} from './CreatebleSelect.css';
import { IconButton, Icon, Typography } from '@mega/ui';
import { Close } from '@mega/icons';
import { FiSend } from 'react-icons/fi';

interface CreatableSelectProps {
    name: string;
    placeholder?: string;
}

export const CreatebleSelectField: FC<
    CreatableSelectProps
> = ({ name, placeholder = '' }) => {
    const { values, setFieldValue } = useFormikContext<{
        codes: string[];
    }>();

    const [field, meta, setters] = useField({
        name,
    });
    const [inputValue, setInputValue] = React.useState('');
    const [value, setValue] = React.useState<string[]>(
        field.value,
    );

    useEffect(() => {
        setFieldValue(field.name, value);
    }, [value]);

    const { enqueueSnackbar } = useSnackbar();

    const handleKeyDown: KeyboardEventHandler = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setValue((prev) => {
                    if (prev.includes(inputValue)) {
                        enqueueSnackbar(
                            'Это значение уже существует',
                            { variant: 'error' },
                        );
                        return [...prev];
                    } else {
                        return [...prev, inputValue];
                    }
                });
                setInputValue('');
                event.preventDefault();
        }
    };

    const createOption = () => {
        setValue((prev) => {
            return [...prev, inputValue];
        });
        setInputValue('');
    };

    const handleChange = (selectedOption: any) => {
        const transformOption = selectedOption.map(
            (item: { label: string; value: string }) =>
                item.label,
        );
        setValue(transformOption);
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
            }}
        >
            <CreatableSelect
                className={[
                    typographyClasses.recipe({
                        size: '14',
                        weight: 'regular',
                    }),
                ].join(' ')}
                components={{
                    DropdownIndicator: null,
                    IndicatorsContainer:
                        IndicatorsContainer({
                            className: '',
                            removeIcon: value.length ? (
                                <IconButton
                                    animate="none"
                                    classes={{
                                        paper: {
                                            color: 'gray',
                                        },
                                    }}
                                    onClick={() =>
                                        setValue([])
                                    }
                                >
                                    <Icon size="20">
                                        <Close
                                            style={{
                                                color: '#282828',
                                            }}
                                        />
                                    </Icon>
                                </IconButton>
                            ) : null,
                            sendIcon:
                                inputValue !== '' ? (
                                    <IconButton
                                        animate="none"
                                        classes={{
                                            paper: {
                                                color: 'gray',
                                            },
                                        }}
                                        onClick={
                                            createOption
                                        }
                                    >
                                        <Icon size="18">
                                            <FiSend
                                                style={{
                                                    transform:
                                                        'rotate(45deg)',
                                                    color: '#282828',
                                                }}
                                            />
                                        </Icon>
                                    </IconButton>
                                ) : null,
                        }),
                    Placeholder: Placeholder({
                        className: [
                            typographyClasses.recipe({
                                size: '12',
                                weight: 'regular',
                            }),
                            placeholderStyle,
                        ].join(' '),
                    }),
                }}
                inputValue={inputValue}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={(newValue) => {
                    handleChange(newValue);
                }}
                onInputChange={(newValue) =>
                    setInputValue(newValue)
                }
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                value={value.map((item) => {
                    return { value: item, label: item };
                })}
                styles={{
                    // control: (base) => ({
                    //     ...base,
                    //     padding: '8px',
                    //     background: '#f4f4f4',
                    //     borderColor: '#eaeaea',
                    //     borderRadius: '5px',
                    //     minHeight: '48px',
                    // }),
                    control: (base) =>
                        meta.error &&
                        !meta.value.length &&
                        meta.touched
                            ? {
                                  ...base,
                                  padding: '8px',
                                  background: '#f4f4f4',
                                  borderColor: 'red',
                                  borderRadius: '5px',
                                  minHeight: '48px',
                              }
                            : {
                                  ...base,
                                  padding: '8px',
                                  background: '#f4f4f4',
                                  borderColor: '#eaeaea',
                                  borderRadius: '5px',
                                  minHeight: '48px',
                              },
                    multiValue: (base) => ({
                        ...base,
                        background: '#fff',
                        border: '1px solid #878787',
                        padding: '2px',
                        borderRadius: '4px',
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        gap: '4px',
                    }),
                    multiValueRemove: (base) => ({
                        ...base,
                        cursor: 'pointer',
                        transition: 'color 0.5s',
                        ':hover': { color: 'red' },
                    }),
                    placeholder: (base) => ({
                        ...base,
                        color: '#878787',
                    }),
                }}
                theme={(theme): any => ({
                    colors: {},
                    spacing: '',
                })}
            />
            {meta.error &&
                !meta.value.length &&
                meta.touched && (
                    <Typography
                        className={textHelper}
                        size="14"
                    >
                        {meta.error}
                    </Typography>
                )}
        </div>
    );
};
