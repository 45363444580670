import React, {
    createContext,
    FC,
    ReactElement,
    useContext,
} from 'react';

interface CrumbsContextProps {
    inCrumbs: boolean;
}

export const CrumbsContext =
    createContext<CrumbsContextProps | null>(null);

export interface CrumbsContextProviderProps
    extends CrumbsContextProps {
    children: ReactElement | ReactElement[];
}

const CrumbsContextProvider: FC<
    CrumbsContextProviderProps
> = ({ inCrumbs, children }) => (
    <CrumbsContext.Provider value={{ inCrumbs }}>
        {children}
    </CrumbsContext.Provider>
);

const useCrumbsContext = () => {
    const context = useContext(CrumbsContext);
    return context;
};

export { CrumbsContextProvider, useCrumbsContext };
