import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/browser';
import './main.css';
import 'normalize.css';
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { store, storeContext } from '@mega/store';
import { SnackbarProvider } from 'notistack';

const rootRef = document.getElementById(
    'root',
) as HTMLDivElement;

rootRef.className = 'root';

const root = ReactDOM.createRoot(rootRef);

if (process.env?.REACT_APP_GLITCH_DSN) {
    Sentry.init({
        dsn: process.env?.REACT_APP_GLITCH_DSN,
        environment:
            process.env?.REACT_APP_ENVIRONMENT_SCOPE,
    });
}

root.render(
    <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        autoHideDuration={3000}
    >
        <BrowserRouter>
            <Provider
                store={store}
                //@ts-ignore
                context={storeContext}
            >
                <App />
            </Provider>
        </BrowserRouter>
    </SnackbarProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
