import React, { FC } from 'react';
import {
    Form,
    Formik,
    FormikHelpers,
    useFormikContext,
} from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Button,
    FormLayout,
    Icon,
    InputField,
    Paper,
    Stack,
    Typography,
    WithLabel,
} from '@mega/ui';
import { useSiteStore } from '@mega/store';
import type { Document } from '@mega/api';
import { documents } from '@mega/api';
import { DndFileLoader } from '@mega/core';
import {
    base,
    styledFilePreview,
    styledLink,
} from './DocumentCreate.css';
import dayjs from 'dayjs';
import { FiFile } from 'react-icons/fi';

export interface DocumentEditProps {
    initialValue?: Document;
    handleUpdate?: (
        value: Document,
        actions: FormikHelpers<Document>,
    ) => void;
}

const DocumentCreate: FC<DocumentEditProps> = ({
    handleUpdate = () => null,
    initialValue = {
        title: '',
        file: null,
    },
}) => {
    const site = useSiteStore();
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const navigate = useNavigate();

    const handleCreate = async (value: Document) => {
        if (!Boolean(site && site.id)) {
            return enqueueSnackbar(
                'Простите что-то пошло не так, обратитесь пожалуйста в тех поддержку',
                {
                    variant: 'error',
                },
            );
        }
        const formData = new FormData();
        formData.append('site', site?.id || '');
        for (const key in value) {
            if (
                Object.prototype.hasOwnProperty.call(
                    value,
                    key,
                )
            ) {
                // @ts-ignore
                formData.append(key, value[key]);
            }
        }

        try {
            const response = await documents?.create({
                data: formData,
                notifier: enqueueSnackbar,
            });
            if (response?.data) {
                navigate(
                    `/${site?.id || ''}/newspaperdocument/`,
                    {
                        state: location,
                    },
                );
            }
        } catch (e) {
            return {
                isOk: false,
                error: e,
            };
        }
    };

    return (
        <Formik
            validationSchema={Yup.object({
                title: Yup.string()
                    .min(
                        2,
                        'Это поле явно должно быть больше одного символа',
                    )
                    .required('Это поле обязательное'),
            })}
            // @ts-ignore
            initialValues={initialValue}
            onSubmit={handleCreate}
        >
            {({ values }) => {
                const formatDate = values.updated_at
                    ? dayjs(values.updated_at).format(
                          'DD.MM.YYYY в HH:mm',
                      )
                    : '';
                return (
                    <Form>
                        <FormLayout
                            actions={
                                <DocumentCreateActions
                                    // @ts-ignore
                                    handleUpdate={
                                        handleUpdate
                                    }
                                />
                            }
                        >
                            <Paper
                                variant="outline"
                                color="white"
                                classes={{
                                    padding: {
                                        size: '24x24',
                                    },
                                }}
                            >
                                <Stack gap="18">
                                    <InputField
                                        type="text"
                                        name={'title'}
                                        placeholder="Заголовок"
                                        dimension="medium"
                                    />
                                    <div className={base}>
                                        <WithLabel
                                            id={'file'}
                                            title="Файл"
                                        >
                                            <FileLoaderField name="file" />
                                        </WithLabel>
                                    </div>
                                    {formatDate && (
                                        <Typography size="14">
                                            Дата обновления:{' '}
                                            {formatDate}
                                        </Typography>
                                    )}
                                </Stack>
                            </Paper>
                        </FormLayout>
                    </Form>
                );
            }}
        </Formik>
    );
};

interface IFileLoaderField {
    name: keyof Document;
}

const FileLoaderField: FC<IFileLoaderField> = ({
    name,
}) => {
    const { values, setFieldValue } =
        useFormikContext<any>();
    const uploadHandler = (e: any) => {
        setFieldValue(name, e);
    };

    const getSrc = () => {
        if (values?.[name]) {
            return typeof values?.[name] !== 'string'
                ? URL.createObjectURL(values?.[name])
                : values?.[name];
        }
        return '';
    };
    return (
        <>
            <DndFileLoader onUpload={uploadHandler}>
                <div className={styledFilePreview}>
                    <Icon size="25">
                        <FiFile />
                    </Icon>
                </div>
            </DndFileLoader>
            {typeof values?.[name] === 'string' && (
                <a
                    className={styledLink}
                    target={'_blank'}
                    href={getSrc()}
                >
                    <span>{values?.[name]}</span>
                </a>
            )}
            {values?.[name]?.name && (
                <span>{values?.[name].name}</span>
            )}
        </>
    );
};

interface DocumentCreateActionForm {
    handleUpdate?: (
        value: Document,
        actions: FormikHelpers<Document>,
    ) => void;
}

const DocumentCreateActions: FC<
    DocumentCreateActionForm
> = ({ handleUpdate }) => {
    const { submitForm, isSubmitting, values, ...rest } =
        useFormikContext<Document>();

    return (
        <Paper
            style={{
                borderTop: '1px solid #EAEAEA',
            }}
            fullWidth
            color={'white'}
            variant="filled"
            classes={{
                padding: { size: '24x24' },
            }}
        >
            <Button
                classes={{
                    paper: {
                        variant: 'filled',
                        color: 'dark',
                    },
                }}
                disabled={isSubmitting}
                color="secondary"
                size={'large'}
                type={'submit'}
                label={values.id ? 'Обновить' : 'Создать'}
                onClick={
                    values.id && handleUpdate
                        ? // @ts-ignore
                          () => handleUpdate(values, rest)
                        : submitForm
                }
            />
        </Paper>
    );
};

export { DocumentCreate };
