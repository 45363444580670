import React, { FC, useEffect, useState } from 'react';

import {
    ErrorMessage,
    useField,
    useFormikContext,
} from 'formik';

import { Options } from '../types';
import {
    InputSelectMulti,
    InputSelectMultiProps,
} from './InputSelectMulti';

import { inputSelectMultiClasses } from './InputSelectMulti.css';

export interface InputSelectMultiFieldProps
    extends Omit<InputSelectMultiProps, 'isError'> {
    name: string;
    //   label?: string;
    placeholder?: string;
    isLabelHidden?: boolean;
}

const transform = (item: {
    value: string | number;
    label: string;
}) => ({
    id: item.value,
    label: item.label,
});

const reTransform = (item: {
    id: string | number;
    label: string;
}) => ({
    value: item.id,
    label: item.label,
});

const InputSelectMultiField: FC<
    InputSelectMultiFieldProps
> = ({ name, handleLoader, ...props }) => {
    const [defaultOptions, setDefaultOptions] =
        useState<Options>([]);
    const { values, setFieldValue } = useFormikContext();
    const [field, meta, setters] = useField({
        multiple: true,
        name,
    });

    const isError = meta.error === undefined ? false : true;

    const handleOnChange = (option: Options) => {
        const value = option.map(transform);
        const thisIsPropertyIntoFormikContext =
            typeof values === 'object' &&
            values !== null &&
            name in values;
        if (thisIsPropertyIntoFormikContext) {
            setters.setValue(value);
            setters.setTouched(true);
        } else {
            setFieldValue(field.name, value);
            setters.setTouched(true);
        }
    };

    useEffect(() => {
        (async () => {
            const def = await handleLoader('', 1);
            setDefaultOptions(def);
        })().catch(() => {});
    }, []);

    const value = field?.value?.map?.(reTransform) ?? [];

    return (
        <div>
            <InputSelectMulti
                {...props}
                handleLoader={handleLoader}
                key={field.name}
                actionOnSelectedOption={handleOnChange}
                value={value}
                defaultValue={value}
                defaultOptions={defaultOptions}
                isError={isError}
            />
            <div
                className={
                    inputSelectMultiClasses.errorMessage
                }
            >
                <ErrorMessage name={name} />
            </div>
        </div>
    );
};

export { InputSelectMultiField };
