"use client";

import type { Control } from "../../../../types/core";
import { Potokus } from "./Potokus";
import { PotokusEnum, type PotokusPluginDefault } from "./Potokus.types";

const PLUGIN_POTOKUS_NAME = PotokusEnum.type;

const DEFAULT_POTOKUS: PotokusPluginDefault = {
	type: PLUGIN_POTOKUS_NAME,
	meta: {},
	childrenElement: [],
};

const PLUGIN_POTOKUS = {
	[PLUGIN_POTOKUS_NAME]: {
		emptyObject: DEFAULT_POTOKUS,
		render: Potokus,
	},
};

const PLUGIN_POTOKUS_CONTROL: Control = {
	name: PLUGIN_POTOKUS_NAME,
	label: "Potokus",
	tooltip: "Создать заголовок",
	Icon: () => <></>,
};

export { DEFAULT_POTOKUS, PLUGIN_POTOKUS, PLUGIN_POTOKUS_CONTROL, PLUGIN_POTOKUS_NAME };
