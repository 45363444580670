import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import { styles as s } from './DraggableContainer.css';
import { useMainGridStore } from '@mega/store';

export interface DraggableContainerProps {
    type: string;
    id?: string;
    children: React.ReactElement;
    parentId?: string | null;
    meta?: any;
}

export const DraggableContainer: React.FC<
    DraggableContainerProps
> = (props) => {
    const { type, children, parentId, meta, id } = props;
    const store = useMainGridStore();
    const { settingsSmartGridIsOff, settingsIsOpen } =
        store;

    const { attributes, listeners, setNodeRef, transform } =
        useDraggable({
            id: id || type,
            data: { type, parentId, meta },
            // disabled: settingsSmartGridIsOff,
            disabled:
                settingsSmartGridIsOff || settingsIsOpen,
        });
    const style = transform
        ? {
              transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
          }
        : undefined;

    return (
        <div
            ref={setNodeRef}
            className={s.wrapper()}
            style={style}
            {...listeners}
            {...attributes}
        >
            {children}
        </div>
    );
};
