import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';
import { createSelector } from 'reselect';
import { RootModel } from '../../models/rootModel';
import { RematchRootState } from '@rematch/core';
import { RoomUser } from '../../models/type';

const selectArticlesRoom = (
    state: RematchRootState<RootModel>,
) => state.articlesRoom.room;

const selectArticlesRoomInfo = createSelector(
    selectArticlesRoom,
    (room) => {
        const pagesWithEditor = Object.entries(room).reduce(
            (acc, item) => {
                const [key, value] = item;

                return {
                    ...acc,
                    [key]: value.reduce<RoomUser | null>(
                        (acc, item) => {
                            const isNotReadonly =
                                !item?.read_only;
                            if (isNotReadonly) {
                                return item;
                            }
                            return acc;
                        },
                        null,
                    ),
                };
            },
            {},
        ) as {
            [key: string | number]: RoomUser | null;
        };
        return { pagesWithEditor };
    },
);

const useArticlesRoom = () => {
    const info = useStoreSelector(selectArticlesRoomInfo);
    return info;
};

const useArticlesRoomDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        checkPageRoom: dispatch.articlesRoom.checkPageRoom,
    };
};

export { useArticlesRoomDispatch, useArticlesRoom };
