import React, {
    ReactNode,
    useEffect,
    useRef,
    useState,
} from 'react';
import { ReactPortal } from '@pages/smartGrid/components/shared';
import { toolsStyles as s } from '@pages/smartGrid/components/shared/GridSettings/GridSettings.css';
import CloseIcon from '@mui/icons-material/Close';
import { useMainGridDispatch } from '@mega/store';

export type modalRenderProps = ({
    isOpen,
    handleClose,
}: {
    isOpen: boolean;
    handleClose: () => void;
}) => ReactNode | JSX.Element;

export interface GridSettingsProps {
    title?: string;
    isOpen?: boolean;
    setIsOpen: (isOpen: boolean) => void;
    render: modalRenderProps;
}

export const GridSettings: React.FC<GridSettingsProps> = ({
    isOpen,
    setIsOpen,
    title = 'Настройки',
    render,
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const containerWrapperRef =
        useRef<HTMLDivElement>(null);

    const { setSettingsIsOpen } = useMainGridDispatch();

    const handleClose = () => {
        setIsVisible(false);
        setTimeout(() => {
            setIsOpen(false);
        }, 300);
        setSettingsIsOpen(false);
    };

    useEffect(() => {
        if (isOpen) {
            setIsVisible(true);
            setSettingsIsOpen(true);
            containerWrapperRef.current?.focus({
                preventScroll: true,
            });
        }
    }, [isOpen]);

    useEffect(() => {
        const closeOnEscapeKey = (e: { key: string }) =>
            e.key === 'Escape' ? handleClose() : null;
        document.body.addEventListener(
            'keydown',
            closeOnEscapeKey,
        );
        return () => {
            document.body.removeEventListener(
                'keydown',
                closeOnEscapeKey,
            );
        };
    }, [handleClose]);

    if (!isOpen) return null;

    return (
        <ReactPortal customRoot={'#grid__settings'}>
            <div
                ref={containerWrapperRef}
                className={`${s.wrapper({ isVisible })}`}
                id={'not_draggable__container'}
                tabIndex={0}
            >
                <div className={s.header}>
                    <div className={s.title}>{title}</div>
                    <button
                        className={s.close}
                        type={'button'}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleClose();
                        }}
                    >
                        <CloseIcon />
                    </button>
                </div>
                <p className={s.alert}>
                    Для сохранения изменений не забудьте
                    нажать на кнопку
                </p>
                <div className={s.content}>
                    {render({ isOpen, handleClose })}
                </div>
            </div>
        </ReactPortal>
    );
};
