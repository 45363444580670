import React, { useState } from 'react';
import { MediaPickerProps } from './MediaPicker.types';
import { MediaPickerModal } from '../MediaPickerModal';
import { Button } from '@mega/ui';
import { icon } from '../ArticleCoverBlock/ArticleCoverBlock.css';
import { Mediashare as MediashareIcon } from '@mega/icons';
import { pickerWrapper } from './MediaPicker.css';

export const MediaPicker: React.FC<MediaPickerProps> = ({
    type,
    isMulti,
    handleSubmit,
    onOpen = () => {},
    onClose = () => {},
}) => {
    const [isOpen, setisOpen] = useState(false);
    const handleToggle = () => {
        setisOpen((prev) => {
            if (prev) {
                onClose();
            } else {
                onOpen();
            }
            return !prev;
        });
    };
    return (
        <>
            <div
                onClick={handleToggle}
                className={pickerWrapper}
            >
                <Button
                    classes={{
                        paper: {
                            color: 'white',
                            variant: 'filled',
                        },
                    }}
                    label={() => {
                        return (
                            <div className={icon}>
                                <MediashareIcon />
                            </div>
                        );
                    }}
                />
            </div>
            <MediaPickerModal
                isMulti={isMulti}
                type={type}
                isOpen={isOpen}
                onClose={() => {
                    setisOpen(false);
                    onClose();
                }}
                handleSubmit={handleSubmit}
            />
        </>
    );
};
