import React, { useEffect, FC } from 'react';
import { Article } from '@mega/api';
import { useFormikContext, useField } from 'formik';
import {
    Button,
    Divider,
    InputField as Input,
    InputTextareaField,
    Paper,
    Stack,
    WithLabel,
} from '@mega/ui';
import {
    ArticleCoverBlock as CoverBlock,
    EditorDataToHtml,
} from '@mega/core';
import { articleEditFormClasses } from '../ArticleEdit.css';
import { ArticleEditorField } from '@mega/editor';
import { getTypographedText } from '@mega/utils';
import {
    useApostrophDispatch,
    useApostrophStore,
} from '@mega/store';

interface ArticleFormBodyProps {}

export const ArticleFormBody: FC<
    ArticleFormBodyProps
> = () => {
    const { setIsFormatted, setIsSearchedInAgent } =
        useApostrophDispatch();
    const { isFormatted, isSearchedInAgent } =
        useApostrophStore();

    const fieldsArray: Array<keyof Article> = [
        'title',
        'explanation',
        'meta_title',
        'dzen_title',
    ];
    const { values, setFieldValue } =
        useFormikContext<Article>();

    useEffect(() => {
        if (isFormatted) {
            fieldsArray.forEach((field) => {
                setFieldValue(
                    field,
                    getTypographedText(
                        (values as any)[field],
                    ),
                );
            });
            setTimeout(
                () =>
                    setIsFormatted({
                        isFormatted: false,
                    }),
                1000,
            );
        }
    }, [isFormatted]);

    useEffect(() => {
        if (isSearchedInAgent) {
            fieldsArray.forEach((field) => {
                console.log({ field });
            });
            setTimeout(
                () =>
                    setIsSearchedInAgent({
                        isSearchedInAgent: false,
                    }),
                1000,
            );
        }
    }, [isSearchedInAgent]);

    return (
        <Paper
            variant="outline"
            color="white"
            classes={{
                padding: {
                    size: '24x24',
                },
            }}
        >
            <Stack gap="18">
                <EditorDataToHtml
                    updater="editor_data"
                    updated="editor_html"
                />
                <InputTextareaField
                    name={'title'}
                    placeholder="Основной заголовок"
                    dimension="medium"
                    limit={120}
                    color={'transparent'}
                />
                <InputTextareaField
                    name={'explanation'}
                    placeholder="Заголовок для Дзен.Новостей"
                    dimension="medium"
                    limit={80}
                    color={'transparent'}
                />
                <InputTextareaField
                    name={'dzen_title'}
                    placeholder="Заголовок для Дзена (платформы)"
                    dimension="medium"
                    limit={120}
                    color={'transparent'}
                />
                <Input
                    type="text"
                    name={'meta_title'}
                    placeholder="Заголовок для поисковиков (SEO)"
                    dimension="medium"
                />

                <WithLabel
                    id={'slug'}
                    title="URL страницы формируется автоматически"
                >
                    <Input
                        type="text"
                        name={'slug'}
                        dimension="medium"
                    />
                </WithLabel>
                <Divider />
                <CoverBlock />
                <Divider />
                <div
                    className={
                        articleEditFormClasses.editorWrapper
                    }
                >
                    <WithLabel
                        id={'slug'}
                        title="Редактор контента"
                    >
                        <ArticleEditorField name="editor_data" />
                    </WithLabel>
                </div>
            </Stack>
        </Paper>
    );
};
