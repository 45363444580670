import React, { FC } from 'react';
import { Button, EntityListLayout } from '@mega/ui';
import { Add } from '@mega/icons';
import { ArticlesFilters } from '@mega/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArticlesListing } from './ArticlesListing';

interface ArticlesProps {
    children?: never;
}

export const Articles: FC<ArticlesProps> = () => {
    let navigate = useNavigate();
    let location = useLocation();
    return (
        <EntityListLayout
            gap="24"
            setting={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: '18px',
                    }}
                >
                    <ArticlesFilters />
                    <Button
                        size={'large'}
                        endIcon={<Add />}
                        label="Добавить"
                        classes={{
                            paper: {
                                variant: 'filled',
                                color: 'dark',
                            },
                        }}
                        onClick={() => {
                            navigate(`add`, {
                                replace: true,
                                state: location,
                            });
                        }}
                    />
                </div>
            }
        >
            <ArticlesListing />
        </EntityListLayout>
    );
};
