import { definitions, operations } from '@mega/api';
import {
    OpArgType,
    OpErrorType,
    OpReturnType,
} from 'openapi-typescript-fetch';

export interface IisOk<T> {
    isOk: true;
    value: T;
}

export interface IisNotOk<T> {
    isOk: false;
    value: T;
}

export function isOk<T, E>(
    test: IisOk<T> | IisNotOk<E>,
): test is IisOk<T> {
    return (test as IisOk<T>).isOk === true;
}

export function isNotOk<T, E>(
    test: IisOk<T> | IisNotOk<E>,
): test is IisOk<T> {
    return (test as IisNotOk<E>).isOk === false;
}

/* #region  Articles type */

export type Articles = definitions['AdminArticleList'];
export type ArticlesQuery =
    operations['admin_articles_articles_list']['parameters']['query'];
export type ArticlesWithPagination =
    operations['admin_articles_articles_list']['responses']['200']['schema'];

/* #endregion */

/* #region  Article */

export type Article = definitions['AdminArticle'];

export interface ArticleForm
    extends Omit<Article, 'article_type' | 'title'> {
    article_type: {
        value: string;
        label: string;
    };
    title?: string;
}

export interface ArticleFormExtend extends ArticleForm {
    isSave: boolean;
    isPublish: boolean;
    isUnPublish: boolean;
}

export type ArticleCreate =
    operations['admin_articles_articles_create']['parameters']['body']['data'];

export type ArticleUpdate =
    operations['admin_articles_articles_partial_update']['parameters']['body']['data'];

export type UpdateBulk =
    operations['admin_articles_articles_bulk_update']['parameters']['body']['data'];

/* #endregion */

/* #region  MainGrid */

export type MainGrid = definitions['AdminMainGrid'];
export type MainGridQuery =
    operations['admin_website_main-grid_list']['parameters']['query'];
export type MainGridWithPagination =
    operations['admin_website_main-grid_list']['responses']['200']['schema'];

export type MainGridCreate =
    operations['admin_website_main-grid_create']['parameters']['body']['data'];

export type MainGridUpdate =
    operations['admin_website_main-grid_update']['parameters']['body']['data'];
export type MainGridGetItems =
    operations['admin_website_main-grid_main_grid_items']['parameters']['body']['data'];

/* #endregion */

/* #region  Tags */

export type Tags = definitions['AdminTagList'];
export type TagsQuery =
    operations['admin_articles_tags_list']['parameters']['query'];
export type TagsWithPagination =
    operations['admin_articles_tags_list']['responses']['200']['schema'];
export type Tag = definitions['AdminTag'];

export type TagCreate =
    operations['admin_articles_tags_create']['parameters']['body']['data'];
export type TagUpdate =
    operations['admin_articles_tags_update']['parameters']['body']['data'];

/* #endregion */

/* #region  Stories */

export type Stories = definitions['AdminStoryV2'];
export type StoriesQuery =
    operations['admin_stories_stories_list']['parameters']['query'];
export type StoriesWithPagination =
    operations['admin_stories_stories_list']['responses']['200']['schema'];
export type Story = definitions['AdminStoryV2'];
export type StoryCard = definitions['AdminStoryCard'];

export type StoryCreate =
    operations['admin_stories_stories_create']['parameters']['body']['data'];
export type StoryRemove =
    operations['admin_stories_stories_create']['parameters']['body']['data'];
export type StoryUpdate =
    operations['admin_stories_stories_update']['parameters']['body']['data'];

/* #endregion */

/* #region  BO */

export type BannedOrganizations =
    definitions['AdminBannedOrganization'];
export type BannedOrganizationsQuery =
    operations['admin_articles_banned-organizations_list']['parameters']['query'];
export type BannedOrganizationsWithPagination =
    operations['admin_articles_banned-organizations_list']['responses']['200']['schema'];
export type BannedOrganization =
    definitions['AdminBannedOrganization'];

export type BannedOrganizationCreate =
    operations['admin_articles_banned-organizations_create']['parameters']['body']['data'];
export type BannedOrganizationUpdate =
    operations['admin_articles_banned-organizations_update']['parameters']['body']['data'];

/* #endregion */

/* #region  Categories */

export type Categories = definitions['AdminCategoryList'];
export type Category = definitions['AdminCategory'];
export type CategoriesQuery =
    operations['admin_articles_categories_list']['parameters']['query'];
export type CategoriesWithPagination =
    operations['admin_articles_categories_list']['responses']['200']['schema'];

export type CategoryCreate = OpArgType<
    operations['admin_articles_categories_create']
>;

export type CategoryUpdate =
    operations['admin_articles_categories_update']['parameters']['body']['data'];

/* #endregion */

// /* #region  Authors */

export type Authors = definitions['AdminAuthorList'];
export type AuthorsQuery =
    operations['admin_articles_authors_list']['parameters']['query'];
export type AuthorsWithPagination =
    operations['admin_articles_authors_list']['responses']['200']['schema'];
export type Author = definitions['AdminAuthor'];

export type AuthorCreate =
    operations['admin_articles_authors_create']['parameters']['body']['data'];
export type AuthorUpdate =
    operations['admin_articles_authors_update']['parameters']['body']['data'];

// /* #endregion */

/* #region  AdminHostVideoList type */

export type Videos = definitions['AdminHostVideoList'];
export type VideosQuery =
    operations['admin_articles_videos_list']['parameters']['query'];
export type VideosWithPagination =
    operations['admin_articles_videos_list']['responses']['200']['schema'];

/* #endregion */

/* #region  AdminHostVideoDetail */

export type Video = definitions['AdminHostVideoDetail'];

export type VideoCreate =
    definitions['AdminHostVideoUpload'];

export type VideoUpdate =
    operations['admin_articles_videos_partial_update']['parameters']['formData'];

/* #endregion */

/* #region  AdminInfoUnitTaskList type */

export type InfounittaskArray =
    definitions['AdminInfoUnitTaskList'];
export type InfounittaskQuery =
    operations['admin_info-units_tasks_list']['parameters']['query'];
export type InfounittaskWithPagination =
    operations['admin_info-units_tasks_list']['responses']['200']['schema'];

export type InfounittaskCreate = OpArgType<
    operations['admin_info-units_tasks_create']
>;

export type InfounittaskUpdate = OpArgType<
    operations['admin_info-units_tasks_update']
>;

/* #endregion */

/* #region  Copyright */

export type Copyrights = OpReturnType<
    operations['admin_articles_copyrights_list']
>['results'][0];
export type CopyrightsWithPagination = OpReturnType<
    operations['admin_articles_copyrights_list']
>;

export type CopyrightsQuery = OpArgType<
    operations['admin_articles_copyrights_list']
>;

export type Copyright = definitions['AdminCopyright'];

export type CopyrightCreate = OpArgType<
    operations['admin_articles_copyrights_create']
>;
export type CopyrightUpdate = OpArgType<
    operations['admin_articles_copyrights_update']
>;

/* #region  SourcesReports */

export type SourcesReports = OpReturnType<
    operations['admin_reports_sources_list']
>['results'][0];
export type SourcesReportsWithPagination = OpReturnType<
    operations['admin_reports_sources_list']
>;

export type SourcesReportsQuery = OpArgType<
    operations['admin_reports_sources_list']
>;

export type SourcesReport =
    definitions['AdminReportSource'];

export type SourcesReportCreate = OpArgType<
    operations['admin_reports_sources_create']
>;
export type SourcesReportUpdate = OpArgType<
    operations['admin_reports_sources_update']
>;

/* #endregion */

/* #region  Copyright */

export type Reposts = OpReturnType<
    operations['admin_website_reposts_list']
>['results'][0];
export type RepostsWithPagination = OpReturnType<
    operations['admin_website_reposts_list']
>;

export type RepostsQuery = OpArgType<
    operations['admin_website_reposts_list']
>;

export type Repost = definitions['AdminRepost'];

export type RepostCreate = OpArgType<
    operations['admin_website_reposts_create']
>;
export type RepostUpdate = OpArgType<
    operations['admin_website_reposts_update']
>;

export type UserHistory = {
    id: number;
    label: string;
};

export type RevisionHistory = {
    comment: string;
    date_created: string;
    user: UserHistory;
};

export type CommitHistory = {
    id: number;
    revision: RevisionHistory;
};

export type PaginatedHistory = {
    count: number;
    next: string;
    previous: string;
    results: Array<CommitHistory>;
};

/* #region  Papers */

export type Papers = definitions['AdminIssue'];
export type Paper = definitions['AdminIssue'];
export type PapersQuery =
    operations['admin_papers_issues_list']['parameters']['query'];
export type PapersWithPagination =
    operations['admin_papers_issues_list']['responses']['200']['schema'];
export type PaperCreate = OpArgType<
    operations['admin_papers_issues_create']
>;
export type PaperUpdate =
    operations['admin_papers_issues_update']['parameters'];
export type Documents =
    definitions['AdminNewspaperDocument'];
export type Document =
    definitions['AdminNewspaperDocument'];
export type DocumentsQuery =
    operations['admin_papers_documents_list']['parameters']['query'];
export type DocumentsWithPagination =
    operations['admin_papers_documents_list']['responses']['200']['schema'];
export type DocumentCreate = OpArgType<
    operations['admin_papers_documents_create']
>;
export type DocumentUpdate =
    operations['admin_papers_documents_update']['parameters'];

/* #endregion */

// type b =
//     operations['admin_website_main-page-streams_create'];
// type c =
//     operations['admin_website_main-page-streams_delete'];
// type a = operations['admin_website_main-page-streams_list'];
// type d = operations['admin_website_main-page-streams_partial_update'];
// type f = operations['admin_website_main-page-streams_read'];
// type g = operations['admin_website_main-page-streams_update'];
//!SECTION Streams GET
export type GetStreams = OpReturnType<
    operations['admin_website_main-page-streams_list']
>;
export type Streams = OpReturnType<
    operations['admin_website_main-page-streams_list']
>['results'];

export type StreamEntity = Streams[number];

export type GetStreamsQuery = OpArgType<
    operations['admin_website_main-page-streams_list']
>;
export type GetStreamsError = OpErrorType<
    operations['admin_website_main-page-streams_list']
>;

//!SECTION Stream GET

export type GetStream = OpReturnType<
    operations['admin_website_main-page-streams_read']
>;
export type GetStreamQuery = OpArgType<
    operations['admin_website_main-page-streams_read']
>;

export type GetStreamError = OpErrorType<
    operations['admin_website_main-page-streams_read']
>;

//!!SECTION Stream
//!SECTION Stream GET

export type CreateStream = OpReturnType<
    operations['admin_website_main-page-streams_create']
>;
export type CreateStreamQuery = OpArgType<
    operations['admin_website_main-page-streams_create']
>;

export type CreateStreamError = OpErrorType<
    operations['admin_website_main-page-streams_create']
>;

//!SECTION Stream Partial Update

export type PartialUpdateStream = OpReturnType<
    operations['admin_website_main-page-streams_partial_update']
>;
export type PartialUpdateStreamQuery = OpArgType<
    operations['admin_website_main-page-streams_partial_update']
>;
export type PartialUpdateStreamError = OpErrorType<
    operations['admin_website_main-page-streams_partial_update']
>;

//!SECTION Stream Update

export type UpdateStream = OpReturnType<
    operations['admin_website_main-page-streams_update']
>;
export type UpdateStreamQuery = OpArgType<
    operations['admin_website_main-page-streams_update']
>;
export type UpdateStreamError = OpErrorType<
    operations['admin_website_main-page-streams_update']
>;

//!SECTION Stream Delete

export type DeleteStream = OpReturnType<
    operations['admin_website_main-page-streams_delete']
>;
export type DeleteStreamQuery = OpArgType<
    operations['admin_website_main-page-streams_delete']
>;
export type DeleteStreamError = OpErrorType<
    operations['admin_website_main-page-streams_delete']
>;

//!!SECTION ADS block
//!SECTION ADS block GET

export type GetADSBlock = OpReturnType<
    operations['admin_ads_blocks_list']
>;
export type GetADSBlockQuery = OpArgType<
    operations['admin_ads_blocks_list']
>;

export type GetADSBlockError = OpErrorType<
    operations['admin_ads_blocks_list']
>;

export type AdsBlocks = GetADSBlock['results'];
export type AdsBlockEntity = AdsBlocks[number];
//!SECTION ADS block getblyId

export type GetByIdADSBlock = OpReturnType<
    operations['admin_ads_blocks_read']
>;
export type GetByIdADSBlockQuery = OpArgType<
    operations['admin_ads_blocks_read']
>;

export type GetByIdADSBlockError = OpErrorType<
    operations['admin_ads_blocks_read']
>;

//!SECTION ADS block Create

export type CreateADSBlock = OpReturnType<
    operations['admin_ads_blocks_create']
>;

export type CreateADSBlockQuery = Pick<
    OpArgType<operations['admin_ads_blocks_create']>,
    | 'is_active'
    | 'platform'
    | 'title'
    | 'slot'
    | 'body_script'
>;

export type CreateADSBlockError = OpErrorType<
    operations['admin_ads_blocks_create']
>;

//!SECTION ADS block Partial Update

export type PartialUpdateADSBlock = OpReturnType<
    operations['admin_ads_blocks_partial_update']
>;
export type PartialUpdateADSBlockQuery = OpArgType<
    operations['admin_ads_blocks_partial_update']
>;

export type PartialUpdateADSBlockError = OpErrorType<
    operations['admin_ads_blocks_partial_update']
>;

//!SECTION ADS block Delete

export type DeleteADSBlock = OpReturnType<
    operations['admin_ads_blocks_delete']
>;
export type DeleteADSBlockQuery = OpArgType<
    operations['admin_ads_blocks_delete']
>;

export type DeleteADSBlockError = OpErrorType<
    operations['admin_ads_blocks_delete']
>;

//!!SECTION ADS HeadScript
//!SECTION ADS HeadScript GET

export type GetADSHeadScript = OpReturnType<
    operations['admin_ads_head-scripts_list']
>;
export type GetADSHeadScriptQuery = OpArgType<
    operations['admin_ads_head-scripts_list']
>;

export type GetADSHeadScriptError = OpErrorType<
    operations['admin_ads_head-scripts_list']
>;
export type AdsHeadScripts = GetADSHeadScript['results'];
export type AdsHeadScriptsEntity = AdsHeadScripts[number];

//!SECTION ADS HeadScript GET by id

export type GetByIdADSHeadScript = OpReturnType<
    operations['admin_ads_head-scripts_read']
>;
export type GetByIdADSHeadScriptQuery = OpArgType<
    operations['admin_ads_head-scripts_read']
>;

export type GetByIdADSHeadScriptError = OpErrorType<
    operations['admin_ads_head-scripts_read']
>;

//!SECTION ADS HeadScript Create

export type CreateADSHeadScript = OpReturnType<
    operations['admin_ads_head-scripts_create']
>;
export type CreateADSHeadScriptQuery = OpArgType<
    operations['admin_ads_head-scripts_create']
>;

export type CreateADSHeadScriptError = OpErrorType<
    operations['admin_ads_head-scripts_create']
>;

//!SECTION ADS HeadScript Update

export type PartialUpdateADSHeadScript = OpReturnType<
    operations['admin_ads_head-scripts_partial_update']
>;
export type PartialUpdateADSHeadScriptQuery = OpArgType<
    operations['admin_ads_head-scripts_partial_update']
>;

export type PartialUpdateADSHeadScriptError = OpErrorType<
    operations['admin_ads_head-scripts_partial_update']
>;

//!SECTION ADS HeadScript Delete

export type DeleteADSHeadScript = OpReturnType<
    operations['admin_ads_head-scripts_delete']
>;
export type DeleteADSHeadScriptQuery = OpArgType<
    operations['admin_ads_head-scripts_delete']
>;

export type DeleteADSHeadScriptError = OpErrorType<
    operations['admin_ads_head-scripts_delete']
>;

//!!SECTION  infiniteMix
//!SECTION  infiniteMix GET

export type GetInfiniteMixList = OpReturnType<
    operations['admin_articles_infinite-mix_list']
>;
export type InfiniteMixList = GetInfiniteMixList['results'];
export type InfiniteMixEntity = InfiniteMixList[number];

export type GetInfiniteMixQuery = OpArgType<
    operations['admin_articles_infinite-mix_list']
>;

export type GetInfiniteMixError = OpErrorType<
    operations['admin_articles_infinite-mix_list']
>;

//!SECTION  infiniteMix GET by id

export type GetByIdInfiniteMix = OpReturnType<
    operations['admin_articles_infinite-mix_read']
>;
export type GetByIdInfiniteMixQuery = OpArgType<
    operations['admin_articles_infinite-mix_read']
>;

export type GetByIdInfiniteMixError = OpErrorType<
    operations['admin_articles_infinite-mix_read']
>;

//!SECTION  infiniteMix Create

export type CreateInfiniteMix = OpReturnType<
    operations['admin_articles_infinite-mix_create']
>;
export type CreateInfiniteMixQuery = OpArgType<
    operations['admin_articles_infinite-mix_create']
>;

export type CreateInfiniteMixError = OpErrorType<
    operations['admin_articles_infinite-mix_create']
>;

//!SECTION infiniteMix Update

export type PartialUpdateInfiniteMix = OpReturnType<
    operations['admin_articles_infinite-mix_partial_update']
>;
export type PartialUpdateInfiniteMixQuery = OpArgType<
    operations['admin_articles_infinite-mix_partial_update']
>;

export type PartialUpdateInfiniteMixError = OpErrorType<
    operations['admin_articles_infinite-mix_partial_update']
>;

//!SECTION infiniteMix Delete

export type DeleteInfiniteMix = OpReturnType<
    operations['admin_articles_infinite-mix_delete']
>;
export type DeleteInfiniteMixQuery = OpArgType<
    operations['admin_articles_infinite-mix_delete']
>;

export type DeleteInfiniteMixError = OpErrorType<
    operations['admin_articles_infinite-mix_delete']
>;

//!SECTION multipost Get

export type GetMultipostingArticles = OpReturnType<
    operations['admin_articles_articles_multiposting_read']
>;
export type GetMultipostingArticlesQuery = OpArgType<
    operations['admin_articles_articles_multiposting_read']
>;

export type GetMultipostingArticlesError = OpErrorType<
    operations['admin_articles_articles_multiposting_read']
>;

//!SECTION multipost create

export type CreateMultipostingArticles = OpReturnType<
    operations['admin_articles_articles_multiposting_create']
>;
export type CreateMultipostingArticlesQuery = OpArgType<
    operations['admin_articles_articles_multiposting_create']
>;

export type CreateMultipostingArticlesError = OpErrorType<
    operations['admin_articles_articles_multiposting_create']
>;

/* #region Employees type */

export type Employees = definitions['UserList'];
export type EmployeesQuery =
    operations['admin_users_users_list']['parameters']['query'];
export type EmployeesWithPagination =
    operations['admin_users_users_list']['responses']['200']['schema'];

/* #endregion */
