import React, { FC, useEffect, useMemo } from 'react';
import { Column, CellValue } from 'react-table';
import type { BannedOrganizations as BannedOrganizationsType } from '@mega/api';
import { ListingTable } from '@mega/ui';
import { typographyClasses } from '@mega/styles';
import {
    useBannedOrganizationsStore,
    useBannedOrganizationsDispatch,
} from '@mega/store';
import { headItem, listingRecipe } from '../Listing.css';
import { TitleColumn, PublicationColumn } from '../Columns';

export interface ListingBannedOrganizationsProps {}

const ListingBannedOrganizations: FC<
    ListingBannedOrganizationsProps
> = () => {
    const store = useBannedOrganizationsStore();
    const { get, clear } = useBannedOrganizationsDispatch();

    useEffect(() => {
        get({});
        return () => {
            clear();
        };
    }, [get, clear]);

    const columns: Column<BannedOrganizationsType>[] =
        React.useMemo(
            () => [
                {
                    Header: 'Заголовок',
                    accessor: (row) => {
                        return {
                            title: row.title,
                            id: row.id,
                        };
                    },
                    Cell: ({ value }: CellValue) => (
                        <TitleColumn {...value} />
                    ),
                    minWidth: 200,
                    width: '100%',
                },
                {
                    Header: 'Синонимы',
                    accessor: (row) => {
                        return {
                            synonyms: row?.synonyms || [],
                        };
                    },

                    Cell: ({ value }: CellValue) => {
                        return (
                            <div>
                                {value.synonyms.map(
                                    (item: any) => {
                                        return (
                                            <TitleColumn
                                                title={item}
                                            />
                                        );
                                    },
                                )}
                            </div>
                        );
                    },
                },
                {
                    Header: 'Тип',
                    accessor: (row) => {
                        return {
                            title: row.org_type,
                        };
                    },

                    Cell: ({ value }: CellValue) => {
                        return (
                            <TitleColumn
                                title={
                                    value.title ===
                                    'extremist'
                                        ? 'Экстремисты'
                                        : value.title ===
                                          'terrorist'
                                        ? 'Террористы'
                                        : 'Иноагенты'
                                }
                            />
                        );
                    },
                },
                {
                    Header: 'Активно',
                    accessor: (row) => {
                        return {
                            data: '',
                            pubOn: row?.is_active || false,
                        };
                    },

                    Cell: ({ value }: CellValue) => (
                        <PublicationColumn {...value} />
                    ),
                    minWidth: 110,
                    maxWidth: 150,
                    width: '100%',
                },
            ],
            [],
        );
    const data = useMemo(() => {
        const values =
            store.pages?.[store.currentPage] || [];
        return Object.values(values).flat();
    }, [store.pages]);

    return (
        <>
            <ListingTable
                getHeaderGroupProps={(cellInfo) => {
                    return {
                        className: listingRecipe({
                            header: true,
                        }),
                    };
                }}
                getHeaderProps={(cellInfo) => {
                    return {
                        className: headItem({
                            title:
                                cellInfo?.id ===
                                'Заголовок',
                        }),
                    };
                }}
                getRowGroupProps={() => {
                    return {
                        className: [
                            typographyClasses.recipe({
                                size: '14',
                            }),
                            listingRecipe({
                                header: false,
                            }),
                        ].join(' '),
                    };
                }}
                columns={columns}
                data={data}
            />
        </>
    );
};

export { ListingBannedOrganizations };
