import * as CLIENT from '@mega/api';
import { Story, StoryCreate, StoryUpdate } from '@mega/api';
import { createModel } from '@rematch/core';
import { DispatchEffectWithDependency } from '../type';

import type { RootModel } from '../rootModel';

export interface StoryCreateEffect
    extends DispatchEffectWithDependency<
        Pick<StoryCreate, 'title' | 'background' | 'cards'>
    > {}

export interface StoryRemoveEffect
    extends DispatchEffectWithDependency<{ id: string }> {}

export interface StoryUpdateEffect
    extends DispatchEffectWithDependency<StoryUpdate> {
    query: {
        id: number;
    };
}

const story = createModel<RootModel>()({
    name: 'story',
    state: {} as Story,
    reducers: {
        put: (state, payload: Story) => {
            return { ...payload };
        },
    },
    effects: (dispatch) => ({
        get: async (params: { id: string }, store) => {
            try {
                const response =
                    await CLIENT.stories.getById?.(
                        {
                            ...params,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    dispatch.story.put(response.data);
                }
            } catch (e) {
                if (
                    CLIENT.stories.getById &&
                    e instanceof
                        CLIENT.stories.getById.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },
        create: async (
            { payload, options }: StoryCreateEffect,
            store,
        ) => {
            try {
                const response =
                    await CLIENT.stories?.create?.(
                        {
                            ...payload,
                            site: store.site.site?.id || '',
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    if (options?.router) {
                        const { navigate, location } =
                            options.router;
                        navigate(
                            `/${
                                store.site.site?.id || ''
                            }/story/change/${
                                response.data.id
                            }`,
                            {
                                state: location,
                            },
                        );
                    }
                    if (options?.notifier) {
                        const { enqueueSnackbar } =
                            options.notifier;
                        enqueueSnackbar(
                            'Новая история добавлена',
                            {
                                variant: 'success',
                            },
                        );
                    }
                }
            } catch (e) {
                if (
                    CLIENT.stories.create &&
                    e instanceof CLIENT.stories.create.Error
                ) {
                    const error = e.getActualType();

                    if (options?.notifier) {
                        const { enqueueSnackbar } =
                            options.notifier;

                        const errormsgs = Object.values(
                            error.data,
                        );

                        errormsgs?.map((msg) => {
                            enqueueSnackbar(msg as string, {
                                variant: 'error',
                            });
                        });
                    }
                    return error.data;
                }
            }
        },
        update: async (
            { query, payload, options }: StoryUpdateEffect,
            store,
        ) => {
            try {
                const response =
                    await CLIENT.stories?.updatePartialById?.(
                        {
                            ...payload,
                            // @ts-ignore
                            id: query.id,
                            site: store.site.site?.id || '',
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    dispatch.story.put(response.data);
                    if (options?.notifier) {
                        const { enqueueSnackbar } =
                            options.notifier;
                        enqueueSnackbar(
                            'История обновлена',
                        );
                    }
                }
            } catch (e) {
                if (
                    CLIENT.stories.updatePartialById &&
                    e instanceof
                        CLIENT.stories.updatePartialById
                            .Error
                ) {
                    const error = e.getActualType();

                    if (
                        options?.notifier?.enqueueSnackbar
                    ) {
                        const { enqueueSnackbar } =
                            options.notifier;

                        const errormsgs = Object.values(
                            error.data,
                        );
                        const cardsErrors =
                            error.data?.cards;

                        // TODO написать нормальный обработчик ошибок после клиента

                        cardsErrors?.find((item: any) => {
                            if (Object.keys(item)) {
                                Object.values(item).map(
                                    (subItem: any) => {
                                        subItem.map(
                                            (msg: any) => {
                                                enqueueSnackbar(
                                                    msg as string,
                                                    {
                                                        variant:
                                                            'error',
                                                    },
                                                );
                                            },
                                        );
                                    },
                                );
                            }
                        });
                    }
                    return error.data;
                }
            }
        },
        remove: async (
            { payload, options }: StoryRemoveEffect,
            store,
        ) => {
            try {
                const response =
                    await CLIENT?.stories?.deleteById?.(
                        {
                            ...payload,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );

                if (response?.ok) {
                    if (options?.router) {
                        const { navigate, location } =
                            options.router;
                        navigate(
                            `/${
                                store.site.site?.id || ''
                            }/story`,
                            {
                                state: location,
                            },
                        );
                    }
                    if (options?.notifier) {
                        const { enqueueSnackbar } =
                            options.notifier;
                        enqueueSnackbar(
                            'История успешно удалена',
                            {
                                variant: 'success',
                            },
                        );
                    }
                }
            } catch {}
        },
    }),
});

export { story };
export default story;
