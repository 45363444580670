import { Article, definitions } from '@mega/api';
const isPropValuesEqual = (
    subject: any,
    target: any,
    propNames: string[],
) =>
    propNames.every(
        (propName) =>
            subject[propName] === target[propName],
    );

const getUniqueItemsByProperties = (
    items: any,
    propNames: string[],
) =>
    items.filter(
        (item: any, index: number, array: any[]) =>
            index ===
            array.findIndex((foundItem) =>
                isPropValuesEqual(
                    foundItem,
                    item,
                    propNames,
                ),
            ),
    );
interface FileArrayOptions {
    files: Array<definitions['AdminImage']>;
    field: keyof Pick<Article, 'images' | 'videos'>;
    values: Article;
    setFieldValue: (field: string, value: any) => void;
}

export const updateFileArray = ({
    files = [],
    field,
    values,
    setFieldValue,
}: FileArrayOptions): void => {
    const currentValues = values[field];
    let currentArray = currentValues?.length
        ? [...currentValues, ...files]
        : [...files];
    currentArray = getUniqueItemsByProperties(
        currentArray,
        ['id'],
    );
    setFieldValue(field, currentArray);
    return;
};

export const removeFromFileArray = ({
    ids,
    field,
    values,
    setFieldValue,
}: Omit<FileArrayOptions, 'files'> & {
    ids: number[];
}): void => {
    const currentValues = values[field];
    let currentArray = currentValues?.length
        ? (
              currentValues as definitions['AdminImage'][]
          )?.filter(
              (item) => item.id && !ids.includes(item.id),
          )
        : currentValues;
    currentArray = getUniqueItemsByProperties(
        currentArray,
        ['id'],
    );

    setFieldValue(field, currentArray);
    return;
};
