import React, { useEffect } from 'react';
import { ListContent, Pagination } from '@mega/ui';
import {
    useArticlesDispatch,
    useArticlesStore,
} from '@mega/store';
import {
    ListingArticles,
    NoResultsFoundMessage,
} from '@mega/core';
import { CheckBox } from '@mega/icons';
interface ArticlesListingProps {}

export const ArticlesListing: React.FC<
    ArticlesListingProps
> = () => {
    const { jumpTo } = useArticlesDispatch();
    const store = useArticlesStore();
    const articleListName = 'articleList';
    const allArticlesCount = store.meta.count || 0;
    const articlesPerPage = store.query.per_page || 1;
    const allPagesCount = Math.ceil(
        allArticlesCount / articlesPerPage,
    );
    const isItLoadingNow = store.loading.get.loading;

    useEffect(() => {
        if (!isItLoadingNow) {
            window.scrollTo(0, 0);
        }
    }, [isItLoadingNow]);

    const hasPage = store.pages['1'] ? true : false;
    const isPageEmpty =
        !isItLoadingNow &&
        hasPage &&
        store.pages['1'].length === 0;

    return (
        <>
            <div id="targetListHeader"></div>
            <ListContent name={articleListName}>
                {() => {
                    return (
                        <>
                            <ListingArticles />
                        </>
                    );
                }}
            </ListContent>
            <NoResultsFoundMessage
                isPageEmpty={isPageEmpty}
            />
            <Pagination
                pageCount={allPagesCount}
                jumpTo={jumpTo}
                loading={isItLoadingNow}
                currentPage={store.currentPage}
            />
        </>
    );
};
