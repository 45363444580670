import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgImage = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M4.425 12.806h9.169l-2.756-3.675-2.475 3.207-1.744-2.382-2.194 2.85Zm-1.05 2.944c-.3 0-.563-.113-.788-.338a1.08 1.08 0 0 1-.337-.787V3.375c0-.3.112-.563.337-.788a1.08 1.08 0 0 1 .788-.337h11.25c.3 0 .563.112.787.337.225.225.338.488.338.788v11.25c0 .3-.113.563-.338.787a1.08 1.08 0 0 1-.787.338H3.375Zm0-1.125h11.25V3.375H3.375v11.25Zm0-11.25v11.25-11.25Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgImage);
export default ForwardRef;
