import React, { FC, useEffect, useMemo } from 'react';

import { Column, CellValue } from 'react-table';
import { PublicationColumn, TitleColumn } from '../Columns';

import { AcceptModal } from '@mega/core';
import { SourcesReports as SourcesReportsType } from '@mega/api';
import {
    useSourcesReportsDispatch,
    useSourcesReportsStore,
    useSourcesReportDispatch,
} from '@mega/store';
import { ListingTable, IconButton, Icon } from '@mega/ui';
import { Close } from '@mega/icons';
import { typographyClasses } from '@mega/styles';
import { listingRecipe, headItem } from '../Listing.css';

interface ListingInfoUnitProps {}

const ListingReportsSources: FC<
    ListingInfoUnitProps
> = () => {
    const store = useSourcesReportsStore();
    const { get, clear } = useSourcesReportsDispatch();
    const { remove } = useSourcesReportDispatch();

    useEffect(() => {
        get({});
        return () => {
            clear();
        };
    }, [get, clear]);

    const columns: Column<SourcesReportsType>[] =
        React.useMemo(
            () => [
                {
                    Header: 'Имя источника',
                    accessor: (row) => {
                        return {
                            title: row.title,
                            id: row.id,
                        };
                    },
                    Cell: ({ value }: CellValue) => {
                        return <TitleColumn {...value} />;
                    },
                },
                {
                    Header: 'Публикация',
                    accessor: (row) => {
                        return {
                            data: row.updated_at,
                            pubOn: row.is_active,
                        };
                    },

                    Cell: ({ value }: CellValue) => (
                        <PublicationColumn {...value} />
                    ),
                    minWidth: 110,
                    maxWidth: 150,
                    width: '100%',
                },
                {
                    Header: 'Домен',
                    accessor: 'codes',
                    Cell: ({ value }) => {
                        return (
                            <ul
                                style={{
                                    listStyleType: 'none',
                                    padding: '0',
                                    margin: '0',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '4px',
                                }}
                            >
                                {value.map((item) => (
                                    <li key={item}>
                                        <a
                                            style={{
                                                color: 'inherit',
                                                textDecoration:
                                                    'none',
                                            }}
                                            href={`https://${item}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {item}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        );
                    },
                    width: '100%',
                    maxWidth: 150,
                },
                {
                    Header: 'Действия',
                    accessor: (row) => {
                        return {
                            id: row.id,
                            isActive: row.is_active,
                        };
                    },
                    Cell: ({ value }: CellValue) => {
                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent:
                                        'center',
                                }}
                            >
                                <AcceptModal
                                    msg="Вы уверенны что хотите удалить источник ?"
                                    Trigger={
                                        <IconButton
                                            classes={{
                                                paper: {
                                                    color: 'white',
                                                },
                                            }}
                                            onClick={() => {
                                                if (
                                                    value.id &&
                                                    !value.isActive
                                                ) {
                                                    remove(
                                                        value.id,
                                                    );
                                                }
                                            }}
                                            disabled={
                                                value.isActive
                                            }
                                        >
                                            <Icon size="25">
                                                <Close
                                                    style={{
                                                        color: 'red',
                                                    }}
                                                />
                                            </Icon>
                                        </IconButton>
                                    }
                                />
                            </div>
                        );
                    },
                    width: '70px',
                },
            ],
            [],
        );
    const data = useMemo(() => {
        const values =
            store.pages?.[store.currentPage] || [];
        return Object.values(values).flat();
    }, [store.pages]);

    return (
        <>
            <ListingTable
                getHeaderGroupProps={(cellInfo) => {
                    return {
                        className: listingRecipe({
                            header: true,
                        }),
                    };
                }}
                getHeaderProps={(cellInfo) => {
                    return {
                        className: headItem({
                            title:
                                cellInfo?.id ===
                                'Имя источника',
                        }),
                        style: {},
                    };
                }}
                getRowGroupProps={(row) => {
                    return {
                        className: [
                            typographyClasses.recipe({
                                size: '14',
                            }),
                            listingRecipe({
                                header: false,
                            }),
                        ].join(' '),
                    };
                }}
                columns={columns}
                data={data}
            />
        </>
    );
};

export { ListingReportsSources };
