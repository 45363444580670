import React, { useEffect, useState } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { styles as s } from './DroppableContainer.css';

export interface DroppableContainerProps {
    id: string;
    neighborsList: string[];
    neighborsType?: string;
    children: React.ReactElement;
    isContainer?: boolean;
    acceptedTypes?: string[];
    meta?: Record<string, unknown>;
}

export const DroppableContainer: React.FC<
    DroppableContainerProps
> = (props) => {
    const {
        id,
        children,
        isContainer,
        acceptedTypes,
        meta,
        neighborsList,
        neighborsType,
    } = props;

    const [noAcceptable, setNoAcceptable] = useState(false);
    const { isOver, setNodeRef, active } = useDroppable({
        id,
        data: {
            id,
            noAcceptable: noAcceptable,
        },
    });

    const curActive = active?.data?.current;

    const activeType = curActive?.type || '';

    const hasNoType =
        typeof acceptedTypes !== 'undefined' &&
        !acceptedTypes.includes(activeType);

    const isOverSuccess = isOver && isContainer;

    const isSortable =
        isOver && neighborsList.includes(curActive?.id);

    const isCustomPositionAdded =
        isOver && neighborsType === activeType;

    useEffect(() => {
        if (
            noAcceptable &&
            isOver &&
            !isContainer &&
            !isSortable &&
            !isCustomPositionAdded
        ) {
            setNoAcceptable(true);
        } else {
            setNoAcceptable(false);
        }
    }, [isOver, hasNoType, isContainer]);

    return (
        <div
            ref={setNodeRef}
            className={s.wrapper({
                isMain: !!meta?.isMain,
            })}
        >
            {children}

            <div
                className={s.error({
                    isOverSuccess,
                    noAcceptable,
                    isSortable,
                    isCustomPositionAdded,
                })}
            />
        </div>
    );
};
