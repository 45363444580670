import React, { FC, useEffect } from 'react';
import { SecondProps } from './Second.types';
import { PluginViewWrapper } from '@pages/smartGrid/components/shared';
import { useEditor } from '@pages/smartGrid/store/hooks';
import { getIdArray } from '@pages/smartGrid/helpers/getIdArray';
import {
    DEFAULT_FLOW_ROW,
    DEFAULT_FLOW_STACK,
    DEFAULT_HEADLINE,
} from '@pages/smartGrid/components/Plugins';

const getConfig = (...args: string[]) => {
    return {
        [args[0]]: {
            id: args[0],
            ...DEFAULT_FLOW_STACK,
        },
        [args[1]]: {
            id: args[1],
            ...DEFAULT_FLOW_STACK,
            childrenElement: [args[3], args[2]],
        },
        [args[2]]: {
            id: args[2],
            ...DEFAULT_FLOW_STACK,
        },
        [args[3]]: {
            id: args[3],
            ...DEFAULT_FLOW_STACK,
        },
        [args[4]]: {
            id: args[4],
            ...DEFAULT_HEADLINE,
        },
        [args[5]]: {
            id: args[5],
            ...DEFAULT_FLOW_ROW,
            childrenElement: [args[0], args[1]],
        },
        [args[6]]: {
            id: args[6],
            ...DEFAULT_FLOW_STACK,
            childrenElement: [args[4], args[5]],
        },
    };
};

const Second: FC<SecondProps> = (props) => {
    const { children, meta, id, parentId } = props;

    const { handleChangeElements } = useEditor();

    useEffect(() => {
        const idArr = getIdArray(7);
        const payload = getConfig(...idArr);

        if (parentId) {
            handleChangeElements({
                payload,
                id,
                parentId,
                newId: idArr[6],
            });
        }
    }, []);

    return (
        <PluginViewWrapper
            id={id}
            parentId={parentId}
            dynHeight={'max-content'}
            title={'Шаблон 1'}
        >
            {children}
        </PluginViewWrapper>
    );
};

export { Second };
