import { ReactElement } from 'react';
import { PluginTemplate } from '@pages/smartGrid/types/blocks';

export enum StoriesListEnum {
    type = 'STORIES_LIST',
}

export interface StoriesListProps {
    id: string;
    type: string;
    parentId: string | null;
    meta?: Record<string, unknown>;
    children: ReactElement<any> | ReactElement<any>[];
}

export type StoriesListMeta = {};

export type StoriesListPlugin = PluginTemplate<
    StoriesListEnum.type,
    StoriesListMeta
>;
export type StoriesListPluginDefault = Omit<
    StoriesListPlugin,
    'id'
>;
