import { ReactElement } from 'react';
import { PluginTemplate } from '@pages/smartGrid/types/blocks';

export enum HeadlineEnum {
    type = 'HEADLINE',
}

export interface HeadlineProps {
    id: string;
    type: string;
    parentId: string | null;
    text: string;
}

export type HeadlineMeta = {};

export type HeadlinePlugin = PluginTemplate<
    HeadlineEnum.type,
    HeadlineMeta
>;
export type HeadlinePluginDefault = Omit<
    HeadlinePlugin,
    'id'
>;
