import { useAuthStore, useSiteStore } from '@mega/store';
import { fullFormats, reportTypeList } from '../constants';
import {
    ReportFormValues,
    ReportServerResponse,
} from '../Reports.types';
import { usePolling } from './usePolling';
import { useFormikContext } from 'formik';
import { useMemo, useRef, useState } from 'react';
import {
    buildReportQuery,
    isReportCompleted,
} from '../util';

export const useReport = () => {
    const [id, setId] = useState<number | null>();
    const [response, setResponse] =
        useState<ReportServerResponse | null>();
    const [loading, setLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const pollingId = useRef<number>();

    const store = useAuthStore();
    const site = useSiteStore();
    //@ts-ignore
    const apiBaseUrl = process.env.REACT_APP_API_URL;

    const { values } = useFormikContext<ReportFormValues>();

    const curType = useMemo(
        () =>
            reportTypeList.find(
                (item) =>
                    item.value === values.report_type?.id,
            ) || reportTypeList[0],
        [values.report_type?.id],
    );
    const query = buildReportQuery(values, values.sources);

    let apiPollingCall;

    const curFormat = fullFormats.find(
        (item) => item.value === values.file_format.id,
    );

    if (id) {
        apiPollingCall = async () => {
            const type = curType.value;

            try {
                const pollingResult = await fetch(
                    `${apiBaseUrl}/admin/reports/${id}/${type}/`,
                    {
                        headers: {
                            'Content-Type':
                                'application/json',
                            authorization: `Bearer ${store?.accessToken}`,
                            'x-site': site?.id || '',
                        },
                    },
                );
                setId(null);
                return pollingResult;
            } catch (e) {
                console.log(e);
            }
        };
    }

    const startRequestForReport = async () => {
        try {
            setResponse(null);
            setLoading(true);
            const res = await fetch(
                `${apiBaseUrl}/admin/reports/${
                    curType?.path || curType.value
                }/?${query}`,
                {
                    headers: {
                        'Content-Type':
                            curFormat?.type ||
                            'application/xlsx',
                        authorization: `Bearer ${store?.accessToken}`,
                        'x-site': site?.id || '',
                    },
                },
            );
            const data = await res.json();
            await setId(data.id);
        } catch (e) {
            console.log(e, 'error in startRequestReport');
        }
    };

    const { promise, stopPolling } = usePolling({
        callApiFn: apiPollingCall,
        testFn: isReportCompleted,
        feedBackFn: (data) => {
            if (data.progress.state === 'error') {
                stopPolling();
                setIsError(true);
                setLoading(false);
            }
            setResponse(data);
        },
        interval: 3000,
    });

    promise.then((data) => {
        if (
            data &&
            data.id !== undefined &&
            data.id !== pollingId.current
        ) {
            stopPolling();
            setLoading(false);
            setResponse(data);

            pollingId.current = data?.id;
        } else {
            console.log('empty promise');
        }
    });

    return {
        startRequestForReport,
        loading,
        response,
        setResponse,
        isError,
    };
};
