import React from 'react';
import { Button, Stack } from '@mega/ui';
import { ReportFormValues } from 'src/Reports/Reports.types';
import { useFormikContext } from 'formik';
import { useReportOld } from '../../hooks/useReportOld';

export const IndicatorsReportPage: React.FC = () => {
    const {
        values: {
            pub_date__date__gte,
            pub_date__date__lte,
        },
    } = useFormikContext<ReportFormValues>();

    const { getReportFile } = useReportOld();

    const isButtonDisabled = !(
        Boolean(pub_date__date__gte) &&
        Boolean(pub_date__date__lte)
    );

    return (
        <Stack gap="18">
            <Button
                label={'Запросить'}
                onClick={getReportFile}
                disabled={isButtonDisabled}
            />
        </Stack>
    );
};
