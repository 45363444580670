import React, { FC, useEffect, useMemo } from 'react';
import { Column, CellValue } from 'react-table';
import type { Reposts as RepostsType } from '@mega/api';
import { ListingTable } from '@mega/ui';
import { typographyClasses } from '@mega/styles';

import {
    useRepostsDispatch,
    useRepostsStore,
} from '@mega/store';
import { headItem, listingRecipe } from '../Listing.css';
import {
    ActionRepostColumn,
    PublicationColumn,
    TitleRepostColumn,
} from '../Columns';

export interface ListingRepostsProps {}

const ListingReposts: FC<ListingRepostsProps> = () => {
    const store = useRepostsStore();
    const { get, clear } = useRepostsDispatch();

    useEffect(() => {
        get({});
        return () => {
            clear();
        };
    }, [get]);

    const columns: Column<RepostsType>[] = React.useMemo(
        () => [
            {
                Header: 'Содержание',
                accessor: (row) => {
                    return {
                        title: row.text,
                        id: row.id,
                    };
                },
                Cell: ({ value }: CellValue) => {
                    return <TitleRepostColumn {...value} />;
                },
                minWidth: 160,
                width: '100%',
            },
            {
                Header: 'Публикация',
                accessor: (row) => {
                    return {
                        data: row.pub_date,
                        pubOn: row.is_active,
                    };
                },

                Cell: ({ value }: CellValue) => (
                    <PublicationColumn {...value} />
                ),
                minWidth: 110,
                maxWidth: 150,
                width: '100%',
            },

            {
                Header: 'Источник',
                accessor: (row) => {
                    return {
                        author: row.author,
                        authorLink: row.author_link,
                    };
                },
                Cell: ({ value }: CellValue) => {
                    return (
                        <a
                            style={{
                                cursor: 'pointer',
                                color: 'inherit',
                                textDecoration: 'none',
                                display: 'block',
                                textAlign: 'center',
                            }}
                            target="_blank"
                            href={value.authorLink}
                        >
                            {value.author}
                        </a>
                    );
                },
            },
            {
                Header: 'Действия',
                accessor: (row) => {
                    return {
                        isActive: row.is_active,
                        url: row.link,
                        id: row.id,
                    };
                },
                Cell: ({ value }: CellValue) => (
                    <ActionRepostColumn {...value} />
                ),
                minWidth: 60,
                maxWidth: 66,
                width: '100%',
            },
        ],
        [],
    );
    const data = useMemo(() => {
        const values =
            store.pages?.[store.currentPage] || [];
        return Object.values(values).flat();
    }, [store.pages]);

    return (
        <>
            <ListingTable
                getCellProps={(cellInfo) => {
                    return {
                        style: {},
                    };
                }}
                getHeaderGroupProps={(cellInfo) => {
                    return {
                        className: listingRecipe({
                            header: true,
                        }),
                    };
                }}
                getHeaderProps={(cellInfo) => {
                    return {
                        className: headItem({
                            title:
                                cellInfo?.id ===
                                'Содержание',
                        }),
                        style: {},
                    };
                }}
                getRowGroupProps={() => {
                    return {
                        className: [
                            typographyClasses.recipe({
                                size: '14',
                            }),
                            listingRecipe({
                                header: false,
                            }),
                        ].join(' '),
                    };
                }}
                columns={columns}
                data={data}
            />
        </>
    );
};

export { ListingReposts };
