/* eslint-disable @typescript-eslint/ban-types */
import React, { PropsWithChildren } from 'react';
import { components, InputProps } from 'react-select';
import { selectSingleClasses } from '../../SelectSingle.css';
import { typographyClasses } from '@mega/styles';

type InputOverrideProps = InputProps<{}, true>;

const Input = (
    props: PropsWithChildren<
        InputProps<Record<string, unknown>, true>
    >,
) => {
    if (props.isHidden) {
        return (
            <components.Input
                className={selectSingleClasses.input}
                style={{
                    width: '100%',
                    backgroundColor: 'transparent',
                    border: 'none',
                    padding: '0',
                    outline: 'none',
                    cursor: 'text',
                }}
                {...props}
            />
        );
    } else {
        return (
            <components.Input
                style={{
                    width: '100%',
                    backgroundColor: 'transparent',
                    border: 'none',
                    padding: '0',
                    outline: 'none',
                    color: '#878787',
                    cursor: 'text',
                }}
                className={[selectSingleClasses.input].join(
                    ' ',
                )}
                {...props}
            />
        );
    }
};

export { Input };
export default Input;
