import React, { FC, useEffect, useState } from 'react';
import {
    HeadTemplatePlugin,
    TemplatePlugin,
} from '@mega/editor';

import { useEditor } from '@apostroph/store';
import { FormControl } from '@mui/material';
import { videoMeta, videoProps } from './Video.types';
import { Icon, Typography } from '@mega/ui';
import { MediaLoaderWithMS } from '@mega/core';
import { Videocam as VideocamIcon } from '@mega/icons';
import { useFormikContext } from 'formik';
import { Article, definitions } from '@mega/api';
import { removeFromFileArray } from '@mega/utils/src/updateFileArrays';
import { VideoPicker } from '@mega/core/src/VideoPicker';

const Video: FC<videoProps> = ({ id, parentId, type }) => {
    const { handleUpdateElement, getCurrentDataElement } =
        useEditor();
    const { meta, childrenElement } =
        getCurrentDataElement<videoMeta>(id);
    const { setFieldValue, values } =
        useFormikContext<Article>();
    const articleFile = values.videos?.find(
        (item) => item.id === meta.id,
    );

    const [state, setState] = useState(articleFile || meta);

    useEffect(() => {
        const newGlobalState = {
            id,
            type,
            childrenElement,
            meta: state,
        };
        handleUpdateElement(newGlobalState);
    }, [state]);

    const handleChange = (
        cover: definitions['AdminHostVideoList'],
    ) => {
        setState(cover);
    };

    const handleDelete = (id?: number) => {
        setState({ title: '' });
        if (id) {
            removeFromFileArray({
                ids: [id],
                field: 'videos',
                values,
                setFieldValue,
            });
        }
    };

    // @ts-ignore
    return (
        <TemplatePlugin
            head={
                <HeadTemplatePlugin
                    id={id}
                    onDelete={() => handleDelete(state.id)}
                    parentId={parentId}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '4px',
                        }}
                    >
                        <Icon size="16">
                            <VideocamIcon />
                        </Icon>
                        <Typography size={'12'}>
                            Видео
                        </Typography>
                    </div>
                </HeadTemplatePlugin>
            }
        >
            <FormControl variant="outlined" fullWidth>
                <div style={{ maxWidth: 270 }}>
                    <MediaLoaderWithMS type={'video'}>
                        <VideoPicker
                            id={state.id}
                            handleDelete={handleDelete}
                            handleLoad={handleChange}
                            // @ts-ignore
                            fileSrc={
                                state.file_link ||
                                state?.video
                            }
                            poster={state.frame}
                        />
                    </MediaLoaderWithMS>
                </div>
                <Typography size="16" weight="bold">
                    {state.title}
                </Typography>
            </FormControl>
        </TemplatePlugin>
    );
};

export { Video };
