import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgCheck = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.742 6.264a.919.919 0 0 1 0 1.276L8.57 19l-5.311-5.448a.918.918 0 0 1 0-1.275.864.864 0 0 1 1.243 0l4.068 4.172 9.93-10.185a.864.864 0 0 1 1.244 0Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgCheck);
export default ForwardRef;
