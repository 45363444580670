import {
    Control,
    PositionsVariants,
} from '@apostroph/types';
import FileUpload from '@mui/icons-material/FileUpload';
import { UploadFile } from './UploadFile';
import {
    UploadFilePluginDefault,
    UploadFileEnum,
} from './UploadFile.types';

const PLUGIN_UPLOAD_FILES_NAME = UploadFileEnum.type;

const DEFAULT_UPLOAD_FILES: UploadFilePluginDefault = {
    type: PLUGIN_UPLOAD_FILES_NAME,
    meta: {
        files: {},
    },
    childrenElement: [],
};

const PLUGIN_UPLOAD_FILES = {
    [PLUGIN_UPLOAD_FILES_NAME]: {
        emptyObject: DEFAULT_UPLOAD_FILES,
        render: UploadFile,
    },
};

const PLUGIN_UPLOAD_FILES_CONTROL: Control = {
    name: PLUGIN_UPLOAD_FILES_NAME,
    label: 'Добавить Фаил',
    tooltip: 'Добавить Фаил',
    position: PositionsVariants.actionFeed,
    Icon: () => <FileUpload />,
};

export {
    DEFAULT_UPLOAD_FILES,
    PLUGIN_UPLOAD_FILES,
    PLUGIN_UPLOAD_FILES_CONTROL,
    PLUGIN_UPLOAD_FILES_NAME,
};
export default PLUGIN_UPLOAD_FILES;
