import { EditorModel, Elements } from '@apostroph/types';
import { UniqueIdentifier } from '@dnd-kit/core';
import { Action } from '@rematch/core/dist/types';
import { DebouncedFunc } from 'lodash-es';
import { FlowStackEnum } from '../components/Plugins/Grid/FlowStack/FlowStack.types';

interface handleCreateParentIdPropsType {
    type: FlowStackEnum;
    overId: UniqueIdentifier;
    getCurrentState: () => EditorModel;
    getCurrentDataElement: <T>(id: string) => Elements;
    handleUpdateElement: DebouncedFunc<
        (
            payload: Partial<Elements>,
        ) => Action<Partial<Elements>, void>
    >;
}

export const handleCreateParentId = ({
    type,
    overId,
    getCurrentState,
    getCurrentDataElement,
    handleUpdateElement,
}: handleCreateParentIdPropsType) => {
    if (!overId) return;

    let parentId;

    const currentGrid = getCurrentState().data;

    for (let parentKey in currentGrid) {
        const searchCondition =
            currentGrid[parentKey].childrenElement.length >
                0 &&
            currentGrid[parentKey].childrenElement.includes(
                overId.toString(),
            );

        if (searchCondition) {
            const targetParent =
                currentGrid[parentKey].childrenElement;

            for (let childIdx in targetParent) {
                const searchedElement =
                    getCurrentDataElement(
                        targetParent[childIdx],
                    );

                const isTheSameEntity =
                    searchedElement.type === type;
                const isMainElement =
                    type === FlowStackEnum.type;

                const isRestrictedToCreateParentId =
                    !isTheSameEntity || isMainElement;

                if (isRestrictedToCreateParentId) continue;

                const elementWithParentId = {
                    ...searchedElement,
                    parentId: parentKey,
                };

                handleUpdateElement(elementWithParentId);
                parentId = parentKey;
            }
        }
    }
    return parentId;
};
