import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';
import { useRef } from 'react';
import { debounce } from 'lodash-es';

const usePapersStore = () => {
    const state = useStoreSelector(
        (state: RootState) => state.papers,
    );
    const loading = useStoreSelector(
        (state: RootState) => state.loading.effects.papers,
    );

    return Object.assign(state, {
        loading: {
            get: loading.get,
            chungeCountItemsOnPage:
                loading.chungeCountItemsOnPage,
            jumpTo: loading.jumpTo,
        },
    });
};
const usePapersDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    const debouncedHandleSetCurrentPage = useRef(
        debounce(dispatch.papers.setCurrentPage, 1000),
    );

    return {
        get: dispatch.papers.get,
        nextPage: dispatch.papers.nextPage,
        jumpTo: dispatch.papers.jumpTo,
        setCurrentPage:
            debouncedHandleSetCurrentPage.current,
        search: dispatch.papers.search,
        clear: dispatch.papers.clear,
        deleteById: dispatch.papers.deleteById,
    };
};

export { usePapersStore, usePapersDispatch };
