'use client';

import type { FC } from 'react';
import { useEditor } from '../../../../store/hooks';
import { PluginViewWrapper } from '../../../shared/PluginViewWrapper';
import type { PotokusProps } from './Potokus.types';

const Potokus: FC<PotokusProps> = (props) => {
    const { id, parentId } = props;

    const { getCurrentDataElement } = useEditor();
    const data = getCurrentDataElement(id);

    if (!data && id !== 'preview') {
        return null;
    }
    return (
        <PluginViewWrapper
            id={id}
            parentId={parentId}
            title={'Потокус'}
        />
    );
};

export { Potokus };
