import React, { PropsWithChildren } from 'react';
import {
    components,
    MultiValueRemoveProps,
} from 'react-select';

interface MultiRemoveClass {
    className: MultiValueRemoveProps['innerProps']['className'];
    // inn: MultiValueRemoveProps['innerProps']['onClick'];
}

type MultiValueRemoveOverideProps = MultiValueRemoveProps<
    Record<string, unknown>,
    true
>;

const MultiValueRemove =
    ({ className }: MultiRemoveClass) =>
    ({
        innerProps,
        ...props
    }: PropsWithChildren<MultiValueRemoveOverideProps>) =>
        (
            <components.MultiValueRemove
                {...props}
                innerProps={{ ...innerProps, className }}
            />
        );

export { MultiValueRemove };
export default MultiValueRemove;
