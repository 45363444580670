import React, { FC } from 'react';
import {
    useInfounitTaskDispatch,
    useInfounittasksStore,
    useSiteStore,
    useUserInfo,
} from '@mega/store';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
    AcceptModal,
    getOptionsRedactors,
} from '@mega/core';
import type { InfounittaskArray as InfoUnitType } from '@mega/api';

import {
    Button,
    Divider,
    Icon,
    IconButton,
    Modal,
    SelectSingleField,
    Stack,
    Typography,
    WithLabel,
} from '@mega/ui';
import { Close } from '@mega/icons';
import { FiCornerUpLeft } from 'react-icons/fi';
import { userWrapper } from './ListingInfoUnit.css';
import { Tooltip } from '@mui/material';

interface ReplacePerformerProps extends InfoUnitType {
    id: number;
}

export const ReplacePerformer: FC<ReplacePerformerProps> = (
    props,
) => {
    const { update } = useInfounitTaskDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const currentSite = useSiteStore();

    const callbackRedactors = getOptionsRedactors({
        site: currentSite?.id || '',
    });

    const store = useInfounittasksStore();

    const getCurrentUser = (object: {
        [key: string]: Array<any>;
    }) => {
        const valuesObject = Object.values(object).flat();

        const usersWithIdInfoUnit = valuesObject.reduce(
            (acc, item) => {
                return {
                    ...acc,
                    [item?.id]: item?.user?.label,
                };
            },
            {},
        );

        return usersWithIdInfoUnit;
    };

    const user = getCurrentUser(store.pages);
    const { me } = useUserInfo();

    const isCurrentUser = props.user?.id === me?.id;

    const isRealisingEdit = me?.groups?.find(
        ({ id }) => id === 8,
    );

    const canRevert = isRealisingEdit || isCurrentUser;
    return (
        <Modal
            width="500px"
            Trigger={
                <div
                    style={{
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {user[props.id] ? (
                        <div className={userWrapper}>
                            {user[props.id]}
                            {canRevert && (
                                <Tooltip
                                    title="Вернуть"
                                    placement="bottom"
                                >
                                    <span>
                                        <AcceptModal
                                            msg="Вы уверенны что хотите вернуть инфоповод?"
                                            Trigger={
                                                <IconButton
                                                    onClick={async () => {
                                                        if (
                                                            props
                                                                .info_unit
                                                                .id
                                                        )
                                                            await update(
                                                                {
                                                                    query: {
                                                                        id: props.id,
                                                                    },
                                                                    payload:
                                                                        {
                                                                            info_unit:
                                                                                props
                                                                                    .info_unit
                                                                                    .id,
                                                                            user: null,
                                                                            id: props.id,
                                                                        },
                                                                    options:
                                                                        {
                                                                            notifier:
                                                                                {
                                                                                    enqueueSnackbar,
                                                                                },
                                                                        },
                                                                },
                                                            );
                                                    }}
                                                >
                                                    <FiCornerUpLeft />
                                                </IconButton>
                                            }
                                        />
                                    </span>
                                </Tooltip>
                            )}
                        </div>
                    ) : (
                        <Button
                            label={'Назначить'}
                            animate="none"
                            classes={{
                                paper: {
                                    color: 'dark',
                                },
                            }}
                        />
                    )}
                </div>
            }
            Content={(handleClose) => {
                return (
                    <Formik
                        initialValues={{
                            ...props,
                        }}
                        onSubmit={async (
                            values,
                            actions,
                        ) => {
                            if (
                                values.id &&
                                values.info_unit.id
                            ) {
                                await update({
                                    query: {
                                        id: values.id,
                                    },
                                    payload: {
                                        info_unit:
                                            values.info_unit
                                                .id,
                                        user:
                                            values.user
                                                ?.id ??
                                            null,
                                        id: values.id,
                                    },
                                    options: {
                                        notifier: {
                                            enqueueSnackbar,
                                        },
                                    },
                                });
                            }
                            handleClose();
                        }}
                    >
                        <Form>
                            <Stack gap="16">
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems:
                                            'center',
                                        justifyContent:
                                            'space-between',
                                    }}
                                >
                                    <Typography
                                        size="16"
                                        weight="bold"
                                    >
                                        Назначение
                                        исполнителя
                                    </Typography>
                                    <IconButton
                                        onClick={
                                            handleClose
                                        }
                                        animate="none"
                                        classes={{
                                            paper: {
                                                color: 'gray',
                                            },
                                        }}
                                    >
                                        <Icon size="18">
                                            <Close />
                                        </Icon>
                                    </IconButton>
                                </div>
                                <Divider />
                                <WithLabel
                                    title={'Инфоповод'}
                                >
                                    <div>
                                        {
                                            props.info_unit
                                                ?.title
                                        }
                                    </div>
                                </WithLabel>
                                <WithLabel
                                    title={'Исполнитель'}
                                >
                                    <SelectSingleField
                                        name="user"
                                        handleLoader={
                                            callbackRedactors
                                        }
                                    />
                                </WithLabel>
                                <Button
                                    classes={{
                                        paper: {
                                            color: 'dark',
                                            variant:
                                                'outline',
                                        },
                                    }}
                                    animate="none"
                                    label={'Сохранить'}
                                    type="submit"
                                />
                            </Stack>
                        </Form>
                    </Formik>
                );
            }}
        />
    );
};
