import React, { FC } from 'react';
import { ArticleListProps } from './ArticleList.types';
import { styles as s } from './ArticleList.css';
import { PluginViewWrapper } from '@pages/smartGrid/components/shared';

const ArticleList: FC<ArticleListProps> = (props) => {
    const { children, meta, id, parentId } = props;

    return (
        <PluginViewWrapper
            id={id}
            parentId={parentId}
            dynHeight={'max-content'}
            title={'Лента из новостей'}
        >
            <div className={[s.wrapper()].join()}>
                {children}
            </div>
        </PluginViewWrapper>
    );
};

export { ArticleList };
