import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgPubOn = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <rect
            width={26}
            height={26}
            rx={4}
            transform="matrix(-1 0 0 1 26 0)"
            fill="#6FCF97"
        />
        <path
            d="M18.5 13.062 10 18.124V8l8.5 5.062Z"
            fill="currentColor"
        />
        <rect
            x={4.5}
            y={4.5}
            width={17}
            height={17}
            rx={8.5}
            stroke="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgPubOn);
export default ForwardRef;
