import React, {
    FC,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import type { VideoCreate as VideoCreateType } from '@mega/api';
import { useSiteStore } from '@mega/store';
import { Video } from '@mega/api';
import {
    Button,
    Divider,
    FormLayout,
    Indi,
    InputField as Input,
    InputTextareaField,
    Paper,
    Row,
    Stack,
    Typography,
    WithLabel,
} from '@mega/ui';
import * as Yup from 'yup';
import {
    DndFileLoader,
    FileViewer,
    NotifyVideoPresets,
} from '@mega/core';
import dayjs from 'dayjs';
import {
    filesWrapper,
    infoBlock,
    progressBar,
} from './VideoCreate.css';
import { fmtMSS } from '@mega/admin/src/utils';
import { throttle } from 'lodash-es';

export interface VideoCreateProps {
    initialValue?: Video;
    handleUpdate?: (value: VideoCreateType) => void;
    handleCreate?: (value: VideoCreateType) => void;
    progress?: number;
}

const VideoCreateActions = () => {
    const { submitForm, values } =
        useFormikContext<Video>();

    const site = useSiteStore();

    return (
        <Paper
            style={{
                borderTop: '1px solid #EAEAEA',
            }}
            fullWidth
            color={'white'}
            variant="filled"
            classes={{
                padding: { size: '24x24' },
            }}
        >
            <Row gap="16">
                <Button
                    classes={{
                        paper: {
                            variant: 'filled',
                            color: 'dark',
                        },
                    }}
                    color="secondary"
                    size={'large'}
                    type={'submit'}
                    label={'Сохранить'}
                    onClick={submitForm}
                />
                {values.id && (
                    <Button
                        classes={{
                            paper: {
                                variant: 'outline',
                                color: 'dark',
                            },
                        }}
                        color="secondary"
                        size={'large'}
                        type={'button'}
                        label={'Перейти на страницу видео'}
                        onClick={() => {
                            // @ts-ignore
                            const url = `${site.url}/video/${values.id}`;
                            window.open(url, '_blank');
                        }}
                    />
                )}
            </Row>
        </Paper>
    );
};

const labelMap = {
    pending: 'В ожидании',
    uploaded: 'Загружено',
    processed: 'Обработано',
    deleted: 'Удалено',
};
const VideoCreate: FC<VideoCreateProps> = ({
    handleUpdate = () => null,
    handleCreate = () => null,
    progress = 0,
    initialValue = {
        title: '',
        description: '',
        copyright: {},
    },
}) => {
    const [selectedFile, setSelectedFile] = useState();
    const [coverPreview, setCoverPreview] = useState(
        initialValue?.cover || '',
    );
    const [selectedVideo, setSelectedVideo] = useState();

    const [videoPreview, setVideoPreview] = useState(
        initialValue?.file_link ||
            initialValue?.video ||
            '',
    );

    useEffect(() => {
        const objectUrl = selectedFile
            ? URL.createObjectURL(selectedFile)
            : '';
        if (objectUrl) {
            setCoverPreview(objectUrl);
        }

        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile]);

    useEffect(() => {
        const objectUrl = selectedVideo
            ? URL.createObjectURL(selectedVideo)
            : '';
        if (objectUrl) {
            setVideoPreview(objectUrl);
        }

        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedVideo]);

    const [isCreated, setIsCreated] = useState(false);
    const throttledSubmit = useMemo(
        () => throttle(handleUpdate, 1000),
        [],
    );
    const handleSubmit = (values: Video) => {
        if (!isCreated && handleCreate) {
            handleCreate(values);
            setIsCreated(true);
            return;
        }
        if (values.id) {
            throttledSubmit(values);
        }
        return;
    };

    return (
        <Formik
            validationSchema={Yup.object({
                title: Yup.string()
                    .min(
                        2,
                        'Это поле явно должно быть больше одного символа',
                    )
                    .required('Это поле обязательное'),
                description: Yup.string()
                    .min(
                        2,
                        'Это поле явно должно быть больше одного символа',
                    )
                    .required('Это поле обязательное'),
            })}
            initialValues={initialValue}
            onSubmit={handleSubmit}
        >
            {({ setFieldValue, handleChange, values }) => {
                return (
                    <Form>
                        <FormLayout
                            actions={<VideoCreateActions />}
                            settings={
                                <Stack gap="16">
                                    <Paper
                                        contentHeight
                                        color={'white'}
                                        variant="outline"
                                        classes={{
                                            padding: {
                                                size: '24x24',
                                            },
                                        }}
                                    >
                                        <Stack gap="18">
                                            {values.status && (
                                                <Indi
                                                    label={
                                                        labelMap[
                                                            values
                                                                .status
                                                        ]
                                                    }
                                                    colors={
                                                        values.status ===
                                                        'processed'
                                                            ? 'success'
                                                            : values.status ===
                                                              'deleted'
                                                            ? 'deleted'
                                                            : 'in_progress'
                                                    }
                                                />
                                            )}
                                        </Stack>
                                    </Paper>
                                    <Paper
                                        contentHeight
                                        color={'white'}
                                        variant="outline"
                                        classes={{
                                            padding: {
                                                size: '24x24',
                                            },
                                        }}
                                    >
                                        <Stack gap="18">
                                            <div
                                                className={
                                                    infoBlock
                                                }
                                            >
                                                <Typography size="14">
                                                    Длина
                                                    видео
                                                </Typography>

                                                <Typography size="14">
                                                    {values?.video_duration
                                                        ? fmtMSS(
                                                              Math.trunc(
                                                                  values?.video_duration,
                                                              ),
                                                          )
                                                        : '-'}
                                                </Typography>
                                            </div>
                                            <div
                                                className={
                                                    infoBlock
                                                }
                                            >
                                                <Typography size="14">
                                                    Разрешение
                                                </Typography>
                                                <Typography size="14">
                                                    {values.video_height &&
                                                    values.video_width
                                                        ? `${values.video_height}x${values.video_width}`
                                                        : '-'}
                                                </Typography>
                                            </div>
                                            <div
                                                className={
                                                    infoBlock
                                                }
                                            >
                                                <Typography size="14">
                                                    Дата
                                                    загрузки
                                                </Typography>
                                                <Typography size="14">
                                                    {values.created_at
                                                        ? dayjs(
                                                              values.created_at,
                                                          ).format(
                                                              'DD/MM/YYYY',
                                                          )
                                                        : '--/--/----'}
                                                </Typography>
                                            </div>
                                        </Stack>
                                    </Paper>
                                    <NotifyVideoPresets />
                                </Stack>
                            }
                        >
                            <Paper
                                variant="outline"
                                color="white"
                                classes={{
                                    padding: {
                                        size: '24x24',
                                    },
                                }}
                            >
                                <Stack gap="18">
                                    <Input
                                        type="text"
                                        name={'title'}
                                        placeholder="Название видео"
                                        dimension="medium"
                                    />
                                    <InputTextareaField
                                        name="description"
                                        value={
                                            values?.description ||
                                            ''
                                        }
                                        onChange={
                                            handleChange
                                        }
                                        placeholderDefault={
                                            'Описание'
                                        }
                                        color={
                                            'transparent'
                                        }
                                        rows={3}
                                    />
                                    <Divider />
                                    <div
                                        className={
                                            filesWrapper
                                        }
                                    >
                                        <WithLabel
                                            id={'video'}
                                            title="Обложка"
                                        >
                                            <DndFileLoader
                                                onUpload={(
                                                    res: any,
                                                ) => {
                                                    setFieldValue(
                                                        'cover',
                                                        res,
                                                    );
                                                    setSelectedFile(
                                                        res,
                                                    );
                                                }}
                                                onDelete={() => {
                                                    setFieldValue(
                                                        'cover',
                                                        new File(
                                                            [
                                                                '',
                                                            ],
                                                            '',
                                                        ),
                                                    );
                                                    setCoverPreview(
                                                        '',
                                                    );
                                                }}
                                                fileType={
                                                    'image'
                                                }
                                                id={
                                                    values.cover
                                                        ? values.id
                                                        : undefined
                                                }
                                            >
                                                <FileViewer
                                                    fileSrc={
                                                        coverPreview
                                                    }
                                                />
                                            </DndFileLoader>
                                        </WithLabel>
                                        <WithLabel
                                            id={'video'}
                                            title="Видео"
                                        >
                                            {values.id ? (
                                                <FileViewer
                                                    fileSrc={
                                                        videoPreview
                                                    }
                                                    isVideo={
                                                        values.status !==
                                                        'deleted'
                                                    }
                                                    videoPoster={
                                                        values.frame
                                                    }
                                                />
                                            ) : (
                                                <DndFileLoader
                                                    onUpload={(
                                                        res: any,
                                                    ) => {
                                                        setFieldValue(
                                                            'video',
                                                            res,
                                                        );
                                                        setSelectedVideo(
                                                            res,
                                                        );
                                                    }}
                                                    fileType={
                                                        'video'
                                                    }
                                                >
                                                    <FileViewer
                                                        fileSrc={
                                                            videoPreview
                                                        }
                                                        isVideo
                                                        videoPoster={
                                                            values.frame
                                                        }
                                                    />
                                                </DndFileLoader>
                                            )}
                                        </WithLabel>
                                    </div>
                                </Stack>
                            </Paper>
                            <div
                                className={progressBar}
                                style={{
                                    width: `${progress}%`,
                                    visibility:
                                        progress < 50
                                            ? 'visible'
                                            : 'hidden',
                                }}
                            ></div>
                        </FormLayout>
                    </Form>
                );
            }}
        </Formik>
    );
};

export { VideoCreate };
