import React, { FC } from 'react';
import { EditorModel, PreviewCore } from '@apostroph/types';
import { PreviewSerializer } from '../previewSerializer';
import { DependencyContextPreview } from '../../store';

const defaultConfigPreview: EditorModel & PreviewCore = {
    items: [],
    data: {},
    rootElements: {},
    pluginElements: {},
};

const InitPreview: FC<{
    dependencyPreview?: Partial<PreviewCore>;
    initialState: EditorModel;
}> = ({ dependencyPreview, initialState }) => (
    <DependencyContextPreview
        config={{
            rootElements: {
                ...defaultConfigPreview.rootElements,
                ...(dependencyPreview?.rootElements || {}),
            },
            pluginElements: {
                ...defaultConfigPreview.pluginElements,
                ...(dependencyPreview?.pluginElements ||
                    {}),
            },
        }}
    >
        <PreviewSerializer data={initialState} />
    </DependencyContextPreview>
);

export { InitPreview };
export default { InitPreview };
