import { definitions } from '@mega/api';

export interface IInitValuesForm {
    id: string;
    title?: string;
    count?: string;
    url?: string;
    // image
    cover?: definitions['AdminImage'];
    images?: definitions['AdminImage'];
}

export const initValuesForm: IInitValuesForm = {
    id: '',
    title: '',
    count: undefined,
    url: undefined,
    // image
    cover: undefined,
    images: undefined,
};
