import { createModel } from '@rematch/core';

import type { RootModel } from '../rootModel';

export interface ApostrophModel {
    isFormatted: boolean;
    isSearchedInAgent: boolean;
}

const apostroph = createModel<RootModel>()({
    name: 'apostroph',
    state: {
        isFormatted: false,
        isSearchedInAgent: false,
    } as ApostrophModel,
    reducers: {
        putIsFormatted: (state, payload: boolean) => {
            return { ...state, isFormatted: payload };
        },
        putIsSearchedInAgent: (state, payload: boolean) => {
            return { ...state, isSearchedInAgent: payload };
        },
    },
    effects: (dispatch) => ({
        setIsFormatted: async (params: {
            isFormatted: boolean;
        }) => {
            dispatch.apostroph.putIsFormatted(
                params.isFormatted,
            );
        },
        setIsSearchedInAgent: async (params: {
            isSearchedInAgent: boolean;
        }) => {
            dispatch.apostroph.putIsSearchedInAgent(
                params.isSearchedInAgent,
            );
        },
    }),
});

export { apostroph };
export default apostroph;
