import React from 'react';
import { EmployeeEdit as EmployeeUpdate } from '@mega/forms';

export const EmployeeEdit: React.FC = () => {
    return (
        <>
            <EmployeeUpdate />
        </>
    );
};
