import React, {
    createContext,
    FC,
    ReactElement,
    RefObject,
    useContext,
} from 'react';

interface LexContextProps {
    menu: RefObject<HTMLDivElement> | null;
    isLead?: boolean;
}

export const LexContext =
    createContext<LexContextProps | null>(null);

export interface LexContextProviderProps
    extends LexContextProps {
    children: ReactElement | ReactElement[];
}

export const LexContextProvider: FC<
    LexContextProviderProps
> = ({ menu, isLead = false, children }) => (
    <LexContext.Provider
        value={{
            menu,
            isLead: isLead,
        }}
    >
        {children}
    </LexContext.Provider>
);

export const useLexContext = () => {
    const state = useContext(LexContext);
    if (!state) {
        throw new Error(
            'useLexContext used without LexContextProvider provider',
        );
    }
    return state;
};
