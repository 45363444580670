import React, {
    ReactElement,
    PropsWithChildren,
} from 'react';
import { components, ControlProps } from 'react-select';

interface WithIconControlProps {
    startIcon?: ReactElement;
    className?: string;
}

type ControlOverideProps = ControlProps<
    Record<string, unknown>,
    true
>;

const Control =
    ({ startIcon, className = '' }: WithIconControlProps) =>
    ({
        children,
        ...props
    }: PropsWithChildren<ControlOverideProps>) =>
        (
            <components.Control
                className={[className].join(' ')}
                {...props}
            >
                {startIcon}
                {children}
            </components.Control>
        );

export { Control };
export default Control;
