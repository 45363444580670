import * as Yup from 'yup';

const NumOfTheDayObj = {
    title: Yup.string(),
    count: Yup.string(),
    url: Yup.string().matches(
        /((https?):\/\/)?(www.)?[a-zA-Z0-9_-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#_-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Укажите ссылку',
    ),
};

export const validateNumOfTheDay =
    Yup.object().shape(NumOfTheDayObj);
