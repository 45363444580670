import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';

const useVideosStore = () => {
    const state = useStoreSelector(
        (state: RootState) => state.videos,
    );
    const loading = useStoreSelector(
        (state: RootState) => state.loading.effects.videos,
    );

    return Object.assign(state, {
        loading: {
            nextPage: loading.nextPage,
            search: loading.search,
            get: loading.get,
            chungeCountItemsOnPage:
                loading.chungeCountItemsOnPage,
        },
    });
};
const useVideosDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        get: dispatch.videos.get,
        nextPage: dispatch.videos.nextPage,
        clear: dispatch.videos.clear,
        removeFromListiong:
            dispatch.videos.removeFromListiong,
        search: dispatch.videos.search,
        jumpTo: dispatch.videos.jumpTo,
    };
};

export { useVideosStore, useVideosDispatch };
