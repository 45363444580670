import { useUserInfo } from '../../../../src/hooks/User/selectors';

export enum EntityAction {
    view = 'view',
    add = 'add',
    change = 'change',
    delete = 'delete',
}

type CheckPermissionsProps = {
    entity: string;
    action: EntityAction;
};

export const useCheckPermissions = ({
    entity,
    action,
}: CheckPermissionsProps) => {
    const { me, rootPermissions } = useUserInfo();

    if (!rootPermissions.includes(entity)) return false;

    return Boolean(
        me?.permissions.find(
            (p) => p === `${entity}.${action}`,
        ),
    );
};
