import React, { useEffect, useState } from 'react';
import { Modal } from '@mega/utils';
import {
    useInfounitTaskDispatch,
    useInfounittasksStore,
    useInfounitTaskStore,
} from '@mega/store';
import {
    base,
    styledCloseIcon,
    styledLoader,
} from './PreviewModal.css';
import { InfoUnitPreview } from '../InfoUnitPreview';
import { Close } from '@mega/icons';
import { Loader } from '@mega/ui';

interface PreviewModalProps {
    isOpen: boolean;
    onClose: () => void;
    unitId: number;
}

export const PreviewModal: React.FC<PreviewModalProps> = (
    props,
) => {
    const { isOpen, onClose, unitId } = props;
    const [data, setData] = useState(null);
    const handleClose = () => {
        onClose();
    };
    const { getUnit } = useInfounitTaskDispatch();
    const store = useInfounittasksStore();
    const handleGetUnit = async () => {
        const res = await getUnit({ id: unitId });
        if (res) {
            setData(res.data);
        }
    };
    useEffect(() => {
        if (isOpen) {
            handleGetUnit();
        }
    }, [isOpen]);
    return (
        <Modal onClose={handleClose} open={isOpen}>
            <div className={base}>
                <div
                    className={styledCloseIcon}
                    onClick={handleClose}
                >
                    <Close />
                </div>
                {data ? (
                    <InfoUnitPreview data={data} />
                ) : (
                    <div className={styledLoader}>
                        <Loader size="large" />
                    </div>
                )}
            </div>
        </Modal>
    );
};
