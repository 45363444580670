import { createModel } from '@rematch/core';
import * as CLIENT from '@mega/api';
import type {
    Videos,
    VideosQuery,
    VideosWithPagination,
} from '@mega/api';
import { debounce, isUndefined } from 'lodash-es';

import type { RootModel } from '../rootModel';
import { DefaultStore } from '../type';
import { parseQueryString } from '../transformers';

const urlParams = new URLSearchParams(location.search);

const defaultStore: DefaultStore<Videos, VideosQuery> = {
    currentPage: 1,
    query: {
        per_page: 30,
    },
    meta: {
        count: null,
    },
    hasMore: false,
    pages: {},
};

const videos = createModel<RootModel>()({
    name: 'videos',
    state: defaultStore,
    reducers: {
        changePerPage: (
            state,
            payload: { perPage: number },
        ) => {
            return { ...state, perPage: payload.perPage };
        },
        putCurrentPage: (
            state,
            payload: { triggerPage: number },
        ) => {
            return {
                ...state,
                currentPage: payload.triggerPage,
            };
        },
        putPage: (state, payload: VideosWithPagination) => {
            return {
                ...state,
                meta: {
                    count: isUndefined(payload.count)
                        ? null
                        : payload.count,
                },
                pages: {
                    ...state.pages,
                    [`${state.currentPage}`]:
                        payload.results,
                },
            };
        },
        putPages: (state, payload) => {
            return { ...state, pages: payload };
        },
        putQuery: (state, payload: VideosQuery) => {
            return {
                ...state,
                query: { ...state.query, ...payload },
            };
        },
        clear: () => {
            return defaultStore;
        },
    },
    effects: (dispatch) => ({
        search: async (
            query: Pick<VideosQuery, 'search'>,
        ) => {
            await dispatch.videos.putQuery(query);
            await dispatch.videos.afterSearch(null);
        },
        afterSearch: debounce((_, state) => {
            dispatch.videos.get(state.videos.query);
        }, 1000),
        get: async (query, store) => {
            const { query: storeQuery } = store.videos;
            try {
                const response = await CLIENT.videos.get?.(
                    {
                        ...storeQuery,
                        ...query,
                        is_count: true,
                        page: store.videos.currentPage,
                    },
                    {
                        headers: {
                            'X-Site':
                                store.site.site?.id || '',
                        },
                    },
                );
                if (response?.ok) {
                    const { data } = response;

                    dispatch.videos?.putPage(data);
                }
            } catch (e) {
                if (
                    CLIENT.videos.get &&
                    e instanceof CLIENT.videos.get.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },
        nextPage: async (_: any, store) => {
            const { query, currentPage } = store.videos;
            await dispatch.videos.putCurrentPage({
                triggerPage: currentPage + 1,
            });
            await dispatch.videos.get({
                ...query,
                page: currentPage + 1,
            });
        },

        chungeCountItemsOnPage: async (
            perPage: number,
            state,
        ) => {
            if (
                Math.floor(
                    state?.videos?.meta.count ??
                        0 / perPage,
                ) > 0
            ) {
                await dispatch.videos.changePerPage({
                    perPage,
                });
                await dispatch.videos.get({});
            }
        },
        /* #endregion */
        removeFromListiong: async (id: number, store) => {
            await dispatch.video.remove(id);
            const test = Object.entries(
                store.videos.pages,
            ).reduce((acc, [key, value]) => {
                return {
                    ...acc,
                    [key]: value.filter(
                        (item) => item.id !== id,
                    ),
                };
            }, {});
            dispatch.videos.putPages(test);
        },
        jumpTo: async (page: number) => {
            await dispatch.videos.putQuery({ page: page });
            await dispatch.videos.putCurrentPage({
                triggerPage: page,
            });
            dispatch.videos.get({
                page: page,
            });
        },
    }),
});

export { videos };
export default videos;
