import type { PluginTemplate } from '@apostroph/types';
import { definitions } from '@mega/api';

export enum ImgEnum {
    type = 'IMG',
}

export interface ImgProps {
    id: string;
    type: string;
    parentId: string | null;
}

export type ImgMeta = definitions['AdminImage'];

export type ImgPlugin = PluginTemplate<
    ImgEnum.type,
    ImgMeta
>;
export type ImgPluginDefault = Omit<ImgPlugin, 'id'>;
