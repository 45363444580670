import {
    InitialReportType,
    ReportType,
} from './Reports.types';

const defaultFormat = [
    {
        value: 'xlsx',
        label: 'xlsx',
        type: 'application/xlsx',
    },
];

const newsFormat = [
    ...defaultFormat,
    {
        value: 'zip',
        label: 'zip',
        type: 'application/zip',
    },
    {
        value: 'pdf',
        label: 'pdf',
        type: 'application/pdf',
    },
];

const mainFormat = [
    ...defaultFormat,
    {
        value: 'csv',
        label: 'csv',
        type: 'text/csv',
    },
];

export const fullFormats = [...mainFormat, ...newsFormat];

export const reportTypeList: ReportType<InitialReportType>[] =
    [
        {
            value: 'articles',
            label: 'Новости',
            formats: mainFormat,
        },
        {
            value: 'videos',
            label: 'Видео',
            formats: mainFormat,
        },
        {
            value: 'indicators',
            path: 'aggregates/indicators',
            label: 'Показатели',
        },
        {
            value: 'newsmosreg',
            label: 'Отчет по контракту',
            formats: newsFormat,
        },
    ];
