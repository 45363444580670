import { parse } from 'query-string';

export const parseQueryString = <T>(
    s: string,
    key: string,
    fn: (s: string) => T,
) => {
    const parsed = parse(s);
    const get = parsed?.[key] as string | null;
    if (get) {
        if (fn) {
            return fn(get);
        }
        return null;
    }
    return null;
};
