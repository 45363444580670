import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';

const useSiteStore = () => {
    const store = useStoreSelector(
        (state: RootState) => state.site.site,
    );
    const loading = useStoreSelector(
        (state: RootState) => state.loading.effects.site,
    );

    return store;
};

const useSiteDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();
    return {
        pinSite: dispatch.site.pinSite,
        unpinSite: dispatch.site.unpinSite,
    };
};

export { useSiteStore, useSiteDispatch };
