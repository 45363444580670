import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgHorizontalRule = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M3 9.563V8.437h12v1.126H3Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgHorizontalRule);
export default ForwardRef;
