import React, { FC, ReactElement } from 'react';
import { CrumbsContextProvider } from './Crumbs.context';
import { crumbsClasses, CrumbsClasses } from '@mega/styles';

export interface BreadCrItemProps {
    separator?: ReactElement;
    onClick?: () => void;
    children: ReactElement;
}

const Crumbs: FC<
    BreadCrItemProps & CrumbsClasses['recipe']
> = ({ separator, onClick, children, color }) => {
    const hasSeparator = Boolean(separator);

    return (
        <CrumbsContextProvider inCrumbs={true}>
            <div
                className={crumbsClasses.recipe({ color })}
                onClick={onClick}
            >
                {hasSeparator && separator}
                {children}
            </div>
        </CrumbsContextProvider>
    );
};

export { Crumbs };
