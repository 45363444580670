import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { Modal } from '@mega/utils';
import {
    base,
    checkedItem,
    contentWrapper,
    gridWrapper,
    inputWrapper,
    modalTitle,
    pagination,
    videoFooter,
    videoGrid,
    videoItem,
    videoWrapper,
} from './VideoModal.css';
import { fmtMSS } from '@mega/admin/src/utils';
import { FiCheck } from 'react-icons/fi';
import * as CLIENT from '@mega/api';
import { definitions } from '@mega/api';
import { motion } from 'framer-motion';
import { Button, Loader, Typography } from '@mega/ui';
import { Input, Pagination } from '@mui/material';
import { debounce } from 'lodash-es';
import { usePagination } from './usePagination';
import { useSiteStore } from '@mega/store';

interface VideoModalProps {
    isOpen: boolean;
    videoId?: number;
    setIsOpen: (open: boolean) => void;
    handleVideoSave?: (
        cover: definitions['AdminHostVideoList'],
    ) => void;
}

const { getOptions } = CLIENT.videos;

const perPage = 9;
export const VideoModal: React.FC<VideoModalProps> = (
    props,
) => {
    const { isOpen, setIsOpen, handleVideoSave, videoId } =
        props;
    const [search, setSearch] = useState('');
    const [count, setCount] = useState(0);
    const { page, setPage, totalPages } = usePagination({
        count,
        perPage,
    });
    const [isLoaded, setIsLoaded] = useState(false);

    const [videoList, setVideoList] = useState<
        definitions['AdminHostVideoList'][]
    >([]);
    const [cover, setCover] =
        useState<definitions['AdminHostVideoList']>();

    const site = useSiteStore();

    const getArticleVideos = async (query: {
        search?: string;
        page?: number;
        per_page?: number;
        is_count?: boolean;
    }) => {
        setIsLoaded(false);
        const res = await getOptions?.(query, {
            headers: {
                'X-Site': site?.id ?? '',
            },
        });

        if (res?.data) {
            setIsLoaded(true);
            const { results, count } = res.data;
            if (count) setCount(count);
            setVideoList(results);
        }
    };

    const handleSave = (
        cover: definitions['AdminHostVideoList'],
    ) => {
        if (cover?.id && handleVideoSave) {
            handleVideoSave(cover);
        }
        handleClose();
    };

    const handleClose = () => {
        setSearch('');
        setIsOpen(false);
        setIsLoaded(false);
    };

    const toggleCover = (
        item: definitions['AdminHostVideoList'],
    ) => {
        if (item.id === cover?.id) {
            return setCover({ title: '' });
        }
        return setCover(item);
    };

    const searchArticleVideos = () => {
        if (!isOpen) return null;
        getArticleVideos({
            search,
            page,
            per_page: perPage,
            is_count: true,
        });
    };
    const delayedSearch = useCallback(
        debounce(searchArticleVideos, 300),
        [search, page, isOpen],
    );

    useEffect(() => {
        delayedSearch();
        return delayedSearch.cancel;
    }, [search, delayedSearch, page]);

    useEffect(() => {
        const currentCover = videoList.find(
            (item) => item.id === videoId,
        ) || { title: '' };
        setCover(currentCover);
    }, [videoList, videoId]);

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            onClick={(e: MouseEvent) => e.stopPropagation()}
        >
            <div className={base}>
                <Typography
                    className={modalTitle}
                    weight={'bold'}
                    size={'24'}
                >
                    Выберите видеообложку
                </Typography>
                <div className={inputWrapper}>
                    <Input
                        name="search"
                        type="text"
                        value={search}
                        placeholder="Поиск видео по названию"
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                    />
                </div>
                <div className={contentWrapper}>
                    {isLoaded ? (
                        <div className={gridWrapper}>
                            <div className={videoGrid}>
                                {videoList.length ? (
                                    videoList.map(
                                        (item) => (
                                            <motion.div
                                                key={
                                                    item.id
                                                }
                                                whileHover={{
                                                    scale: 1.05,
                                                    filter: 'brightness(0.95)',
                                                }}
                                                className={
                                                    videoWrapper
                                                }
                                                onClick={() => {
                                                    toggleCover(
                                                        item,
                                                    );
                                                }}
                                            >
                                                <video
                                                    src={
                                                        item?.file_link
                                                    }
                                                    className={
                                                        videoItem
                                                    }
                                                    controls
                                                    muted
                                                    poster={
                                                        item?.frame
                                                    }
                                                />
                                                {cover?.id ===
                                                    item.id && (
                                                    <div
                                                        className={
                                                            checkedItem
                                                        }
                                                    >
                                                        <FiCheck />
                                                    </div>
                                                )}

                                                <div
                                                    className={
                                                        videoFooter
                                                    }
                                                >
                                                    <div>
                                                        {item.title ===
                                                        ''
                                                            ? 'Без названия'
                                                            : item.title}
                                                    </div>
                                                    <div>
                                                        {item.video_duration
                                                            ? fmtMSS(
                                                                  Math.trunc(
                                                                      item.video_duration,
                                                                  ),
                                                              )
                                                            : '-'}
                                                    </div>
                                                </div>
                                            </motion.div>
                                        ),
                                    )
                                ) : (
                                    <Typography>
                                        Результаты не
                                        найдены
                                    </Typography>
                                )}
                            </div>
                            <div className={pagination}>
                                <Pagination
                                    count={totalPages}
                                    onChange={(e, value) =>
                                        setPage(value)
                                    }
                                    page={page}
                                    size="small"
                                />
                            </div>
                        </div>
                    ) : (
                        <Loader size={'small'} />
                    )}
                </div>
                <Button
                    label={'Сохранить'}
                    classes={{
                        paper: {
                            color: 'dark',
                            variant: 'filled',
                        },
                    }}
                    size={'small'}
                    onClick={() =>
                        cover?.id && handleSave(cover)
                    }
                />
            </div>
        </Modal>
    );
};
