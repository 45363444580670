import React from 'react';
import { RepostCreate as RepostCreateForm } from '@mega/forms';
import { useRepostDispatch } from '@mega/store';
import { RepostCreate as RepostCreateType } from '@mega/api';
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from 'react-router-dom';

// export const RepostCreate: React.FC = () => {
//     return <RepostCreateForm />;
// };

export const RepostCreate: React.FC = () => {
    const { create } = useRepostDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const location = useLocation();
    const navigate = useNavigate();

    const handleCreate = async (
        value: RepostCreateType,
    ) => {
        return create({
            payload: value,
            options: {
                router: {
                    navigate,
                    location,
                },
                notifier: {
                    enqueueSnackbar,
                },
            },
        });
    };

    return <RepostCreateForm handleCreate={handleCreate} />;
};
