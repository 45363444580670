import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgFilterList = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 3.833H6.667a.5.5 0 1 1 0-1H14a.5.5 0 0 1 0 1ZM14 13.167H6.667a.5.5 0 0 1 0-1H14a.5.5 0 1 1 0 1ZM3.333 2.5a.833.833 0 1 1 0 1.667.833.833 0 0 1 0-1.667Zm1.834.833a1.833 1.833 0 1 0-3.667 0 1.833 1.833 0 0 0 3.667 0ZM3.333 11.833a.833.833 0 1 1 0 1.667.833.833 0 0 1 0-1.667Zm1.834.834a1.833 1.833 0 1 0-3.667 0 1.833 1.833 0 0 0 3.667 0ZM2 8.5h7.333a.5.5 0 1 0 0-1H2a.5.5 0 0 0 0 1ZM12.667 7.167a.833.833 0 1 0 0 1.666.833.833 0 0 0 0-1.666ZM10.833 8A1.833 1.833 0 1 1 14.5 8a1.833 1.833 0 0 1-3.667 0Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgFilterList);
export default ForwardRef;
