import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgTableStrokeDown = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M11.25 8.75V5.5h1.5v3.25H16v1.5h-3.25v3.25h-1.5v-3.25H8v-1.5h3.25Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 2.25A2.75 2.75 0 0 0 2.25 5v14A2.75 2.75 0 0 0 5 21.75h14A2.75 2.75 0 0 0 21.75 19V5A2.75 2.75 0 0 0 19 2.25H5Zm15.25 13V5c0-.69-.56-1.25-1.25-1.25H5c-.69 0-1.25.56-1.25 1.25v10.25h16.5Zm-4.5 1.5v3.5H19c.69 0 1.25-.56 1.25-1.25v-2.25h-4.5Zm-1.5 0h-4.5v3.5h4.5v-3.5Zm-6 0v3.5H5c-.69 0-1.25-.56-1.25-1.25v-2.25h4.5Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgTableStrokeDown);
export default ForwardRef;
