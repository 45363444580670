import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgIconRefresh = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M7.044 12.25a5.13 5.13 0 0 1-2.056-.415 5.427 5.427 0 0 1-1.685-1.131 5.278 5.278 0 0 1-1.138-1.677A5.131 5.131 0 0 1 1.75 6.97c0-.73.139-1.41.415-2.042a5.238 5.238 0 0 1 1.138-1.655 5.388 5.388 0 0 1 1.684-1.116 5.21 5.21 0 0 1 2.057-.408c.777 0 1.514.17 2.209.51a5.55 5.55 0 0 1 1.8 1.4V2.115h.877V5.15H8.88v-.876h1.533a5.071 5.071 0 0 0-1.51-1.196 3.967 3.967 0 0 0-1.859-.452c-1.215 0-2.255.415-3.12 1.247-.866.831-1.299 1.85-1.299 3.055 0 1.235.428 2.285 1.283 3.15.856.865 1.901 1.298 3.136 1.298 1.215 0 2.241-.428 3.077-1.283.836-.856 1.254-1.891 1.254-3.107h.875c0 1.458-.505 2.7-1.517 3.726-1.01 1.026-2.24 1.539-3.69 1.539Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgIconRefresh);
export default ForwardRef;
