import React, {
    FC,
    memo,
    useCallback,
    useMemo,
} from 'react';
import {
    HeadTemplatePlugin,
    TemplatePlugin,
} from '@mega/editor';
import { useBlock } from '@apostroph/store';
import {
    UploadFileMeta,
    UploadFilePlugin,
    UploadFileProps,
} from './UploadFile.types';
import { Icon, Typography } from '@mega/ui';
import { File as FileIcon } from '@mega/icons';
import { Uploader } from './widget/Uploader';
import { useField } from 'formik';
import { UploaderProvider } from './model/Uploader.provider';

const UploadFile: FC<UploadFileProps> = ({
    id,
    parentId,
    type,
}) => {
    const { handleUpdateElement, getCurrentDataElement } =
        useBlock(id);

    const { meta, childrenElement } =
        getCurrentDataElement<UploadFileMeta>();

    const [field, _meta, helpers] = useField<Array<number>>(
        {
            name: 'files',
        },
    );

    const updateCurrentElement = useCallback(
        (element: Omit<UploadFilePlugin, 'meta'>) =>
            (meta: UploadFileMeta) => {
                handleUpdateElement({ ...element, meta });
            },

        [],
    );

    return (
        <TemplatePlugin
            head={
                <HeadTemplatePlugin
                    id={id}
                    parentId={parentId}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '4px',
                            color: 'black',
                        }}
                    >
                        <Icon size="16">
                            <FileIcon />
                        </Icon>
                        <Typography size={'12'}>
                            Загрузка файлов
                        </Typography>
                    </div>
                </HeadTemplatePlugin>
            }
        >
            <UploaderProvider
                files={meta.files}
                externalState={{
                    formik: {
                        field,
                    },
                }}
                dependencies={{
                    apostroph: {
                        updateCurrentElement:
                            updateCurrentElement({
                                id,
                                type,
                                childrenElement,
                            }),
                    },
                    formik: {
                        helpers,
                    },
                }}
            >
                <Uploader />
            </UploaderProvider>
        </TemplatePlugin>
    );
};

const UploadFileMemo = memo(UploadFile);

export { UploadFileMemo as UploadFile };
