import { PluginTemplate } from '@pages/smartGrid/types/blocks';

export enum AuthorEnum {
    type = 'CONTENT_AUTHOR',
}

export interface AuthorProps {
    id: string;
    type: string;
    parentId: string | null;
    meta: Record<string, unknown>;
}

export type AuthorMeta = {};

export type AuthorPlugin = PluginTemplate<
    AuthorEnum.type,
    AuthorMeta
>;
export type AuthorPluginDefault = Omit<AuthorPlugin, 'id'>;
