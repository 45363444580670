import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';

const useInfiniteMixListStore = () => {
    const state = useStoreSelector(
        (state: RootState) => state.infiniteMixList,
    );
    const loading = useStoreSelector(
        (state: RootState) =>
            state.loading.effects.infiniteMixList,
    );

    return Object.assign(state, {
        loading: {
            get: loading.get,
            chungeCountItemsOnPage:
                loading.chungeCountItemsOnPage,
        },
    });
};
const useInfiniteMixListDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        get: dispatch.infiniteMixList.get,
        nextPage: dispatch.infiniteMixList.nextPage,
        search: dispatch.infiniteMixList.search,
        clear: dispatch.infiniteMixList.clear,
        jumpTo: dispatch.infiniteMixList.jumpTo,
    };
};

export {
    useInfiniteMixListStore,
    useInfiniteMixListDispatch,
};
