import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { definitions } from '@mega/api';
import {
    InputField,
    WithLabel,
    Typography,
    Stack,
    Button,
    Base,
} from '@mega/ui';
import { headerText, footerText } from './createUser.css';

export interface CreateUserProps {}

const CreateUser: FC<CreateUserProps> = () => {
    // const { login } = useAuthDispatch();
    // const [, setFormError] = useState<string | null>(null);
    // const [] = useStoreLoading();
    const initialValues: Partial<
        definitions['TokenObtainPair']
    > = {};
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
                username: Yup.string()
                    .min(
                        2,
                        'Это поле явно должно быть больше одного символа',
                    )
                    .required('Это поле обязательное'),
                password: Yup.string()
                    .min(
                        1,
                        'Пароль из одного символа? Серьезно?',
                    )
                    .required('Это поле обязательное'),
            })}
            onSubmit={async () => {
                // if (values && values.password && values.username) {
                //   const responce = await login?.({
                //     username: values.username,
                //     password: values.password,
                //   });
                //   setFormError(responce.detail);
                // }
            }}
        >
            <Form>
                <Stack gap="16">
                    <Stack gap="40">
                        <Typography
                            className={headerText}
                            size="32"
                            weight="bold"
                        >
                            Запрос доступа в терминал 360
                        </Typography>
                        <Stack gap="24">
                            <WithLabel
                                id="1"
                                labelSide="top"
                                title="Контактное лицо"
                            >
                                <InputField
                                    name="username"
                                    type="text"
                                    placeholder="ФИО"
                                />
                            </WithLabel>

                            <WithLabel
                                id="1"
                                labelSide="top"
                                title="Компания"
                            >
                                <InputField
                                    name="company"
                                    type="text"
                                    placeholder="Название компании"
                                />
                            </WithLabel>

                            <WithLabel
                                id="1"
                                labelSide="top"
                                title="E-mail"
                            >
                                <InputField
                                    name="email"
                                    type="email"
                                    placeholder="sample@mail.ru"
                                />
                            </WithLabel>

                            <WithLabel
                                id="1"
                                labelSide="top"
                                title="Рабочий телефон"
                            >
                                <InputField
                                    name="telephone"
                                    type="tel"
                                />
                            </WithLabel>

                            <WithLabel
                                id="1"
                                labelSide="top"
                                title="Должность"
                            >
                                <InputField
                                    name="position"
                                    type="text"
                                />
                            </WithLabel>

                            <WithLabel
                                id="1"
                                labelSide="top"
                                title="Тематика новостей"
                            >
                                <InputField
                                    name="theme news"
                                    type="text"
                                    placeholder="Какие темы новостей вам интересны?"
                                />
                            </WithLabel>
                            <Base width="250px">
                                <Button label="Отправить запрос" />
                            </Base>
                        </Stack>
                    </Stack>
                    <Typography
                        className={footerText}
                        size="12"
                        weight="regular"
                    >
                        Нажимая кнопку «Отправить запрос», я
                        даю своё согласие на обработку
                        персональных данных на условиях и
                        для целей, определенных Политикой и
                        Правилами использования.
                    </Typography>
                </Stack>

                {/* <button type="submit">
          {stageLogin.loading ? '...loading' : 'Submit'}
        </button>
        {formError} */}
            </Form>
        </Formik>
    );
};

export { CreateUser };
