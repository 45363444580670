import React from 'react';
import { useFormikContext } from 'formik';
import { MediaLoaderWithMS, Slide } from '@mega/core';
import { slideGrid } from './GalleryForm.css';
import { Article, definitions } from '@mega/api';
import { removeFromFileArray } from '@mega/utils/src/updateFileArrays';
import { IconButton } from '@mega/ui';
import { ArrowLeft, ArrowRight } from '@mega/icons';

export interface GalleryFormProps {
    changeOrder?: (
        id: number | undefined,
        type: 'next' | 'prev',
    ) => () => void;
    handleUpdateItem: (
        data: Array<definitions['AdminImage']>,
    ) => void;
    slides: Array<definitions['AdminImage']>;
    handleUpdateUpload: (
        data: Array<definitions['AdminImage']>,
    ) => void;
}

export const GalleryForm: React.FC<GalleryFormProps> = (
    props,
) => {
    const { handleUpdateItem, slides, handleUpdateUpload } =
        props;
    const { values, setFieldValue } =
        useFormikContext<Article>();

    const handleUpload = (
        res: definitions['AdminImage'],
    ) => {
        let currentSlides;

        if (slides.find((item) => res.id === item.id)) {
            currentSlides = slides.map((item) => {
                if (item.id === res.id) {
                    return res;
                }
                return item;
            });

            handleUpdateItem([...currentSlides]);
        } else {
            handleUpdateUpload([{ ...res }]);
        }
    };
    const deleteSlide = (id: number) => {
        const currentSlides = slides.filter(
            (item) => id !== item.id,
        );
        removeFromFileArray({
            ids: [id],
            field: 'images',
            values,
            setFieldValue,
        });
        handleUpdateItem(currentSlides);
    };

    return (
        <div className={slideGrid}>
            {slides?.map((item, index) => {
                return (
                    <div
                        style={{ position: 'relative' }}
                        key={item.id}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                top: '25%',
                                left: '5%',
                                zIndex: 100,
                            }}
                        >
                            {index !== 0 && (
                                <IconButton
                                    onClick={props.changeOrder?.(
                                        item.id,
                                        'prev',
                                    )}
                                    classes={{
                                        paper: {
                                            color: 'white',
                                        },
                                    }}
                                    style={{
                                        boxShadow:
                                            '#0c0c0c 3px 3px 9px 0px',
                                    }}
                                >
                                    <ArrowLeft />
                                </IconButton>
                            )}
                        </div>
                        <Slide
                            key={item.id}
                            img={item}
                            isLast={
                                slides.length === index + 1
                            }
                            handleUpload={handleUpload}
                            id={index}
                            deleteSlide={deleteSlide}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                top: '25%',
                                right: '5%',
                                zIndex: 100,
                            }}
                        >
                            {index !==
                                slides.length - 1 && (
                                <IconButton
                                    onClick={props.changeOrder?.(
                                        item.id,
                                        'next',
                                    )}
                                    classes={{
                                        paper: {
                                            color: 'white',
                                        },
                                    }}
                                    style={{
                                        boxShadow:
                                            '#0c0c0c -3px 3px 9px 0px',
                                    }}
                                >
                                    <ArrowRight />
                                </IconButton>
                            )}
                        </div>
                    </div>
                );
            })}
            <Slide
                handleUpload={handleUpload}
                id={slides?.length}
                deleteSlide={deleteSlide}
                hasMS
            />
        </div>
    );
};
