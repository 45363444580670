import React, { FC, useEffect, useMemo } from 'react';
import { Column, CellValue } from 'react-table';
import type { AdsHeadScriptsEntity as HeadScriptType } from '@mega/api';
import { ListingTable } from '@mega/ui';
import { typographyClasses } from '@mega/styles';
import {
    useAdsHeadScriptsDispatch,
    useAdsHeadScriptsStore,
} from '@mega/store';
import { headItem, listingRecipe } from '../Listing.css';
import { TitleColumn, PublicationColumn } from '../Columns';

export interface ListingHeadScriptProps {}

const ListingHeadScripts: FC<
    ListingHeadScriptProps
> = () => {
    const store = useAdsHeadScriptsStore();
    const { get, clear } = useAdsHeadScriptsDispatch();

    useEffect(() => {
        get({});
        return () => {
            clear();
        };
    }, [get, clear]);

    const columns: Column<HeadScriptType>[] = React.useMemo(
        () => [
            {
                Header: 'Название',
                accessor: (row) => {
                    return {
                        title: row.title,
                        id: row.id,
                    };
                },
                Cell: ({ value }: CellValue) => (
                    <TitleColumn {...value} />
                ),
                minWidth: 200,
                width: '100%',
            },
            {
                Header: 'Публикация',
                accessor: (row) => {
                    return {
                        pubOn: row.is_active,
                    };
                },

                Cell: ({ value }: CellValue) => {
                    return (
                        <PublicationColumn
                            onlyIcon
                            {...value}
                        />
                    );
                },
            },
            {
                Header: 'На всех страницах',
                accessor: (row) => {
                    return {
                        pubOn: row.is_common,
                    };
                },

                Cell: ({ value }: CellValue) => {
                    return (
                        <PublicationColumn
                            onlyIcon
                            {...value}
                        />
                    );
                },
            },
        ],
        [],
    );
    const data = useMemo(() => {
        const values =
            store.pages?.[store.currentPage] || [];
        return Object.values(values).flat();
    }, [store.pages]);

    return (
        <>
            <ListingTable
                getHeaderGroupProps={(cellInfo) => {
                    return {
                        className: listingRecipe({
                            header: true,
                        }),
                    };
                }}
                getHeaderProps={(cellInfo) => {
                    return {
                        className: headItem({
                            title:
                                cellInfo?.id === 'Название',
                        }),
                    };
                }}
                getRowGroupProps={() => {
                    return {
                        className: [
                            typographyClasses.recipe({
                                size: '14',
                            }),
                            listingRecipe({
                                header: false,
                            }),
                        ].join(' '),
                    };
                }}
                columns={columns}
                data={data}
            />
        </>
    );
};

export { ListingHeadScripts };
