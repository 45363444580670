import * as CLIENT from '@mega/api';
import { createModel } from '@rematch/core';
import { isEqual } from 'lodash-es';
import type { RootModel } from '../rootModel';
import type { RoomUser } from '../type';

export interface RoomModel {
    isInit: boolean;
    rooms: Array<RoomUser>;
}

const articleRoom = createModel<RootModel>()({
    name: 'articleRoom',
    state: {
        isInit: false,
        rooms: [],
    } as RoomModel,
    reducers: {
        putRoom: (state, payload: Array<RoomUser>) => {
            return {
                ...state,
                rooms: payload,
                isInit: true,
            };
        },
        clear: () => ({
            rooms: [],
            isInit: false,
        }),
    },
    effects: (dispatch) => ({
        checkin: async (params: { id: number }, state) => {
            try {
                const response =
                    await CLIENT?.rooms?.checkin?.({
                        obj_id: `${params.id}`,
                    });
                if (response?.ok) {
                    const checkinUser =
                        response?.data?.users || [];

                    if (
                        !isEqual(
                            state.articleRoom.rooms.sort(),
                            checkinUser.sort(),
                        )
                    ) {
                        dispatch.articleRoom.putRoom(
                            checkinUser,
                        );
                    }
                }
            } catch (e) {
                if (
                    CLIENT.rooms.checkin &&
                    e instanceof CLIENT.rooms.checkin.Error
                ) {
                    const error = e.getActualType();
                    if (error.status === 401) {
                        const { isOk } =
                            await dispatch.auth[401](error);
                        if (isOk) {
                            dispatch.articleRoom.checkin(
                                params,
                            );
                        }
                    }
                }
            }
        },
    }),
});

export { articleRoom };
export default articleRoom;
