import React, { PropsWithChildren } from 'react';
import Select, {
    components,
    ContainerProps,
} from 'react-select';

import { selectSingleClasses } from '../../SelectSingle.css';

type ContainerOverideProps = ContainerProps<
    Record<string, unknown>,
    true
>;

const ContainerPrimary = ({
    children,
    ...props
}: PropsWithChildren<ContainerOverideProps>) => {
    return (
        <div
            className={selectSingleClasses.recipe({
                variant: 'primary',
            })}
        >
            <components.SelectContainer {...props}>
                {children}
            </components.SelectContainer>
        </div>
    );
};

const ContainerSecondary = ({
    children,
    ...props
}: PropsWithChildren<ContainerOverideProps>) => {
    return (
        <div
            className={selectSingleClasses.recipe({
                variant: 'secondary',
            })}
        >
            <components.SelectContainer {...props}>
                {children}
            </components.SelectContainer>
        </div>
    );
};

const ContainerThird = ({
    children,
    ...props
}: PropsWithChildren<ContainerOverideProps>) => {
    return (
        <div
            className={selectSingleClasses.recipe({
                variant: 'third',
            })}
        >
            <components.SelectContainer {...props}>
                {children}
            </components.SelectContainer>
        </div>
    );
};

export {
    ContainerPrimary,
    ContainerSecondary,
    ContainerThird,
};
export default ContainerPrimary;
