import { ReactElement } from 'react';
import { PluginTemplate } from '@pages/smartGrid/types/blocks';

export enum ArticleListEnum {
    type = 'ARTICLE_LIST',
}

export interface ArticleListProps {
    id: string;
    type: string;
    meta: any;
    parentId: string | null;
    children: ReactElement | ReactElement[];
}

export type ArticleListMeta = {};

export type ArticleListPlugin = PluginTemplate<
    ArticleListEnum.type,
    ArticleListMeta
>;
export type ArticleListPluginDefault = Omit<
    ArticleListPlugin,
    'id'
>;
