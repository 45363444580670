import * as CLIENT from '@mega/api';
import type {
    GetByIdADSBlock,
    GetByIdADSBlockError,
    GetByIdADSBlockQuery,
    CreateADSBlock,
    CreateADSBlockError,
    CreateADSBlockQuery,
    PartialUpdateADSBlock,
    PartialUpdateADSBlockError,
    PartialUpdateADSBlockQuery,
    DeleteADSBlock,
    DeleteADSBlockError,
    DeleteADSBlockQuery,
    IisOk,
    IisNotOk,
} from '@mega/api';

export type GetADSBlockPromise = Promise<
    | IisOk<GetByIdADSBlock>
    | IisNotOk<GetByIdADSBlockError | null>
>;

export const getADSBlockById = async (
    query: GetByIdADSBlockQuery,
    site: string,
): GetADSBlockPromise => {
    try {
        const response =
            await CLIENT?.ads?.getAdsBlocksById?.(
                {
                    ...query,
                },
                {
                    headers: {
                        'X-Site': site,
                    },
                },
            );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.streams.get &&
            e instanceof CLIENT.streams.get.Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};

export type CreateADSBlockPromise = Promise<
    | IisOk<CreateADSBlock>
    | IisNotOk<CreateADSBlockError | null>
>;

export const createADSBlock = async (
    query: CreateADSBlockQuery,
    site: string,
): GetADSBlockPromise => {
    try {
        const response =
            await CLIENT?.ads?.createAdsBlocks?.(
                {
                    ...query,
                    site,
                },
                {
                    headers: {
                        'X-Site': site,
                    },
                },
            );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.ads.createAdsBlocks &&
            e instanceof CLIENT.ads.createAdsBlocks.Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};

export type PartialUpdateADSBlockPromise = Promise<
    | IisOk<PartialUpdateADSBlock>
    | IisNotOk<PartialUpdateADSBlockError | null>
>;

export const partialUpdateADSBlockById = async (
    query: PartialUpdateADSBlockQuery,
    site: string,
): PartialUpdateADSBlockPromise => {
    try {
        const response =
            await CLIENT?.ads?.updatePartialAdsBlocksById?.(
                {
                    ...query,
                },
                {
                    headers: {
                        'X-Site': site,
                    },
                },
            );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.ads.updatePartialAdsBlocksById &&
            e instanceof
                CLIENT.ads.updatePartialAdsBlocksById.Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};

export type DeleteADSBlockPromise = Promise<
    | IisOk<DeleteADSBlock>
    | IisNotOk<DeleteADSBlockError | null>
>;

export const deleteADSBlockById = async (
    query: DeleteADSBlockQuery,
    site: string,
): DeleteADSBlockPromise => {
    try {
        const response =
            await CLIENT?.ads?.deleteAdsBlocksById?.(
                {
                    ...query,
                },
                {
                    headers: {
                        'X-Site': site,
                    },
                },
            );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.streams.get &&
            e instanceof CLIENT.streams.get.Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};
