/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stories } from './Stories';
import { StoriesPlugin } from './StoriesPlugin';
import {
    StoriesControl,
    StoriesEnum,
    StoriesPluginDefault,
} from './Stories.types';

const PLUGIN_STORIES_NAME = StoriesEnum.type;

const PLUGIN_META_DEFAULT = {
    dataId: '',
    label: '',
};
const DEFAULT_STORIES: StoriesPluginDefault = {
    type: PLUGIN_STORIES_NAME,
    meta: PLUGIN_META_DEFAULT,
    childrenElement: [],
};

const PLUGIN_STORIES = {
    [PLUGIN_STORIES_NAME]: {
        emptyObject: DEFAULT_STORIES,
        render: Stories,
    },
};

const PLUGIN_STORIES_CONTROL: StoriesControl = {
    name: PLUGIN_STORIES_NAME,
    label: 'Stories',
    render: StoriesPlugin,
    tooltip: 'Создать историю',
    meta: PLUGIN_META_DEFAULT,
    Icon: () => <></>,
};

export {
    DEFAULT_STORIES,
    PLUGIN_STORIES,
    PLUGIN_STORIES_CONTROL,
    PLUGIN_STORIES_NAME,
};
