/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
    Control,
    PositionsVariants,
} from '@apostroph/types';
import { Img } from './Img';
import { ImgPluginDefault, ImgEnum } from './Img.types';
import ImageIcon from '@mui/icons-material/Image';
const PLUGIN_IMG_NAME = ImgEnum.type;

const DEFAULT_IMG: ImgPluginDefault = {
    type: PLUGIN_IMG_NAME,
    meta: {
        copyright: undefined,
        description: undefined,
        fp_x: undefined,
        fp_y: undefined,
        id: undefined,
        image: '',
    },
    childrenElement: [],
};

const PLUGIN_IMG = {
    [PLUGIN_IMG_NAME]: {
        emptyObject: DEFAULT_IMG,
        render: Img,
    },
};

const PLUGIN_IMG_CONTROL: Control = {
    name: PLUGIN_IMG_NAME,
    label: 'Картинка',
    tooltip: 'Вставить картинку',
    position: PositionsVariants.actionFeed,
    Icon: () => <ImageIcon />,
};

export {
    DEFAULT_IMG,
    PLUGIN_IMG,
    PLUGIN_IMG_CONTROL,
    PLUGIN_IMG_NAME,
};
