import React from 'react';
import { Control } from '../../../../types/core';
import { Carousel } from './Carousel';
import {
    CarouselEnum,
    CarouselPluginDefault,
} from './Carousel.types';
import {
    PLUGIN_ARTICLE_NAME,
    PLUGIN_AUTO_ARTICLE_NAME,
} from '../../Content';

const PLUGIN_CAROUSEL_NAME = CarouselEnum.type;

const DEFAULT_CAROUSEL: CarouselPluginDefault = {
    type: PLUGIN_CAROUSEL_NAME,
    meta: {},
    isContainer: true,
    acceptedTypes: [
        PLUGIN_ARTICLE_NAME,
        PLUGIN_AUTO_ARTICLE_NAME,
    ],
    childrenElement: [],
};

const PLUGIN_CAROUSEL = {
    [PLUGIN_CAROUSEL_NAME]: {
        emptyObject: DEFAULT_CAROUSEL,
        render: Carousel,
    },
};

const PLUGIN_CAROUSEL_CONTROL: Control = {
    name: PLUGIN_CAROUSEL_NAME,
    label: 'Carousel',
    tooltip: 'Создать карусель',
    Icon: () => <></>,
};

export {
    DEFAULT_CAROUSEL,
    PLUGIN_CAROUSEL,
    PLUGIN_CAROUSEL_CONTROL,
    PLUGIN_CAROUSEL_NAME,
};
export default PLUGIN_CAROUSEL;
