import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';

const useInfiniteMixStore = () => {
    const store = useStoreSelector(
        (state: RootState) => state.infiniteMix,
    );
    const loading = useStoreSelector(
        (state: RootState) =>
            state.loading.effects.infiniteMix,
    );

    return {
        store,
        loading,
    };
};

const useInfiniteMixDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        get: dispatch.infiniteMix.get,
        put: dispatch.infiniteMix.put,
        create: dispatch.infiniteMix.create,
        update: dispatch.infiniteMix.update,
        remove: dispatch.infiniteMix.remove,
    };
};

export { useInfiniteMixStore, useInfiniteMixDispatch };
