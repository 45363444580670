import React from 'react';
import {
    Control,
    PositionsVariants,
} from '@apostroph/types';
import { ViewDay as ViewDayIcon } from '@mui/icons-material';
import { Section } from './Section';
import {
    SectionPluginDefault,
    SectionEnum,
} from './Section.types';

const PLUGIN_SECTION_NAME = SectionEnum.type;

const DEFAULT_SECTION: SectionPluginDefault = {
    type: PLUGIN_SECTION_NAME,
    meta: {
        name: 'Карточка',
    },
    childrenElement: [],
};

const PLUGIN_SECTION = {
    [PLUGIN_SECTION_NAME]: {
        emptyObject: DEFAULT_SECTION,
        render: Section,
    },
};

const PLUGIN_SECTION_CONTROL: Control = {
    name: PLUGIN_SECTION_NAME,
    label: 'Карточка',
    tooltip: 'Создать карточку',
    position: PositionsVariants.actionFeed,
    Icon: () => <ViewDayIcon />,
};

export {
    DEFAULT_SECTION,
    PLUGIN_SECTION,
    PLUGIN_SECTION_CONTROL,
    PLUGIN_SECTION_NAME,
};
export default PLUGIN_SECTION;
