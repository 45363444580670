import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';

const useMediashareAuthStore = () => {
    const store = useStoreSelector(
        (state: RootState) => state.mediashareAuth,
    );
    const loading = useStoreSelector(
        (state: RootState) =>
            state.loading.effects.mediashareAuth,
    );

    return Object.assign(store, {
        loading: {
            stageInit: loading.stageInit,
            stageLogin: loading.stageLogin,
        },
    });
};

const useMediashareAuthDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        login: dispatch.mediashareAuth.stageLogin,
        logout: dispatch.mediashareAuth.logout,
        retry: dispatch.mediashareAuth.retry,
    };
};

export {
    useMediashareAuthStore,
    useMediashareAuthDispatch,
};
