import React, {
    FC,
    useEffect,
    useState,
    useCallback,
} from 'react';
import { useSnackbar } from 'notistack';
import { MainGrid } from '@tv360/main-grid';
import { useLocation } from 'react-router-dom';
import { useAuthStore, useSiteStore } from '@mega/store';
import { articles } from '@mega/api';
import { camelCase } from 'lodash-es';
export interface GridProps {}

const API_URL =
    process.env?.REACT_APP_API_URL ||
    'https://api.360tv.cltest1.ru/api/v1';
const BASE_URl =
    process.env?.REACT_APP_BASE_CLIENT_URL ||
    'https://360tv.cltest1.ru/';

type Config =
    | ({
          select_card_type?: boolean | undefined;
          select_listing_type?: boolean | undefined;
          date_range?: boolean | undefined;
      } & {
          [key: string]: string;
      })
    | undefined
    | null;

const Grid: FC<GridProps> = () => {
    const location = useLocation();
    const currenSite = useSiteStore();
    const { accessToken } = useAuthStore();
    const [isConfigLoading, setIsConfigLoading] =
        useState<boolean>(false);
    const [config, setConfig] = useState<Config>(null);

    const { enqueueSnackbar } = useSnackbar();

    const getConfig = useCallback(() => {
        setIsConfigLoading(true);
        (async () => {
            try {
                const res =
                    await articles?.getWebsiteMainLatest?.({
                        site: currenSite?.id,
                    });
                if (res?.ok) {
                    const newConfig = Object.entries(
                        res?.data?.config || {},
                    ).reduce((acc, item) => {
                        const [key, value] = item;
                        return {
                            ...acc,
                            [camelCase(key)]: value,
                        };
                    }, {});

                    setConfig(newConfig);
                    setIsConfigLoading(false);
                }
            } catch (e) {}
            // getWebsiteMainLatest()
        })();
    }, [currenSite]);
    useEffect(getConfig, [getConfig, currenSite]);
    if (isConfigLoading) {
        return <div>loading</div>;
    }
    if (!Boolean(config)) {
        return (
            <div>
                Для данного сайта нет конфигурации сетки.
                Обратитесь к администратору
            </div>
        );
    }
    const maxArticlesInGrid =
        (config?.limit as number | undefined) || 30;
    return (
        <div>
            <MainGrid
                //@ts-ignore
                key={currenSite?.id}
                //@ts-ignore
                feature={config || {}}
                articlesApi={`${API_URL}/admin/articles/articles/main-grid/`}
                repostsApi={`${API_URL}/admin/website/reposts/main-grid/`}
                crudApi={`${API_URL}/admin/website/main-page/`}
                //@ts-ignore
                siteLink={currenSite?.url}
                maxArticlesInGrid={maxArticlesInGrid}
                //@ts-ignore
                token={accessToken}
                xSite={currenSite?.id}
                heightConfig={{
                    commonHeightDiff: 200,
                    articleHeightDiff: 300,
                }}
                //@ts-ignore
                notify={(notify: {
                    message:
                        | { [s: string]: unknown }
                        | ArrayLike<unknown>;
                    type: any;
                }) => {
                    if (notify) {
                        const message = Object.values(
                            notify.message,
                        ).flat();

                        message.map((msg) => {
                            enqueueSnackbar(msg as string, {
                                variant: notify.type,
                            });
                        });
                    }
                }}
            />
        </div>
    );
};

export { Grid };
