import {
    Button,
    InputSelectMulti,
    WithLabel,
} from '@mega/ui';
import React, { FC } from 'react';
import { IInitValuesForm } from './config';
import { getOptionsTags } from '@mega/core';
import { useSiteStore } from '@mega/store';
import { useFormikContext } from 'formik';
import { styles as s } from '../../Themes.css';

interface ThemesFormProps {
    onSubmit: (
        e?: React.FormEvent<HTMLFormElement> | undefined,
    ) => void;
}

export const ThemesForm: FC<ThemesFormProps> = ({
    onSubmit,
}) => {
    const currentSite = useSiteStore();
    const { setFieldValue, values } =
        useFormikContext<IInitValuesForm>();
    return (
        <>
            <div className={s.settingsContainer}>
                <WithLabel
                    title={'Темы'}
                    id={'tags'}
                    color={'white'}
                >
                    <InputSelectMulti
                        defaultValue={values?.tags}
                        key="tags"
                        classes={{
                            paper: { color: 'white' },
                            select: { variant: 'third' },
                        }}
                        actionOnSelectedOption={(
                            formValues,
                        ) => {
                            setFieldValue(
                                'tags',
                                formValues,
                            );
                        }}
                        handleLoader={getOptionsTags({
                            site: currentSite?.id || '',
                            is_theme: true,
                        })}
                    />
                </WithLabel>

                <Button
                    type={'button'}
                    label={'Сохранить'}
                    onClick={() => onSubmit()}
                    classes={{
                        paper: {
                            color: 'dark',
                            variant: 'filled',
                        },
                    }}
                />
            </div>
        </>
    );
};
