import React, { FC, ReactElement } from 'react';
import {
    listItemInfoRender,
    listItemInfoRenderTheme,
} from './ListItemInfo.css';
import { Indi } from '@mega/ui';

const ListItemInfoDataRender: FC<{
    label: string;
    themes: string[];
}> = ({ label, themes }) => {
    return (
        <div className={listItemInfoRender}>
            <span>{label}:</span>
            <ul className={listItemInfoRenderTheme}>
                {themes.map((theme, index) => {
                    return (
                        <li key={index}>
                            <Indi
                                label={theme}
                                colors={'deleted'}
                            />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export { ListItemInfoDataRender };
export default ListItemInfoDataRender;
