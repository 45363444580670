import React, { useEffect } from 'react';
import { TagEdit } from '@mega/forms';
import {
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';

import {
    useSiteStore,
    useTagDispatch,
    useTagStore,
} from '@mega/store';
import { useSnackbar } from 'notistack';
import { Tag } from '@mega/api';
import { FormikHelpers } from 'formik';

export const TagsEdit: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();

    const { id } = useParams();
    const { loading, store } = useTagStore();
    const { get, update } = useTagDispatch();
    const currentSite = useSiteStore();

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            get({ id: parseInt(id) });
        }
    }, [id, get]);

    if (loading.get.loading || !loading.get.success) {
        return <div>loading</div>;
    }

    let editedId: number;

    if (id) {
        editedId = parseInt(id);
    } else {
        throw new Error('The editable entity has no id.');
    }

    const handleUpdate = async (
        payload: Tag,
        actions: FormikHelpers<Tag>,
    ) => {
        const formData = new FormData();
        if (typeof payload.icon === 'object') {
            formData.append('icon', payload.icon);
        }

        await update({
            query: {
                id: editedId,
            },
            payload: {
                iconData: formData,
                formData: {
                    site: currentSite?.id || '',
                    title: payload.title,
                    slug: payload.slug,
                    is_active: payload.is_active,
                    long_title: payload.long_title,
                    meta_title: payload.meta_title,
                    meta_description:
                        payload.meta_description,
                    annotation: payload.annotation,
                    is_theme: payload.is_theme,
                    title_normal_form:
                        payload.title_normal_form,
                },
            },
            options: {
                notifier: {
                    enqueueSnackbar,
                },
                router: {
                    navigate,
                    location,
                },
            },
        });

        actions.setSubmitting(false);
    };

    return (
        <TagEdit
            handleUpdate={handleUpdate}
            initialValue={store}
        />
    );
};
