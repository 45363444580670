import React from 'react';
import { Modal } from '@mega/utils';
import { base, titleBlock } from './MediaPickerModal.css';
import { IconButton, Row, Typography } from '@mega/ui';
import { Close } from '@mega/icons';
import { Form, Formik } from 'formik';
import { Content, CustomInput } from './components';
import {
    FilesObject,
    FormValues,
    MediaFormat,
} from './types';

interface ArticleLinkModalProps {
    isOpen: boolean;
    onClose: () => void;
    type: MediaFormat;
    handleSubmit: (files: FilesObject) => void;
    isMulti?: boolean;
}

const defaultValues: FormValues = {
    search: '',
    type: 'image',
    fileIdList: [],
    places: [],
    events: [],
    people: [],
    blank: [],
    total: 0,
    isMulti: false,
};
export const MediaPickerModal: React.FC<
    ArticleLinkModalProps
> = (props) => {
    const { isOpen, onClose, handleSubmit, type, isMulti } =
        props;

    const isPhoto = type === 'image';

    return (
        <Modal onClose={onClose} open={isOpen}>
            {/*<Tabs />*/}
            <Formik
                initialValues={{
                    ...defaultValues,
                    type,
                    isMulti,
                }}
                onSubmit={(values) => {
                    onClose();
                    handleSubmit(values.fileIdList);
                }}
            >
                {() => {
                    return (
                        <Form>
                            <div className={base}>
                                <Row
                                    className={titleBlock}
                                    verticalAlign={'top'}
                                    gap={'16'}
                                >
                                    <Typography
                                        weight={'bold'}
                                        size={'24'}
                                    >
                                        Выберите файл с
                                        медиастока
                                    </Typography>
                                    <IconButton
                                        animate="primary"
                                        size="medium"
                                        onClick={onClose}
                                    >
                                        <Close />
                                    </IconButton>
                                </Row>
                                <CustomInput
                                    type="text"
                                    name={'search'}
                                    placeholderBase={`Поиск ${
                                        isPhoto
                                            ? 'фото'
                                            : 'видео'
                                    } по названию`}
                                />

                                <Content />
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );
};
