import React, { FC, ReactElement } from 'react';

import { PaperClasses, paperClasses } from '@mega/styles';

import { cardNavigationClasses } from './CardNavigation.css';

export interface CardNavigationProps {
    button?: ReactElement;
    label?: string;
    icon?: ReactElement;
    onClick?: () => void;
    classes?: {
        paper?: PaperClasses['recipe'];
    };
}

const CardNavigation: FC<CardNavigationProps> = ({
    button,
    label,
    icon,
    onClick,
    classes,
}) => {
    const hasButton = Boolean(button);
    const hasIcon = Boolean(icon);

    return (
        <div
            className={[
                cardNavigationClasses.wrapper,
                paperClasses.recipe({
                    borderRadius: 'small',
                    variant: 'filled',
                    color: 'gray',
                    withFocus: true,
                    ...(classes?.paper ?? {}),
                }),
            ].join(' ')}
            onClick={onClick}
        >
            <div className={cardNavigationClasses.layout}>
                {hasIcon && icon}
                <div
                    className={
                        cardNavigationClasses.footerCard
                    }
                >
                    {label}
                    {hasButton && button}
                </div>
            </div>
        </div>
    );
};

export { CardNavigation };
