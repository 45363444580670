import { ServerFile } from '../model/Uploader.store';
import { Tooltip } from '@mui/material';
import { IconButton, Typography } from '@mega/ui';
import { Delete, OpenInBrowser } from '@mui/icons-material';

type DocumentType = 'pdf' | 'excel' | 'word' | undefined;

function getDocumentType(filename: string): DocumentType {
    if (
        typeof filename !== 'string' ||
        filename.trim() === ''
    ) {
        return undefined;
    }

    // Используем регулярное выражение для извлечения расширения
    const match = filename.match(/\.([^.]+)$/);
    if (!match) {
        return undefined;
    }

    const extension = match[1]?.toLowerCase();
    return extension ? extensionMap[extension] : undefined;
}

const extensionMap: Record<string, DocumentType> = {
    pdf: 'pdf',
    xls: 'excel',
    xlsx: 'excel',
    doc: 'word',
    docx: 'word',
};

export function CardActions(props: {
    deleteFile: () => void;
    serverFile: ServerFile;
    order: number;
}) {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <Tooltip
                title={
                    getDocumentType(
                        props?.serverFile?.file || '',
                    ) === 'pdf'
                        ? 'Открыть в новой вкладке для предпросмотра'
                        : 'Скачать файл'
                }
            >
                <a
                    href={props.serverFile.file}
                    target="_blank"
                    style={{
                        maxWidth: 'min-content',
                    }}
                >
                    <IconButton>
                        <OpenInBrowser />
                    </IconButton>
                </a>
            </Tooltip>
            <Typography mod="capitalize" size="16">
                Файл №{props.order}
            </Typography>
            <Tooltip title={'Удалить'}>
                <IconButton
                    type={'button'}
                    onClick={props.deleteFile}
                >
                    <Delete />
                </IconButton>
            </Tooltip>
        </div>
    );
}
