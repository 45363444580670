import React, { FC, ReactNode } from 'react';

import { rowClasses, RowClasses } from '@mega/styles';

export interface RowProps {
    className?: string;
    children: ReactNode;
}

const Row: FC<RowProps & RowClasses['recipe']> = ({
    className,
    children,
    gap,
    verticalAlign,
    reverse,
    wrap = false,
}) => (
    <div
        className={[
            className,
            rowClasses.recipe({
                gap,
                verticalAlign,
                reverse,
                wrap,
            }),
        ].join(' ')}
    >
        {children}
    </div>
);

export { Row };
