import type { PluginTemplate } from '@apostroph/types';
import { definitions } from '@mega/api';

export enum GalleryEnum {
    type = 'GALLERY',
}

export interface GalleryProps {
    id: string;
    type: string;
    parentId: string | null;
}

export type GalleryMeta = {
    images: Array<definitions['AdminImage']>;
};

export type GalleryPlugin = PluginTemplate<
    GalleryEnum.type,
    GalleryMeta
>;
export type GalleryPluginDefault = Omit<
    GalleryPlugin,
    'id'
>;
