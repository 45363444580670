/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { FC, ReactElement, useMemo } from 'react';

import {
    Form,
    Formik,
    useFormikContext,
    FormikHelpers,
    Field,
} from 'formik';
import * as Yup from 'yup';

import type { Category } from '@mega/api';

import {
    Accordion,
    Button,
    CheckboxSwitchField,
    InputField,
    Paper,
    WithLabel,
    FormLayout,
    Stack,
} from '@mega/ui';
import { InputSelectMultiField } from '@mega/ui';
import { throttle } from 'lodash-es';
import { IconLoader } from '@mega/core';

interface HandleGet {
    (inputValue: string, page: number): Promise<
        {
            label: string;
            value: number;
        }[]
    >;
}
export interface CategoryEditProps {
    initialValue?: Category;
    handleUpdate?: (
        value: Category,
        actions: FormikHelpers<Category>,
    ) => void;
    apiConnector: {
        handleGetTagsOptions: HandleGet;
    };
}

const CategoryEditActions = () => {
    const { submitForm, isSubmitting } = useFormikContext();
    const throttledSubmit = useMemo(
        () => throttle(submitForm, 1000),
        [],
    );
    return (
        <>
            <Paper
                style={{
                    borderTop: '1px solid #EAEAEA',
                }}
                fullWidth
                color={'white'}
                variant="filled"
                classes={{
                    padding: { size: '24x24' },
                }}
            >
                <Button
                    size={'large'}
                    type={'submit'}
                    label={'Обновить'}
                    onClick={throttledSubmit}
                    classes={{
                        paper: {
                            color: 'dark',
                            variant: 'filled',
                        },
                    }}
                    disabled={isSubmitting}
                />
            </Paper>
        </>
    );
};

const CategoryEdit: FC<CategoryEditProps> = ({
    handleUpdate = () => null,
    initialValue = { title: '' },
    apiConnector: { handleGetTagsOptions },
}) => {
    return (
        <Formik
            validationSchema={Yup.object({
                title: Yup.string()
                    .min(
                        2,
                        'Это поле явно должно быть больше одного символа',
                    )
                    .required('Это поле обязательное'),
            })}
            initialValues={{
                ...initialValue,
            }}
            onSubmit={handleUpdate}
        >
            <Form
                style={{
                    minHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <FormLayout
                    actions={<CategoryEditActions />}
                    settings={
                        <Stack gap="16">
                            <Paper
                                contentHeight
                                color={'white'}
                                variant="outline"
                                classes={{
                                    padding: {
                                        size: '24x24',
                                    },
                                }}
                            >
                                <Stack gap="18">
                                    <Accordion
                                        isOpened
                                        label={'Настройки'}
                                        value="settings"
                                    >
                                        <Stack gap="16">
                                            <Stack gap="8">
                                                <CheckboxSwitchField
                                                    name="is_active"
                                                    label="Активно"
                                                />
                                            </Stack>
                                        </Stack>
                                    </Accordion>
                                </Stack>
                            </Paper>
                            <Paper
                                contentHeight
                                color={'white'}
                                variant="outline"
                                classes={{
                                    padding: {
                                        size: '24x24',
                                    },
                                }}
                            >
                                <WithLabel title={'Теги'}>
                                    <InputSelectMultiField
                                        name={'tags'}
                                        handleLoader={
                                            handleGetTagsOptions
                                        }
                                    />
                                </WithLabel>
                            </Paper>
                        </Stack>
                    }
                >
                    <Paper
                        variant="outline"
                        color="white"
                        classes={{
                            padding: {
                                size: '24x24',
                            },
                        }}
                    >
                        <Stack gap="18">
                            <InputField
                                type="text"
                                name={'title'}
                                placeholder="Название"
                                dimension="medium"
                                isRequired
                            />
                            <InputField
                                type="text"
                                name={'long_title'}
                                placeholder="Длинное название для заголовка страницы"
                                dimension="medium"
                            />
                            <InputField
                                type="text"
                                name={'meta_title'}
                                placeholder="meta заголовок"
                                dimension="medium"
                            />
                            <InputField
                                type="text"
                                name={'meta_description'}
                                placeholder="meta описание"
                                dimension="medium"
                            />
                            <InputField
                                type="text"
                                name={'slug'}
                                placeholder="Слаг"
                                dimension="medium"
                                disabled={
                                    !!initialValue.slug
                                }
                            />
                            <WithLabel title={'Иконка'}>
                                <Field
                                    name="icon"
                                    component={IconLoader}
                                />
                            </WithLabel>
                        </Stack>
                    </Paper>
                </FormLayout>
            </Form>
        </Formik>
    );
};

export { CategoryEdit };
