import * as CLIENT from '@mega/api';
import type {
    GetByIdADSHeadScript,
    GetByIdADSHeadScriptError,
    GetByIdADSHeadScriptQuery,
    CreateADSHeadScript,
    CreateADSHeadScriptError,
    CreateADSHeadScriptQuery,
    PartialUpdateADSHeadScript,
    PartialUpdateADSHeadScriptError,
    PartialUpdateADSHeadScriptQuery,
    DeleteADSHeadScript,
    DeleteADSHeadScriptError,
    DeleteADSHeadScriptQuery,
    IisOk,
    IisNotOk,
} from '@mega/api';

export type GetADSHeadScriptPromise = Promise<
    | IisOk<GetByIdADSHeadScript>
    | IisNotOk<GetByIdADSHeadScriptError | null>
>;

export const getADSHeadScriptById = async (
    query: GetByIdADSHeadScriptQuery,
    site: string,
): GetADSHeadScriptPromise => {
    try {
        const response =
            await CLIENT?.ads?.getAdsHeadScriptsById?.(
                {
                    ...query,
                },
                {
                    headers: {
                        'X-Site': site,
                    },
                },
            );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.streams.get &&
            e instanceof CLIENT.streams.get.Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};

export type CreateADSHeadScriptPromise = Promise<
    | IisOk<CreateADSHeadScript>
    | IisNotOk<CreateADSHeadScriptError | null>
>;

export const createADSHeadScript = async (
    query: CreateADSHeadScriptQuery,
    site: string,
): GetADSHeadScriptPromise => {
    try {
        const response =
            await CLIENT?.ads?.createAdsHeadScripts?.(
                {
                    ...query,
                },
                {
                    headers: {
                        'X-Site': site,
                    },
                },
            );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.ads.createAdsHeadScripts &&
            e instanceof
                CLIENT.ads.createAdsHeadScripts.Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};

export type PartialUpdateADSHeadScriptPromise = Promise<
    | IisOk<PartialUpdateADSHeadScript>
    | IisNotOk<PartialUpdateADSHeadScriptError | null>
>;

export const partialUpdateADSHeadScriptById = async (
    query: PartialUpdateADSHeadScriptQuery,
    site: string,
): PartialUpdateADSHeadScriptPromise => {
    try {
        const response =
            await CLIENT?.ads?.updatePartialAdsHeadScriptsById?.(
                {
                    ...query,
                },
                {
                    headers: {
                        'X-Site': site,
                    },
                },
            );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.ads.updatePartialAdsHeadScriptsById &&
            e instanceof
                CLIENT.ads.updatePartialAdsHeadScriptsById
                    .Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};

export type DeleteADSHeadScriptPromise = Promise<
    | IisOk<DeleteADSHeadScript>
    | IisNotOk<DeleteADSHeadScriptError | null>
>;

export const deleteADSHeadScriptById = async (
    query: DeleteADSHeadScriptQuery,
    site: string,
): DeleteADSHeadScriptPromise => {
    try {
        const response =
            await CLIENT?.ads?.deleteAdsHeadScriptsById?.(
                {
                    ...query,
                },
                {
                    headers: {
                        'X-Site': site,
                    },
                },
            );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.streams.get &&
            e instanceof CLIENT.streams.get.Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};
