import { PluginTemplate } from '@pages/smartGrid/types/blocks';
import { Control } from '@pages/smartGrid/types/core';
import { CoverType } from '@pages/smartGrid/types/const';

export enum StoriesEnum {
    type = 'CONTENT_STORIES',
}

export interface StoriesProps {
    id: string;
    meta: StoriesMeta;
    type: string;
    parentId: string | null;
}

export type StoriesMeta = {
    label: string;
    dataId: string;
};

export interface StoriesControl extends Control {
    meta: StoriesMeta;
}

export type StoriesPlugin = PluginTemplate<
    StoriesEnum.type,
    StoriesMeta
>;
export type StoriesPluginDefault = Omit<
    StoriesPlugin,
    'id'
>;
