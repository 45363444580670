import React, { useState } from 'react';
import { MediaCard } from '../../MediaCard';
import { styleMediaListingWrapper } from './MediaListing.css';
import { SliderModal } from '../../SliderModal';

export interface UnitInfoProps {
    files?: any[];
    audios?: any[];
    images?: any[];
    videos?: any[];
}

export const MediaListing: React.FC<UnitInfoProps> = (
    props,
) => {
    const {
        images = [],
        videos = [],
        files = [],
        audios = [],
    } = props;

    const [firstImage, ...restImages] = images.map(
        (item) => ({
            ...item,
            type: 'image',
        }),
    );
    const [firstVideo, ...restVideos] = videos.map(
        (item) => ({
            ...item,
            type: 'video',
        }),
    );

    const allFiles = [
        firstImage,
        firstVideo,
        ...restImages,
        ...restVideos,
        ...files.map((item) => ({ ...item, type: 'file' })),
        ...audios.map((item) => ({
            ...item,
            type: 'audio',
        })),
    ].filter(Boolean);

    const firstViewCount =
        +!!images?.length + +!!videos?.length;
    const noViewFilesLength = firstViewCount
        ? allFiles.length - firstViewCount
        : 0;

    const [activeFileIndex, setActiveFileIndex] = useState<
        number | null
    >(null);
    return (
        <>
            <div className={styleMediaListingWrapper}>
                {!!images?.length && (
                    <MediaCard
                        file={firstImage}
                        onClick={() =>
                            setActiveFileIndex(0)
                        }
                    />
                )}
                {!!videos?.length && (
                    <MediaCard
                        file={firstVideo}
                        onClick={() =>
                            setActiveFileIndex(1)
                        }
                    />
                )}
                {!!noViewFilesLength && (
                    <MediaCard
                        files={allFiles}
                        hiddenFilesCount={noViewFilesLength}
                        text={'Скачать все'}
                        onClick={() =>
                            setActiveFileIndex(
                                firstViewCount,
                            )
                        }
                    />
                )}
            </div>
            <SliderModal
                files={allFiles}
                isOpen={activeFileIndex !== null}
                initialSlide={
                    activeFileIndex !== null
                        ? activeFileIndex
                        : 2
                }
                onClose={() => setActiveFileIndex(null)}
            />
        </>
    );
};
