import React, { Component, PropsWithChildren } from 'react';
import Select, {
    components,
    SingleValueProps,
} from 'react-select';

interface SingleValueClass {
    className?: string;
}

type SingleValueOverideProps = SingleValueProps<
    Record<string, unknown>,
    true
>;

const SingleValue =
    ({ className = '' }: SingleValueClass) =>
    ({
        children,
        ...props
    }: PropsWithChildren<SingleValueOverideProps>) =>
        (
            <components.SingleValue
                className={className}
                {...props}
            >
                {children}
            </components.SingleValue>
        );

export { SingleValue };
export default SingleValue;
