import * as CLIENT from '@mega/api';
import {
    EmployeeGetByIdReturn,
    EmployeeGetByIdError,
    EmployeeGetByIdQuery,
    EmployeeCreateReturn,
    EmployeeCreateError,
    EmployeeCreateQuery,
    EmployeePartialUpdateReturn,
    EmployeePartialUpdateError,
    EmployeePartialUpdateQuery,
    EmployeeChangePasswordReturn,
    EmployeeChangePasswordError,
    EmployeeChangePasswordQuery,
    IisOk,
    IisNotOk,
} from '@mega/api';

export type EmployeeGetByIdPromise = Promise<
    | IisOk<EmployeeGetByIdReturn>
    | IisNotOk<EmployeeGetByIdError | null>
>;

export const employeeGetById = async (
    query: EmployeeGetByIdQuery,
    site: string,
): EmployeeGetByIdPromise => {
    try {
        const response = await CLIENT.user.getUsersById?.(
            { ...query },
            {
                headers: {
                    'X-Site': site,
                },
            },
        );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.user.getUsersById &&
            e instanceof CLIENT.user.getUsersById?.Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};

export type EmployeeCreatePromise = Promise<
    | IisOk<EmployeeCreateReturn>
    | IisNotOk<EmployeeCreateError | null>
>;

export const employeeCreate = async (
    query: EmployeeCreateQuery,
    site: string,
): EmployeeCreatePromise => {
    try {
        const response = await CLIENT?.user.createUser?.(
            { ...query },
            {
                headers: {
                    'X-Site': site,
                },
            },
        );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.user.createUser &&
            e instanceof CLIENT.user.createUser.Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};

export type EmployeePartialUpdatePromise = Promise<
    | IisOk<EmployeePartialUpdateReturn>
    | IisNotOk<EmployeePartialUpdateError | null>
>;

export const employeePartialUpdateById = async (
    query: EmployeePartialUpdateQuery,
    site: string,
): EmployeePartialUpdatePromise => {
    try {
        const response =
            await CLIENT?.user.updatePartialUsersById?.(
                {
                    ...query,
                },
                {
                    headers: {
                        'X-Site': site,
                    },
                },
            );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.user.updatePartialUsersById &&
            e instanceof
                CLIENT.user.updatePartialUsersById.Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};

export type EmployeeResetPasswordPromise = Promise<
    | IisOk<EmployeeChangePasswordReturn>
    | IisNotOk<EmployeeChangePasswordError | null>
>;

export const resetPassword = async (
    query: EmployeeChangePasswordQuery,
    site: string,
): EmployeeResetPasswordPromise => {
    try {
        const response = CLIENT?.user.resetPassword?.(
            {
                ...query,
            },
            {
                headers: {
                    'X-Site': site,
                },
            },
        );
        if ((await response)?.ok) {
            return {
                isOk: true,
                value: (await response)?.data || {},
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.user.resetPassword &&
            e instanceof CLIENT.user.resetPassword.Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};
