import React, { useEffect, useRef, useState } from 'react';
import {
    Button,
    Loader,
    Typography,
    Checkbox,
} from '@mega/ui';
import {
    base,
    cropContainer,
    cropWrapper,
    imgPreview,
    innerContainer,
    loader,
    modalTitle,
    pointContainer,
    previewContainer,
    previewImg,
    previewResolution,
    previewWrapper,
    reactCrop,
    reactCropImg,
} from './CropModal.css';
import * as CLIENT from '@mega/api';
import { definitions } from '@mega/api';
import useResizeObserver from 'use-resize-observer';
import {
    getPointPosition,
    getPositionFromFocus,
    setCropPosition,
} from './helpers/helper';

const { getById, getPreviewById, updatePartialById } =
    CLIENT.images;

interface CropModalProps {
    setOpen: (state: boolean) => void;
    id: number;
    onUpdate: (
        res: Partial<definitions['AdminImage']>,
        id?: number,
        cover?: boolean,
    ) => void;
    onUpdateIsWaterMarked: (id: number) => void;
    isLoading?: boolean;
    is_watermarked?: boolean;
}

export const CropModal: React.FC<CropModalProps> = (
    props,
) => {
    const {
        setOpen,
        id,
        onUpdate,
        onUpdateIsWaterMarked,
        isLoading,
        is_watermarked,
    } = props;
    const [currentImage, setCurrentImage] =
        useState<any>(null);
    const [currentPreview, setCurrentPreview] =
        useState<Record<string, string> | null>(null);

    const [cropFloat, setCropFloat] = useState({
        fp_x: 0.5,
        fp_y: 0.5,
    });
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const pointRef = useRef<HTMLDivElement | null>(null);
    const { ref, width = 1 } =
        useResizeObserver<HTMLDivElement>();

    const handleCroppedImg = async ({
        fp_x,
        fp_y,
    }: {
        fp_x: number;
        fp_y: number;
    }) => {
        onUpdate(
            {
                id,
                fp_x,
                fp_y,
            },
            id,
            true,
        );
    };

    const fetchImage = async () => {
        const { data } = (await getById?.({
            id,
        })) as any;
        setCurrentImage(data);
        setCropFloat({ fp_x: data.fp_x, fp_y: data.fp_y });
    };
    const fetchPreviewImage = async (
        fp_x: number,
        fp_y: number,
    ) => {
        const { data } = (await getPreviewById?.({
            id,
            fp_x,
            fp_y,
        })) as any;
        setCurrentPreview(data);
    };

    const updateIsWaterMarked = async () => {
        onUpdateIsWaterMarked(id);
    };

    useEffect(() => {
        fetchImage();
    }, [id]);

    useEffect(() => {
        if (currentImage && !isLoading) {
            setOpen(false);
        }
    }, [isLoading]);

    useEffect(() => {
        if (currentImage?.image && wrapperRef?.current) {
            const { fp_x, fp_y } = currentImage;

            const position = getPositionFromFocus({
                fp_x,
                fp_y,
                ref: wrapperRef,
            });
            if (position) {
                const { x, y } = position;
                setCropPosition(pointRef, x, y);
            }
        }
    }, [width]);

    const handleChangePoint = (
        e: React.MouseEvent<HTMLDivElement>,
    ) => {
        const coords = getPointPosition({
            event: e,
            ref: wrapperRef,
        });
        if (coords && pointRef?.current) {
            const { x, y, fp_x, fp_y } = coords;
            setCropPosition(pointRef, x, y);
            setCropFloat({ fp_x, fp_y });
        }
    };

    useEffect(() => {
        const { fp_x, fp_y } = cropFloat;
        fetchPreviewImage(fp_x, fp_y);
    }, [cropFloat]);

    return (
        <div className={base}>
            <Typography
                className={modalTitle}
                weight={'bold'}
                size={'24'}
            >
                Редактирование обложки
            </Typography>
            <div className={cropContainer}>
                {currentImage?.image ? (
                    <div className={innerContainer}>
                        <div className={imgPreview}>
                            <div
                                className={cropWrapper}
                                onClick={handleChangePoint}
                                ref={ref}
                            >
                                <div
                                    className={reactCrop}
                                    ref={wrapperRef}
                                >
                                    <img
                                        className={
                                            reactCropImg
                                        }
                                        crossOrigin="anonymous"
                                        alt="pic"
                                        src={
                                            currentImage.image
                                        }
                                    />
                                    <div
                                        className={
                                            pointContainer
                                        }
                                        ref={pointRef}
                                    />
                                </div>

                                {isLoading && (
                                    <div className={loader}>
                                        <Loader size="medium" />
                                    </div>
                                )}
                            </div>
                            <div
                                className={previewContainer}
                            >
                                {currentPreview &&
                                    Object.entries(
                                        currentPreview,
                                    ).map((item) => {
                                        const [key, value] =
                                            item;
                                        return (
                                            <div
                                                key={key}
                                                className={
                                                    previewWrapper
                                                }
                                            >
                                                <Typography
                                                    className={
                                                        previewResolution
                                                    }
                                                    weight={
                                                        'bold'
                                                    }
                                                    size={
                                                        '16'
                                                    }
                                                >
                                                    {key}
                                                </Typography>
                                                <img
                                                    className={
                                                        previewImg
                                                    }
                                                    src={
                                                        value
                                                    }
                                                    alt=""
                                                />
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>

                        <Button
                            disabled={isLoading}
                            label={'Сохранить'}
                            classes={{
                                paper: {
                                    color: 'dark',
                                    variant: 'filled',
                                },
                            }}
                            size={'medium'}
                            onClick={() =>
                                handleCroppedImg(cropFloat)
                            }
                        />

                        <Checkbox
                            labelProps={{
                                size: '12',
                                weight: 'semiMeduim',
                            }}
                            iconSize="12"
                            theme="third"
                            name={String(id)}
                            checked={
                                is_watermarked
                                    ? is_watermarked
                                    : false
                            }
                            label="Наложить вотермарку"
                            value="is_watermarked_crop"
                            onChange={updateIsWaterMarked}
                        />
                    </div>
                ) : (
                    <Loader size="small" />
                )}
            </div>
        </div>
    );
};
