import * as CLIENT from '@mega/api';
import type { operations } from '@mega/api';
import { createModel } from '@rematch/core';
import type { RootModel } from '../rootModel';

type PermissionConfig =
    operations['admin_admin_menus-conf_list']['responses']['200']['schema'];

export interface PermissionConfRead {
    [key: string]: {
        name?: string | undefined;
        verbose_name?: string | undefined;
        verbose_name_plural?: string | undefined;
        in_dashboard?: boolean | undefined;
    };
}

const settings = createModel<RootModel>()({
    name: 'settings',
    state: {
        models: [],
        navigation: [],
        config: {
            article: {
                change: {
                    editor_data: {
                        MILK: true,
                        BLOCKQUOTE: true,
                        VIDEO: true,
                        IMG: true,
                        GALLERY: true,
                        HTML_TEXT: true,
                        HORIZONTAL_LINE: true,
                        ADS_SOURCE: true,
                        SECTION: true,
                        IN_PROGRESS: true,
                    },
                    cover: true,
                    main_video: true,
                    is_important: true,
                    is_exclusive: true,
                    is_muted: true,
                    telegram_post: true,
                },
            },
        },
    } as PermissionConfig,
    reducers: {
        putPermissonConfig(
            state,
            payload: PermissionConfig,
        ) {
            return { ...payload };
        },
    },
    selectors: (slice) => ({
        getPermissonConfig() {
            return slice((store) =>
                store.models.reduce<PermissionConfRead>(
                    (acc, item) => {
                        return {
                            ...acc,
                            [`${item?.name || '???'}`]:
                                item,
                        };
                    },
                    {},
                ),
            );
        },
    }),
    effects: (dispatch) => ({
        async getNavigationConfig(_: {}, store) {
            try {
                const response =
                    await CLIENT.permissionConf.getNav?.(
                        {},
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );

                if (response?.ok) {
                    dispatch.settings.putPermissonConfig(
                        response.data,
                    );
                }
            } catch (e) {
                if (
                    CLIENT.permissionConf.getNav &&
                    e instanceof
                        CLIENT.permissionConf.getNav.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },
    }),
});

export { settings };
export default settings;
