import {
    createContext,
    useContext,
    Component,
    memo,
} from 'react';
import { useStore } from 'zustand';
import {
    type UploaderProps,
    type UploaderState,
    type UploaderStore,
    createUploaderStore,
} from './Uploader.store';

type UploaderProviderProps =
    React.PropsWithChildren<UploaderProps>;

export const UploaderContext =
    createContext<UploaderStore | null>(null);

class UploaderProvider extends Component<UploaderProviderProps> {
    private store: UploaderStore;

    constructor(props: UploaderProviderProps) {
        super(props);
        this.store = createUploaderStore(props);
    }

    componentDidMount(): void {
        console.log(this.store.getState());
    }

    componentDidUpdate(): void {
        this.store.setState((state) => {
            const newState = {
                ...state,
                externalState: {
                    formik: {
                        field: this.props.externalState
                            ?.formik?.field,
                    },
                },
            };
            return newState;
        });
    }

    render() {
        return (
            <UploaderContext.Provider value={this.store}>
                {this.props.children}
            </UploaderContext.Provider>
        );
    }
}

export function useUploaderContext<T>(
    selector: (state: UploaderState) => T,
): T {
    const store = useContext(UploaderContext);
    if (!store)
        throw new Error(
            'Missing BearContext.Provider in the tree',
        );
    return useStore(store, selector);
}

const customComparator = (
    prevProps: UploaderProviderProps,
    nextProps: UploaderProviderProps,
) => {
    return (
        prevProps.externalState?.formik?.field?.value ===
        nextProps.externalState?.formik?.field?.value
    );
};

const UploaderProviderMemo = memo(
    UploaderProvider,
    customComparator,
);

export { UploaderProviderMemo as UploaderProvider };
