import React, { FC, CSSProperties, useState } from 'react';

import {
    YandexRssOff,
    YandexRssOn,
    GoogleRssOff,
    GoogleRssOn,
    DzenRssOff,
    DzenRssOn,
    Close,
    Image,
    Videocam,
    PubOn,
    PubOff,
    ArrowDown,
    LockEdition,
} from '@mega/icons';
import { useSnackbar } from 'notistack';
import { AcceptModal } from '@mega/core';
import Reply from '@mui/icons-material/Reply';
import {
    Bread,
    Crumbs,
    Icon,
    IconButton,
    Indi,
    Button,
} from '@mega/ui';
import { format } from 'date-fns';
import {
    useArticlesRoom,
    useArticleDispatch,
    useRepostDispatch,
} from '@mega/store';
import { useLocation, Link } from 'react-router-dom';

import {
    classesColumnsListing as classes,
    styledTitleWrapper,
} from './ColumnsListing.css';

const TitleColumn: FC<{
    title?: string;
    id?: number;
    style?: CSSProperties;
}> = ({ title, id, style }) => {
    const location = useLocation();
    const { pagesWithEditor } = useArticlesRoom();
    const editor = id ? pagesWithEditor?.[id] : undefined;

    return (
        <Link
            style={{
                cursor: 'pointer',
                color: 'inherit',
                textDecoration: 'none',
                ...(style ? style : {}),
            }}
            target="_blank"
            to={`change/${id}`}
            state={{ state: location }}
        >
            <div className={styledTitleWrapper}>
                <span>
                    {title === ''
                        ? 'Без названия'
                        : title
                              ?.replace(/\s/g, ' ')
                              .replaceAll('&nbsp;', ' ')}
                </span>
                {editor && (
                    <div>
                        <LockEdition />
                    </div>
                )}
            </div>
        </Link>
    );
};

const NameColumn: FC<{
    firstName: string;
    lastName: string;
    patronymic: string | null;
    id: number;
    style?: CSSProperties;
}> = ({ firstName, lastName, patronymic, id, style }) => {
    const location = useLocation();

    return (
        <Link
            style={{
                cursor: 'pointer',
                color: 'inherit',
                textDecoration: 'none',
                ...(style ? style : {}),
            }}
            target="_blank"
            to={`change/${id}`}
            state={{ state: location }}
        >
            <div
                className={styledTitleWrapper}
                style={{ justifyContent: 'flex-start' }}
            >
                <span>
                    {`${firstName} ${lastName} ${
                        patronymic ? patronymic : ''
                    }`}
                </span>
            </div>
        </Link>
    );
};

const IdColumn: FC<{
    id: number;
    style?: CSSProperties;
}> = ({ id, style }) => {
    const location = useLocation();

    return (
        <Link
            style={{
                cursor: 'pointer',
                color: 'inherit',
                textDecoration: 'none',
                display: 'flex',
                padding: '4px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '4px',
                ...(style ? style : {}),
            }}
            target="_blank"
            to={`change/${id}`}
            state={{ state: location }}
        >
            <div className={styledTitleWrapper}>
                <span>{id}</span>
            </div>
        </Link>
    );
};

const EmailColumn: FC<{
    email: string;
    id: number;
    style?: CSSProperties;
}> = ({ id, email, style }) => {
    const location = useLocation();

    return (
        <Link
            style={{
                cursor: 'pointer',
                color: 'inherit',
                textDecoration: 'none',
                ...(style ? style : {}),
            }}
            target="_blank"
            to={`change/${id}`}
            state={{ state: location }}
        >
            <div className={styledTitleWrapper}>
                <span>{email}</span>
            </div>
        </Link>
    );
};

const LoginColumn: FC<{
    username: string;
    id: number;
    style?: CSSProperties;
}> = ({ id, username, style }) => {
    const location = useLocation();

    return (
        <Link
            style={{
                cursor: 'pointer',
                color: 'inherit',
                textDecoration: 'none',
                ...(style ? style : {}),
            }}
            target="_blank"
            to={`change/${id}`}
            state={{ state: location }}
        >
            <div className={styledTitleWrapper}>
                <span>{username}</span>
            </div>
        </Link>
    );
};

const SitesColumn: FC<{
    sites: { id: number; label: string }[];
    id: number;
}> = ({ sites, id }): JSX.Element => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Link
            target="_blank"
            to={`change/${id}`}
            state={{ state: location }}
            className={classes.sitesColumns}
            style={{ justifyContent: 'center' }}
        >
            {sites
                .slice(0, isOpen ? sites.length : 4)
                .map((el) => (
                    <div
                        className={
                            classes.sitesColumnsElement
                        }
                        key={el.id}
                    >
                        {el.label}
                    </div>
                ))}
            {sites.length > 4 ? (
                <Button
                    style={{
                        backgroundColor: '#878787',
                    }}
                    label={
                        isOpen
                            ? 'Свернуть'
                            : `Ещё ${sites.length - 4}`
                    }
                    onClick={(e) => {
                        e.preventDefault();
                        setIsOpen(
                            (prevState) => !prevState,
                        );
                    }}
                    endIcon={
                        isOpen ? (
                            <ArrowDown />
                        ) : (
                            <ArrowDown />
                        )
                    }
                ></Button>
            ) : null}
        </Link>
    );
};

export const TitleRepostColumn: FC<{
    title?: string;
    id?: number;
}> = ({ title, id }) => {
    const location = useLocation();

    const transformText = title?.replace(
        /\r\n|\r|\n/g,
        '</br>',
    );

    return (
        <Link
            style={{
                cursor: 'pointer',
                color: 'inherit',
                textDecoration: 'none',
            }}
            target="_blank"
            to={`change/${id}`}
            state={{ state: location }}
        >
            {transformText ? (
                <div
                    dangerouslySetInnerHTML={{
                        __html: transformText,
                    }}
                />
            ) : (
                'Без текста'
            )}
        </Link>
    );
};

const PublicationColumn: FC<{
    data: string;
    pubOn?: boolean;
    onlyIcon?: boolean;
}> = ({ data, pubOn, onlyIcon = false }) => {
    function transformData(data?: string) {
        const dateArticle = data ? new Date(data) : null;
        const showDate = dateArticle
            ? `${format(
                  dateArticle,
                  'dd.MM.yyyy',
              )} в ${format(dateArticle, 'HH:mm')}`
            : null;

        return showDate;
    }

    const getData = transformData(data);

    return (
        <div
            className={classes.publication({
                data: onlyIcon,
            })}
        >
            <div className={classes.iconStatus}>
                <Icon size="25">
                    {pubOn ? <PubOn /> : <PubOff />}
                </Icon>
            </div>
            <Bread>
                {getData ? (
                    <Crumbs>
                        <span>{getData}</span>
                    </Crumbs>
                ) : null}
            </Bread>
        </div>
    );
};

const CreatedAtColumn: FC<{
    data: string;
    pubOn?: boolean;
    onlyIcon?: boolean;
}> = ({ data, onlyIcon = false }) => {
    function transformData(data?: string) {
        const dateArticle = data ? new Date(data) : null;
        const showDate = dateArticle
            ? `${format(
                  dateArticle,
                  'dd.MM.yyyy',
              )} в ${format(dateArticle, 'HH:mm')}`
            : null;

        return showDate;
    }

    const getData = transformData(data);

    return (
        <div
            className={classes.publication({
                data: true,
            })}
        >
            <Bread>
                {getData ? (
                    <Crumbs>
                        <span>{getData}</span>
                    </Crumbs>
                ) : null}
            </Bread>
        </div>
    );
};

const CoverColumn: FC<{
    video?: string;
    cover?: string;
}> = ({ cover, video }) => {
    const hasVideo = Boolean(video);
    const hasFrame = Boolean(cover);

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <div
                style={
                    hasFrame
                        ? { opacity: 1 }
                        : { opacity: '0.2' }
                }
            >
                <Icon size="25">
                    <Image />
                </Icon>
            </div>
            <div
                style={
                    hasVideo
                        ? { opacity: 1 }
                        : { opacity: '0.2' }
                }
            >
                <Icon size="25">
                    <Videocam />
                </Icon>
            </div>
        </div>
    );
};

const RssColumn: FC<{
    rss?: { id?: number; label?: string }[];
}> = ({ rss }) => {
    const hasYandex = rss?.filter(
        (item: { id?: number; label?: string }) =>
            item.label == 'main',
    )?.[0];
    const hasGoogle = rss?.filter(
        (item: { id?: number; label?: string }) =>
            item.label == 'google_news',
    )?.[0];
    const hasDzen = rss?.filter(
        (item: { id?: number; label?: string }) =>
            item.label == 'dzen',
    )?.[0];
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            {hasYandex ? <YandexRssOn /> : <YandexRssOff />}
            {hasGoogle ? <GoogleRssOn /> : <GoogleRssOff />}
            {hasDzen ? <DzenRssOn /> : <DzenRssOff />}
        </div>
    );
};

const TagIsThemeColumn: FC<{
    is_theme: boolean;
}> = ({ is_theme }) => {
    return (
        <div
            style={{
                textAlign: 'center',
            }}
        >
            {is_theme ? 'Да' : 'Нет'}
        </div>
    );
};

const ActionColumn: FC<{
    hasDeleteArticlePermission: boolean;
    isActive: boolean;
    url: string;
    id: number;
}> = ({
    isActive,
    url,
    id,
    hasDeleteArticlePermission,
}) => {
    const { removeByIdWithRoomCheck } =
        useArticleDispatch();
    const { enqueueSnackbar } = useSnackbar();

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <IconButton
                disabled={!isActive}
                onClick={() => {
                    if (url && isActive) {
                        window.open(url, '_blank');
                    }
                }}
                classes={{
                    paper: {
                        color: 'white',
                    },
                }}
                style={{ backgroundColor: 'inherit' }}
            >
                <Icon>
                    <Reply
                        style={{
                            transform: 'scale(-1,1)',
                            color: '#F2C94CCC',
                        }}
                    />
                </Icon>
            </IconButton>
            {hasDeleteArticlePermission && (
                <AcceptModal
                    msg="Вы уверенны что хотите удалить новость ?"
                    Trigger={
                        <IconButton
                            animate="none"
                            onClick={() => {
                                if (id && !isActive) {
                                    removeByIdWithRoomCheck(
                                        id,
                                    );
                                } else {
                                    return enqueueSnackbar(
                                        'Опубликованную статью удалить нельзя',
                                        {
                                            variant:
                                                'error',
                                        },
                                    );
                                }
                            }}
                            classes={{
                                paper: {
                                    color: 'white',
                                },
                            }}
                            style={{
                                backgroundColor: 'inherit',
                            }}
                        >
                            <Icon size="25">
                                <Close
                                    style={{
                                        color: '#EB5757CC',
                                    }}
                                />
                            </Icon>
                        </IconButton>
                    }
                />
            )}
        </div>
    );
};

export const ActionRepostColumn: FC<{
    isActive: boolean;
    url: string;
    id: number;
}> = ({ isActive, url, id }) => {
    const { remove } = useRepostDispatch();

    const { enqueueSnackbar } = useSnackbar();

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <IconButton
                // disabled={!isActive}
                onClick={() => {
                    if (url) {
                        window.open(url, '_blank');
                    }
                }}
                classes={{
                    paper: {
                        color: 'white',
                    },
                }}
            >
                <Icon>
                    <Reply
                        style={{
                            transform: 'scale(-1,1)',
                            color: '#F2C94CCC',
                        }}
                    />
                </Icon>
            </IconButton>
            <AcceptModal
                msg="Вы уверенны что хотите удалить репост ?"
                Trigger={
                    <IconButton
                        animate="none"
                        onClick={() => {
                            if (id && !isActive) {
                                remove(id);
                            } else {
                                return enqueueSnackbar(
                                    'Активный репост удалить нельзя',
                                    {
                                        variant: 'error',
                                    },
                                );
                            }
                        }}
                        classes={{
                            paper: {
                                color: 'white',
                            },
                        }}
                    >
                        <Icon size="25">
                            <Close
                                style={{
                                    color: '#EB5757CC',
                                }}
                            />
                        </Icon>
                    </IconButton>
                }
            />
        </div>
    );
};

const AuthorsColumn: FC<{
    authors?: { id?: number; label?: string }[];
}> = ({ authors }) => {
    return (
        <ul
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                padding: 0,
                margin: 0,
                listStyleType: 'none',
            }}
        >
            {authors?.map(
                (
                    item: { id?: number; label?: string },
                    i: any,
                ) => {
                    const arrayName =
                        item?.label?.split(' ');

                    return (
                        <li
                            style={{
                                display: 'flex',
                                gap: '2px',
                                flexWrap: 'wrap',
                            }}
                            title={item?.label}
                            key={i}
                        >
                            {arrayName?.map((item) => (
                                <span
                                    key={item}
                                    className={
                                        classes.author
                                    }
                                >
                                    {item}
                                </span>
                            ))}
                        </li>
                    );
                },
            )}
        </ul>
    );
};

const OwnerColumn: FC<{
    owner?: { id?: number; label?: string };
}> = ({ owner }) => {
    return (
        <ul
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                padding: 0,
                margin: 0,
                listStyleType: 'none',
            }}
        >
            <li
                style={{
                    display: 'flex',
                    gap: '2px',
                    flexWrap: 'wrap',
                }}
            >
                {owner?.label}
            </li>
        </ul>
    );
};

const WipColumn: FC<{ id: number }> = ({ id }) => {
    const { pagesWithEditor } = useArticlesRoom();
    const editor = pagesWithEditor?.[id];

    return (
        <>
            {editor && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Indi
                        widthMin={
                            editor?.name?.length !==
                                undefined &&
                            editor.name?.length > 9
                        }
                        wrap
                        colors="exclusive"
                        label={editor.name}
                    />
                </div>
            )}
        </>
    );
};

export {
    RssColumn,
    PublicationColumn,
    TagIsThemeColumn,
    CreatedAtColumn,
    CoverColumn,
    AuthorsColumn,
    OwnerColumn,
    WipColumn,
    TitleColumn,
    NameColumn,
    ActionColumn,
    IdColumn,
    EmailColumn,
    LoginColumn,
    SitesColumn,
};
