import { ReactElement } from 'react';
import { PluginTemplate } from '@pages/smartGrid/types/blocks';

export enum SecondEnum {
    type = 'SECOND_TEMPLATE',
}

export interface SecondProps {
    id: string;
    type: string;
    meta: any;
    parentId: string | null;
    children: ReactElement | ReactElement[];
}

export type SecondMeta = {};

export type SecondPlugin = PluginTemplate<
    SecondEnum.type,
    SecondMeta
>;
export type SecondPluginDefault = Omit<SecondPlugin, 'id'>;
