import { RootState } from '../../models';
import { createSelector } from 'reselect';

const loadingAuth = (state: RootState) =>
    state.loading.effects.auth;

export const selectAuthLoading = createSelector(
    loadingAuth,
    (loading) => loading,
);
