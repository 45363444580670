import React from 'react';
import Select, {
    components,
    PlaceholderProps,
} from 'react-select';

interface WithClassNamePlaceholder {
    className?: string;
}

const Placeholder =
    ({ className = '' }: WithClassNamePlaceholder) =>
    (props: PlaceholderProps<{}>) =>
        (
            <components.Placeholder
                className={className}
                {...props}
            />
        );

export { Placeholder };
export default Placeholder;
