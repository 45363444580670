/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { FC, useMemo } from 'react';

import { Form, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { throttle } from 'lodash-es';
import dayjs from 'dayjs';

import type { Repost } from '@mega/api';

import {
    Button,
    CheckboxSwitchField,
    FormLayout,
    InputField,
    Paper,
    Stack,
    WithLabel,
    Row,
} from '@mega/ui';

import { classes } from './RepostEdit.css';

export interface RepostEditProps {
    initialValue?: Repost;
    handleUpdate?: (value: Repost) => void;
}

const RepostEditActions = () => {
    const { submitForm, isSubmitting, values } =
        useFormikContext<Repost>();
    const throttledSubmit = useMemo(
        () => throttle(submitForm, 1000),
        [],
    );

    return (
        <>
            <Paper
                style={{
                    borderTop: '1px solid #EAEAEA',
                }}
                fullWidth
                color={'white'}
                variant="filled"
                classes={{
                    padding: { size: '24x24' },
                }}
            >
                <Row gap="16">
                    <Button
                        size={'large'}
                        type={'submit'}
                        label={'Обновить'}
                        onClick={throttledSubmit}
                        classes={{
                            paper: {
                                color: 'dark',
                                variant: 'filled',
                            },
                        }}
                        disabled={isSubmitting}
                    />
                    <Button
                        animate="secondary"
                        size={'large'}
                        type={'submit'}
                        label={'Открыть оригинал'}
                        onClick={() =>
                            window.open(
                                values.link,
                                '_blank',
                            )
                        }
                        classes={{
                            paper: {
                                color: 'dark',
                                variant: 'outline',
                            },
                        }}
                    />
                </Row>
            </Paper>
        </>
    );
};

const RepostEdit: FC<RepostEditProps> = ({
    handleUpdate = () => null,
    initialValue = { link: '' },
}) => {
    const timeTemplate = 'DD.MM.YYYY в HH:mm';

    const transformText = initialValue.text?.replace(
        /\r\n|\r|\n/g,
        '</br>',
    );

    return (
        <Formik
            validationSchema={Yup.object({
                link: Yup.string()
                    .min(
                        2,
                        'Это поле явно должно быть больше одного символа',
                    )
                    .required('Это поле обязательное'),
            })}
            initialValues={{
                ...initialValue,
            }}
            onSubmit={handleUpdate}
        >
            <Form
                style={{
                    minHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <FormLayout
                    actions={<RepostEditActions />}
                    settings={
                        <Stack gap="16">
                            <div
                                className={
                                    classes.timePublication
                                }
                            >
                                <span>Опубликован:</span>
                                <div>
                                    {dayjs(
                                        initialValue.pub_date,
                                    ).format(timeTemplate)}
                                </div>
                            </div>
                            <Paper
                                contentHeight
                                color={'white'}
                                variant="outline"
                                classes={{
                                    padding: {
                                        size: '24x24',
                                    },
                                }}
                            >
                                <Stack gap="18">
                                    <CheckboxSwitchField
                                        name="is_active"
                                        label="Активность"
                                    />
                                    <CheckboxSwitchField
                                        name="is_important"
                                        label="Важно"
                                    />
                                </Stack>
                            </Paper>
                        </Stack>
                    }
                >
                    <Paper
                        variant="outline"
                        color="white"
                        classes={{
                            padding: {
                                size: '24x24',
                            },
                        }}
                    >
                        <Stack gap="18">
                            <InputField
                                type="text"
                                name={'link_redirect'}
                                placeholder="Ссылка при переходе"
                                dimension="medium"
                            />
                            <InputField
                                type="text"
                                name={'link'}
                                placeholder="Ссылка"
                                dimension="medium"
                                disabled
                            />

                            <InputField
                                type="text"
                                name={'author'}
                                placeholder="Источник"
                                dimension="medium"
                                disabled
                            />
                            <InputField
                                type="text"
                                name={'author_link'}
                                placeholder="Ссылка источника"
                                dimension="medium"
                                disabled
                            />

                            {transformText ? (
                                <WithLabel title={'Текст'}>
                                    <div
                                        className={
                                            classes.text
                                        }
                                        dangerouslySetInnerHTML={{
                                            __html: transformText,
                                        }}
                                    />
                                </WithLabel>
                            ) : null}

                            {initialValue.cover ? (
                                <WithLabel
                                    title={'Обложка'}
                                    id="cover"
                                >
                                    <div
                                        className={
                                            classes.cover
                                        }
                                    >
                                        <img
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                            }}
                                            src={
                                                initialValue.cover
                                            }
                                            alt="cover"
                                        />
                                    </div>
                                </WithLabel>
                            ) : null}
                        </Stack>
                    </Paper>
                </FormLayout>
            </Form>
        </Formik>
    );
};

export { RepostEdit };
