import React, { FC, useEffect, useState } from 'react';
import {
    HeadTemplatePlugin,
    TemplatePlugin,
} from '@mega/editor';
import {
    Icon,
    InputTextareaField,
    WithLabel,
} from '@mega/ui';
import { FiEdit } from 'react-icons/fi';
import { SectionMeta, SectionProps } from './Section.types';
import { EditorControls } from '../../feature/EditorControls';
import { useEditor } from '@apostroph/store';
import { useApostrophStore } from '@mega/store';
import { getTypographedText } from '@mega/utils';

const Section: FC<SectionProps> = ({
    id,
    parentId,
    children,
    type,
}) => {
    const { isFormatted } = useApostrophStore();

    const { handleUpdateElement, getCurrentDataElement } =
        useEditor();
    const { meta, childrenElement } =
        getCurrentDataElement<SectionMeta>(id);
    const [content, setContent] = useState(meta.name);

    useEffect(() => {
        const newGlobalState = {
            id,
            type,
            childrenElement,
            meta: {
                name: content,
            },
        };
        handleUpdateElement(newGlobalState);
    }, [content]);
    useEffect(() => {
        if (isFormatted) {
            setContent(getTypographedText(content));
        }
    }, [isFormatted]);
    return (
        <TemplatePlugin
            head={
                <HeadTemplatePlugin
                    id={id}
                    parentId={parentId}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '4px',
                            width: '100%',
                        }}
                    >
                        <Icon size="16">
                            <FiEdit />
                        </Icon>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '10px',
                            }}
                        >
                            <span>Карточка</span>
                            <div
                                style={{
                                    borderRadius: '8px',
                                    height: '5px',
                                    width: '100%',
                                    backgroundColor:
                                        '#FF0000',
                                }}
                            ></div>
                        </div>
                    </div>
                </HeadTemplatePlugin>
            }
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                }}
            >
                <WithLabel
                    id="name"
                    title="Название"
                    gap="small"
                >
                    <InputTextareaField
                        name={'name'}
                        placeholderDefault={'< >'}
                        value={content}
                        rows={1}
                        dimension={'none'}
                        verticalTextCenter
                        color={'transparent'}
                        onChange={(e) => {
                            const newValue = e.target.value;
                            setContent(newValue);
                        }}
                    />
                </WithLabel>
                {children}
                <EditorControls
                    parentId={id}
                    disableSection={true}
                />
            </div>
        </TemplatePlugin>
    );
};

export { Section };
export default Section;
