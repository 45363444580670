import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgGraph = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 18 18"
        fill="none"
        ref={ref}
        {...props}
    >
        <path
            d="M3.375 15.75c-.3 0-.563-.113-.788-.338a1.08 1.08 0 0 1-.337-.787V3.375c0-.3.112-.563.337-.788a1.08 1.08 0 0 1 .788-.337h11.25c.3 0 .563.112.787.337.225.225.338.488.338.788v11.25c0 .3-.113.563-.338.787a1.08 1.08 0 0 1-.787.338H3.375Zm0-1.125h11.25V3.375H3.375v11.25Z"
            fill="currentColor"
        />
        <path
            d="M7.5 7.5v5H6v-5c0-.275.225-.5.5-.5H7c.275 0 .5.225.5.5ZM8.25 6v6.5h1.5V6c0-.275-.225-.5-.5-.5h-.5c-.275 0-.5.225-.5.5Zm2.25 3v3.5H12V9c0-.275-.225-.5-.5-.5H11c-.275 0-.5.225-.5.5Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgGraph);
export default ForwardRef;
