import React from 'react';
import { VideoPreview } from '../VideoPreview';
import { ImagePreview } from '../ImagePreview';
import { AudioPreview } from '../AudioPreview';
import { FilePreview } from '../FilePreview';

export interface MediaPreviewProps {
    file: any;
}

export const MediaPreview: React.FC<MediaPreviewProps> = (
    props,
) => {
    const { file } = props;

    switch (file.type) {
        case 'video': {
            return (
                <VideoPreview
                    videoSrc={file.file_link || file.video}
                    title={file.title}
                    imgSrc={file.frame}
                />
            );
        }
        case 'audio': {
            return (
                <AudioPreview
                    src={file.audio}
                    title={file.title}
                />
            );
        }
        case 'file': {
            return (
                <FilePreview
                    src={file.file}
                    title={file.title}
                />
            );
        }
        default: {
            return (
                <ImagePreview
                    src={file.image}
                    title={file.description}
                />
            );
        }
    }
};
