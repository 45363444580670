import React, { Children, FC, ReactElement } from 'react';
import { listContent, childStyle } from '@mega/styles';

export interface ListContentProps {
    name?: string;
    padding?: string;
    children:
        | ReactElement
        | ReactElement[]
        | ((props: {
              className: string;
          }) => ReactElement | ReactElement[])
        | ((props: {
              className: string;
          }) => ReactElement | ReactElement[]);
    title?: ReactElement;
}

const ListContent: FC<ListContentProps> = ({
    name,
    children,
    title = undefined,
    padding,
}) => {
    if (typeof children === 'function') {
        return (
            <div id={name} className={listContent}>
                {typeof title === undefined ? null : title}
                {children({ className: childStyle })}
            </div>
        );
    }

    return (
        <div id={name} className={listContent}>
            {Children.map(children, (child) => (
                <div className={childStyle}>{child}</div>
            ))}
        </div>
    );
};

export { ListContent };
