import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgFormatQuote = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M10.875 8.625h3v-3h-3v3Zm-6.75 0h3v-3h-3v3Zm7.613 4.125 1.5-3H9.75V4.5H15v5.4l-1.425 2.85h-1.837Zm-6.75 0 1.5-3H3V4.5h5.25v5.4l-1.425 2.85H4.987Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgFormatQuote);
export default ForwardRef;
