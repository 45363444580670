import type { PluginTemplate } from '@apostroph/types';
import { definitions } from '@mega/api';

export enum videoEnum {
    type = 'VIDEO',
}

export interface videoProps {
    id: string;
    type: string;
    parentId: string | null;
}

export type videoMeta = definitions['AdminHostVideoList'];

export type videoPlugin = PluginTemplate<
    videoEnum.type,
    videoMeta
>;
export type videoPluginDefault = Omit<videoPlugin, 'id'>;
