import { Option } from '@mega/ui/src/components/Inputs/types';
import { PluginTemplate } from '@pages/smartGrid/types/blocks';

export enum ThemesEnum {
    type = 'CONTENT_THEMES',
}

export interface ThemesProps {
    id: string;
    meta: ThemesMeta;
    type: string;
    parentId: string | null;
}

export type ThemesMeta = {
    label: string;
    dataId: string;
    categories: Option[];
    tags: Option[];
};

export type ThemesPlugin = PluginTemplate<
    ThemesEnum.type,
    ThemesMeta
>;
export type ThemesPluginDefault = Omit<ThemesPlugin, 'id'>;
