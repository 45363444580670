import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgCaution = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 61 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m33.153 5.382-.001-.001C32.017 3.399 30.948 3.1 30.495 3.1c-.454 0-1.523.3-2.657 2.28L4.43 46.259l-.003.006c-1.148 1.985-.87 3.068-.647 3.456.224.391 1.023 1.18 3.31 1.18H53.9c2.285 0 3.089-.788 3.317-1.184.227-.395.505-1.482-.63-3.463L33.153 5.383Zm26.099 39.322L35.818 3.832C34.353 1.275 32.427 0 30.495 0c-1.933 0-3.859 1.275-5.323 3.832L1.768 44.704C-1.191 49.817 1.21 54 7.09 54h46.808c5.88 0 8.281-4.183 5.353-9.296Z"
            fill="#C7C7C7"
        />
        <path
            d="M33.55 20.605v14.301h-6.11v-14.3h6.11ZM30.5 44.178a3.326 3.326 0 0 1-1.856-.567 3.362 3.362 0 0 1-1.231-1.511 3.389 3.389 0 0 1 .724-3.67 3.32 3.32 0 0 1 3.643-.73c.61.256 1.133.687 1.5 1.24.367.555.563 1.205.563 1.871a3.387 3.387 0 0 1-.979 2.387 3.34 3.34 0 0 1-2.37.986l.007-.006Z"
            fill="#C7C7C7"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgCaution);
export default ForwardRef;
