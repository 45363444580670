import {
    CoverType,
    EnumCoverType,
    EnumLabelType,
} from '@pages/smartGrid/types/const';

export interface IInitValuesForm {
    cover: CoverType;
    withLead: boolean;
    withInfo: boolean;
}

export const initValuesForm: IInitValuesForm = {
    cover: {
        id: EnumCoverType.photocover,
        label: EnumLabelType.photocover,
    },
    withLead: true,
    withInfo: true,
};
