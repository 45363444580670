import React from 'react';
import {
    fileWrapper,
    iconWrapper,
} from './FilePreview.css';
import { FiFile } from 'react-icons/fi';
import { Tooltip } from '@mui/material';

export interface VideoPreviewProps {
    src: string;
    title?: string;
}

export const FilePreview: React.FC<VideoPreviewProps> = (
    props,
) => {
    const { src } = props;
    return (
        <div className={fileWrapper}>
            <Tooltip
                title="Открыть в новой вкладке"
                placement="bottom"
            >
                <span>
                    <a
                        className={iconWrapper}
                        href={src}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FiFile />
                    </a>
                </span>
            </Tooltip>
        </div>
    );
};
