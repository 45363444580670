/* eslint-disable no-debugger */
import { createModel } from '@rematch/core';

import { v4 as uuidv4 } from 'uuid';
import { isEqual } from 'lodash-es';
import { arrayMoveImmutable } from 'array-move';
import type { RootModel } from '../connector';
import { EditorModel } from '../../types/state';
import { Elements } from '../../types/blocks';
import { RecursiveArray } from '../../types/utils';
import { insert } from '@pages/smartGrid/helpers/immutInsertToArray';

const smartGridEditor = createModel<RootModel>()({
    state: {
        items: [],
        data: {},
    } as EditorModel,
    reducers: {
        initEditor: (_, payload: EditorModel) => payload,
        createElement: (
            state,
            data: {
                payload: Omit<Elements, 'id'>;
                where?: string;
                meta?: Record<string, any>;
            },
        ) => {
            const { payload, where, meta } = data;
            const createId = uuidv4() as string;

            const type = payload.type;

            let newCreateId = {
                id: createId,
                parentId: where,
                ...payload,
            };
            if (meta) {
                newCreateId = { ...newCreateId, meta };
            }
            const newItem = {
                [createId]: newCreateId,
            };

            if (where) {
                const newStore: EditorModel = {
                    ...state,
                    data: {
                        ...state.data,
                        [where]: {
                            ...state.data[where],
                            childrenElement: [
                                ...(state.data[where]
                                    ?.childrenElement ||
                                    []),
                                createId,
                            ],
                        },
                        ...newItem,
                    },

                };
                return newStore;
            }

            const newStore: EditorModel = {
                ...state,
                items: [...state.items, createId],
                data: {
                    ...state.data,
                    ...newItem,
                },
            };

            return newStore;
        },
        createElementAtCustomPosition: (
            state,
            data: {
                payload: Omit<Elements, 'id'>;
                where: string;
                meta?: Record<string, any>;
                neighbour: string;
                childrenElement?: string[];
            },
        ) => {
            const {
                payload,
                where,
                meta,
                neighbour,
                childrenElement,
            } = data;

            const type = payload.type;

            const createId = uuidv4() as string;

            let futureParent = state.data[where];

            const futurePosition =
                futureParent.childrenElement.findIndex(
                    (item) => item === neighbour,
                );

            let newCreateId = {
                parentId: where,
                id: createId,
                ...payload,
            };
            if (meta) {
                newCreateId = { ...newCreateId, meta };
            }

            if (
                meta &&
                childrenElement &&
                childrenElement.length > 0
            ) {
                newCreateId = {
                    ...newCreateId,
                    meta,
                    childrenElement: [...childrenElement],
                };

                childrenElement.forEach((c) => {
                    state.data[c].parentId = createId;
                });
            }
            const newItem = {
                [createId]: newCreateId,
            };

            const newParent = state.data[where];

            if (where) {
                const currentChildren =
                    state.data[where].childrenElement;
                const newChildren = insert(
                    currentChildren,
                    futurePosition,
                    createId,
                );

                const newStore: EditorModel = {
                    ...state,
                    data: {
                        ...state.data,
                        [where]: {
                            ...newParent,
                            childrenElement: newChildren,
                        },
                        ...newItem,
                    },
                };

                return newStore;
            }
        },
        updateElement: (
            state,
            payload: Partial<Elements>,
        ) => {
            const updateElementId = payload.id;
            if (updateElementId) {
                const clearElementData =
                    state.data[updateElementId];

                if (!isEqual(payload, clearElementData)) {
                    const newState = {
                        ...state,
                        data: {
                            ...state.data,
                            [updateElementId]: {
                                ...clearElementData,
                                ...payload,
                            },
                        },
                    };

                    return newState;
                }
            }
            console.log('return state');
            return state;
        },
        removeElement: (
            state,
            payload: {
                what: string;
                parent?: string;
            },
        ) => {
            const { what, parent } = payload;

            const element = state.data[what];
            const type = element.type;

            if (parent) {
                const currentObject = state.data[what];

                const searchChildren = (
                    element: Elements,
                ): RecursiveArray<string> => {
                    const children =
                        element.childrenElement;
                    return [
                        ...children,
                        ...children.map((childId) =>
                            searchChildren(
                                state.data[childId],
                            ),
                        ),
                    ];
                };

                const allChildren: Array<string> =
                    // @ts-ignore
                    searchChildren(currentObject).flat(
                        Infinity,
                    );

                const newData = Object.fromEntries(
                    Object.entries(state.data).filter(
                        ([key]) =>
                            [what, ...allChildren].includes(
                                key,
                            ),
                    ),
                );

                const parentData = state.data[parent];
                const newParent = {
                    ...parentData,
                    childrenElement:
                        parentData.childrenElement.filter(
                            (id) => id !== what,
                        ),
                };

                const exclude = new Set(
                    Object.keys(newData),
                );

                return {
                    items: state.items,
                    data: {
                        ...Object.fromEntries(
                            Object.entries(
                                state.data,
                            ).filter(
                                (e) => !exclude.has(e[0]),
                            ),
                        ),
                        [parent]: newParent,
                    },
                };
            }

            const currentObject = state.data[what];

            const searchChildren = (
                element: Elements,
            ): RecursiveArray<string> => {
                const children = element.childrenElement;
                return [
                    ...children,
                    ...children.map((childId) =>
                        searchChildren(state.data[childId]),
                    ),
                ];
            };

            const allChildren: Array<string> =
                searchChildren(currentObject).flat(
                    Infinity,
                );

            const newItems = state.items.filter(
                (id) => id !== what,
            );

            const exclude = new Set(
                Object.keys(allChildren),
            );

            return {
                items: newItems,
                data: {
                    ...Object.fromEntries(
                        Object.entries(state.data).filter(
                            (e) => !exclude.has(e[0]),
                        ),
                    ),
                },
                // data: omit(state.data, allChildren),
            };
        },
        moveElement: (
            state,
            payload: {
                what: string;
                where: string;
                parent: string;
            },
        ) => {
            const { what, where, parent } = payload;

            if (parent) {
                let currentParent = state.data[parent];
                const newParent = state.data[where];
                const isNewParent =
                    !newParent?.childrenElement.includes(
                        what,
                    );

                const whereIsNeighbour =
                    currentParent.childrenElement.includes(
                        where,
                    );

                if (!whereIsNeighbour && isNewParent) {
                    return {
                        ...state,
                        data: {
                            ...state.data,
                            [where]: {
                                ...newParent,
                                childrenElement: [
                                    ...newParent.childrenElement,
                                    what,
                                ],
                            },
                            [parent]: {
                                ...currentParent,
                                childrenElement:
                                    currentParent.childrenElement.filter(
                                        (id) => id !== what,
                                    ),
                            },
                        },
                    };
                }

                const currentPosition =
                    currentParent.childrenElement.findIndex(
                        (item) => item === what,
                    );
                const newPosition =
                    currentParent.childrenElement.findIndex(
                        (item) => item === where,
                    );

                return {
                    ...state,
                    data: {
                        ...state.data,
                        [parent]: {
                            ...currentParent,
                            childrenElement:
                                arrayMoveImmutable(
                                    state.data[parent]
                                        .childrenElement,
                                    currentPosition,
                                    newPosition,
                                ),
                        },
                    },
                };
            }

            return state;
        },

        changeElements: (
            state,
            data: {
                payload: any;
                id: string;
                parentId?: string;
                newId?: string;
            },
        ) => {
            const { payload, id, parentId, newId } = data;

            if (parentId) {
                const currentParent = state.data[parentId];

                const curIdIndex =
                    currentParent.childrenElement?.indexOf(
                        id,
                    );

                if (
                    typeof curIdIndex !== 'undefined' &&
                    newId
                ) {
                    const parentChildren = [
                        ...currentParent.childrenElement,
                    ];

                    parentChildren[curIdIndex] = newId;

                    const updatedParent = {
                        [parentId]: {
                            ...currentParent,
                            childrenElement: parentChildren,
                        },
                    };
                    const updatedStateData = {
                        ...state.data,
                        ...updatedParent,
                        ...payload,
                    };

                    delete updatedStateData[id];

                    return {
                        ...state,
                        data: updatedStateData,
                    };
                }
                return {
                    ...state,
                };
            }
        },
    },
});

export { smartGridEditor };
export default smartGridEditor;
