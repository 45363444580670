import React, { FC, ReactElement, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import * as AccordionPrimetive from '@radix-ui/react-accordion';
import {
    paddingBaseClasses,
    PaddingBaseClasses,
    paperClasses,
    PaperClasses,
} from '@mega/styles';
import { accordionClasses } from './Accordion.css';
import { Divider } from '../Divider';

export interface AccordionProps {
    children: ReactElement | ReactElement[];
    label: string;
    startIcon?: ReactElement;
    endIcon?: ReactElement;
    value: string;
    className?: string;
    trigger?: ReactElement;
    isOpened?: boolean;
    divider?: boolean;
    classes?: {
        paper?: PaperClasses['recipe'];
        padding?: PaddingBaseClasses['recipe'];
    };
}

const Content: FC<{
    children: ReactElement | ReactElement[];
    state: boolean;
    divider: boolean;
}> = ({ children, state, divider }) => {
    return (
        <AnimatePresence>
            {state && (
                <motion.div
                    initial={{
                        height: '0px',
                        opacity: 0,
                    }}
                    animate={{
                        height: 'auto',
                        opacity: 1,
                    }}
                    exit={{
                        height: 0,
                        opacity: 0,
                    }}
                    transition={{ duration: 0.3 }}
                >
                    <AccordionPrimetive.Content
                        forceMount
                        className={accordionClasses.content}
                    >
                        {divider ? <Divider /> : <div />}
                        {children}
                    </AccordionPrimetive.Content>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

const IconAnimated: FC<{
    className?: string;
    state: boolean;
    icon?: ReactElement;
}> = ({ state, icon, className = '' }) => (
    <motion.div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
        className={className}
        animate={state ? { rotate: 90 } : { rotate: 0 }}
        transition={{ duration: 0.1 }}
    >
        {icon}
    </motion.div>
);

const Accordion: FC<AccordionProps> = ({
    children,
    label,
    startIcon,
    endIcon,
    value,
    className = '',
    trigger,
    classes,
    isOpened,
    divider = true,
}) => {
    const hasStartIcon = Boolean(startIcon);
    const hasEndIcon = Boolean(endIcon);
    const hasButton = Boolean(trigger);

    const [isVisible, setIsVisible] = useState(!!isOpened);

    function handle() {
        setIsVisible(!isVisible);
    }

    return (
        <AccordionPrimetive.Root
            className={[
                className,
                paperClasses.recipe({
                    borderRadius: 'small',
                    variant: 'outlineFilled',
                    color: 'gray',
                    withFocus: true,
                    ...(classes?.paper ?? {}),
                }),
                paddingBaseClasses.recipe({
                    size: '9x8',
                    ...(classes?.padding ?? {}),
                }),
            ].join(' ')}
            type="multiple"
        >
            <AccordionPrimetive.Item value={value}>
                <AccordionPrimetive.Header
                    className={accordionClasses.wrapper}
                >
                    <div
                        className={
                            accordionClasses.headerLayout
                        }
                    >
                        <AccordionPrimetive.Trigger
                            style={
                                isVisible
                                    ? {
                                          paddingBottom:
                                              '8px',
                                      }
                                    : { paddingBottom: '0' }
                            }
                            onClick={handle}
                            className={`${accordionClasses.triggerStyle}`}
                        >
                            <div
                                className={
                                    accordionClasses.base
                                }
                            >
                                {hasStartIcon && (
                                    <IconAnimated
                                        className={
                                            accordionClasses.iconLayout
                                        }
                                        state={isVisible}
                                        icon={startIcon}
                                    />
                                )}
                                {label}
                            </div>
                            {hasEndIcon && (
                                <IconAnimated
                                    state={isVisible}
                                    icon={endIcon}
                                />
                            )}
                            {hasButton && trigger}
                        </AccordionPrimetive.Trigger>
                    </div>
                    <Content
                        divider={divider}
                        state={isVisible}
                    >
                        {children}
                    </Content>
                </AccordionPrimetive.Header>
            </AccordionPrimetive.Item>
        </AccordionPrimetive.Root>
    );
};

export { Accordion };
