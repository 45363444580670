import Modal from '@mui/material/Modal';
import Popover from '@mui/material/Popover';
import Portal from '@mui/material/Portal';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Drawer from '@mui/material/Drawer';
export { getTypographedText } from './getTypographedText';
export { vkEmbed } from './vkEmbed';
export { tgEmbed } from './tgEmbed';
export { youTubeEmbed } from './youtubeEmbed';

function delay(ms: number) {
    return new Promise((resolve) =>
        setTimeout(resolve, ms),
    ).then((res) => true);
}

const flattenObjKey: any = (
    obj: Record<string, unknown>,
    key: string,
) =>
    [].concat(
        ...Object.entries(obj).map(([k, v]) =>
            typeof v === 'object' && v
                ? flattenObjKey(v, key)
                : k === key
                ? v
                : [],
        ),
    );

function hasOwnProperty<
    X extends {},
    Y extends PropertyKey,
>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
    return obj.hasOwnProperty(prop);
}

const calculatePagesCount = (
    pageSize: number,
    totalCount: number,
) => {
    return totalCount < pageSize
        ? 1
        : Math.ceil(totalCount / pageSize);
};
export {
    Modal,
    Popover,
    Portal,
    TextareaAutosize,
    Drawer,
    delay,
    hasOwnProperty,
    flattenObjKey,
    calculatePagesCount,
};
