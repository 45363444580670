import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';

import { useEffect } from 'react';
import {
    useParams,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FormikHelpers } from 'formik';

import { CreateMultipostingArticles } from '@mega/api';
import { useSiteStore } from '..';

const useArticleMultipostStore = () => {
    const progress = useStoreSelector(
        (store: RootState) => store.articleMultipost,
    );
    return progress;
};

const useArticleMultipostDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        get: dispatch.articleMultipost.get,
        update: dispatch.articleMultipost.update,
    };
};

const useArticleMultipost = () => {
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { get, update } = useArticleMultipostDispatch();

    useEffect(() => {
        if (id) {
            get(parseInt(id));
        }
    }, [id]);

    return {
        id: id,

        createCopies: async (
            payload: Pick<
                CreateMultipostingArticles,
                'copies'
            >,
            actions?: FormikHelpers<
                Pick<CreateMultipostingArticles, 'copies'>
            >,
        ) => {
            if (id) {
                const res = await update({
                    payload: payload,
                    query: { id: parseInt(id) },
                });
                if (res?.isOk) {
                    enqueueSnackbar(
                        'Статья(и) успешно создана(ы)',
                    );
                    return {
                        isOk: res?.isOk,
                        data: res?.data,
                    };
                } else {
                    //@ts-ignore
                    Object.entries(res.value).forEach(
                        ([key, value]) => {
                            try {
                                enqueueSnackbar(value);
                            } catch (e) {}
                        },
                    );
                    return {
                        isOk: res?.isOk,
                        data: res?.data,
                    };
                }
            } else {
                enqueueSnackbar(
                    'форма была не проинициализирована, повторите попытку сохранить снова',
                );
            }
        },
    };
};

export {
    useArticleMultipostStore,
    useArticleMultipostDispatch,
    useArticleMultipost,
};
