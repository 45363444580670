import React, { FC } from 'react';
import { Loader, Button } from '@mega/ui';
import { wrapper } from './SimplePagination.css';
export interface SimplePaginationProps {
    hasMore?: boolean;
    nextPage: ({}: any) => void;
    loading?: boolean;
}

const SimplePagination: FC<SimplePaginationProps> = ({
    hasMore,
    nextPage,
    loading,
}) => {
    return (
        <div className={wrapper}>
            {hasMore ? (
                <Button
                    size="large"
                    onClick={() => nextPage({})}
                    label={() => (
                        <div>
                            {loading ? (
                                <Loader size="small" />
                            ) : (
                                'ПОКАЗАТЬ ЕЩЕ'
                            )}
                        </div>
                    )}
                    style={{ width: '130px' }}
                    animate="none"
                    classes={{
                        paper: {
                            variant: 'outline',
                        },
                    }}
                />
            ) : null}
        </div>
    );
};

export { SimplePagination };
