import React, { FC, useEffect } from 'react';
import { BlockScriptEdit } from '@mega/forms';
import {
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';

import {
    useSiteStore,
    useAdsBlockStore,
    useAdsBlockDispatch,
} from '@mega/store';
import { useSnackbar } from 'notistack';
import { PartialUpdateADSBlockQuery } from '@mega/api';
import { FormikHelpers } from 'formik';

export const BlockEdit: FC<{}> = () => {
    const { enqueueSnackbar } = useSnackbar();

    const { id } = useParams();
    const { loading, store } = useAdsBlockStore();
    const { get, update } = useAdsBlockDispatch();
    const site = useSiteStore();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            get({ paylaod: { id: parseInt(id) } });
        }
    }, [id, get]);

    if (loading.get.loading || !loading.get.success) {
        return <div>loading</div>;
    }

    let editedId: number;

    if (id) {
        editedId = parseInt(id);
    } else {
        throw new Error('The editable entity has no id.');
    }

    const handleUpdate = async (
        value: Omit<
            PartialUpdateADSBlockQuery,
            'site' | 'id'
        >,
        actions: FormikHelpers<
            Omit<PartialUpdateADSBlockQuery, 'site' | 'id'>
        >,
    ) => {
        if (!Boolean(site && site.id)) {
            return enqueueSnackbar(
                'Простите что-то пошло не так, обратитесь пожалуйста в тех поддержку',
                {
                    variant: 'error',
                },
            );
        }

        const response = await update({
            payload: {
                ...value,
                //@ts-ignore
                height:
                    value.height === 0
                        ? null
                        : value.height,
                id: editedId,
                site: site?.id || '',
            },
        });

        if (response.isOk) {
            enqueueSnackbar('Скрипт <block> создан', {
                variant: 'success',
            });
        } else {
            if (response.value) {
                const errormsgs = Object.values(
                    response.value,
                );

                errormsgs?.map((msg) => {
                    enqueueSnackbar(msg as string, {
                        variant: 'error',
                    });
                });
            }
        }

        return response;
    };

    return (
        <BlockScriptEdit
            handleUpdate={handleUpdate}
            initialValue={{
                is_active: store.is_active,
                platform: store.platform,
                title: store.title,
                slot: store.slot,
                head_script: store.head_script?.id,
                body_script: store.body_script,
                rotation_weight: store.rotation_weight,
                height: store.height,
                articles: getIds(store?.articles || []),
                not_articles: getIds(
                    store?.not_articles || [],
                ),
                article_in_categories: getIds(
                    store?.article_in_categories || [],
                ),
                article_not_in_categories: getIds(
                    store?.article_not_in_categories || [],
                ),
                article_types: store?.article_types || [],
                not_article_types:
                    store?.not_article_types || [],
            }}
        />
    );
};

function getIds(
    array: Array<{
        id?: number | undefined;
    }>,
): Array<number> {
    return (
        array.reduce<Array<number>>((acc, item) => {
            if (item?.id) {
                return [...acc, item.id];
            }
            return acc;
        }, []) || []
    );
}
