import React, { FC, useState } from 'react';
import { Modal } from '@mega/utils';
import { Article, articleHistory } from '@mega/api';
import { Preview } from './Preview/Preview';
import { Button, IconButton, Row } from '@mega/ui';
import { Close } from '@mega/icons';
import { useSiteStore } from '@mega/store';
interface ArticleHistoryPreviewProps {
    id?: number;
    versionId?: number;
}

const ArticleHistoryPreview: FC<
    ArticleHistoryPreviewProps
> = ({ id, versionId }) => {
    const currentSite = useSiteStore();
    const [isOpen, setIsOpen] = useState(false);
    const [articleForm, setArticleForm] =
        useState<Article | null>(null);

    if (!id && !versionId) {
        return null;
    }

    const handleGetHistoryArticle = async () => {
        if (id && versionId) {
            try {
                const res =
                    await articleHistory.getVersion?.(
                        {
                            //@ts-ignore
                            id: parseInt(id),
                            //@ts-ignore
                            version_pk: parseInt(versionId),
                        },
                        {
                            headers: {
                                'x-site': currentSite?.id,
                            },
                        },
                    );

                if (res?.ok) {
                    //@ts-ignore
                    if (res.data?.field_dict) {
                        //@ts-ignore
                        setArticleForm(res.data.field_dict);
                    }
                }
            } catch {}
        }
    };

    return (
        <>
            <Button
                label={'Просмотреть'}
                onClick={() => {
                    setIsOpen(true);
                    handleGetHistoryArticle();
                }}
            />
            <Modal
                open={isOpen}
                onClose={() => {
                    setIsOpen(false);
                    setArticleForm(null);
                }}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        backgroundColor: 'white',
                        maxHeight: '80vh',
                        maxWidth: '90vw',
                        height: '100%',
                        width: '100%',
                        overflowX: 'scroll',
                        flexDirection: 'column',
                        gap: '30px',
                    }}
                >
                    <div style={{ padding: '15px' }}>
                        <Row reverse>
                            <IconButton
                                animate="primary"
                                size="medium"
                                onClick={() => {
                                    setIsOpen(false);
                                    setArticleForm(null);
                                }}
                            >
                                <Close />
                            </IconButton>
                        </Row>
                    </div>
                    <div
                        style={{
                            overflowX: 'scroll',
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        <Preview
                            key={versionId}
                            initialData={articleForm}
                            isReadonly
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export { ArticleHistoryPreview };
