import type { PluginTemplate } from '@apostroph/types';
import { ReactElement } from 'react';

export enum SectionEnum {
    type = 'SECTION',
}

export interface SectionProps {
    id: string;
    type: string;
    parentId: string | null;
    children: ReactElement | ReactElement[];
}

export type SectionMeta = {
    name: string;
};

export type SectionPlugin = PluginTemplate<
    SectionEnum.type,
    SectionMeta
>;
export type SectionPluginDefault = Omit<
    SectionPlugin,
    'id'
>;
