import React, { useState } from 'react';
import {
    base,
    filterSection,
    searchSection,
    sectionWrapper,
} from './Content.css';
import cn from 'classnames';
import { Listing } from '../Listing';
import { Filters } from '../Filters';
import { CheckedList } from '../CheckedList';

export const Content = () => {
    const [isOpenFilters, setIsOpenFilters] =
        useState(true);

    return (
        <div className={base({ isOpen: isOpenFilters })}>
            <div
                className={cn(
                    sectionWrapper,
                    filterSection,
                )}
            >
                <Filters
                    isOpen={isOpenFilters}
                    handleToggle={() =>
                        setIsOpenFilters((prev) => !prev)
                    }
                />
            </div>
            <div
                className={cn(
                    sectionWrapper,
                    searchSection,
                )}
            >
                <CheckedList />
                <Listing />
            </div>
        </div>
    );
};
