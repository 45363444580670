import React, { ReactElement } from 'react';
import cn from 'classnames';
import {
    rowClasses,
    typographyClasses,
} from '@mega/styles';
import { useArticleRoom } from '@mega/store';
import { LockEdition } from '@mega/icons';
import { articleEditHeaderClasses } from './ArticleEditHeader.css';

interface ArticleEditHeaderProps {
    startRender?: () => ReactElement[] | ReactElement;
    endRender?: () => ReactElement[] | ReactElement;
}

export const ArticleEditHeader: React.FC<
    ArticleEditHeaderProps
> = ({ startRender, endRender }) => {
    const { currentEditor, readonlyUsers } =
        useArticleRoom();

    if (!Boolean(currentEditor?.id)) {
        return null;
    }

    return (
        <div
            className={[
                rowClasses.recipe({ gap: '8' }),
            ].join(' ')}
        >
            {startRender?.()}
            <div
                className={
                    articleEditHeaderClasses.noticeContainer
                }
            >
                <LockEdition style={{ fontSize: '20px' }} />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '4px',
                    }}
                >
                    <div
                        className={cn(
                            typographyClasses.recipe({
                                size: '14',
                                weight: 'bold',
                            }),
                            articleEditHeaderClasses.noticeTitle,
                        )}
                    >
                        Статья редактируется пользователем
                    </div>
                    <div
                        className={cn(
                            typographyClasses.recipe({
                                size: '14',
                                weight: 'medium',
                            }),
                            articleEditHeaderClasses.noticeInfo,
                        )}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px',
                            }}
                        >
                            <span
                                className={typographyClasses.recipe(
                                    {
                                        size: '14',
                                    },
                                )}
                            >
                                Кто редактирует:
                            </span>
                            <div
                                key={currentEditor?.id}
                                className={cn(
                                    typographyClasses.recipe(
                                        {
                                            size: '14',
                                        },
                                    ),
                                    articleEditHeaderClasses.editorInfo,
                                )}
                            >
                                {currentEditor?.name ||
                                    '??'}
                            </div>
                        </div>
                        {readonlyUsers.length !== 0 && (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                }}
                            >
                                <span
                                    className={typographyClasses.recipe(
                                        { size: '14' },
                                    )}
                                >
                                    Кто смотрит:
                                </span>
                                {readonlyUsers.map(
                                    (item) => {
                                        return (
                                            <div
                                                key={
                                                    item.id
                                                }
                                                className={cn(
                                                    typographyClasses.recipe(
                                                        {
                                                            size: '14',
                                                        },
                                                    ),
                                                    articleEditHeaderClasses.viewerInfo,
                                                )}
                                            >
                                                {item?.name ||
                                                    'test test'}
                                            </div>
                                        );
                                    },
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {endRender?.()}
        </div>
    );
};
