import React from 'react';
import { wrapper } from './LinkWrapper.css';

export interface LinkWrapperProps {
    url?: string;
    children: React.ReactNode;
}

const LinkWrapper: React.FC<LinkWrapperProps> = (props) => {
    const { url, children } = props;
    if (url) {
        return (
            <a href={url} className={wrapper}>
                {children}
            </a>
        );
    }
    return <>{children}</>;
};

export { LinkWrapper };
