import { PluginTemplate } from '@pages/smartGrid/types/blocks';
import { Control } from '@pages/smartGrid/types/core';
import {
    CoverType,
    ViewType,
} from '@pages/smartGrid/types/const';

export enum ArticleEnum {
    type = 'CONTENT_ARTICLE',
}

export interface ArticleProps {
    id: string;
    meta: ArticleMeta;
    type: string;
    parentId: string | null;
}

export type ArticleMeta = {
    label: string;
    dataId: string;
    cover: CoverType;
    withLead: boolean;
    withInfo: boolean;
    viewType: ViewType;
};

export interface ArticleControl extends Control {
    meta: ArticleMeta;
}

export type ArticlePlugin = PluginTemplate<
    ArticleEnum.type,
    ArticleMeta
>;
export type ArticlePluginDefault = Omit<
    ArticlePlugin,
    'id'
>;
