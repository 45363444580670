import React, {
    FC,
    useCallback,
    memo,
    useMemo,
} from 'react';
import { useDropzone, FileWithPath } from 'react-dropzone';
import { useField, useFormikContext } from 'formik';
import {
    isString as _isString,
    isNull as _isNull,
} from 'lodash-es';
import { avatarLoaderClasses } from './AvatarLoader.field.css';
import { FiPlus } from 'react-icons/fi';
export type DropFile = {
    file?: string | null;
    id?: number | null;
    title?: string | null;
};

interface FileLoaderProps {}

function isFile(
    value: string | File | null,
): value is File {
    return (value as FileWithPath)?.path !== undefined;
}
function isString(
    value: string | File | null,
): value is string {
    return _isString(value);
}

function isNull(
    value: string | File | null,
): value is null {
    return _isNull(value);
}

const Preview: FC<{
    value: FileWithPath | string | null;
}> = memo(({ value }) => {
    if (isFile(value)) {
        const preview = useMemo(() => {
            return URL.createObjectURL(value);
        }, [value]);
        return (
            <img
                className={avatarLoaderClasses.root}
                src={preview}
                style={{}}
            />
        );
    }
    if (isString(value)) {
        return (
            <img
                className={avatarLoaderClasses.root}
                src={value}
                style={{}}
            />
        );
    }

    return null;
});

const AvatarFileLoader: FC<FileLoaderProps> = () => {
    const [{ value }, meta, { setValue }] = useField<
        string | File | null
    >('photo');

    const onDrop = useCallback(
        async (acceptedFiles: Array<FileWithPath>) => {
            setValue(acceptedFiles[0]);
        },
        [],
    );

    const { getRootProps, getInputProps, isDragActive } =
        useDropzone({
            onDrop,
        });

    return (
        <div
            {...getRootProps()}
            className={avatarLoaderClasses.container}
        >
            <input {...getInputProps()} />
            {isDragActive ? (
                <div
                    className={avatarLoaderClasses.boxLoader(
                        { isDragActive },
                    )}
                >
                    {value ? (
                        <Preview value={value} />
                    ) : (
                        <FiPlus />
                    )}
                </div>
            ) : (
                <div
                    className={avatarLoaderClasses.boxLoader(
                        { isDragActive },
                    )}
                >
                    {value ? (
                        <Preview value={value} />
                    ) : (
                        <FiPlus />
                    )}
                </div>
            )}
        </div>
    );
};

export { AvatarFileLoader };
