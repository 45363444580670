import React from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import { CategoryCreate } from '@mega/forms';
import {
    useCategoryDispatch,
    useSiteStore,
} from '@mega/store';
import { CategoryCreate as CategoryCreateType } from '@mega/api';

export const CategoriesNew: React.FC = () => {
    const { create } = useCategoryDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const site = useSiteStore();

    const location = useLocation();
    const navigate = useNavigate();

    const handleCreate = async (
        value: Pick<CategoryCreateType, 'title'>,
    ) => {
        if (!Boolean(site && site.id)) {
            return enqueueSnackbar(
                'Простите что-то пошло не так, обратитесь пожалуйста в тех поддержку',
                {
                    variant: 'error',
                },
            );
        }

        return create({
            payload: value,
            options: {
                router: {
                    navigate,
                    location,
                },
                notifier: {
                    enqueueSnackbar,
                },
            },
        });
    };

    return <CategoryCreate handleCreate={handleCreate} />;
};
