import React, { FC, ReactElement } from 'react';
import {
    // FormLayoutRecipeVariants,
    // formLayoutRecipe,
    formContentClasses,
    FormContentClasses,
} from '@mega/styles';

import { MainLayoutBottomPortal } from '../Main/Main.layout';

export interface FormLayoutProps {
    children?: ReactElement | ReactElement[];
    settings?: ReactElement | ReactElement[];
    actions?: ReactElement | ReactElement[];
    className?: string;
}

const FormLayout: FC<
    FormLayoutProps & FormContentClasses['recipe']
> = ({ children, settings, actions, className }) => {
    const withSettings = Boolean(settings);

    return (
        <div
            className={className}
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                width: '100%',
                height: '100%',
                position: 'relative',
                flexGrow: '1',
            }}
        >
            {actions && (
                <MainLayoutBottomPortal>
                    {actions}
                </MainLayoutBottomPortal>
            )}
            <div
                className={formContentClasses.recipe({
                    withSettings,
                })}
            >
                <div className={formContentClasses.content}>
                    {children}
                </div>
                {withSettings && (
                    <div
                        className={
                            formContentClasses.setting
                        }
                    >
                        {settings}
                    </div>
                )}
            </div>
        </div>
    );
};

const FormLayoutClasses = formContentClasses;
export { FormLayout, FormLayoutClasses };
