import React, { ChangeEvent, FC, forwardRef } from 'react';
import DatePicker, {
    registerLocale,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputMask from 'react-input-mask';
import ru from 'date-fns/locale/ru';

import {
    PaperClasses,
    paperClasses,
    paddingBaseClasses,
    PaddingBaseClasses,
    typographyClasses,
} from '@mega/styles';
import { useField, useFormikContext } from 'formik';
import dayjs from 'dayjs';
import { inputDate } from './InputDate.css';
import { Calendar } from '@mega/icons';

registerLocale('ru', ru);

export interface InputDateProps {
    name: string;
    onChange?: () => void;
    classes?: {
        paper?: PaperClasses['recipe'];
        padding?: PaddingBaseClasses['recipe'];
    };
}

const InputDate: FC<InputDateProps> = ({
    name,
    classes,
}) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField<string>({ name });

    const timeTemplate = 'YYYY-MM-DDTHH:mm';

    const onChangeWithFormat = (value: Date | null) => {
        if (!value) {
            return;
        }
        const data = dayjs(value).format(timeTemplate);

        setFieldValue(field.name, `${data}`);
        return value;
    };

    return (
        <>
            <DatePicker
                name={name}
                selected={
                    (field.value &&
                        new Date(field.value)) ||
                    null
                }
                onChange={onChangeWithFormat}
                disabledKeyboardNavigation
                dateFormat="dd.MM.yyyy в HH:mm"
                locale={'ru'}
                showTimeInput
                timeInputLabel="Время:"
                className={[
                    paperClasses.recipe({
                        color: 'gray',
                        borderRadius: 'small',
                        variant: 'outlineFilled',
                        ...(classes?.paper ?? {}),
                    }),
                    paddingBaseClasses.recipe({
                        size: '8x8',
                        ...(classes?.padding ?? {}),
                    }),
                    typographyClasses.recipe({
                        size: '14',
                        weight: 'regular',
                    }),
                    inputDate,
                ].join(' ')}
                customInput={
                    <InputMask
                        mask="99.99.9999 в 99:99"
                        maskChar={null}
                    />
                }
            />
        </>
    );
};

export { InputDate };
export { InputDate as InputDateField };
