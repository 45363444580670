import React, { FC, CSSProperties } from 'react';
import {
    useTable,
    useResizeColumns,
    Column,
    TableProps,
    HeaderGroup,
    RowPropGetter,
    Row,
    Cell,
    useFlexLayout,
} from 'react-table';
import { useArticlesRoom } from '@mega/store';

import { reactTableStyle as classes } from './ListingTable.css';

type PropsAdd = {
    style?: CSSProperties;
    className?: string;
    onClick?: () => void;
};

export interface ListingTableProps extends TableProps {
    columns: Column<any>[];
    data: Array<object>;
    getCellProps?: (cell: Cell<object>) => PropsAdd;
    getHeaderProps?: (
        cell: HeaderGroup<object>,
    ) => PropsAdd;
    getHeaderGroupProps?: (
        cell: HeaderGroup<object>,
    ) => PropsAdd;
    getRowGroupProps?: (
        cell:
            | RowPropGetter<object>
            | Row<object>
            | Cell<object>
            | HeaderGroup<object>
            | any,
    ) => PropsAdd;
    hiddenColumns?: string[];
}

const ListingTable: FC<ListingTableProps> = ({
    columns,
    data,
    getCellProps = () => ({}),
    getHeaderProps = () => ({}),
    getHeaderGroupProps = () => ({}),
    getRowGroupProps = () => ({}),
    hiddenColumns = [],
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                hiddenColumns,
            },
        },
        useFlexLayout,
        useResizeColumns,
    );

    return (
        <table
            className={classes.tableStyle}
            {...getTableProps()}
        >
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr
                        {...headerGroup.getHeaderGroupProps(
                            [
                                getHeaderGroupProps(
                                    headerGroup,
                                ),
                            ],
                        )}
                    >
                        {headerGroup.headers.map(
                            (column) => {
                                return (
                                    <th
                                        {...column.getHeaderProps(
                                            [
                                                getHeaderProps(
                                                    column,
                                                ),

                                                {
                                                    style: {
                                                        width: column.width,
                                                        maxWidth:
                                                            column.maxWidth,
                                                        minWidth:
                                                            column.minWidth,
                                                    },
                                                },
                                            ],
                                        )}
                                    >
                                        {column.render(
                                            'Header',
                                        )}
                                    </th>
                                );
                            },
                        )}
                    </tr>
                ))}
            </thead>

            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <tr
                            {...row.getRowProps(
                                getRowGroupProps([
                                    getRowGroupProps(row),
                                ]),
                            )}
                        >
                            {row.cells.map((cell) => {
                                return (
                                    <td
                                        {...cell.getCellProps(
                                            [
                                                getCellProps(
                                                    cell,
                                                ),
                                                {
                                                    style: {
                                                        width: cell
                                                            .column
                                                            .width,
                                                        maxWidth:
                                                            cell
                                                                .column
                                                                .maxWidth,
                                                        minWidth:
                                                            cell
                                                                .column
                                                                .minWidth,
                                                    },
                                                },
                                            ],
                                        )}
                                        width={
                                            cell.column
                                                .width
                                        }
                                    >
                                        {cell.render(
                                            'Cell',
                                        )}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export { ListingTable };
export default ListingTable;
