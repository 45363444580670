import React, { FC, ReactElement } from 'react';

import {
    dashboardLayoutClasses,
    DashboardLayoutClasses,
} from '@mega/styles';

export interface DashboardLayoutProps {
    children?: ReactElement | ReactElement[];
}

const DashboardLayout: FC<
    DashboardLayoutProps & DashboardLayoutClasses['recipe']
> = ({ children }) => (
    <div
        className={[dashboardLayoutClasses.recipe()].join(
            ' ',
        )}
    >
        {children}
    </div>
);

export { DashboardLayout };
