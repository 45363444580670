import React from 'react';
import {
    CheckboxSwitchField,
    SelectSingleField,
} from '@mega/ui';
import { styles as s } from './ArticleDefaultForm.css';
import {
    coverTypeList,
    viewTypeList,
} from '@pages/smartGrid/constants/data';
import { useSiteStore } from '@mega/store';

export interface ArticleDefaultProps {}

export const ArticleDefaultForm: React.FC<
    ArticleDefaultProps
> = () => {
    const coverData = Object.values(coverTypeList).map(
        (item) => ({ ...item, value: item.id }),
    );
    const viewTypeData = Object.values(viewTypeList).map(
        (item) => ({ ...item, value: item.id }),
    );

    const currenSite = useSiteStore();
    // TODO написать конфиг для обработки плагинов сетки и их настроек
    const is360 = currenSite?.id === '360tv.ru';

    return (
        <>
            <SelectSingleField
                isCannotRemovalbe={true}
                name="cover"
                placeholder="Тип обложки"
                className={s.selectField}
                classes={{
                    paper: {
                        color: 'dark',
                    },
                    select: {
                        variant: 'secondary',
                    },
                }}
                handleLoader={async () => coverData}
            />
            {is360 && (
                <SelectSingleField
                    isCannotRemovalbe={true}
                    name="viewType"
                    placeholder="Тип отображения"
                    className={s.selectField}
                    classes={{
                        paper: {
                            color: 'dark',
                        },
                        select: {
                            variant: 'secondary',
                        },
                    }}
                    handleLoader={async () => viewTypeData}
                />
            )}
            <div className={s.checkboxGroup}>
                <CheckboxSwitchField
                    name="withLead"
                    label="Лид-абзац"
                    theme={'secondary'}
                />
                <CheckboxSwitchField
                    name="withInfo"
                    label="Инфо"
                    theme={'secondary'}
                />
            </div>
        </>
    );
};
