import React, { useEffect, FC } from 'react';
import { useParams } from 'react-router-dom';

import {
    useArticleDispatch,
    useArticleRoomDispatch,
} from '@mega/store';
import { ArticleEdit } from '@mega/forms';
import { useInterval } from 'usehooks-ts';
import { ArticleEditHeader } from './ArticleEditHeader';
import { usePickTxnGetProgress } from '@mega/store';
import { Loader, Button } from '@mega/ui';
import { ArticleHistory } from '@mega/core';

const ArticleRoomChecker = () => {
    const { checkin } = useArticleRoomDispatch();
    const { id } = useParams();
    useInterval(() => {
        if (id) {
            checkin({ id: parseInt(id) });
        }
    }, 5000);
    return <></>;
};

export const ArticlesEdit: FC<{}> = () => {
    const { loading } = usePickTxnGetProgress();
    const { checkin } = useArticleRoomDispatch();
    const { txnGet, clear } = useArticleDispatch();
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            txnGet(parseInt(id));
            checkin({ id: parseInt(id) });
        }
    }, [id, txnGet, checkin]);

    useEffect(() => {
        return () => {
            clear();
        };
    }, []);

    if (loading) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100px',
                }}
            >
                <Loader size="large" />
            </div>
        );
    }

    if (!id) {
        return null;
    }

    return (
        <>
            <ArticleHistory id={id}>
                {({ openArticleHistory }) => (
                    <ArticleEditHeader
                        endRender={() => {
                            return (
                                <div
                                    style={{
                                        marginLeft: 'auto',
                                    }}
                                >
                                    <Button
                                        label={'История'}
                                        onClick={
                                            openArticleHistory
                                        }
                                    />
                                </div>
                            );
                        }}
                    />
                )}
            </ArticleHistory>
            <ArticleEdit />
            <ArticleRoomChecker />
        </>
    );
};
