import React, { FC, useEffect, useState } from 'react';
import {
    TemplatePlugin,
    HeadTemplatePlugin,
} from '@mega/editor';
import { useEditor } from '@apostroph/store';
import { Box } from '@mui/material';
import {
    HtmlTextMeta,
    HtmlTextProps,
} from './HtmlText.types';
import { debounce } from 'lodash-es';
import { Embed } from '@mega/core';
import { Icon, Typography } from '@mega/ui';
import { Code } from '@mega/icons';
import { InputTextareaField } from '@mega/ui/src/components/Inputs/InputTextarea';

const HtmlText: FC<HtmlTextProps> = ({
    id,
    parentId,
    type,
}) => {
    const { handleUpdateElement, getCurrentDataElement } =
        useEditor();
    const { meta, childrenElement } =
        getCurrentDataElement<HtmlTextMeta>(id);
    const [content, setContent] = useState(meta.html);

    useEffect(() => {
        const newGlobalState = {
            id,
            type,
            childrenElement,
            meta: {
                html: content,
            },
        };
        handleUpdateElement(newGlobalState);
    }, [content]);

    const onChange = debounce(
        (value) => setContent(value),
        1000,
    );

    return (
        <>
            <TemplatePlugin
                head={
                    <HeadTemplatePlugin
                        id={id}
                        parentId={parentId}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '4px',
                                flexShrink: 0,
                            }}
                        >
                            <Icon size="16">
                                <Code />
                            </Icon>
                            <Typography size={'12'}>
                                HTML код
                            </Typography>
                        </div>
                    </HeadTemplatePlugin>
                }
            >
                <Box sx={{ pb: 2 }}>
                    <InputTextareaField
                        name={'html'}
                        placeholderDefault={'< >'}
                        value={content}
                        rows={1}
                        dimension={'none'}
                        verticalTextCenter
                        color={'transparent'}
                        onChange={(e) => {
                            const newValue = e.target.value;
                            onChange(newValue);
                        }}
                    />
                </Box>
            </TemplatePlugin>
            <TemplatePlugin
                head={
                    <HeadTemplatePlugin
                        id={id}
                        parentId={parentId}
                        showMove={false}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '4px',
                                flexShrink: 0,
                            }}
                        >
                            <Icon size="16">
                                <Code />
                            </Icon>
                            <Typography size={'12'}>
                                Предпросмотр
                            </Typography>
                        </div>
                    </HeadTemplatePlugin>
                }
            >
                <Box sx={{ pb: 2 }}>
                    <Embed html={content} />
                </Box>
            </TemplatePlugin>
        </>
    );
};

export { HtmlText };
