import * as CLIENT from '@mega/api';
import { Video, VideoCreate, VideoUpdate } from '@mega/api';
import { createModel } from '@rematch/core';
import { DispatchEffectWithDependency } from '../type';

import type { RootModel } from '../rootModel';

export interface VideoCreateEffect
    extends DispatchEffectWithDependency<VideoCreate> {
    payload: any;
}

export interface VideoUpdateEffect
    extends DispatchEffectWithDependency<VideoUpdate> {
    payload: any;
    site?: any;
    query: {
        id: number;
    };
}

export interface VideoModel {
    data: Video;
}

const video = createModel<RootModel>()({
    name: 'video',
    state: {
        data: {},
    } as VideoModel,
    reducers: {
        put: (state, payload: Video) => {
            return { ...state, data: payload };
        },
    },
    effects: (dispatch) => ({
        get: async (params: { id: number }, store) => {
            try {
                const response =
                    await CLIENT.videos.getById?.(params, {
                        headers: {
                            'X-Site':
                                store.site.site?.id ?? '',
                        },
                    });
                if (response?.ok) {
                    dispatch.video.put(response.data);
                }
            } catch (e) {
                if (
                    CLIENT.videos.getById &&
                    e instanceof CLIENT.videos.getById.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },
        create: async (
            { payload, options }: VideoCreateEffect,
            store,
        ) => {
            try {
                const response =
                    await CLIENT.videos?.create?.({
                        data: payload.data,
                        type: payload.type,
                        setProgress: payload.setProgress,
                        xSite: store.site.site?.id ?? '',
                    });
                if (
                    response?.status === 201 ||
                    response?.status === 200
                ) {
                    if (options?.router) {
                        const { navigate, location } =
                            options.router;
                        navigate(
                            `/${
                                store.site.site?.id || ''
                            }/hostvideo/change/${
                                response.data.id
                            }`,
                            {
                                state: location,
                            },
                        );
                    }
                    return response;
                }
            } catch (e) {}
        },
        update: async (
            { query, payload, options }: VideoUpdateEffect,
            store,
        ) => {
            try {
                const response =
                    await CLIENT.videos?.updatePartialById?.(
                        {
                            data: payload,
                            id: query.id,
                            xSite:
                                store.site.site?.id ?? '',
                        },
                    );
                if (
                    response?.status === 201 ||
                    response?.status === 200
                ) {
                    dispatch.video.put(response.data);
                    if (options?.notifier) {
                        const { notifier } = options;
                        notifier.enqueueSnackbar(
                            'Видео обновлено',
                        );
                    }
                }
            } catch (e) {
                return e;
            }
        },
        remove: async (
            {
                id,
                type,
            }: { id: number; type: 'articles' | 'stories' },
            store,
        ) => {
            try {
                const response =
                    await CLIENT?.videos?.deleteById?.(
                        {
                            id,
                            type,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
            } catch {}
        },
    }),
});

export { video };
export default video;
