import * as CLIENT from '@mega/api';
import { createModel } from '@rematch/core';
import { isEqual } from 'lodash-es';
import type { RootModel } from '../rootModel';
import type { RoomUser } from '../type';
import {
    NOTIFY_MODULE,
    GET_AUTH_NOTIFY,
    GET_AUTH_NOTIFY_CATCH,
} from './actions';

export interface NotifyModel {
    count: number | null;
}

const notify = createModel<RootModel>()({
    name: NOTIFY_MODULE,
    state: {
        count: null,
    } as NotifyModel,
    reducers: {
        updateCount: (
            state,
            payload: {
                count: number | null;
            },
        ) => {
            return {
                ...state,
                count: payload.count,
            };
        },
    },
    effects: (dispatch) => ({
        [GET_AUTH_NOTIFY]: async () => {
            const response = await CLIENT.notify[
                GET_AUTH_NOTIFY
            ]?.({});
            if (response?.ok) {
                dispatch.notify.updateCount({
                    count:
                        response?.data
                            ?.info_unit_tasks_count || null,
                });
                return {
                    isOk: true,
                    data: response.data,
                };
            } else {
                return {
                    isOk: false,
                    data: response?.data,
                };
            }
        },
        [GET_AUTH_NOTIFY_CATCH]: async () => {
            // try {
            //     await dispatch.notify[GET_AUTH_NOTIFY]();
            // } catch (e) {
            //     if (
            //         CLIENT.notify.getAuthNotify &&
            //         e instanceof
            //             CLIENT.notify.getAuthNotify.Error
            //     ) {
            //         const error = e.getActualType();
            //         if (error.status === 401) {
            //             const { isOk } =
            //                 await dispatch.auth[401]();
            //             if (isOk) {
            //                 dispatch.notify[
            //                     GET_AUTH_NOTIFY
            //                 ]();
            //             }
            //         }
            //     }
            // }
        },
    }),
});

export { notify };
export default notify;
