import React, { FC } from 'react';
import { EditorModel } from '@apostroph/types';
import { useDependencyContextPreview } from '../../store/renderDependencyPreviewContainer';

interface RenderElementProps {
    currentId: string;
    parrentId: string | null;
    data: EditorModel;
}

const RenderElement: FC<RenderElementProps> = ({
    currentId,
    parrentId,
    data: dataProps,
}) => {
    const { rootElements, pluginElements } =
        useDependencyContextPreview();
    const { data } = dataProps;

    const currentElementData = data[currentId];
    const currentType = currentElementData.type;

    const currentElement =
        currentType in rootElements
            ? rootElements[currentType]
            : currentType in pluginElements
            ? pluginElements[currentType]
            : null;

    if (!currentElement) {
        return null;
    }

    if (currentElement?.render) {
        const Comonent = currentElement.render;
        if (
            Array.isArray(
                currentElementData?.childrenElement,
            )
        ) {
            return (
                <Comonent
                    key={currentId}
                    id={currentId}
                    parentId={parrentId}
                    {...currentElementData.meta}
                >
                    {currentElementData.childrenElement.map(
                        (id) => (
                            <RenderElement
                                key={id}
                                currentId={id}
                                parrentId={currentId}
                                data={dataProps}
                            />
                        ),
                    )}
                </Comonent>
            );
        }
        return null;
    }
    return null;
};

const PreviewSerializer: FC<{
    data: EditorModel;
}> = ({ data: { items, data } }) => (
    <>
        {items.map((id) => (
            <RenderElement
                key={id}
                currentId={id}
                parrentId={null}
                data={{ items, data }}
            />
        ))}
    </>
);

export { PreviewSerializer };
export default { PreviewSerializer };
