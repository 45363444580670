import React, { FC } from 'react';
import {
    HeadTemplatePlugin,
    TemplatePlugin,
} from '@mega/editor';
import { inProgressProps } from './InProgress.types';
import { Icon, Typography } from '@mega/ui';
import { Update as UpdateIcon } from '@mega/icons';

const InProgress: FC<inProgressProps> = ({
    id,
    parentId,
}) => {
    return (
        <TemplatePlugin
            head={
                <HeadTemplatePlugin
                    id={id}
                    parentId={parentId}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '4px',
                        }}
                    >
                        <Icon size="16">
                            <UpdateIcon />
                        </Icon>
                        <Typography size={'12'}>
                            <span
                                style={{
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                В процессе
                            </span>
                        </Typography>
                    </div>
                </HeadTemplatePlugin>
            }
        />
    );
};

export { InProgress };
