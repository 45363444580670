import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgDashboardAlt = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M7.563 5.813V.75h6.187v5.063H7.562ZM.25 8.063V.75h6.188v7.313H.25Zm7.313 6.187V6.937h6.187v7.313H7.562Zm-7.313 0V9.187h6.188v5.063H.25Zm1.125-7.313h3.938V1.875H1.374v5.063Zm7.313 6.188h3.937V8.062H8.687v5.063Zm0-8.438h3.937V1.876H8.687v2.813Zm-7.313 8.438h3.938v-2.813H1.374v2.813Z"
            fill="#F4F4F4"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgDashboardAlt);
export default ForwardRef;
