import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgAutoGraph = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="m6.188 6.35-.694-1.481-1.481-.694 1.48-.694L6.188 2l.694 1.481 1.482.694-1.482.694-.694 1.481Zm5.062 2.269-.581-1.238L9.43 6.8l1.238-.581.581-1.238.581 1.238 1.238.581-1.238.581-.581 1.238Zm-8.681 1.5L1.988 8.88.75 8.3l1.238-.581.58-1.238.582 1.238 1.238.581-1.238.581-.581 1.238Zm.543 5.756-.862-.863 5.625-5.625 3 3.02 5.587-6.263.788.768-6.375 7.182-3-2.981-4.763 4.762Z"
            fill="#F4F4F4"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgAutoGraph);
export default ForwardRef;
