import React, { FC } from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import {
    Button,
    FormLayout,
    InputField,
    Paper,
    Stack,
} from '@mega/ui';
import { CategoryCreate as CategoryCreateType } from '@mega/api';
import { CategoryCreateActionForm } from './Category.type';

export interface CategoryTestProps {
    initialValue?: Pick<CategoryCreateType, 'title'>;
    handleCreate?: (
        value: Pick<CategoryCreateType, 'title'>,
    ) => any;
}

const CategoryCreate: FC<CategoryTestProps> = ({
    initialValue = { title: '' },
    handleCreate = () => null,
}) => {
    return (
        <Formik
            validationSchema={Yup.object({
                title: Yup.string()
                    .min(
                        2,
                        'Это поле явно должно быть больше одного символа',
                    )
                    .required('Это поле обязательное'),
            })}
            initialValues={initialValue}
            onSubmit={handleCreate}
        >
            <Form>
                <FormLayout
                    actions={<CategoryCreateActions />}
                >
                    <Paper
                        variant="outline"
                        color="white"
                        classes={{
                            padding: { size: '24x24' },
                        }}
                    >
                        <Stack gap="18">
                            <InputField
                                type="text"
                                name={'title'}
                                placeholder="Заголовок"
                                dimension="medium"
                            />
                        </Stack>
                    </Paper>
                </FormLayout>
            </Form>
        </Formik>
    );
};

const CategoryCreateActions: CategoryCreateActionForm =
    () => {
        const { submitForm, isSubmitting } =
            useFormikContext();

        return (
            <Paper
                style={{
                    borderTop: '1px solid #EAEAEA',
                }}
                fullWidth
                color={'white'}
                variant="filled"
                classes={{
                    padding: { size: '24x24' },
                }}
            >
                <Button
                    classes={{
                        paper: {
                            variant: 'filled',
                            color: 'dark',
                        },
                    }}
                    disabled={isSubmitting}
                    color="secondary"
                    size={'large'}
                    type={'submit'}
                    label={'Создать'}
                    onClick={submitForm}
                />
            </Paper>
        );
    };

export { CategoryCreate };
