import { IconButton, Typography } from '@mega/ui';
import './Modal.css';

import { ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Close } from '@mega/icons';

function PortalImpl({
    onClose,
    children,
    title,
    closeOnClickOutside,
}: {
    children: ReactNode;
    closeOnClickOutside: boolean;
    onClose: () => void;
    title: string;
}) {
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (modalRef.current !== null) {
            modalRef.current.focus();
        }
    }, []);

    useEffect(() => {
        let modalOverlayElement: HTMLElement | null = null;
        const handler = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        const clickOutsideHandler = (event: MouseEvent) => {
            const target = event.target;
            if (
                modalRef.current !== null &&
                !modalRef.current.contains(
                    target as Node,
                ) &&
                closeOnClickOutside
            ) {
                onClose();
            }
        };
        const modelElement = modalRef.current;
        if (modelElement !== null) {
            modalOverlayElement =
                modelElement.parentElement;
            if (modalOverlayElement !== null) {
                modalOverlayElement.addEventListener(
                    'click',
                    clickOutsideHandler,
                );
            }
        }

        window.addEventListener('keydown', handler);

        return () => {
            window.removeEventListener('keydown', handler);
            if (modalOverlayElement !== null) {
                modalOverlayElement?.removeEventListener(
                    'click',
                    clickOutsideHandler,
                );
            }
        };
    }, [closeOnClickOutside, onClose]);

    return (
        <div className="Modal__overlay" role="dialog">
            <div
                className="Modal__modal"
                tabIndex={-1}
                ref={modalRef}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography size="24">
                        {title}
                    </Typography>
                    <IconButton
                        type="button"
                        classes={{
                            paper: {
                                variant: 'outline',
                                color: 'white',
                            },
                        }}
                        size="medium"
                        onClick={onClose}
                    >
                        <Close />
                    </IconButton>
                </div>

                <div className="Modal__content">
                    {children}
                </div>
            </div>
        </div>
    );
}

export default function Modal({
    onClose,
    children,
    title,
    closeOnClickOutside = false,
}: {
    children: ReactNode;
    closeOnClickOutside?: boolean;
    onClose: () => void;
    title: string;
}): JSX.Element {
    return createPortal(
        <PortalImpl
            onClose={onClose}
            title={title}
            closeOnClickOutside={closeOnClickOutside}
        >
            {children}
        </PortalImpl>,
        document.body,
    );
}
