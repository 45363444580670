/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Control } from '../../../../types/core';
import { Categories } from './Categories';
import {
    CategoriesEnum,
    CategoriesPluginDefault,
} from './Categories.types';

const PLUGIN_CATEGORIES_NAME = CategoriesEnum.type;

const PLUGIN_META_DEFAULT = {
    dataId: '',
    label: '',
    categories: [],
    tags: [],
};
const DEFAULT_CATEGORIES: CategoriesPluginDefault = {
    type: PLUGIN_CATEGORIES_NAME,
    meta: PLUGIN_META_DEFAULT,
    childrenElement: [],
};

const PLUGIN_CATEGORIES = {
    [PLUGIN_CATEGORIES_NAME]: {
        emptyObject: DEFAULT_CATEGORIES,
        render: Categories,
    },
};

const PLUGIN_CATEGORIES_CONTROL: Control = {
    name: PLUGIN_CATEGORIES_NAME,
    label: 'Categories',
    tooltip: 'Создать категорию',
    meta: PLUGIN_META_DEFAULT,
    Icon: () => <></>,
};

export {
    DEFAULT_CATEGORIES,
    PLUGIN_CATEGORIES,
    PLUGIN_CATEGORIES_CONTROL,
    PLUGIN_CATEGORIES_NAME,
};
