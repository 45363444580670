//@ts-nocheck

import React, { FC, useState } from 'react';
import { definitions } from '@mega/api';
import {
    InputField,
    WithLabel,
    CheckboxField,
    Stack,
    Button,
    Base,
    Typography,
    Loader,
} from '@mega/ui';
import { AnimatePresence, motion } from 'framer-motion';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useAuthDispatch, useAuthStore } from '@mega/store';
import { useSnackbar } from 'notistack';

export interface LoginProps {}

const LoginForm: FC<LoginProps> = () => {
    const {
        loading: { stageLogin },
    } = useAuthStore();
    const { login } = useAuthDispatch();
    const [, setFormError] = useState<string | null>(null);

    const initialValues: Partial<
        definitions['TokenObtainPair']
    > = {};

    const { enqueueSnackbar } = useSnackbar();
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
                username: Yup.string()
                    .min(
                        2,
                        'Это поле явно должно быть больше одного символа',
                    )
                    .required('Это поле обязательное'),
                password: Yup.string()
                    .min(
                        1,
                        'Пароль из одного символа? Серьезно?',
                    )
                    .required('Это поле обязательное'),
            })}
            onSubmit={async (values, actions) => {
                if (
                    values &&
                    values.password &&
                    values.username
                ) {
                    const responce = await login?.({
                        username: values.username,
                        password: values.password,
                    });
                    setFormError(responce.detail);
                    return enqueueSnackbar(
                        responce.detail,
                        {
                            variant: 'error',
                        },
                    );
                }
            }}
        >
            <Form>
                <Stack gap="58">
                    <Typography size="32" weight="bold">
                        Вход для сотрудников
                    </Typography>
                    <Stack gap="32">
                        <Stack gap="16">
                            <Stack gap="24">
                                <WithLabel
                                    id="1"
                                    labelSide="top"
                                    title="Логин"
                                >
                                    <InputField
                                        name="username"
                                        type="text"
                                        placeholder="Логин"
                                    />
                                </WithLabel>

                                <WithLabel
                                    id="1"
                                    labelSide="top"
                                    title="Пароль"
                                >
                                    <InputField
                                        name="password"
                                        type="password"
                                        placeholder="Пароль"
                                        showIcon={({
                                            isShow,
                                        }) => {
                                            return isShow ? (
                                                <FiEye />
                                            ) : (
                                                <FiEyeOff />
                                            );
                                        }}
                                    />
                                </WithLabel>
                            </Stack>
                            <CheckboxField
                                name="remember"
                                label="Запомнить меня"
                                value="1"
                            ></CheckboxField>
                        </Stack>
                        <Base height="60px" width="170px">
                            <Button
                                classes={{
                                    paper: {
                                        color: 'dark',
                                        variant: 'filled',
                                        fullHeight: true,
                                        fullWidth: true,
                                    },
                                }}
                                size="none"
                                type="submit"
                                label={() => {
                                    const isLoading =
                                        stageLogin.loading;
                                    return (
                                        <AnimatePresence>
                                            {isLoading ? (
                                                <Loader size="small" />
                                            ) : (
                                                <motion.span
                                                    initial={{
                                                        opacity: 0,
                                                        scale: 0,
                                                    }}
                                                    animate={{
                                                        opacity: 1,
                                                        scale: 1,
                                                    }}
                                                    exit={{
                                                        opacity: 0,
                                                        scale: 0,
                                                    }}
                                                >
                                                    Войти
                                                </motion.span>
                                            )}
                                        </AnimatePresence>
                                    );
                                }}
                            />
                        </Base>
                    </Stack>
                </Stack>
            </Form>
        </Formik>
    );
};

export { LoginForm };
