import React, {
    ReactElement,
    useEffect,
    useState,
} from 'react';
import { GroupBase, Props } from 'react-select';
import type { CreatableProps } from 'react-select/creatable';
import Creatable from 'react-select/creatable';

import {
    AsyncPaginate,
    ComponentProps,
    LoadOptions,
    UseAsyncPaginateParams,
    withAsyncPaginate,
} from 'react-select-async-paginate';

import {
    Class,
    selectSingleClasses,
} from './SelectSingle.css';
import {
    ClearIndicator,
    ContainerPrimary,
    ContainerSecondary,
    ContainerThird,
    ControlPrimary,
    ControlSecondary,
    ControlThird,
    IndicatorsContainer,
    Input,
    MenuListPrimary,
    MenuListSecondary,
    MenuListThird,
    MenuPrimary,
    MenuSecondary,
    MenuThird,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
} from './components';
import { Icon } from '@mega/ui';
import { Close } from '@mega/icons';
import {
    Option as OptionType,
    Options as OptionsType,
    TextOption as TextOptionType,
} from '../types';
import { PaperClasses } from '@mega/styles';

type Additional = {
    page: number;
};

interface State {
    readonly inputValue: string;
    selectedOption: OptionType | null | undefined;
}

export interface SelectSingleProps extends Props {
    placeholder?: string;
    handleLoader: (
        inputValue: string,
        page: number,
    ) => Promise<OptionsType>;
    defaultValue?: OptionType;
    defaultText?: TextOptionType;
    actionOnSelectedOption?: (
        selectedOption: OptionType | null,
    ) => void;
    actionOnCreatedOption?: (value: string) => void;
    defaultOptions?: OptionsType;
    startIcon?: ReactElement;
    endIcon?: ReactElement;
    clearIcon?: ReactElement;
    options?: OptionsType;
    isGroup?: boolean;
    menuIsOpen?: boolean | undefined;
    classes?: {
        paper?: PaperClasses['recipe'];
        select?: Class['recipe'];
    };
    isError?: boolean;
    isCreatable?: boolean;
    isCannotRemovalbe?: boolean;
    getUploadOptions?: (options: OptionsType) => void;
}

type AsyncPaginateCreatableProps<
    OptionType,
    Group extends GroupBase<OptionType>,
    Additional,
    IsMulti extends boolean,
> = CreatableProps<OptionType, IsMulti, Group> &
    UseAsyncPaginateParams<OptionType, Group, Additional> &
    ComponentProps<OptionType, Group, IsMulti>;

type AsyncPaginateCreatableType = <
    OptionType,
    Group extends GroupBase<OptionType>,
    Additional,
    IsMulti extends boolean = false,
>(
    props: AsyncPaginateCreatableProps<
        OptionType,
        Group,
        Additional,
        IsMulti
    >,
) => ReactElement;
const CreatableAsyncPaginate = withAsyncPaginate(
    Creatable,
) as AsyncPaginateCreatableType;

const emptyText = {
    value: undefined,
    label: '',
};
export const SelectSingle: React.FC<SelectSingleProps> = (
    props,
) => {
    const {
        placeholder = '',
        startIcon,
        endIcon = null,
        clearIcon = (
            <Icon size="18">
                <Close />
            </Icon>
        ),
        isCreatable,
        isClearable = true,
        options,
        isGroup = false,
        menuIsOpen,
        classes = {
            select: {
                variant: 'secondary',
            },
        },
        isError,
        actionOnSelectedOption,
        actionOnCreatedOption,
        defaultValue,
        defaultText,
        handleLoader,
        isCannotRemovalbe,
        getUploadOptions,
    } = props;
    const [selectedOption, setSelectedOption] = useState(
        defaultValue || null,
    );
    const [selectedText, setSelectedText] = useState(
        defaultText || emptyText,
    );

    const handleChange = (selectedOption: OptionType) => {
        if (isCannotRemovalbe) {
            if (selectedOption) {
                setSelectedOption(selectedOption);
                if (actionOnSelectedOption) {
                    actionOnSelectedOption(selectedOption);
                }
            }
        } else {
            setSelectedOption(selectedOption);
            if (actionOnSelectedOption) {
                actionOnSelectedOption(selectedOption);
            }
        }
    };

    const optionsPerPage = 9.9;

    const [isSearch, setIsSearch] = useState(false);

    const loadOptions = async (
        search: string,
        page: number,
    ): Promise<{
        options: OptionType[];
        hasMore: boolean;
    }> => {
        const getOptions = await handleLoader(search, page);

        if (search !== '') {
            setIsSearch(true);
        } else {
            setIsSearch(false);
        }

        let filteredOptions;
        if (!search) {
            filteredOptions = getOptions;
        } else {
            const searchLower = search.toLowerCase();
            filteredOptions = getOptions.filter(
                ({ label }) =>
                    label
                        .toLowerCase()
                        .includes(searchLower),
            );
        }

        const hasMore =
            Math.ceil(
                filteredOptions.length / optionsPerPage,
            ) > page;

        const slicedOptions = filteredOptions.slice(
            Math.ceil((page - 1) * optionsPerPage),
            Math.ceil(page * optionsPerPage),
        );

        return {
            options: slicedOptions,
            hasMore,
        };
    };

    const loadPageOptions: LoadOptions<
        OptionType,
        GroupBase<OptionType>,
        Additional
    > = async (q, prevOptions, { page }) => {
        const { options, hasMore } = await loadOptions(
            q,
            page,
        );

        if (getUploadOptions) {
            getUploadOptions(options);
        }

        return {
            options: options,
            hasMore,

            additional: {
                page: page + 1,
            },
        };
    };

    useEffect(() => {
        setSelectedOption(defaultValue || null);
    }, [defaultValue]);
    useEffect(() => {
        setSelectedText(defaultText || emptyText);
    }, [defaultText]);

    const handleCreate = (value: string) => {
        if (actionOnCreatedOption) {
            setSelectedText({
                value: undefined,
                label: value,
            });
            actionOnCreatedOption(value);
        }
    };
    const getVariantPrimary =
        classes.select?.variant === 'primary';

    const getVariantSecondary =
        classes.select?.variant === 'secondary';

    const getNewPage = options
        ? isSearch
            ? 1
            : Math.ceil(options?.length / 10) + 1
        : 1;

    const CurrentAsyncSelect = isCreatable
        ? CreatableAsyncPaginate
        : AsyncPaginate;

    const curValue = selectedText.label
        ? selectedText
        : selectedOption;
    return (
        <div>
            <CurrentAsyncSelect
                {...props}
                loadOptions={loadPageOptions}
                value={curValue}
                cacheOptions
                formatCreateLabel={(value) => (
                    <div>Добавить: "{value}"</div>
                )}
                additional={{
                    page: getNewPage,
                }}
                components={{
                    Control: getVariantPrimary
                        ? ControlPrimary
                        : getVariantSecondary
                        ? ControlSecondary
                        : ControlThird,
                    IndicatorsContainer:
                        IndicatorsContainer({
                            className:
                                selectSingleClasses.indicatorsContainer,
                        }),
                    Input,
                    MenuList: getVariantPrimary
                        ? MenuListPrimary
                        : getVariantSecondary
                        ? MenuListSecondary
                        : MenuListThird,
                    DropdownIndicator: () => {
                        if (selectedOption) {
                            return <></>;
                        } else return endIcon;
                    },
                    ClearIndicator: ClearIndicator({
                        clearIcon,
                        className:
                            selectSingleClasses.clearIndicator,
                    }),
                    Option: Option({
                        className:
                            selectSingleClasses.optionRecipe(
                                classes.select,
                            ),
                    }),
                    Placeholder: Placeholder({
                        className:
                            selectSingleClasses.placeholderRecipe(
                                classes.select,
                            ),
                    }),
                    SelectContainer: getVariantPrimary
                        ? ContainerPrimary
                        : getVariantSecondary
                        ? ContainerSecondary
                        : ContainerThird,
                    SingleValue: SingleValue({
                        className:
                            selectSingleClasses.singleValueRecipe(
                                classes.select,
                            ),
                    }),
                    ValueContainer: ValueContainer,
                    Menu: getVariantPrimary
                        ? MenuPrimary
                        : getVariantSecondary
                        ? MenuSecondary
                        : MenuThird,
                }}
                styles={{
                    indicatorSeparator: (base) => ({
                        ...base,
                        display: 'none',
                    }),
                    control: () => ({
                        border: isError
                            ? '1px solid red !important'
                            : 'none',
                    }),
                    input: () => ({}),
                    menu: (base) => ({
                        ...base,
                        position:
                            classes.select?.variant ===
                                'primary' ||
                            classes.select?.variant ===
                                'third'
                                ? 'relative'
                                : 'absolute',
                    }),
                    valueContainer: () => ({}),
                    loadingIndicator: (base) => ({
                        ...base,
                        color: getVariantPrimary
                            ? '#fff'
                            : getVariantSecondary
                            ? '#282828'
                            : '#fff',
                    }),
                    loadingMessage: (base) => ({
                        ...base,
                        padding: '6px',
                    }),
                    singleValue: (base) => ({
                        ...base,
                        whiteSpace: 'normal',
                    }),
                }}
                placeholder={placeholder}
                onCreateOption={handleCreate}
                onChange={(value) => {
                    if (typeof value !== 'string') {
                        setSelectedText(emptyText);
                        const typedValue = value as any;

                        handleChange(typedValue);
                    }
                }}
                menuIsOpen={menuIsOpen}
                theme={(theme): any => ({
                    colors: {},
                    spacing: '',
                })}
                closeMenuOnSelect
                isClearable={isClearable}
            />
        </div>
    );
};
