import { ReactElement } from 'react';
import { PluginTemplate } from '@pages/smartGrid/types/blocks';

export enum FlowStackEnum {
    type = 'FLOW_STACK',
}

export interface FlowStackProps {
    id: string;
    type: string;
    meta: any;
    parentId: string | null;
    children: ReactElement | ReactElement[];
}

export type FlowStackMeta = {};

export type FlowStackPlugin = PluginTemplate<
    FlowStackEnum.type,
    FlowStackMeta
>;
export type FlowStackPluginDefault = Omit<
    FlowStackPlugin,
    'id'
>;
