import {
    init,
    RematchDispatch,
    RematchRootState,
} from '@rematch/core';
import { createContext } from 'react';
import {
    createDispatchHook,
    createSelectorHook,
} from 'react-redux';
import { models, RootModel } from './connector';

export const smartGridEditorContext =
    createContext<RootState | null>(null);

export const store = (name = 'smartGrid/root') =>
    init({
        name: name,
        models,
        redux: {
            devtoolOptions: {
                actionSanitizer: (action) => action,
            },
        },
    });

export const useGridEditorDispatch = createDispatchHook(
    //@ts-ignore
    smartGridEditorContext,
);

export const useGridEditorSelector = createSelectorHook(
    //@ts-ignore
    smartGridEditorContext,
);

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;
