import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgTableDeleteStroke = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="m13.347 14.25-1.5 1.5h4.136l-1.5-1.5h-1.136ZM14.311 9.75l1.5-1.5h-3.792l1.5 1.5h.792Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 2.25A2.75 2.75 0 0 0 2.25 5v14A2.75 2.75 0 0 0 5 21.75h14A2.75 2.75 0 0 0 21.75 19V5A2.75 2.75 0 0 0 19 2.25H5Zm-1.25 12v-4.5h6.233l-1.5-1.5H3.75V5c0-.69.56-1.25 1.25-1.25h14c.69 0 1.25.56 1.25 1.25v3.25h-.904l-1.5 1.5h2.404v4.5h-2.231l1.5 1.5h.731V19c0 .69-.56 1.25-1.25 1.25H5c-.69 0-1.25-.56-1.25-1.25v-3.25h4.561l1.5-1.5H3.75Z"
            fill="currentColor"
        />
        <path
            d="M13.915 10.853 9.53 6.468 8.47 7.53l4.384 4.385-4.384 4.384 1.06 1.06 4.385-4.384 4.383 4.384 1.06-1.061-4.383-4.383L19.36 7.53l-1.061-1.06-4.383 4.383Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgTableDeleteStroke);
export default ForwardRef;
