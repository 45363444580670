import * as CLIENT from '@mega/api';
import {
    BannedOrganization,
    BannedOrganizationCreate,
    BannedOrganizationUpdate,
} from '@mega/api';
import { createModel } from '@rematch/core';
import { DispatchEffectWithDependency } from '../type';

import type { RootModel } from '../rootModel';

export interface BannedOrganizationCreateEffect
    extends DispatchEffectWithDependency<
        Pick<BannedOrganizationCreate, 'title'>
    > {}

export interface BannedOrganizationUpdateEffect
    extends DispatchEffectWithDependency<BannedOrganizationUpdate> {
    query: {
        id: number;
    };
}

const bannedOrganization = createModel<RootModel>()({
    name: 'bannedOrganization',
    state: {} as BannedOrganization,
    reducers: {
        put: (state, payload: BannedOrganization) => {
            return { ...payload };
        },
    },
    effects: (dispatch) => ({
        get: async (params: { id: number }, store) => {
            try {
                const response =
                    await CLIENT.bannedOrganizations.getById?.(
                        {
                            ...params,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    dispatch.bannedOrganization.put(
                        response.data,
                    );
                }
            } catch (e) {
                if (
                    CLIENT.bannedOrganizations.getById &&
                    e instanceof
                        CLIENT.bannedOrganizations.getById
                            .Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },
        create: async (
            {
                payload,
                options,
            }: BannedOrganizationCreateEffect,
            store,
        ) => {
            try {
                const response =
                    await CLIENT.bannedOrganizations?.create?.(
                        {
                            ...payload,
                            //@ts-ignore
                            site: store.site.site?.id || '',
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    if (options?.router) {
                        const { navigate, location } =
                            options.router;
                        navigate(
                            `/${
                                store.site.site?.id || ''
                            }/bannedorganization/change/${
                                response.data.id
                            }`,
                            {
                                state: location,
                            },
                        );
                    }
                    if (options?.notifier) {
                        const { enqueueSnackbar } =
                            options.notifier;
                        enqueueSnackbar(
                            'Новый тег добавлен',
                            {
                                variant: 'success',
                            },
                        );
                    }
                }
            } catch (e) {
                if (
                    CLIENT.bannedOrganizations.create &&
                    e instanceof
                        CLIENT.bannedOrganizations.create
                            .Error
                ) {
                    const error = e.getActualType();

                    if (options?.notifier) {
                        const { enqueueSnackbar } =
                            options.notifier;

                        const errormsgs = Object.values(
                            error.data,
                        );

                        errormsgs?.map((msg) => {
                            enqueueSnackbar(msg as string, {
                                variant: 'error',
                            });
                        });
                    }
                    return error.data;
                }
            }
        },
        update: async (
            {
                query,
                payload,
                options,
            }: BannedOrganizationUpdateEffect,
            store,
        ) => {
            try {
                const response =
                    await CLIENT.bannedOrganizations?.updateById?.(
                        {
                            ...payload,
                            id: query.id,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    dispatch.bannedOrganization.put(
                        response.data,
                    );
                    if (options?.notifier) {
                        const { enqueueSnackbar } =
                            options.notifier;
                        enqueueSnackbar('Тег обновлен');
                    }
                }
            } catch (e) {
                if (
                    CLIENT.bannedOrganizations.updateById &&
                    e instanceof
                        CLIENT.bannedOrganizations
                            .updateById.Error
                ) {
                    const error = e.getActualType();
                    if (
                        options?.notifier?.enqueueSnackbar
                    ) {
                        const { enqueueSnackbar } =
                            options.notifier;

                        const errormsgs = Object.values(
                            error.data,
                        );

                        errormsgs?.map((msg) => {
                            enqueueSnackbar(msg as string, {
                                variant: 'error',
                            });
                        });
                    }
                    return error.data;
                }
            }
        },
        remove: async (id: number, store) => {
            const query = store.bannedOrganizations.query;

            try {
                const response =
                    await CLIENT?.bannedOrganizations?.deleteById?.(
                        {
                            id,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    dispatch.bannedOrganizations.get(query);
                }
            } catch {}
        },
    }),
});

export { bannedOrganization };
export default bannedOrganization;
