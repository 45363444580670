import React, { FC } from 'react';
import { MostReadProps } from './MostRead.types';
import { styles as s } from './MostRead.css';
import { PluginViewWrapper } from 'src/pages/smartGrid/components/shared/PluginViewWrapper';

const MostRead: FC<MostReadProps> = (props) => {
    const { id, parentId, type } = props;
    return (
        <PluginViewWrapper
            id={id}
            parentId={parentId}
            title={'Самое читаемое'}
        >
            <div className={s.wrapper({})}>
                <p className={s.text}>Самое читаемое</p>
            </div>
        </PluginViewWrapper>
    );
};

export { MostRead };
