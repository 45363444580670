import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgSearch = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M14.925 15.731 9.994 10.8a4.133 4.133 0 0 1-1.313.76c-.5.18-1.031.271-1.593.271-1.35 0-2.494-.468-3.432-1.406C2.72 9.487 2.25 8.356 2.25 7.031c0-1.325.469-2.456 1.406-3.393C4.594 2.7 5.731 2.23 7.07 2.23c1.325 0 2.453.469 3.384 1.407.931.937 1.397 2.068 1.397 3.393 0 .538-.088 1.057-.262 1.557a4.76 4.76 0 0 1-.788 1.406l4.95 4.912-.825.825Zm-7.856-5.025c1.012 0 1.875-.36 2.587-1.078a3.556 3.556 0 0 0 1.069-2.597 3.556 3.556 0 0 0-1.069-2.597A3.509 3.509 0 0 0 7.07 3.356c-1.025 0-1.897.36-2.616 1.078a3.54 3.54 0 0 0-1.078 2.597 3.54 3.54 0 0 0 1.078 2.597c.719.719 1.59 1.078 2.616 1.078Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgSearch);
export default ForwardRef;
