import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';

const useAuthStore = () => {
    const store = useStoreSelector(
        (state: RootState) => state.auth,
    );
    const loading = useStoreSelector(
        (state: RootState) => state.loading.effects.auth,
    );

    return Object.assign(store, {
        loading: {
            stageInit: loading.stageInit,
            stageLogin: loading.stageLogin,
        },
    });
};

const useAuthDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        login: dispatch.auth.stageLogin,
        logout: dispatch.auth.logout,
        retry: dispatch.auth.retry,
        getNavigation: dispatch.auth.getNavigation,
        subscribeNotifyMiddleWare:
            dispatch.auth.subscribeNotifyMiddleWare,
    };
};

export { useAuthStore, useAuthDispatch };
