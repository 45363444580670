import React, { useEffect, useState } from 'react';
import { ArrowDown, Filter, Search } from '@mega/icons';
import {
    useInfounittasksDispatch,
    useInfounittasksStore,
    useSiteStore,
    useUserInfo,
} from '@mega/store';
import {
    Button,
    Group,
    Icon,
    SelectSingleField,
    Typography,
    WithTrigger,
    Input,
} from '@mega/ui';
import { Form, Formik, isString } from 'formik';
import { getOptionsRedactors } from '@mega/core';

import { MdClose } from 'react-icons/md';
import { motion } from 'framer-motion';
import {
    useSearchParams,
    URLSearchParamsInit,
} from 'react-router-dom';
import DateRangePicker from '@mega/core/src/DatePicker/DateRangePicker';
import dayjs from 'dayjs';
import { searchRecipe } from './InfounitFilters.css';
import { useLocalStorage } from 'usehooks-ts';

type Option = {
    label: string;
    value: string | number;
};

interface IMyArticlesProps {
    handleSelect: (
        type: string,
        e: Option | undefined,
    ) => void;
    current?: any;
    value: Option;
}

interface IIsRejectedInfounittask {
    handleSelect: (
        type: string,
        e: string | undefined,
    ) => void;
    current?: boolean;
    value: boolean;
}

const MyTasks = ({
    handleSelect,
    current,
    value,
}: IMyArticlesProps) => {
    const handleClick = () => {
        if (current !== value.value) {
            handleSelect('user', value);
        } else {
            handleSelect('user', undefined);
        }
    };

    return (
        <Button
            size={'large'}
            label="Мои задачи"
            classes={{
                paper: {
                    variant: 'filled',
                    color:
                        current === value.value
                            ? 'green'
                            : 'gray',
                },
            }}
            onClick={handleClick}
        />
    );
};

const IsRejectedInfounittask = ({
    handleSelect,
    current,
    value,
}: IIsRejectedInfounittask) => {
    const handleClick = () => {
        if (current) {
            handleSelect('is_rejected', 'false');
        } else {
            handleSelect('is_rejected', 'true');
        }
    };

    return (
        <Button
            size={'large'}
            label="Отказы"
            classes={{
                paper: {
                    variant: 'filled',
                    color: current ? 'green' : 'gray',
                },
            }}
            onClick={handleClick}
        />
    );
};

const ActiveFilter: React.FC<{
    text: string;
    onDelete: () => void;
}> = ({ text, onDelete }) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: 4,
                height: 24,
                padding: '6px 4px',
                background: '#F2C94C',
                borderRadius: 4,
            }}
        >
            <Typography
                style={{
                    fontSize: 14,
                    lineHeight: '120%',
                }}
            >
                {text}
            </Typography>
            <motion.div
                style={{
                    cursor: 'pointer',
                    height: 15,
                }}
                onClick={onDelete}
                whileHover={{
                    scale: 1.1,
                    transition: {
                        duration: 0.2,
                    },
                }}
            >
                <MdClose />
            </motion.div>
        </div>
    );
};

const InfounitFilters = () => {
    const [searchParams, setSearchParams] =
        useSearchParams();

    const { me } = useUserInfo();
    const timeTemplate = 'YYYY-MM-DD';

    const currentSite = useSiteStore();
    const store = useInfounittasksStore();
    const { search, clear, jumpTo } =
        useInfounittasksDispatch();

    const query = store.query;

    const cb = (
        prevInit: URLSearchParams,
    ): URLSearchParamsInit => {
        prevInit.set('page', '1');
        return prevInit.toString();
    };

    const resetPaginatorToFirstPage = () => {
        setSearchParams(cb);
        jumpTo(1);
    };

    const currentUser = {
        label: `${me?.first_name} ${me?.last_name}`,
        value: me?.id || '',
    };

    const [currentAuthor, setCurrentAuthor] = useState<
        Option | undefined
    >();

    const [currentSort, setCurrentSort] = useLocalStorage<
        string | null
    >('sorting', null);
    const [currentDateRange, setCurrentDateRange] =
        useState<[string, string] | undefined>(undefined);

    const handleSelect = async (
        type: string,
        e: Option | undefined,
    ) => {
        resetPaginatorToFirstPage();
        await search({
            [type]: e?.value || null,
        });

        if (type === 'user') {
            setCurrentAuthor(e);
        }
    };

    const handleSelectQuery = async (
        type: string,
        e: string | undefined,
    ) => {
        resetPaginatorToFirstPage();
        await search({
            [type]: e || null,
        });
    };

    const handleSelectDateRange = (dates: Date[]) => {
        const ltDate = dayjs(dates[1]).format(timeTemplate);
        const gtDate = dayjs(dates[0]).format(timeTemplate);
        setCurrentDateRange([ltDate, gtDate]);
        resetPaginatorToFirstPage();
        search({
            //@ts-ignore
            info_unit__updated_at__date__gte: gtDate,
            info_unit__updated_at__date__lte: ltDate,
        });
    };

    useEffect(() => {
        if (searchParams.get('page')) {
            setCurrentAuthor(undefined);
            search({
                user:
                    searchParams.get('user')?.toString() ||
                    undefined,
                is_rejected: 'false',
                search: searchParams
                    .get('search')
                    ?.toString(),
                article__isnull: searchParams
                    .get('article__isnull')
                    ?.toString(),
                user__isnull: searchParams
                    .get('user__isnull')
                    ?.toString(),
                info_unit__updated_at__date__gte:
                    searchParams
                        .get(
                            'info_unit__updated_at__date__gte',
                        )
                        ?.toString(),
                info_unit__updated_at__date__lte:
                    searchParams
                        .get(
                            'info_unit__updated_at__date__lte',
                        )
                        ?.toString(),
                ordering:
                    currentSort ||
                    searchParams
                        .get('ordering')
                        ?.toString(),
            });
        } else {
            search({
                is_rejected: 'false',
                ordering:
                    currentSort ||
                    searchParams
                        .get('ordering')
                        ?.toString(),
            });
        }
        return () => {
            clear();
        };
    }, []);

    const { article__isnull, user__isnull, ordering } =
        query;

    return (
        <Formik initialValues={{}} onSubmit={() => {}}>
            <Form>
                <div
                    style={{
                        display: 'flex',
                        gap: '18px',
                    }}
                >
                    <MyTasks
                        current={currentAuthor?.value}
                        handleSelect={handleSelect}
                        value={currentUser}
                    />

                    <IsRejectedInfounittask
                        current={
                            isString(query.is_rejected)
                                ? query.is_rejected ===
                                  'true'
                                    ? true
                                    : false
                                : query.is_rejected
                        }
                        handleSelect={handleSelectQuery}
                        value={
                            query.is_rejected ? true : false
                        }
                    />

                    <div
                        className={searchRecipe({
                            hasText: !!query.search,
                        })}
                    >
                        <Input
                            startIcon={<Search />}
                            color="primary"
                            dimension="small"
                            borderRadius={'small'}
                            placeholderBase={'Поиск'}
                            value={query.search || ''}
                            type="text"
                            onChange={(e) => {
                                search({
                                    search: e.target.value
                                        ? e.target.value
                                        : undefined,
                                });
                            }}
                            style={{
                                '--input-placeholder-color':
                                    '#282828',
                            }}
                        />
                    </div>

                    <Group>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: '#F4F4F4',
                                border: '1px solid #EAEAEA',
                                borderRadius: 4,
                                padding: '8px 10px',
                            }}
                        >
                            <Filter />
                            <WithTrigger
                                trigger={({
                                    handleClick,
                                }) => (
                                    <Button
                                        animate="none"
                                        classes={{
                                            paper: {
                                                color: 'gray',
                                            },
                                        }}
                                        size="large"
                                        onClick={
                                            handleClick
                                        }
                                        label="Исполнитель"
                                        endIcon={
                                            <Icon size="18">
                                                <ArrowDown />
                                            </Icon>
                                        }
                                    />
                                )}
                            >
                                <SelectSingleField
                                    classes={{
                                        paper: {
                                            color: 'dark',
                                        },
                                        select: {
                                            variant:
                                                'primary',
                                        },
                                    }}
                                    controlShouldRenderValue={
                                        false
                                    }
                                    menuIsOpen
                                    placeholder="Поиск"
                                    name="user"
                                    actionOnSelectedOption={(
                                        e,
                                    ) => {
                                        if (e) {
                                            handleSelect(
                                                'user',
                                                e,
                                            );
                                        }
                                    }}
                                    handleLoader={getOptionsRedactors(
                                        {
                                            site:
                                                currentSite?.id ||
                                                '',
                                        },
                                    )}
                                />
                            </WithTrigger>
                            <WithTrigger
                                trigger={({
                                    handleClick,
                                }) => (
                                    <Button
                                        animate="none"
                                        classes={{
                                            paper: {
                                                color: 'gray',
                                            },
                                        }}
                                        size="large"
                                        onClick={
                                            handleClick
                                        }
                                        label="Наличие статьи"
                                        endIcon={
                                            <Icon size="18">
                                                <ArrowDown />
                                            </Icon>
                                        }
                                    />
                                )}
                            >
                                <SelectSingleField
                                    classes={{
                                        paper: {
                                            color: 'dark',
                                        },
                                        select: {
                                            variant:
                                                'primary',
                                        },
                                    }}
                                    menuIsOpen
                                    controlShouldRenderValue={
                                        false
                                    }
                                    placeholder="Поиск"
                                    name="article__isnull"
                                    actionOnSelectedOption={(
                                        e,
                                    ) => {
                                        resetPaginatorToFirstPage();
                                        search({
                                            article__isnull: `${e?.value}`,
                                        });
                                    }}
                                    handleLoader={async () => [
                                        {
                                            label: 'Есть статья',
                                            value: 'false',
                                        },
                                        {
                                            label: 'Нет статьи',
                                            value: 'true',
                                        },
                                    ]}
                                />
                            </WithTrigger>
                            <WithTrigger
                                trigger={({
                                    handleClick,
                                }) => (
                                    <Button
                                        animate="none"
                                        classes={{
                                            paper: {
                                                color: 'gray',
                                            },
                                        }}
                                        size="large"
                                        onClick={
                                            handleClick
                                        }
                                        label="Наличие исполнителя"
                                        endIcon={
                                            <Icon size="18">
                                                <ArrowDown />
                                            </Icon>
                                        }
                                    />
                                )}
                            >
                                <SelectSingleField
                                    classes={{
                                        paper: {
                                            color: 'dark',
                                        },
                                        select: {
                                            variant:
                                                'primary',
                                        },
                                    }}
                                    menuIsOpen
                                    controlShouldRenderValue={
                                        false
                                    }
                                    placeholder="Поиск"
                                    name="user"
                                    actionOnSelectedOption={(
                                        e,
                                    ) => {
                                        resetPaginatorToFirstPage();
                                        search({
                                            user__isnull: `${e?.value}`,
                                        });
                                    }}
                                    handleLoader={async () => [
                                        {
                                            label: 'Есть исполнитель',
                                            value: 'false',
                                        },
                                        {
                                            label: 'Нет исполнителя',
                                            value: 'true',
                                        },
                                    ]}
                                />
                            </WithTrigger>
                            <WithTrigger
                                trigger={({
                                    handleClick,
                                }) => (
                                    <Button
                                        classes={{
                                            paper: {
                                                color: 'gray',
                                            },
                                        }}
                                        size="large"
                                        onClick={
                                            handleClick
                                        }
                                        label="По дате"
                                        endIcon={
                                            <Icon size="18">
                                                <ArrowDown />
                                            </Icon>
                                        }
                                    />
                                )}
                            >
                                <DateRangePicker
                                    onChange={
                                        handleSelectDateRange
                                    }
                                />
                            </WithTrigger>
                            <WithTrigger
                                trigger={({
                                    handleClick,
                                }) => (
                                    <Button
                                        animate="none"
                                        classes={{
                                            paper: {
                                                color: 'gray',
                                            },
                                        }}
                                        size="large"
                                        onClick={
                                            handleClick
                                        }
                                        label="Сортировка"
                                        endIcon={
                                            <Icon size="18">
                                                <ArrowDown />
                                            </Icon>
                                        }
                                    />
                                )}
                            >
                                <SelectSingleField
                                    classes={{
                                        paper: {
                                            color: 'dark',
                                        },
                                        select: {
                                            variant:
                                                'primary',
                                        },
                                    }}
                                    menuIsOpen
                                    controlShouldRenderValue={
                                        false
                                    }
                                    placeholder="Поиск"
                                    name="ordering"
                                    actionOnSelectedOption={(
                                        e,
                                    ) => {
                                        resetPaginatorToFirstPage();
                                        search({
                                            ordering: `${e?.value}`,
                                        });
                                        setCurrentSort(
                                            `${e?.value}`,
                                        );
                                    }}
                                    handleLoader={async () => [
                                        {
                                            label: 'По дате добавления, по возрастанию',
                                            value: 'created_at',
                                        },
                                        {
                                            label: 'По дате добавления, по убыванию',
                                            value: '-created_at',
                                        },
                                    ]}
                                />
                            </WithTrigger>
                        </div>
                    </Group>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 8,
                        marginTop: 10,
                    }}
                >
                    {currentAuthor && (
                        <ActiveFilter
                            onDelete={() =>
                                handleSelect(
                                    'user',
                                    undefined,
                                )
                            }
                            text={`${
                                currentAuthor.label.split(
                                    ' ',
                                )[1]
                            } ${
                                currentAuthor.label.split(
                                    ' ',
                                )[0][0]
                            }.`}
                        />
                    )}
                    {article__isnull && (
                        <ActiveFilter
                            onDelete={() =>
                                handleSelect(
                                    'article__isnull',
                                    undefined,
                                )
                            }
                            text={
                                article__isnull === 'true'
                                    ? 'Нет статьи'
                                    : 'Есть статья'
                            }
                        />
                    )}
                    {user__isnull && (
                        <ActiveFilter
                            onDelete={() =>
                                handleSelect(
                                    'user__isnull',
                                    undefined,
                                )
                            }
                            text={
                                user__isnull === 'true'
                                    ? 'Нет исполнителя'
                                    : 'Есть исполнитель'
                            }
                        />
                    )}
                    {currentDateRange && (
                        <ActiveFilter
                            onDelete={() => {
                                handleSelect(
                                    'info_unit__updated_at__date__gte',
                                    undefined,
                                );
                                handleSelect(
                                    'info_unit__updated_at__date__lte',
                                    undefined,
                                );
                                setCurrentDateRange(
                                    undefined,
                                );
                            }}
                            text={`${dayjs(
                                currentDateRange[1],
                            ).format(
                                'DD.MM.YYYY',
                            )} - ${dayjs(
                                currentDateRange[0],
                            ).format('DD.MM.YYYY')}`}
                        />
                    )}
                    {ordering && (
                        <ActiveFilter
                            onDelete={() => {
                                handleSelect(
                                    'ordering',
                                    undefined,
                                );
                                setCurrentSort(null);
                            }}
                            text={
                                ordering === 'created_at'
                                    ? 'По дате добавления, по возрастанию'
                                    : 'По дате добавления, по убыванию'
                            }
                        />
                    )}
                </div>
            </Form>
        </Formik>
    );
};

export { InfounitFilters };
export default InfounitFilters;
