import React, {
    createContext,
    FC,
    ReactElement,
    RefObject,
} from 'react';

interface MainLayoutContextProps {
    headerRef: RefObject<HTMLDivElement> | null;
    bottomRef: RefObject<HTMLDivElement> | null;
}

export const LayoutContext =
    createContext<MainLayoutContextProps | null>(null);

export interface MainLayoutContextProviderProps {
    headerRef: RefObject<HTMLDivElement> | null;
    bottomRef: RefObject<HTMLDivElement> | null;
    children: ReactElement | ReactElement[];
}

export const MainLayoutContextProvider: FC<
    MainLayoutContextProviderProps
> = ({ headerRef, bottomRef, children }) => (
    <LayoutContext.Provider
        value={{
            headerRef,
            bottomRef,
        }}
    >
        {children}
    </LayoutContext.Provider>
);
