/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Control } from '../../../../types/core';
import { ArticleList } from './ArticleList';
import {
    ArticleListEnum,
    ArticleListPluginDefault,
} from './ArticleList.types';
import {
    PLUGIN_ARTICLE_NAME,
    PLUGIN_AUTO_ARTICLE_NAME,
} from '../../Content';

const PLUGIN_ARTICLE_LIST_NAME = ArticleListEnum.type;

const DEFAULT_ARTICLE_LIST: ArticleListPluginDefault = {
    type: PLUGIN_ARTICLE_LIST_NAME,
    meta: {},
    isContainer: true,
    acceptedTypes: [
        PLUGIN_ARTICLE_NAME,
        PLUGIN_AUTO_ARTICLE_NAME,
    ],
    childrenElement: [],
};

const PLUGIN_ARTICLE_LIST = {
    [PLUGIN_ARTICLE_LIST_NAME]: {
        emptyObject: DEFAULT_ARTICLE_LIST,
        render: ArticleList,
    },
};

const PLUGIN_ARTICLE_LIST_CONTROL: Control = {
    name: PLUGIN_ARTICLE_LIST_NAME,
    label: 'Article List',
    tooltip: 'Создать Flow Stack',
    Icon: () => <></>,
};

export {
    DEFAULT_ARTICLE_LIST,
    PLUGIN_ARTICLE_LIST,
    PLUGIN_ARTICLE_LIST_CONTROL,
    PLUGIN_ARTICLE_LIST_NAME,
};
