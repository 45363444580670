import React, { useEffect } from 'react';
import { ListContent, Pagination } from '@mega/ui';

import {
    ListingEmployees,
    NoResultsFoundMessage,
} from '@mega/core';
import {
    useEmployeesDispatch,
    useEmployeesStore,
} from '@mega/store';

interface EmployeesListing {}

export const EmployeesListing: React.FC<
    EmployeesListing
> = (): JSX.Element => {
    const store = useEmployeesStore();
    const { jumpTo } = useEmployeesDispatch();

    const employeesListName = 'employeesList';
    const allEmployeesCount = store.meta.count || 0;
    const employeesPerPage = store.query.per_page || 1;
    const allPagesCount = Math.ceil(
        allEmployeesCount / employeesPerPage,
    );

    const hasPage = store.pages['1'] ? true : false;
    const isItLoadingNow = store.loading.get.loading;
    const isPageEmpty =
        !isItLoadingNow &&
        hasPage &&
        store.pages['1'].length === 0;

    useEffect(() => {
        if (!isItLoadingNow) {
            window.scrollTo(0, 0);
        }
    }, [isItLoadingNow]);
    return (
        <>
            <ListContent name={employeesListName}>
                {() => {
                    return (
                        <>
                            <ListingEmployees />
                        </>
                    );
                }}
            </ListContent>
            <NoResultsFoundMessage
                isPageEmpty={isPageEmpty}
            />
            <Pagination
                pageCount={allPagesCount}
                jumpTo={jumpTo}
                loading={isItLoadingNow}
            />
        </>
    );
};
