import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';
import { createSelector } from 'reselect';
import { RootModel } from '../../models/rootModel';
import { RematchRootState } from '@rematch/core';
import { RoomUser } from '../../models/type';

const selectMe = (state: RematchRootState<RootModel>) =>
    state.user.me?.id;

const selectArticleRoom = (
    state: RematchRootState<RootModel>,
) => state.articleRoom;

const selectArticleRoomInfo = createSelector(
    selectMe,
    selectArticleRoom,
    (id, articleRoom) => {
        const separateUsers = articleRoom.rooms.reduce<{
            currentEditor: RoomUser | null;
            readonlyUsers: Array<RoomUser>;
        }>(
            (acc, user) => {
                let currentEditor = null;
                let readonlyUsers = null;

                if (!user.read_only) {
                    currentEditor = user;
                }

                if (user.read_only) {
                    readonlyUsers = user;
                }

                return {
                    ...acc,
                    ...(currentEditor
                        ? { currentEditor }
                        : {}),
                    readonlyUsers: [
                        ...acc.readonlyUsers,
                        ...(readonlyUsers ? [user] : []),
                    ],
                };
            },
            {
                currentEditor: null,
                readonlyUsers: [],
            },
        );
        return {
            isInit: articleRoom.isInit,
            isReadonly:
                separateUsers.currentEditor?.id !== id,
            ...separateUsers,
        };
    },
);

const useArticleRoom = () => {
    const info = useStoreSelector(selectArticleRoomInfo);
    return info;
};

const useArticleRoomDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        checkin: dispatch.articleRoom.checkin,
        clear: dispatch.article.clear,
    };
};

export { useArticleRoomDispatch, useArticleRoom };
