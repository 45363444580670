import React, { FC, useEffect, useMemo } from 'react';
import { Column, CellValue } from 'react-table';
import type { Categories as CategoriesType } from '@mega/api';
import { ListingTable } from '@mega/ui';
import { typographyClasses } from '@mega/styles';
import {
    useCategoriesDispatch,
    useCategoriesStore,
} from '@mega/store';
import { TitleColumn, PublicationColumn } from '../Columns';
import { headItem, listingRecipe } from '../Listing.css';

export interface ListingCategroiesProps {}

const ListingCategroies: FC<
    ListingCategroiesProps
> = () => {
    const store = useCategoriesStore();
    const { get, clear } = useCategoriesDispatch();

    useEffect(() => {
        get({});
        return () => {
            clear();
        };
    }, [get, clear]);

    const columns: Column<CategoriesType>[] = React.useMemo(
        () => [
            {
                Header: 'Заголовок',
                accessor: (row) => {
                    return {
                        title: row.title,
                        id: row.id,
                    };
                },
                Cell: ({ value }: CellValue) => (
                    <TitleColumn {...value} />
                ),
                minWidth: 200,
                width: '100%',
            },
            {
                Header: 'Публикация',
                accessor: (row) => {
                    return {
                        data: row.updated_at,
                        pubOn: row.is_active,
                    };
                },

                Cell: ({ value }: CellValue) => {
                    return <PublicationColumn {...value} />;
                },
            },
        ],
        [],
    );
    const data = useMemo(() => {
        const values =
            store.pages?.[store.currentPage] || [];
        return Object.values(values).flat();
    }, [store.pages]);

    return (
        <div>
            <ListingTable
                getCellProps={(cellInfo) => {
                    return {
                        style: {},
                    };
                }}
                getHeaderGroupProps={(cellInfo) => {
                    return {
                        className: listingRecipe({
                            header: true,
                        }),
                    };
                }}
                getHeaderProps={(cellInfo) => {
                    return {
                        className: headItem({
                            title:
                                cellInfo?.id ===
                                'Заголовок',
                        }),
                        style: {},
                    };
                }}
                getRowGroupProps={() => {
                    return {
                        className: [
                            typographyClasses.recipe({
                                size: '14',
                            }),
                            listingRecipe({
                                header: false,
                            }),
                        ].join(' '),
                    };
                }}
                columns={columns}
                data={data}
            />
        </div>
    );
};

export { ListingCategroies };
