import React, {
    FC,
    useEffect,
    useMemo,
    CSSProperties,
} from 'react';

import { Column, CellValue } from 'react-table';
import type { StreamEntity } from '@mega/api';
import { ListingTable } from '@mega/ui';
import { typographyClasses } from '@mega/styles';
import {
    useStreamsDispatch,
    useStreamsStore,
} from '@mega/store';
import { headItem, listingRecipe } from '../Listing.css';
import { TitleColumn, PublicationColumn } from '../Columns';
import { format } from 'date-fns';

export interface ListingStreamsProps {}

const centred = {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
} as CSSProperties;

const ListingStreams: FC<ListingStreamsProps> = () => {
    const store = useStreamsStore();
    const { get, clear } = useStreamsDispatch();

    useEffect(() => {
        get({});
        return () => {
            clear();
        };
    }, [get, clear]);

    const columns: Column<StreamEntity>[] = React.useMemo(
        () => [
            {
                Header: 'Заголовок',
                accessor: (row) => {
                    return {
                        title: row.title,
                        id: row.id,
                    };
                },
                Cell: ({ value }: CellValue) => (
                    <TitleColumn {...value} />
                ),
                minWidth: 200,
                width: '100%',
            },
            {
                Header: 'Активно',
                accessor: (row) => {
                    return {
                        pubOn: row.is_active,
                    };
                },

                Cell: ({ value }: CellValue) => {
                    return (
                        <div style={centred}>
                            <PublicationColumn {...value} />
                        </div>
                    );
                },
            },

            {
                Header: 'Создано',
                accessor: (row) => row.created_at,
                Cell: ({ value }: CellValue) => {
                    return (
                        <div style={centred}>
                            {format(
                                new Date(value),
                                'dd-MM-yyyy HH:mm',
                            )}
                        </div>
                    );
                },
            },
            {
                Header: 'Обновлено',
                accessor: (row) => row.updated_at,
                Cell: ({ value }: CellValue) => {
                    return (
                        <div style={centred}>
                            {format(
                                new Date(value),
                                'dd-MM-yyyy HH:mm',
                            )}
                        </div>
                    );
                },
            },
            {
                Header: 'Вещание началось',
                accessor: (row) => row.stream_start,
                Cell: ({ value }: CellValue) => {
                    return (
                        <div style={centred}>
                            {format(
                                new Date(value),
                                'dd-MM-yyyy HH:mm',
                            )}
                        </div>
                    );
                },
            },
            {
                Header: 'Вещание закончилось',
                accessor: (row) => row.stream_end,
                Cell: ({ value }: CellValue) => {
                    return (
                        <div style={centred}>
                            {format(
                                new Date(value),
                                'dd-MM-yyyy HH:mm',
                            )}
                        </div>
                    );
                },
            },
        ],
        [],
    );
    const data = useMemo(() => {
        const values =
            store.pages?.[store.currentPage] || [];
        return Object.values(values).flat();
    }, [store.pages]);

    return (
        <>
            <ListingTable
                getHeaderGroupProps={(cellInfo) => {
                    return {
                        className: listingRecipe({
                            header: true,
                        }),
                    };
                }}
                getHeaderProps={(cellInfo) => {
                    return {
                        className: headItem({
                            title:
                                cellInfo?.id ===
                                'Заголовок',
                        }),
                    };
                }}
                getRowGroupProps={() => {
                    return {
                        className: [
                            typographyClasses.recipe({
                                size: '14',
                            }),
                            listingRecipe({
                                header: false,
                            }),
                        ].join(' '),
                    };
                }}
                columns={columns}
                data={data}
            />
        </>
    );
};

export { ListingStreams };
