export const youTubeEmbed = () => {
    const containers = document.querySelectorAll(
        '.article-youtube-sharing:empty',
    );

    if (containers.length === 0) return;

    const renderYoutube = (
        container: Element,
        source?: string,
    ) => {
        const iframe = document.createElement('iframe');
        const a = document.createElement('a');

        const regx = /[?&]v=([^&]+)/;
        if (source) {
            a.href = source;
            const videoId = source.match(regx);

            const idEmbed =
                a.pathname === '/watch'
                    ? `/${videoId?.[1]}`
                    : a.pathname;

            iframe.setAttribute('width', '560');
            iframe.setAttribute('height', '315');
            iframe.setAttribute(
                'src',
                `https://www.youtube.com/embed${idEmbed}`,
            );
            iframe.setAttribute(
                'title',
                'YouTube video player',
            );
            iframe.setAttribute('frameborder', '0');
            iframe.setAttribute(
                'allow',
                'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share',
            );
            iframe.setAttribute('allowfullscreen', '0');
            container.appendChild(iframe);
        }
    };

    containers.forEach((container: Element) => {
        if (container instanceof HTMLElement) {
            renderYoutube(
                container,
                container.dataset.youtubeSource,
            );
        }
    });
};
