import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';

const useTagStore = () => {
    const store = useStoreSelector(
        (state: RootState) => state.tag,
    );
    const loading = useStoreSelector(
        (state: RootState) => state.loading.effects.tag,
    );

    return {
        store,
        loading,
    };
};

const useTagDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        get: dispatch.tag.get,
        put: dispatch.tag.put,
        create: dispatch.tag.create,
        update: dispatch.tag.update,
        remove: dispatch.tag.remove,
    };
};

export { useTagStore, useTagDispatch };
