/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
    Control,
    PositionsVariants,
} from '@apostroph/types';
import { Gallery } from './Gallery';
import {
    GalleryPluginDefault,
    GalleryEnum,
} from './Gallery.types';
import CollectionsIcon from '@mui/icons-material/Collections';
const PLUGIN_GALLERY_NAME = GalleryEnum.type;

const DEFAULT_GALLERY: GalleryPluginDefault = {
    type: PLUGIN_GALLERY_NAME,
    meta: {
        images: [],
    },
    childrenElement: [],
};

const PLUGIN_GALLERY = {
    [PLUGIN_GALLERY_NAME]: {
        emptyObject: DEFAULT_GALLERY,
        render: Gallery,
    },
};

const PLUGIN_GALLERY_CONTROL: Control = {
    name: PLUGIN_GALLERY_NAME,
    label: 'Галлерея',
    tooltip: 'Создать Галлерею',
    position: PositionsVariants.actionFeed,
    Icon: () => <CollectionsIcon />,
};

export {
    DEFAULT_GALLERY,
    PLUGIN_GALLERY,
    PLUGIN_GALLERY_CONTROL,
    PLUGIN_GALLERY_NAME,
};
