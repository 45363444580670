import React, { FC } from 'react';

import { BlockProps } from './Block.types';

const Block: FC<BlockProps> = ({ children }) => {
    return <>{children}</>;
};

export { Block };
export default Block;
