import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgTime = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M6 11A5 5 0 1 0 6 1a5 5 0 0 0 0 10Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6 3.272V6l1.818 1.818"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgTime);
export default ForwardRef;
