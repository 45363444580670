import React, { FC, useEffect } from 'react';
import { MainTemplateProps } from './Main.types';
import { PluginViewWrapper } from '@pages/smartGrid/components/shared';
import { useEditor } from '@pages/smartGrid/store/hooks';
import { getIdArray } from '@pages/smartGrid/helpers/getIdArray';
import {
    DEFAULT_ADS,
    DEFAULT_ARTICLE_LIST,
    DEFAULT_CAROUSEL,
    DEFAULT_FLOW_ROW,
    DEFAULT_FLOW_STACK,
    DEFAULT_NUMOFTHEDAY,
} from '@pages/smartGrid/components/Plugins';

const getConfig = (...args: string[]) => {
    return {
        [args[0]]: {
            id: args[0],
            ...DEFAULT_FLOW_STACK,
            childrenElement: [args[1], args[7], args[8]],
        },
        [args[1]]: {
            id: args[1],
            ...DEFAULT_FLOW_ROW,
            childrenElement: [args[2], args[3], args[4]],
        },
        [args[2]]: {
            id: args[2],
            ...DEFAULT_FLOW_STACK,
        },
        [args[3]]: {
            id: args[3],
            ...DEFAULT_ARTICLE_LIST,
        },
        [args[4]]: {
            id: args[4],
            ...DEFAULT_FLOW_STACK,
            childrenElement: [args[5], args[6]],
        },
        [args[5]]: {
            id: args[5],
            ...DEFAULT_ADS,
        },
        [args[6]]: {
            id: args[6],
            ...DEFAULT_NUMOFTHEDAY,
        },
        [args[7]]: {
            id: args[7],
            ...DEFAULT_CAROUSEL,
        },
        [args[8]]: {
            id: args[8],
            ...DEFAULT_ADS,
        },
    };
};

const Main: FC<MainTemplateProps> = (props) => {
    const { children, meta, id, parentId } = props;

    const { handleChangeElements } = useEditor();

    useEffect(() => {
        const idArr = getIdArray(9);
        const payload = getConfig(...idArr);

        if (parentId) {
            handleChangeElements({
                payload,
                id,
                parentId,
                newId: idArr[0],
            });
        }
    }, []);

    return (
        <PluginViewWrapper
            id={id}
            parentId={parentId}
            dynHeight={'max-content'}
            title={'Главный блок'}
        >
            {children}
        </PluginViewWrapper>
    );
};

export { Main };
