import { useState } from 'react';

export interface PaginationProps {
    count: number;
    perPage?: number;
}
export const usePagination = (props: PaginationProps) => {
    const { count, perPage = 10 } = props;
    const [page, setPage] = useState(1);

    const totalPages = Math.ceil(count / perPage) || 1;
    return { page, setPage, totalPages };
};
