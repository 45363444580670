import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import { $findMatchingParent } from '@lexical/utils';
import {
    $getSelection,
    $isRangeSelection,
    $isRootOrShadowRoot,
    COMMAND_PRIORITY_CRITICAL,
    SELECTION_CHANGE_COMMAND,
} from 'lexical';
import {
    ReactElement,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import useModal from '../hooks/useModal';

import { InsertTableDialog } from '../Table/TablePlugin';

import { $isAtNodeEnd } from '@lexical/selection';
import {
    ElementNode,
    RangeSelection,
    TextNode,
} from 'lexical';
import { useLexContext } from '../../../context/lex.context';
import { createPortal } from 'react-dom';
import { IconButton } from '@mega/ui';
import { Table } from '@mega/icons';
import { Tooltip } from '@mui/material';
export const CAN_USE_DOM: boolean =
    typeof window !== 'undefined' &&
    typeof window.document !== 'undefined' &&
    typeof window.document.createElement !== 'undefined';

export const IS_APPLE: boolean =
    CAN_USE_DOM &&
    /Mac|iPod|iPhone|iPad/.test(navigator.platform);

const SUPPORTED_URL_PROTOCOLS = new Set([
    'http:',
    'https:',
    'mailto:',
    'sms:',
    'tel:',
]);
export function getSelectedNode(
    selection: RangeSelection,
): TextNode | ElementNode {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
        return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
        return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    } else {
        return $isAtNodeEnd(anchor)
            ? anchorNode
            : focusNode;
    }
}

export function sanitizeUrl(url: string): string {
    try {
        const parsedUrl = new URL(url);
        // eslint-disable-next-line no-script-url
        if (
            !SUPPORTED_URL_PROTOCOLS.has(parsedUrl.protocol)
        ) {
            return 'about:blank';
        }
    } catch {
        return url;
    }
    return url;
}

export default function ToolbarPlugin(): JSX.Element {
    const lexContext = useLexContext();
    const [editor] = useLexicalComposerContext();
    const [activeEditor, setActiveEditor] =
        useState(editor);

    const [modal, showModal] = useModal();

    const $updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            let element =
                anchorNode.getKey() === 'root'
                    ? anchorNode
                    : $findMatchingParent(
                          anchorNode,
                          (e) => {
                              const parent = e.getParent();
                              return (
                                  parent !== null &&
                                  $isRootOrShadowRoot(
                                      parent,
                                  )
                              );
                          },
                      );

            if (element === null) {
                element =
                    anchorNode.getTopLevelElementOrThrow();
            }
        }
    }, [activeEditor, editor]);

    useEffect(() => {
        return editor.registerCommand(
            SELECTION_CHANGE_COMMAND,
            (_payload, newEditor) => {
                setActiveEditor(newEditor);
                $updateToolbar();
                return false;
            },
            COMMAND_PRIORITY_CRITICAL,
        );
    }, [editor, $updateToolbar]);

    useEffect(() => {
        activeEditor.getEditorState().read(() => {
            $updateToolbar();
        });
    }, [activeEditor, $updateToolbar]);

    if (!lexContext.menu?.current) {
        return <></>;
    }
    return createPortal(
        <div className="toolbar">
            <ToolbarItem
                onClick={() => {
                    showModal(
                        'Вставить таблицу',
                        (onClose) => (
                            <InsertTableDialog
                                activeEditor={activeEditor}
                                onClose={onClose}
                            />
                        ),
                    );
                }}
                className="item"
            >
                <Table />
            </ToolbarItem>

            {modal}
        </div>,
        lexContext.menu?.current,
    );
}

export function ToolbarItem({
    children,
    onClick,
}: {
    children: ReactElement;
    className: string;
    onClick: (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => void;
    title?: string;
}) {
    return (
        <Tooltip title={'Вставить таблицу'} placement="top">
            <div>
                <IconButton
                    type="button"
                    classes={{
                        paper: {
                            variant: 'filled',
                            color: 'dark',
                        },
                    }}
                    size="medium"
                    onClick={onClick}
                >
                    {children}
                </IconButton>
            </div>
        </Tooltip>
    );
}
