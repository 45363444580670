import { createModel } from '@rematch/core';
import type { RootModel } from '../rootModel';

import { Dispatch } from '../';
import { debounce } from 'lodash-es';

type ScreenType =
    | 'mobile'
    | 'largeMobile'
    | 'tablet'
    | 'laptop'
    | 'desktop'
    | 'init';

const layoutObserverConnector = (
    updater: (screen: ScreenType) => void,
) =>
    new ResizeObserver((entries) => {
        for (let entry of entries) {
            if (entry.contentBoxSize) {
                const inlineSize =
                    entry.contentBoxSize[0].inlineSize;
                const isMobile = inlineSize < 480;
                const isLargeMobile =
                    !isMobile &&
                    inlineSize >= 480 &&
                    inlineSize < 768;
                const isTablet =
                    !isMobile &&
                    !isLargeMobile &&
                    inlineSize >= 768 &&
                    inlineSize < 992;
                const isLaptop =
                    !isMobile &&
                    !isLargeMobile &&
                    !isTablet &&
                    inlineSize >= 992 &&
                    inlineSize < 1200;
                const isDesktop =
                    !isMobile &&
                    !isLargeMobile &&
                    !isTablet &&
                    !isLaptop &&
                    inlineSize >= 1200;

                if (isMobile) {
                    return updater('mobile');
                }
                if (isLargeMobile) {
                    return updater('largeMobile');
                }
                if (isTablet) {
                    return updater('tablet');
                }
                if (isLaptop) {
                    return updater('laptop');
                }
                if (isDesktop) {
                    return updater('desktop');
                }
                return updater('init');
            }
        }
    });

class LayoutObserver {
    observer: ResizeObserver;
    type: ScreenType;
    dispatch: Dispatch;

    constructor(
        tag: HTMLDivElement,
        observer: (
            updater: (screen: ScreenType) => void,
        ) => ResizeObserver,
        dispatch: Dispatch,
    ) {
        this.trigger = debounce(
            this.trigger.bind(this),
            64,
        );
        this.observer = observer(this.trigger);
        this.observer.observe(tag);
        this.dispatch = dispatch;
        this.type = 'init';
    }

    trigger(screen: ScreenType) {
        if (screen !== this.type) {
            this.type = screen;
            this.dispatch.layout.update(screen);
        }
    }
}

type LayoutState = {
    layoutType: ScreenType;
};

const layout = createModel<RootModel>()({
    name: 'layout',
    state: {
        layoutType: 'init',
    } as LayoutState,
    reducers: {
        update(_, payload: ScreenType) {
            return { layoutType: payload };
        },
    },
});

export { layout, LayoutObserver, layoutObserverConnector };
export default layout;
