import { call, delay, put } from 'redux-saga/effects';
import {
    NOTIFY_MODULE,
    GET_AUTH_NOTIFY_CATCH,
} from './actions';

function* NotifySaga() {
    while (true) {
        yield put({
            type: `${NOTIFY_MODULE}/${GET_AUTH_NOTIFY_CATCH}`,
        });
        yield delay(10000);
    }
}

export { NotifySaga };
