import React, { FC, useEffect, useState } from 'react';

import { $isTextNode, EditorState } from 'lexical';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';

import Nodes from './Nodes/EditorNodes';
import { TextFormatFloatingToolbarPlugin } from './Plugins/CharacterStylesPopupPlugin';
import TableActionMenuPlugin from './Plugins/Table/TableActionMenu/TableActionMenuPlugin';
import { theme as themeEditor } from './Theme';
import { ContentCutCommand } from '../type';
import { typographyClasses } from '@mega/styles';
import { CounterInitPlugin } from './Plugins/CounterInitPlugin';
import { TypographAllPlugin } from './Plugins/TypographAllPlugin';
import { SearchInAgent } from './Plugins/SearchInAgent';
import { PastePlugin } from './Plugins/PastePlugin';
import { editorStyle } from './Editor.css';
import { useLexDataContext } from '../context/lexData.context';
import { useAphostrophExternalDataContext } from '../../../feature/context/AphostrophExternalData';
import { useArticleRoom } from '@mega/store';
import { SetInAgent } from '../Lex.types';
import ToolbarPlugin from './Plugins/Toolbar/Toolbar';

export interface RichEditorProps extends ContentCutCommand {
    content?: string;
    onChange?: (props: string) => void;
    setInAgent?: SetInAgent;
    isLead?: boolean;
}

const RichEditor: FC<RichEditorProps> = ({
    handleContentCut,
    content = '',
    onChange,
    setInAgent,
    isLead = false,
}) => {
    const { isReadonly } = useArticleRoom();
    const lexData = useLexDataContext();
    const externalData = useAphostrophExternalDataContext();
    const initialConfig = {
        namespace: 'PlaygroundEditor',
        nodes: [...Nodes],
        theme: themeEditor,
        ...(content
            ? {
                  editorState:
                      typeof content === 'string'
                          ? content
                          : JSON.stringify(content),
              }
            : {}),
        //@ts-ignore
        onError: (error) => {
            throw error;
        },
    };

    const [floatingAnchorElem, setFloatingAnchorElem] =
        useState<HTMLDivElement | null>(null);

    const onRef = (_floatingAnchorElem: HTMLDivElement) => {
        if (_floatingAnchorElem !== null) {
            setFloatingAnchorElem(_floatingAnchorElem);
        }
    };

    const handleChange = (editorState: EditorState) => {
        editorState.read(() => {
            let textNodes = { gliphs: 0 };
            editorState._nodeMap.forEach((node) => {
                if ($isTextNode(node)) {
                    const spaces = node
                        .exportJSON()
                        .text.replace(/\s+/g, ' ')
                        .split('')
                        .filter(
                            (item) => item === ' ',
                        ).length;

                    const { gliphs } = node
                        .exportJSON()
                        .text.replace(/\s+/g, ' ')
                        .split(' ')
                        .reduce<{
                            gliphs: number;
                            previus: null | string;
                        }>(
                            (acc, item) => {
                                item;
                                return {
                                    gliphs:
                                        acc.gliphs +
                                        item.split('')
                                            .length,
                                    previus: item,
                                };
                            },
                            {
                                gliphs: 0 + spaces,
                                previus: null,
                            },
                        );
                    textNodes = {
                        gliphs: textNodes.gliphs + gliphs,
                    };
                }
            });
            if (externalData?.setGliphs && lexData?.id) {
                externalData.setGliphs(
                    lexData.id,
                    textNodes,
                );
            }

            editorState.toJSON();
            onChange?.(JSON.stringify(editorState));
        });
    };

    useEffect(() => {
        return () => {
            if (externalData?.setGliphs && lexData?.id) {
                externalData.setGliphs(lexData.id, {
                    gliphs: 0,
                });
            }
        };
    }, []);

    return (
        <LexicalComposer
            key={`${isReadonly}`}
            initialConfig={{
                ...initialConfig,
                editable: !isReadonly,
            }}
        >
            <div style={{ position: 'relative' }}>
                {!isLead && <ToolbarPlugin />}
                {!isLead && <TablePlugin />}
                {!isLead && floatingAnchorElem && (
                    <TableActionMenuPlugin
                        cellMerge={true}
                        anchorElem={floatingAnchorElem}
                    />
                )}
                <RichTextPlugin
                    contentEditable={
                        <div ref={onRef}>
                            <ContentEditable
                                className={`${editorStyle} editableRichText`}
                            />
                        </div>
                    }
                    ErrorBoundary={LexicalErrorBoundary}
                    placeholder={
                        <div
                            style={{
                                pointerEvents: 'none',
                                position: 'absolute',
                                top: 0,
                                color: '#878787',
                            }}
                            className={typographyClasses.recipe(
                                {
                                    size: '16',
                                    weight: 'regular',
                                },
                            )}
                        >
                            Пиши в меня
                        </div>
                    }
                />
                <OnChangePlugin onChange={handleChange} />
                <HistoryPlugin />

                <LinkPlugin />
                <ListPlugin />
                <CounterInitPlugin
                    handleChange={handleChange}
                />
                <CheckListPlugin />

                <TypographAllPlugin />
                <SearchInAgent setInAgent={setInAgent} />
                <PastePlugin />
                {!isReadonly && (
                    <TextFormatFloatingToolbarPlugin
                        handleContentCut={handleContentCut}
                    />
                )}
            </div>
        </LexicalComposer>
    );
};

export { RichEditor };
