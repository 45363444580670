import React, {
    PropsWithChildren,
    ReactElement,
} from 'react';
import {
    components,
    IndicatorsContainerProps,
} from 'react-select';

interface IndClass {
    className?: string;
    removeIcon?: ReactElement | null;
    sendIcon?: ReactElement | null;
}

type IndicatorsContainerOverrideProps =
    IndicatorsContainerProps<Record<string, unknown>, true>;

export const IndicatorsContainer =
    ({ className = '', removeIcon, sendIcon }: IndClass) =>
    ({
        ...props
    }: PropsWithChildren<IndicatorsContainerOverrideProps>) =>
        (
            <>
                <components.IndicatorsContainer
                    className={className}
                    {...props}
                >
                    {removeIcon}
                </components.IndicatorsContainer>
                {sendIcon}
            </>
        );
