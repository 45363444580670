import React, { useEffect } from 'react';
import { Form, useFormikContext } from 'formik';
import { MainMeta } from '../Main.types';
import { Stack } from '@mega/ui';
import { InputTextareaField } from '@mega/ui/src/components/Inputs/InputTextarea';
import { useApostrophStore } from '@mega/store';
import { getTypographedText } from '@mega/utils';

export interface MainProps {
    handleUpdate: (data: MainMeta) => void;
}

export const MainForm: React.FC<MainProps> = (props) => {
    const { handleUpdate } = props;
    const { values, handleChange, setFieldValue } =
        useFormikContext<MainMeta>();
    const { isFormatted } = useApostrophStore();

    useEffect(() => {
        handleUpdate(values);
    }, [values]);

    const fieldArray = Object.keys(values);
    useEffect(() => {
        if (isFormatted) {
            fieldArray.forEach((field) => {
                setFieldValue(
                    field,
                    getTypographedText(
                        values[field as keyof MainMeta],
                    ),
                );
            });
        }
    }, [isFormatted]);

    return (
        <Form>
            <Stack>
                <InputTextareaField
                    name="text"
                    value={values.text}
                    onChange={handleChange}
                    placeholderDefault={'Сообщение'}
                    rows={2}
                    classes={{
                        paper: {
                            color: undefined,
                            variant: undefined,
                        },
                    }}
                    textAlign={'center'}
                    color={'transparent'}
                />
            </Stack>
        </Form>
    );
};
