import React, { FC, useMemo } from 'react';
import { useTable } from 'react-table';
import {
    Link,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import { Typography, IconButton } from '@mega/ui';
import { articleMultipostedTableClasses } from './ArticleMultipostedTable.css';
import { ArrowRight, PubOff, PubOn } from '@mega/icons';
import { useArticleMultipostStore } from '@mega/store';

const formatDateString = (dateString: string): string => {
    const [date, time] = dateString.split('T');
    const [year, month, day] = date.split('-');
    const formattedDate = `${day}.${month}.${year} в ${time}`;
    return formattedDate;
};

const ArticleMultipostedTable: FC = () => {
    const { data: dataStore } = useArticleMultipostStore();
    const data = dataStore ? dataStore.copies : [];
    if (data.length === 0) {
        return <></>;
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Сайт',
                accessor: 'site',
                Cell: ({ value }) => {
                    return <p>{value.label}</p>;
                },
            },
            {
                Header: () => (
                    <p style={{ textAlign: 'center' }}>
                        Статус
                    </p>
                ),
                accessor: 'is_active',
                Cell: ({ value }: { value: boolean }) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent:
                                    'space-around',
                            }}
                        >
                            {value ? (
                                <PubOn
                                    style={{
                                        width: '24px',
                                        height: '24px',
                                    }}
                                />
                            ) : (
                                <PubOff
                                    style={{
                                        width: '24px',
                                        height: '24px',
                                    }}
                                />
                            )}
                        </div>
                    );
                },
            },
            {
                Header: () => (
                    <p style={{ textAlign: 'center' }}>
                        Время
                    </p>
                ),
                accessor: 'pub_date',
                Cell: ({ value }) => {
                    return (
                        <p style={{ textAlign: 'center' }}>
                            {formatDateString(value)}
                        </p>
                    );
                },
            },
            {
                Header: () => (
                    <p style={{ textAlign: 'center' }}>
                        Смотреть
                    </p>
                ),
                accessor: 'link',
                Cell: ({ row }) => {
                    return (
                        <Link
                            to={`/${row.original.site.id}/article/change/${row.original.id}`}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                            target="_blank"
                        >
                            <IconButton
                                classes={{
                                    paper: {
                                        color: 'blue',
                                    },
                                }}
                            >
                                <ArrowRight />
                            </IconButton>
                        </Link>
                    );
                },
            },
        ],
        [],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    return (
        <>
            <Typography
                size="12"
                style={{
                    color: '#878787',
                    marginBottom: '20px',
                    marginTop: '10px',
                }}
                weight="regular"
            >
                Опубликованые статьи
            </Typography>
            <table
                {...getTableProps()}
                className={
                    articleMultipostedTableClasses.table
                }
            >
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            className={
                                articleMultipostedTableClasses.tr
                            }
                        >
                            {headerGroup.headers.map(
                                (column) => (
                                    <th
                                        {...column.getHeaderProps()}
                                        className={
                                            articleMultipostedTableClasses.th
                                        }
                                    >
                                        {column.render(
                                            'Header',
                                        )}
                                    </th>
                                ),
                            )}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                                className={
                                    articleMultipostedTableClasses.tr
                                }
                            >
                                {row.cells.map((cell) => (
                                    <td
                                        {...cell.getCellProps()}
                                        className={
                                            articleMultipostedTableClasses.td
                                        }
                                    >
                                        {cell.render(
                                            'Cell',
                                        )}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};

export default ArticleMultipostedTable;
export { ArticleMultipostedTable };
