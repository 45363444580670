import React, { ReactElement } from 'react';
import { useEditor } from '@apostroph/store';
import { maybePluginKeys } from '../../plugins/types';
import { IconButton, Menu, Row } from '@mega/ui';
import {
    Add as AddIcon,
    Ads as AdsIcon,
    FormatQuote as FormatQuoteIcon,
    Gallery as GalleryIcon,
    HorizontalRule as HorizontalRuleIcon,
    Htmlcode as HtmlcodeIcon,
    Image as ImageIcon,
    Subject as SubjectIcon,
    Update as UpdateIcon,
    Videocam as VideocamIcon,
    Graph as GraphIcon,
    Reference as ReferenceIcon,
    File as FileIcon,
} from '@mega/icons';
import { FiEdit } from 'react-icons/fi';

import { Tooltip } from '@mui/material';
import { RootState, useStoreSelector } from '@mega/store';

type EditorConfig = {
    tooltip: string;
    name: maybePluginKeys;
    icon: ReactElement;
    disalable?: boolean;
};
const editorConfig: Array<EditorConfig> = [
    {
        tooltip: 'Текст',
        name: 'MILK',
        icon: <SubjectIcon />,
    },
    {
        tooltip: 'Цитата',
        name: 'BLOCKQUOTE',
        icon: <FormatQuoteIcon />,
    },
    {
        tooltip: 'Видео',
        name: 'VIDEO',
        icon: <VideocamIcon />,
    },
    {
        tooltip: 'Картинка',
        name: 'IMG',
        icon: <ImageIcon />,
    },
    {
        tooltip: 'Галерея',
        name: 'GALLERY',
        icon: <GalleryIcon />,
    },
    {
        tooltip: 'Инфографика',
        name: 'INFOGRAPH',
        icon: <GraphIcon />,
    },
    {
        tooltip: 'Блок Справочное',
        name: 'MAIN',
        icon: <ReferenceIcon />,
    },
    {
        tooltip: 'HTML код',
        name: 'HTML_TEXT',
        icon: <HtmlcodeIcon />,
    },
    {
        tooltip: 'Разделитель',
        name: 'HORIZONTAL_LINE',
        icon: <HorizontalRuleIcon />,
    },
    {
        tooltip: 'ADS источник',
        name: 'ADS_SOURCE',
        icon: <AdsIcon />,
    },
    {
        tooltip: 'Загрузить фаил',
        name: 'FILES',
        icon: <FileIcon />,
    },
    {
        tooltip: 'Карточка',
        name: 'SECTION',
        icon: <FiEdit />,
        disalable: true,
    },
    {
        tooltip: 'В процессе',
        name: 'IN_PROGRESS',
        icon: <UpdateIcon />,
    },
];
export const EditorControls: React.FC<{
    id?: string;
    parentId?: string | null;
    disableSection?: boolean;
}> = (props) => {
    const { id, parentId, disableSection = false } = props;
    const {
        editor,
        getTemplate,
        handleCreateElement,
        handleInsertElement,
    } = useEditor();
    const { config } = useStoreSelector(
        (store: RootState) => store.settings,
    );
    const contentBlock =
        parentId ||
        editor?.data?.[editor?.items?.[1]]?.id ||
        null;

    let handleCloseAction: Function;

    const handleCreate =
        (templateKey: maybePluginKeys) => () => {
            if (contentBlock) {
                const template = getTemplate(templateKey);
                if (template) {
                    if (id) {
                        handleInsertElement(
                            template,
                            id,
                            contentBlock,
                        );
                    } else {
                        handleCreateElement(
                            template,
                            contentBlock,
                        );
                    }

                    handleCloseAction();
                }
            }
        };
    return (
        <Menu
            style={{ padding: '8px' }}
            paddingTB={{ size: 'none' }}
            variant={'secondary'}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            gap="8px"
            trigger={({ handleClick, handleClose }) => {
                handleCloseAction = handleClose;
                return (
                    <IconButton
                        type="button"
                        classes={{
                            paper: {
                                variant: 'dashed',
                            },
                        }}
                        onClick={handleClick}
                    >
                        <AddIcon />
                    </IconButton>
                );
            }}
        >
            <Row gap="8">
                {editorConfig.map((item, id) => {
                    const {
                        tooltip,
                        name,
                        icon,
                        disalable,
                    } = item;

                    const isAvailable =
                        config.article.change.editor_data[
                            name
                        ];
                    if (
                        (disalable && disableSection) ||
                        !isAvailable
                    )
                        return null;
                    return (
                        <Tooltip
                            key={id}
                            title={tooltip}
                            placement="bottom"
                        >
                            <span>
                                <IconButton
                                    size={'medium'}
                                    type="button"
                                    color="secondary"
                                    onClick={handleCreate(
                                        name,
                                    )}
                                >
                                    {icon}
                                </IconButton>
                            </span>
                        </Tooltip>
                    );
                })}
            </Row>
        </Menu>
    );
};
