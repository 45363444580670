import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgLockEdition = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.375 8.379V6.667a5.625 5.625 0 0 1 11.25 0v1.712c.929.07 1.534.244 1.976.686.732.733.732 1.911.732 4.268 0 2.357 0 3.536-.732 4.268-.732.732-1.91.732-4.268.732H6.667c-2.357 0-3.536 0-4.268-.732-.732-.732-.732-1.91-.732-4.268 0-2.357 0-3.535.732-4.268.442-.442 1.047-.617 1.976-.686Zm1.25-1.712a4.375 4.375 0 0 1 8.75 0v1.67c-.32-.004-.665-.004-1.042-.004H6.667c-.377 0-.723 0-1.042.003v-1.67Zm6.042 6.666a1.667 1.667 0 1 1-3.334 0 1.667 1.667 0 0 1 3.334 0Z"
            fill="#C47FA1"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgLockEdition);
export default ForwardRef;
