import React, { useEffect } from 'react';
import { BannedOrganizationEdit as BannedOrganizationEditForm } from '@mega/forms';
import {
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';

import {
    // useSiteStore,
    useBannedOrganizationStore,
    useBannedOrganizationDispatch,
} from '@mega/store';
import { useSnackbar } from 'notistack';
import { BannedOrganization } from '@mega/api';
import { FormikHelpers } from 'formik';

const orgTypeMap = new Map()
    .set('terrorist', {
        value: 'terrorist',
        label: 'Террористы',
    })
    .set('foreign_agent', {
        value: 'foreign_agent',
        label: 'Иноагенты',
    })
    .set('extremist', {
        value: 'extremist',
        label: 'Экстремисты',
    });

export const BannedOrganizationEdit: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();

    const { id } = useParams();
    const { loading, store } = useBannedOrganizationStore();
    const { get, update } = useBannedOrganizationDispatch();
    // const currentSite = useSiteStore();

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            get({ id: parseInt(id) });
        }
    }, [id, get]);

    if (loading.get.loading || !loading.get.success) {
        return <div>loading</div>;
    }

    let editedId: number;

    if (id) {
        editedId = parseInt(id);
    } else {
        throw new Error('The editable entity has no id.');
    }

    const handleUpdate = async (
        payload: BannedOrganization,
        actions: FormikHelpers<BannedOrganization>,
    ) => {
        await update({
            query: {
                id: editedId,
            },
            payload: {
                title: payload.title,
                description: payload.description,
                id: payload.id,
                //@ts-ignore
                org_type: payload.org_type.id,
                //@ts-ignore
                synonyms: payload.synonyms.split(','),
                is_active: payload?.is_active || false,
            },
            options: {
                notifier: {
                    enqueueSnackbar,
                },
                router: {
                    navigate,
                    location,
                },
            },
        });

        actions.setSubmitting(false);
    };

    return (
        <BannedOrganizationEditForm
            handleUpdate={handleUpdate}
            initialValue={{
                ...store,
                //@ts-ignore
                synonyms: store.synonyms?.join(','),
                org_type: orgTypeMap.get(store.org_type),
            }}
        />
    );
};

// terrorist
// {
//     value: 'terrorist',
//     label: 'Террористы',
// },
// {
//     value: 'extremist',
//     label: 'Экстремисты',
// },
