import React, { FC, useEffect } from 'react';

import {
    useLocation,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import {
    Button,
    EntityListLayout,
    Input,
    ListContent,
    Pagination,
} from '@mega/ui';
import { Add, Search } from '@mega/icons';
import {
    useDocumentsDispatch,
    useDocumentsStore,
} from '@mega/store';
import { ListingDocuments } from '@mega/core/src/Listing/ListingDocuments';
import { NoResultsFoundMessage } from '@mega/core';

interface NewspapersProps {
    children?: never;
}

const Documents: FC<NewspapersProps> = () => {
    const store = useDocumentsStore();
    const { search, jumpTo } = useDocumentsDispatch();
    let navigate = useNavigate();
    let location = useLocation();
    const [, setSearchParams] = useSearchParams();

    const documentListName = 'documentList';
    const allDocumentsCount = store.meta.count || 0;
    const documentsPerPage = store.query.per_page || 1;
    const allPagesCount = Math.ceil(
        allDocumentsCount / documentsPerPage,
    );

    const resetPaginatorToFirstPage = () => {
        setSearchParams({ page: '1' });
        jumpTo(1);
    };
    const hasPage = store.pages['1'] ? true : false;
    const isItLoadingNow = store.loading.get.loading;
    const isPageEmpty =
        !isItLoadingNow &&
        hasPage &&
        store.pages['1'].length === 0;

    useEffect(() => {
        if (!isItLoadingNow) {
            window.scrollTo(0, 0);
        }
    }, [isItLoadingNow]);

    return (
        <EntityListLayout
            setting={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: '18px',
                    }}
                >
                    <div style={{ minWidth: '300px' }}>
                        <Input
                            startIcon={<Search />}
                            color="secondary"
                            dimension="medium"
                            value={
                                store?.query?.search || ''
                            }
                            type="text"
                            onChange={(e) => {
                                resetPaginatorToFirstPage();
                                return search({
                                    search: e.target.value,
                                });
                            }}
                        />
                    </div>
                    <Button
                        size={'large'}
                        endIcon={<Add />}
                        label="Добавить"
                        classes={{
                            paper: {
                                variant: 'filled',
                                color: 'dark',
                            },
                        }}
                        onClick={() => {
                            navigate(`add`, {
                                replace: true,
                                state: location,
                            });
                        }}
                    />
                </div>
            }
        >
            <>
                <ListContent name={documentListName}>
                    {({ className }) => {
                        return <ListingDocuments />;
                    }}
                </ListContent>
                <NoResultsFoundMessage
                    isPageEmpty={isPageEmpty}
                />
                <Pagination
                    pageCount={allPagesCount}
                    jumpTo={jumpTo}
                    loading={isItLoadingNow}
                />
            </>
        </EntityListLayout>
    );
};

export { Documents };
