import React, { FC, useEffect } from 'react';
import { StreamEdit as StreamEditForm } from '@mega/forms';
import { useParams } from 'react-router-dom';

import {
    useSiteStore,
    useStreamDispatch,
    useStreamStore,
} from '@mega/store';
import { useSnackbar } from 'notistack';
import { GetStream } from '@mega/api';
import { FormikHelpers } from 'formik';

export const StreamEdit: FC<{}> = () => {
    const { enqueueSnackbar } = useSnackbar();

    const { id } = useParams();
    const { loading, store } = useStreamStore();
    const { get, update } = useStreamDispatch();
    const currentSite = useSiteStore();

    useEffect(() => {
        if (id) {
            get({ payload: { id: id } });
        }
    }, [id, get]);

    if (loading.get.loading || !loading.get.success) {
        return <div>loading</div>;
    }

    let editedId: number;

    if (id) {
        editedId = parseInt(id);
    } else {
        throw new Error('The editable entity has no id.');
    }

    const handleUpdate = async (
        payload: GetStream,
        actions: FormikHelpers<GetStream>,
    ) => {
        const response = await update({
            payload: {
                site: currentSite?.id || '',
                //@ts-ignore
                id: editedId,
                title: payload.title,
                description: payload.description,
                stream_status: payload.stream_status,
                is_active: payload.is_active,
                link_redirect: payload.link_redirect,
                stream_start: payload.stream_start,
                stream_end: payload.stream_end,
            },
        });

        actions.setSubmitting(false);
        if (response.isOk) {
            enqueueSnackbar('Трансляция обновлена', {
                variant: 'success',
            });
        } else {
            if (response.value) {
                const errormsgs = Object.values(
                    response.value,
                );

                errormsgs?.map((msg) => {
                    enqueueSnackbar(msg as string, {
                        variant: 'error',
                    });
                });
            }
        }

        return response;
    };

    return (
        <StreamEditForm
            handleUpdate={handleUpdate}
            initialValue={store}
        />
    );
};
