import React, { FC, PropsWithChildren } from 'react';
import {
    components,
    MenuListProps,
    MenuProps,
} from 'react-select';
import { wrapMenuList } from 'react-select-async-paginate';
import { paperClasses } from '@mega/styles';
import { inputSelectMultiClasses } from '../../InputSelectMulti.css';

export type MenuListOverideProps = MenuListProps<
    Record<string, unknown>,
    true
>;

const CustomMenuList =
    ({ className = '' }: MenuListClass) =>
    ({
        ...props
    }: PropsWithChildren<MenuListOverideProps>) =>
        (
            <components.MenuList
                className={className}
                {...props}
            ></components.MenuList>
        );

const MenuListPrimaryCustom = ({
    ...props
}: PropsWithChildren<MenuListOverideProps>) => (
    <components.MenuList
        className={[
            inputSelectMultiClasses.menuListMultiRecipe({
                variant: 'primary',
            }),
            paperClasses.recipe({
                variant: 'outlineFilled',
                color: 'gray',
                borderRadius: 'small',
            }),
        ].join(' ')}
        {...props}
    ></components.MenuList>
);

const MenuListSecondaryCustom = ({
    ...props
}: PropsWithChildren<MenuListOverideProps>) => (
    <components.MenuList
        className={[
            inputSelectMultiClasses.menuListMultiRecipe({
                variant: 'secondary',
            }),
            paperClasses.recipe({
                variant: 'outlineFilled',
                color: 'gray',
                borderRadius: 'small',
            }),
        ].join(' ')}
        {...props}
    ></components.MenuList>
);

const MenuListFourthCustom = ({
    ...props
}: PropsWithChildren<MenuListOverideProps>) => (
    <components.MenuList
        className={[
            inputSelectMultiClasses.menuListMultiRecipe({
                variant: 'fourth',
            }),
            paperClasses.recipe({
                variant: 'outlineFilled',
                color: 'gray',
                borderRadius: 'small',
            }),
        ].join(' ')}
        {...props}
    ></components.MenuList>
);

const MenuListPrimary = wrapMenuList(MenuListPrimaryCustom);
const MenuListSecondary = wrapMenuList(
    MenuListSecondaryCustom,
);
const MenuListFourth = wrapMenuList(MenuListFourthCustom);

export {
    MenuListPrimary,
    MenuListSecondary,
    MenuListFourth,
};
export default MenuListPrimary;
