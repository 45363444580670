import React, { FC } from 'react';
import { NumOfTheDayProps } from './NumOfTheDay.types';
import { styles as s } from './NumOfTheDay.css';
import { PluginViewWrapper } from 'src/pages/smartGrid/components/shared/PluginViewWrapper';
import { GridSettings } from '@pages/smartGrid/components/shared/GridSettings';
import {
    IInitValuesForm,
    initValuesForm,
} from './NumOfTheDaySettings/NumOfTheDayForm/config/config';
import { NumOfTheDaySettings } from './NumOfTheDaySettings/NumOfTheDaySettings';
import { useEditor } from '@pages/smartGrid/store/hooks';
import ShareIcon from '@mui/icons-material/Share';
import { Tooltip } from '@mui/material'; // NOTE: Название - в макете надпись "цифра дня"?

// NOTE: Название - в макете надпись "цифра дня"?

const NumOfTheDay: FC<NumOfTheDayProps> = ({
    id,
    type,
    parentId,
}) => {
    const { handleUpdateElement, getCurrentDataElement } =
        useEditor();
    const data = getCurrentDataElement(id);

    const values = { ...initValuesForm, ...data?.meta, id };

    const onSubmit = (values: IInitValuesForm) => {
        handleUpdateElement({
            id,
            type,
            meta: values as Record<string, any>,
        });
    };

    const isImage = !!values.cover?.image;

    if (!data && id !== 'preview') return null;
    return (
        <>
            <PluginViewWrapper
                id={id}
                parentId={parentId}
                title={'Цифра дня'}
                render={(isEdit: any, setIsEdit: any) => {
                    return (
                        <GridSettings
                            isOpen={isEdit}
                            setIsOpen={setIsEdit}
                            title={'Цифра дня - настройка'}
                            render={({ handleClose }) => {
                                return (
                                    <NumOfTheDaySettings
                                        initialValues={
                                            values
                                        }
                                        onSave={(
                                            values: IInitValuesForm,
                                        ) => {
                                            onSubmit(
                                                values,
                                            );
                                            handleClose();
                                        }}
                                    />
                                );
                            }}
                        />
                    );
                }}
            >
                <div className={s.wrapper({})}>
                    <div
                        className={`${s.content({
                            isImage,
                        })}`}
                    >
                        <div
                            className={`${s.contentIn({
                                isImage,
                            })}`}
                        >
                            {isImage && (
                                <div
                                    className={`${s.image}`}
                                    style={{
                                        backgroundImage: `url(${values.cover?.image})`,
                                    }}
                                ></div>
                            )}
                            {values.count && (
                                <div className={s.count}>
                                    {values.count}
                                </div>
                            )}
                            {values.title && (
                                <div className={s.text}>
                                    {values.title}
                                </div>
                            )}
                            {values.url && (
                                <Tooltip title={values.url}>
                                    <a
                                        className={s.url({
                                            isImage,
                                        })}
                                        target="_blank"
                                        href={values.url}
                                        rel="noreferrer"
                                    >
                                        <ShareIcon />
                                        Поделиться
                                    </a>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                </div>
            </PluginViewWrapper>
        </>
    );
};

export { NumOfTheDay };
