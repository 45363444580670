import { PluginTemplate } from '@pages/smartGrid/types/blocks';

export enum NumOfTheDayEnum {
    type = 'NUM_OF_THE_DAY',
}

export interface NumOfTheDayProps {
    id: string;
    type: string;
    parentId: string | null;
    text: string;
}

export type NumOfTheDayMeta = {};

export type NumOfTheDayPlugin = PluginTemplate<
    NumOfTheDayEnum.type,
    NumOfTheDayMeta
>;
export type NumOfTheDayPluginDefault = Omit<
    NumOfTheDayPlugin,
    'id'
>;
