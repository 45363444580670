import React, { ReactElement } from 'react';
import {
    Article,
    Articles,
    ArticlesEdit,
    ArticlesNew,
    Authors,
    AuthorsEdit,
    AuthorsNew,
    BannedOrganizationEdit,
    BannedOrganizationNew,
    BannedOrganizations,
    Block,
    BlockEdit,
    BlockNew,
    Categories,
    CategoriesEdit,
    CategoriesNew,
    Copyright,
    CopyrightCreate,
    CopyrightEdit,
    Documents,
    DocumentsEdit,
    DocumentsNew,
    EmployeeEdit,
    EmployeeNew,
    Employees,
    Grid,
    HeadScript,
    HeadScriptEdit,
    HeadScriptNew,
    InfiniteMix,
    InfiniteMixEdit,
    InfiniteMixNew,
    Infounittasks,
    Newspapers,
    NewspapersEdit,
    NewspapersNew,
    Reports,
    ReportSources,
    ReportSourcesEdit,
    ReportSourcesNew,
    RepostCreate,
    RepostEdit,
    Reposts,
    SmartGrid,
    Stories,
    StoryEdit,
    StreamEdit,
    StreamNew,
    Streams,
    Tags,
    TagsEdit,
    TagsNew,
    VideoEdit,
    VideoNew,
    Videos,
} from '@pages';

type Pages = Record<string, ReactElement>;

// Ключ компонента это пермишен
const mapPages: Pages = {
    mainpage: <Grid />,
    maingrid: <SmartGrid />,
    story: <Stories />,
    story_change: <StoryEdit />,
    article: <Articles />,
    article_view: <Article />,
    article_add: <ArticlesNew />,
    article_change: <ArticlesEdit />,
    hostvideo: <Videos />,
    hostvideo_add: <VideoNew />,
    hostvideo_change: <VideoEdit />,
    tag: <Tags />,
    tag_add: <TagsNew />,
    tag_change: <TagsEdit />,
    category: <Categories />,
    category_add: <CategoriesNew />,
    category_change: <CategoriesEdit />,
    issue: <Newspapers />,
    issue_add: <NewspapersNew />,
    issue_change: <NewspapersEdit />,
    newspaperdocument: <Documents />,
    newspaperdocument_add: <DocumentsNew />,
    newspaperdocument_change: <DocumentsEdit />,
    author: <Authors />,
    author_add: <AuthorsNew />,
    author_change: <AuthorsEdit />,
    infounittask: <Infounittasks />,

    report: <Reports />,
    copyright: <Copyright />,
    copyright_add: <CopyrightCreate />,
    copyright_change: <CopyrightEdit />,
    reportsource: <ReportSources />,
    reportsource_add: <ReportSourcesNew />,
    reportsource_change: <ReportSourcesEdit />,
    repost: <Reposts />,
    repost_add: <RepostCreate />,
    repost_change: <RepostEdit />,

    mainpagestream: <Streams />,
    mainpagestream_add: <StreamNew />,
    mainpagestream_change: <StreamEdit />,

    block: <Block />,
    block_add: <BlockNew />,
    block_change: <BlockEdit />,

    headscript: <HeadScript />,
    headscript_add: <HeadScriptNew />,
    headscript_change: <HeadScriptEdit />,

    infinitemix: <InfiniteMix />,
    infinitemix_add: <InfiniteMixNew />,
    infinitemix_change: <InfiniteMixEdit />,

    bannedorganization: <BannedOrganizations />,
    bannedorganization_add: <BannedOrganizationNew />,
    bannedorganization_change: <BannedOrganizationEdit />,

    user: <Employees />,
    user_add: <EmployeeNew />,
    user_change: <EmployeeEdit />,
};

export { mapPages };
