import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgHome = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M3 15.75v-9l6.037-4.5L15 6.75v9h-4.387v-5.344H7.369v5.344H3Zm1.125-1.125h2.119V9.281h5.494v5.344h2.137V7.312L9.037 3.656 4.125 7.312v7.313Z"
            fill="currentColor"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgHome);
export default ForwardRef;
