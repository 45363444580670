import * as CLIENT from '@mega/api';
import type {
    GetADSBlock,
    GetADSBlockQuery,
    GetADSBlockError,
    IisOk,
    IisNotOk,
} from '@mega/api';

export type GetAdsBlockPromise = Promise<
    IisOk<GetADSBlock> | IisNotOk<GetADSBlockError | null>
>;

export const getAdsBlock = async (
    query: GetADSBlockQuery,
    site: string,
): GetAdsBlockPromise => {
    try {
        const response = await CLIENT?.ads?.getAdsBlocks?.(
            query,
            {
                headers: {
                    'X-Site': site,
                },
            },
        );
        if (response?.ok) {
            return {
                isOk: true,
                value: response.data,
            };
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    } catch (e) {
        if (
            CLIENT.streams.get &&
            e instanceof CLIENT.streams.get.Error
        ) {
            const error = e.getActualType();
            switch (error.status) {
                case 401: {
                    return {
                        isOk: false,
                        value: error.data,
                    };
                }
                default: {
                    return {
                        isOk: false,
                        value: null,
                    };
                }
            }
        } else {
            return {
                isOk: false,
                value: null,
            };
        }
    }
};
