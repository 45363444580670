import React, { useEffect } from 'react';
import {
    definitions,
    VideoCreate as VideoCreateType,
} from '@mega/api';
import {
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import {
    useVideoDispatch,
    useVideoStore,
} from '@mega/store';
import { VideoCreate } from '@mega/forms';
import { useSnackbar } from 'notistack';

export const VideoEdit: React.FC = () => {
    const { get, update } = useVideoDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { loading, store } = useVideoStore();
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            get({ id: parseInt(id) });
        }
    }, [id, get]);

    if (loading.get.loading || !loading.get.success) {
        return <div>loading</div>;
    }

    if (!id) {
        throw new Error('The editable entity has no id.');
    }

    const handleUpdate = async (
        values: VideoCreateType & {
            cover?: definitions['AdminImage'];
        },
    ) => {
        const updatedFields = [
            'cover',
            'description',
            'title',
        ];
        const formData = new FormData();
        for (const [key, value] of Object.entries(values)) {
            if (updatedFields.includes(key) && value) {
                if (
                    !(
                        key === 'cover' &&
                        typeof value === 'string'
                    )
                ) {
                    formData.append(key, value as string);
                }
            }
        }
        if (values.id) {
            update({
                query: {
                    id: values.id,
                },
                payload: formData,
                options: {
                    notifier: {
                        enqueueSnackbar,
                    },
                    router: {
                        navigate,
                        location,
                    },
                },
            });
        }
    };

    return (
        <VideoCreate
            handleUpdate={handleUpdate}
            initialValue={store?.data}
        />
    );
};
