import React, { useCallback } from 'react';
import {
    base,
    cross,
    image,
    preview,
} from './CheckedList.css';
import { Mediafile } from '@mega/api/typesMS';
import { useFormikContext } from 'formik';
import { FormValues } from '../../types';
import { Close } from '@mega/icons';
import { getActualPreview } from '../../helper';

export const CheckedList = () => {
    const { values, setFieldValue } =
        useFormikContext<FormValues>();
    const { fileIdList } = values;

    const deleteItem = useCallback(
        (id: number) => {
            const curArray = { ...fileIdList };
            delete curArray[id];
            setFieldValue('fileIdList', curArray);
        },
        [fileIdList],
    );

    if (Object.keys(fileIdList).length < 2) return null;
    return (
        <div className={base}>
            {Object.keys(fileIdList).map((key) => {
                const media = fileIdList[+key];
                const img = (
                    getActualPreview(
                        // @ts-ignore
                        media.file?.srcset,
                        30,
                        45,
                    ) || media.file
                )?.src;

                return (
                    <div className={preview} key={key}>
                        <img
                            className={image}
                            src={img}
                            alt=""
                        />
                        <div
                            className={cross}
                            onClick={() => deleteItem(+key)}
                        >
                            <Close />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
