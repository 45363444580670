import React, {
    FC,
    memo,
    useEffect,
    useReducer,
} from 'react';
import {
    HeadTemplatePlugin,
    TemplatePlugin,
} from '@mega/editor';
import { useEditor } from '@apostroph/store';
import { GalleryMeta, GalleryProps } from './Gallery.types';
import { GalleryForm } from './GalleryForm';
import { Icon, Typography } from '@mega/ui';
import { Gallery as GalleryIcon } from '@mega/icons';
import { useFormikContext } from 'formik';
import { Article } from '@mega/api';
import { removeFromFileArray } from '@mega/utils/src/updateFileArrays';
import { useMediaDispatch } from '@mega/store';

type State = {
    images: GalleryMeta['images'];
};

function reducer(
    state: State,
    action:
        | {
              type: 'updateByChangeItem';
              payload: GalleryMeta['images'];
          }
        | {
              type: 'updateByUpload';
              payload: GalleryMeta['images'];
          },
) {
    switch (action.type) {
        case 'updateByChangeItem':
            return {
                images: [...action.payload],
            };

        case 'updateByUpload':
            return {
                images: [
                    ...state.images,
                    ...action.payload,
                ],
            };
        default:
            throw new Error();
    }
}

const Gallery: FC<GalleryProps> = ({
    id,
    parentId,
    type,
}) => {
    const { handleUpdateElement, getCurrentDataElement } =
        useEditor();
    const { meta, childrenElement } =
        getCurrentDataElement<GalleryMeta>(id);
    const { setFieldValue, values } =
        useFormikContext<Article>();
    const { setIsLoading } = useMediaDispatch();

    const [slides, dispatch] = useReducer(reducer, {
        images: meta.images,
    });

    useEffect(() => {
        const newGlobalState = {
            id,
            type,
            childrenElement,
            meta: {
                images: slides.images,
            },
        };
        setIsLoading({
            isLoading: false,
        });

        handleUpdateElement(newGlobalState);
    }, [slides.images]);

    const handleDelete = (ids: number[]) => {
        removeFromFileArray({
            ids,
            field: 'images',
            values,
            setFieldValue,
        });
    };

    type order = (
        id: number | undefined,
        type: 'next' | 'prev',
    ) => () => void;

    const findIndex = (id: number) =>
        slides.images.findIndex((item) => item.id === id);

    const handleChangeOrder: order = (id, type) => () => {
        if (id) {
            switch (type) {
                case 'next': {
                    const index = findIndex(id);
                    const state = [...slides.images];
                    Array.prototype.splice.call(
                        state,
                        index + 1,
                        0,
                        Array.prototype.splice.call(
                            state,
                            index,
                            1,
                        )[0],
                    );
                    dispatch({
                        type: 'updateByChangeItem',
                        payload: state,
                    });
                    break;
                }
                case 'prev': {
                    const index = findIndex(id);
                    const state = [...slides.images];
                    Array.prototype.splice.call(
                        state,
                        index - 1,
                        0,
                        Array.prototype.splice.call(
                            state,
                            index,
                            1,
                        )[0],
                    );
                    dispatch({
                        type: 'updateByChangeItem',
                        payload: state,
                    });
                    break;
                }
            }
        }
    };

    return (
        <TemplatePlugin
            head={
                <HeadTemplatePlugin
                    id={id}
                    parentId={parentId}
                    onDelete={() => {
                        const idArray = slides.images.map(
                            (item) => item.id,
                        );
                        idArray.length &&
                            handleDelete(
                                idArray as number[],
                            );
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '4px',
                        }}
                    >
                        <Icon size="16">
                            <GalleryIcon />
                        </Icon>
                        <Typography size={'12'}>
                            Галерея
                        </Typography>
                    </div>
                </HeadTemplatePlugin>
            }
        >
            <GalleryForm
                slides={slides.images}
                handleUpdateItem={(data) => {
                    dispatch({
                        type: 'updateByChangeItem',
                        payload: data,
                    });
                }}
                handleUpdateUpload={(data) => {
                    dispatch({
                        type: 'updateByUpload',
                        payload: data,
                    });
                }}
                changeOrder={handleChangeOrder}
            />
        </TemplatePlugin>
    );
};

const GalleryMemo = memo(Gallery);

export { GalleryMemo as Gallery };
