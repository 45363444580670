import React, { FC } from 'react';
import { FlowStackProps } from './FlowStack.types';
import { styles as s } from './FlowStack.css';
import { PluginViewWrapper } from '@pages/smartGrid/components/shared';
import { useEditor } from '@pages/smartGrid/store/hooks';
import cn from 'classnames';

const FlowStack: FC<FlowStackProps> = (props) => {
    const { children, meta, id, parentId } = props;

    const { handleUpdateElement } = useEditor();

    // const {
    //     config,
    //     elParent,
    //     setIsEdit: setIsEditConfig,
    //     isEdit,
    // } = useGridConfig(id, true);

    // const { config, elParent } = useGridConfig(id, true);
    // const saveMeta = useCallback(() => {
    //     const newGlobalState = {
    //         id,
    //         meta: {
    //             gridConfig,
    //         },
    //     };
    //     handleUpdateElement(newGlobalState);
    // }, [gridConfig]);

    if (meta?.isMain) {
        return (
            <div className={s.wrapper({ hasGap: true })}>
                {children}
            </div>
        );
    }

    return (
        <PluginViewWrapper
            dynHeight={'max-content'}
            id={id}
            parentId={parentId}
            title={'Столбец'}
            // render={(isEdit: any, setIsEdit: any) => {
            //     return (
            //         <GridSettings
            //             isOpen={isEdit}
            //             setIsOpen={setIsEdit}
            //             title={'Ряд - настройки'}
            //             render={({ handleClose }) => {
            //                 return (
            //                     <FlowSettings
            //                         onSave={() => {
            //                             // saveMeta();
            //                             handleClose();
            //                         }}
            //                         onChange={(
            //                             values,
            //                         ) => {}}
            //                     />
            //                 );
            //             }}
            //         />
            //     );
            // }}
        >
            <div
                className={[
                    s.wrapper({ hasGap: true }),
                ].join()}
                // ref={elParent}
                // style={assignInlineVars({
                //     [DynRows]: config,
                // })}
                // style={{ gridTemplateRows: config }}
            >
                {/*//@ts-ignore*/}
                {children?.props.children.map(
                    (child: any, idx: number) => {
                        // const isVisible = idx !== 0;
                        return (
                            <div
                                key={`${id}_${idx}`}
                                className={cn(
                                    s.child,
                                    `${id}_part`,
                                )}
                            >
                                {/*{isVisible && (*/}
                                {/*    <div*/}
                                {/*        id={*/}
                                {/*            'not_draggable__container'*/}
                                {/*        }*/}
                                {/*        className={cn(*/}
                                {/*            s.gutter({*/}
                                {/*                isEdit,*/}
                                {/*            }),*/}
                                {/*            `gutter`,*/}
                                {/*        )}*/}
                                {/*    />*/}
                                {/*)}*/}
                                {child}
                            </div>
                        );
                    },
                )}
            </div>
        </PluginViewWrapper>
    );
};

export { FlowStack };
