import React, { FC, useEffect } from 'react';

import {
    motion,
    usePresence,
    Variants,
} from 'framer-motion';

import {
    loaderClasses as classes,
    LoaderClasses,
} from './Loader.css';

const ContainerVariants = {
    initial: {
        transition: {
            staggerChildren: 0.2,
        },
    },
    animate: {
        transition: {
            staggerChildren: 0.2,
        },
    },
};

const DotVariants: Variants = {
    animate: {
        y: ['0%', '100%'],
        transition: {
            duration: 0.5,
            repeat: Infinity,
            repeatType: 'reverse',
            ease: 'easeInOut',
        },
    },
};

const DotTransition = {
    duration: 1,
    repeatType: 'mirror',
    ease: 'easeInOut',
    repeat: Infinity,
};

const Loader: FC<
    Record<string, unknown> & LoaderClasses['recipe']
> = ({ size }) => {
    const [isPresent, safeToRemove] = usePresence();

    useEffect(() => {
        if (safeToRemove && !isPresent) {
            setTimeout(safeToRemove, 1000);
        }
    }, [isPresent, safeToRemove]);

    return (
        <motion.div
            initial={{
                opacity: 0,
                scale: 0,
            }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{
                opacity: 0,
                scale: 0,
            }}
            className={classes.recipe({ size })}
        >
            <motion.div
                className={classes.loadingContainer}
                variants={ContainerVariants}
                initial="initial"
                animate="animate"
            >
                <motion.span
                    className={classes.loadingDot}
                    variants={DotVariants}
                    transition={DotTransition}
                    animate={{
                        backgroundColor: [
                            'hsl(240deg 46.18% 78.17%)',
                            'hsl(178.12deg 46.18% 78.17%)',
                        ],
                    }}
                />
                <motion.span
                    className={classes.loadingDot}
                    variants={DotVariants}
                    transition={DotTransition}
                    animate={{
                        backgroundColor: [
                            'hsl(240deg 46.18% 78.17%)',
                            'hsl(178.12deg 46.18% 78.17%)',
                        ],
                    }}
                />
                <motion.span
                    className={classes.loadingDot}
                    variants={DotVariants}
                    transition={DotTransition}
                    animate={{
                        backgroundColor: [
                            'hsl(240deg 46.18% 78.17%)',
                            'hsl(178.12deg 46.18% 78.17%)',
                        ],
                    }}
                />
            </motion.div>
        </motion.div>
    );
};

export { Loader };
export default Loader;
