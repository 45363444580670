import {
    Button,
    InputSelectMulti,
    WithLabel,
} from '@mega/ui';
import React, { FC } from 'react';
import { IInitValuesForm } from './config';
import {
    getOptionsCategories,
    getOptionsTags,
} from '@mega/core';
import { useSiteStore } from '@mega/store';
import { useFormikContext } from 'formik';
import { styles as s } from '../../AutoArticle.css';
import { ArticleDefaultForm } from '@pages/smartGrid/components/Forms';

interface AutoArticleFormProps {
    onSubmit: (
        e?: React.FormEvent<HTMLFormElement> | undefined,
    ) => void;
}

export const AutoArticleForm: FC<AutoArticleFormProps> = ({
    onSubmit,
}) => {
    const currentSite = useSiteStore();
    const { setFieldValue, values } =
        useFormikContext<IInitValuesForm>();
    return (
        <>
            <div className={s.settingsContainer}>
                <ArticleDefaultForm />
                <WithLabel
                    title={'Рубрика'}
                    id={'categogries'}
                    color={'white'}
                >
                    <InputSelectMulti
                        defaultValue={values?.categories}
                        key="categories"
                        classes={{
                            paper: {
                                color: 'white',
                            },
                            select: {
                                variant: 'third',
                            },
                        }}
                        actionOnSelectedOption={(
                            formValues,
                        ) => {
                            setFieldValue(
                                'categories',
                                formValues,
                            );
                        }}
                        handleLoader={getOptionsCategories({
                            site: currentSite?.id || '',
                            has_articles: true,
                        })}
                    />
                </WithLabel>

                <WithLabel
                    title={'Теги'}
                    id={'tags'}
                    color={'white'}
                >
                    <InputSelectMulti
                        defaultValue={values?.tags}
                        key="tags"
                        classes={{
                            paper: {
                                color: 'white',
                            },
                            select: {
                                variant: 'third',
                            },
                        }}
                        actionOnSelectedOption={(
                            formValues,
                        ) => {
                            setFieldValue(
                                'tags',
                                formValues,
                            );
                        }}
                        handleLoader={getOptionsTags({
                            site: currentSite?.id || '',
                            has_articles: true,
                        })}
                    />
                </WithLabel>

                <Button
                    type={'button'}
                    label={'Сохранить'}
                    onClick={() => onSubmit()}
                    classes={{
                        paper: {
                            color: 'dark',
                            variant: 'filled',
                        },
                    }}
                />
            </div>
        </>
    );
};
