import React, { FC, useEffect, useMemo } from 'react';
import { Column, CellValue } from 'react-table';
import type { Copyrights as CopyrightsType } from '@mega/api';
import { ListingTable, Icon } from '@mega/ui';
import { typographyClasses } from '@mega/styles';
import { PubOff, PubOn } from '@mega/icons';
import {
    useCopyrightsDispatch,
    useCopyrightsStore,
} from '@mega/store';
import { headItem, listingRecipe } from '../Listing.css';
import { TitleColumn } from '../Columns';

export interface ListingCopyrightProps {}

const ListingCopyright: FC<ListingCopyrightProps> = () => {
    const store = useCopyrightsStore();
    const { get, clear } = useCopyrightsDispatch();

    useEffect(() => {
        get({});
        return () => {
            clear();
        };
    }, [get, clear]);

    const columns: Column<CopyrightsType>[] = React.useMemo(
        () => [
            {
                Header: 'Заголовок',
                accessor: (row) => {
                    return {
                        title: row.title,
                        id: row.id,
                    };
                },
                Cell: ({ value }: CellValue) => (
                    <TitleColumn {...value} />
                ),
                minWidth: 200,
                width: '100%',
            },
            {
                Header: 'Публикация',
                accessor: 'is_active',

                Cell: ({ value }) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            {value ? (
                                <Icon size="25">
                                    <PubOn />
                                </Icon>
                            ) : (
                                <Icon size="25">
                                    <PubOff />
                                </Icon>
                            )}
                        </div>
                    );
                },
            },
        ],
        [],
    );

    const data = useMemo(() => {
        const values =
            store.pages?.[store.currentPage] || [];
        return Object.values(values).flat();
    }, [store.pages]);

    return (
        <>
            <ListingTable
                getCellProps={(cellInfo) => {
                    return {
                        style: {},
                    };
                }}
                getHeaderGroupProps={(cellInfo) => {
                    return {
                        className: listingRecipe({
                            header: true,
                        }),
                    };
                }}
                getHeaderProps={(cellInfo) => {
                    return {
                        className: headItem({
                            title:
                                cellInfo?.id ===
                                'Заголовок',
                        }),
                        style: {},
                    };
                }}
                getRowGroupProps={() => {
                    return {
                        className: [
                            typographyClasses.recipe({
                                size: '14',
                            }),
                            listingRecipe({
                                header: false,
                            }),
                        ].join(' '),
                    };
                }}
                columns={columns}
                data={data}
            />
        </>
    );
};

export { ListingCopyright };
