import React, { FC, useEffect, useState } from 'react';
import {
    base,
    checked,
    image,
    imageWrapper,
    info,
    styledDate,
    styledTitle,
    video,
} from './Card.css';
import dayjs from 'dayjs';
import { Loader, Typography } from '@mega/ui';
import { Mediafile } from '@mega/api/typesMS';
import { FiCheck } from 'react-icons/fi';
import { getActualPreview } from '../../helper';
import * as CLIENT from '@mega/api';

export type CardTypes = {
    media: Mediafile;
    isActive?: boolean;
    handleClick?: (value: Mediafile) => void;
};

export type PreviewProps = {
    isVideo: boolean;
    src: string;
};

const { getMediafile } =
    CLIENT.mediashareApiClient.Mediashare();

const Preview: React.FC<PreviewProps> = ({
    isVideo,
    src = '',
}) => {
    const [fileSrc, setFileSrc] = useState('');

    const getFileBlob = async () => {
        const res = await getMediafile({ src });
        setFileSrc(res);
    };

    useEffect(() => {
        if (!fileSrc) getFileBlob();
    }, []);

    if (!fileSrc)
        return (
            <div className={imageWrapper}>
                <Loader size="small" />
            </div>
        );
    if (isVideo) {
        return (
            <video
                src={fileSrc}
                className={video}
                controls
                muted
            />
        );
    }
    return (
        <img
            className={image}
            crossOrigin="anonymous"
            alt="pic"
            src={fileSrc}
        />
    );
};

export const Card: FC<CardTypes> = (props) => {
    const { media, handleClick, isActive } = props;
    const { title, created_at, file } = media;

    const isVideo = file?.media_type === 'video';

    const preview = isVideo
        ? file.src
        : getActualPreview(
              // @ts-ignore
              file.srcset,
              100,
              152,
              true,
          )?.src;

    return (
        <div
            className={base}
            onClick={() =>
                handleClick && handleClick(media)
            }
        >
            <div className={checked({ isActive })}>
                <FiCheck />
            </div>
            <div className={imageWrapper}>
                <Preview
                    isVideo={isVideo}
                    src={preview || ''}
                />
            </div>
            <div className={info}>
                <Typography
                    size={'10'}
                    className={styledTitle}
                    title={title}
                >
                    {title}
                </Typography>
                <Typography
                    size={'10'}
                    className={styledDate}
                >
                    {dayjs(created_at).format('DD.MM.YYYY')}
                </Typography>
            </div>
        </div>
    );
};
