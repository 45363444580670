import React, {
    ReactElement,
    PropsWithChildren,
} from 'react';
import { ClearIndicatorProps } from 'react-select';

export interface IconProps {
    clearIcon?: ReactElement | null;
    className?: string;
}

const ClearIndicator =
    ({ clearIcon, className }: IconProps) =>
    ({
        children,
        innerProps: { ref, ...restInnerProps },
    }: PropsWithChildren<
        ClearIndicatorProps<Record<string, unknown>, true>
    >) =>
        (
            <div ref={ref} {...restInnerProps}>
                <div className={className}>{clearIcon}</div>
            </div>
        );

export { ClearIndicator };
export default { ClearIndicator };
