/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
    Control,
    PositionsVariants,
} from '@apostroph/types';
import { AdsSourceText } from './AdsSource';
import {
    AdsSourcePluginDefault,
    AdsSourceEnum,
} from './AdsSource.types';
import CodeIcon from '@mui/icons-material/Code';
const ADS_SOURCE_NAME = AdsSourceEnum.type;

const DEFAULT_ADS_SOURCE: AdsSourcePluginDefault = {
    type: ADS_SOURCE_NAME,
    meta: {
        text: '',
    },
    childrenElement: [],
};

const PLUGIN_ADS_SOURCE = {
    [ADS_SOURCE_NAME]: {
        emptyObject: DEFAULT_ADS_SOURCE,
        render: AdsSourceText,
    },
};

const PLUGIN_ADS_SOURCE_CONTROL: Control = {
    name: ADS_SOURCE_NAME,
    label: 'Рекламный источник',
    tooltip: 'Добавть рекламный источник',
    position: PositionsVariants.actionFeed,
    Icon: () => <CodeIcon />,
};

export {
    DEFAULT_ADS_SOURCE,
    PLUGIN_ADS_SOURCE,
    PLUGIN_ADS_SOURCE_CONTROL,
    ADS_SOURCE_NAME,
};
