import { handleCreateParentId } from '@pages/smartGrid/helpers/handleCreateParentId';
import { Elements } from '@pages/smartGrid/types/blocks';
import {
    CreateParentIdProps,
    DraggingCommonPartsProps,
    GetDraggingElementPropsType,
    GetOverElement,
    ExistingInsideCurrentContainer,
    SameParentCheck,
    ParentMainCheck,
    PutElementAtCustomHandlerProps,
} from './types';

export const createParentId = ({
    type,
    overId,
    isContainer,
    getCurrentDataElement,
    getCurrentState,
    handleUpdateElement,
}: CreateParentIdProps) => {
    let coreParent;

    if (overId && !isContainer) {
        const hasParentId = getCurrentDataElement(
            overId?.toString(),
        ).parentId;

        if (!hasParentId) {
            coreParent = handleCreateParentId({
                type,
                overId,
                getCurrentState,
                getCurrentDataElement,
                handleUpdateElement,
            });
        } else {
            coreParent = hasParentId;
        }
    }

    return coreParent;
};

export const checkIsAcceptable = (
    currentElement: Elements,
    type: any,
    isMain: unknown,
    isContainer: boolean,
) => {
    if (currentElement && currentElement.acceptedTypes) {
        return currentElement.acceptedTypes.includes(type);
    } else if (
        currentElement &&
        typeof currentElement.acceptedTypes ===
            'undefined' &&
        !isMain &&
        isContainer
    ) {
        return true;
    }
};

export const checkIsCustomPlacingAcceptable = (
    coreParent: string | undefined,
    getCurrentDataElement: <T>(id: string) => Elements,
    type: any,
) => {
    if (coreParent) {
        const coreParentElement =
            getCurrentDataElement(coreParent);

        // debugger

        if (!!coreParentElement.acceptedTypes) {
            return coreParentElement.acceptedTypes?.includes(
                type,
            );
        } else if (
            !coreParentElement.acceptedTypes &&
            coreParentElement.isContainer
        ) {
            return true;
        }
    }
};

export const getCommonDraggingParts = ({
    overId,
    type,
    draggingElement,
    getCurrentDataElement,
    getTemplate,
    getParent,
}: DraggingCommonPartsProps) => {
    const template = type
        ? getTemplate(type)
        : getTemplate(draggingElement.type);

    const isContainer = Boolean(
        getParent(overId)?.isContainer,
    );

    const overElement = getCurrentDataElement(
        overId as string,
    );

    const isMain = overElement
        ? overElement.meta?.isMain
        : undefined;

    return { template, isContainer, isMain, overElement };
};

export const getDraggingElement = ({
    id,
    type,
    getCurrentDataElement,
}: GetDraggingElementPropsType) => {
    const draggingElement = getCurrentDataElement(
        id as string,
    );

    let draggingElementParent;

    if (!type && draggingElement.parentId) {
        draggingElementParent = getCurrentDataElement(
            draggingElement.parentId as string,
        );
    }
    return { draggingElement, draggingElementParent };
};

export const getOverElementParent = ({
    overElement,
    getCurrentDataElement,
}: GetOverElement) => {
    if (overElement) {
        return getCurrentDataElement(
            overElement.parentId as string,
        );
    }
};

export const isTheSameParentCheck = ({
    type,
    draggingElementParent,
    overElementParent,
}: SameParentCheck) => {
    if (
        !type &&
        draggingElementParent &&
        overElementParent
    ) {
        return (
            overElementParent.id ===
            draggingElementParent.id
        );
    }
};

export const isElementBetweenStackCheck = ({
    type,
    draggingElementParent,
    overElementParent,
    isTheSameParent,
}: SameParentCheck & {
    isTheSameParent: boolean | undefined;
}) => {
    if (
        !type &&
        draggingElementParent &&
        overElementParent
    ) {
        return (
            !isTheSameParent &&
            overElementParent.isContainer &&
            draggingElementParent.isContainer &&
            overElementParent.type ===
                draggingElementParent.type
        );
    }
};

export const isExistingInsideCurrentContainerCheck = ({
    isMain,
    type,
    overElement,
    draggingElement,
}: ExistingInsideCurrentContainer) => {
    if (overElement && draggingElement) {
        return (
            !isMain &&
            !type &&
            Boolean(
                overElement.childrenElement.find(
                    (id) => id === draggingElement.id,
                ),
            )
        );
    }
};

export const isParentMainCheck = ({
    isElementBetweenStack,
    overElement,
    getCurrentDataElement,
}: ParentMainCheck) => {
    if (isElementBetweenStack) {
        return getCurrentDataElement(
            overElement.parentId as string,
        ).meta.isMain;
    }
};

export const putElementAtCustomPosition = ({
    handleCreateElementAtCustomPosition,
    handleUpdateElement,
    handleRemoveElement,
    template,
    overId,
    draggingElement,
    overElementParent,
}: PutElementAtCustomHandlerProps) => {
    const meta = draggingElement.meta;
    const where = overElementParent.id;
    const children = draggingElement.childrenElement;

    if (children.length > 0) {
        handleCreateElementAtCustomPosition(
            template,
            where,
            meta,
            overId,
            children,
        );

        handleUpdateElement({
            childrenElement: [],
            id: draggingElement.id,
        });

        handleRemoveElement({
            what: draggingElement.id,
            parent: draggingElement.parentId,
        });

        return;
    }

    handleCreateElementAtCustomPosition(
        template,
        where,
        meta,
        overId,
        children,
    );

    handleRemoveElement({
        what: draggingElement.id,
        parent: draggingElement.parentId,
    });
};
