import { useRef } from 'react';
import {
    throttle,
    isObject,
    isString,
    groupBy,
} from 'lodash-es';
import type {
    Control,
    EditorModel,
    Elements,
    Group,
    GroupedByPositionControl,
} from '@apostroph/types';
import {
    RootState,
    Dispatch,
    useEditorSelector,
    useEditorDispatch,
} from '../store';
import { useDependencyEditorContext } from '../context';

type ElementWithoutId = Omit<Elements, 'id'>;

const isElementWithoutId = (
    element: unknown,
): element is ElementWithoutId => {
    const checker = element as Elements;
    return (
        isObject(checker) &&
        Boolean(checker?.type) &&
        Boolean(checker?.childrenElement) &&
        Boolean(checker?.meta) &&
        !checker?.id
    );
};

const isId = (id: unknown): id is string =>
    typeof id === 'string';

const useEditor = () => {
    const editor = useEditorSelector(
        (state: RootState) => state.editor,
    );
    const dispatch = useEditorDispatch<Dispatch>();
    const {
        controls,
        groups,
        rootElements,
        pluginElements,
    } = useDependencyEditorContext();

    const handleCreateElement = (
        payload: unknown,
        where?: unknown,
    ) => {
        if (!isElementWithoutId(payload)) {
            throw new Error(
                'Invalid payload for handleCreateElement',
            );
        }

        if (isId(where)) {
            return dispatch.editor.createElement({
                payload,
                where,
            });
        }

        return dispatch.editor.createElement({ payload });
    };

    const handleInsertElement = (
        payload: ElementWithoutId,
        anchor: string,
        where?: string,
    ) => {
        if (
            !isElementWithoutId(payload) ||
            !isString(anchor)
        ) {
            throw new Error(
                'Invalid arguments for handleInsertElement',
            );
        }

        if (isId(where)) {
            return dispatch.editor.insertElement({
                payload,
                where,
                anchor,
            });
        }

        return dispatch.editor.insertElement({
            payload,
            anchor,
        });
    };

    const handleUpdateElement = (payload: Elements) =>
        dispatch.editor.updateElement(payload);

    const handleRemoveElement = ({
        what,
        parent,
    }: {
        what: unknown;
        parent?: unknown;
    }) => {
        if (!isId(what)) {
            throw new Error(
                'Invalid "what" argument for handleRemoveElement',
            );
        }

        if (isId(parent)) {
            return dispatch.editor.removeElement({
                what,
                parent,
            });
        }

        return dispatch.editor.removeElement({ what });
    };

    const handleMoveElement =
        (direction: 'up' | 'down') =>
        ({
            what,
            parent,
        }: {
            what: unknown;
            parent?: unknown;
        }) => {
            if (!isId(what)) {
                throw new Error(
                    `Invalid "what" argument for handleMoveElement${
                        direction.charAt(0).toUpperCase() +
                        direction.slice(1)
                    }`,
                );
            }

            const action =
                direction === 'up'
                    ? dispatch.editor.moveElementUp
                    : dispatch.editor.moveElementDown;

            if (isId(parent)) {
                return action({ what, parent });
            }

            return action({ what });
        };

    const handleMoveElementUp = handleMoveElement('up');
    const handleMoveElementDown = handleMoveElement('down');

    const throttledHandleUpdateBlock = useRef(
        throttle(handleUpdateElement, 1000),
    );

    const getControls = (
        typeBlock: string,
    ): {
        controls: GroupedByPositionControl;
        groups: Array<Group>;
    } => {
        const currentControls = controls?.[typeBlock] || [];
        const currentGroups = groups?.[typeBlock] || [];

        const splitControls = currentControls.flatMap(
            (control) =>
                Array.isArray(control.position)
                    ? control.position.map((item) => ({
                          ...control,
                          position: item,
                      }))
                    : control,
        );

        const groupedControlsByPosition = groupBy(
            splitControls,
            'position',
        ) as GroupedByPositionControl;

        return {
            controls: groupedControlsByPosition,
            groups: currentGroups,
        };
    };

    const getParent = (parentId: unknown) =>
        isId(parentId) ? editor.data[parentId] : null;

    const getTemplate = (type: unknown) => {
        if (!isString(type)) return null;
        return (
            pluginElements?.[type]?.emptyObject ||
            rootElements?.[type]?.emptyObject ||
            null
        );
    };

    const getCurrentDataElement = <T>(
        id: string,
    ): { meta: T; childrenElement: Array<string> } => {
        const element = editor.data[id];
        if (!element) {
            throw new Error(
                `No element found with id: ${id}`,
            );
        }
        return {
            meta: element.meta as T,
            childrenElement: element.childrenElement,
        };
    };

    const getCurrentState = () => editor;

    return {
        editor,
        handleInit: (state: EditorModel) =>
            dispatch.editor.initEditor(state),
        handleInsertElement,
        handleCreateElement,
        handleUpdateElement:
            throttledHandleUpdateBlock.current,
        handleRemoveElement,
        handleMoveElementUp,
        handleMoveElementDown,
        getControls,
        getParent,
        getTemplate,
        getCurrentDataElement,
        getCurrentState,
    };
};

export { useEditor };
export default { useEditor };
