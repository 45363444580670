import {
    Dispatch,
    RootState,
    store,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';

const useArticleProgress = () => {
    const progress = useStoreSelector(
        (store: RootState) => store.articleProgress,
    );
    return progress;
};

const useArticleProgressDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    return {
        clear: dispatch.articleProgress.clear,
        saveProgress: dispatch.articleProgress.saveProgress,
        toggleOpenDiff:
            dispatch.articleProgress.toggleOpenDiff,
    };
};

export { useArticleProgress, useArticleProgressDispatch };
