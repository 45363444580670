import React from 'react';
import { EmployeeCreate } from '@mega/forms';
import { useSiteStore } from '@mega/store';

export const EmployeeNew: React.FC = () => {
    const siteStore = useSiteStore();

    return (
        <>
            <EmployeeCreate />
        </>
    );
};
