/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
    Control,
    PositionsVariants,
} from '@apostroph/types';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { Blockquote } from './Blockquote';
import {
    BlockquotePluginDefault,
    BlockquoteEnum,
} from './Blockquote.types';

const PLUGIN_BLOCKQUOTE_NAME = BlockquoteEnum.type;

const DEFAULT_BLOCKQUOTE: BlockquotePluginDefault = {
    type: PLUGIN_BLOCKQUOTE_NAME,
    meta: {
        regalia: '',
        owner: '',
        text: '',
    },
    childrenElement: [],
};

const PLUGIN_BLOCKQUOTE = {
    [PLUGIN_BLOCKQUOTE_NAME]: {
        emptyObject: DEFAULT_BLOCKQUOTE,
        render: Blockquote,
    },
};

const PLUGIN_BLOCKQUOTE_CONTROL: Control = {
    name: PLUGIN_BLOCKQUOTE_NAME,
    label: 'Цитата',
    tooltip: 'Создать цитату',
    position: PositionsVariants.actionFeed,
    Icon: () => <FormatQuoteIcon />,
};

export {
    DEFAULT_BLOCKQUOTE,
    PLUGIN_BLOCKQUOTE,
    PLUGIN_BLOCKQUOTE_CONTROL,
    PLUGIN_BLOCKQUOTE_NAME,
};
export default PLUGIN_BLOCKQUOTE;
