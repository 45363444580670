import React, {
    Children,
    cloneElement,
    FC,
    ReactElement,
} from 'react';

import {
    buttonGroupClasses,
    ButtonGroupClasses,
} from '@mega/styles';

export interface ButtonGroupProps {
    children: ReactElement | ReactElement[];
    className?: string;
}

const ButtonGroup: FC<
    ButtonGroupProps & ButtonGroupClasses['recipe']
> = ({ children, orientation, className = '' }) => (
    <div
        className={[
            buttonGroupClasses.recipe({ orientation }),
            className,
        ].join(' ')}
    >
        {Children.map(children, (child) =>
            cloneElement(child, {
                isGroup: true,
                orientation,
            }),
        )}
    </div>
);

export { ButtonGroup };
