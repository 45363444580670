import {
    Dispatch,
    RootState,
    useStoreDispatch,
    useStoreSelector,
} from '../../models';
import { useRef } from 'react';
import { debounce } from 'lodash-es';

const useCopyrightsStore = () => {
    const state = useStoreSelector(
        (state: RootState) => state.copyrights,
    );
    const loading = useStoreSelector(
        (state: RootState) =>
            state.loading.effects.copyrights,
    );

    return Object.assign(state, {
        loading: {
            get: loading.get,
            chungeCountItemsOnPage:
                loading.chungeCountItemsOnPage,
            jumpTo: loading.jumpTo,
        },
    });
};
const useCopyrightsDispatch = () => {
    const dispatch = useStoreDispatch<Dispatch>();

    const debouncedHandleSetCurrentPage = useRef(
        debounce(dispatch.copyrights.setCurrentPage, 1000),
    );

    return {
        get: dispatch.copyrights.get,
        nextPage: dispatch.copyrights.nextPage,
        jumpTo: dispatch.copyrights.jumpTo,
        setCurrentPage:
            debouncedHandleSetCurrentPage.current,
        search: dispatch.copyrights.search,
        clear: dispatch.copyrights.clear,
    };
};

export { useCopyrightsStore, useCopyrightsDispatch };
