import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { paginationClasses } from './Pagination.css';
import { ArrowLeft, ArrowRight } from '@mega/icons';
import { useSearchParams } from 'react-router-dom';
import { Loader } from '../Loader';
import { Typography } from './../../utility';

interface PaginatorProps {
    pageCount: number;
    currentPage?: number;
    jumpTo: (page: number) => void;
    initialPage?: number;
    loading?: boolean;
}

const Pagination: React.FC<PaginatorProps> = ({
    pageCount,
    currentPage,
    jumpTo,
    initialPage = 1,
    loading,
}) => {
    const [searchParams, setSearchParams] =
        useSearchParams();
    const forcePage = searchParams.get('page');
    const isNextPageNeeded =
        forcePage !== '1' &&
        forcePage &&
        forcePage !== initialPage.toString();

    if (
        currentPage &&
        currentPage?.toString() !==
            searchParams.get('page')?.toString()
    ) {
        const newParams = new URLSearchParams(searchParams);
        newParams.set('page', currentPage.toString());
        setSearchParams(newParams);
    }
    // TODO: rewrite forcePage implementation

    useEffect(() => {
        if (isNextPageNeeded) {
            jumpTo(Number(forcePage));
        }
    }, []);

    const handlePageClick = (selectedItem: {
        selected: number;
    }) => {
        const targetPage = selectedItem.selected + 1;
        const newParams = new URLSearchParams(searchParams);
        newParams.set('page', targetPage.toString());
        setSearchParams(newParams);
        jumpTo(targetPage);
    };

    const BreakLabel = () => {
        return <div>...</div>;
    };

    return (
        <Typography weight="bold">
            <div
                style={{
                    marginTop: '20px',
                    marginBottom: '40px',
                }}
            >
                {loading ? (
                    <Loader size="medium" />
                ) : (
                    <div
                        style={{
                            marginTop: '40px',
                            marginBottom: '40px',
                            border: '1px dashed white',
                        }}
                    ></div>
                )}
            </div>
            <ReactPaginate
                previousLabel={
                    <ArrowLeft
                        style={{ fontSize: '15px' }}
                    />
                }
                nextLabel={
                    <ArrowRight
                        style={{ fontSize: '15px' }}
                    />
                }
                breakLabel={<BreakLabel />}
                breakClassName={paginationClasses.breakItem}
                pageCount={loading ? 0 : pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={4}
                onPageChange={
                    loading ? () => {} : handlePageClick
                }
                forcePage={
                    forcePage ? Number(forcePage) - 1 : -1
                }
                containerClassName={
                    paginationClasses.pagination
                }
                activeClassName={paginationClasses.active}
                previousClassName={
                    paginationClasses.previousButton
                }
                nextClassName={paginationClasses.nextButton}
                disabledClassName={
                    paginationClasses.disabled
                }
                pageLinkClassName={
                    paginationClasses.pageNumber
                }
            />
        </Typography>
    );
};

export { Pagination };
