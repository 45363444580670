import React, { useState, FC } from 'react';
import { Modal } from '@mega/utils';
import {
    base,
    buttonWrapper,
    inputTitle,
    modalTitle,
    checkboxGroup,
} from './RejectUnitModal.css';
import {
    Button,
    Checkbox,
    IconButton,
    Input,
    Radio,
    Row,
    Stack,
    Typography,
} from '@mega/ui';
import { Close } from '@mega/icons';
import { useInfounittasksDispatch } from '@mega/store';
import { useSnackbar } from 'notistack';

interface RejectUnitModalProps {
    isOpen: boolean;
    id: number | null;
    page: number;
    smi?: string;
    onClose: () => void;
}

const reasonsList = [
    {
        label: 'Неактуальный инфоповод',
        value: 'Неактуальный инфоповод',
    },
    { label: 'Неинтересно', value: 'Неинтересно' },
    {
        label: 'Инфоповод не соответствует уровню СМИ',
        value: 'Инфоповод не соответствует уровню СМИ',
    },
    {
        label: 'Другая причина',
        value: '',
    },
];
export const RejectUnitModal: FC<RejectUnitModalProps> = (
    props,
) => {
    const { isOpen, id, smi, onClose, page } = props;
    const { rejectInfoUnit } = useInfounittasksDispatch();
    const [comment, setComment] = useState<
        string | null | undefined
    >(undefined);

    const [isDisabled, setIsDisabled] = useState(true);
    const [ownComment, setOwnComment] = useState<
        string | undefined
    >(undefined);
    const { enqueueSnackbar } = useSnackbar();

    const handleClose = () => {
        setComment(undefined);
        setOwnComment(undefined);
        onClose();
    };

    const sendRejection = async () => {
        if (smi) {
            const response = await rejectInfoUnit({
                rejection_comment: comment || ownComment,
                id,
                smi_id: smi,
                page: page,
            });

            if (response?.isOk) {
                enqueueSnackbar(
                    'Отказ оформлен \n Инфоповод пропадет из общего списка',
                    {
                        variant: 'success',
                        style: { whiteSpace: 'pre-line' },
                    },
                );
                handleClose();
            } else {
                enqueueSnackbar(
                    'Ошибка! \n Выберите хотя бы одну причину для отказа',
                    {
                        variant: 'error',
                        style: { whiteSpace: 'pre-line' },
                    },
                );
            }
        }
    };
    const handleChange = ({
        value,
    }: {
        value: string | null;
    }) => {
        setComment(value);
        setOwnComment((prev) => prev ?? '');
        setIsDisabled(!!value);
    };

    return (
        <Modal onClose={handleClose} open={isOpen}>
            <div className={base}>
                <Row
                    className={modalTitle}
                    verticalAlign={'top'}
                    gap={'16'}
                >
                    <Typography weight={'bold'} size={'24'}>
                        Выберите причину отказа
                    </Typography>
                    <IconButton
                        animate="primary"
                        size="medium"
                        onClick={handleClose}
                    >
                        <Close />
                    </IconButton>
                </Row>
                <div
                    style={{
                        display: 'grid',
                        gap: 25,
                    }}
                >
                    <div className={checkboxGroup}>
                        <Radio
                            data={reasonsList}
                            name={'reasons'}
                            value={comment}
                            reinitialize
                            onChange={handleChange}
                        />
                    </div>
                    <Stack gap="4">
                        <Typography
                            className={inputTitle}
                            weight={'regular'}
                            size={'16'}
                        >
                            Другая причина
                        </Typography>
                        <Input
                            name="search"
                            type="text"
                            value={ownComment}
                            disabled={isDisabled}
                            placeholderBase="Введите свою причину отказа"
                            onChange={(e) => {
                                setOwnComment(
                                    e.target.value,
                                );
                            }}
                        />
                    </Stack>
                    <div className={buttonWrapper}>
                        <Button
                            label={'Отправить'}
                            animate="none"
                            classes={{
                                paper: {
                                    color: 'red',
                                },
                            }}
                            onClick={sendRejection}
                        />
                        <Button
                            label={'Отменить'}
                            animate="none"
                            classes={{
                                paper: {
                                    color: 'dark',
                                },
                            }}
                            onClick={() => {
                                handleClose();
                            }}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};
