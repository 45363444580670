import { v4 as uuidv4 } from 'uuid';

export const vkEmbed = () => {
    const containers = document.querySelectorAll(
        '.article-vk-sharing:empty',
    );
    if (containers.length === 0) return;

    const addVkEmbed = (
        container: HTMLElement,
        params?: string,
    ) => {
        const id = uuidv4();

        const searchRegExp = /[',"]/g;
        if (params) {
            const string = params.replaceAll(
                searchRegExp,
                '',
            );
            const paramsArr = string.split(' ');

            if (params.length > 0) {
                const elem = container;

                elem.innerHTML = `<div id=${id}></div>`;

                window.VK.Widgets.Post(
                    id,
                    paramsArr[1],
                    paramsArr[2],
                    paramsArr[3],
                );
            }
        }
    };

    containers.forEach((container) => {
        if (
            container instanceof HTMLElement &&
            window?.VK
        ) {
            addVkEmbed(
                container,
                container.dataset.vkSource,
            );
        }
    });
};
