import React, { FC, memo, useState } from 'react';
import {
    useArticleData,
    useInfounitTaskDispatch,
    useSiteStore,
    useUserInfo,
} from '@mega/store';

import { articles } from '@mega/api';
import { AnimatePresence, motion } from 'framer-motion';
import { format } from 'date-fns';
import useSWR from 'swr';

import {
    Accordion,
    Button,
    Icon,
    Paper,
    Row,
    Stack,
    Typography,
} from '@mega/ui';
import {
    ArticleInfoUnitRecipeVariants,
    rewriteButton,
    rewriteItem,
    styledMediaWrapper,
} from './ArticleInfoUnit.css';
import {
    ListItemInfo,
    ListItemInfoDataRender,
    SliderModal,
} from '@mega/core';
import { ArrowDown, IconRefresh } from '@mega/icons';
import { MediaFunctionalCard } from '@mega/core/src/MediaFunctionalCard';
import { updateFileArray } from '@mega/utils/src/updateFileArrays';
import { useFormikContext } from 'formik';
import { Article } from '@mega/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

export interface ArticleInfoUnitProps {}

const urgencyLabelMap = {
    urgent: 'Молния',
    '2h': '2ч',
    '6h': '6ч',
    '12h': '12ч',
    '24h': '24ч',
    '3h': '3ч',
    not_urgent: 'Не срочно',
};

const ArticleInfoUnit: FC<
    ArticleInfoUnitProps & ArticleInfoUnitRecipeVariants
> = () => {
    const [activeFileIndex, setActiveFileIndex] = useState<
        number | null
    >(null);
    const { values, setFieldValue } =
        useFormikContext<Article>();
    const { unlinkArticle } = useInfounitTaskDispatch();
    let navigate = useNavigate();
    let location = useLocation();
    const currentSite = useSiteStore();
    const { enqueueSnackbar } = useSnackbar();

    const store = useArticleData();
    const embargo = store?.info_unit?.embargo || null;
    const urgency = store?.info_unit?.urgency || null;
    const themes = store?.info_unit?.themes || [];
    const source = store?.info_unit?.source || null;
    const videos = store?.info_unit?.videos || [];
    const images = store?.info_unit?.images || [];
    const audios = store?.info_unit?.audios || [];
    const files = store?.info_unit?.files || [];

    const [rewrite, setRewrite] = useState(store?.rewrite);

    const isPublished = store?.is_active;
    const isRewrited = store?.rewrite;

    const isLoading =
        rewrite?.state === 'waiting' ||
        rewrite?.state === 'processing';
    const isCompleted = rewrite?.state === 'completed';

    const { isPermitted } = useUserInfo();

    const hasRewritePermission = isPermitted({
        rootPermissions: 'article',
        subRootPermissions: 'rewrite',
    });

    const isAllowedToRewrite =
        hasRewritePermission && !isPublished;

    const hasDraft =
        store?.info_unit?.id && !store.pub_date;

    useSWR(
        isLoading ? `rewrite/${rewrite?.id}` : null,
        async () => {
            if (!rewrite?.id) {
                return;
            }
            const responce = await articles.getRewriteURL?.(
                {
                    id: rewrite?.id,
                },
                {
                    headers: {
                        'X-Site': currentSite?.id || '',
                    },
                },
            );

            if (responce?.ok) {
                if (
                    JSON.stringify(rewrite) !==
                    JSON.stringify(responce.data)
                ) {
                    setRewrite(responce.data);
                    if (
                        responce.data.state === 'completed'
                    ) {
                        window.location.reload();
                    }
                }
            }
        },
        { refreshInterval: 5000 },
    );

    const createRewrite = async () => {
        try {
            if (!store?.id) {
                return;
            }

            if (!store.editor_html) {
                enqueueSnackbar(
                    'Сохраните статью перед отправкой',
                    {
                        variant: 'info',
                    },
                );
                return;
            }

            const responce =
                await articles.rewriteContent?.(
                    {
                        id: store?.id,
                        title: store.title,
                        editor_html: store?.editor_html,
                    },
                    {
                        headers: {
                            'X-Site': currentSite?.id || '',
                        },
                    },
                );

            if (responce?.ok) {
                setRewrite(responce.data);
                if (responce.data.state === 'completed') {
                    window.location.reload();
                }
            }
        } catch (e) {
            enqueueSnackbar(
                'При генерации рерайта произошла ошибка. Попробуйте отправить запрос снова',
                {
                    variant: 'error',
                },
            );
        }
    };

    if (!Boolean(store?.info_unit)) {
        return null;
    }

    const mediaList = [
        ...images.map((item) => ({
            ...item,
            type: 'image',
        })),
        ...videos.map((item) => ({
            ...item,
            type: 'video',
        })),
        ...audios.map((item) => ({
            ...item,
            type: 'audio',
        })),
        ...files.map((item) => ({ ...item, type: 'file' })),
    ];

    const handleCoverUpdate = async (file: any) => {
        const isVideo = file.type === 'video';
        const field = isVideo ? 'main_video' : 'cover';
        const arrayField = isVideo ? 'videos' : 'images';
        await updateFileArray({
            files: [file],
            field: arrayField,
            values,
            setFieldValue,
        });
        await setFieldValue(field, file);
    };

    const handleUnlinkUnit = async () => {
        if (store?.id) {
            const res = await unlinkArticle(store.id);

            if (res?.isOk) {
                enqueueSnackbar(
                    'Черновик успешно отвязан',
                    {
                        variant: 'success',
                    },
                );
                navigate(
                    `/${currentSite?.id}/infounittask`,
                    {
                        replace: true,
                        state: location,
                    },
                );
            } else {
                enqueueSnackbar('Что-то пошло не так', {
                    variant: 'error',
                });
            }
        }
    };

    return (
        <Paper
            contentHeight
            color={'white'}
            variant="outline"
            classes={{
                padding: {
                    size: '24x16',
                },
            }}
        >
            <Stack gap="8">
                <Accordion
                    divider={false}
                    isOpened
                    endIcon={
                        <Icon size="18">
                            <ArrowDown
                                style={{
                                    transform:
                                        'rotate(90deg)',
                                }}
                            />
                        </Icon>
                    }
                    label={'Инфоповод'}
                    value={'info'}
                    classes={{
                        paper: {
                            variant: 'filled',
                            color: 'white',
                        },
                        padding: {
                            size: '8x4',
                        },
                    }}
                >
                    <>
                        {embargo ? (
                            <ListItemInfo
                                embargo
                                label={'Важность:'}
                                data={embargo}
                            />
                        ) : urgency ? (
                            <ListItemInfo
                                label={'Важность'}
                                data={
                                    urgencyLabelMap[urgency]
                                }
                                urgency
                                urg={urgency}
                            />
                        ) : null}
                        <ListItemInfoDataRender
                            label={'Тема'}
                            themes={themes}
                        />

                        {source && (
                            <ListItemInfo
                                label={'Источник'}
                                data={source || ''}
                            />
                        )}

                        {/* TODO Не было времени переделывать ListItemInfoDataRender, вывел так, в следующий релиз поправить*/}
                        {/* {regions?.map((region, idx) => {
                            return (
                                <ListItemInfo
                                    label={
                                        !idx
                                            ? 'Муниципалитеты'
                                            : ''
                                    }
                                    data={
                                        region?.label || ''
                                    }
                                />
                            );
                        })} */}
                    </>
                </Accordion>
                <Accordion
                    divider={false}
                    isOpened
                    endIcon={
                        <Icon size="18">
                            <ArrowDown
                                style={{
                                    transform:
                                        'rotate(90deg)',
                                }}
                            />
                        </Icon>
                    }
                    label={'Медиа файлы'}
                    value={'media'}
                    classes={{
                        paper: {
                            variant: 'filled',
                            color: 'white',
                        },
                        padding: {
                            size: '8x4',
                        },
                    }}
                >
                    <div className={styledMediaWrapper}>
                        {mediaList.map((item, idx) => {
                            return (
                                <MediaFunctionalCard
                                    key={item.id}
                                    onClick={() =>
                                        setActiveFileIndex(
                                            idx,
                                        )
                                    }
                                    file={item}
                                    onCoverUpdate={
                                        handleCoverUpdate
                                    }
                                    noTitle
                                />
                            );
                        })}
                    </div>
                    <SliderModal
                        files={mediaList}
                        isOpen={activeFileIndex !== null}
                        initialSlide={activeFileIndex || 0}
                        onCoverUpdate={handleCoverUpdate}
                        onClose={() =>
                            setActiveFileIndex(null)
                        }
                    />
                </Accordion>

                {isAllowedToRewrite && (
                    <Paper
                        color={'white'}
                        classes={{
                            padding: {
                                size: '8x4',
                            },
                        }}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '6px',
                            alignItems: 'start',
                        }}
                    >
                        <Row className={rewriteItem}>
                            <Typography size="14">
                                Рерайт:
                            </Typography>
                            <Stack
                                gap="4"
                                style={{
                                    display: 'flex',
                                    alignItems: 'end',
                                }}
                            >
                                {isCompleted && (
                                    <div>
                                        {rewrite.completed_at && (
                                            <Typography
                                                weight="semiMeduim"
                                                size="12"
                                                textAlign="right"
                                                style={{
                                                    color: '#878787',
                                                    lineHeight:
                                                        '140%',
                                                }}
                                            >
                                                {`(выполнен: ${format(
                                                    new Date(
                                                        rewrite.completed_at,
                                                    ),
                                                    'dd.MM.yyyy HH:mm',
                                                )})`}
                                            </Typography>
                                        )}
                                    </div>
                                )}
                                <Button
                                    disabled={isLoading}
                                    label={
                                        !isRewrited
                                            ? 'Сделать рерайт'
                                            : 'Новый рерайт'
                                    }
                                    onClick={createRewrite}
                                    className={
                                        rewriteButton
                                    }
                                    endIcon={
                                        !isRewrited ? undefined : (
                                            <IconRefresh />
                                        )
                                    }
                                />
                            </Stack>
                        </Row>

                        <Row className={rewriteItem}>
                            {hasDraft && (
                                <Typography size="14">
                                    Черновик:
                                </Typography>
                            )}

                            {hasDraft && (
                                <Button
                                    label={
                                        'Отвязать черновик'
                                    }
                                    onClick={
                                        handleUnlinkUnit
                                    }
                                />
                            )}
                        </Row>

                        <AnimatePresence>
                            {isLoading ? (
                                <motion.div
                                    style={{
                                        display: 'flex',
                                        justifyContent:
                                            'center',
                                        width: '100%',
                                    }}
                                    initial={{
                                        opacity: 0,
                                        scale: 0,
                                    }}
                                    animate={{
                                        opacity: 1,
                                        scale: 1,
                                    }}
                                    exit={{
                                        opacity: 0,
                                        scale: 0,
                                    }}
                                >
                                    <Stack gap="4">
                                        <Typography
                                            as={'span'}
                                            size="18"
                                            weight="bold"
                                            textAlign="center"
                                        >
                                            Статья создается
                                            {'...'
                                                .split('')
                                                .map(
                                                    (
                                                        letter,
                                                        index,
                                                    ) => (
                                                        <motion.span
                                                            key={
                                                                index
                                                            }
                                                            initial={{
                                                                opacity: 0,
                                                            }}
                                                            animate={{
                                                                opacity: 1,
                                                            }}
                                                            transition={{
                                                                duration: 1,
                                                                delay:
                                                                    index *
                                                                    1.2,
                                                                repeat: Infinity,
                                                                // repeatDelay: 0.5,
                                                            }}
                                                        >
                                                            {
                                                                letter
                                                            }
                                                        </motion.span>
                                                    ),
                                                )}
                                        </Typography>
                                        <Typography
                                            as={'span'}
                                            size="12"
                                            weight="regular"
                                            textAlign="center"
                                            style={{
                                                color: '#878787',
                                            }}
                                        >
                                            Это займет
                                            некоторое время
                                        </Typography>
                                    </Stack>
                                </motion.div>
                            ) : null}
                        </AnimatePresence>

                        <AnimatePresence>
                            {rewrite?.state === 'error' && (
                                <motion.div
                                    initial={{
                                        opacity: 0,
                                        scale: 0,
                                    }}
                                    animate={{
                                        opacity: 1,
                                        scale: 1,
                                    }}
                                    exit={{
                                        opacity: 0,
                                        scale: 0,
                                    }}
                                    style={{
                                        display: 'flex',
                                        padding: '10px',
                                        alignItems:
                                            'flex-start',
                                        gap: '6px',
                                        alignSelf:
                                            'stretch',
                                        borderRadius: '4px',
                                        border: '1px solid var(--border-alert,#DF4B4B)',
                                        background:
                                            'var(--bg-warning-subdued,#FDF8F8)',
                                    }}
                                >
                                    <Stack gap="4">
                                        <Typography
                                            size="14"
                                            weight="bold"
                                        >
                                            Ответ от Яндекс
                                            GPT:
                                        </Typography>
                                        <Typography
                                            size="14"
                                            weight="regular"
                                            style={{
                                                color: 'var(--text-alert-secondary, #A36E6E)',
                                            }}
                                        >
                                            {rewrite.error}
                                        </Typography>
                                    </Stack>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </Paper>
                )}
            </Stack>
        </Paper>
    );
};

const ArticleInfoUnitMemo = memo(ArticleInfoUnit);

export { ArticleInfoUnitMemo as ArticleInfoUnit };
