/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
    Control,
    PositionsVariants,
} from '@apostroph/types';
import { InfoGraph } from './InfoGraph';
import {
    InfoGraphPluginDefault,
    InfoGraphEnum,
} from './InfoGraph.types';
import { Graph } from '@mega/icons';
const PLUGIN_INFOGRAPH_NAME = InfoGraphEnum.type;

const DEFAULT_INFOGRAPH: InfoGraphPluginDefault = {
    type: PLUGIN_INFOGRAPH_NAME,
    meta: {
        fp_x: undefined,
        fp_y: undefined,
        id: undefined,
        image: '',
    },
    childrenElement: [],
};

const PLUGIN_INFOGRAPH = {
    [PLUGIN_INFOGRAPH_NAME]: {
        emptyObject: DEFAULT_INFOGRAPH,
        render: InfoGraph,
    },
};

const PLUGIN_INFOGRAPH_CONTROL: Control = {
    name: PLUGIN_INFOGRAPH_NAME,
    label: 'Инфографика',
    tooltip: 'Вставить Инфографику',
    position: PositionsVariants.actionFeed,
    Icon: () => <Graph />,
};

export {
    DEFAULT_INFOGRAPH,
    PLUGIN_INFOGRAPH,
    PLUGIN_INFOGRAPH_CONTROL,
    PLUGIN_INFOGRAPH_NAME,
};
