import { createModel } from '@rematch/core';

import type { RootModel } from '../rootModel';

export interface MediaModel {
    isLoading: boolean;
}

const media = createModel<RootModel>()({
    name: 'media',
    state: {
        isLoading: false,
    } as MediaModel,
    reducers: {
        putIsLoading: (state, payload: boolean) => {
            return { ...state, isLoading: payload };
        },
    },
    effects: (dispatch) => ({
        setIsLoading: async (params: {
            isLoading: boolean;
        }) => {
            dispatch.media.putIsLoading(params.isLoading);
        },
    }),
});

export { media };
export default media;
