import React, { FC } from 'react';

import { Button, EntityListLayout } from '@mega/ui';
import { Add } from '@mega/icons';
import { useLocation, useNavigate } from 'react-router-dom';

import { EmployeesListing } from './EmployeesListing';
import { EmployeesFilters } from './EmployeesListing/EmployeesFilters';

interface ArticlesProps {
    children?: never;
}

export const Employees: FC<
    ArticlesProps
> = (): JSX.Element => {
    let navigate = useNavigate();
    let location = useLocation();

    return (
        <EntityListLayout
            setting={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: '18px',
                    }}
                >
                    <EmployeesFilters />
                    <Button
                        size={'large'}
                        endIcon={<Add />}
                        label="Добавить"
                        classes={{
                            paper: {
                                variant: 'filled',
                                color: 'dark',
                            },
                        }}
                        onClick={() => {
                            navigate(`add`, {
                                state: location,
                            });
                        }}
                    />
                </div>
            }
        >
            <EmployeesListing />
        </EntityListLayout>
    );
};
