import "../../packages/core/src/SliderModal/SliderModal.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../packages/core/src/SliderModal/SliderModal.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA41U7W7bIBT936dAqiql0oiwE7sJk6b92J5jIgY7tBgsIB/d1HefwOCA66pVJCtczr3cj3Pu+k9x2lcvTxsE/t0BMCjDLVcSA3IwSpws+34HgFUDBhV6cP8Fa+10sJpI0yrd4/GvIJatYIUevgH3fXSYg9KUaagJ5SeDwXa4OuuFU3vEYF/5OD25wsmCHOLtbh0zK3xm4bpAKHjojkt4UNaqHoOyGsPe8tdMEMvPLAtV+lC+HFgEF827o6toHiBtQHh8W4+gIxt94vlAmpdOq5OkGNwjhD4um3IzCPKKQSuYNxDBOwm5Zb3BoGHSMu3MzydjefsKGyUtkza9ak7aKI3BoHg0/YVcUnZ13ZmV+wOYc+eLbpRwXvdt2yYVbVBeUTz7aYZGTEMGaF2a/AF8VGemk2cSRpiGCLYq1sVj5rNJx9kQ0azcTAEENRquj8lsR6bFjD6Z7NZHXWRFgqq+SPIb2ZJ2zHmOEpJ/NIHaPzhWwiW3nIiEc3A35/pTgg+3E3ryT/A7j1cDabh9xQCtq5QiSx773KNYpFSCJzP15XyZDBm9EvfDe/Emt012W+5mwaMhZWPLhWX6PRWZDzXeYnDwPZPMmBVa73ICth75TokdGYJME2y3tHtiflWFvqz/eqb/TnPqDE4+rVAXDI6cUiZ9JppTaFk/OJpBrS4mF8reCcV/s1yPM3LfdJLNKyX4dL5VAOMkf/9yv+wF/pl8NjljN/MUn/PWxybMdtNAKOWyw6DcDldQhiA/e0Y5AaueS3gbiG+FCzrb8XHL1yGjm+bi0N5Sp01wWlhLRRn30mwz1UuB6hAovlaUS6ingApCT0Bv/wEzSqgUkQcAAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var base = '_1u95k730';
export var closeButton = '_1u95k732';
export var downloadButton = '_1u95k73i';
export var fileAuthor = '_1u95k73f';
export var filePreview = '_1u95k73h';
export var mainSlider = '_1u95k734';
export var navigationArrow = _7a468({defaultClassName:'_1u95k735',variantClassNames:{reversed:{true:'_1u95k736',false:'_1u95k737'},disabled:{true:'_1u95k738',false:'_1u95k739'}},defaultVariants:{},compoundVariants:[]});
export var navigationArrowIcon = '_1u95k73a';
export var slide = '_1u95k73g';
export var slideInfo = '_1u95k73j';
export var sliderContainer = '_1u95k731';
export var sliderThumbs = '_1u95k73b';
export var sliderWrapper = '_1u95k733';
export var thumbItem = _7a468({defaultClassName:'_1u95k73c',variantClassNames:{isActive:{true:'_1u95k73d',false:'_1u95k73e'}},defaultVariants:{},compoundVariants:[]});